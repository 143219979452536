import styled from 'styled-components';

const CancelButton = styled.button`
  display: block;

  border: 0;
  padding: 10px;
  margin: 15px auto -30px;
  background: none;

  font-size: 14px;
  color: var(--text-color);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export default CancelButton;
