/** @const {string} randomPrefix */
const randomPrefix = Math.random()
  .toString(36)
  .substring(2);

/**
 * Call event only once
 * @see https://api.jquery.com/one
 *
 * @param {string} type
 * @param {function} callback
 *
 * @returns {void}
 */
export function one(type, callback) {
  const fn = event => {
    window.removeEventListener(type, fn);
    callback(event);
  };

  window.addEventListener(type, fn);
}

/**
 * Emit custom event
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 *
 * @param {string} name
 * @param {object} config
 *
 * @returns {void}
 */
export function emit(name, config = {}) {
  const customEvent = new CustomEvent(`${randomPrefix}-${name}`, config);
  window.dispatchEvent(customEvent);
}

/**
 * Subscribe custom event
 *
 * @param {string} name
 * @param {function} cb
 * @param {boolean} once
 *
 * @returns {object}
 */
export function subscribe(name, cb, once = false) {
  if (once) {
    one(window, `${randomPrefix}-${name}`, cb);
    return {};
  }

  window.addEventListener(`${randomPrefix}-${name}`, cb);

  return {
    unsubscribe: () => {
      window.removeEventListener(`${randomPrefix}-${name}`, cb);
    },
  };
}
