import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Check } from 'assets/icons/ok-check.svg';
import Text from 'components/Text';

import Wrapper from './Wrapper';

const InnerWrapper = styled.div`
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;
`;

const CheckIcon = styled(Check)`
  fill: var(--text-color);
  display: block;
  min-width: 24px;
  max-width: 24px;
`;

const SuccessfullyConnected = ({ email }) => (
  <Wrapper style={{ display: 'block' }}>
    <InnerWrapper>
      <CheckIcon />
      <div style={{ minWidth: 0 }}>
        <Text size={16} weight="bold">
          Połączono
        </Text>
        <Text
          color="light-gray"
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {email}
        </Text>
      </div>
    </InnerWrapper>
  </Wrapper>
);

SuccessfullyConnected.propTypes = {
  email: PropTypes.string.isRequired,
};

export default SuccessfullyConnected;
