import React from 'react';
import PropTypes from 'prop-types';

import { PaySafeCardLogo } from 'styles/Logas';
import { ButtonWithAnimatedArrow } from 'components/Buttons';
import Text from 'components/Text';

const Step1 = ({ processStep }) => (
  <>
    <PaySafeCardLogo style={{ marginBottom: 32 }} />
    <div style={{ maxWidth: 330 }}>
      <Text size={14} lineHeight="1.36" mb={20}>
        Cześć, super że chcesz aktywować metodę Paysafecard! Nie będzie to trudne spokojnie.
        Dokładnie czytaj ze zrozumieniem nasz poradnik, aby poprawnie aktywować możliwość płatności
        Paysafecard na swojej stronie.
      </Text>
      <Text mb={35}>
        W późniejszych etapach poradnika, będziemy umieszczać odpowiednie gify, które na pewno
        pomogą Ci wprost zrozumieć co masz zrobić.
      </Text>
      <Text mb={35}>Do dzieła ;)</Text>
    </div>
    <ButtonWithAnimatedArrow onClick={processStep}>Zaczynamy</ButtonWithAnimatedArrow>
  </>
);

export default Step1;

Step1.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
};
