import styled from 'styled-components';

export default styled.span.attrs(({ color, size }) => {
  let boxSize = 20;
  const svgSize = {
    width: 13,
    height: 14,
  };

  switch (size) {
    case 'small':
      boxSize = 18;
      svgSize.width = 12;
      svgSize.height = 10;
      break;
    default:
  }

  return {
    boxSize,
    svgSize,
  };
})`
  display: inline-block;
  position: relative;

  width: ${props => props.boxSize}px;
  height: ${props => props.boxSize}px;

  border: 1px solid #5b5b5b;
  border-radius: 5px;

  transform: scale(1);
  transition: all 0.2s ease;

  vertical-align: middle;

  margin: 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    pointer-events: none;
  }

  > svg {
    position: absolute;
    width: ${props => props.svgSize.width}px;
    height: ${props => props.svgSize.height}px;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translateZ(0);
  }
`;
