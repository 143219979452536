import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';

const loadingCss = css`
  color: transparent;
  user-select: none;
  cursor: wait;

  > * {
    color: transparent !important;
  }
`;

const InputWrapper = styled.label`
  position: relative;
  width: 100%;

  > textarea {
    width: 100%;
    margin: 0;

    cursor: pointer;

    border: 1px solid var(--border-color);
    border-radius: 3px;
    padding: 12px 35px 12px 15px;

    transition: 0.2s ease;

    background: none;
    box-shadow: none;
    outline: none;
    color: var(--text-color);

    text-overflow: ellipsis;

    height: 135px;
    resize: none;

    &:focus,
    &:hover {
      border-color: var(--blue);
    }
  }

  > span {
    position: absolute;
    right: 15px;
    bottom: 12px;

    font-size: 10px;
    color: var(--text-color-white-gray);
    pointer-events: none;
  }

  ${props => props.loading && loadingCss};
  &[disabled] {
    ${loadingCss}
  }

  .spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    .spinner-path {
      stroke: var(--blue);
    }
  }
`;

const TextInput = ({
  value,
  prefix,
  suffix,
  maxLength,
  loading,
  disabled,
  copyButton,
  copyWithPrefix,
  ...props
}) => (
  <InputWrapper loading={loading} disabled={disabled}>
    {prefix && <p>{prefix}</p>}
    <textarea type="text" value={value} maxLength={maxLength} disabled={disabled} {...props} />
    {suffix && <p>{suffix}</p>}

    {copyButton && (
      <CopyToClipboard text={copyWithPrefix ? prefix + value : value} className="copyButton">
        <CopyIcon />
      </CopyToClipboard>
    )}

    {maxLength && <span>{maxLength - value.length}</span>}
    {loading && <MaterialSpinner />}
  </InputWrapper>
);

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  maxLength: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  copyButton: PropTypes.bool,
  copyWithPrefix: PropTypes.bool,
};

TextInput.defaultProps = {
  prefix: '',
  suffix: '',
  maxLength: '',
  loading: false,
  disabled: false,
  copyButton: false,
  copyWithPrefix: false,
};

export default TextInput;
