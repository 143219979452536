import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as ChatBubble } from 'assets/icons/chat-bubble.svg';

const Wrapper = styled.div`
  margin: 5px 0 0;

  svg {
    width: 35px;
    margin-right: 25px;
  }
`;

const ChatIcon = ({ iconColor }) => (
  <Wrapper>
    <ChatBubble fill={`var(--${iconColor})`} />
  </Wrapper>
);

ChatIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default ChatIcon;
