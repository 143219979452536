import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

const Wrapper = styled.div`
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 10px;
  height: 450px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const CodeEditor = ({ code, language, onChange }) => (
  <Wrapper>
    <Editor
      onValueChange={onChange}
      highlight={value => highlight(value, languages[language], language)}
      value={code}
      style={{
        fontFamily: 'monospace, monospace',
        minHeight: '100%',
      }}
    />
  </Wrapper>
);

CodeEditor.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default CodeEditor;
