import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    100%  {
      transform: scale(1) translate(-50%, 15px);
      opacity: 1;
    }
`;

const fadeOut = keyframes`
    40% {
      width: 300px;
      overflow: hidden;
    }

    70% {
      opacity: 1;
    }

    100%  {
      width: 250px;
      transform: scale(0.2) translate(-50%, -50%);
      opacity: 0;
    }
`;

export default styled.div`
  position: absolute;
  z-index: 10;

  width: 350px;
  height: 60px;

  color: #454545;
  background-color: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.04);

  border-radius: inherit;
  border-radius: 100px;

  transform-origin: 0;
  animation-duration: ${props => props.unmountDelay - 100}ms;
  animation-fill-mode: forwards;

  &.fade-enter {
    opactiy: 0;
    transform: scale(0.2) translate(-50%, -30px);
  }

  &.fade-enter-active,
  &.fade-enter-done {
    animation-name: ${fadeIn};
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }

  &.fade-exit {
    transform: scale(1) translate(-50%, 15px);

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: inherit;
    }
  }

  &.fade-exit-active {
    animation-name: ${fadeOut};
    animation-timing-function: ease-in;

    &::after {
      content: '';
    }
  }
`;
