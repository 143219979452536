import React, { useState } from 'react';

import serverAlert from 'store/utils/serverAlert';

import Text from 'components/Text';
import Flexbox from 'components/Flexbox';
import WhiteBox from 'styles/WhiteBox';

import Item from './components/Item';

import { Consumer } from '../../Context';

const COLORS = ['green', 'red', 'yellow', 'blue', 'purple'];

const Theme = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Consumer>
      {({ userDataProfile, updateTipsPageSettings }) => (
        <WhiteBox style={{ padding: '40px 25px', marginTop: 15 }}>
          <Text color="blue" weight="medium" size={15} margin="0 0 20px">
            Wybierz motyw swojej strony
          </Text>

          <Flexbox align="center" justify="space-between">
            {COLORS.map(color => (
              <Item
                key={color}
                value={color}
                checked={color === userDataProfile.theme_color}
                disabled={loading}
                onChange={async () => {
                  if (!loading) {
                    setLoading(true);

                    try {
                      await updateTipsPageSettings({ themeColor: color });
                    } catch (error) {
                      serverAlert(error.response.data.errors.children.themeColor.errors.join(', '));
                    }

                    setLoading(false);
                  }
                }}
              />
            ))}
          </Flexbox>
        </WhiteBox>
      )}
    </Consumer>
  );
};

export default Theme;
