import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { contextMenu } from 'react-contexify';
import { connect } from 'react-redux';

import { GradientText } from 'components/GradientText';
import { RoundedInfoWrapper } from 'components/RoundedInfoWrapper';
import Text from 'components/Text';
import { openAlert } from 'components/Alert/utils';

import store from 'store';
import { fetchMediaIfNeeded } from 'store/actions/mediaManager';
import serverAlert from 'store/utils/serverAlert';

import http from 'utils/http';

import { ActionCatcher } from '../../components/ActionCatcher';
import AnimationManager from '../../components/AnimationManager';
import BottomTip from '../../components/BottomTip';
import ChangeScreenBackground from '../../components/ChangeScreenBackground';
import ColumnBottomHelp from '../../components/ColumnBottomHelp';
import CustomCode from '../../components/CustomCode';
import FloatingInput from '../../components/FloatingInput';
import Panel from '../../components/Panel';
import PriceInput from '../../components/PriceInput';
import Screen from '../../components/Screen';
import ScreenContextMenu from '../../components/ScreenContextMenu';
import Switcher from '../../components/Switcher';
import TemplatesManager from '../../components/TemplatesManager';
import NumberInput from '../../components/TopDonators/styles/NumberInput';
import TwitchActivity, { redirectToTwitchAuthorization } from '../../components/TwitchActivity';
import WidgetUrl from '../../components/WidgetUrl';

import withConfiguration from '../../containers/withConfiguration';
import withHistory from '../../containers/withHistory';

import noEditableAlert from '../../lib/noEditableAlert';

import ConfigUnderScreen from '../../styles/ConfigUnderScreen';

import AddTime from './components/AddTime';
import Elements from './components/Elements';
import StartDatePicker from './components/StartDatePicker';

import { Provider } from './Context';

const CONFIGURATOR_TYPE = 'COUNTER_TO_END_LIVE';

class CounterToEndLive extends Component {
  static propTypes = {
    activeTemplateId: PropTypes.string.isRequired,
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    allTemplates: PropTypes.instanceOf(Array).isRequired,
    updateTemplate: PropTypes.instanceOf(Function).isRequired,

    configurationsAreFetched: PropTypes.bool.isRequired,
    configuratorConfig: PropTypes.instanceOf(Object).isRequired,
    updateConfig: PropTypes.instanceOf(Function).isRequired,

    elements: PropTypes.instanceOf(Object).isRequired,
    getHiddenElements: PropTypes.instanceOf(Function).isRequired,
    showElement: PropTypes.instanceOf(Function).isRequired,
    dispatchFetchMediaIfNeeded: PropTypes.instanceOf(Function).isRequired,

    pushHistory: PropTypes.instanceOf(Function).isRequired,
    createHistoryElement: PropTypes.instanceOf(Function).isRequired,
    getLatestHistoryElement: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    const { activeTemplateId, configuratorConfig } = props;

    this.state = {
      activeTemplateId,
      configuratorConfig,
      autoplayAnimation: false,
      isAnimated: false,
    };

    this.configuratorWrapper = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let returnedData = {
      configuratorConfig: props.configuratorConfig,
    };

    if (state.activeTemplateId !== props.activeTemplateId) {
      returnedData = {
        ...returnedData,
        activeTemplateId: props.activeTemplateId,
      };
    }

    return returnedData;
  }

  componentDidMount = () => {
    document.title = 'Tipply | Konfigurator (Twój cel)';

    const { dispatchFetchMediaIfNeeded } = this.props;

    dispatchFetchMediaIfNeeded();

    this.handleTwitchAuthorizeCallback();
  };

  /**
   * Obsługuje callback po autoryzacji aplikacji na Twitch. Wysyła uzyskany access token do API.
   *
   * @todo To powinno być robione w backend
   */
  handleTwitchAuthorizeCallback = () => {
    const twitchHash = window.location.hash.slice(1);
    const at = twitchHash.indexOf('access_token=');

    if (at >= 0) {
      http
        .post(`/user/twitch/twitchsubscribe`)
        .then(() => {
          window.location.assign(`/konfigurator/odlicznik-czasu-do-konca-transmisji`);
        })
        .catch(() => {
          serverAlert('Wystąpił błąd przy zapisywaniu do subskrypcji');
        });
    }
  };

  componentDidUpdate = prevProps => {
    const { configurationsAreFetched } = this.props;

    if (prevProps.configurationsAreFetched === false && configurationsAreFetched) {
      this.checkTwitchConnection();
    }
  };

  /**
   * Sprawdza stan połączenia z Twitch, jeżeli streamer używa integracji
   */
  checkTwitchConnection = () => {
    const { configuratorConfig, updateConfig } = this.props;
    const { twitchActivity, twitchActivityBegin } = configuratorConfig;

    if (twitchActivity) {
      const lastCheckDate = moment.parseZone(twitchActivityBegin);
      const daysSinceLastCheck = moment().diff(lastCheckDate, 'days');

      if (daysSinceLastCheck > 1) {
        http
          .get('/user/twitch/statuscheck')
          .then(response => {
            const { data } = response;

            updateConfig({
              twitchActivityBegin: moment().format(),
            });

            if (data === 'disabled') {
              openAlert(
                'confirm',
                'Połączenie odlicznika z Twitch',
                'Połączenie pobierające aktywności z Twitcha zostało zerwane. Aktywuj je ponownie by licznik nadal był przedłużany płatnymi aktywnościami Twitch.',
                {
                  cancelButtonText: 'Zrezygnuj i przerwij',
                  confirmButtonText: 'Odnów połączenie',
                  cancelButton: true,
                },
                () => {
                  redirectToTwitchAuthorization();
                },
              );
            }
          })
          .catch(() => {
            serverAlert('Nie udaje się sprawdzić stanu połączenia z Twitch');
          });
      }
    }
  };

  /**
   * Zamyka nasłuch zdarzeń z Twitch
   */
  unsubscribeTwitchListener = () => {
    http
      .post(`/user/twitch/twitchunsubscribe`)
      .then(() => {})
      .catch(() => {
        serverAlert('Wystąpił błąd przy wypisywaniu z subskrypcji');
      });
  };

  /**
   * Funkcja uruchamiająca menu kontekstowe dla całego widoku
   *
   * @param {Object} e
   * @returns {void}
   */
  handleShowScreenContextMenu = e => {
    e.preventDefault();
    contextMenu.show({
      id: 'screenContextMenu',
      event: e,
    });
  };

  handleAnimationState = value => {
    this.setState({
      isAnimated: value,
    });
  };

  resetElementPosition(elementName) {
    const { elements, pushHistory } = this.props;

    pushHistory({ [elementName]: { position: elements[elementName].defaults.position } });
  }

  render() {
    const { configuratorConfig, isAnimated, autoplayAnimation } = this.state;
    const {
      elements,
      activeTemplate,
      configurationsAreFetched,
      pushHistory,
      createHistoryElement,
      getLatestHistoryElement,
      allTemplates,
      updateConfig,
      getHiddenElements,
      showElement,
      updateTemplate,
    } = this.props;

    const { userData } = store.getState();

    if (configurationsAreFetched) {
      const hiddenElements = getHiddenElements();
      const history = getLatestHistoryElement();

      return (
        <Provider
          value={{
            handleAnimationState: this.handleAnimationState,
            isAnimated,
            autoplayAnimation,
          }}
        >
          <Panel>
            <StartDatePicker
              value={configuratorConfig.startDate}
              onChange={value =>
                updateConfig({
                  startDate: value,
                  beginDate: moment().format(),
                })
              }
            />
            <FloatingInput
              text={
                <>
                  <strong>Każde 1,00 PLN</strong>
                  {' dodaje do czasu:'}
                </>
              }
              popupComponent={
                <NumberInput
                  defaultValue={configuratorConfig.extraTime}
                  decimalScale={0}
                  allowEmptyFormatting={false}
                  allowNegative={false}
                  suffix=" sek"
                  isAllowed={({ floatValue }) => floatValue > 0 && floatValue <= 999}
                  onValueChange={({ floatValue }) => {
                    updateConfig({ extraTime: floatValue });
                  }}
                />
              }
            />
            <FloatingInput
              text="Minimalna kwota wpłaty"
              popupComponent={
                <PriceInput
                  defaultValue={configuratorConfig.priceFromAddTime}
                  onChange={value => updateConfig({ priceFromAddTime: value })}
                />
              }
              tooltipContent={
                <>
                  <Text>
                    Funkcja umożliwia zdefiniowanie minimalnej kwoty, która musi zostać przesłana
                    przez Widza, by wpłata mogła zostać dodana do odliczania.
                  </Text>
                  <Text noMargin>
                    Przykładowo: Gdy minimalną kwotą, którą zdefiniujesz będzie 5 PLN, to Widz
                    wysyłając 3 PLN nie zostanie doliczony do odliczania.
                  </Text>
                </>
              }
            />
            <AddTime extraTime={configuratorConfig.extraTime} />
            <FloatingInput
              text={
                <>
                  {'Suby i bitsy z '}
                  <GradientText startColor="#7c23ff" endColor="#8449fe">
                    Twitch
                  </GradientText>
                  {' dodają czas'}
                </>
              }
              popupPosition="right-top-shifted"
              popupComponent={
                <TwitchActivity
                  configuratorConfig={configuratorConfig}
                  updateConfig={updateConfig}
                  userData={userData}
                  dispatchTwitchUnsubscribe={this.unsubscribeTwitchListener}
                />
              }
            />
            <Switcher
              text="Wyświetlaj liczbę dni"
              tooltipContent={
                <Text noMargin>
                  Czas powyżej 24 godzin będzie liczony w dniach. Przykładowo: zamiast 50:20:15
                  licznik wyświetli: 2 dni 02:20:15
                </Text>
              }
              name="show-days-counter"
              value={!!configuratorConfig.showDays}
              onChange={checkboxValue => {
                updateConfig({ showDays: checkboxValue });
              }}
            />
            <Switcher
              text="Dodawaj czas po odliczeniu %"
              tooltipContent={
                <>
                  <Text>
                    Włącz aby odlicznik uwzględniał kwotę pomniejszoną o % prowizji od wpłaty.
                  </Text>
                  <Text noMargin>
                    Przykładowo: Otrzymując wpłatę 10,00 PLN metodą SMS+ której Twoja część wynosi:
                    57% = otrzymasz: 5,70 PLN, doda czas do odlicznika, jakbyś otrzymał 5,70 PLN
                    zamiast pełnych: 10,00 PLN.
                  </Text>
                </>
              }
              name="configurator-mode-counter"
              value={configuratorConfig.addTimeWithoutCommission}
              onChange={checkboxValue => {
                updateConfig({ addTimeWithoutCommission: checkboxValue });
              }}
            />
            <CustomCode template={activeTemplate} onChange={updateTemplate} />
            <ColumnBottomHelp>
              Pamiętaj by przed rozpoczęciem nowej transmisji i gdy odlicznik wskazuje 00:00:00,
              ustawić na nowo “Wybierz czas zakończenia LIVE” niebieskim przyciskiem.
            </ColumnBottomHelp>
          </Panel>
          <div ref={this.configuratorWrapper}>
            <Screen>
              <ActionCatcher
                activeTemplate={activeTemplate}
                onContextMenu={event => {
                  this.handleShowScreenContextMenu(event);
                }}
              />

              <div style={{ width: '100%', height: '100%' }}>
                <Elements
                  activeTemplate={activeTemplate}
                  configuratorType={CONFIGURATOR_TYPE}
                  configuratorConfig={configuratorConfig}
                  configuratorWrapper={this.configuratorWrapper}
                  pushHistory={historyObject => pushHistory(historyObject)}
                  createHistoryElement={() => createHistoryElement()}
                  getLatestHistoryElement={() => getLatestHistoryElement()}
                  updateConfig={updateConfig}
                />
              </div>

              <ScreenContextMenu
                elements={elements}
                hiddenElements={hiddenElements}
                onResetElementPosition={elementName => this.resetElementPosition(elementName)}
                onShowElement={showElement}
                updateScreen={config => this.updateScreen(config)}
              />
            </Screen>
            <ConfigUnderScreen position="start">
              <AnimationManager
                isAnimated={isAnimated}
                onClickControl={type => this.handleAnimationState(type)}
                onChangeAutoplay={autoplayValue => {
                  this.setState({
                    autoplayAnimation: autoplayValue,
                  });
                }}
                autoplayValue={autoplayAnimation}
                autoplayPanel
              />
              <WidgetUrl configuratorType={CONFIGURATOR_TYPE} templates={allTemplates} />
            </ConfigUnderScreen>
            <BottomTip />
          </div>
          <Panel>
            <RoundedInfoWrapper info="Stwórz wiele wyglądów odlicznika, który zmienisz w każdym momencie">
              <TemplatesManager type={CONFIGURATOR_TYPE} />
            </RoundedInfoWrapper>
            <ChangeScreenBackground />

            <Switcher
              text="Korzystaj z Odlicznika"
              name="configurator-mode-counter"
              value={activeTemplate.config.mode === 'COUNTER'}
              onChange={checkboxValue => {
                if (activeTemplate.config.editable) {
                  updateTemplate({ mode: checkboxValue ? 'COUNTER' : 'CLOCK' });
                } else {
                  noEditableAlert();
                }
              }}
            />
            <Switcher
              text="Korzystaj z Zegara"
              name="configurator-mode-clock"
              value={activeTemplate.config.mode === 'CLOCK'}
              tooltipContent={
                <>
                  <Text>
                    Zegar nie odlicza czasu, jedynie wskazuje godzinę zakończenia transmisji.
                  </Text>
                  <Text>
                    Zegar umożliwia wybranie konkretnej godziny transmisji np. godz. 12:00 i każda
                    przychodząca wpłata czy aktywność z Twitch, będzie powodowała przesunięcie
                    Zegara do przodu o skonfigurowany czas.
                  </Text>
                  <Text noMargin>
                    Działając na zasadzie 1 PLN = +1 MIN, oraz przyjmując wpłatę 50,00 PLN, dodasz
                    do odliczania 50 minut, co zegar wyświetli jako godzinę 12:50.
                  </Text>
                </>
              }
              onChange={checkboxValue => {
                if (activeTemplate.config.editable) {
                  updateTemplate({ mode: checkboxValue ? 'CLOCK' : 'COUNTER' });
                } else {
                  noEditableAlert();
                }
              }}
            />

            <Switcher
              text="Animacja przewijania licznika"
              name="configurator-mode-counter"
              value={history.elementsOptions.textInput.isAnimated}
              onChange={checkboxValue => {
                if (activeTemplate.config.editable) {
                  pushHistory({
                    textInput: { isAnimated: checkboxValue },
                  });
                } else {
                  noEditableAlert();
                }
              }}
            />
          </Panel>
        </Provider>
      );
    }

    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchFetchMediaIfNeeded() {
    dispatch(fetchMediaIfNeeded());
  },
});

const mapStateToProps = ({ userData }) => ({
  userDataInfo: userData.info,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfiguration(withHistory(CounterToEndLive), CONFIGURATOR_TYPE));
