import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';
import { Scrollbars } from 'react-custom-scrollbars';
import VirtualizedList from 'react-virtualized/dist/commonjs/List';
import 'react-virtualized/styles.css';

import { ReactComponent as IconFontFamily } from 'assets/icons/font-family.svg';
import { ReactComponent as IconAdd } from 'assets/icons/add.svg';
import * as webfontsActions from 'store/actions/webfonts';
import { showFontManagerToggle } from 'store/actions/mediaManager';
import { TextEditor } from '../Context';
import {
  SecondaryCapsule,
  OptionIcon,
  Option,
  ListItem,
  AbsoluteLayer,
  Input,
  AnimationToggleOption,
} from '../styles';

const TYPE = 'fontFamily';

const List = styled.ul`
  > div {
    padding: 20px 0;
    outline: none;
  }
`;

const ControlsWrapper = styled.div`
  margin-left: 30px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: left;
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: column;

    p {
      color: var(--grey);
      font-size: 10px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 0;
      height: 13px;
      line-height: 13px;
    }

    input {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      height: 20px;
      &::placeholder {
        color: var(--text-grey);
        opacity: 1;
      }
    }
  }
`;

const EditButton = styled.button`
  position: absolute;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 21px;
  width: 313px;
  font-size: 14px;
  transform: translateX(-50%);
  bottom: calc(100% + 14px);
  left: 50%;
  color: var(--text-color);
  border: none;
  transition: var(--hover-transition);
  cursor: pointer;
  padding: 9px 11px;
  background-color: #000000d9;
  border-radius: 76px;
  height: 65px;
  box-sizing: border-box;

  &:active {
    transform: translateX(-50%) scale(0.96);
  }

  > span:first-child {
    width: 47px;
    aspect-ratio: 1/1;
    display: flex;
    place-content: center;
    place-items: center;
    color: var(--text-color);
    background-color: #037cfa;
    border-radius: 50%;
  }

  > span:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    align-items: flex-start;

    span:first-child {
      color: var(--text-color);
      font-size: 14px;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
    }

    span:nth-child(2) {
      color: #adadad;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const FontFamily = ({ webfontsItems, fetchWebfontsIfNeeded, ...props }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [search, setSearch] = useState('');

  const {
    style: { fontFamily },
    updateStyle,
    isActive,
    isNotStyle,
  } = useContext(TextEditor);

  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(showFontManagerToggle());
  };

  const medias = useSelector(state => state.mediaManager.items);
  const userFonts = medias.filter(item => item.provider_name === 'sonata.media.provider.font');

  useEffect(() => {
    fetchWebfontsIfNeeded();
  }, []);

  useEffect(() => {
    const uf = userFonts.map(item => ({
      family: item.name.replace('.ttf', ''),
      custom: true,
    }));

    uf.forEach(item => {
      webfontsItems.unshift(item);
    });

    const jsonObject = webfontsItems.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const noDuplicates = Array.from(uniqueSet).map(JSON.parse);

    dispatch(webfontsActions.allFontsList(noDuplicates));
  }, []);

  const filteredItems = webfontsItems.filter(font =>
    font.family.toLowerCase().startsWith(search.toLowerCase()),
  );

  return (
    <Option {...props}>
      <AnimationToggleOption isInactive={isNotStyle} delayOut={0.05}>
        <OptionIcon isActive>
          <IconFontFamily />
        </OptionIcon>
      </AnimationToggleOption>

      <AbsoluteLayer isActive={isActive(TYPE)} style={{ zIndex: 5, paddingLeft: 20 }}>
        <OptionIcon isActive>
          <IconFontFamily />
        </OptionIcon>

        <EditButton onClick={handleToggle}>
          <span>
            <IconAdd />
          </span>
          <span>
            <span>DODAJ WŁASNĄ CZCIONKĘ</span>
            <span>Po dodaniu, odśwież alert w OBS</span>
          </span>
        </EditButton>

        <ControlsWrapper>
          <div>
            <p>
              {'Używasz: '}
              {fontFamily}
            </p>
            <Input
              type="text"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
              placeholder="Wyszukaj czcionkę..."
              style={{ margin: 0 }}
            />
          </div>
        </ControlsWrapper>
      </AbsoluteLayer>

      <SecondaryCapsule isActive={isActive(TYPE)}>
        <Scrollbars
          style={{ height: 200 }}
          onScroll={({ target: { scrollTop: targetScrollTop } }) => setScrollTop(targetScrollTop)}
        >
          <List>
            <VirtualizedList
              width={350}
              height={200}
              autoHeight
              scrollTop={scrollTop}
              rowCount={filteredItems.length}
              rowHeight={30}
              rowRenderer={({ key, index, style }) => {
                const item = filteredItems[index];

                return (
                  <ListItem
                    key={key}
                    style={style}
                    onClick={() => {
                      updateStyle(TYPE, item.family, true);
                      setSearch('');
                      const customFontsUrl = process.env.REACT_APP_PROXY_URL;

                      if (item.custom) {
                        WebFont.load({
                          custom: {
                            families: [`${item.family}`],
                            urls: [`${customFontsUrl}/user/fonts`],
                          },
                        });
                      } else {
                        WebFont.load({
                          google: {
                            families: [`${item.family}:400,700,900`],
                          },
                        });
                      }
                    }}
                    isActive={fontFamily === item.family}
                  >
                    {item.family}
                  </ListItem>
                );
              }}
            />
          </List>
        </Scrollbars>
      </SecondaryCapsule>
    </Option>
  );
};

FontFamily.propTypes = {
  fetchWebfontsIfNeeded: PropTypes.instanceOf(Function).isRequired,
  webfontsItems: PropTypes.instanceOf(Array).isRequired,
};

export default connect(
  ({ webfonts: { items: webfontsItems } }) => ({ webfontsItems }),
  dispatch => bindActionCreators(webfontsActions, dispatch),
)(FontFamily);
