import React from 'react';

import { NotificationPropTypes } from '../types';
import Paragraph from './Paragraph';

const BankTransferValidationRejectedNotification = ({ notification }) => (
  <div>
    <Paragraph>
      Sprawdziliśmy wniosek o weryfikację danych przelewem bankowym i stwierdziliśmy, że coś się nie
      zgadza. Będziesz musiał spróbować ponownie..
    </Paragraph>
    <Paragraph>Przed tym, sprawdź swoją skrzynkę mailową by uzyskać więcej informacji!</Paragraph>
    <Paragraph>
      {'Dodatkowy komentarz: '}
      {notification.payload.reason}
    </Paragraph>
  </div>
);

export default BankTransferValidationRejectedNotification;

BankTransferValidationRejectedNotification.propTypes = NotificationPropTypes;
