import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const hiddenStyle = css`
  pointer-events: none;
  transform: translateY(-26px);
  opacity: 0;
  z-index: -1 !important;
  ${({ fully }) =>
    fully &&
    css`
      margin: 0 !important;
    `}
`;

const Root = styled.div`
  position: relative;
  transform: translateY(0);
  opacity: 1;
  transition: all 0.25s ease-in-out ${({ delayAppear, hidden }) => !hidden && delayAppear};

  ${({ hidden }) => hidden && hiddenStyle}
`;

const Hideable = props => {
  const { hidden, fully } = props;
  const rootRef = useRef();

  useEffect(() => {
    if (rootRef.current && fully) {
      rootRef.current.style.height = hidden ? 0 : `${rootRef.current.scrollHeight}px`;
    }
  }, [hidden, fully]);

  return <Root ref={rootRef} {...props} />;
};

Hideable.propTypes = {
  children: PropTypes.node.isRequired,
  delayAppear: PropTypes.string,
  hidden: PropTypes.bool,
  fully: PropTypes.bool,
};

Hideable.defaultProps = {
  hidden: false,
  fully: false,
  delayAppear: '',
};

export default Hideable;
