import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ConfigContext from '../ConfigContext';

const StyledButton = styled(Button)`
  height: 36px;
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 12px;

  div {
    display: inline-flex;
    column-gap: 10px;
    align-items: flex-start;
    justify-content: center;
  }

  svg {
    height: 14px;

    path {
      fill: currentColor;
    }
  }
`;

const ANIMATION_DURATION_SECONDS = 1;
const ANIMATIONS = {
  success: 'saved',
  fail: 'failed',
};

const ActionEllipse = styled.span`
  width: 157px;
  height: 81px;
  border-radius: 100%;
  background: #004994;
  position: absolute;
  transform: translate(-50%, -150%);
  left: 50%;
  top: 50%;
  z-index: 0;
  animation-duration: ${ANIMATION_DURATION_SECONDS}s;
  & ~ * {
    position: relative;
    z-index: 1;
  }
`;

// todo: refactor to a normal button
const SaveButton = styled(StyledButton)`
  background-color: var(--blue, #037cfa);
  color: white;
  overflow: hidden;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 110px;
  padding: 0 16px;

  &:not(:disabled):hover {
    box-shadow: 0 0 8px 8px rgba(3, 124, 250, 0.2);
  }

  &:disabled {
    cursor: initial;
  }

  &:active {
    background-color: color-mix(in srgb, var(--brand-blue), #fff 10%);
  }

  @keyframes ellipse-${ANIMATIONS.success} {
    0% {
      transform: translate(-50%, -150%);
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, 150%);
    }
  }

  @keyframes ellipse-${ANIMATIONS.fail} {
     0% {
      transform: translate(-50%, 150%);
    }
    50% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, -150%);
    }
  }

  @keyframes ${ANIMATIONS.success} {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(360deg) scale(1);
    }
    85% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes ${ANIMATIONS.fail} {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(0.8);
    }
    15% {
      transform: scale(0.8) translateX(-15%);
    }
    20% {
      transform: scale(0.8) translateX(15%);
    }
    25% {
      transform: scale(0.8) translateX(-15%);
    }
    30% {
      transform: scale(0.8) translateX(15%);
    }
    35% {
      transform: scale(0.8) translateX(-15%);
    }
    40% {
      transform: scale(0.8) translateX(15%);
    }
    45% {
      transform: scale(0.8) translateX(-15%);
    }
    50% {
      transform: scale(0.8) translateX(15%);
    }
    55% {
      transform: scale(0.8) translateX(-15%);
    }
    60% {
      transform: scale(0.8) translateX(15%);
    }
    65% {
      transform: scale(0.8) translateX(-15%);
    }
    70% {
      transform: scale(0.8) translateX(15%);
    }
    75% {
      transform: scale(0.8) translateX(-15%);
    }
    80% {
      transform: scale(0.8) translateX(15%);
    }
    85% {
      transform: scale(0.8) translateX(0);
    }
    90% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  svg {
    animation-duration: ${ANIMATION_DURATION_SECONDS}s;
  }
`;

const SaveConfigButton = ({ disabled, onClick, ...props }) => {
  const { savingStatus } = useContext(ConfigContext);
  const [result, setResult] = useState('');

  const handleClick = () => {
    onClick();
  };

  useEffect(() => {
    let animationTimeout;
    if (result) {
      animationTimeout = setTimeout(() => {
        setResult('');
      }, ANIMATION_DURATION_SECONDS * 1000);
    }
    return () => {
      clearTimeout(animationTimeout);
    };
  }, [result]);

  useEffect(() => {
    if (savingStatus === 'saved') setResult('success');
  }, [savingStatus]);

  return (
    <SaveButton
      onClick={handleClick}
      style={disabled && !result ? { backgroundColor: '#a5a5a5' } : {}}
      disabled={!!disabled}
      {...props}
    >
      <ActionEllipse style={result ? { animationName: `ellipse-${ANIMATIONS[result]}` } : {}} />
      <SaveIcon style={result ? { animationName: ANIMATIONS[result] } : {}} />
      <span style={{ transform: `translateY(1px)` }}>Zapisz</span>
    </SaveButton>
  );
};

SaveConfigButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.instanceOf(Function).isRequired,
};

SaveConfigButton.defaultProps = {
  disabled: false,
};

export default SaveConfigButton;
