import styled from 'styled-components';

export default styled.span`
  width: 180px;
  height: 55px;
  border-radius: 30px;
  background: #242526;
  border: 2px solid var(--border-color);

  &:hover {
    border: 2px solid var(--brand-blue);
  }
`;
