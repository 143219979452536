import styled, { css } from 'styled-components';

const minus = css`
  color: var(--error-color);

  svg {
    fill: var(--error-color);
    transform: rotate(180deg) translateY(-3px);
  }
`;

const zero = css`
  color: var(--text-color-light-gray);

  svg {
    fill: var(--text-color-light-gray);
    transform: rotate(90deg) translate(2px, 2px);
  }
`;

export default styled.div`
  display: flex;
  font-family: var(--font-lato);
  font-size: 14px;
  color: var(--green-plus);
  margin: 0 0 5px 10px;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  svg {
    transform: rotate(0) translateY(2px);
    transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    height: 12px;
    fill: var(--green-plus);
    margin-right: 3px;
  }

  ${props => props.minus && minus};
  ${props => props.zero && zero};
`;
