import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './components/Wrapper';

export default class FloatingMenu extends Component {
  static propTypes = {
    parent: PropTypes.instanceOf(Element).isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.instanceOf(Function).isRequired,
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = e => {
    const { onClose, visible, parent } = this.props;

    if (parent.contains(e.target) || !visible) {
      return;
    }

    onClose();
  };

  render() {
    const { children, visible, position, ...props } = this.props;

    return (
      <Wrapper {...props} visible={visible} position={position}>
        {children}
      </Wrapper>
    );
  }
}
