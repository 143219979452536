import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ClearButton } from 'components/styled';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

const themes = {
  dark: css`
    background-color: var(--gray);
  `,
  light: css`
    background-color: var(--light-gray);
  `,
};

const Button = styled(ClearButton)`
  line-height: 0;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  color: currentColor;

  svg {
    width: 3px;
  }

  ${props => themes[props.theme]}
`;

export const InfoTooltip = ({ content, placement, theme, trigger, arrow, ...props }) => (
  <Tooltip content={content} placement={placement} theme={theme} trigger={trigger} arrow={arrow}>
    <Button theme={theme} {...props}>
      <InfoIcon />
    </Button>
  </Tooltip>
);

InfoTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.string,
  theme: PropTypes.string,
  trigger: PropTypes.string,
  arrow: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  placement: 'top',
  theme: 'dark',
  trigger: 'click',
  arrow: true,
};
