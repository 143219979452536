import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import keycode from 'keycode';
import faker from 'faker/locale/en';
import { Marquee } from '@tipply/marquee';

import priceFormatter from 'utils/priceFormatter';
import { mapElementsObjectToArray } from 'pages/Configurators/utils';
import { RangeEditor, TextEditor } from 'components/ElementEditor';
import { ReactComponent as PulseIcon } from 'assets/icons/pulse.svg';

import { MARQUEE_MAX_SPEED, MARQUEE_MIN_SPEED } from 'store/constants';
import { setCurrentElement } from 'store/actions/templates';

import FocusedElement from '../../../../components/FocusedElement';
import ContextMenuElement from '../../../../components/ContextMenuElement';

import { Consumer as ListViewContextConsumer } from '../../Context';

import { AlignSubmenu } from '../../../../components/AlignPopup';

const DirectionWrapper = styled.div`
  display: inline-grid;
  justify-content: start;
  padding: 19px 23px;
  grid-gap: ${props => props.spacing}px;
  grid-auto-flow: ${props => (props.vertical ? 'row' : 'column')};
  ${props =>
    props.vertical
      ? {
          width: '100%',
          gridTemplateColumns: '1fr',
        }
      : ''}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: ${props => props.align};

  span {
    &[data-type='colon'] {
      margin: 0 10px 0 0;
    }

    &[data-type='counter'] {
      margin: 0 5px 0 0;
    }
  }
`;

const ElementWrapper = styled.span`
  display: inline-block;
  outline: none;
`;

const elementProps = {
  counter: {
    value: '1.',
    menuOptions: ['editText', 'delete'],
  },
  price: {
    value: '2,00zł',
    menuOptions: ['editText'],
  },
  colon: {
    value: ':',
    menuOptions: ['editText', 'delete'],
  },
  nickname: {
    value: 'Tipply',
    menuOptions: ['editText', 'delete'],
  },
};

const elementsOrder = ['counter', 'nickname', 'colon', 'price'];

const ListItem = ({
  elementName,
  options,
  onSelectedOption,
  onDoubleClick,
  onKeyDown,
  index,
  isAnimated,
  isEditable,
  withAlignContextMenu,
  currentAlignment,
}) => {
  if (options.isVisible) {
    const ref = useRef();
    const [value, setValue] = useState('');

    useEffect(() => {
      switch (elementName) {
        case 'counter':
          setValue(`${index + 1}.`);
          break;
        case 'nickname':
          setValue(faker.name.firstName());
          break;
        case 'price':
          setValue(
            priceFormatter(
              faker.random.number({
                min: 100,
                max: 2500,
              }),
            ),
          );
          break;
        default:
          setValue(elementProps[elementName].value);
          break;
      }
    }, [elementName, index]);

    const contextMenuCustomOptions = () => {
      const defaultCustomOptions = [
        {
          label: isAnimated ? 'Wyłącz animację przewijania' : 'Włącz animację przewijania',
          value: 'animation',
        },
        {
          label: 'Dostosuj szybkość przewijania',
          value: 'marquee-speed',
        },
      ];

      if (withAlignContextMenu) {
        return defaultCustomOptions.concat([
          <AlignSubmenu
            onSelectedOption={option => onSelectedOption(option, ref.current)}
            current={currentAlignment}
            key="alignSubmenu"
          >
            Zmień wyrównanie
          </AlignSubmenu>,
        ]);
      }

      return defaultCustomOptions;
    };

    return (
      <ListViewContextConsumer>
        {({ enableAnimation }) => {
          const element = () => (
            <ElementWrapper
              ref={ref}
              data-type={elementName}
              style={{ ...options.styles }}
              onDoubleClick={() => onDoubleClick(ref.current)}
            >
              {value}
            </ElementWrapper>
          );

          if (enableAnimation) {
            return element();
          }

          return (
            <FocusedElement onKeyDown={onKeyDown}>
              <ContextMenuElement
                id={`${elementName}-element-list-${index}`}
                elementName={elementName}
                options={elementProps[elementName].menuOptions}
                onSelectedOption={option => onSelectedOption(option, ref.current)}
                customOptions={contextMenuCustomOptions()}
                isEditable={isEditable}
              >
                {element()}
              </ContextMenuElement>
            </FocusedElement>
          );
        }}
      </ListViewContextConsumer>
    );
  }

  return null;
};

ListItem.propTypes = {
  elementName: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  onSelectedOption: PropTypes.instanceOf(Function),
  onDoubleClick: PropTypes.instanceOf(Function),
  onKeyDown: PropTypes.instanceOf(Function),
  index: PropTypes.number.isRequired,
  isAnimated: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  withAlignContextMenu: PropTypes.bool.isRequired,
  currentAlignment: PropTypes.string.isRequired,
};

ListItem.defaultProps = {
  onSelectedOption: ref => {},
  onDoubleClick: ref => {},
  onKeyDown: () => {},
};

const List = ({ options, updateElement, configuratorWrapper, activeTemplate }) => {
  const dispatch = useDispatch();
  const [marqueeEditorMounted, mountMarqueeEditor] = useState(null);
  const [textEditorMounted, setTextEditorMounted] = useState({
    counter: false,
    colon: false,
    price: false,
    nickname: false,
  });

  const elements = mapElementsObjectToArray(options.children, elementsOrder);

  return (
    <ListViewContextConsumer>
      {({ updateTemplate, enableAnimation }) => {
        const isVerticalList = activeTemplate.config.displayDirection === 'vertical';

        /**
         * @param {string} option
         * @param {HTMLElement} elementRef
         * @param {string} elementName
         *
         * @returns {void}
         */
        const handleElementOptionClick = (option, elementRef, elementName) => {
          switch (option) {
            case 'editText':
              setTextEditorMounted({ ...textEditorMounted, [elementName]: elementRef });
              dispatch(setCurrentElement(elementName));
              break;
            case 'delete':
              updateElement({
                list: { children: { [elementName]: { isVisible: false } } },
              });
              break;
            case 'animation':
              updateTemplate({
                animation: { enable: !activeTemplate.config.animation.enable },
              });
              break;
            case 'marquee-speed':
              mountMarqueeEditor(elementRef);
              break;
            case 'flex-start':
            case 'center':
            case 'flex-end':
              updateElement({
                list: { align: option },
              });
              break;
            default:
          }
        };

        const renderItems = () => {
          const items = [];
          const align = isVerticalList ? options.align : 'start';

          for (let i = 0; i < activeTemplate.config.numberDisplayedItems; i += 1) {
            items.push(
              <Wrapper key={i} align={align} className="single-element">
                {elements.map(([elementName, elementOptions]) => (
                  <ListItem
                    index={i}
                    key={elementName}
                    elementName={elementName}
                    options={elementOptions}
                    focused={textEditorMounted[elementName]}
                    onDoubleClick={elementRef =>
                      setTextEditorMounted({ ...textEditorMounted, [elementName]: elementRef })
                    }
                    onSelectedOption={(option, elementRef) =>
                      handleElementOptionClick(option, elementRef, elementName)
                    }
                    onKeyDown={event => {
                      const keyCode = keycode(event);

                      if (keyCode === 'delete') {
                        updateElement({
                          list: { children: { [elementName]: { isVisible: false } } },
                        });
                      }
                    }}
                    isAnimated={activeTemplate.config.animation.enable}
                    isEditable={activeTemplate.config.editable}
                    withAlignContextMenu={isVerticalList}
                    currentAlignment={options.align}
                  />
                ))}
              </Wrapper>,
            );
          }

          return items;
        };

        const itemsWrapper = () => (
          <DirectionWrapper
            vertical={isVerticalList}
            spacing={activeTemplate.config.spacingBetweenElements}
          >
            {renderItems()}
          </DirectionWrapper>
        );

        if (enableAnimation) {
          return (
            <div
              style={{
                top: options.position.y,
                left: options.position.x,
                position: 'absolute',
                width: '100%',
              }}
            >
              <Marquee
                duration={
                  activeTemplate.config.numberDisplayedItems *
                  (MARQUEE_MAX_SPEED +
                    MARQUEE_MIN_SPEED -
                    activeTemplate.config.animation.durationMultiply)
                }
                gap={activeTemplate.config.spacingBetweenElements}
                direction={activeTemplate.config.displayDirection}
              >
                {itemsWrapper()}
              </Marquee>
            </div>
          );
        }

        return (
          <>
            {itemsWrapper()}

            {elements.map(([elementName, elementOptions]) => (
              <TextEditor
                key={elementName}
                isMounted={!!textEditorMounted[elementName]}
                snapTo={textEditorMounted[elementName] || document.body}
                defaultStyle={elementOptions.styles}
                onChange={styles =>
                  updateElement({ list: { children: { [elementName]: { styles } } } })
                }
                onHide={() => setTextEditorMounted({ ...textEditorMounted, [elementName]: false })}
                showIn={configuratorWrapper.current}
                disableTextAlign
                unmountDelay={0}
                mode="style"
              />
            ))}

            {configuratorWrapper.current && (
              <RangeEditor
                isMounted={!!marqueeEditorMounted}
                onHide={() => mountMarqueeEditor(false)}
                icon={<PulseIcon />}
                min={1}
                max={10}
                value={activeTemplate.config.animation.durationMultiply}
                valueDivide={10}
                showIn={configuratorWrapper.current}
                snapTo={marqueeEditorMounted}
                rangeValuePrefix=""
                onConfirm={val =>
                  updateTemplate({
                    animation: { durationMultiply: val },
                  })
                }
              />
            )}
          </>
        );
      }}
    </ListViewContextConsumer>
  );
};

List.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  configuratorWrapper: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
};

export default List;
