import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Text from 'components/Text';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';

import { GoalsPageContainer, Intro, NewGoalContainer } from './components';
import Goal from './components/Goal';

export const Goals = ({
  goals,
  ready,
  working,
  dispatchCreateGoal,
  fetchDataIfNeeded,
  votingGoals,
  fetchVotingConfig,
}) => {
  useEffect(() => {
    fetchDataIfNeeded();
    fetchVotingConfig();
  }, []);

  const handleCreate = () => {
    dispatchCreateGoal();
  };

  const goalInVoting = goalId => votingGoals.includes(goalId);

  if (!ready) {
    return null;
  }

  return (
    <GoalsPageContainer>
      <Intro working={working} />
      {goals.map((goal, index) => (
        <Goal key={goal.id} index={index} data={goal} inVoting={goalInVoting(goal.id)} />
      ))}
      <NewGoalContainer>
        <Button
          icon={<Plus />}
          background="blue"
          onClick={handleCreate}
          disabled={goals.length >= 10 || working}
        >
          Dodaj kolejny cel
        </Button>
        <Text size={12} noMargin>
          <strong>Uwaga</strong>
          {': Posiadając więcej niż jeden cel wszystkie cele będą widoczne na '}
          Twojej stronie wpłat i widzowie będą mogli wybrać na który dokładnie cel chcą dokonać
          płatności!
        </Text>
      </NewGoalContainer>
    </GoalsPageContainer>
  );
};

Goals.propTypes = {
  goals: PropTypes.arrayOf(Object).isRequired,
  ready: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  dispatchCreateGoal: PropTypes.instanceOf(Function).isRequired,
  fetchDataIfNeeded: PropTypes.instanceOf(Function).isRequired,
  votingGoals: PropTypes.arrayOf(Object).isRequired,
  fetchVotingConfig: PropTypes.instanceOf(Function).isRequired,
};
