import isNumber from 'is-number';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import http from 'utils/http';

import { openAlert } from 'components/Alert/utils';
import InfoTooltip from 'components/InfoTooltip';
import { TinyActionButton } from 'components/Buttons';
import Text from 'components/Text';

import Box from '../../../styles/Box';

import NumberInput from '../../../components/TopDonators/styles/NumberInput';

const Wrapper = styled(Box)`
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  display: grid;
  grid-row-gap: 12px;
  grid-template-columns: 1fr;
`;

const Row = styled.div`
  display: grid;
  column-gap: 13px;
  align-items: center;
  width: 100%;
`;

const AddTimeRow = styled(Row)`
  white-space: nowrap;
  grid-template-columns: auto 1fr 38px;
`;

const AddMoneyRow = styled(Row)`
  grid-template-columns: 1fr 110px;
`;

const InfoBox = styled.div`
  p {
    font-size: 12.08px;
    margin: 0;
    line-height: 16.67px;
  }

  .grey {
    color: var(--gray);

    span {
      color: var(--text-color-blue);
      text-decoration: underline;
    }
  }
`;

const AddTime = ({ extraTime }) => {
  const [minutes, setMinutes] = useState(0);
  const [money, setMoney] = useState(0);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setMinutes((money * extraTime) / 60);
  }, [money]);

  useEffect(() => {
    setMoney(minutes / (extraTime / 60));
  }, [minutes]);

  const onClick = async () => {
    setWorking(true);
    try {
      await http.post('/user/countdown/time', {
        value: minutes * 60,
      });
      setMinutes(0);
    } catch (e) {
      openAlert('error', 'Błąd zapisu', 'Ups, nie mogę dodać czasu, spróbuj ponownie później 🥲');
    }
    setWorking(false);
  };

  const isValid = () => isNumber(minutes) && minutes > 0;

  return (
    <Wrapper>
      <AddTimeRow>
        <div>Dodaj czas:</div>
        <NumberInput
          defaultValue={minutes}
          prefix="+"
          suffix=" min"
          style={{ textAlign: 'center' }}
          onValueChange={({ floatValue }) => setMinutes(floatValue)}
          decimalScale={0}
          disabled={working}
          variant="tiny"
        />
        <TinyActionButton
          onClick={onClick}
          disabled={working || !isValid()}
          working={working}
          label="Dodaj"
        >
          <PlusIcon />
        </TinyActionButton>
      </AddTimeRow>
      <InfoBox>
        <p>
          <span style={{ position: 'relative' }}>
            Chcesz przedłużyć trwającego streama?
            <InfoTooltip
              style={{ position: 'absolute', top: -10, right: -20 }}
              content={
                <>
                  <Text>
                    Dodaj czas, lub dodaj kwotę do trwającego odliczania. Wpisując kwotę, system
                    automatycznie przeliczy kwotę na czas i doda go do odliczania.
                  </Text>
                  <Text noMargin>
                    Przykładowo: Ustawiając zasadę 1 PLN = +1 MIN, a następnie wpisując kwotę 5 PLN
                    w rubryce, dodasz 5 minut do odliczania.
                  </Text>
                </>
              }
              placement="right"
              theme="dark"
            />
          </span>
        </p>
        <p className="grey">
          <span>Zrób to w tym miejscu!</span>
          {' Tutaj nic nie usuniesz. Ani dodanych już wpłat, ani subów z Twitcha.'}
        </p>
      </InfoBox>
      <AddMoneyRow>
        <div>
          Lub dodaj kwotę
          <br />
          przeliczoną na czas:
        </div>
        <NumberInput
          defaultValue={money}
          prefix="+"
          suffix=" PLN"
          style={{ textAlign: 'center' }}
          onValueChange={({ floatValue }) => setMoney(floatValue)}
          decimalScale={2}
          disabled={working}
          variant="tiny"
        />
      </AddMoneyRow>
    </Wrapper>
  );
};

AddTime.propTypes = {
  extraTime: PropTypes.number.isRequired,
};

export default AddTime;
