import { useReducer, createContext } from 'react';
import { convertToGrosze } from 'utils/priceConverter';

const defaults = {
  activeType: null,
  values: { 'transfer-type': 0, 'sms-type': 0 },
  customAmountValue: convertToGrosze(0),
  customAmountEnabled: false,
};

const paymentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TYPE':
      return {
        ...state,
        activeType: action.payload,
      };
    case 'SET_CUSTOM_AMOUNT_VALUE':
      return {
        ...state,
        customAmountValue: action.payload,
      };
    case 'SET_CUSTOM_AMOUNT_ENABLED':
      return {
        ...state,
        customAmountEnabled: action.payload,
      };
    default:
      return state;
  }
};

const usePaymentState = () => {
  const [state, rootDispatch] = useReducer(paymentReducer, defaults);
  const dispatch = (type, payload) => rootDispatch({ type, payload });

  return [state, dispatch];
};

export const Context = createContext();
export default usePaymentState;
