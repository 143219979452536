import styled, { css } from 'styled-components';

import Element from './Element';

const align = {
  left: css``,
  center: css`
    > span {
      &:nth-child(1),
      &:nth-child(2) {
        left: 55px;
      }

      &:nth-child(3) {
        left: 61px;
      }
    }
  `,
  right: css`
    > span {
      &:nth-child(1),
      &:nth-child(2) {
        left: calc(var(--wrapper-width) - var(--line-width));
      }

      &:nth-child(3) {
        left: calc(var(--wrapper-width) - var(--line-width-small));
      }
    }
  `,
};

export default styled(Element)`
  position: absolute;
  width: 0;
  left: 0;
  padding: 0;

  > span {
    left: 0;

    &:nth-child(1) {
      transition: 0.4s ease;
    }

    &:nth-child(2) {
      transition: 0.6s ease;
    }

    &:nth-child(3) {
      transition: 0.8s ease;
    }
  }

  ${props => props.align && align[props.align]};
`;
