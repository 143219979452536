import styled from 'styled-components';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  gap: 20px;

  &.no-margin {
    margin-bottom: 0;
  }

  .row {
    display: grid;
    column-gap: 14px;
    grid-template-columns: 1fr 2fr;

    &.half-half {
      grid-template-columns: 1fr 1fr;
    }

    .cell {
      position: relative;

      &.wide {
        min-width: 270px;
      }
    }
  }
`;

export default FormWrapper;
