import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';
import { alert } from 'components/Alert';
import getUserAvatar from 'utils/getUserAvatar';

import { ReactComponent as GooglePlusIcon } from 'assets/icons/google-plus.svg';

import WhiteBox from 'styles/WhiteBox';

import styled from 'styled-components';
import AvatarWrapper from './styles/AvatarWrapper';
import Button from './styles/Button';
import AvatarButton from './styles/AvatarButton';

import { Consumer } from '../../Context';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import serverAlert from '../../../../store/utils/serverAlert';
import MediaManager from '../../../../components/MediaManager';

const EditButton = styled.div`
  transform: translate(200px, -140px);
`;

const Avatar = ({ syncGoogleAvatar, mediumType, onSelectedItem, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  return (
    <Consumer>
      {({ userDataProfile, deleteUserMedia }) => {
        // Ustawianie avatara użytkownika
        const userAvatar = getUserAvatar(userDataProfile);

        return (
          <WhiteBox style={{ padding: '30px 25px', marginTop: '15px', height: '433px' }}>
            <Text color="blue" weight="bold" size={15} margin="0 0 5px">
              Twoje zdjęcie profilowe
            </Text>
            <Text color="light-gray" size={11} margin="0 0 20px">
              Wymagana rozdzielczość: 512x512
            </Text>

            <AvatarWrapper
              style={{ backgroundImage: userAvatar ? `url(${userAvatar})` : userAvatar }}
            />
            <Button
              loading={loading}
              disabled={loading}
              type="button"
              background="gray"
              noWrapper
              onClick={async () => {
                if (!loading) {
                  setLoading(true);

                  try {
                    await syncGoogleAvatar();

                    // Jeżeli wcześniej był ustawiony był własny avatar przez użytkownika to usuwamy go.
                    if (userDataProfile.avatar) {
                      await deleteUserMedia('avatar');
                    }
                  } catch (error) {
                    alert.open({
                      config: {
                        type: 'error',
                        title: 'Wystąpił błąd',
                        description: <span>Avatar nie został podmieniony.</span>,
                        confirmButtonText: 'Zamknij',
                      },
                    });
                  }

                  setLoading(false);
                }
              }}
            >
              <GooglePlusIcon style={{ opacity: loading ? 0 : 1 }} />
              <span>Pobierz avatar z konta Google</span>
            </Button>
            <EditButton>
              <AvatarButton
                background="gray"
                type="button"
                onClick={() => setMediaManagerVisibility(true)}
                loading={addLoading}
                disabled={addLoading}
              >
                <EditIcon width="20" fill="var(--text-color-dark-gray)" />
              </AvatarButton>
            </EditButton>
            <MediaManager
              allowedTypes={['image']}
              onClose={() => setMediaManagerVisibility(false)}
              onSelectedItem={async item => {
                setAddLoading(true);
                setMediaManagerVisibility(false);
                try {
                  await onSelectedItem(mediumType, item);
                } catch (error) {
                  serverAlert(error.response.data.message);
                }
                setAddLoading(false);
              }}
              isMounted={mediaManagerVisibility}
            />
          </WhiteBox>
        );
      }}
    </Consumer>
  );
};

Avatar.propTypes = {
  syncGoogleAvatar: PropTypes.instanceOf(Function).isRequired,
  mediumType: PropTypes.string.isRequired,
  onSelectedItem: PropTypes.instanceOf(Function).isRequired,
};

export default Avatar;
