import styled from 'styled-components';

export default styled.span`
  width: 190px;
  height: 55px;
  border-radius: 30px;
  background: #3a3b3c;
  border: 2px solid var(--border-color);
  margin-right: 10px;

  &:hover {
    border: 2px solid var(--brand-blue);
  }
`;
