import styled, { css } from 'styled-components';

export const Input = styled.input`
  display: block;
  width: 100%;
  margin-top: 2px;
  padding: 12px 21px;
  font-family: var(--google-sora-cdn);
  font-size: 12px;
  line-height: 1.58;
  color: var(--text-color);
  border-radius: 20px;
  border: solid 0.5px #a9a9a9;
  background-color: var(--bg-color-text-input);
`;

export const Label = styled.label`
  font-family: var(--gilroy-font);
  font-size: 10px;
  text-transform: uppercase;
  color: var(--text-color);
`;

export const FieldError = styled.div`
  font-size: 10px;
  color: var(--red);
  margin-top: 4px;
`;

export const BackendError = styled.div`
  text-align: center;
  font-weight: bold;
  color: var(--red);
  margin-top: -25px;
  margin-bottom: 22px;
`;

export const InputWrapper = styled.div`
  margin: 0 auto 20px;
  max-width: 271px;

  ${props =>
    props.hasError &&
    css`
      ${Label} {
        color: var(--red);
      }

      ${Input} {
        border-color: var(--red);
        color: var(--red);
      }
    `};
`;
