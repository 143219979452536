import React from 'react';
import PropTypes from 'prop-types';
import StyledNotifyButton from '../styles/StyledNotifyButton';

const NotifyButton = React.forwardRef(({ children, notify, handleClick, width, ...props }, ref) => (
  <StyledNotifyButton
    {...props}
    ref={ref}
    width={width}
    notify={notify}
    onClick={() => handleClick()}
  >
    {children}
  </StyledNotifyButton>
));

NotifyButton.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  notify: PropTypes.bool,
  handleClick: PropTypes.instanceOf(Function),
  width: PropTypes.string,
  theme: PropTypes.string,
};

NotifyButton.defaultProps = {
  notify: false,
  handleClick: () => {},
  width: '22px',
  theme: 'default',
};

export default NotifyButton;
