import React from 'react';
import { alert } from './index';

export const openAlert = (type, title, description, config = {}, onConfirm = () => {}) => {
  alert.open({
    config: {
      type,
      title,
      description: <span>{description}</span>,
      ...config,
    },
    events: {
      onConfirm: () => {
        onConfirm();
      },
    },
  });
};
