import styled from 'styled-components';

import Button from 'styles/Button';

export default styled(Button)`
  font-family: var(--google-sora-cdn);
  font-size: 12.5px;
  width: 70%;
  border-radius: 30px;
  background-color: ${props => props.background};
  transition: var(--hover-transition);

  &:hover {
    background-color: ${props => props.background};
    box-shadow: 0 0 26px 0 ${props => props.background};
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    opacity: 0.8;
  }
`;
