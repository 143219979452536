import http from 'utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const requestReports = () => ({
  type: 'REQUEST_REPORTS',
});

const receiveReports = receivedData => ({
  type: 'RECEIVE_REPORTS',
  receivedData,
});

const failureReports = () => ({
  type: 'FAILURE_REPORTS',
});

// Todo paginacja

const fetchReports = () => async dispatch => {
  dispatch(requestReports());

  try {
    const reports = await http.get('/user/reports');
    dispatch(receiveReports(reports.data));
  } catch (error) {
    dispatch(failureReports());
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchReportsIfNeeded = () => (dispatch, getState) => {
  const { reports } = getState();

  if (shouldFetchData(reports)) {
    dispatch(fetchReports());
  }
};
