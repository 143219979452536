import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ReactComponent as ListIcon } from 'assets/icons/mainmenu/list.svg';
import { ReactComponent as TrashBin } from 'assets/icons/trashbin.svg';
import MenuItem from './components/MenuItem';

const MenuItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
`;

const ActiveBar = styled.div`
  height: 7px;
  width: calc(100% - 20px);
  position: absolute;
  left: 10px;
  background: var(--bg-color-light-secondary-gray);
  border-radius: 50px;

  > div {
    height: 100%;
    background: var(--brand-green);
    border-radius: 40px;
    width: calc(16.666% - 15px);
    position: absolute;
    left: 0;
    transition: 600ms ease-in-out;
  }
`;

class ModeratorMenu extends Component {
  static propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
    moderatorId: PropTypes.instanceOf(Object).isRequired,
  };

  state = {
    offsetLeft: 0,
    currentBarWidth: 16.666666666666668,
  };

  pointsAndBonusesRef = React.createRef();

  menuItemsRef = React.createRef();

  componentDidMount() {
    this.setState({
      offsetLeft: this.calculateOffset(),
      currentBarWidth: this.calculateCurrentBarWidth(),
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentWillUnmount() {}

  onRouteChanged() {
    this.updateActiveOffset();
  }

  /**
   * Funkcja aktualizująca położenie paska, który wskazuje aktywny element menu
   */
  updateActiveOffset = () => {
    this.setState({ offsetLeft: this.calculateOffset() });
  };

  /**
   * Funkcja obliczająca położenie paska, który wskazuje aktywny element menu
   *
   * @returns {number}
   */
  calculateOffset() {
    const activeEl = this.menuItemsRef.current.querySelector(`a.active`);

    if (activeEl) {
      return activeEl.offsetLeft;
    }

    return 0;
  }

  /**
   * Funkcja obliczająca szerokość paska, który wskazuje aktywny element menu
   *
   * @returns {number}
   */
  calculateCurrentBarWidth() {
    return 100 / this.menuItemsRef.current.children.length;
  }

  render() {
    const { offsetLeft, currentBarWidth } = this.state;
    const { moderatorId } = this.props;
    const panelurl = `/panel-moderatora/${moderatorId.moderatorId}`;
    const basketurl = `/koszyk-moderatora/${moderatorId.moderatorId}`;

    return (
      <div style={{ position: 'relative' }}>
        <MenuItems ref={this.menuItemsRef} className="main-menu">
          <MenuItem to={panelurl}>
            <ListIcon width="22px" height="22px" />
            <span>Lista wiadomości</span>
          </MenuItem>
          <MenuItem to={basketurl}>
            <TrashBin width="22px" height="22px" />
            <span>Wiadomości odrzucone</span>
          </MenuItem>
        </MenuItems>
        <ActiveBar>
          <div style={{ left: offsetLeft, width: `calc(${currentBarWidth}% - 15px)` }} />
        </ActiveBar>
      </div>
    );
  }
}

export default withRouter(ModeratorMenu);
