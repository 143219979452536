import styled, { css } from 'styled-components';

const align = {
  center: css`
    margin: var(--margin-tb);
  `,
  right: css`
    margin: var(--margin-tb) 11px;
  `,
  left: css`
    margin: var(--margin-tb) 0;
  `,
};

export default styled.span`
  --margin-tb: 6px;

  position: relative;
  display: block;

  width: ${props => (props.short ? '13px' : '25px')};
  height: 3px;

  background: ${props => (props.black ? 'var(--brand-green)' : 'var(--gray)')};
  border-radius: 30px;

  ${props => (props.align ? align[props.align] : 'margin: 6px 0;')};
`;
