import React from 'react';
import PropTypes from 'prop-types';

import { ButtonWithAnimatedArrow } from 'components/Buttons';

import { StepContentWrapper } from '../components/StepContent';
import BackButton from '../components/BackButton';
import SelectedPlatform from '../components/SelectedPlatform';

const Step3 = ({ provider, processStep, goPrev }) => (
  <StepContentWrapper fixedHeight>
    <div className="content">
      <SelectedPlatform provider={provider} variant="large" />
    </div>
    <div className="footer">
      <ButtonWithAnimatedArrow onClick={() => processStep(true)}>
        POMIŃ TUTORIAL
      </ButtonWithAnimatedArrow>
      <ButtonWithAnimatedArrow onClick={() => processStep(false)} earlyHoverDetect={false}>
        AKTYWUJĘ METODĘ PO RAZ PIERWSZY
      </ButtonWithAnimatedArrow>
      <BackButton onClick={goPrev}>Wstecz</BackButton>
    </div>
  </StepContentWrapper>
);

export default Step3;

Step3.propTypes = {
  provider: PropTypes.string.isRequired,
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
