import styled from 'styled-components';

export default styled.div`
  padding: 0;

  > span {
    display: inline-block;
    margin: 0 0 15px;
  }
`;
