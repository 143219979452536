import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  position: relative;
  width: auto;
  height: 30px;

  padding: 0 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 100px;
  transition: var(--hover-transition);
  box-shadow: none;

  &::before {
    width: 150px;
    height: 150px;
  }

  &:active {
    transform: scale(0.96);
  }
`;
