/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import calculateBorderRadius from 'utils/calculateBorderRadius';

import { stripesStyles } from 'styles/Stripes';

import ColorPicker from 'components/ColorPicker';

import ContextMenuElement from '../../../../../components/ContextMenuElement';
import DragWrapper from '../../../../../components/DragWrapper';
import { Consumer as DragConsumer } from '../../../../../components/DragWrapper/Context';

const StyledProgressBar = styled.div`
  --stripesColor: ${props => (props.isLight ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)')};

  width: 100%;
  height: 100%;
  overflow: hidden;

  > div {
    width: 10%;
    height: 100%;

    ${props => props.stripes && stripesStyles}
  }
`;

const ProgressBar = ({
  activeTemplate,
  options,
  updateElement,
  configuratorType,
  configuratorWrapper,
  onKeyDown,
}) => {
  const elementRef = useRef(null);
  const [colorPickerMounted, setColorPickerMounted] = useState({
    enable: false,
    color: '',
    color2: '',
  });

  let currentGradient = options.styles.gradientColor;
  if (colorPickerMounted.enable === 'progressColor') {
    currentGradient = options.gradientColor;
  }

  return (
    <>
      <DragWrapper
        updateElement={updateElement}
        elementOptions={options}
        elementName="progressBar"
        configuratorType={configuratorType}
        isResizable
        resizeMinConstraints={[20, 20]}
        onKeyDown={event => onKeyDown(event, 'progressBar')}
      >
        <DragConsumer>
          {({ size }) => {
            const progressBarStyles = {
              backgroundColor: options.styles.backgroundColor,
              borderRadius: calculateBorderRadius(size.height, options.styles.borderRadius),
            };

            const progressBarInnerStyle = {
              backgroundColor: options.progressColor,
            };

            if (options.styles.gradientColor) {
              progressBarStyles.backgroundImage = `linear-gradient(${options.styles.backgroundColor}, ${options.styles.gradientColor})`;
              progressBarInnerStyle.backgroundImage = `linear-gradient(${options.progressColor}, ${options.gradientColor})`;
            }

            return (
              <ContextMenuElement
                id="progressBar-element-list"
                elementName="progressBar"
                options={['delete']}
                customOptions={[
                  {
                    label: 'Edytuj kolor paska',
                    value: 'progressColor',
                  },
                  {
                    label: 'Edytuj kolor tła',
                    value: 'backgroundColor',
                  },
                  {
                    label: options.stripes ? 'Wyłącz animację w paski' : 'Włącz animację w paski',
                    value: 'stripes',
                  },
                ]}
                isEditable={activeTemplate.config.editable}
                onSelectedOption={option => {
                  if (option === 'stripes') {
                    updateElement({ progressBar: { stripes: !options.stripes } });
                  } else if (option === 'delete') {
                    updateElement({ progressBar: { isVisible: false } });
                  } else if (option === 'progressColor') {
                    setColorPickerMounted(prevState => ({
                      ...prevState,
                      enable: 'progressColor',
                      color: options.progressColor,
                    }));
                  } else if (option === 'backgroundColor') {
                    setColorPickerMounted(prevState => ({
                      ...prevState,
                      enable: 'backgroundColor',
                      color: options.styles.backgroundColor,
                    }));
                  }
                }}
              >
                <StyledProgressBar
                  ref={elementRef}
                  stripes={options.stripes}
                  isLight={tinycolor(options.progressColor).isLight()}
                  style={progressBarStyles}
                >
                  {options.stripes && (
                    <div
                      style={{
                        backgroundColor: options.progressColor,
                      }}
                    />
                  )}
                  {!options.stripes && <div style={progressBarInnerStyle} />}
                </StyledProgressBar>
              </ContextMenuElement>
            );
          }}
        </DragConsumer>
      </DragWrapper>

      <ColorPicker
        isMounted={!!colorPickerMounted.enable}
        snapTo={elementRef.current || document.body}
        defaultColor={colorPickerMounted.color}
        gradientColor={currentGradient}
        stripes={options.stripes}
        pickerEnabled={colorPickerMounted.enable}
        onChange={(color, color2) => {
          if (colorPickerMounted.enable === 'backgroundColor') {
            updateElement({
              progressBar: {
                styles: { backgroundColor: color, gradientColor: color2 },
              },
            });
          } else if (colorPickerMounted.enable === 'progressColor') {
            updateElement({ progressBar: { progressColor: color, gradientColor: color2 } });
          }
        }}
        onHide={() => {
          setColorPickerMounted({ mounted: false, color: colorPickerMounted.color });
        }}
        showIn={configuratorWrapper.current || document.body}
        mode="style"
      />
    </>
  );
};
ProgressBar.propTypes = {
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  configuratorType: PropTypes.string.isRequired,
  configuratorWrapper: PropTypes.instanceOf(Object).isRequired,
  onKeyDown: PropTypes.instanceOf(Function).isRequired,
};

export default ProgressBar;
