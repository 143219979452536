import styled, { css } from 'styled-components';

const vertical = css`
  top: auto;
  left: 50%;
  transform: translateX(-50%);
`;

export default styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  font-weight: 700;
  z-index: 1;

  user-select: none;
  pointer-events: none;
  transition: 0.2s ease;

  ${props => props.white && 'color: #fff'};
  ${props => props.vertical && vertical};
`;
