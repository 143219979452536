export default {
  speechSynthesizer: {
    voiceType: 'GOOGLE_POLISH_FEMALE',
    options: {
      volume: 0.7,
      pitch: 0,
      maxEmotes: 5,
      maxSigns: 5,
      interLink: true,
      readNickname: true,
      readAmount: true,
      readMessage: true,
      readLink: true,
      readEmoji: true,
      readSpecChar: true,
    },
  },
};
