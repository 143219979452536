import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import RootModal from 'components/Modal';
import Button from 'components/Button';
import Text from 'components/Text';
import Grid from 'components/Grid';

const MissingPersonalDataModal = ({ onClose, ...props }) => (
  <RootModal
    title="Pamiętaj o uzupełnieniu swoich danych"
    width={700}
    height="auto"
    onClose={onClose}
    {...props}
  >
    <Text size={16} color="black" lineHeight="normal" margin="0 0  20px ">
      Aby móc w pełni korzystać z tej funkcji Tipply, a więc przyjmować wiadomości od widzów, należy
      wprowadzić swoje dane osobowe. Rekomendujemy zrobienie tego wcześniej, przed uruchomieniem
      transmisji, gdyż widzowie będą mogli dokonywać płatności dla Ciebie dopiero w momencie, kiedy
      dane zostaną wpisane, a dodatkowo zaakceptowane przez administratora.
    </Text>
    <Grid columns="1fr 1fr" style={{ alignItems: 'start' }}>
      <Link to="/panel-uzytkownika">
        <Button
          block
          background="blue"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_PERSONALDATA_URL}`;
          }}
        >
          UZUPEŁNIJ TERAZ
        </Button>
      </Link>
      <Button
        block
        background="green"
        onClick={() => {
          onClose();
        }}
      >
        POMIŃ
      </Button>
    </Grid>
  </RootModal>
);

MissingPersonalDataModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default MissingPersonalDataModal;
