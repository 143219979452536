import styled from 'styled-components';

export default styled.span`
  position: relative;
  top: 2px;
  padding-left: 15px;
  padding-right: 15px;

  margin: 0;
`;
