import styled from 'styled-components';

export default styled.div`
  padding: 30px;

  h4 {
    margin-bottom: 25px;
  }

  .send-test-message {
    margin-top: 30px;
  }
`;
