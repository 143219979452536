import styled from 'styled-components';
import Flexbox from 'components/Flexbox';

export default styled(Flexbox)`
  padding-right: 30px;
  align-items: center;

  + div {
    margin-top: 15px;
  }
`;
