import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ClearButton } from 'components/styled';
import { Tooltip } from 'components/Tooltip';

const themes = {
  gradientalert: css`
    background-color: #cccccc;
    svg {
      fill: #cccccc;
    }
  `,
};

const Button = styled(ClearButton)`
  line-height: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;

  div {
    font-size: 20px;
    color: #ffffff;
  }

  ${props => themes[props.theme]}
`;

export const GradientTooltip = ({ content, placement, theme, trigger, arrow, ...props }) => (
  <Tooltip content={content} placement={placement} theme={theme} trigger={trigger} arrow={arrow}>
    <Button theme={theme} {...props}>
      <div>
        <strong>+</strong>
      </div>
    </Button>
  </Tooltip>
);

GradientTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.string,
  theme: PropTypes.string,
  trigger: PropTypes.string,
  arrow: PropTypes.bool,
};

GradientTooltip.defaultProps = {
  placement: 'top',
  theme: 'dark',
  trigger: 'click',
  arrow: true,
};
