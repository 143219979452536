import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import http from 'utils/http';

import GlobalStyle from 'styles/Global';
import UniversalStyle from 'styles/Universal';

import 'normalize.css';
import 'react-contexify/dist/ReactContexify.min.css';
import 'styles/components/contexify.scss';

import { ReactComponent as TipplyLogo } from 'assets/icons/tipply-logo.svg';

import Text from 'components/Text';
import Link from 'components/Link';
import { ColorfulRoundedButton } from 'components/Buttons';

const PageContainer = styled.div`
  max-width: 1600px;
  height: 100vh;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Contents = styled.div`
  text-align: center;
  padding: 30px 55px;
  background-color: #fff;
  box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 85vw;
  max-width: 470px;
  overflow-y: auto;
  max-height: 95vh;
`;

const LogoContainer = styled.div`
  width: 120px;
  margin: 0 auto 3rem;

  svg {
    display: block;
    width: 130px;
    height: 46px;
  }
`;

const MainText = styled(Text)`
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 1em;
`;

const RedText = styled(Text)`
  color: var(--red);
`;

const AcceptButton = styled(ColorfulRoundedButton)`
  margin: 1.5rem 0 2.5rem;
`;

const ExternalLink = ({ href, label }) => (
  <Link href={`https://tipply.pl${href}`} size={16} target="_blank">
    {label}
  </Link>
);

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const ForceAcceptNewTerms = ({ onConfirmed }) => {
  const [processing, setProcessing] = useState(false);

  const processAccept = () => {
    setProcessing(true);
    http.post('/user/set-new-statue-accepted').then(() => {
      setProcessing(false);
      onConfirmed();
    });
  };

  return (
    <>
      <GlobalStyle />
      <UniversalStyle />
      <PageContainer>
        <Contents>
          <LogoContainer>
            <TipplyLogo />
          </LogoContainer>
          <MainText>
            {'Niedawno wysłaliśmy Ci wiadomość mailową na temat nadchodzącej aktualizacji '}
            <ExternalLink href="/regulamin" label="regulaminu" />
            {' oraz '}
            <ExternalLink href="/polityka-prywatnosci" label="polityki prywatności" />
            {' naszej platformy. Mamy nadzieję, że zdążyłeś się już z nimi zapoznać. Jeżeli nie, ' +
              'możesz się tym zająć zaglądając na swoją skrzynkę pocztową.'}
          </MainText>
          <MainText>
            {'Jednocześnie ponownie przypominamy, ze od dnia 20.01.2022 r. wejdzie w życie nowy '}
            <ExternalLink href="/regulamin" label="regulamin" />
            {' oraz nowa '}
            <ExternalLink href="/polityka-prywatnosci" label="polityka prywatności" />
            {', a Ty możesz już dziś się z nimi zapoznać i zaakceptować je, ciesząc się w pełni ' +
              'funkcjonalnościami naszego serwisu tak jak każdego dnia.'}
          </MainText>
          <MainText>Cieszymy się, że możemy razem budować tak świetną drużynę!</MainText>
          <AcceptButton disabled={processing} onClick={processAccept}>
            Akceptuję nowy regulamin i politykę prywatności
          </AcceptButton>
          <RedText>
            Jeśli nie akceptujesz wprowadzanych zmian, prosimy Cię o zamknięcie swojego konta przed
            omawianą datą – 20.01.2022 r., kontaktując się z nami poprzez e-mail: kontakt@tipply.pl.
            W przypadku braku kontaktu z Twojej strony – uznamy, że akceptujesz nowe warunki
            świadczenia usług.
          </RedText>
        </Contents>
      </PageContainer>
    </>
  );
};

ForceAcceptNewTerms.propTypes = {
  onConfirmed: PropTypes.instanceOf(Function).isRequired,
};

export default ForceAcceptNewTerms;
