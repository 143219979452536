import styled from 'styled-components';

import Input from 'styles/Input';

export default styled(Input)`
  background-color: var(--bg-color-text-input);
  font-family: var(--google-sora-cdn);
  font-size: 13px;
  padding: 15px;

  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  transform: none;

  &:hover {
    border-color: var(--bg-color-text-input);
  }
`;
