import styled, { css } from 'styled-components';

const active = css`
  &::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const loading = css`
  color: transparent;
  user-select: none;
  cursor: wait;
`;

const withIconStyles = css`
  > div {
    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      width: 19px;
      fill: currentColor;
    }
  }
`;

export default styled.button.attrs(({ background, borderRadius, withIcon }) => {
  let animationHover;

  let backgroundColor;
  let hoverBackgroundColor;

  let borderColor = 'transparent';
  let color = '#fff';
  let spinnerFill = '#fff';

  let padding = '15px 25px';

  if (background === 'white') {
    backgroundColor = 'var(--white)';
    hoverBackgroundColor = 'var(--bg-color-white-gray)';
    borderColor = 'var(--border-color)';
    color = 'var(--text-color)';
    spinnerFill = 'var(--blue)';
  } else if (background === 'gray') {
    backgroundColor = 'var(--bg-color-white-gray)';
    hoverBackgroundColor = 'var(--bg-color-white-gray)';
    borderColor = 'var(--border-color)';
    color = 'var(--text-color)';
    spinnerFill = 'var(--blue)';
  } else if (background === 'blue') {
    backgroundColor = 'var(--blue)';
    hoverBackgroundColor = 'var(--blue)';
    borderColor = 'var(--border-color)';
    animationHover = 'hoverAnimationBlue';
  } else if (background === 'yellow') {
    backgroundColor = 'var(--yellow)';
    hoverBackgroundColor = 'var(--yellow)';
    borderColor = 'var(--yellow)';
    animationHover = 'hoverAnimationYellow';
  } else if (background === 'green') {
    backgroundColor = 'var(--green)';
    borderColor = 'var(--green)';
    animationHover = 'hoverAnimationGreen';
  } else if (background === 'green-dark') {
    backgroundColor = 'var(--brand-green-dark)';
    borderColor = 'var(--brand-green-dark)';
    animationHover = 'hoverAnimationGreen';
  } else if (background === 'red') {
    backgroundColor = 'var(--error-color)';
    borderColor = 'var(--error-color)';
    animationHover = 'hoverAnimationRed';
  } else if (background === 'light-red') {
    backgroundColor = 'var(--light-red)';
    borderColor = 'var(--light-red)';
    color = 'var(--red)';
    animationHover = 'hoverAnimationRed';
  }

  if (withIcon) {
    padding = '12px 25px';
  }

  return {
    color,
    backgroundColor,
    hoverBackgroundColor,
    borderColor,
    backgroundColorSecondary: `var(--${background}-secondary)`,
    animationHover,
    borderRadius: borderRadius || '1000px',
    spinnerFill,
    padding,
  };
})`
  display: inline-block;
  position: relative;
  overflow: hidden;

  background-color: ${props => props.backgroundColor};

  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  white-space: nowrap;
  color: ${props => props.color};

  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  ${props => props.block && 'width: 100%;'}
  max-width: 100%;
  padding: ${props => props.padding};
  transition: var(--hover-transition);

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.96);
  }

  &:not(:disabled) {
    &::before {
      content: '';
      position: absolute;
      width: 400px;
      height: 400px;
      background-color: ${props => props.backgroundColorSecondary};
      border-radius: 100%;
      left: 50%;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
      transition: 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    &:hover {
      animation: ${props => props.animationHover} 0.4s ease;
      background-color: ${props => props.hoverBackgroundColor};
    }
  }

  .spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    .spinner-path {
      stroke: ${props => props.spinnerFill};
    }
  }

  * {
    position: relative;
    z-index: 1;
  }

  ${props => !!props.active && active};
  ${props => !!props.loading && loading};
  ${props => !!props.withIcon && withIconStyles};
`;
