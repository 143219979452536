import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

import { useDelayedState } from 'utils/hooks';
import Box from '../styles/Box';
import { TextInput } from './TextInput';

const Wrapper = styled(Box)`
  padding: 0 25px;
  height: 55px;

  ${TextInput} {
    max-width: 80px;
  }
`;

export const TextInputBox = ({ label, disabled, value, onChange, ...props }) => {
  const [innerValue, setInnerValue] = useDelayedState(value, 'value', (fieldName, newState) =>
    onChange(newState),
  );

  return (
    <Wrapper>
      <span>{label}</span>
      <TextInput
        value={innerValue}
        onChange={e => setInnerValue(e.target.value)}
        disabled={disabled}
        style={{ textAlign: 'center' }}
        {...props}
      />
    </Wrapper>
  );
};

TextInputBox.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
