export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_EXTRAANNOUNCEMENTS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'PATCH_EXTRAANNOUNCEMENTS':
      return {
        ...state,
        isPatching: true,
        failurePatching: false,
      };
    case 'RECEIVE_EXTRAANNOUNCEMENTS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.extraAnnouncements,
      };
    case 'FAILURE_EXTRAANNOUNCEMENTS':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'FAILURE_PATCH_EXTRAANNOUNCEMENTS':
      return {
        ...state,
        isPatching: false,
        failurePatching: true,
      };
    default:
      return state;
  }
};
