import styled from 'styled-components';

export default styled.div`
  padding: 31px 55px;
  position: relative;

  h4 {
    margin-bottom: 16px;
  }

  .group-label {
    margin-bottom: 18px;
    font-size: 15px;
  }

  .form-group {
    margin-bottom: 35px;
  }

  .two-stage-payouts {
    margin-bottom: 48px;
  }

  .two-stage-payouts-tooltip {
    vertical-align: middle;
    display: inline-block;

    svg {
      width: 15px;
      margin: 0 5px;
      fill: var(--text-color-dark-gray);
    }
  }
`;
