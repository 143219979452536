import styled from 'styled-components';

export default styled.div`
  background: var(--white);
  padding: 16px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;
