import React from 'react';

/**
 * Może być używany jako lokalny stan pojedyńczo widocznych powiadomień,
 * np. nowych funkcji.
 */

const defaultValues = {
  currentNotification: '',
  setCurrentNotification: () => {},
};

const Context = React.createContext(defaultValues);

export default Context;
