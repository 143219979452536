import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tipsActions from 'store/actions/tips';
import * as tipsModerationActions from 'store/actions/tipsModeration';

import ModeratorBasketPage from './ModeratorBasketPage';

const mapStateToProps = ({
  tiplist: { showGoals },
  tipsModeration: { tipsModeration, tipsModerationBasket, forceModerationBasketModal },
}) => ({
  showGoals,
  tipsModeration,
  tipsModerationBasket,
  forceModerationBasketModal,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...tipsActions, ...tipsModerationActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModeratorBasketPage);
