import { connect } from 'react-redux';

import {
  changeActiveTemplate,
  createTemplate,
  deleteTemplate,
  updateTemplate,
  cancelCreateTemplate,
} from 'store/actions/templates';

import TemplatesManager from './TemplatesManager';

const mapStateToProps = (state, props) => {
  const { type: configType } = props;
  const { defaults, created, active } = state.templates;

  const defaultTemplates = defaults.filter(template => template.type === configType);
  const createdTemplates = created.filter(template => template.type === configType);
  const allTemplates = [...defaultTemplates, ...createdTemplates];

  const activeTemplateId = active[configType];
  const activeTemplate = allTemplates.find(template => template.id === activeTemplateId);

  return {
    defaults: defaultTemplates,
    created: createdTemplates,
    activeId: activeTemplateId,
    activeTemplate,
    templateConfig: { ...activeTemplate.config },
  };
};

const mapDispatchToProps = (dispatch, { type: configType }) => ({
  dispatchCancelCreateTemplate: () => dispatch(cancelCreateTemplate()),
  dispatchChangeActiveTemplate: templateId => {
    dispatch(changeActiveTemplate(templateId, configType));
  },
  createTemplate: templateConfig => dispatch(createTemplate(templateConfig, configType)),
  deleteTemplate: templateId => dispatch(deleteTemplate(templateId, configType)),
  updateTemplate: (updatedData, templateId) => dispatch(updateTemplate(updatedData, templateId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatesManager);
