import CollapsedWhiteBox from 'components/CollapsedWhiteBox';

import styled from 'styled-components';

export default styled(CollapsedWhiteBox)`
  width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;

  > div {
    width: 100%;
  }
`;
