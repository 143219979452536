import React from 'react';
import PropTypes from 'prop-types';

import ContextMenu from '../ContextMenu';

const baseStyles = {
  margin: 0,
  backfaceVisibility: 'hidden',
  overflowY: 'hidden',
  overflowWrap: 'break-word',
  minWidth: 150,
};

const Message = ({ name, options, animation }) => (
  <React.Fragment>
    <p
      id={`${name}__screen_element`}
      className={animation}
      style={{ ...baseStyles, ...options.styles, width: '100%' }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed risus non elit dictum porta
      eu non velit. Etiam at eros vitae mi faucibus accumsan nec non dolor. Curabitur sed molestie
      dolor, a vehicula nisi. Donec malesuada vel quam vel tempus. Ut sed.
    </p>

    <ContextMenu elementName={name} fields={['editText', 'animation']} />
  </React.Fragment>
);

Message.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  animation: PropTypes.string.isRequired,
};

export default Message;
