import styled from 'styled-components';

const Label = styled.div`
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  line-height: 1.3;
  color: var(--text-color-light-gray);

  > span {
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color);
  }

  a {
    font: inherit;
    color: inherit;
    text-decoration: underline;
  }
`;

export default Label;
