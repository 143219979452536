import styled from 'styled-components';

import Input from 'styles/Input';

import BaseInput from './BaseInput';

export default styled(Input)`
  ${BaseInput};
  display: block;
  max-width: none;
`;
