import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import priceFormatter from 'utils/priceFormatter';
import Text from '../../Text';

const WithdrawalAcceptedNotification = ({ notification, methodName }) => (
  <>
    <Text size={21} margin="0 0 40px">
      Sprawdź konto może już przyszła!
    </Text>
    <Text marign="0 0 50px">
      {methodName}
      <br />
      {`z dnia: ${fecha.format(new Date(notification.payload.requested_at), 'DD/MM/YY HH:mm')}`}
      <br />
      <br />
      {`otrzymasz: ${priceFormatter(
        notification.payload.amount - notification.payload.commission,
      )}`}
    </Text>
  </>
);

export default WithdrawalAcceptedNotification;

WithdrawalAcceptedNotification.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
  methodName: PropTypes.string.isRequired,
};
