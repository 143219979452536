import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import BlankList from 'styles/BlankList';

import ListItem from './ListItem';

const ListSort = ({ items }) => (
  <Scrollbars className="scrollbar">
    <BlankList>
      {items
        .sort((a, b) => (a.config.title > b.config.title ? 1 : -1))
        .map(({ id, config: { title } }) => (
          <ListItem key={id} id={id} title={title} isCustom />
        ))}
    </BlankList>
  </Scrollbars>
);

ListSort.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default ListSort;
