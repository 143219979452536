import React from 'react';
import PropTypes from 'prop-types';

import { ArrowInCircleButton } from 'components/Buttons';

import ConfigBox from '../../ConfigBox';

const SettingsButton = ({ onClick }) => (
  <ConfigBox>
    <span>Własny kod CSS, HTML i JS</span>
    <ArrowInCircleButton background="green" onClick={onClick} />
  </ConfigBox>
);

SettingsButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default SettingsButton;
