import React from 'react';
import PropTypes from 'prop-types';
import { contextMenu, Menu, Item, Separator } from 'react-contexify';
import { isNumber } from 'utils/validators';
import { Link } from 'react-router-dom';
import noEditableAlert from '../lib/noEditableAlert';
import Text from '../../../components/Text';
import { HoverTooltip } from '../../../components/HoverTooltip/HoverTooltip';

const ContextMenuElement = ({
  id,
  elementName,
  children,
  options,
  customOptions,
  onSelectedOption,
  isEditable,
  zIndex,
}) => {
  const onlyChildren = React.Children.only(children);

  const menuId = `elementContextMenu-${id}`;

  const innerText = `Aktualnie nazwę swojego celu możesz zmienić jedynie w ustawieniach celu. Przejdź do nich, klikając tutaj.`;

  return (
    <>
      {React.cloneElement(onlyChildren, {
        ...onlyChildren.props,
        onContextMenu: event => {
          event.preventDefault();

          if (!isEditable) {
            noEditableAlert();
            return;
          }

          contextMenu.show({
            id: menuId,
            event,
          });
        },
      })}

      <Menu id={menuId}>
        {options.map(field => {
          switch (field) {
            case 'editText': {
              return (
                <Item key={field} onClick={() => onSelectedOption(field)}>
                  Edytuj tekst
                </Item>
              );
            }
            case 'changeText': {
              if (elementName === 'goalName') {
                return (
                  <Item key={`${field}_${elementName}`}>
                    <Link to="/cele" style={{ color: 'var(--text-color)', textDecoration: 'none' }}>
                      <HoverTooltip
                        hoverText="Zmień tekst"
                        content={
                          <Text
                            margin="0"
                            dangerouslySetInnerHTML={{
                              __html: innerText,
                            }}
                          />
                        }
                        placement="top"
                        theme="light"
                        trigger="mouseenter"
                      />
                    </Link>
                  </Item>
                );
              }

              return (
                <Item
                  onClick={() => {
                    onSelectedOption(field);
                  }}
                  key={field}
                >
                  Zmień tekst
                </Item>
              );
            }
            default:
              return null;
          }
        })}

        {customOptions.map(customOption =>
          React.isValidElement(customOption) ? (
            customOption
          ) : (
            <Item onClick={() => onSelectedOption(customOption.value)} key={customOption.value}>
              {customOption.label}
            </Item>
          ),
        )}

        {isNumber(zIndex) && (
          <>
            <Separator />
            <Item
              disabled={zIndex === 4}
              onClick={() => onSelectedOption('zIndex', { elementName, mode: 'moveUp' })}
            >
              Przenieś do przodu
              <span>CTRL +</span>
            </Item>
            <Item
              disabled={zIndex === 1}
              onClick={() => onSelectedOption('zIndex', { elementName, mode: 'moveDown' })}
            >
              Przenieś do tyłu
              <span>CTRL -</span>
            </Item>
          </>
        )}
        <Separator />
        <Item onClick={() => onSelectedOption('delete')}>
          Usuń
          <span>DELETE</span>
        </Item>
      </Menu>
    </>
  );
};

ContextMenuElement.propTypes = {
  id: PropTypes.string.isRequired,
  elementName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSelectedOption: PropTypes.instanceOf(Function),
  options: PropTypes.instanceOf(Array),
  customOptions: PropTypes.instanceOf(Array),
  isEditable: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ContextMenuElement.defaultProps = {
  onSelectedOption: option => {},
  options: [],
  customOptions: [],
  isEditable: false,
  zIndex: false,
};

export default ContextMenuElement;
