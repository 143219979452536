import styled from 'styled-components';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 35px;

  width: 70px;
  height: 70px;
  border-radius: 100%;

  svg {
    fill: #fff;
  }

  img {
    max-width: 75px;
  }
`;

export default Icon;
