import styled from 'styled-components';

export default styled.p`
  margin: 0;
  color: var(--text-color-text-editor-notactive);
  font-size: 8px;
  line-height: 1.4;
  font-weight: 500;
  text-align: center;
`;
