/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fecha from 'fecha';
import Picture from 'pages/UserPanel/components/Hello/styles/Picture';
import Link from '../../../../../../Link';

const Wrapper = styled.div`
  text-align: left;

  > time {
    display: inline-block;
    margin: 20px 0;
    font-weight: 500;
  }

  > p {
    margin: 0;
    font-weight: 700;
  }
`;

function createMarkup(content) {
  return { __html: content };
}

const Announcement = ({ createdAt, title, content, piclink, piclinkhref }) => (
  <Wrapper>
    <time dateTime={createdAt}>{fecha.format(new Date(createdAt), 'DD.MM.YYYY')}</time>
    <Link href={piclinkhref} target="_blank">
      <Picture
        style={{
          width: '100%',
          height: '450px',
          border: '1px solid #fff',
          backgroundImage: piclink ? `url(${piclink})` : null,
        }}
      />
    </Link>
    <h3>{title}</h3>
    <div dangerouslySetInnerHTML={createMarkup(content)} />
  </Wrapper>
);

Announcement.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  piclink: PropTypes.string.isRequired,
  piclinkhref: PropTypes.string.isRequired,
};

export default Announcement;
