import styled from 'styled-components';

export default styled.div`
  margin-bottom: 17px;

  .input-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    align-items: center;

    button {
      background: var(--brand-blue);
      width: 30px;
      height: 30px;
      border: 0px solid white;
      border-radius: 30px;
      padding: 7px;
      cursor: pointer;
      transition: 250ms;
      transition-delay: 100ms;
      transform: scale(1) rotate(0);

      svg {
        fill: white;
      }

      &:hover {
        transform: scale(1.2) rotate(-10deg);
      }
    }
  }

  input {
    border: 1px solid var(--bg-color-light-gray);
    background: white;
    border-radius: 5px;
    padding: 14px 20px;
    width: 86%;
    transition: 250ms;

    &.text-copied {
      animation: increaseLeftPadding 1.5s ease-in-out;
      color: var(--brand-blue);
    }
  }
`;
