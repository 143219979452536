import styled, { css } from 'styled-components';
import arrow from '../../../icons/arrow.svg';

const active = css`
  border: 2px solid ${props => (props.active ? 'var(--bg-color-media-asset)' : 'var(--gray)')};
`;

const activeAfter = css`
  opacity: 1;
  bottom: -24px;
`;

export default styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  min-height: 80px;
  background: var(--white);

  border-radius: 10px;
  margin-right: 15px;
  transition: var(--hover-transition);
  ${active}

  > svg {
    position: relative;
    fill: #656565;
  }

  &:after {
    content: url('${arrow}');
    position: absolute;
    left: 50%;
    bottom: -35px;
    opacity: 1;
    height: 10px;
    width: 18px;
    transform: translate(-50%, 0);
    transition: 300ms;
    ${props => (props.active ? activeAfter : '')}
  }

  &:active {
    transform: scale(0.96);
  }
`;
