import React from 'react';
import PropTypes from 'prop-types';

import AbstractElements from 'pages/Configurators/shared/AbstractElements';
import ElementsContext from './ElementsContext';
import ElementDragWrapper from './ElementDragWrapper';

export default class Elements extends AbstractElements {
  static propTypes = {
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    screenRef: PropTypes.instanceOf(Object).isRequired,

    getLatestHistoryElement: PropTypes.instanceOf(Function).isRequired,
    createHistoryElement: PropTypes.instanceOf(Function).isRequired,
    pushHistory: PropTypes.instanceOf(Function).isRequired,
    onFocusElement: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    this.elementsWrapper = React.createRef();
  }

  handleClickOutside = event => {
    const { screenRef, onFocusElement } = this.props;

    if (
      screenRef.current &&
      screenRef.current.contains(event.target) &&
      this.elementsWrapper.current &&
      !this.elementsWrapper.current.contains(event.target)
    ) {
      onFocusElement('');
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  render() {
    const { elements } = this.props;

    const handleUpdateElement = config => {
      this.updateElement(config);
    };

    const handleContextMenu = (event, elementName) => {
      this.handleContextMenu(event, elementName);
    };

    const handleKeyDownElement = (event, elementName) => {
      this.handleKeyDownElement(event, elementName);
    };

    return (
      <ElementsContext.Provider
        value={{
          updateElement: handleUpdateElement,
          handleContextMenu,
          handleKeyDownElement,
        }}
      >
        <div ref={this.elementsWrapper}>
          {elements.map(el => (
            <ElementDragWrapper key={el.key} elementName={el.key} />
          ))}
        </div>
      </ElementsContext.Provider>
    );
  }
}
