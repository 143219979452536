import styled, { css } from 'styled-components';

const visibilityStyles = css`
  transform: scale(1);
  opacity: 1;
  visibility: visible;
`;

const shiftTopStyles = css`
  transform: translateY(-50%);
`;

export default styled.div.attrs(({ position }) => {
  let x;
  let y;
  let transformOrigin;

  if (position === 'right' || position === 'right-top-shifted') {
    x = 'left: calc(100% + 10px);';
    y = 'top: 0;';
    transformOrigin = 'left';
  } else if (position === 'bottom') {
    x = 'right: 0;';
    y = 'top: calc(100% + 10px);';
    transformOrigin = 'top';
  } else if (position === 'top-left') {
    x = 'left: 0;';
    y = 'bottom: calc(100% + 10px);';
    transformOrigin = 'bottom';
  } else if (position === 'bottom-left') {
    x = 'left: 0;';
    y = 'top: calc(100% + 10px);';
    transformOrigin = 'top';
  }

  return {
    x,
    y,
    transformOrigin,
  };
})`
  position: absolute;
  ${props => props.x};
  ${props => props.y};
  min-width: 280px;

  opacity: 0;
  visibility: hidden;

  transform: scale(0);
  transform-origin: ${({ transformOrigin }) => transformOrigin || 'left'};

  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 10;

  ${({ visible }) => visible && visibilityStyles};
  ${({ position }) => position === 'right-top-shifted' && shiftTopStyles}
`;
