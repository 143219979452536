import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import ColorPicker from './ColorPicker';

const Component = props => {
  const { isMounted, unmountDelay } = props;

  return (
    <CSSTransition in={isMounted} timeout={unmountDelay} classNames="fade" unmountOnExit>
      <ColorPicker {...props} />
    </CSSTransition>
  );
};

Component.propTypes = {
  isMounted: PropTypes.bool.isRequired,
  unmountDelay: PropTypes.number,
};

Component.defaultProps = {
  unmountDelay: 500,
};

export default Component;
