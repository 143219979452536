/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { alert } from 'components/Alert';

export default () => {
  alert.open({
    config: {
      type: 'error',
      title: 'Coś się pokićkało',
      description: (
        <React.Fragment>
          <p>Domyślnych szablonów nie możesz edytować lub zmieniać!</p>
          <p>
            Wybierz własny szablon i w nim zmieniaj cokolwiek chcesz! Listę dostępnych szablonów
            znajdziesz po prawej stronie.
          </p>
        </React.Fragment>
      ),
      confirmButtonText: 'OK',
    },
  });
};
