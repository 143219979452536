import styled from 'styled-components';

export default styled.div`
  position: relative;
  padding: 20px;

  background-color: var(--white-box-background);
  color: var(--text-color);
  border-radius: var(--block-border-radius);
  box-shadow: var(--block-shadow);
`;
