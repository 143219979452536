import styled from 'styled-components';

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto 5px 1fr;
  grid-gap: 0 36px;
  align-items: center;
`;

export default Columns;
