import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

import AnnouncementListItem from './AnnouncementListItem';

const AnnouncementList = ({ entries }) => {
  const [scrollbarStyles, setScrollbarStyles] = useState({
    height: 200,
    maxHeight: window.innerHeight - 150,
  });
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setScrollbarStyles({
        ...scrollbarStyles,
        height: contentRef.current.clientHeight,
      });
    }
  }, []);

  return (
    <Scrollbars className="scrollbar" style={scrollbarStyles}>
      <div ref={contentRef}>
        {entries.map(({ id, created_at: createdAt, ...item }) => (
          <AnnouncementListItem key={`announcement_list_item_${id}`} createdAt poster {...item} />
        ))}
      </div>
    </Scrollbars>
  );
};

AnnouncementList.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AnnouncementList;
