import deepmerge from 'deepmerge';

export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_MEDIA':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_MEDIA':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.items,
      };
    case 'SHOW_MANAGER_TRUE': {
      return {
        ...state,
        showManager: true,
      };
    }
    case 'SHOW_MANAGER_FALSE': {
      return {
        ...state,
        showManager: false,
      };
    }
    case 'UPDATE_MEDIA': {
      const items = state.items.map(item => {
        if (item.id === action.id) {
          return deepmerge(item, { ...action.updatedData });
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case 'INSERT_MEDIUM':
      return deepmerge(state, { items: [action.medium] });
    case 'DELETE_MEDIA':
      return {
        ...state,
        items: state.items.filter(medium => !action.mediaIds.includes(medium.id)),
      };
    case 'UPDATE_MEDIA_SPACE_USAGE':
      return {
        ...state,
        usage: action.usage,
      };
    case 'FAILURE_MEDIA':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    default:
      return state;
  }
};
