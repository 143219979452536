import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as OkCheck } from 'assets/icons/ok-check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';

import ModeratorStatus from '../../TipsList/styles/ModeratorStatus';
import StyledToolbar from '../../TipsList/styles/StyledToolbar';

import { ToggleGoals } from './ToggleGoals';
import { ToggleAudioPlay } from './ToggleAudioPlay';

function Toolbar() {
  const moderationMode = useSelector(
    state => state.tipsModeration.currentModerator.moderation_mode,
  );

  return (
    <StyledToolbar className="box">
      <h2>Lista wiadomości do moderacji</h2>
      <ToggleGoals />
      <ToggleAudioPlay />
      {moderationMode ? (
        <ModeratorStatus>
          <OkCheck fill="var(--brand-green)" />
          <span>MODERACJA AKTYWNA</span>
        </ModeratorStatus>
      ) : (
        <ModeratorStatus>
          <CloseIcon fill="var(--error-color)" />
          <span>MODERACJA NIEAKTYWNA</span>
        </ModeratorStatus>
      )}
    </StyledToolbar>
  );
}

export default Toolbar;
