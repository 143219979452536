import deepmerge from 'deepmerge';

import { openAlert } from 'components/Alert/utils';

export default (state = [], action) => {
  switch (action.type) {
    case 'MODERATOR_FETCH_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'MODERATOR_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.payload,
      };
    case 'MODERATOR_FETCH_ERROR':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        failureFetching: true,
      };
    case 'MODERATOR_UPDATE_FIELD_REQUEST': {
      const { id, field, value } = action.payload;
      const items = state.items.map(item => {
        if (item.id === id) {
          return deepmerge(item, {
            [field]: value,
          });
        }

        return item;
      });

      return {
        ...state,
        isPatching: true,
        items: [...items],
      };
    }
    case 'MODERATOR_UPDATE_FIELD_SUCCESS':
      return {
        ...state,
        isPatching: false,
        failureFetching: false,
      };
    case 'MODERATOR_UPDATE_FIELD_ERROR':
      openAlert(
        'error',
        'Błąd zapisu',
        'Ups, nie mogę zaktualizować moderatora, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    case 'MODERATOR_CREATE_REQUEST':
      return {
        ...state,
        isPatching: true,
      };
    case 'MODERATOR_CREATE_SUCCESS':
      return {
        ...state,
        items: [...state.items, action.payload],
        isPatching: false,
        failureFetching: false,
      };
    case 'MODERATOR_CREATE_ERROR':
      openAlert(
        'error',
        'Błąd zapisu',
        'Ups, nie mogę utworzyć nowego moderatora, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    case 'MODERATOR_DELETE_REQUEST':
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.id),
        isPatching: true,
      };
    case 'MODERATOR_DELETE_SUCCESS':
      return {
        ...state,
        isPatching: false,
        failureFetching: false,
      };
    case 'MODERATOR_DELETE_ERROR':
      openAlert(
        'error',
        'Błąd usuwania',
        'Ups, nie mogę usunąć moderatora, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    default:
      return state;
  }
};
