import React from 'react';
import styled from 'styled-components';
import { ClearButton } from 'components/styled';

const Container = styled(ClearButton)`
  position: relative;
  width: 24px;
  height: 24px;
  transition: transform 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
`;

const Dot = styled.span`
  position: absolute;
  width: 100%;
  height: 6px;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(0);
  transition: transform 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 0;
    border-radius: 100%;
    background-color: var(--light-gray);
  }

  &:first-child {
    &::before {
      left: 0;
    }
  }

  &:nth-child(2) {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:last-child {
    transition-delay: 0.03s;

    &::before {
      right: 0;
    }
  }
`;

const Toggle = props => (
  <Container {...props}>
    <Dot />
    <Dot />
    <Dot />
  </Container>
);

export { Dot };
export default Toggle;
