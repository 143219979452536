import { Scrollbars } from 'react-custom-scrollbars';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import getUserAvatar from 'utils/getUserAvatar';
import http from 'utils/http';
import logout from 'utils/logout';

import FloatingMenu from 'components/FloatingMenu';
import DropDown from 'components/DropDown';

import OptionsPopup, { OptionsPopupItem } from 'styles/OptionsPopup';

import { ReactComponent as SettingsIcons } from 'assets/icons/gear.svg';
import Notification from 'assets/icons/components/Notification';
import ThemeIcon from 'assets/icons/components/ThemeIcon';
import LinkIcon from 'assets/icons/components/Link';

import MissingPersonalDataModal from 'pages/UserPanel/components/MissingPersonalDataModal';
import AnnouncementsModal from 'pages/UserPanel/components/AnnouncementsModal';

import NotifyButton from '../../blocks/NotifyButton';
import StyledTools from '../../styles/StyledTools';
import Heading from '../../styles/Heading';

import FastCopyDropDownContent from './components/FastCopyDropDownContent';
import AccountSettings from './components/AccountSettings';
import Announcements from './components/Announcements';
import PersonalData from './components/PersonalData';
import Avatar from './styles/Avatar';
import { Provider } from './Context';

const OnOffSign = styled.div`
  color: var(--brand-green);
  font-size: 11px;
  font-weight: 800;
  position: absolute;
  left: 30px;
  top: -3px;
`;

class Tools extends Component {
  static propTypes = {
    templates: PropTypes.instanceOf(Array).isRequired,
    announcementsList: PropTypes.instanceOf(Array).isRequired,
    announcementsFailurePatching: PropTypes.bool.isRequired,
    announcementsFailureFetching: PropTypes.bool.isRequired,
    announcementsArePatching: PropTypes.bool.isRequired,
    announcementsAreFetching: PropTypes.bool.isRequired,
    fetchAnnouncementsIfNeeded: PropTypes.instanceOf(Function).isRequired,
    fetchExtraAnnouncementsIfNeeded: PropTypes.instanceOf(Function).isRequired,
    markAnnouncementsAsRead: PropTypes.instanceOf(Function).isRequired,
    userDataIsPatching: PropTypes.bool.isRequired,
    userDataInfo: PropTypes.instanceOf(Object).isRequired,
    userDataProfile: PropTypes.instanceOf(Object).isRequired,
    fetchUserProfileIfNeeded: PropTypes.instanceOf(Function).isRequired,
    submitChangedPassword: PropTypes.instanceOf(Function).isRequired,
    submitChangedEmail: PropTypes.instanceOf(Function).isRequired,
    sendEmailAuthenticatorCode: PropTypes.instanceOf(Function).isRequired,
    enableEmailAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disableEmailAuthenticator: PropTypes.instanceOf(Function).isRequired,
    connectGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    enableGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disableGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disconnectService: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showUpdatesDropdown: false,
      showFastCopyDropDown: false,
      optionsPopupIsVisible: false,
      accountSettings: false,
      personalData: false,
      announcementsModalVisibility: false,
      checkModal: true,
      missingPersonalDataModalVisibility: false,
    };

    this.optionsRef = React.createRef();
    this.announcementsRef = React.createRef();
    this.widgetsRef = React.createRef();
  }

  componentDidMount() {
    const {
      fetchAnnouncementsIfNeeded,
      fetchUserProfileIfNeeded,
      fetchExtraAnnouncementsIfNeeded,
    } = this.props;

    fetchUserProfileIfNeeded();
    fetchAnnouncementsIfNeeded();
    fetchExtraAnnouncementsIfNeeded();

    const { checkModal } = this.state;
    if (checkModal) {
      this.timer = setInterval(() => this.showModal(), 3000);
    }
  }

  showModal() {
    clearInterval(this.timer);
    const { announcementsList, userDataInfo, userDataProfile } = this.props;

    if (userDataInfo.missing_personal_data && !userDataProfile.pending) {
      this.setState({ missingPersonalDataModalVisibility: true }); // missing data modal
    }

    if (announcementsList.length > 0 && !announcementsList[0].is_read) {
      this.setState({ announcementsModalVisibility: true });
      this.setState({ checkModal: false });
    }
  }

  toggleFastCopyDropDown() {
    this.setState(prevState => ({
      showFastCopyDropDown: !prevState.showFastCopyDropDown,
    }));
  }

  toggleUpdatesDropdown() {
    this.setState(prevState => ({
      showUpdatesDropdown: !prevState.showUpdatesDropdown,
    }));
  }

  hideFastCopyDropDown() {
    this.setState({
      showFastCopyDropDown: false,
    });
  }

  hideUpdatesDropDown() {
    this.setState({
      showUpdatesDropdown: false,
    });
  }

  render() {
    const {
      templates,
      announcementsList,
      announcementsFailurePatching,
      announcementsFailureFetching,
      announcementsArePatching,
      announcementsAreFetching,
      markAnnouncementsAsRead,
      userDataIsPatching,
      userDataInfo,
      userDataProfile,
      submitChangedPassword,
      submitChangedEmail,
      sendEmailAuthenticatorCode,
      enableEmailAuthenticator,
      disableEmailAuthenticator,
      connectGoogleAuthenticator,
      enableGoogleAuthenticator,
      disableGoogleAuthenticator,
      disconnectService,
    } = this.props;

    const {
      showUpdatesDropdown,
      showFastCopyDropDown,
      accountSettings,
      optionsPopupIsVisible,
      personalData,
      announcementsModalVisibility,
      missingPersonalDataModalVisibility,
    } = this.state;

    // Ustawianie avatara użytkownika
    const userAvatar = getUserAvatar(userDataProfile);

    const currentPath = window.location.pathname;
    let showMissingDataModal = false;
    if (currentPath.includes('wyplaty') || currentPath.includes('ustawienia-strony-zamowien')) {
      showMissingDataModal = true;
    }

    const unreadNotifications = announcementsList.filter(message => !message.is_read);

    return (
      <Provider
        value={{
          templates,
          announcementsList,
          markAnnouncementsAsRead,
          announcementsFailurePatching,
          announcementsFailureFetching,
          announcementsArePatching,
          announcementsAreFetching,
          userDataIsPatching,
          userDataInfo,
          userDataProfile,
        }}
      >
        <StyledTools>
          <ul className="tools-menu">
            <li>
              <NotifyButton
                handleClick={async event => {
                  await http.post('/user/toggle-color-theme');
                  window.location.reload();
                }}
              >
                <ThemeIcon />
                <OnOffSign>
                  {userDataInfo.color_theme === 'default' && <>OFF</>}
                  {userDataInfo.color_theme !== 'default' && <>ON</>}
                </OnOffSign>
              </NotifyButton>
            </li>
            <li>
              <div ref={this.widgetsRef}>
                <NotifyButton
                  handleClick={() => {
                    this.toggleFastCopyDropDown();
                  }}
                >
                  <LinkIcon />
                </NotifyButton>
                <DropDown
                  show={showFastCopyDropDown}
                  padding="5px"
                  outsideClick={() => this.hideFastCopyDropDown()}
                  parent={this.widgetsRef.current || document.body}
                >
                  <Heading style={{ padding: '30px 30px 0 30px', margin: '0' }}>Widżety</Heading>
                  <FastCopyDropDownContent />
                </DropDown>
              </div>
            </li>
            <li>
              <div ref={this.announcementsRef}>
                <NotifyButton
                  notify={!!unreadNotifications.length}
                  handleClick={() => {
                    this.toggleUpdatesDropdown();
                  }}
                >
                  <Notification />
                </NotifyButton>

                <MissingPersonalDataModal
                  isMounted={missingPersonalDataModalVisibility && showMissingDataModal}
                  onClose={() => {
                    this.setState({ missingPersonalDataModalVisibility: false });
                  }}
                />

                <AnnouncementsModal
                  isMounted={announcementsModalVisibility}
                  onClose={() => {
                    markAnnouncementsAsRead();
                    this.setState({ announcementsModalVisibility: false });
                  }}
                />

                <DropDown
                  parent={this.announcementsRef.current || document.body}
                  show={showUpdatesDropdown}
                  outsideClick={() => this.hideUpdatesDropDown()}
                >
                  <Announcements />
                </DropDown>
              </div>
            </li>
            <li>
              <Link to="/ustawienia-strony-zamowien">
                <NotifyButton>
                  <Avatar
                    style={{
                      backgroundImage: userAvatar ? `url(${userAvatar})` : userAvatar,
                    }}
                  />
                </NotifyButton>
              </Link>
            </li>
            <li style={{ position: 'relative' }}>
              <div ref={this.optionsRef}>
                <NotifyButton
                  width="18px"
                  handleClick={() => {
                    this.setState(prevState => ({
                      optionsPopupIsVisible: !prevState.optionsPopupIsVisible,
                      accountSettings: false,
                      personalData: false,
                    }));
                  }}
                >
                  <SettingsIcons style={{ width: 24, height: 24 }} />
                </NotifyButton>

                <FloatingMenu
                  visible={optionsPopupIsVisible}
                  position="bottom-left"
                  onClose={() => this.setState({ optionsPopupIsVisible: false })}
                  parent={this.optionsRef.current || document.body}
                >
                  <OptionsPopup>
                    <OptionsPopupItem
                      onClick={() =>
                        this.setState({
                          accountSettings: true,
                          optionsPopupIsVisible: false,
                        })
                      }
                    >
                      Ustawienia konta
                    </OptionsPopupItem>
                    <OptionsPopupItem
                      onClick={() =>
                        this.setState({
                          personalData: true,
                          optionsPopupIsVisible: false,
                        })
                      }
                    >
                      Dane osobowe
                    </OptionsPopupItem>
                    <OptionsPopupItem onClick={logout}>Wyloguj się</OptionsPopupItem>
                  </OptionsPopup>
                </FloatingMenu>

                <DropDown
                  show={accountSettings}
                  outsideClick={() =>
                    this.setState({
                      accountSettings: false,
                    })
                  }
                  padding="5px"
                  height="980px"
                  parent={this.optionsRef.current || document.body}
                >
                  <Scrollbars>
                    <AccountSettings
                      submitChangedPassword={submitChangedPassword}
                      submitChangedEmail={submitChangedEmail}
                      sendEmailAuthenticatorCode={sendEmailAuthenticatorCode}
                      enableEmailAuthenticator={enableEmailAuthenticator}
                      disableEmailAuthenticator={disableEmailAuthenticator}
                      disconnectService={disconnectService}
                      connectGoogleAuthenticator={connectGoogleAuthenticator}
                      enableGoogleAuthenticator={enableGoogleAuthenticator}
                      disableGoogleAuthenticator={disableGoogleAuthenticator}
                    />
                  </Scrollbars>
                </DropDown>
                <DropDown
                  show={personalData}
                  outsideClick={() =>
                    this.setState({
                      personalData: false,
                    })
                  }
                  padding="5px"
                  height={userDataInfo.is_verified ? '980px' : '830px'}
                  parent={this.optionsRef.current || document.body}
                >
                  <Scrollbars>
                    {Object.keys(userDataProfile).length > 0 && (
                      <PersonalData profile={userDataProfile} userData={userDataInfo} />
                    )}
                  </Scrollbars>
                </DropDown>
              </div>
            </li>
          </ul>
        </StyledTools>
      </Provider>
    );
  }
}

export default Tools;
