import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TipModeration from './TipModeration';

class TipsModerationList extends Component {
  static propTypes = {
    tipsModeration: PropTypes.instanceOf(Object).isRequired,
    showGoals: PropTypes.bool.isRequired,
    approveTipModeration: PropTypes.instanceOf(Function).isRequired,
    restoreModerationPanel: PropTypes.instanceOf(Function).isRequired,
  };

  render() {
    const { tipsModeration, showGoals, approveTipModeration, restoreModerationPanel } = this.props;

    return tipsModeration.map(modtip => (
      <TipModeration
        createdAt={modtip.created_at}
        nickname={modtip.nickname}
        email={modtip.email}
        id={modtip.id}
        key={modtip.id}
        message={modtip.message}
        amount={modtip.amount}
        goalTitle={modtip.goal ? modtip.goal.title : 'Cel domyślny'}
        paymentType={modtip.payment ? modtip.payment.payment_method : ''}
        showGoals={showGoals}
        approveTipModeration={approveTipModeration}
        restoreModerationPanel={restoreModerationPanel}
      />
    ));
  }
}

export default TipsModerationList;
