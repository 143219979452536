import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from '../styles/StyledButton';

function SwitchTypeButton({ handleClick, icon, id, active }) {
  return (
    <StyledButton
      active={active}
      onClick={() => {
        handleClick(id);
      }}
    >
      {icon}
    </StyledButton>
  );
}

SwitchTypeButton.propTypes = {
  handleClick: PropTypes.instanceOf(Function).isRequired,
  icon: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default SwitchTypeButton;
