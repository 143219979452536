import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg';

const UndoButton = ({ onClick, ...props }) => (
  <IconButton $variant="outlined" onClick={onClick} {...props}>
    <UndoIcon />
  </IconButton>
);

UndoButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default UndoButton;
