import { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { contextMenu } from 'react-contexify';
import { getDuplicatesFromArray } from 'utils';
import { isNull } from 'utils/validators';
import noEditableAlert from '../lib/noEditableAlert';

const DEFAULT_STYLE_MENU_OPTS = {
  elementName: '',
  mode: 'style',
  attribute: '',
  target: '',
};

export default class AbstractElements extends Component {
  static propTypes = {
    activeTemplate: PropTypes.instanceOf(Object).isRequired,

    getLatestHistoryElement: PropTypes.instanceOf(Function).isRequired,
    createHistoryElement: PropTypes.instanceOf(Function).isRequired,
    pushHistory: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      textEditor: DEFAULT_STYLE_MENU_OPTS,
    };

    this.keyboardShortcuts = {
      moveUp: ['numpad +', '='],
      moveDown: ['numpad -', '-'],
      movePosition: ['up', 'right', 'down', 'left'],
    };
  }

  /**
   * Set z-index to element
   *
   * @param {string} elementName
   * @param {'moveUp' | 'moveDown'} direction
   * @returns {void}
   */
  setZIndex = (elementName, direction) => {
    const { createHistoryElement, pushHistory } = this.props;
    // history fragment
    const { elementsOptions } = createHistoryElement();
    // elements options entries
    const entries = Object.entries(elementsOptions);
    // current z-index values
    const values = entries.map(([, options]) => options.styles.zIndex);
    // limits of the z-index
    const boundary = [1, Math.max(1, values.length)];
    // moved elements
    const locked = [];

    /**
     * Set new z-index for element options
     *
     * @param {number} index
     * @param {number} value
     * @param {boolean} replace
     * @returns {void}
     */
    const setIndex = (index, value, replace = false) => {
      elementsOptions[entries[index][0]].styles.zIndex = value;
      locked.push(index);

      if (replace) {
        values[index] = value;
      }
    };

    /**
     * Find new z-index for element
     *
     * @param {number} index
     * @returns {boolean}
     */
    const move = index => {
      let newValue = null;

      // Find free value for a duplicate
      for (let i = 1; i <= boundary[1]; i += 1) {
        if (values.indexOf(i) === -1) {
          newValue = i;
          break;
        }
      }

      if (!isNull(newValue)) {
        setIndex(index, newValue, true);
        return true;
      }

      return false;
    };

    /**
     * Normalize duplicate z-index values.
     * If the template has duplicate z-index values,
     * find them new values.
     *
     * @param {number[]} duplicatedValues
     * @returns {void}
     */
    const normalize = ([duplicatedValue, ...rest]) => {
      let duplicatedValueIndex = values.indexOf(duplicatedValue);

      // Find duplicated value and skip locked
      for (let i = 0; i < values.length; i += 1) {
        if (values[i] === duplicatedValue && locked.indexOf(i) === -1) {
          duplicatedValueIndex = i;
          break;
        }
      }

      // Find a new value
      if (move(duplicatedValueIndex, duplicatedValue)) {
        if (rest.length) {
          normalize(rest);
        }
      }
    };

    /**
     * Replace z-index of selected item
     *
     * @param {number} elementIndex
     * @param {(1|-1)} sign
     * @returns {void}
     */
    const replace = (elementIndex, sign) => {
      // Calculate and bind new value
      const newValue = values[elementIndex] + sign;
      setIndex(elementIndex, newValue);

      // Find element to replace
      const replaceWith = values.indexOf(newValue);
      if (replaceWith !== -1) {
        setIndex(replaceWith, values[elementIndex], true);
      }

      values[elementIndex] = newValue;

      // If any z-index items are duplicated, find and reset them
      const duplicates = getDuplicatesFromArray(values);
      if (duplicates.length) {
        normalize(duplicates.sort());
      }
    };

    for (let i = 0; i < entries.length; i += 1) {
      const [key, options] = entries[i];
      const { styles } = options;

      if (key === elementName) {
        if (direction === 'moveUp' && styles.zIndex < boundary[1]) {
          replace(i, 1);
        }

        if (direction === 'moveDown' && styles.zIndex > boundary[0]) {
          replace(i, -1);
        }

        break;
      }
    }

    pushHistory({ ...elementsOptions });
  };

  /**
   * Funkcja ustawiająca widocznośc dla elementu
   *
   * @param {String} elementName
   * @param {Boolean} value
   * @returns {void}
   */
  setVisibility = (elementName, value) => {
    const { pushHistory } = this.props;

    pushHistory({ [elementName]: { isVisible: value } });
  };

  /**
   * Funkcja ustawiająca animacje dla elementu
   *
   * @param {Object} config
   * @returns {void}
   */
  setAnimation = ({ elementName, animation, direction }) => {
    const { pushHistory } = this.props;

    pushHistory({ [elementName]: { animation: { [direction]: animation } } });
  };

  /**
   * Funkcja ustawiająca aktualną pozycję elementu
   *
   * @param {Object} config
   * @returns {void}
   */
  setPosition = ({ currentPosition, elementName }) => {
    const { createHistoryElement, pushHistory } = this.props;
    const historyElement = createHistoryElement();
    const historyPosition = historyElement.elementsOptions[elementName].position;

    if (!(historyPosition.x === currentPosition.x && historyPosition.y === currentPosition.y)) {
      historyElement.elementsOptions[elementName].position = currentPosition;
      pushHistory({ [elementName]: { position: currentPosition } });
    }
  };

  /**
   * Funkcja ustawiająca aktualną pozycję elementu
   *
   * @param {Object} config
   * @returns {void}
   */
  setChildrenPosition = ({ currentPosition, elementName }) => {
    const { createHistoryElement, pushHistory } = this.props;
    const historyElement = createHistoryElement();
    let historyPosition;

    if (historyElement.elementsOptions.goalNumbers.children[elementName].position !== undefined) {
      historyPosition = historyElement.elementsOptions.goalNumbers.children[elementName].position;
    } else {
      historyPosition = historyElement.elementsOptions.goalNumbers.position;
    }

    if (!(historyPosition.x === currentPosition.x && historyPosition.y === currentPosition.y)) {
      historyElement.elementsOptions.goalNumbers.children[elementName].position = currentPosition;
      pushHistory({
        goalNumbers: { children: { [elementName]: { position: currentPosition } } },
      });
    }
  };

  /**
   * Funkcja ustawiająca aktualną pozycję elementu
   *
   * @param {string} elementName
   * @param {Object} style
   * @returns {void}
   */
  setStyle = (elementName, { style }) => {
    const { pushHistory } = this.props;

    pushHistory({ [elementName]: { styles: { ...style } } });
  };

  /**
   * Ustawia elementowi wskazany styl
   *
   * @param {string} elementName
   * @param {Object} style
   * @returns {void}
   */
  setElementStyle = (elementName, { style }) => {
    const { pushHistory } = this.props;

    pushHistory({
      [elementName]: { styles: style },
    });
  };

  /**
   * Funkcja ustawiająca aktualną pozycję elementu
   *
   * @param {string} elementName
   * @param {string} text
   * @returns {void}
   */
  setText = (elementName, { text }) => {
    const { pushHistory } = this.props;

    pushHistory({ [elementName]: { value: text } });
  };

  /**
   * Funkcja ustawiająca aktualną pozycję elementu
   *
   * @param {string} elementName
   * @param {Object} size
   * @returns {void}
   */
  setSize = (elementName, { size }) => {
    const { pushHistory } = this.props;

    pushHistory({
      [elementName]: {
        size: {
          width: size.width,
        },
      },
    });
  };

  /**
   * Funkcja zwracająca najnowsze ustawienia elementu
   *
   * @param {string} elementName
   * @returns {Object}
   */
  getElementOptions = elementName => {
    if (elementName) {
      const { getLatestHistoryElement } = this.props;

      return getLatestHistoryElement().elementsOptions[elementName];
    }

    return {};
  };

  /**
   * Funkcja aktualizująca style elementu
   *
   * @param {MouseEvent} e
   * @param {string} elementName
   * @returns {void}
   */
  handleContextMenu = (e, elementName) => {
    e.preventDefault();
    const { styles, animation } = this.getElementOptions(elementName);
    const { activeTemplate } = this.props;

    if (!activeTemplate.config.editable) {
      noEditableAlert();
      return;
    }

    contextMenu.show({
      id: `elementContextMenu-${elementName}`,
      event: e,
      props: {
        zIndex: styles.zIndex,
        animation,
        target: e.target,
      },
    });
  };

  /**
   * Funkcja aktualizująca style elementu
   *
   * @param {Object} config
   * @returns {void}
   */
  updateElement = config => {
    const { activeTemplate } = this.props;
    const { pushHistory } = this.props;
    const { elementName, mode } = config;

    if (!activeTemplate.config.editable) {
      noEditableAlert();
      return;
    }

    switch (mode) {
      case 'edit':
        break;
      case 'moveUp':
      case 'moveDown':
        this.setZIndex(elementName, mode);
        break;
      case 'delete':
        this.setVisibility(elementName, false);
        break;
      case 'animation':
        this.setAnimation(config);
        break;
      case 'position':
        this.setPosition(config);
        break;
      case 'childrenPosition':
        this.setChildrenPosition(config);
        break;
      case 'style':
        this.setElementStyle(elementName, config);
        break;
      case 'text':
        this.setText(elementName, config);
        break;
      case 'size':
        this.setSize(elementName, config);
        break;
      case 'source':
        pushHistory({ [elementName]: { mediumId: config.mediumId } });
        break;
      default:
        pushHistory(config);
    }
  };

  /**
   * Skróty klawiszowe do zarządania pojedyńczym elementem
   *
   * @param {KeyboardEvent} event
   * @param {string} elementName
   * @returns {void}
   */
  handleKeyDownElement = (event, elementName) => {
    event.preventDefault();
    const { activeTemplate } = this.props;

    if (!activeTemplate.config.editable) {
      noEditableAlert();
      return;
    }

    const { ctrlKey } = event;
    const keyCode = keycode(event);

    if (keyCode === 'delete') {
      this.setVisibility(elementName, false);
    } else if (ctrlKey && this.keyboardShortcuts.moveUp.indexOf(keyCode) > -1) {
      this.setZIndex(elementName, 'moveUp');
    } else if (ctrlKey && this.keyboardShortcuts.moveDown.indexOf(keyCode) > -1) {
      this.setZIndex(elementName, 'moveDown');
    } else if (this.keyboardShortcuts.movePosition.indexOf(keyCode) > -1) {
      this.updatePosition(elementName, keyCode);
    }
  };

  toggleTextEditor = (elementName, target, mode) => {
    const { textEditor: currentState } = this.state;
    const state = { textEditor: { target: null, elementName: '', mode: currentState.mode } };

    if (elementName) {
      state.textEditor = { target, elementName };

      if (mode) {
        state.textEditor.mode = mode;
      }
    }

    this.setState(state);
  };

  updatePosition = (elementName, value) => {
    const { createHistoryElement, pushHistory } = this.props;
    const historyElement = createHistoryElement();
    const newPosition = historyElement.elementsOptions[elementName].position;

    switch (value) {
      case 'up':
        newPosition.y -= 1;
        break;
      case 'right':
        newPosition.x += 1;
        break;
      case 'down':
        newPosition.y += 1;
        break;
      case 'left':
        newPosition.x -= 1;
        break;
      default:
    }

    pushHistory({ [elementName]: { position: { ...newPosition } } });
  };
}
