import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TipsModerationBasket from './TipsModerationBasket';

class TipsModerationListBasket extends Component {
  static propTypes = {
    tipsModerationBasket: PropTypes.instanceOf(Object).isRequired,
    restoreTipModeration: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
  };

  render() {
    const {
      tipsModerationBasket,
      restoreTipModeration,
      fetchTipsModerationBasketForModerators,
      fetchTipsModerationForTipsListPage,
    } = this.props;

    return tipsModerationBasket.map(modtipbasket => (
      <TipsModerationBasket
        key={modtipbasket.id}
        createdAt={modtipbasket.created_at}
        nickname={modtipbasket.nickname}
        email={modtipbasket.email}
        id={modtipbasket.id}
        message={modtipbasket.message}
        amount={modtipbasket.amount}
        goalTitle={modtipbasket.goal ? modtipbasket.goal.title : 'Cel domyślny'}
        paymentType={modtipbasket.payment_method ? modtipbasket.payment_method : ''}
        restoreTipModeration={restoreTipModeration}
        fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
        fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
      />
    ));
  }
}

export default TipsModerationListBasket;
