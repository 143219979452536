import { connect } from 'react-redux';

import { updateConfig } from 'store/actions/configuratorsConfigs';
import { updateTemplate } from 'store/actions/templates';
import { defaultConfigurations } from 'store/data/configurators';
import configuratorsElements from 'store/data/configuratorsElements';

export default function withConfiguration(ComponentWithHistory, configType) {
  const mapStateToProps = state => {
    // note: the state comes from initStore.js
    const { defaults, created, active } = state.templates;
    const stateConfig = state.configuratorsConfigs;

    // Get config data from global store
    const defaultConfig = defaultConfigurations[configType];
    const configuratorConfig = { ...defaultConfig, ...stateConfig.configurations[configType] };
    // Get default element config type's configs
    const elementConfigs = configuratorsElements[configType];

    // Get all the templates for config type
    const defaultTemplates = defaults.filter(template => template.type === configType);
    const createdTemplates = created.filter(template => template.type === configType);
    // const createdTemplates = created.reduce((list, template) => {
    //   if (template.type === configType) list.push(deepmerge(defaultTemplates[0], template));
    //   return list;
    // }, []);
    const allTemplates = [...defaultTemplates, ...createdTemplates];

    // Get active template
    const activeTemplateId = active[configType];
    const activeTemplate = allTemplates.find(template => activeTemplateId === template.id);

    return {
      /** @deprecated */
      elements: elementConfigs,
      elementConfigs,
      allTemplates,
      activeTemplate,
      configuratorConfig,
      configurationsAreFetched: stateConfig.isFetched,

      // could be reduced since we're already passing active template instance
      activeTemplateId,
      templateConfig: { ...activeTemplate.config },
    };
  };

  const mapDispatchToProps = dispatch => ({
    updateConfig(config) {
      dispatch(updateConfig(configType, config));
    },
    updateTemplate(updatedFragment) {
      dispatch(updateTemplate(updatedFragment, null, configType));
    },
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ComponentWithHistory);
}
