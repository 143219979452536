import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hint from 'components/PanelHint';

import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down-small.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up-small.svg';
import { ReactComponent as Close } from 'assets/icons/close-small.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as MoveIn } from 'assets/icons/move-in.svg';
import { ReactComponent as Move } from 'assets/icons/move.svg';
import { ReactComponent as MoveOut } from 'assets/icons/move-out.svg';
import { AnimationSettingsHint } from 'store/data/hints';
import PanelBlock from '../PanelBlock';
import animatesList from '../AnimationsContextMenuList/animatesList';
import ConfigContext from '../../ConfigContext';
import AnimationContextMenu from './AnimationContextMenu';

const Root = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const MenuWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  background-color: color-mix(in srgb, var(--white) 25%, var(--bg-color-white-gray));
  border: solid 1px var(--white);
  border-radius: 16px;
  box-shadow: 0 0 2px 3px var(--bg-color-white-gray);
`;

const WrapperContent = styled.div`
  padding: 98px 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Button = styled.button`
  outline: none;
  box-shadow: none;
  background-color: var(${({ isOpen }) => (isOpen ? '--green' : '--white-white')});
  color: black;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 18px;
  padding: 1px 12px 0;
  height: 26px;
  column-gap: 8px;
  font-size: 10px;
  font-weight: 800;
  transition: var(--hover-transition);

  &:active {
    transform: scale(0.96);
  }
`;

const Spacer = styled.span`
  height: 1.2em;
`;

const SubBlockContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: absolute;
  inset: 0;
`;

const SmallButton = styled(Button)`
  background-color: var(--green);
  height: 22px;

  &:disabled {
    opacity: 0.5;
    background-color: var(--grey);
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
`;

const TogglePlayButton = styled(Button)`
  background-color: var(--white-box-background);
  color: var(--text-grey);
  column-gap: 12px;
  font-size: 10px;
  text-transform: uppercase;

  svg {
    height: 9px;

    path {
      fill: currentColor;
    }
  }
`;

const StyledHint = styled(Hint)`
  font-size: 8px;
  font-weight: 900;
  display: block;
  text-transform: uppercase;
  color: var(--text-grey);
  text-align: left;
`;

const panelSubBlock = {
  fontSize: 12,
  fontWeight: 400,
  paddingRight: 24,
  paddingLeft: 24,
};

const BlockToggleButton = props => {
  const { isOpen } = props;

  if (isOpen) {
    return (
      <Button {...props}>
        <Close />
        Zamknij
      </Button>
    );
  }

  return (
    <Button {...props}>
      Wybierz
      <ChevronDown />
    </Button>
  );
};

BlockToggleButton.propTypes = {
  isOpen: PropTypes.bool,
};

BlockToggleButton.defaultProps = {
  isOpen: false,
};

const BlockMenuButton = ({ isActive, ...props }) => (
  <SmallButton {...props}>
    zmień
    {(isActive && <ChevronUp />) || <ChevronDown />}
  </SmallButton>
);

BlockMenuButton.propTypes = {
  isActive: PropTypes.bool,
};

BlockMenuButton.defaultProps = {
  isActive: false,
};

const meuItems = [
  {
    title: 'Efekt wejścia:',
    startIcon: <MoveIn />,
    directionName: 'in',
    menuId: 'animationIn',
  },
  {
    title: 'Efekt w trakcie:',
    startIcon: <Move />,
    directionName: 'during',
    menuId: 'animationDuring',
  },
  {
    title: 'Efekt wyjścia:',
    startIcon: <MoveOut />,
    directionName: 'out',
    menuId: 'animationOut',
  },
];

const AnimationSettings = props => {
  const { animationConfigs, onSelectAnimation, isElementConfig } = props;

  const { openContextMenu, toggleAnimationPreview, playAnimation } = useContext(ConfigContext);

  const [isOpen, setIsOpen] = useState(false);

  const rootRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    wrapperRef.current.style.height = isOpen ? `${wrapperRef.current.scrollHeight}px` : '100%';
  }, [isOpen, wrapperRef]);

  const handleToggleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handlePlayClick = () => {
    toggleAnimationPreview(true);
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (event.target.contains(rootRef.current)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [rootRef, isOpen]);

  return (
    <Root ref={rootRef}>
      <PanelBlock
        title="Zarządzaj efektami alertu"
        endIcon={<BlockToggleButton isOpen={isOpen} onClick={handleToggleButtonClick} />}
        style={{ zIndex: 2, paddingLeft: 30, paddingRight: 30, columnGap: 'unset' }}
        hint={<AnimationSettingsHint />}
      >
        {'Animacje '}
        {isElementConfig ? 'elementu' : 'widgetu'}
      </PanelBlock>

      <MenuWrapper ref={wrapperRef}>
        <WrapperContent>
          {meuItems.map(menuItem => {
            const activeAnimation = animationConfigs[menuItem.directionName];

            const handleAnimationSelect = animationName => {
              onSelectAnimation(menuItem.directionName, animationName);
            };

            return (
              <React.Fragment key={menuItem.directionName}>
                <PanelBlock
                  title={menuItem.title}
                  startIcon={menuItem.startIcon}
                  endIcon={
                    <BlockMenuButton onClick={evt => openContextMenu(menuItem.menuId, evt)} />
                  }
                  style={panelSubBlock}
                >
                  <Spacer />
                  <SubBlockContent>
                    {activeAnimation.length ? activeAnimation : 'Brak animacji'}
                  </SubBlockContent>
                </PanelBlock>
                <AnimationContextMenu
                  menuId={menuItem.menuId}
                  animationTypes={animatesList[menuItem.directionName].list}
                  activeAnimation={activeAnimation}
                  onAnimationSelect={handleAnimationSelect}
                />
              </React.Fragment>
            );
          })}

          <Bottom style={{ marginTop: 8 }}>
            <TogglePlayButton onClick={handlePlayClick} disabled={!!playAnimation}>
              <Play />
              Testuj
            </TogglePlayButton>

            <StyledHint>
              By animować jeden element - kliknij na niego i zmień animację powyżej
            </StyledHint>
          </Bottom>
        </WrapperContent>
      </MenuWrapper>
    </Root>
  );
};

AnimationSettings.propTypes = {
  animationConfigs: PropTypes.instanceOf(Object).isRequired,
  onSelectAnimation: PropTypes.instanceOf(Object).isRequired,
  isElementConfig: PropTypes.bool,
};

AnimationSettings.defaultProps = {
  isElementConfig: false,
};

export default AnimationSettings;
