import React, { useRef, useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import CopyInput from 'components/CopyInput';
import { showModal } from 'components/SendTestTipModal';

import Button from 'styles/Button';
import copyToClipboard from 'utils/copyToClipboard';

import Title from './PersonalData/components/Title';
import StyledFastCopyDropDownContent from '../../../styles/StyledFastCopyDropDownContent';
import Select from '../styles/Select';

import { Consumer } from '../Context';

const FastCopyDropDownContent = () => {
  const inputsContianer = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(inputsContianer.current.scrollHeight);
  }, []);

  return (
    <>
      <Consumer>
        {({ userDataInfo, templates }) => {
          const getUrl = (configuratorType = '', templateId = '') =>
            `${process.env.REACT_APP_WIDGETS_URL}/${configuratorType}/${userDataInfo.id}/${templateId}`;

          const formatTemplates = configuratorType => {
            const filteredTemplates = templates.filter(
              template => template.type === configuratorType,
            );

            return filteredTemplates.map(template => ({
              label: template.config.title,
              value: template.id,
            }));
          };

          const largestDonatesTemplates = formatTemplates('LARGEST_DONATES');
          const latestDonatesTemplates = formatTemplates('LATEST_DONATES');
          const counterToEndLiveTemplates = formatTemplates('COUNTER_TO_END_LIVE');

          return (
            <Scrollbars style={{ height }}>
              <StyledFastCopyDropDownContent className="text-left" ref={inputsContianer}>
                <Title>Powiadomienie o wiadomości</Title>
                <CopyInput id="tips-notify" value={getUrl('TIP_ALERT')} />

                <Title>Ranking wiadomości</Title>
                <Select
                  value=""
                  options={largestDonatesTemplates}
                  placeholder="Wybierz szablon z rozwijanej listy"
                  onChange={value => copyToClipboard(getUrl('LARGEST_MESSAGES', value.value))}
                />

                <Title>Lista najnowszych wiadomości</Title>
                <Select
                  value=""
                  options={latestDonatesTemplates}
                  placeholder="Wybierz szablon z rozwijanej listy"
                  onChange={value => copyToClipboard(getUrl('LATEST_MESSAGES', value.value))}
                />

                <Title>Odlicznik czasu do końca transmisji</Title>
                <Select
                  value=""
                  options={counterToEndLiveTemplates}
                  placeholder="Wybierz szablon z rozwijanej listy"
                  onChange={value => copyToClipboard(getUrl('COUNTER_TO_END_LIVE', value.value))}
                />

                <div className="text-center send-test-message">
                  <Button rounded withHover clicked width="300px" onClick={showModal}>
                    Wyślij wiadomość testową
                  </Button>
                </div>
              </StyledFastCopyDropDownContent>
            </Scrollbars>
          );
        }}
      </Consumer>
    </>
  );
};

export default FastCopyDropDownContent;
