import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--input-bg-mod);

  width: 46px;
  height: 46px;
`;
