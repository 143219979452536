import styled from 'styled-components';

export default styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  > span {
    line-height: normal;
    text-align: right;
    width: 80px;
  }

  > input {
    width: 130px;
  }
`;
