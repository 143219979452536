import styled from 'styled-components';

export default styled.div`
  margin-top: 1px;
  width: 4px;
  border-radius: 1px;
  height: 8px;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
  background: rgb(255, 255, 255);
  transform: translateX(-2px);
  cursor: pointer;
`;
