export const changeBackground = background => ({
  type: 'CHANGE_BACKGROUND',
  background,
});

export const getBackgroundFromLocalStorage = () => dispatch => {
  const backgroundFromLocalStorage = localStorage.getItem('configurator-background');

  /**
   * Jeżeli pobraliśmy obrazek z localStorage sprawdzamy czy da się go odczytać.
   * Jeżeli nie to ustawiamy domyślny i usuwamy go z localStorage.
   */
  if (backgroundFromLocalStorage) {
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = () => reject();

      img.src = backgroundFromLocalStorage;
    })
      .then(() => dispatch(changeBackground(backgroundFromLocalStorage)))
      .catch(() => localStorage.removeItem('configurator-background'));
  }
};
