import React from 'react';
import PropTypes from 'prop-types';

import BlankList from 'styles/BlankList';
import { openAlert } from 'components/Alert/utils';
import ListItem from './ListItem';
import ListItemDisabled from './ListItemDisabled';

const List = ({ items, onClick }) => (
  <BlankList>
    {items.map(({ id, title, disabled, globallyEnabled }) => {
      if (disabled || !globallyEnabled) {
        return (
          <ListItemDisabled
            key={id}
            onClick={() => {
              openAlert(
                'error',
                'Metoda nieaktywna',
                !globallyEnabled
                  ? 'Wybrana metoda płatności jest chwilowo niedostępna. Proponujemy skorzystanie z innej.'
                  : 'Wybrana metoda wypłaty jest zablokowana. Skontaktuj się z nami.',
              );
            }}
          >
            {title}
          </ListItemDisabled>
        );
      }
      return (
        <ListItem key={id} onClick={() => onClick(id)}>
          {title}
        </ListItem>
      );
    })}
  </BlankList>
);

List.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default List;
