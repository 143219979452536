import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { BaseTextBoxCss } from '../pages/Configurators/components/TextInput';

export default styled(NumberFormat)`
  ${BaseTextBoxCss};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
