import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDisplaySettingsDialogVisibility } from 'store/actions/configuratorsConfigs';

import ConfigContext from '../../../../ConfigContext';
import useViewTemplates from '../../../../hooks/useViewTemplates';

import Modal from './components/Modal';

const DisplaySettings = props => {
  const { configuratorType } = useContext(ConfigContext);
  const { allTemplates } = useViewTemplates(configuratorType);

  const dispatch = useDispatch();
  const isDialogVisible = useSelector(
    state => state.configuratorsConfigs.displaySettingsDialog.open,
  );

  return (
    <Modal
      {...props}
      templates={allTemplates}
      isMounted={isDialogVisible}
      onClose={() => dispatch(setDisplaySettingsDialogVisibility(false))}
    />
  );
};

export default DisplaySettings;
