import http from './http';

/**
 * Check if the argument passed is a boolean
 *
 * @param {*} element
 * @returns {bool}
 */
export const isBoolean = element => typeof element === 'boolean';

/**
 * Check if the argument passed is an object
 *
 * @param {*} element
 * @returns {boolean}
 */
export const isObject = element => typeof element === 'object' && element !== null;

/**
 * Check if the argument passed is an array
 *
 * @param {*} element
 * @returns {boolean}
 */
export const isArray = element => Array.isArray(element);

/**
 * Check if the argument passed is a string
 *
 * @param {*} element
 * @returns {boolean}
 */
export const isString = element => typeof element === 'string' || element instanceof String;

/**
 * Check if the argument passed is a string
 *
 * @param {*} element
 * @returns {boolean}
 */
export const isNumber = element => typeof element === 'number' || element instanceof Number;

/**
 * Check if the argument passed is a null
 *
 * @param {*} element
 * @returns {boolean}
 */
export const isNull = element => element === null;

/**
 * Check if the argument passed is a empty object
 *
 * @param {object} obj
 * @returns {boolean}
 */
export const isEmptyObject = obj => Object.entries(obj).length === 0;

export class PersonalDataValidator {
  validateRemotely = async (validatorName, payload) => {
    const result = await http.patch(`/validators/${validatorName}`, {
      payload,
    });

    const { valid } = result.data;

    return valid;
  };

  nip = async payload => this.validateRemotely('nip', payload);

  street = async payload => this.validateRemotely('street', payload);

  postalCode = async payload => this.validateRemotely('postalcode', payload);

  socialMediaUrl = async payload => this.validateRemotely('socialmedia', payload);

  fullname = async payload => this.validateRemotely('fullname', payload);

  bankNumber = async payload => this.validateRemotely('banknumber', payload);
}
