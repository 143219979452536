import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @keyframes hoverAnimationGreen {
    0% {
      box-shadow: 0 0 0 0 rgba(43, 210, 131, .5);
    }

    100% {
      box-shadow: 0 0 0 25px rgba(43, 210, 131, .0);
    }
  }

  @keyframes hoverAnimationBlue {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 120, 255, .5);
    }

    100% {
      box-shadow: 0 0 0 25px rgba(0, 120, 255, .0);
    }
  }

  @keyframes hoverAnimationWhite {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, .5);
    }

    100% {
      box-shadow: 0 0 0 25px rgba(0, 120, 255, .0);
    }
  }

  @keyframes hoverAnimationYellow {
    0% {
      box-shadow: 0 0 0 0 rgba(253, 190, 0, .5);
    }

    100% {
      box-shadow: 0 0 0 25px rgba(253, 190, 0, .0);
    }
  }

  @keyframes hoverAnimationRed {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 79, 79, .5);
    }

    100% {
      box-shadow: 0 0 0 25px rgba(253, 190, 0, .0);
    }
  }

  @keyframes tipIn {
    0% {
      max-height: 0;
      overflow: hidden;
      margin-bottom: 50px;
      opacity: 0;
    }

    50% {
      opacity: 0;
      max-height: 500px;
      left: -150px;
    }

    80% {
      left: 25px;
    }

    90% {
      left: -10px;
    }

    100% {
      opacity: 1;
      left: 0px;
      overflow: hidden;
      margin-bottom: 0px;
    }
  }

  @keyframes boxTipIn {
    0% {
      padding: 0 35px;
    }
    100% {
      padding: 35px;
    }
  }

  @keyframes increaseLeftPadding {
    0% {
      padding-left: 20px;
    }

    20% {
      padding-left: 60px;
    }

    80% {
      padding-left: 60px;
    }

    100% {
      padding-left: 20px;
    }
  }

  @keyframes loadingFlash {
    0% {
      background-position: -84% 0;
    }
    100% {
      background-position: 142% 0;
    }
  }
`;
