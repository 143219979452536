import { css } from 'styled-components';

export default css`
  body {
    .react-resizable-handle {
      display: none;
      position: absolute;
      right: -10px;
      bottom: -5px;
      cursor: nw-resize;

      border-style: solid;
      border-width: 0 0 20px 20px;
      border-color: transparent transparent var(--white-white) transparent;
    }
`;
