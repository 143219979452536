function getTextShadowParams(cssShadow) {
  if (!cssShadow || cssShadow === 'none') return [0, 0, 0, 0, 0, 0, 8, 0.67];

  const values = cssShadow.match(/(-?\d+\.?\d*)/g);
  const colorValues = cssShadow.match(/\(([^)]+)\)/)[1].split(',');

  return [
    parseInt(colorValues[0], 10),
    parseInt(colorValues[1], 10),
    parseInt(colorValues[2], 10),
    parseFloat(colorValues[3]),
    parseFloat(values[0]),
    parseFloat(values[1]),
    parseFloat(values[2]),
  ];
}

function getTextShadowRGBA(cssShadow) {
  if (cssShadow && cssShadow !== 'none') {
    const params = getTextShadowParams(cssShadow);
    return { r: params[0], g: params[1], b: params[2], a: params[3] };
  }

  return { r: 0, g: 0, b: 0, a: 0 };
}

function updateParam(textShadow, value, index) {
  const params = getTextShadowParams(textShadow);
  params[index] = value;

  return (
    `${params[4]}px ${params[5]}px ${params[6]}px ` +
    `rgba(${params[0]}, ${params[1]}, ${params[2]}, ${params[3]})`
  );
}

function toHexWithAlpha(rgb, hex) {
  let alpha = Math.round(rgb.a * 255).toString(16);
  if (alpha.length < 2) alpha = `0${alpha}`;
  return hex + alpha;
}

export { getTextShadowParams, getTextShadowRGBA, updateParam, toHexWithAlpha };
