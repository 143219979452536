import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Tooltip } from 'components/Tooltip';
import Text from 'components/Text';
import Flexbox from 'components/Flexbox';
import CollapsedWhiteBox from 'components/CollapsedWhiteBox';

import Odometer from './components/Odometer';
import PercentValue from './components/PercentValue';
import ChatIcon from './components/ChatIcon';

const TooltipWrapper = styled.div`
  outline: none;
  cursor: pointer;
`;

const StatisticsBox = ({
  value,
  percent,
  percentTooltip,
  text,
  prefix,
  iconColor,
  localStorageName,
  numberFormat,
}) => (
  <CollapsedWhiteBox style={{ height: 145 }} localStorageName={localStorageName}>
    <Flexbox>
      <ChatIcon iconColor={iconColor} />

      <div>
        <Flexbox align="flex-end">
          <Odometer value={value} format={numberFormat} />
          {!!prefix && (
            <Text weight="black" family="lato" size={30} margin="0 0 4px">
              {prefix}
            </Text>
          )}

          {percent !== undefined &&
            (percentTooltip ? (
              <Tooltip content={percentTooltip} placement="top" theme="dark" trigger="click" arrow>
                <TooltipWrapper>
                  <PercentValue value={percent} />
                </TooltipWrapper>
              </Tooltip>
            ) : (
              <PercentValue value={percent} />
            ))}
        </Flexbox>
        <Text size={15} margin="5px 0 0" color="light-gray">
          {text}
        </Text>
      </div>
    </Flexbox>
  </CollapsedWhiteBox>
);

StatisticsBox.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.number.isRequired,
  percent: PropTypes.number,
  percentTooltip: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  localStorageName: PropTypes.string.isRequired,
  numberFormat: PropTypes.string,
};

StatisticsBox.defaultProps = {
  prefix: '',
  numberFormat: '( ddd),dd',
  percent: undefined,
  percentTooltip: undefined,
};

export default StatisticsBox;
