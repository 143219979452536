import React, { useContext } from 'react';
import Button from 'components/Modal/styles/ItemRedButton';

import { ReactComponent as CloseIcon } from 'assets/icons/close-2.svg';
import ThresholdContext from './ThresholdContext';

const DeleteButton = () => {
  const { onDelete, isUpdating } = useContext(ThresholdContext);

  return (
    <Button clicked onClick={onDelete} disabled={isUpdating}>
      <CloseIcon />
    </Button>
  );
};

export default DeleteButton;
