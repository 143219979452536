import styled from 'styled-components';

import Button from 'styles/Button';

export default styled(Button)`
  padding: 0;
  height: 44px;
  min-width: 44px;
  max-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--error-color);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 5.75px;
  margin: 0;
  transition: var(--hover-transition);

  &:hover {
    background-color: var(--error-color);
    box-shadow: 0 0 26px 0 rgba(255, 79, 79, 0.5);
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      box-shadow: none;
    }
  }

  > svg {
    fill: #fff;
    width: 26px;
  }
`;
