import styled from 'styled-components';

const DashedBorderBox = styled.div`
  padding: 24px 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23404040FF' stroke-width='3' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 16px;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 24px;
`;

export default DashedBorderBox;
