import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlankButton from 'styles/BlankButton';
import UIBlock from 'styles/WhiteBox';

import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-1.svg';

const QuestionBlock = styled(UIBlock)`
  background-color: var(--red);
  color: var(--white);
  margin-bottom: var(--block-spacing);

  display: flex;
  justify-content: space-between;
  padding: 16px 30px;

  button {
    color: inherit;
  }
`;

const ContentBlock = styled(UIBlock)`
  padding: 30px;
  text-align: center;
  margin-bottom: var(--block-spacing);
`;

const ArrowButton = styled(BlankButton)`
  transition: 0.2s ease-out;
  ${props => (props.isExpanded ? `transform: rotate(90deg);` : '')}
`;

function Hint({ question, discardHandler, children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <>
      <QuestionBlock>
        <BlankButton onClick={discardHandler}>
          <CloseIcon width="12px" height="12px" fill="currentColor" />
        </BlankButton>
        <BlankButton onClick={handleToggle}>{question}</BlankButton>
        <ArrowButton onClick={handleToggle} isExpanded={isExpanded}>
          <ArrowRightIcon width="12px" height="12px" fill="currentColor" />
        </ArrowButton>
      </QuestionBlock>

      {isExpanded && <ContentBlock>{children}</ContentBlock>}
    </>
  );
}

Hint.propTypes = {
  question: PropTypes.string.isRequired,
  discardHandler: PropTypes.instanceOf(Function).isRequired,
  children: PropTypes.node.isRequired,
};

export default Hint;
