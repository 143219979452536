import styled from 'styled-components';

import OptionsPopup, { OptionsPopupItem as RootOptionsPopupItem } from 'styles/OptionsPopup';

export const OptionsPopupItem = styled(RootOptionsPopupItem)`
  > p {
    display: flex;
    align-items: center;
  }
`;

export default OptionsPopup;
