import styled from 'styled-components';

import Button from 'styles/Button';

const BackButton = styled(Button).attrs(() => ({
  disableHover: true,
}))`
  padding: 0;
  margin: 17px 0 0;
  color: var(--black);
`;

export default BackButton;
