import PropTypes from 'prop-types';
import React from 'react';

import Hr from 'components/Hr';
import Text from 'components/Text';

const ColumnBottomHelp = ({ children }) => (
  <>
    <Hr />
    <Text size={12} margin="0 0 15px" align="center" color="grey">
      {children}
    </Text>
  </>
);

ColumnBottomHelp.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ColumnBottomHelp;
