import React from 'react';
import PropTypes from 'prop-types';

import FocusedElement from '../../../../../components/FocusedElement';

import ContextMenu from '../ContextMenu';

const UsernameAction = props => {
  const { name, options, animation } = props;

  return (
    <React.Fragment>
      <FocusedElement>
        <p
          id={`${name}__screen_element`}
          className={animation}
          style={{ margin: 0, ...options.styles, backfaceVisibility: 'hidden' }}
        >
          {options.value || (
            <span style={{ fontStyle: 'italic', fontWeight: 300, color: 'var(--error-color)' }}>
              brak tekstu
            </span>
          )}
        </p>
      </FocusedElement>

      <ContextMenu elementName={name} fields={['editText', 'changeText', 'animation']} />
    </React.Fragment>
  );
};

UsernameAction.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  animation: PropTypes.string.isRequired,
};

export default UsernameAction;
