import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';

const Wrapper = styled.div`
  .spinner {
    opacity: 0.5;
    width: 20px;
    height: 20px;

    .spinner-path {
      stroke: var(--text-color);
    }
  }
`;

const Spinner = () => (
  <Wrapper>
    <MaterialSpinner />
  </Wrapper>
);

export default Spinner;
