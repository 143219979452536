import styled, { css } from 'styled-components';

const clicked = css`
  background: var(--brand-blue);
  color: (--text-color);
  box-shadow: 0 0 26px 0 rgba(0, 120, 255, ${props => (props.withHover ? '0' : '0.5')});
`;

export default styled.button`
  background: ${props => (props.background ? props.background : 'var(--light-gray)')};
  border-radius: ${props => (props.rounded ? '100px' : '5px')};
  padding: 15px 25px;
  color: var(--white-white);
  border: ${props => (props.bordered ? '1px' : '0px')} solid var(--border-color);
  cursor: pointer;
  transition: var(--hover-transition);
  outline: none;
  margin: 5px;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 0 0 rgba(0, 120, 255, ${props => (props.withHover ? '0' : '0.5')});
  font-weight: 500;
  z-index: 9;
  ${props => (props.clicked ? clicked : '')};
  ${props => (props.width ? `width: ${props.width}; max-width: 100%;` : '')};
  ${props =>
    !props.disableHover &&
    css`
      &:hover {
        ${clicked};
        ${props.withHover ? 'animation: hoverAnimationBlue .4s ease;' : ''} );
        z-index: 10;
      }
    `};

  &:active {
    transform: scale(0.96);
  }

  svg {
    height: 19px;

    path {
      fill: currentColor;
    }
  }
`;
