import styled, { css } from 'styled-components';

import OptionsPopupItem from 'styles/OptionsPopupItem';
import BlankButton from 'styles/BlankButton';

const activeStyles = css`
  background-color: var(--bg-color-light-secondary-gray);
`;

export default styled(BlankButton)`
  ${OptionsPopupItem}
  padding-left: 35px;

  ${props => props.active && activeStyles};
`;
