import { connect } from 'react-redux';

import { createGoal, fetchGoalsIfNeeded } from 'store/actions/goals';
import { fetchVotingConfig } from 'store/actions/goalsVoting';
import { Goals } from './Goals';

const mapStateToProps = ({
  goals: { items, isFetched: goalsFetched, isPatching },
  goalsVoting: {
    config: { goals: votingGoals },
  },
  templates: { isFetched: templatesFetched },
}) => ({
  goals: items,
  votingGoals: votingGoals ? votingGoals.map(g => g.goal.id) : [],
  ready: goalsFetched && templatesFetched,
  working: isPatching,
});

const mapDispatchToProps = dispatch => ({
  dispatchCreateGoal() {
    dispatch(createGoal());
  },
  fetchDataIfNeeded() {
    dispatch(fetchGoalsIfNeeded());
  },
  fetchVotingConfig() {
    dispatch(fetchVotingConfig());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Goals);
