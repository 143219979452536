import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { Wrapper } from './Wrapper';

const TRANSITION_DURATION = 500; // in miliseconds

const Container = ({ children, snapTo, showIn, onHide, width, ...props }) => {
  const node = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleClickOutside = event => {
    if (node.current && !node.current.contains(event.target)) {
      onHide();
    }
  };

  const getPosition = () => {
    const boundingRect = snapTo.getBoundingClientRect();
    const showInBoundingRect = showIn.getBoundingClientRect();

    const showInBottomValue = showInBoundingRect.top + showInBoundingRect.height + window.scrollY;
    let positionTop = boundingRect.top + boundingRect.height + window.scrollY;

    if (positionTop > showInBottomValue) {
      positionTop = showInBottomValue;
    }

    return {
      top: positionTop,
      left: boundingRect.left + boundingRect.width / 2,
    };
  };

  useEffect(() => {
    setPosition(getPosition());
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleClick = evt => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Wrapper
      ref={node}
      onClick={handleClick}
      style={position}
      unmountDelay={TRANSITION_DURATION}
      width={width}
    >
      {children}
    </Wrapper>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  onHide: PropTypes.instanceOf(Function).isRequired,
  snapTo: PropTypes.instanceOf(HTMLElement),
  showIn: PropTypes.instanceOf(HTMLElement),
  width: PropTypes.number,
};

Container.defaultProps = {
  snapTo: document.body,
  showIn: document.body,
  width: 420,
};

export const Base = ({ isMounted, ...props }) =>
  ReactDOM.createPortal(
    <CSSTransition in={isMounted} timeout={TRANSITION_DURATION} classNames="fade" unmountOnExit>
      <Container {...props} />
    </CSSTransition>,
    document.body,
  );

Base.propTypes = {
  isMounted: PropTypes.bool.isRequired,
};
