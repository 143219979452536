import styled from 'styled-components';

import RangeSliderWrapper from 'components/RangeSlider/components/Wrapper';

export default styled.div.attrs(({ between }) => ({
  justifyContent: between ? 'space-between' : 'flex-end',
}))`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 20px;

  > ${RangeSliderWrapper} {
    width: 40%;
  }

  > span {
    font-family: var(--google-sora-cdn);
    font-size: 12.5px;
  }

  &:empty {
    display: none;
  }
`;
