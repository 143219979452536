import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ColorfulRoundedButton } from 'components/Buttons';

import SettingsButton from './SettingsButton';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ActiveState = ({ onSettingsRequested }) => (
  <Wrapper>
    <ColorfulRoundedButton variant="brand-green" onClick={onSettingsRequested}>
      Aktywna
    </ColorfulRoundedButton>
    <SettingsButton onClick={onSettingsRequested} />
  </Wrapper>
);

export default ActiveState;

ActiveState.propTypes = {
  onSettingsRequested: PropTypes.instanceOf(Function).isRequired,
};
