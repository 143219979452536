import styled from 'styled-components';

export default styled.div.attrs(({ background }) => ({
  background: background === 'dark' ? 'var(--dark-box-background)' : 'var(--white-box-background)',
}))`
  display: grid;
  grid-template-rows: auto 1fr;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;

  max-width: 800px;
  max-height: 1000px;

  @media screen and (min-width: 1680px) {
    max-width: 900px;
    max-height: 1200px;
  }

  @media screen and (min-width: 2500px) {
    max-width: 1200px;
    max-height: 1500px;
  }

  padding: 30px 40px;
  border-radius: 20px;
  border: 1px solid var(--border-color);

  opacity: 0;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  transition: all 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);

  overflow: hidden;
  background-color: ${props => props.background};
`;
