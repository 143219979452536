import React from 'react';
import Paragraph from '../Paragraph';

const Tip100Achievement = () => (
  <div>
    <Paragraph>
      Gratulacje! Od teraz możesz używać wypłat natychmiastowych i otrzymywać pieniądze nawet w
      minutę na swoje konto bankowe lub PayPal (o ile Twój bank spełnia kryteria)! Sprawdź więcej
      informacji na stronie wypłat!
    </Paragraph>
  </div>
);

export default Tip100Achievement;
