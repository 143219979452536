import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import priceFormatter from 'utils/priceFormatter';
import Text from 'components/Text';
import CollapsedWhiteBox from 'components/CollapsedWhiteBox';
import { Tooltip } from 'components/Tooltip';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 3fr) 1fr;
`;

const ButtonStyles = css`
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  border: 0 solid var(--border-color);
  background-color: var(--bg-color-white-gray);
  padding: 4.5px 5.5px;
  transition: background-color 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  > svg {
    width: 24px;
    fill: var(--text-color-dark-gray);
  }
`;

const Button = styled.a`
  ${ButtonStyles};
  &:hover {
    background-color: var(--bg-color-light-gray);
  }
`;

const DisabledPrintButton = styled.span`
  ${ButtonStyles};
  opacity: 0.5;
  cursor: not-allowed;
`;

const CellHeader = ({ label }) => (
  <Text size={14} weight="medium" color="light-gray" noMargin>
    {label}
  </Text>
);

CellHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

const CellValue = ({ value, color }) => (
  <Text size={18} weight="medium" color={color} margin="0 0 2.5px">
    {value}
  </Text>
);

CellValue.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};

CellValue.defaultProps = {
  color: undefined,
};

const Bill = ({ date, number, amount, method, pdfEnabled, pdf, docType, monthPeriod }) => (
  <CollapsedWhiteBox isCollapsible={false} style={{ padding: '50px 70px 50px 100px' }}>
    <Wrapper>
      <div>
        <CellValue value={date} color="blue" />
        <CellHeader label="DATA" />
      </div>
      <div>
        <CellValue value={number} />
        <CellHeader label="NUMER DOKUMENTU" />
      </div>
      {amount && (
        <div>
          <CellValue value={priceFormatter(amount, false)} />
          <CellHeader label="WYPŁACONA KWOTA" />
        </div>
      )}
      {monthPeriod && (
        <div>
          <CellValue value={monthPeriod} />
          <CellHeader label="ZESTAWIENIE MIESIĄCA" />
        </div>
      )}
      {method && (
        <div style={{ minWidth: 200 }}>
          <CellValue value={method} />
          <CellHeader label="METODA WYPŁATY" />
        </div>
      )}
      {docType && (
        <div style={{ minWidth: 200 }}>
          <CellValue value={docType} />
          <CellHeader label="TYP DOKUMENTU" />
        </div>
      )}
      <div>
        {pdfEnabled ? (
          <Button href={pdf} target="_blank" rel="noopener norefferer">
            <EyeIcon />
          </Button>
        ) : (
          <Tooltip
            content="Rachunek z wypłat z tego bilansu dostępny będzie na początku następnego miesiąca"
            placement="top"
            theme="dark"
            trigger="mouseenter"
            arrow
          >
            <DisabledPrintButton>
              <EyeIcon />
            </DisabledPrintButton>
          </Tooltip>
        )}
      </div>
    </Wrapper>
  </CollapsedWhiteBox>
);

Bill.propTypes = {
  date: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  pdf: PropTypes.string.isRequired,
  pdfEnabled: PropTypes.bool,
  amount: PropTypes.number,
  method: PropTypes.string,
  docType: PropTypes.string,
  monthPeriod: PropTypes.string,
};

Bill.defaultProps = {
  pdfEnabled: true,
  amount: null,
  method: null,
  docType: null,
  monthPeriod: null,
};

export default Bill;
