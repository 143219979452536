import React from 'react';
import styled from 'styled-components';

const BaseLogoElement = styled.img`
  display: block;
`;

const TutorialImageElement = styled(BaseLogoElement)`
  width: 340px;
  height: 200px;
`;

export const TutorialImage = props => <TutorialImageElement {...props} />;
