import { connect } from 'react-redux';
import deepmerge from 'deepmerge';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { alert } from 'components/Alert';
import Button from 'components/Button';

import Switcher from 'pages/Configurators/components/Switcher';

import {
  fetchProfile,
  submitChangedContactName,
  submitChangedPayPal,
  submitChangedProfile,
  submitChangedSocialMediaLink,
  submitDelete,
} from 'store/actions/userData';

import ErrorsWrapper from 'styles/ErrorsWrapper';
import SuccessWrapper from 'styles/SuccessWrapper';

import { hop, PersonalDataValidator } from 'utils';
import { isObject } from 'utils/validators';
import logout from 'utils/logout';

import Heading from '../../../../styles/Heading';
import StyledAccountSettingsDropDownContent from '../../../../styles/StyledAcountSettingsDropDownContent';

import BankTransferValidationStatus from './components/BankTransferValidationStatus';
import CountrySelect from './components/CountrySelect';
import FramedText from './components/FramedText';
import PayPalButton from './components/PayPalButton';
import Title from './components/Title';
import UnverifiedUserMessage from './components/UnverifiedUserMessage';

import AlertText from './styles/AlertText';
import DeleteButton from './styles/DeleteButton';
import Input from './styles/Input';

function snakeToCamel(string) {
  return string.replace(/(_\w)/g, m => m[1].toUpperCase());
}

class PersonalData extends Component {
  static propTypes = {
    dispatchSubmitChangedProfile: PropTypes.instanceOf(Function).isRequired,
    dispatchSubmitChangedSocialMediaLink: PropTypes.instanceOf(Function).isRequired,
    dispatchSubmitChangedContactName: PropTypes.instanceOf(Function).isRequired,
    dispatchSubmitDelete: PropTypes.instanceOf(Function).isRequired,
    dispatchFetchProfile: PropTypes.instanceOf(Function).isRequired,
    profile: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const { profile } = props;
    let fieldsData = profile;

    if (profile.pending) {
      fieldsData = profile.pending;
    }

    this.state = {
      fields: {
        fullname: fieldsData.fullname,
        personal_number: fieldsData.personal_number,
        address: {
          street: fieldsData.address.street,
          city: fieldsData.address.city,
          postal_code: fieldsData.address.postal_code,
          country: fieldsData.address.country,
        },
        bank_number: fieldsData.bank_number || '',
        paypal_email: fieldsData.paypal_email || '',
        social_media_link: fieldsData.social_media_link || '',
        is_company: fieldsData.is_company || false,
        contact_name: fieldsData.contact_name || '',
        contact_email: fieldsData.contact_email || '',
      },
      loading: false,
      deleteAccountPassword: '',
      fieldsErrors: {},
      fieldsSuccess: {},
      fullNameNeedConfirm: false,
      fullNameConfirmError: false,
      fullNameConfirm: '',
      validatedNipError: false,
      validatedStreetError: false,
      validatedPostalCodeError: false,
      validatedFullnameError: false,
      socialMediaLinkFatalError: false,
      countrySelectOpen: false,
    };

    this.firstErrorRef = React.createRef();
    this.validator = new PersonalDataValidator();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.firstErrorRef.current) {
      const targetField = this.firstErrorRef.current.previousSibling;
      targetField.focus({ preventScroll: true });
      targetField.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
      this.firstErrorRef.current = null;
    }
  }

  /**
   * Funkcja aktualizująca stan dla loadera
   *
   * @param {boolean} value
   * @returns {void}
   */
  setLoaderState = value => {
    this.setState({
      loading: value,
    });
  };

  /**
   * Funkcja aktualizująca wartości w inputach.
   *
   * @param {Event} event
   * @param {string} nested
   */
  onChangeInput = (event, nested) => {
    let updatedValue = { [event.target.name]: event.target.value };

    if (nested) {
      updatedValue = { [nested]: updatedValue };
    }

    this.setState(prevState => ({
      fields: deepmerge(prevState.fields, updatedValue),
    }));
  };

  onChangeSelect = (value, key) => {
    const updatedValue = { address: { [key]: value } };

    this.setState(prevState => ({
      fields: deepmerge(prevState.fields, updatedValue),
    }));
  };

  digitsOnly = nip => nip.replace(/\D/g, '');

  /**
   * Funkcja aktualizująca wartości w SocialMediaLink patch action.
   *
   * @param {Event} event
   * @param {string} nested
   */
  onChangeSocialMediaLink = (event, nested) => {
    const updatedValue = { [event.target.name]: event.target.value };

    this.setState(prevState => ({
      fields: deepmerge(prevState.fields, updatedValue),
    }));
  };

  onFullNameChange = event => {
    const currentValue = event.target.value;
    const { profile } = this.props;

    if (currentValue !== profile.fullname) {
      this.setState({
        fullNameNeedConfirm: true,
        fullNameConfirmError: false,
        fullNameConfirm: '',
      });
    }

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        fullname: currentValue,
      },
    }));
  };

  onFullNameConfirmChange = event => {
    const currentValue = event.target.value;
    const { fields } = this.state;

    this.setState({
      fullNameConfirmError: currentValue !== fields.fullname,
      fullNameConfirm: currentValue,
    });
  };

  /**
   * Funkcja do wysyłki formularza z danymi osobowymi
   *
   * @param {Event} event
   */
  onSubmit = async event => {
    event.preventDefault();
    let errors = {};
    let socialMediaLinkFatalError = false;
    let validatedNipError = false;
    let validatedStreetError = false;
    let validatedPostalCodeError = false;
    let validatedFullnameError = false;
    let validatedBankError = false;

    this.setState({
      socialMediaLinkFatalError: false,
      validatedNipError: false,
      validatedStreetError: false,
      validatedPostalCodeError: false,
      validatedFullnameError: false,
      validatedBankError: false,
    });
    const {
      dispatchFetchProfile,
      dispatchSubmitChangedProfile,
      dispatchSubmitChangedSocialMediaLink,
      dispatchSubmitChangedContactName,
      profile,
    } = this.props;
    const { fields, fullNameNeedConfirm, fullNameConfirmError, fullNameConfirm } = this.state;
    const profileFields = {
      ...fields,
    };

    this.setLoaderState(true);

    if (!profile.fullname_locked) {
      const validatedFullname = await this.validator.fullname(fields.fullname);

      if (!validatedFullname) {
        validatedFullnameError = true;
        this.setState({
          validatedFullnameError: true,
        });
      }
    }

    const validatedSocialMediaLink = await this.validator.socialMediaUrl(fields.social_media_link);

    if (!this.isProfileChanged(profileFields, profile) && validatedSocialMediaLink) {
      await dispatchSubmitChangedSocialMediaLink({ socialMediaLink: fields.social_media_link });
    }

    if (!validatedSocialMediaLink) {
      socialMediaLinkFatalError = true;
      this.setState({
        socialMediaLinkFatalError: true,
      });
    }

    if (!this.isProfileChanged(profileFields, profile)) {
      await dispatchSubmitChangedContactName({
        contactName: fields.contact_name,
        contactEmail: fields.contact_email,
      });
    }

    const validatedNip = await this.validator.nip(fields.personal_number);
    const validatedBankNumber = await this.validator.bankNumber(fields.bank_number);
    const validatedStreet = await this.validator.street({
      country: fields.address.country,
      street: fields.address.street,
    });

    if (!validatedNip && fields.is_company) {
      validatedNipError = true;
      this.setState({
        validatedNipError: true,
      });
    }

    if (!validatedBankNumber && fields.bank_number) {
      validatedBankError = true;
      this.setState({
        validatedBankError: true,
      });
    }

    if (fields.bank_number.length === 0 && profile.bank_number) {
      validatedBankError = true;
      this.setState({
        validatedBankError: true,
      });
    }

    if (!validatedStreet && fields.address.street) {
      validatedStreetError = true;
      this.setState({
        validatedStreetError: true,
      });
    }

    let normalizedPostalCode = fields.address.postal_code.trim();
    normalizedPostalCode = normalizedPostalCode.toUpperCase();
    fields.address.postal_code = normalizedPostalCode;
    normalizedPostalCode = fields.address.postal_code.replace(/ /g, '');

    const validatedPostalCode = await this.validator.postalCode({
      country: fields.address.country,
      postalCode: normalizedPostalCode,
    });
    if (!validatedPostalCode && fields.address.postal_code) {
      validatedPostalCodeError = true;
      this.setState({
        validatedPostalCodeError: true,
      });
    }

    const fullNameCorrectlyChanged =
      !fullNameNeedConfirm ||
      (fullNameNeedConfirm && !fullNameConfirmError && fullNameConfirm === fields.fullname);

    if (!fullNameCorrectlyChanged) {
      this.setState({
        fullNameConfirmError: true,
      });
    }

    if (
      !profile.pending &&
      !socialMediaLinkFatalError &&
      !validatedNipError &&
      !validatedStreetError &&
      !validatedPostalCodeError &&
      !validatedFullnameError &&
      !validatedBankError &&
      fullNameCorrectlyChanged &&
      this.isProfileChanged(profileFields, profile)
    ) {
      if (!!profile.paypal_email || fields.bank_number.length) {
        try {
          const updated = {
            ...profileFields,
          };

          updated.socialMediaLink = fields.social_media_link;
          updated.bankNumber = fields.bank_number.toUpperCase();
          updated.personalNumber = fields.personal_number;
          updated.isCompany = fields.is_company;
          updated.address = {
            ...fields.address,
            postalCode: fields.address.postal_code,
          };
          updated.contactEmail = fields.contact_email;
          updated.contactName = fields.contact_name;

          delete updated.social_media_link;
          delete updated.bank_number;
          delete updated.personal_number;
          delete updated.is_company;
          delete updated.address.postal_code;
          delete updated.contact_email;
          delete updated.contact_name;

          await dispatchSubmitChangedProfile(updated);

          alert.open({
            config: {
              type: 'loading',
              title: 'Sukces',
              description: (
                <span>Dane osobowe zostały poprawnie zmienione. Teraz czekają na akceptację.</span>
              ),
              confirmButtonText: 'Rozumiem',
            },
          });

          dispatchFetchProfile();
        } catch (error) {
          if (error.response.status === 400) {
            errors = {
              ...errors,
              ...error.response.data,
            };

            this.setState({ fieldsErrors: errors });
          } else {
            alert.open({
              config: {
                type: 'error',
                title: 'Wystąpił bład',
                description: <span>{error.response.data.message}</span>,
                confirmButtonText: 'Innym razem',
              },
            });
          }
        }
      } else {
        errors.bankNumber = {
          errors: ['Wymagany jest Numer konta bankowego lub połączenie z kontem PayPal.'],
        };
      }
    }

    this.setState({ fieldsErrors: errors });
    this.setLoaderState(false);
  };

  isProfileChanged = (fields, profile) => {
    let changed = false;

    for (const fieldName in fields) {
      if (hop(fields, fieldName)) {
        const isProfileField = hop(profile, fieldName);

        if (!isProfileField) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const isNested = isObject(fields[fieldName]);

        if (isNested) {
          if (this.isProfileChanged(fields[fieldName], profile[fieldName])) {
            return true;
          }
        } else if (fields[fieldName] !== profile[fieldName]) {
          if (
            fieldName === 'social_media_link' ||
            fieldName === 'contact_name' ||
            fieldName === 'contact_email'
          ) {
            // eslint-disable-next-line no-self-assign
            changed = changed;
          } else if (fieldName === 'bank_number') {
            if (
              fields[fieldName].toUpperCase().replace('PL', '') !==
              profile[fieldName].replace('PL', '')
            ) {
              changed = true;
            }
          } else {
            changed = true;
          }
        }
      }
    }
    return changed;
  };

  /**
   * Funkcja do usuwania konta po potwierdzeniu hasła
   */
  onDeleteSubmit = async event => {
    const { dispatchSubmitDelete } = this.props;
    const { deleteAccountPassword } = this.state;

    event.preventDefault();

    this.setState(
      {
        loading: true,
      },
      () => alert.update(this.getDeleteConfirmAlertConfig()),
    );

    try {
      await dispatchSubmitDelete({ current_password: deleteAccountPassword });
      this.setState({ fieldsErrors: {} });
      logout();
    } catch (error) {
      if (error.response.status === 400) {
        this.setState(
          {
            fieldsErrors: {
              currentPassword: {
                errors: ['Musisz podać poprawne hasło do swojego konta'],
              },
            },
            loading: false,
          },
          () => alert.update(this.getDeleteConfirmAlertConfig()),
        );
      } else {
        alert.open({
          config: {
            type: 'error',
            title: 'Wystąpił bład',
            description: <span>{error.response.data.message}</span>,
            confirmButtonText: 'Innym razem',
          },
        });
      }
    }

    this.setLoaderState(false);
  };

  getDeleteConfirmAlertConfig = () => {
    const { loading } = this.state;

    return {
      config: {
        type: 'error',
        title: 'Czy na pewno?',
        description: (
          <span>
            Usunięcie konta to ogromna strata.
            <br />
            <br />
            Wraz z usunięciem konta, anulowane zostaną wszelkie zlecenia, działania oraz dane
            przypisane do Twojego konta.
            <br />
            <br />
            Potwierdź swoją decyzję wpisując swoje hasło.
            <br />
            <br />
            <form onSubmit={this.onDeleteSubmit}>
              <Input
                onChange={event => {
                  this.setState({ deleteAccountPassword: event.target.value });
                }}
                placeholder="Twoje hasło"
                type="password"
              />
              {this.renderErrors('currentPassword')}
              <Button
                noWrapper
                block
                background="red"
                loading={loading}
                onClick={this.onDeleteSubmit}
              >
                Usuwamy
              </Button>
            </form>
          </span>
        ),
        confirmButton: false,
        cancelButtonText: 'Jednak nie, żartowałem',
        cancelButton: true,
      },
    };
  };

  /**
   * Rekurencyjna funkcja wyszukująca błędy w zwracanych obiektach
   *
   * @param {Object} fieldsErrors
   * @param {string} fieldName
   * @param {Object} errors
   *
   * @returns {Object}
   */
  findErrors = (fieldsErrors, fieldName, errors = {}) => {
    const keys = Object.keys(fieldsErrors);

    // Jeżeli trafiliśmy na klucz to zwracamy znalezioną wartość klucza
    if (keys.includes(fieldName)) {
      return fieldsErrors[fieldName];
    }

    // Iterujemy po każdym kluczu i wyszukujemy dalej
    let foundErrors = {};
    for (const key in fieldsErrors) {
      if (Object.prototype.hasOwnProperty.call(fieldsErrors, key)) {
        if (fieldsErrors[key] === Object(fieldsErrors[key])) {
          foundErrors = this.findErrors(fieldsErrors[key], fieldName, errors);
        }
      }
    }

    return foundErrors;
  };

  /**
   * Funkcja renderująca błedy dla pola przekazanego w argumencie
   *
   * @param {string} fieldName
   * @returns {(Node|null)}
   */
  renderErrors = fieldName => {
    const { fieldsErrors } = this.state;
    const findErrors = this.findErrors(fieldsErrors, snakeToCamel(fieldName));

    // Sprawdzamy czy obiekt posiada klucz "errors" oraz czy klucz "errors" posiada wartość która jest tablicą
    if (!findErrors.errors && !(findErrors.errors instanceof Array)) return null;

    return (
      <ErrorsWrapper
        ref={this.firstErrorRef.current ? {} : this.firstErrorRef} // assign first reference if none exists
        style={{ margin: '0 0 15px' }}
      >
        {findErrors.errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ErrorsWrapper>
    );
  };

  /**
   * Funkcja renderująca błedy dla pola przekazanego w argumencie
   *
   * @param {string} fieldName
   * @returns {(Node|null)}
   */
  renderSuccess = fieldName => {
    const { fieldsSuccess } = this.state;
    const success = hop(fieldsSuccess, fieldName) ? fieldsSuccess[fieldName] : null;

    if (success === null) return null;

    return (
      <SuccessWrapper style={{ margin: '0 0 15px' }}>
        <li>{success}</li>
      </SuccessWrapper>
    );
  };

  openCountrySelect = () => {
    this.setState({ countrySelectOpen: true });
  };

  closeCountrySelect = () => {
    this.setState({ countrySelectOpen: false });
  };

  render() {
    const {
      fields,
      loading,
      fullNameNeedConfirm,
      fullNameConfirmError,
      socialMediaLinkFatalError,
      validatedNipError,
      validatedStreetError,
      validatedPostalCodeError,
      validatedFullnameError,
      validatedBankError,
      countrySelectOpen,
    } = this.state;
    const { profile, userData } = this.props;

    return (
      <StyledAccountSettingsDropDownContent>
        <Heading>Dane osobowe</Heading>

        {userData.missing_personal_data && !profile.pending ? (
          <UnverifiedUserMessage />
        ) : (
          <form onSubmit={this.onSubmit} noValidate>
            <BankTransferValidationStatus />
            <div className="form-group">
              <Switcher
                text={`${profile.is_company ? ' Aktywne konto Firmowe' : ' Aktywuj konto Firmowe'}`}
                name="is_company"
                value={profile.is_company}
                onChange={() => {}}
              />
            </div>
            <FramedText
              text="Jeżeli chcesz zmienić typ konta na firmowe lub prywatne napisz do nas."
              theme="gray"
            />
            <div className="form-group">
              {profile.is_company && <Title>Nazwa firmy</Title>}
              {!profile.is_company && <Title>Jak się nazywasz?</Title>}
              {!!profile.pending && (
                <FramedText
                  color="white"
                  text="Dane są w trakcie weryfikacji przez administratora serwisu. W miedzyczasie nie możesz edytować wpisanych danych. Weryfikacja trwa zazwyczaj do 24 godzin."
                  theme="red"
                />
              )}
              <Input
                readOnly={!!profile.pending || profile.fullname_locked}
                value={fields.fullname}
                onChange={event => this.onFullNameChange(event)}
                name="fullname"
                placeholder="Imię i Nazwisko i/lub Nazwa firmy*"
                type="text"
                style={
                  profile.fullname_locked ? { backgroundColor: 'var(--bg-color-white-gray)' } : {}
                }
                required
              />
              {this.renderErrors('fullname')}
              {validatedFullnameError && fields.fullname && (
                <FramedText text="Wpisz imię oraz nazwisko" theme="red" />
              )}
              {profile.fullname_locked && (
                <p style={{ margin: '-5px 0 20px' }}>
                  {`Nie możesz zmienić imienia i nazwiska lub danych firmy. W sytuacjach wyjątkowych
                napisz na `}
                  <a href="mailto:ksiegowosc@tipply.pl">ksiegowosc@tipply.pl</a>
                </p>
              )}
              {fullNameNeedConfirm && (
                <>
                  <p style={{ margin: '-5px 0 10px' }}>
                    <strong>Uwaga!</strong>
                    {` Wpisanie imienia i nazwiska lub danych firmy w serwisie jest nieodwracalne. Nie ma możliwości jego
                  zmiany, bądź edycji w przyszłości.`}
                  </p>
                  <Input
                    onChange={event => this.onFullNameConfirmChange(event)}
                    style={{ marginBottom: 30 }}
                    placeholder="Potwierdź Imię i Nazwisko i/lub Nazwa firmy"
                  />
                  {fullNameConfirmError && (
                    <ErrorsWrapper style={{ margin: '-15px 0 30px' }}>
                      <li>
                        W pierwszej i drugiej rubryce należy wpisać takie samo imię i nazwisko lub
                        dane firmy w celu potwierdzenia. Zweryfikuj to i spróbuj ponownie.
                      </li>
                    </ErrorsWrapper>
                  )}
                </>
              )}
              {profile.is_company && (
                <Input
                  readOnly={!!profile.pending || profile.fullname_locked}
                  value={this.digitsOnly(fields.personal_number)}
                  onChange={event => {
                    this.onChangeInput(event);
                  }}
                  name="personal_number"
                  placeholder="NIP"
                  title="Numer NIP"
                  type={
                    !!profile.pending ||
                    (hop(profile, 'personal_number') && profile.personal_number)
                      ? 'text'
                      : 'text'
                  }
                  style={
                    profile.fullname_locked ? { backgroundColor: 'var(--bg-color-white-gray)' } : {}
                  }
                  required
                />
              )}
              {validatedNipError && fields.is_company && (
                <FramedText text="NIP wpisany niepoprawnie." theme="red" />
              )}
              {this.renderErrors('personal_number')}
            </div>

            <div className="form-group">
              <Title>Gdzie streamujesz lub tworzysz swoje treści w internecie?</Title>
              <Input
                readOnly={!!profile.pending}
                value={fields.social_media_link}
                onChange={event => this.onChangeInput(event)}
                name="social_media_link"
                placeholder="Social media link"
                type="text"
              />
              {socialMediaLinkFatalError && <FramedText text="Nieprawidłowy link." theme="red" />}
              {this.renderErrors('socialMediaLink')}
            </div>

            <div className="form-group">
              <Title>Gdzie mieszkasz?</Title>

              <CountrySelect
                value={fields.address.country}
                readOnly={!!profile.pending}
                menuIsOpen={countrySelectOpen}
                onMenuOpen={this.openCountrySelect}
                onMenuClose={this.closeCountrySelect}
                onChange={value => {
                  this.onChangeSelect(value, 'country');
                  setTimeout(this.closeCountrySelect, 100);
                }}
              />
              {this.renderErrors('country')}

              <Input
                readOnly={!!profile.pending}
                value={fields.address.street}
                onChange={event => this.onChangeInput(event, 'address')}
                name="street"
                placeholder="Ulica, numer domu*"
                type="text"
                required
              />
              {validatedStreetError && fields.address.street && (
                <FramedText text="Ulica wpisana niepoprawnie." theme="red" />
              )}
              {this.renderErrors('street')}

              <Input
                readOnly={!!profile.pending}
                value={fields.address.city}
                onChange={event => this.onChangeInput(event, 'address')}
                name="city"
                placeholder="Miasto*"
                type="text"
              />
              {this.renderErrors('city')}

              <Input
                readOnly={!!profile.pending}
                value={fields.address.postal_code}
                onChange={event => this.onChangeInput(event, 'address')}
                name="postal_code"
                placeholder="Kod pocztowy 00-000*"
                type="text"
              />
              {validatedPostalCodeError && fields.address.postal_code && (
                <FramedText text="Kod pocztowy wpisany niepoprawnie." theme="red" />
              )}
              {this.renderErrors('postalCode')}

              <FramedText
                text={`Pamiętaj o podaniu numeru domu w rubryce "Ulica, numer domu". W Twojej miejscowości nie ma nazwy
                ulicy? Wprowadź w <b>Ulica</b> nazwę miejscowości i numer domu, a w <b>Miasto</b> nazwę gminy.`}
                theme="orange"
              />
            </div>
            <div className="form-group">
              <Title>Gdzie wypłacamy zgromadzone środki?</Title>
              <Input
                readOnly={!!profile.pending}
                value={fields.bank_number}
                onChange={event => this.onChangeInput(event)}
                name="bank_number"
                placeholder="Numer konta bankowego"
                type="text"
              />
              {this.renderErrors('bankNumber')}

              <FramedText
                text={`${
                  userData.validated_with_bank_transfer
                    ? '<b>Uwaga:</b> Zmiana numeru konta bankowego będzie wymagała ponownej weryfikacji konta przelewem.<br><br>'
                    : ''
                }Akceptujemy tylko polskie konta bankowe, a przedrostek "PL" dodajemy automatycznie.`}
                theme="gray"
              />

              <PayPalButton />

              <FramedText
                text='<b>Uwaga:</b> Na email PayPal który zostanie wpisany w tej rubryce, będą realizowane bezpośrednie
                transakcje PayPal, które będą umożliwiały płatność PayPal widzom. Transakcje PayPal są bezpośrednie, a
                więc nie trafiają do bilansów konta Tipply.
                <br><br>
                Na podany e-mail będą również realizowane wypłaty PayPal zlecone w panelu wypłat oraz sam e-mail
                zostanie udostępniony widzom w celu poprawnego podpisania Umowy z Widzem.
                <br><br>
                Pamiętaj o tym, że PayPal pobiera od transakcji swoją opłatę w wysokości 2,9% oraz 1,35zł opłaty stałej,
                pobieranej co transakcję, szczegóły:
                <a href="https://www.paypal.com/pl/webapps/mpp/paypal-fees" target="_blank"><b>PayPal</b></a>'
                theme="gray"
              />
            </div>

            <div className="form-group">
              <Title>Jak widzowie mogą się z Tobą skontaktować?</Title>
              <Input
                readOnly={!!profile.pending}
                value={fields.contact_name}
                onChange={event => this.onChangeInput(event)}
                name="contact_name"
                placeholder="Imię Nazwisko i/lub nazwa firmy"
                type="text"
              />

              <Input
                readOnly={!!profile.pending}
                value={fields.contact_email}
                onChange={event => this.onChangeInput(event)}
                name="contact_email"
                placeholder="Adres email do kontaktu ze mną"
                type="email"
              />

              <FramedText
                text="Rubryki te będą udostępnione widzowi, jeżeli chciałby np. zgłosić reklamację do wysłanej
                wiadomości. Dane będą widoczne w Regulaminie Twojej strony wpłat."
                theme="gray"
              />
            </div>

            {socialMediaLinkFatalError && <FramedText text="Nieprawidłowy link." theme="red" />}
            {validatedStreetError && fields.address.street && (
              <FramedText text="Ulica wpisana niepoprawnie." theme="red" />
            )}
            {validatedPostalCodeError && fields.address.postal_code && (
              <FramedText text="Kod pocztowy wpisany niepoprawnie." theme="red" />
            )}
            {validatedNipError && fields.is_company && (
              <FramedText text="NIP wpisany niepoprawnie." theme="red" />
            )}
            {validatedFullnameError && fields.fullname && (
              <FramedText text="Wpisz imię oraz nazwisko" theme="red" />
            )}
            {fullNameConfirmError && (
              <ErrorsWrapper style={{ margin: '-15px 0 30px' }}>
                <li>
                  W pierwszej i drugiej rubryce należy wpisać takie samo imię i nazwisko lub dane
                  firmy w celu potwierdzenia. Zweryfikuj to i spróbuj ponownie.
                </li>
              </ErrorsWrapper>
            )}
            {validatedBankError && (
              <FramedText text="Nieprawidłowy numer konta bankowego." theme="red" />
            )}
            {!profile.pending && (
              <Button
                loading={loading}
                type="submit"
                noWrapper
                block
                background="blue"
                disabled={loading}
              >
                Zapisz zmiany
              </Button>
            )}
          </form>
        )}

        <AlertText>
          Klikając przycisk “usuń konto” Twoje konto oraz dane osobowe zostają trwale usunięte, a
          wszystkie zgromadzone na nim środki przepadają nieodwracalnie.
        </AlertText>
        <DeleteButton
          background="red"
          type="button"
          noWrapper
          onClick={() => alert.open(this.getDeleteConfirmAlertConfig())}
        >
          Usuń konto*
        </DeleteButton>
      </StyledAccountSettingsDropDownContent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchSubmitDelete(data) {
    return dispatch(submitDelete(data));
  },
  dispatchSubmitChangedProfile(profileData) {
    return dispatch(submitChangedProfile(profileData));
  },
  dispatchSubmitChangedPayPal(profileData) {
    return dispatch(submitChangedPayPal(profileData));
  },
  dispatchSubmitChangedSocialMediaLink(profileData) {
    return dispatch(submitChangedSocialMediaLink(profileData));
  },
  dispatchSubmitChangedContactName(profileData) {
    return dispatch(submitChangedContactName(profileData));
  },
  dispatchFetchProfile() {
    return dispatch(fetchProfile());
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(PersonalData);
