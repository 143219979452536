import axios from 'axios';

import http, { apiUrl } from 'utils/http';

import serverAlert from '../utils/serverAlert';
import shouldFetchData from '../utils/shouldFetchData';

const requestTestVoices = () => ({
  type: 'REQUEST_TEST_VOICES',
});

const receiveTestVoices = voices => ({
  type: 'RECEIVE_TEST_VOICES',
  voices,
});

const failureTestVoices = () => ({
  type: 'FAILURE_TEST_VOICES',
});

const fetchTestVoicesConfigurators = () => dispatch => {
  dispatch(requestTestVoices());

  axios
    .all([
      http.get('/text-to-speech/test/google/male'),
      http.get('/text-to-speech/test/google/female'),
    ])
    .then(
      axios.spread((male, female) => {
        dispatch(
          receiveTestVoices({
            GOOGLE_POLISH_MALE: `${apiUrl}${male.data.url}`,
            GOOGLE_POLISH_FEMALE: `${apiUrl}${female.data.url}`,
          }),
        );
      }),
    )
    .catch(error => {
      serverAlert('Wystąpił błąd podczas pobierania testowych głosów syntezatora.');
      dispatch(failureTestVoices());
    });
};

// eslint-disable-next-line import/prefer-default-export
export const fetchTestVoicesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchData(getState().speechSynthesizerVoices)) {
    dispatch(fetchTestVoicesConfigurators());
  }
};
