import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Base } from '../../shared';

import Color from './components/Color';
import FontFamily from './components/FontFamily';
import FontSize from './components/FontSize';
import FontStyle from './components/FontStyle';
import FontTransparency from './components/FontTransparency';
import FontWeight from './components/FontWeight';
import ShadowEditor from './components/ShadowEditor';
import TextAlign from './components/TextAlign';
import TextShadow from './components/TextShadow';
import { TextEditor as Context } from './Context';

import { Confirm, Container, Input, ItemsContent } from './styles';

export class TextEditor extends Component {
  static propTypes = {
    defaultStyle: PropTypes.instanceOf(Object).isRequired,
    isMounted: PropTypes.bool.isRequired,
    unmountDelay: PropTypes.number,
    onChange: PropTypes.instanceOf(Function),
    onChangeText: PropTypes.instanceOf(Function),
    onHide: PropTypes.instanceOf(Function),
    mode: PropTypes.string.isRequired,
    text: PropTypes.string,
    disableTextAlign: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    onChangeText: () => {},
    onHide: () => {},
    text: '',
    disableTextAlign: false,
    unmountDelay: 0,
  };

  constructor(props) {
    super(props);
    const { defaultStyle } = this.props;

    this.state = {
      active: '',
      style: defaultStyle,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { defaultStyle } = this.props;

    if (JSON.stringify(prevProps.defaultStyle) !== JSON.stringify(defaultStyle)) {
      this.updateStyles();
    }
  }

  setActive(value = '') {
    this.setState({ active: value });
  }

  getTextShadowParams = textShadow => {
    const t = textShadow.replaceAll(/\s/g, '');
    const indexOfFirst = t.indexOf('rgba(');
    const indexOfLast = t.indexOf(')');
    let rgb = t.slice(indexOfFirst, indexOfLast);
    let par = t.slice(0, indexOfFirst) + t.slice(indexOfLast);
    let params = [];

    rgb = rgb.replace('rgba(', '');
    rgb = rgb.replace(')', '');
    par = par.replace(')', '');
    par = par.replace(/\s/g, '');
    params = rgb.split(',').concat(par.split('px'));

    return params;
  };

  setParam(textShadow, value, index) {
    const params = this.getTextShadowParams(textShadow);
    params[index] = value;

    const result =
      `${params[4]}px ${params[5]}px ${params[6]}px ` +
      `rgba(${params[0]}, ${params[1]}, ${params[2]}, ${params[3]})`;

    return result;
  }

  getTextShadowRGBA() {
    const { style } = this.state;

    if (style.textShadow) {
      const params1 = this.getTextShadowParams(style.textShadow);
      return { r: params1[0], g: params1[1], b: params1[2], a: params1[3] };
    }

    return null;
  }

  colorMixer = (opacity, color) => {
    let alpha = opacity ? opacity.toString() : '16';
    if (alpha.length < 2) alpha = `0${alpha}`;
    let hex = color;
    if (color.length === 4) hex = `${color}${color.substring(-3)}`;
    if (color.length > 7) hex = `${color.substring(0, 7)}`;

    return hex + alpha;
  };

  updateStyles() {
    const { style } = this.state;
    const { defaultStyle } = this.props;

    if (JSON.stringify(defaultStyle) !== JSON.stringify(style)) {
      const updated = {
        ...style,
        ...defaultStyle,
      };

      this.setState({ style: updated });
    }
  }

  updateShadowColor(rgba) {
    const { style } = this.state;

    let t = this.setParam(style.textShadow, rgba.r, 0);
    t = this.setParam(t, rgba.g, 1);
    t = this.setParam(t, rgba.b, 2);
    t = this.setParam(t, rgba.a, 3);

    this.updateStyle('textShadow', t, true);
  }

  isActive(type) {
    const { active } = this.state;

    return active === type;
  }

  confirm() {
    this.setState({ active: '' });

    this.checkStyleBeforeChange();
  }

  checkStyleBeforeChange(updated = {}) {
    const { onChange } = this.props;
    const { style } = this.state;

    const merged = {
      ...style,
      ...updated,
    };

    if (!(parseInt(merged.fontSize, 10) > 0)) {
      merged.fontSize = 16;
    }

    onChange(merged);
  }

  updateStyle(key, value, confirmed) {
    const { style } = this.state;

    if (key === 'fontTransparency') {
      style.color = this.colorMixer(value, style.color);
    }

    const updated = {
      ...style,
      [key]: value,
    };

    this.setState({ style: updated });

    if (confirmed) {
      this.checkStyleBeforeChange(updated);
    }
  }

  handleOnChangeText(value) {
    const { onChangeText } = this.props;

    onChangeText(value);
  }

  buttonIsActive() {
    const { active } = this.state;
    const styles = [
      'color',
      'fontFamily',
      'fontSize',
      'textAlign',
      'fontTransparency',
      'textShadow',
    ];
    return styles.includes(active);
  }

  render() {
    const { mode, text, disableTextAlign } = this.props;
    const { active, style } = this.state;
    const currentShadowColor = this.getTextShadowRGBA();

    const editorContext = {
      updateStyle: (key, value, confirmed) => this.updateStyle(key, value, confirmed),
      setActive: type => this.setActive(type),
      isActive: type => this.isActive(type),
      confirm: () => this.confirm(),
      isActiveEditor: active,
      isNotStyle: active,
      style,
    };

    const handleColorChangeComplete = ({ rgb, hex }) => {
      let alpha = Math.round(rgb.a * 255).toString(16);
      if (alpha.length < 2) alpha = `0${alpha}`;
      const hexWithAlpha = hex + alpha;
      this.updateStyle('color', hexWithAlpha, true);
    };

    return (
      <Context.Provider value={editorContext}>
        <Base width={disableTextAlign ? 420 : 450} {...this.props}>
          <Container
            mode={mode}
            items={disableTextAlign ? 6 : 7}
            style={{ borderColor: '#A1A1A180' }}
          >
            {mode === 'style' ? (
              <ItemsContent>
                <Color
                  title="Kolor"
                  color={style.color} // react-color prop
                  onClick={() => this.setActive('color')}
                  onChangeComplete={handleColorChangeComplete}
                />
                <FontWeight title="Pogrubienie" style={{ marginLeft: 2 }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    columnGap: 7,
                    marginLeft: 1,
                  }}
                >
                  <TextShadow title="Cień" />
                  <ShadowEditor
                    title="Edytor cienia"
                    color={currentShadowColor}
                    onClick={() => this.setActive('textShadow')}
                    onChangeComplete={({ rgb }) => {
                      this.updateShadowColor(rgb);
                    }}
                  />
                </div>
                <FontStyle title="Styl" style={{ marginLeft: -9 }} />
                <FontSize onClick={() => this.setActive('fontSize')} style={{ marginLeft: -3 }} />
                {disableTextAlign ? null : (
                  <TextAlign
                    title="Wyrównanie"
                    onClick={() => this.setActive('textAlign')}
                    style={{ marginLeft: -3 }}
                  />
                )}
                <FontTransparency
                  title="Przezroczystość"
                  onClick={() => this.setActive('fontTransparency')}
                />
                <FontFamily
                  title="Czcionka"
                  onClick={() => this.setActive('fontFamily')}
                  style={{ marginLeft: -3 }}
                />
              </ItemsContent>
            ) : (
              <Input
                type="text"
                value={text}
                onChange={e => this.handleOnChangeText(e.target.value)}
                text
              />
            )}

            <Confirm isActive={this.buttonIsActive()} onClick={() => this.confirm()}>
              OK
            </Confirm>
          </Container>
        </Base>
      </Context.Provider>
    );
  }
}
