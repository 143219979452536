import styled from 'styled-components';

export default styled.div`
  padding: 2.5px 0;
  margin: 0;

  > span {
    display: inline-block;
    margin: 0 10px 0 0;
  }
`;
