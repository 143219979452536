import styled from 'styled-components';

export default styled.div`
  width: calc(100% - 30px);
  margin: 0 auto 30px;
  padding-top: calc(100% - 30px);
  border-radius: 10px;

  background-color: var(--bg-color-white-gray);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
