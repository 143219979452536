import styled, { css } from 'styled-components';

export default styled.span`
  display: inline-block;
  min-width: 41px;
  width: 41px;
  padding: 4px 6px;
  background-color: var(--bg-color-white-gray);
  border: 1px solid var(--border-color);
  border-radius: 100px;
  font-size: 11px;
  text-align: center;
  color: var(--text-color-dark-gray);

  ${props =>
    props.alternate &&
    css`
      min-width: 42px;
      width: 42px;
      font-size: 12px;
      line-height: 14px;
      background-color: transparent;
      color: hsl(0, 0%, 39%);
      border-color: hsl(0, 0%, 75%);
    `}
`;
