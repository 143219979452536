import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ConfigContext from '../../../ConfigContext';

const Root = styled.div`
  border-radius: 15px;
  border: 1px solid rgba(161, 161, 161, 0.2);
  box-shadow: 0 0 18.8px 2px rgba(0, 0, 0, 0.3);
  background: var(--white);
  box-sizing: border-box;
  transition: height 0.4s ease-in-out;
  transform: scale(0.96);

  position: absolute;
  top: 0;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 63px;
`;

const Content = styled.div`
  padding: 73px 32px 25px;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  position: relative;
  min-height: 100%;
`;

const SlideOutMenu = ({ isExpanded, children, autoHeight, ...props }) => {
  const { centerContentRef } = useContext(ConfigContext);
  const rootRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    const targetRef = autoHeight ? contentRef : centerContentRef;
    rootRef.current.style.height = isExpanded ? `${targetRef.current.clientHeight}px` : `64px`;
    rootRef.current.style.minHeight = isExpanded ? `min-content` : `unset`;
    rootRef.current.style.transform = isExpanded ? `scale(1)` : `scale(.96)`;
    rootRef.current.style.transition = isExpanded
      ? `height .4s ease-in-out`
      : `height .4s ease-in-out, transform 0s ease .4s`;

    const timeout = setTimeout(
      () => {
        rootRef.current.style.overflow = isExpanded ? 'visible' : 'hidden';
      },
      isExpanded ? 400 : 0,
    );
    return () => clearTimeout(timeout);
  }, [isExpanded, centerContentRef, rootRef, contentRef]);

  return (
    <Root ref={rootRef} {...props}>
      <Content ref={contentRef}>{children}</Content>
    </Root>
  );
};

SlideOutMenu.propTypes = {
  autoHeight: PropTypes.bool,
  isExpanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SlideOutMenu.defaultProps = {
  autoHeight: false,
  isExpanded: false,
};

export default SlideOutMenu;
