import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import dateFormatter from 'utils/dateFormatter';
import { convertToZlotys } from 'utils/priceConverter';
import { selectWithdrawals } from 'store/selector';
import { fetchWithdrawalsIfNeeded } from 'store/actions/withdrawals';

import Text from 'components/Text';
import Grid from 'components/Grid';
import Bill from './Bill';

const Withdrawals = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(selectWithdrawals);

  useEffect(() => {
    dispatch(fetchWithdrawalsIfNeeded());
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Text noMargin color="blue">
        POZOSTAŁE DOKUMENTY
      </Text>
      <Grid>
        {items.map(withdrawal => (
          <Bill
            key={withdrawal.withdrawal_id}
            date={dateFormatter.format(new Date(withdrawal.requested_at), 'DD/MM/YYYY')}
            number={withdrawal.invoice_number}
            amount={convertToZlotys(
              new Date(withdrawal.requested_at) >= new Date('2020-04-14T20:01:20Z')
                ? withdrawal.amount - withdrawal.commission
                : withdrawal.amount,
            )}
            method={withdrawal.human_method_name}
            pdfEnabled={withdrawal.can_print_confirmation}
            pdf={`${process.env.REACT_APP_API_URL}/bank/print-confirmation/${withdrawal.withdrawal_id}/pdf`}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default Withdrawals;
