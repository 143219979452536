import styled from 'styled-components';

import Text from 'components/Text';

export default styled(Text)`
  font-family: var(--google-sora-cdn);
  font-size: 12.5px;
  line-height: 20.83px;
  text-align: center;
  margin: 35px 0 20px;
  color: ${props => props.color || 'inherit'};

  &:first-child {
    margin-top: 0;
  }

  span {
    color: ${props => props.innerColor || 'inherit'};
  }
`;
