import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg';
import { ReactComponent as CogIcon } from 'assets/icons/cog.svg';
import ConfigContext from '../ConfigContext';
import ElementActions from './ElementActions';
import { StyledButton } from './ElementActionButton';
import DeleteButton from './DeleteButton';

const AdditionalTextActions = ({ enabled }) => {
  const { focusedElementName, toggleTextEditor, handleRemoveElement } = useContext(ConfigContext);

  const eventHandler = (evt, option, flag = false) => {
    if (enabled) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      toggleTextEditor(focusedElementName, option, flag);
    }
  };

  const handleChangeText = evt => {
    eventHandler(evt, 'text');
  };

  const handleChangeStyle = evt => {
    eventHandler(evt, 'style');
  };

  const handleRemoveClick = () => {
    handleRemoveElement(focusedElementName);
  };

  return (
    <ElementActions>
      <StyledButton onClick={handleChangeText} blue>
        <EditIcon />
        Zmień treść tekstu
      </StyledButton>

      <StyledButton onClick={handleChangeStyle}>
        <CogIcon />
        Zmień wygląd tekstu
      </StyledButton>

      <DeleteButton onClick={handleRemoveClick} />
    </ElementActions>
  );
};

AdditionalTextActions.propTypes = {
  enabled: PropTypes.bool,
};

AdditionalTextActions.defaultProps = {
  enabled: false,
};

export default AdditionalTextActions;
