/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'utils/validators';
import Text from 'components/Text';
import { OptionsMenu, OptionsMenuList } from 'components/OptionsMenu';
import CollapsedWhiteBox from 'components/CollapsedWhiteBox';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';

import InfoTooltip from 'components/InfoTooltip';
import Button from './components/Button';
import { Context } from '../../state';
import { openDisabledAlert } from '../../utils';

const MoneyTransfer = ({ background, text, description, menuOptions, onClickOption, tooltip }) => {
  const node = useRef(null);
  const [visibility, setVisibility] = useState(false);
  const { activeType } = useContext(Context);
  const hasOptions = menuOptions.length > 0;
  const isDisabled = isNull(activeType);

  useEffect(() => {
    const handleClickOutside = e => {
      if (node.current.contains(e.target)) {
        return;
      }

      setVisibility(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = id => {
    if (!isDisabled) {
      onClickOption(id);
    }
  };

  return (
    <CollapsedWhiteBox
      isCollapsible={false}
      disabled={isDisabled}
      onClickDisabled={openDisabledAlert}
    >
      <div style={{ padding: '35px 0' }}>
        <div style={{ position: 'relative' }} ref={node}>
          <Button
            active={visibility}
            background={background}
            onClick={() => {
              if (!isDisabled) {
                if (hasOptions) {
                  setVisibility(prevState => !prevState);
                } else {
                  handleClick();
                }
              }
            }}
            style={{ fontSize: 16 }}
          >
            {text}
            {hasOptions && <ArrowDown />}
          </Button>

          {hasOptions && (
            <OptionsMenu visible={visibility}>
              <OptionsMenuList items={menuOptions} onClick={id => handleClick(id)} />
            </OptionsMenu>
          )}

          <InfoTooltip
            style={{ position: 'absolute', top: 7, right: 7 }}
            content={<Text margin="0" dangerouslySetInnerHTML={{ __html: tooltip }} />}
            placement="right"
            theme="light"
          />
        </div>

        <Text margin="10px 0 0" style={{ fontSize: 13, textAlign: 'center' }}>
          {description}
        </Text>
      </div>
    </CollapsedWhiteBox>
  );
};

MoneyTransfer.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  menuOptions: PropTypes.instanceOf(Array),
  onClickOption: PropTypes.instanceOf(Function).isRequired,
};

MoneyTransfer.defaultProps = {
  menuOptions: [],
};

export default MoneyTransfer;
