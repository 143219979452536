import http from 'utils/http';

import defaultMedia from '../data/defaultMedia';
import shouldFetchData from '../utils/shouldFetchData';

const requestMedia = () => ({
  type: 'REQUEST_MEDIA',
});

const receiveMedia = items => ({
  type: 'RECEIVE_MEDIA',
  items,
});

const updateMedia = (id, updatedData) => ({
  type: 'UPDATE_MEDIA',
  id,
  updatedData,
});

const insertMedium = medium => ({
  type: 'INSERT_MEDIUM',
  medium,
});

const deleteMedia = mediaIds => ({
  type: 'DELETE_MEDIA',
  mediaIds,
});

const updateMediaSpaceUsage = usage => ({
  type: 'UPDATE_MEDIA_SPACE_USAGE',
  usage,
});

const failureMedia = () => ({
  type: 'FAILURE_MEDIA',
});

export const toggleShowTrue = () => ({
  type: 'SHOW_MANAGER_TRUE',
});

export const toggleShowFalse = () => ({
  type: 'SHOW_MANAGER_FALSE',
});

const fetchMedia = () => dispatch => {
  dispatch(requestMedia());

  return http(`/user/media`)
    .then(response => response.data)
    .then(filesData => {
      const files = [...defaultMedia, ...filesData];

      dispatch(receiveMedia(files));

      // Pobieramy obiekt z formatami medium
      filesData.forEach(file => {
        const { id: fileId } = file;
        http.get(`/medium/${fileId}/formats`).then(response => {
          dispatch(updateMedia(fileId, { formats: response.data }));
        });
      });
    })
    .catch(error => {
      dispatch(failureMedia());
    });
};

export const fetchMediaIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchData(getState().mediaManager)) {
    dispatch(fetchMedia());

    http.get('/user/media/usage').then(response => {
      dispatch(updateMediaSpaceUsage(response.data.usage));
    });
  }
};

export const insertMediumToStore = medium => (dispatch, getState) => {
  const {
    mediaManager: { usage },
  } = getState();
  const { id } = medium;
  const updatedUsage = usage + medium.size;

  dispatch(insertMedium(medium));
  dispatch(updateMediaSpaceUsage(updatedUsage));

  return http
    .get(`/medium/${id}/formats`)
    .then(response => dispatch(updateMedia(id, { formats: response.data })));
};

export const deleteMediaFromServer = mediaIds => (dispatch, getState) =>
  http.delete(`/media?ids=${JSON.stringify(mediaIds)}`).then(response => {
    const {
      mediaManager: { items, usage },
    } = getState();

    let deletedWeightOfFiles = 0;

    items.forEach(medium => {
      if (mediaIds.includes(medium.id)) {
        deletedWeightOfFiles += medium.size;
      }
    });

    dispatch(updateMediaSpaceUsage(usage - deletedWeightOfFiles));
    dispatch(deleteMedia(mediaIds));
  });

export const showFontManagerToggle = () => (dispatch, getState) => {
  const { mediaManager } = getState();

  if (mediaManager.showManager) {
    dispatch(toggleShowFalse());
  } else {
    dispatch(toggleShowTrue());
  }
};
