import React from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { Scrollbars } from 'react-custom-scrollbars';

import Hint from 'components/PanelHint';
import { CensorWordsHint, WordFilterHint } from 'store/data/hints';
import Switcher from '../Switcher';

import TagsBox from './styles/TagsBox';
import './styles/FilterWords.scss';
import WordsFilterContainer from './styles/WordsFilterContainer';

const FilterWords = props => {
  const {
    forbiddenWords: { enabled: enableWordsFilter, words: wordList },
    profanityFilter: { enabled: enableProfanityFilter },
    submitUpdatedGlobalConfiguration,
    tagsBoxProps,
  } = props;

  const handleToggleProfanityFilter = checkboxValue =>
    submitUpdatedGlobalConfiguration('profanity_filter', { enabled: checkboxValue });

  const handleToggleWordsFilter = checkboxValue =>
    submitUpdatedGlobalConfiguration('forbidden_words', { enabled: checkboxValue });

  /**
   * Funkcja przechwytująca zdarzenie tworzenia elementu
   *
   * @param {Object} name - obiekt z nazwą tagu
   * @returns {void}
   */
  const addWord = ({ name }) => {
    const parts = name.split(',');
    const temp = [];

    parts.forEach(e => {
      if (e.trim() !== '' && !wordList.includes(e.trim()) && !temp.includes(e.trim())) {
        temp.push(e.trim());
      }
    });

    temp.forEach((e, i) => {
      if (i < temp.length - 1) {
        wordList.push(e.trim());
      } else {
        submitUpdatedGlobalConfiguration('forbidden_words', { words: [...wordList, e.trim()] });
      }
    });
  };

  /**
   * Funkcja przechwytująca zdarzenie usuwania elementu
   *
   * @param {Number} itemIndex - indeks elementu w tablicy
   * @returns {void}
   */
  const removeWord = itemIndex => {
    const newList = [...wordList];
    newList.splice(itemIndex, 1);

    submitUpdatedGlobalConfiguration('forbidden_words', { words: newList });
  };

  const tags = wordList.map(word => ({ name: word }));

  return (
    <React.Fragment>
      <Switcher
        text="Filtr wulgaryzmów AI"
        tooltipContent={<WordFilterHint />}
        name="profanity-filter"
        value={enableProfanityFilter}
        onChange={handleToggleProfanityFilter}
      />

      <WordsFilterContainer>
        <Switcher
          text="Cenzuruj wybrane słowa"
          tooltipContent={<CensorWordsHint />}
          name="filter-words"
          value={enableWordsFilter}
          onChange={handleToggleWordsFilter}
        />

        <TagsBox {...tagsBoxProps}>
          <Scrollbars className="scrollbar" style={{ height: 'calc(100% - 15px)' }}>
            <ReactTags
              placeholder="Wpisz słowo, które chcesz zablokować..."
              tags={tags}
              allowNew
              handleAddition={addWord}
              handleDelete={removeWord}
            />
          </Scrollbars>

          <Hint>
            Wpisz jedno słowo i zatwierdź Enterem.
            <br />
            Wkleić możesz również całą listę słów, oddzielonych przecinkiem.
          </Hint>
        </TagsBox>
      </WordsFilterContainer>
    </React.Fragment>
  );
};

FilterWords.propTypes = {
  profanityFilter: PropTypes.instanceOf(Object).isRequired,
  forbiddenWords: PropTypes.instanceOf(Object).isRequired,
  submitUpdatedGlobalConfiguration: PropTypes.instanceOf(Function).isRequired,
  tagsBoxProps: PropTypes.instanceOf(Object),
};

FilterWords.defaultProps = {
  tagsBoxProps: {},
};

export default FilterWords;
