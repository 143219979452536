import styled from 'styled-components';

export default styled.div`
  position: relative;

  input {
    border: 0 solid white;
    background: var(--light-gray);
    border-radius: 50px;
    width: 305px;
    padding: 12px 10px 12px 50px;
    outline: none;
    color: var(--light-text-color);
    transition: 350ms;

    &:focus {
      width: 350px;

      @media screen and (max-width: 768px) {
        width: inherit;
      }
    }

    ::-webkit-input-placeholder {
      color: #9d9d9d;
    }
    ::-moz-placeholder {
      color: #9d9d9d;
    }
    :-ms-input-placeholder {
      color: #9d9d9d;
    }
    :-moz-placeholder {
      color: #9d9d9d;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    position: absolute;
    top: 11px;
    left: 17px;

    img {
      transition: 200ms;
      width: 20px;
      transform: scale(1) rotate(0);
    }

    &:hover {
      img {
        transform: scale(1.1) rotate(-15deg);
      }
    }
  }
`;
