import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 11px;
  margin: 0 -7.5px;
  z-index: 40;
  pointer-events: unset !important;

  > * {
    margin: 0 7.5px;
  }
`;

export default Buttons;
