import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Button from 'pages/UserPanel/components/ComponentWrapper/styles/Button';
import Container from './styles/Container';

export const COOKIE_NAME = 'cookies_accepted';

export default class Messenger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: typeof Cookies.get(COOKIE_NAME) === 'undefined',
    };
  }

  open() {
    this.setState(() => ({ isOpen: true }), this.saveCookie);
  }

  close() {
    this.setState(() => ({ isOpen: false }), this.saveCookie);
  }

  saveCookie() {
    const { isOpen } = this.state;

    if (!isOpen) {
      Cookies.set(COOKIE_NAME, 1, { expires: 365 });
    } else {
      Cookies.remove(COOKIE_NAME);
    }
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Container style={{ display: isOpen ? 'block' : 'none' }}>
        <span>
          Strona wykorzystuje ciasteczka, abyś czerpał największą przyjemność z korzystania ze
          strony!
        </span>
        <Button
          background="blue"
          style={{ borderColor: 'transparent' }}
          onClick={() => this.close()}
        >
          OK, ROZUMIEM
        </Button>
      </Container>
    );
  }
}
