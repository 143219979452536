import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGTM from 'react-gtm-module';
import http, { authUrl, apiUrl, proxyUrl } from 'utils/http';
import noproxy from 'utils/noproxy';
import clearLocalStorage from 'utils/clearLocalStorage';
import { receiveUserInfo } from 'store/actions/userData';
import { fetchTemplatesIfNeeded } from 'store/actions/templates';
import {
  fetchTipsExternalModerators,
  fetchBasketExternalModerators,
  fetchCurrentModerator,
} from 'store/actions/tipsModeration';

import App from './App';
import ModeratorPanel from './ModeratorPanel';
import ForceAcceptNewTerms from './pages/ForceAcceptNewTerms';
import store from './store';

const renderApp = currentTheme => {
  const tagManagerArgs = {
    gtmId: 'GTM-5SPLRQ8',
  };

  ReactGTM.initialize(tagManagerArgs);

  ReactDOM.render(
    <Provider store={store}>
      <App currentTheme={currentTheme} />
    </Provider>,
    document.getElementById('root'),
  );
};

const renderModeratorPanel = (theme, checkModeratorId, moderatorErrors) => {
  ReactDOM.render(
    <Provider store={store}>
      <ModeratorPanel
        theme={theme}
        checkModeratorId={checkModeratorId}
        moderatorErrors={moderatorErrors}
      />
    </Provider>,
    document.getElementById('root'),
  );
};

const renderMustAcceptNewTerms = () => {
  ReactDOM.render(
    <ForceAcceptNewTerms
      onConfirmed={() => {
        renderApp('dark');
      }}
    />,
    document.getElementById('root'),
  );
};

const { pathname } = window.location;
const checkModeration = pathname.split('/')[1];
const checkModeratorId = pathname.split('/')[2];
let moderatorErrors = 0;

if (
  (checkModeration === 'panel-moderatora' || checkModeration === 'koszyk-moderatora') &&
  checkModeratorId.length === 36
) {
  noproxy
    .get(`/moderatordata/${checkModeratorId}`)
    .then(moddata => {
      if (moddata.data) {
        const currentdate = new Date();
        const constlinkDate = new Date(moddata.data.created);
        constlinkDate.setTime(constlinkDate.getTime() + moddata.data.link_time * 60 * 60 * 1000);

        if (moddata.data.id.length !== 36) {
          moderatorErrors = 1; // no moderator found
        } else if (currentdate > constlinkDate) {
          moderatorErrors = 2; // link time exceeded
        } else {
          store.dispatch(fetchCurrentModerator(moddata.data));
          noproxy
            .get(`/moderation/${checkModeratorId}`)
            .then(response => {
              store.dispatch(fetchTipsExternalModerators(response.data));
            })
            .catch(error => {
              throw error;
            });
          noproxy
            .get(`/moderationbasket/${checkModeratorId}`)
            .then(response => {
              store.dispatch(fetchBasketExternalModerators(response.data));
            })
            .catch(error => {
              throw error;
            });
        }
      } else {
        moderatorErrors = 1; // no moderator found
      }

      renderModeratorPanel('dark', checkModeratorId, moderatorErrors);
    })
    .catch(error => {
      throw error;
    });
} else {
  // TODO: Należałoby zastosować jakiś preloader
  http
    .get('/user')
    .then(response => {
      const { data } = response;

      if (typeof data === 'string' && data === '') {
        window.location.href = `${proxyUrl}/logout`;
        return;
      }

      if (data.redirect_to_missing_data_form) {
        http.get('/user/unset-redirect-missing-form').then(() => {
          window.location.href = `${process.env.REACT_APP_PERSONALDATA_URL}`;
        });
        // eslint-disable-next-line no-constant-condition
      } else if (true || Object.prototype.hasOwnProperty.call(data, 'google_id')) {
        store.dispatch(receiveUserInfo(data));
        store.dispatch(fetchTemplatesIfNeeded());

        if (data.new_statue_accepted) {
          renderApp(data.color_theme);
        } else {
          renderMustAcceptNewTerms();
        }
      } else {
        window.location.href = `${apiUrl}/user/connect-google`;
      }
    })
    .catch(error => {
      if (error.response) {
        const statusCode = error.response.status;

        if ([401, 403].includes(statusCode)) {
          clearLocalStorage();
          window.location.href = `${authUrl}?return_url=${window.location.href}`;
        }
      }

      throw error;
    });
}
