import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Root = styled.div`
  width: inherit;
  height: 600px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 0;

  background-size: cover;
  background-position: center;

  textarea:not([readonly]) {
    border: 1px dashed red;
  }
`;

const Screen = forwardRef((props, ref) => {
  const { active: background } = useSelector(state => state.configuratorsBackground);
  const { children } = props;

  return (
    <Root
      ref={ref}
      {...props}
      style={{ backgroundImage: `url(${background})`, position: 'relative', zIndex: 2 }}
    >
      {children}
    </Root>
  );
});

Screen.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Screen;
