import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { showModal } from 'components/SendTestTipModal';

const CustomButton = styled(Button)`
  width: 100%;

  font-family: var(--google-sora-cdn);
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  padding: 16px 32px;

  color: #fff;
  border-radius: 10px;
  box-shadow: none;

  &::before {
    width: 150px;
    height: 150px;
  }
`;

const SendTestTip = ({ onClick }) => {
  const handleClick = () => {
    if (onClick) onClick();
    showModal();
  };

  return (
    <CustomButton block background="blue" type="button" onClick={handleClick}>
      Wyślij wiadomość testową
    </CustomButton>
  );
};

SendTestTip.propTypes = {
  onClick: PropTypes.instanceOf(Function),
};

SendTestTip.defaultProps = {
  onClick: undefined,
};

export default SendTestTip;
