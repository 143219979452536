import styled, { css } from 'styled-components';
import check from '../../../icons/check.svg';

const checked = css`
  background: var(--brand-blue);
  border: 2px solid var(--brand-blue);
  poition: relative;

  &:after {
    content: url(${check});
    width: 8px;
    height: 8px;
  }
`;

export default styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  ${props => (props.left ? `left: ${props.left};` : 'left: 17px;')}
  ${props => (props.top ? `top: ${props.top};` : 'top: 17px;')}
  border-radius: 5px;
  border: 2px solid var(--white);
  transition: 200ms;
  ${props => (props.checked ? checked : '')}
`;
