import http from 'utils/http';
import noproxy from 'utils/noproxy';
import shouldFetchData from '../utils/shouldFetchData';
import store from '../configureStore.prod';

const requestTipsModeration = () => ({
  type: 'REQUEST_TIPS_MODERATION',
});

const requestTipsModerationBasket = () => ({
  type: 'REQUEST_TIPS_MODERATION_BASKET',
});

const receiveTipModeration = tipModeration => ({
  type: 'RECEIVE_TIP_MODERATION',
  tipModeration,
});

const receiveTipsModeration = tipsModeration => ({
  type: 'RECEIVE_TIPS_MODERATION',
  tipsModeration,
});

const receiveTipsModerationBasket = tipsModerationBasket => ({
  type: 'RECEIVE_TIPS_MODERATION_BASKET',
  tipsModerationBasket,
});

const receiveCurrentModeratorData = currentModerator => ({
  type: 'RECEIVE_CURRENT_MODERATOR_DATA',
  currentModerator,
});

const failureTipsModeration = () => ({
  type: 'FAILURE_TIPS_MODERATION',
});

const failureTipsModerationBasket = () => ({
  type: 'FAILURE_TIPS_MODERATION_BASKET',
});

const approveTipsModeration = mid => ({
  type: 'APPROVE_TIPS_MODERATION',
  mid,
});

const rejectTipsModeration = mid => ({
  type: 'REJECT_TIPS_MODERATION',
  mid,
});

const restoreTipsModeration = mid => ({
  type: 'RESTORE_TIPS_MODERATION',
  mid,
});

const fetchTipsModeration = () => async dispatch => {
  dispatch(requestTipsModeration());

  try {
    const response = await http.get('/user/tipsmoderation?limit=12');

    dispatch(receiveTipsModeration(response.data));
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

const fetchTipsModerationRecords = () => async dispatch => {
  dispatch(requestTipsModeration());

  try {
    const response = await http.get(`/user/tipsmoderation`);

    dispatch(receiveTipsModeration(response.data));
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

const fetchTipsModerationBasket = () => async dispatch => {
  dispatch(requestTipsModerationBasket());

  try {
    const response = await http.get(`/user/tipsmoderation/basket`);

    dispatch(receiveTipsModerationBasket(response.data));
  } catch (error) {
    dispatch(failureTipsModerationBasket());
  }
};

export const fetchTipsModerationIfNeeded = () => (dispatch, getState) => {
  const { tipsModeration } = getState();

  if (shouldFetchData(tipsModeration)) {
    dispatch(fetchTipsModeration());
  }
};

export const fetchTipsModerationForTipsListPage = () => dispatch => {
  dispatch(fetchTipsModerationRecords());
};

export const fetchTipsModerationBasketForModerators = () => dispatch => {
  dispatch(fetchTipsModerationBasket());
};

export const approveTipModeration = id => async dispatch => {
  dispatch(approveTipsModeration(id));

  try {
    await http.post(`/user/tipsmoderation/${id}/approve`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const rejectTipModeration = id => async dispatch => {
  dispatch(rejectTipsModeration(id));

  try {
    await http.post(`/user/tipsmoderation/${id}/reject`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const restoreTipModeration = id => async dispatch => {
  dispatch(restoreTipsModeration(id));

  try {
    await http.post(`/user/tipsmoderation/${id}/restore`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const displayModerationBasketModal = () => ({
  type: 'DISPLAY_MODERATION_BASKET_MODAL',
});

export const hideModerationBasketModal = () => ({
  type: 'HIDE_MODERATION_BASKET_MODAL',
});

export const fetchTipsExternalModerators = data => async dispatch => {
  dispatch(receiveTipsModeration(data));
};

export const fetchCurrentModerator = data => async dispatch => {
  dispatch(receiveCurrentModeratorData(data));
};

export const fetchBasketExternalModerators = data => async dispatch => {
  dispatch(receiveTipsModerationBasket(data));
};

export const approveModerationPanel = id => async dispatch => {
  dispatch(approveTipsModeration(id));

  try {
    await noproxy.post(`/moderationpanel/${id}/panelapprove`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const rejectModerationPanel = id => async dispatch => {
  dispatch(rejectTipsModeration(id));
  dispatch(refreshModerations(id));

  try {
    await noproxy.post(`/moderationpanel/${id}/panelreject`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const restoreModerationPanel = id => async dispatch => {
  dispatch(restoreTipsModeration(id));
  try {
    await noproxy.post(`/moderationpanel/${id}/panelrestore`);
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const receivedTipModeration = tipModeration => dispatch =>
  dispatch(receiveTipModeration(tipModeration));

export const refreshModerations = id => async dispatch => {
  const { pathname } = window.location;
  const checkModeratorId = pathname.split('/')[2];

  try {
    await noproxy
      .get(`/moderation/${checkModeratorId}`)
      .then(response => {
        store.dispatch(fetchTipsExternalModerators(response.data));
      })
      .catch(error => {
        throw error;
      });
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const refreshBasket = id => async dispatch => {
  try {
    await noproxy
      .get(`/moderationbasket/${id}`)
      .then(response => {
        store.dispatch(fetchBasketExternalModerators(response.data));
      })
      .catch(error => {
        throw error;
      });
  } catch (error) {
    dispatch(failureTipsModeration());
  }
};

export const toggleMessageAudio = mute => dispatch => {
  http.post('/user/toggle-message-audio').catch(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });
};

export const refreshTipsModerationListOnTip = tipsModeration => dispatch => {
  dispatch(receiveTipsModeration(tipsModeration));
};
