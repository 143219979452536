import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledPaginationBar from '../styles/StyledPaginationBar';

class PaginationBar extends Component {
  static propTypes = {
    paginationItemsCount: PropTypes.number.isRequired,
    changePaginationCount: PropTypes.instanceOf(Function).isRequired,
    changePaginationPagePrev: PropTypes.instanceOf(Function).isRequired,
    changePaginationPageNext: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showPaginationList: false };

    this.paginationItemsValues = [5, 10, 15, 20, 25, 50];
  }

  handleListClick(value) {
    const { changePaginationCount } = this.props;
    this.setState({ showPaginationList: false });

    changePaginationCount(value);
  }

  toggleItemsList() {
    const { showPaginationList } = this.state;
    this.setState({ showPaginationList: showPaginationList === false });
  }

  renderItemsValuesList() {
    const { showPaginationList } = this.state;

    if (showPaginationList) {
      const paginationListItems = this.paginationItemsValues.map(item => (
        <li key={item}>
          <button type="button" onClick={() => this.handleListClick(item)}>
            {item}
          </button>
        </li>
      ));

      const paginationList = <ul className="pagination-List">{paginationListItems}</ul>;
      return paginationList;
    }

    return '';
  }

  render() {
    const { paginationItemsCount, changePaginationPagePrev, changePaginationPageNext } = this.props;

    return (
      <StyledPaginationBar className="box">
        <p className="no-margin pagination-info">Liczba wierszy na stronę:</p>
        <p className="pagination-value">{paginationItemsCount}</p>
        <div className="list-wrap">
          <button type="button" className="down" onClick={() => this.toggleItemsList()}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 292.362 292.362"
              style={{ enableBackground: 'new 0 0 292.362 292.362' }}
              xmlSpace="preserve"
            >
              <path
                d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
              />
            </svg>
          </button>
          {this.renderItemsValuesList()}
        </div>
        <button type="button" className="left" onClick={changePaginationPagePrev}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 240.8 240.8"
            style={{ enableBackground: 'new 0 0 240.8 240.8' }}
            xmlSpace="preserve"
          >
            <path
              d="M57.6,129l108.3,108.3c4.8,4.7,12.5,4.7,17.2,0c4.8-4.7,4.8-12.4,0-17.2l-99.7-99.7
		l99.7-99.7c4.8-4.7,4.8-12.4,0-17.2c-4.8-4.7-12.5-4.7-17.2,0L57.6,111.8C52.9,116.5,52.9,124.3,57.6,129z"
            />
          </svg>
        </button>

        <button type="button" className="right" onClick={changePaginationPageNext}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 240.8 240.8"
            style={{ enableBackground: 'new 0 0 240.8 240.8' }}
            xmlSpace="preserve"
          >
            <path
              d="M183.2,111.8L74.9,3.6c-4.8-4.7-12.5-4.7-17.2,0c-4.8,4.7-4.8,12.4,0,17.2l99.7,99.7
		l-99.7,99.7c-4.8,4.7-4.8,12.4,0,17.2c4.8,4.7,12.5,4.7,17.2,0L183.2,129C187.9,124.3,187.9,116.5,183.2,111.8z"
            />
          </svg>
        </button>
      </StyledPaginationBar>
    );
  }
}

export default PaginationBar;
