import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as GoalsIcon } from 'assets/icons/mainmenu/goals.svg';

import { showGoalsToggle } from 'store/actions/tiplist';

import { MIN_TABLET } from 'styles/base/breakpoints';

const ToggleButton = styled.button`
  appearance: none !important;
  background-color: var(--white);
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  @media (${MIN_TABLET}) {
    margin-bottom: 0;
  }

  > span {
    color: var(--white-black);
  }

  svg {
    background-color: var(--bg-color-light-gray);
    fill: var(--white-black);
  }

  &:hover {
    > span {
      color: var(--brand-blue);
    }

    svg {
      fill: var(--brand-blue);
    }
  }
`;

const ToggleGoals = () => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.tiplist.showGoals);

  const handleToggle = () => {
    dispatch(showGoalsToggle());
  };

  return (
    <ToggleButton type="button" onClick={handleToggle}>
      <GoalsIcon width={22} />
      <span>{`${show ? 'Ukryj' : 'Pokaż'} nazwę celu`}</span>
    </ToggleButton>
  );
};

export default ToggleGoals;
