import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import FocusedElement from '../../../../../../../components/FocusedElement';
import ContextMenuElement from '../../../../../../../components/ContextMenuElement';

import MarginX from '../styles/MarginX';
import ElementWrapper from '../styles/ElementWrapper';

import elementProps from '../data/elementsProps';

const GoalNumbersItem = ({
  elementName,
  options,
  onSelectedOption,
  onDoubleClick,
  onKeyDown,
  isEditable,
}) => {
  if (options.isVisible) {
    const ref = useRef();

    return (
      <MarginX>
        <FocusedElement onKeyDown={onKeyDown}>
          <ContextMenuElement
            id={`${elementName}-element-GoalNumbers`}
            elementName={elementName}
            options={elementProps[elementName].menuOptions}
            onSelectedOption={option => onSelectedOption(option, ref.current)}
            isEditable={isEditable}
          >
            <ElementWrapper
              style={{ ...options.styles }}
              ref={ref}
              data-type={elementName}
              onDoubleClick={() => onDoubleClick(ref.current)}
            >
              {options.textValue || elementProps[elementName].textValue}
            </ElementWrapper>
          </ContextMenuElement>
        </FocusedElement>
      </MarginX>
    );
  }

  return null;
};

GoalNumbersItem.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  elementName: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  onSelectedOption: PropTypes.instanceOf(Function),
  onDoubleClick: PropTypes.instanceOf(Function),
  onKeyDown: PropTypes.instanceOf(Function),
};

GoalNumbersItem.defaultProps = {
  onSelectedOption: ref => {},
  onDoubleClick: ref => {},
  onKeyDown: event => {},
};

export default GoalNumbersItem;
