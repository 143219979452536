import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styled from 'styled-components';
import LinkIcon from 'assets/icons/components/Link';
import Button from 'styles/Button';

const StyledButton = styled(Button)`
  color: #ffffff;
  background-color: var(--brand-blue);
  height: 50px;
  width: auto;
  border-radius: 25px;
  padding: 10px 22px;
  margin: 36px auto 0;
`;

const Content = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 18px;
`;

const Text = styled.span`
  color: currentColor;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  font-weight: bold;
  transform: translateY(0.04em);
`;

const TextOverlay = styled.span`
  background-color: var(--brand-blue);
  color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TEXT_DEFAULT = 'Skopiuj URL do swojej strony';
const TEXT_COPIED = 'Skopiowano!';

const CopyUserUrlButton = ({ link }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied, setCopied]);

  return (
    <CopyToClipboard text={`https://tipply.pl/@${link}`} onCopy={() => setCopied(true)}>
      <StyledButton>
        <Content>
          <LinkIcon />
          <Text>{TEXT_DEFAULT}</Text>

          {copied && (
            <TextOverlay>
              <Text>{TEXT_COPIED}</Text>
            </TextOverlay>
          )}
        </Content>
      </StyledButton>
    </CopyToClipboard>
  );
};

CopyUserUrlButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CopyUserUrlButton;
