import React, { useContext } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconFontSize } from 'assets/icons/font-size-2.svg';
import { TextEditor } from '../Context';
import { Option, OptionText, OptionIcon, AbsoluteLayer, AnimationToggleOption } from '../styles';
import { Range } from '../../../shared';

const TYPE = 'fontSize';
const TEXT_TRANSFORM = 'textTransform';

const RangeWrapper = styled.div`
  width: calc(100% - 60px);
  padding: 0 15px 0 25px;
`;

const TextTransformOptionText = styled(OptionText)`
  color: var(--text-color);
`;

const transformOptions = [
  { option: 'none', label: 'Brak' },
  { option: 'uppercase', label: 'Duże litery' },
  { option: 'lowercase', label: 'Małe litery' },
];

const getTransformIndex = value => {
  const index = transformOptions.findIndex(opt => opt.option === value);
  return index < 0 ? 0 : index;
};

const getTransformIndexOption = value => transformOptions[getTransformIndex(value)].option;

const getNexTransform = value => {
  const nextIndex = (getTransformIndex(value) + 1) % transformOptions.length;
  return transformOptions[nextIndex].option;
};

const getTransformLabel = value => {
  const index = getTransformIndex(value);
  return transformOptions[index].label;
};

const FontSize = props => {
  const {
    style: { fontSize, textTransform },
    updateStyle,
    isActive,
    isNotStyle,
  } = useContext(TextEditor);

  return (
    <Option {...props}>
      <AnimationToggleOption title="Rozmiar" isInactive={isNotStyle} delayOut={0.15}>
        <OptionIcon isActive>
          <IconFontSize />
        </OptionIcon>
      </AnimationToggleOption>

      <AbsoluteLayer isActive={isActive(TYPE)} style={{ zIndex: 5, paddingLeft: 20 }}>
        <Option
          onClick={() => {
            updateStyle(TEXT_TRANSFORM, getNexTransform(textTransform), true);
          }}
        >
          <TextTransformOptionText
            title={getTransformLabel(textTransform)}
            style={{ textTransform: getTransformIndexOption(textTransform) }}
          >
            <IconFontSize />
          </TextTransformOptionText>
        </Option>
        <RangeWrapper>
          <Range
            value={fontSize}
            valuePrefix="px"
            min={1}
            max={99}
            onChange={newValue => updateStyle(TYPE, newValue)}
            onAfterChange={newValue => updateStyle(TYPE, newValue, true)}
          />
        </RangeWrapper>
      </AbsoluteLayer>
    </Option>
  );
};

export default FontSize;
