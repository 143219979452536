import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tip from './Tip';

class TipsList extends Component {
  static propTypes = {
    resendTip: PropTypes.instanceOf(Function).isRequired,
    deleteTip: PropTypes.instanceOf(Function).isRequired,
    tips: PropTypes.instanceOf(Object).isRequired,
    moderationMode: PropTypes.bool.isRequired,
    showGoals: PropTypes.bool.isRequired,
  };

  render() {
    const { tips, resendTip, deleteTip, moderationMode, showGoals } = this.props;

    return tips.map(tip => {
      const {
        id,
        created_at: createdAt,
        nickname,
        email,
        message,
        amount,
        source,
        human_method_name: methodName,
        goal_title: goalTitle,
        consumed,
        audio_url: audioUrl,
        fromSocket,
      } = tip;

      return (
        <Tip
          key={id}
          createdAt={createdAt}
          nickname={nickname}
          email={email}
          id={id}
          message={message}
          amount={amount}
          source={source}
          resendTip={resendTip}
          deleteTip={deleteTip}
          paymentType={methodName}
          goalTitle={goalTitle}
          consumed={consumed}
          hideConsumedStatus={fromSocket}
          moderationMode={moderationMode}
          showGoals={showGoals}
          audioUrl={audioUrl}
        />
      );
    });
  }
}

export default TipsList;
