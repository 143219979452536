import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectStatistics } from 'store/selector';
import { convertToZlotys } from 'utils/priceConverter';
import Grid from 'components/Grid';
import Text from 'components/Text';
import StatisticsBox from 'components/StatisticsBox';

const AnnotationInfoWrapper = styled.div`
  padding: 6px;
`;

const Statistics = () => {
  const {
    data: { income },
  } = useSelector(selectStatistics);

  return (
    <React.Fragment>
      <Grid columns="1fr 1fr 1fr" rows="145px">
        <StatisticsBox
          value={convertToZlotys(income.total)}
          text="Wszystkich zarobków *"
          iconColor="blue"
          localStorageName="monetaryStatisticsSummary"
        />
        <StatisticsBox
          value={convertToZlotys(income.current_month)}
          percent={income.current_month_percent_change}
          percentTooltip="W porównianiu do poprzedniego miesiąca"
          text="Ten miesiąc"
          iconColor="purple"
          localStorageName="monetaryStatisticsMonth"
        />
        <StatisticsBox
          value={convertToZlotys(income.last7)}
          percent={income.last7_percent_change}
          percentTooltip="W porównaniu do poprzedniego tygodnia"
          text="Ostatni tydzień"
          iconColor="green"
          localStorageName="monetaryStatisticsWeek"
        />
      </Grid>
      <AnnotationInfoWrapper>
        <Text size={12}>
          {'* Powyższe statystyki '}
          <strong>nie</strong>
          {' zawierają już wpłat dokonanych w zewnętrznych serwisach, takich jak StreamElements, '}
          {'Tipeeestream i PayPal'}
        </Text>
      </AnnotationInfoWrapper>
    </React.Fragment>
  );
};

export default Statistics;
