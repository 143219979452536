export default {
  TIP_ALERT: {
    message: {
      title: 'Wiadomość',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
    },
    price: {
      title: 'Kwota',
      defaults: {
        position: {
          x: 360,
          y: 280,
        },
      },
    },
    visualObject: {
      title: 'Obrazek 1',
      defaults: {
        position: {
          x: 170,
          y: 174,
        },
      },
    },
    visualObject1: {
      title: 'Obrazek 2',
      defaults: {
        position: {
          x: 170,
          y: 174,
        },
      },
    },
    visualObject2: {
      title: 'Obrazek 3',
      defaults: {
        position: {
          x: 170,
          y: 174,
        },
      },
    },
    visualObject3: {
      title: 'Obrazek 4',
      defaults: {
        position: {
          x: 170,
          y: 174,
        },
      },
    },
    username: {
      title: 'Nazwa użytkownika',
      defaults: {
        position: {
          x: 323,
          y: 214,
        },
      },
    },
    usernameAction: {
      title: 'Tekst 1',
      defaults: {
        position: {
          x: 377,
          y: 214,
        },
      },
    },
    usernameAction1: {
      title: 'Tekst 2',
      defaults: {
        position: {
          x: 377,
          y: 214,
        },
      },
    },
    usernameAction2: {
      title: 'Tekst 3',
      defaults: {
        position: {
          x: 377,
          y: 214,
        },
      },
    },
    usernameAction3: {
      title: 'Tekst 4',
      defaults: {
        position: {
          x: 377,
          y: 214,
        },
      },
    },
  },
  LARGEST_DONATES: {
    list: {
      title: 'Lista',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
      children: {
        counter: {
          title: 'Numer',
        },
        nickname: {
          title: 'Nazwa użytkownika',
        },
        colon: {
          title: 'Dwukropek',
        },
        price: {
          title: 'Kwota',
        },
      },
    },
  },
  LATEST_DONATES: {
    list: {
      title: 'Lista',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
      children: {
        counter: {
          title: 'Numer',
        },
        nickname: {
          title: 'Nazwa użytkownika',
        },
        colon: {
          title: 'Dwukropek',
        },
        price: {
          title: 'Kwota',
        },
      },
    },
  },
  TIPS_GOAL: {
    goalName: {
      title: 'Nazwa celu',
      defaults: {
        position: {
          x: 202.5,
          y: 56,
        },
      },
    },
    progressBar: {
      title: 'Pasek postępu',
      defaults: {
        position: {
          x: 47.5,
          y: 120,
        },
      },
    },
    goalNumbers: {
      title: 'Cel',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
      children: {
        from: {
          title: 'Kwota do zdobycia',
        },
        separator: {
          title: 'Separator',
        },
        to: {
          title: 'Kwota zdobyta',
        },
        percent: {
          title: 'Procent',
        },
      },
    },
    amountPaid: {
      title: 'Wpłacona kwota',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
    },
  },
  COUNTER_TO_END_LIVE: {
    textInput: {
      title: 'Godzina zakończenia',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
    },
    additionalTime: {
      title: 'Przyrost czasu',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
    },
    text: {
      title: 'Tekst',
      defaults: {
        position: {
          x: 221,
          y: 326,
        },
      },
    },
    visualObject: {
      title: 'Tło',
      defaults: {
        position: {
          x: 170,
          y: 174,
        },
      },
    },
  },
};
