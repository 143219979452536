import React from 'react';
import PropTypes from 'prop-types';
import { Item, Submenu } from 'react-contexify';

import { alignOptions } from './constants';
import AlignListItem from './AlignListItem';

const AlignItem = ({ align, active, onSelect }) => (
  <Item style={{ justifyContent: 'flex-start' }} onClick={() => onSelect(align)}>
    <AlignListItem align={align} active={active} />
  </Item>
);

AlignItem.propTypes = {
  align: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.instanceOf(Function).isRequired,
};

const AlignListSubmenu = ({ onSelectedOption, current }) => (
  <Submenu label="Zmień wyrównanie listy">
    {alignOptions.map(item => (
      <AlignItem key={item} align={item} active={item === current} onSelect={onSelectedOption} />
    ))}
  </Submenu>
);

AlignListSubmenu.propTypes = {
  onSelectedOption: PropTypes.instanceOf(Function).isRequired,
  current: PropTypes.string.isRequired,
};

export default AlignListSubmenu;
