import React from 'react';
import styled from 'styled-components';

import { MIN_TABLET } from 'styles/base/breakpoints';

import StyledToolbar from '../styles/StyledToolbar';

import FilterButton from './FilterButton';
import ToggleGoals from './ToggleGoals';
import Search from './Search';

const LeftPart = styled.div`
  @media (${MIN_TABLET}) {
    display: flex;
    gap: 25px;
  }
`;

const Toolbar = () => (
  <StyledToolbar className="box">
    <LeftPart>
      <FilterButton
        filterOptions={[
          { key: 'default', value: 'Filtruj według daty wpłynięcia' },
          { key: 'paymentMethod', value: 'Filtruj według metody płatności' },
          { key: 'amount', value: 'Filtruj według wysokości' },
        ]}
      />
      <ToggleGoals />
    </LeftPart>
    <Search />
  </StyledToolbar>
);

export default Toolbar;
