import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import bulletTriangle from 'icons/bullet-triangle.svg';
import { StreamElementsLogo, TipeeeLogo } from 'styles/Logas';
import { ButtonWithAnimatedArrow } from 'components/Buttons';
import InfoTooltip from 'components/InfoTooltip';

const Columns = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  height: 100%;
`;

const ColumnWrapper = styled.div`
  background-color: var(--light-gray);
  padding: 23px 15px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .logo-container {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  ul {
    list-style: none;
    line-height: 1.58;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 120px;

    li {
      padding-left: 22px;
      position: relative;
      margin-bottom: 14px;

      red {
        color: red;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        background: url(${bulletTriangle}) no-repeat;
        width: 8px;
        height: 12px;
      }
    }
  }

  .bottom > div {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
`;

const Tooltip = styled(InfoTooltip)`
  width: 12px;
  height: 12px;
  position: relative;
  top: -5px;
  margin-left: 4px;

  svg {
    width: 2px;
  }
`;

const ProviderSelector = ({ children, logo, onSelect }) => (
  <ColumnWrapper>
    <div>
      <div className="logo-container">{logo}</div>
      {children}
    </div>
    <div className="bottom">
      <ButtonWithAnimatedArrow onClick={onSelect} centered>
        Wybierz
      </ButtonWithAnimatedArrow>
    </div>
  </ColumnWrapper>
);

const Step2 = ({ processStep }) => (
  <Columns>
    <ProviderSelector onSelect={() => processStep('stream_elements')} logo={<StreamElementsLogo />}>
      <ul>
        <li>
          {'Stała prowizja płatności '}
          <strong>aż 82% - 90gr</strong>
          <Tooltip
            content="Za płatność w StreamElements np. z kwoty 5,00 zł otrzymasz 3,20 zł a więc ~64% całej kwoty.
            Płatności są stałe i nie różnią się zależnie od kwoty, jak to jest w Tipeee. Szczegóły:
            https://streamelements.com/dashboard/tipping/fees"
          />
        </li>
        <li>
          {'Środki dostępne do wypłaty po '}
          <strong>16-21 dniach</strong>
        </li>
        <li>
          {'Czas trwania wypłaty to średnio '}
          <strong>2-3 dni</strong>
          {' robocze'}
        </li>
        <li>
          {'Wymóg '}
          <strong>100 obserwacji</strong>
          {' do aktywacji płatności'}
        </li>
        <li>
          <strong>
            <red>Wymóg logowania</red>
          </strong>
          {' przy płatności widzów'}
        </li>
        <li>
          <strong>
            {'Kompletny '}
            <red>brak opóźnień</red>
          </strong>
          {' alertów'}
          <Tooltip
            content="Tipply w porównaniu do innych platform korzystających z podobnego rozwiązania,
            rozwiązało odpowiednio technologicznie <b>opóźnienie płatności<b> między Tipeee lub StreamElements.
            U nas opóźnienia nie występują! Płatności docierają natychmiast na Twój ekran po płatności na
            stronie np. Tipeee."
          />
        </li>
        <li>
          {'Weryfikacja danych '}
          <strong>od +500&nbsp;€, i od +5k&nbsp;€</strong>
          <Tooltip
            content="Serwis weryfikuje podane dane osobowe dowodem tożsamości, oraz innymi dokumentami,
            po przekroczeniu odpowiedniego progu zarobków. Więcej szczegółów znajdziesz bezpośrednio na stronie."
          />
        </li>
        <li>
          {'Obsługa '}
          <strong>waluty PLN</strong>
          {' jak i wielu innych'}
        </li>
      </ul>
    </ProviderSelector>
    <ProviderSelector onSelect={() => processStep('tipeee')} logo={<TipeeeLogo />}>
      <ul>
        <li>
          {'Zmienna prowizja '}
          <strong>od 63% do 80%</strong>
          <Tooltip
            content="Za płatność w TipeeeStream np. z kwoty 1 EUR (4,50 zł) otrzymasz 0.63 EUR (2,85 zł)
            a więc ~63% całej kwoty. Nie ma tu opłat groszowych jak w StreamElements. Jednakże z płatności
            Tipeee, otrzymasz więcej przy większych kwotach, np. przy 20,00 EUR, prowizja wynosi aż
            80%, a więc 16,00 EUR, jest dla Ciebie (~`72,00 zł). Szczegóły:
            https://www.tipeeestream.com/fees"
          />
        </li>
        <li>
          {'Środki dostępne do wypłaty '}
          <strong>co poniedziałek</strong>
        </li>
        <li>
          {'Czas trwania wypłaty to średnio '}
          <strong>2-3 dni</strong>
          {' robocze'}
        </li>
        <li>
          <strong>Brak wymagań</strong>
          {' dot. aktywacji płatności'}
        </li>
        <li>
          <strong>
            <red>Brak wymogu logowania</red>
          </strong>
          {' przy płatnościach'}
        </li>
        <li>
          <strong>
            {'Kompletny '}
            <red>brak opóźnień</red>
          </strong>
          {' alertów'}
          <Tooltip
            content="Tipply w porównaniu do innych platform korzystających z podobnego rozwiązania,
            rozwiązało odpowiednio technologicznie opóźnienie płatności między Tipeee lub
            StreamElements. U nas opóźnienia nie występują! Płatności docierają natychmiast na Twój
            ekran po płatności na stronie np. Tipeee."
          />
        </li>
        <li>
          {'Weryfikacja danych '}
          <strong>od +1k €, i od +5k €</strong>
          <Tooltip
            content="Serwis weryfikuje podane dane osobowe dowodem tożsamości, oraz innymi
            dokumentami, po przekroczeniu odpowiedniego progu zarobków. Więcej szczegółów
            znajdziesz bezpośrednio na stronie."
          />
        </li>
        <li>
          <strong>Brak obsługi PLN,</strong>
          {' tylko EUR lub USD'}
        </li>
      </ul>
    </ProviderSelector>
  </Columns>
);

export default Step2;

ProviderSelector.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.string.isRequired,
  onSelect: PropTypes.instanceOf(Function).isRequired,
};

Step2.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
};
