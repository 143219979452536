import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CONFIGURATOR_TYPE } from '../constants';

const Container = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const PreviewIFrame = ({ activeTemplate }) => {
  const userId = useSelector(state => state.userData.info.id);

  return (
    <Container
      src={`${process.env.REACT_APP_WIDGETS_URL}/${CONFIGURATOR_TYPE}/${userId}/${activeTemplate.id}?previewMode`}
    />
  );
};

PreviewIFrame.propTypes = {
  activeTemplate: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PreviewIFrame;
