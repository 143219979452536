import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import InfoTooltip from 'components/InfoTooltip';

function colorVar(colorName) {
  switch (colorName) {
    case 'yellow':
      return '#202020';
    case 'blue':
    case 'green':
      return '#fff';
    case 'white':
      return 'var(--white)';
    default:
      return 'var(--black)';
  }
}

function bgColorVar(colorName) {
  switch (colorName) {
    case 'blue':
      return '--blue';
    case 'green':
      return '--green';
    case 'yellow':
      return '--yellow';
    case 'white':
      return '--black';
    default:
      return '--white';
  }
}

const flashAnimation = css`
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(251, 251, 251, 0.05),
    rgba(251, 251, 251, 0.07),
    rgba(251, 251, 251, 0.1),
    rgba(251, 251, 251, 0.16),
    rgba(251, 251, 251, 0.05),
    transparent
  );
  background-size: 45% 100%;
  background-position: -90% 0;
  background-repeat: no-repeat;
`;

const flashOnceStyle = css`
  animation: loadingFlash 0.4s ease;
`;

const defaultStyle = css`
  font-family: var(--google-sora-cdn);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 26px;

  font-size: 15px;
  font-weight: 600;
  line-height: normal;
`;

const boxStyle = css`
  border-radius: 15px;
  color: ${({ color }) => (color ? colorVar(color) : `var(--black)`)};
  background: var(${({ color }) => (color ? bgColorVar(color) : '--white-box-background')});
  box-sizing: border-box;
  padding: 16px 32px;
  outline: none;
  border: 1px solid transparent;
  position: relative;
  transition: all 0.25s ease-in-out;

  ${flashAnimation};
  ${({ flashOnce }) => flashOnce && flashOnceStyle};
`;

export { defaultStyle, boxStyle };

const IconContainer = styled.span`
  display: flex;
  min-width: 22px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  color: ${({ $color }) => ($color ? 'inherit' : '#606060')};
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  flex-grow: 1;
  text-align: left;
`;

const ContentMain = styled.span`
  display: flex;
  column-gap: 8px;
  align-items: center;
  position: relative;
`;

const PanelBoxContent = ({ children, title, startIcon, endIcon, hint, ...rest }) => (
  <React.Fragment>
    {startIcon && <IconContainer>{startIcon}</IconContainer>}
    <Content>
      {!!title && <Title $color={rest.color}>{title}</Title>}
      <ContentMain>
        {children}
        {!!hint && <InfoTooltip content={hint} placement="right" theme="dark" />}
      </ContentMain>
    </Content>
    {endIcon && <IconContainer>{endIcon}</IconContainer>}
  </React.Fragment>
);

PanelBoxContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PanelBoxContent.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  color: undefined,
  hint: undefined,
};

export default PanelBoxContent;
