import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  toggleVerifyWithBankWizard,
  updateUserHasPendingValidationRequest,
} from '../../store/actions/userData';
import http from '../../utils/http';
import logout from '../../utils/logout';
import { openAlert } from '../Alert/utils';
import Dialog from '../Dialog';
import CloseButton from '../Dialog/components/CloseButton';
import TwoColumnsContent from '../Dialog/components/TwoColumnsContent';
import WizardProgress from '../Dialog/components/WizardProgress';
import BankTransferStep from './steps/BankTransferStep';
import { ForcedConfirmationStep, NormalConfirmationStep } from './steps/ConfirmationStep';
import ConfirmDataStep from './steps/ConfirmDataStep';
import { ForcedFirstStep, NormalFirstStep } from './steps/FirstStep';
import SocialMediaStep from './steps/SocialMediaStep';
import UploadConfirmationStep from './steps/UploadConfirmationStep';

const VerifiedUserWizard = () => {
  const {
    showVerifyWithBankTransferModal,
    info: { bank_transfer_validation_requested: forced },
  } = useSelector(state => state.userData);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    socialProfile1: null,
    socialProfile2: null,
    socialProfile3: null,
    noSocialProfile: false,
  });
  const [working, setWorking] = useState(false);

  const toggleVisibility = isVisible => {
    dispatch(toggleVerifyWithBankWizard(isVisible));
  };

  useEffect(() => {
    if (forced) {
      toggleVisibility(true);
    }
  }, [forced]);

  const nextStep = returnedData => {
    if (returnedData) {
      if (returnedData.noSocialProfile) {
        setData({
          socialProfile1: null,
          socialProfile2: null,
          socialProfile3: null,
          noSocialProfile: true,
        });
      } else {
        setData(returnedData);
      }
    }
    setStep(prevState => prevState + 1);
  };

  const sendData = () => {
    setWorking(true);
    http
      .post('/user/new-bank-transfer-validation-request', data)
      .then(() => {
        setWorking(false);
        dispatch(updateUserHasPendingValidationRequest(true));
        nextStep();
      })
      .catch(() => {
        setWorking(false);
        openAlert(
          'error',
          'Błąd zapisu',
          'Ups, wystąpił błąd podczas wysyłania danych, spróbuj ponownie później 🥲',
        );
      });
  };

  const stepTitle = forced ? 'Wymóg potwierdzenia tożsamości' : 'Uzyskanie statusu weryfikacji';
  let stepContent = '';
  let type = forced ? 'danger' : 'normal';

  switch (step) {
    case 1:
      stepContent = forced ? (
        <ForcedFirstStep processStep={nextStep} />
      ) : (
        <NormalFirstStep processStep={nextStep} />
      );
      break;
    case 2:
      stepContent = <ConfirmDataStep processStep={nextStep} />;
      break;
    case 3:
      stepContent = <SocialMediaStep processStep={nextStep} />;
      break;
    case 4:
      stepContent = <BankTransferStep processStep={nextStep} />;
      break;
    case 5:
      stepContent = <UploadConfirmationStep processStep={sendData} working={working} />;
      break;
    case 6:
      type = forced ? 'danger' : 'success';
      stepContent = forced ? (
        <ForcedConfirmationStep processStep={logout} />
      ) : (
        <NormalConfirmationStep processStep={() => toggleVisibility(false)} />
      );
      break;
    default:
      break;
  }

  return (
    <Dialog isVisible={showVerifyWithBankTransferModal} noPadding noBorder width={720}>
      {!forced && <CloseButton onClick={() => toggleVisibility(false)} />}
      <TwoColumnsContent
        title={stepTitle}
        underTitle={<WizardProgress step={step} total={5} />}
        type={type}
      >
        {stepContent}
      </TwoColumnsContent>
    </Dialog>
  );
};

export default VerifiedUserWizard;
