import styled, { css } from 'styled-components';

const start = css`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  justify-content: start;
`;

const between = css`
  display: flex;
  justify-content: space-between;
`;

export default styled.div`
  ${props => (props.position === 'start' ? start : between)}

  margin-top: 20px;
`;
