import TIP_ALERT from './TIP_ALERT';
import LARGEST_DONATES from './LARGEST_DONATES';
import LATEST_DONATES from './LATEST_DONATES';
import TIPS_GOAL from './TIPS_GOAL';
import COUNTER_TO_END_LIVE from './COUNTER_TO_END_LIVE';
import GOAL_VOTING from './GOAL_VOTING';

/**
 * @param {object[]} items
 * @param {string} name
 * @returns {object[]}
 */
const formatTemplates = (items, type) =>
  items.map((config, index) => ({
    id: `DEFAULT_${type}_${index + 1}`,
    type,
    config,
  }));

const templates = [
  ...formatTemplates(TIP_ALERT, 'TIP_ALERT'),
  ...formatTemplates(LARGEST_DONATES, 'LARGEST_DONATES'),
  ...formatTemplates(LATEST_DONATES, 'LATEST_DONATES'),
  ...formatTemplates(TIPS_GOAL, 'TIPS_GOAL'),
  ...formatTemplates(COUNTER_TO_END_LIVE, 'COUNTER_TO_END_LIVE'),
  ...formatTemplates(GOAL_VOTING, 'GOAL_VOTING'),
];

export default templates;
