import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import GradientTooltip from 'components/GradientTooltip';

import {
  Wrapper,
  Container,
  Confirm,
  GradientButton,
  GradientContainer,
  GradientText,
} from './styles';

import Color from './components/Color';
import Gradient from './components/Gradient';
import { assembleHexWithAlpha } from './utils';

export default class ColorPicker extends Component {
  static propTypes = {
    defaultColor: PropTypes.string.isRequired,
    snapTo: PropTypes.instanceOf(Element),
    isMounted: PropTypes.bool.isRequired,
    unmountDelay: PropTypes.number.isRequired,
    onChange: PropTypes.instanceOf(Function),
    onHide: PropTypes.instanceOf(Function),
    showIn: PropTypes.instanceOf(Element),
    stripes: PropTypes.bool.isRequired,
    pickerEnabled: PropTypes.bool.isRequired,
    gradientColor: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    onHide: () => {},
    showIn: document.body,
    snapTo: document.body,
    gradientColor: null,
  };

  constructor(props) {
    super(props);
    const { defaultColor, gradientColor, stripes, pickerEnabled } = this.props;

    this.rootElement = document.getElementById('root');

    let showPicker = true;
    if (pickerEnabled === 'progressColor' && stripes) {
      showPicker = false;
    }

    this.state = {
      active: 'color',
      position: this.getPosition(),
      color: defaultColor,
      color2: gradientColor,
      showGradientPicker: gradientColor && defaultColor !== gradientColor && showPicker,
      showPicker,
    };

    this.node = React.createRef();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  getPosition() {
    const { snapTo, showIn } = this.props;

    const boundingRect = snapTo.getBoundingClientRect();
    const showInBoundingRect = showIn.getBoundingClientRect();

    const showInBottomValue = showInBoundingRect.top + showInBoundingRect.height + window.scrollY;
    let positionTop = boundingRect.top + boundingRect.height + window.scrollY;

    if (positionTop > showInBottomValue) {
      positionTop = showInBottomValue;
    }

    return {
      top: positionTop,
      left: boundingRect.left + boundingRect.width / 2,
    };
  }

  setStyle(defaultColor) {
    this.setState({
      color: defaultColor,
    });
  }

  getStyle(type) {
    const { color } = this.state;

    return color;
  }

  handleClickOutside = e => {
    const { onHide } = this.props;

    if (this.node.current.contains(e.target)) {
      return;
    }

    onHide();
  };

  updateColor(value, confirmed) {
    const { onChange } = this.props;

    this.setState({
      color: value,
      color2: value,
    });

    if (confirmed) {
      onChange(value, value);
    }
  }

  updateGradientColor(value, value2) {
    const { onChange } = this.props;

    this.setState({
      color: value,
      color2: value2,
    });

    onChange(value, value2);
  }

  isActive(type) {
    const { active } = this.state;

    return active === type;
  }

  confirm() {
    const { onHide } = this.props;

    onHide();
  }

  showGradientPicker() {
    this.resetColor();

    this.setState({
      showGradientPicker: true,
    });
  }

  resetColor() {
    const { color } = this.state;
    const { onChange } = this.props;

    this.setState({
      color,
      color2: color,
    });

    onChange(color, color);
  }

  showSolidPicker() {
    this.resetColor();

    this.setState({
      showGradientPicker: false,
    });
  }

  render() {
    const { color, position, color2, showGradientPicker, showPicker } = this.state;
    const { isMounted, unmountDelay } = this.props;

    return ReactDOM.createPortal(
      <Wrapper ref={this.node} style={{ ...position }} unmountDelay={unmountDelay}>
        {!showGradientPicker && (
          <>
            <Container isMounted={isMounted}>
              <Color
                color={color}
                onChangeComplete={e => {
                  this.updateColor(assembleHexWithAlpha(e), true);
                }}
              />
              <Confirm isActive onClick={() => this.confirm()}>
                OK
              </Confirm>
            </Container>
            <GradientContainer>
              {!showPicker && (
                <>
                  <GradientTooltip
                    style={{ transform: 'translate(-5px, 10px)' }}
                    placement="top"
                    theme="gradientalert"
                    trigger="click"
                    content="Aby móc użyć gradientu na pasku postępu, należy wcześniej wyłączyć animacje paska. Wyłącz ja klikając PPM > Wyłącz animację w paski"
                  />
                </>
              )}
              {showPicker && (
                <GradientButton isActive onClick={() => this.showGradientPicker()}>
                  +
                </GradientButton>
              )}
              <GradientText>Utwórz gradient</GradientText>
            </GradientContainer>
          </>
        )}
        {showGradientPicker && (
          <>
            <Container isMounted={isMounted}>
              <Gradient
                color={color}
                color2={color2}
                onChange={(mainColor, gradientColor) => {
                  this.updateGradientColor(mainColor, gradientColor);
                }}
              />
              <Confirm isActive onClick={() => this.confirm()}>
                OK
              </Confirm>
            </Container>
            <GradientContainer style={{ transform: 'translateY(310px)' }}>
              <GradientButton isActive onClick={() => this.showSolidPicker()}>
                -
              </GradientButton>
              <GradientText>Usuń gradient</GradientText>
            </GradientContainer>
          </>
        )}
      </Wrapper>,

      document.body,
    );
  }
}
