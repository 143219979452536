import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonWithAnimatedArrow } from '../../Buttons';
import SingleFileUploadButton from '../../SingleFileUploadButton';
import Spinner from '../components/Spinner';
import StepWrapper from '../components/StepWrapper';
import { StepPropTypes } from '../types';

const FileTypesAnnotation = styled.p`
  color: var(--gray);
  padding-top: 24px;
`;

const UploadConfirmationStep = ({ processStep, working }) => {
  const [fileNotSelected, setFileNotSelected] = useState(true);

  return (
    <StepWrapper>
      <p>
        Czyżbyś na pewno przesłał przelew weryfikacyjny o który poprosiliśmy w poprzednim kroku?
      </p>
      <p>
        By to sprawdzić, musimy prosić Cię o załączenie potwierdzenia przelewu, który przed chwilą
        potwierdziłeś, że wykonałeś.
      </p>
      <p>
        Jeśli przelewu nie zrobiłeś, zrób wniosek od nowa. Załącz potwierdzenie przelewu, by przejść
        dalej.
      </p>
      <p style={{ marginBottom: 50 }}>
        <a
          href="https://www.google.com/search?q=jak+uzyska%C4%87+potwierdzenie+transakcji+w+banku"
          rel="noopener noreferrer"
          target="_blank"
        >
          Jak uzyskać potwierdzenia przelewu?
        </a>
      </p>
      <SingleFileUploadButton onChange={e => setFileNotSelected(false)} />
      <FileTypesAnnotation>Wymagany plik w formacie .pdf</FileTypesAnnotation>
      <div className="button-container">
        <ButtonWithAnimatedArrow onClick={processStep} disabled={working || fileNotSelected}>
          Prześlij wniosek
        </ButtonWithAnimatedArrow>
        {working && <Spinner />}
      </div>
    </StepWrapper>
  );
};

UploadConfirmationStep.propTypes = {
  ...StepPropTypes,
  working: PropTypes.bool.isRequired,
};

export default UploadConfirmationStep;
