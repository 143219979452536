import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { externalTipsSourceConnected } from 'store/actions/userData';
import Dialog from 'components/Dialog';
import CloseButton from 'components/Dialog/components/CloseButton';
import TwoColumnsContent from 'components/Dialog/components/TwoColumnsContent';
import WizardProgress from 'components/Dialog/components/WizardProgress';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import StepFinal from './steps/StepFinal';
import StepNoSEPAYButton from './steps/StepNoSEPAYButton';

import StreamElementsHasAccountStep4 from './steps/StreamElements/HasAccount/Step4';
import StreamElementsHasAccountStep5 from './steps/StreamElements/HasAccount/Step5';
import StreamElementsHasAccountStep6 from './steps/StreamElements/HasAccount/Step6';

import StreamElementsNoAccountStep4 from './steps/StreamElements/NoAccount/Step4';
import StreamElementsNoAccountStep5 from './steps/StreamElements/NoAccount/Step5';
import StreamElementsNoAccountStep6 from './steps/StreamElements/NoAccount/Step6';
import StreamElementsNoAccountStep7 from './steps/StreamElements/NoAccount/Step7';
import StreamElementsNoAccountStep8 from './steps/StreamElements/NoAccount/Step8';
import StreamElementsNoAccountStep9 from './steps/StreamElements/NoAccount/Step9';

import StreamElementsStepForm from './steps/StreamElements/StepForm';

import TipeeeStreamHasAccountStep4 from './steps/TipeeeStream/HasAccount/Step4';
import TipeeeStreamHasAccountStep5 from './steps/TipeeeStream/HasAccount/Step5';

import TipeeeStreamNoAccountStep4 from './steps/TipeeeStream/NoAccount/Step4';
import TipeeeStreamNoAccountStep5 from './steps/TipeeeStream/NoAccount/Step5';
import TipeeeStreamNoAccountStep6 from './steps/TipeeeStream/NoAccount/Step6';
import TipeeeStreamNoAccountStep7 from './steps/TipeeeStream/NoAccount/Step7';
import TipeeeStreamNoAccountStep8 from './steps/TipeeeStream/NoAccount/Step8';
import TipeeeStreamNoAccountStep9 from './steps/TipeeeStream/NoAccount/Step9';
import TipeeeStreamNoAccountStep10 from './steps/TipeeeStream/NoAccount/Step10';
import TipeeeStreamNoAccountStep11 from './steps/TipeeeStream/NoAccount/Step11';

import TipeeeStreamStepForm from './steps/TipeeeStream/StepForm';

const ConnectWizard = ({ history }) => {
  const [totalSteps, setTotalSteps] = useState(7);
  const [step, setStep] = useState(1);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [hasAccount, setHasAccount] = useState(false);
  const [noSEPAYButton, setNoSEPAYButton] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentProvider === 'stream_elements' && hasAccount) {
      setTotalSteps(8);
    } else if (currentProvider === 'stream_elements' && !hasAccount) {
      setTotalSteps(11);
    } else if (currentProvider === 'tipeee' && hasAccount) {
      setTotalSteps(7);
    } else if (currentProvider === 'tipeee' && !hasAccount) {
      setTotalSteps(13);
    } else {
      setTotalSteps(7);
    }
  }, [currentProvider, hasAccount]);

  const nextStep = () => {
    let next = step + 1;
    if (next > totalSteps) {
      next = totalSteps;
    }
    setStep(next);
  };

  const prevStep = () => {
    let prev = step - 1;
    if (prev < 1) {
      prev = 1;
    }
    setStep(prev);
  };

  const setNoSEPAY = () => {
    setNoSEPAYButton(true);
  };

  const resetSEPAY = () => {
    setNoSEPAYButton(false);
    setStep(1);
  };

  let stepTitle = 'Łączenie konta - postępuj zgodnie ze wskazówkami';
  let stepContent = null;
  let compactRightColumn = true;

  switch (step) {
    case 1:
      compactRightColumn = false;
      stepTitle = 'Aktywowanie metody Paysafecard';
      stepContent = <Step1 processStep={() => nextStep()} />;
      break;
    case 2:
      stepTitle = 'Porównaj oferty i warunki wymienionych stron';
      stepContent = (
        <Step2
          processStep={selectedProvider => {
            setCurrentProvider(selectedProvider);
            nextStep();
          }}
        />
      );
      break;
    case 3:
      stepTitle = 'Jesteś pewien? Potwierdź wybór platformy';
      stepContent = (
        <Step3
          processStep={accountConnected => {
            setHasAccount(accountConnected);
            nextStep();
          }}
          goPrev={() => {
            setCurrentProvider(null);
            setHasAccount(false);
            prevStep();
          }}
          provider={currentProvider}
        />
      );
      break;
    case 4:
      stepTitle = 'Super! Wykonuj rzetelnie krok po kroku integrację aby połączyć platformy';
      switch (currentProvider) {
        case 'stream_elements':
          if (hasAccount) {
            stepContent = (
              <StreamElementsHasAccountStep4
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          } else {
            stepContent = (
              <StreamElementsNoAccountStep4
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        case 'tipeee':
          if (hasAccount) {
            stepContent = (
              <TipeeeStreamHasAccountStep4
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          } else {
            stepContent = (
              <TipeeeStreamNoAccountStep4
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 5:
      switch (currentProvider) {
        case 'stream_elements':
          if (hasAccount) {
            stepContent = (
              <StreamElementsHasAccountStep5
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          } else {
            stepContent = (
              <StreamElementsNoAccountStep5
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        case 'tipeee':
          if (hasAccount) {
            stepContent = (
              <TipeeeStreamHasAccountStep5
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          } else {
            stepContent = (
              <TipeeeStreamNoAccountStep5
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 6:
      switch (currentProvider) {
        case 'stream_elements':
          if (hasAccount) {
            stepContent = (
              <StreamElementsHasAccountStep6
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          } else if (noSEPAYButton) {
            stepContent = (
              <StepNoSEPAYButton
                provider={currentProvider}
                onConfirm={() => {
                  resetSEPAY();
                }}
              />
            );
          } else {
            stepContent = (
              <StreamElementsNoAccountStep6
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
                noSEPAYButton={() => setNoSEPAY()}
              />
            );
          }
          break;
        case 'tipeee':
          if (hasAccount) {
            stepContent = (
              <TipeeeStreamStepForm processStep={() => nextStep()} goPrev={() => prevStep()} />
            );
          } else {
            stepContent = (
              <TipeeeStreamNoAccountStep6
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 7:
      switch (currentProvider) {
        case 'stream_elements':
          if (hasAccount) {
            stepContent = (
              <StreamElementsStepForm processStep={() => nextStep()} goPrev={() => prevStep()} />
            );
          } else {
            stepContent = (
              <StreamElementsNoAccountStep7
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        case 'tipeee':
          if (hasAccount) {
            stepContent = (
              <StepFinal
                provider={currentProvider}
                onConfirm={() => {
                  dispatch(externalTipsSourceConnected(currentProvider));
                }}
              />
            );
          } else {
            stepContent = (
              <TipeeeStreamNoAccountStep7
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 8:
      switch (currentProvider) {
        case 'stream_elements':
          if (hasAccount) {
            stepContent = (
              <StepFinal
                provider={currentProvider}
                onConfirm={() => {
                  dispatch(externalTipsSourceConnected(currentProvider));
                }}
              />
            );
          } else {
            stepContent = (
              <StreamElementsNoAccountStep8
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        case 'tipeee':
          if (!hasAccount) {
            stepContent = (
              <TipeeeStreamNoAccountStep8
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 9:
      switch (currentProvider) {
        case 'stream_elements':
          if (!hasAccount) {
            stepContent = (
              <StreamElementsNoAccountStep9
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        case 'tipeee':
          if (!hasAccount) {
            stepContent = (
              <TipeeeStreamNoAccountStep9
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 10:
      switch (currentProvider) {
        case 'stream_elements':
          if (!hasAccount) {
            stepContent = (
              <StreamElementsStepForm processStep={() => nextStep()} goPrev={() => prevStep()} />
            );
          }
          break;
        case 'tipeee':
          if (!hasAccount) {
            stepContent = (
              <TipeeeStreamNoAccountStep10
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 11:
      switch (currentProvider) {
        case 'stream_elements':
          if (!hasAccount) {
            stepContent = (
              <StepFinal
                provider={currentProvider}
                onConfirm={() => {
                  dispatch(externalTipsSourceConnected(currentProvider));
                }}
              />
            );
          }
          break;
        case 'tipeee':
          if (!hasAccount) {
            stepContent = (
              <TipeeeStreamNoAccountStep11
                processStep={() => nextStep()}
                goPrev={() => prevStep()}
              />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 12:
      switch (currentProvider) {
        case 'tipeee':
          if (!hasAccount) {
            stepContent = (
              <TipeeeStreamStepForm processStep={() => nextStep()} goPrev={() => prevStep()} />
            );
          }
          break;
        default:
          break;
      }
      break;
    case 13:
      stepContent = (
        <StepFinal
          provider={currentProvider}
          onConfirm={() => {
            dispatch(externalTipsSourceConnected(currentProvider));
          }}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Dialog isVisible noPadding noBorder width={720}>
      <CloseButton onClick={() => history.push('/ustawienia-strony-zamowien')} />
      <TwoColumnsContent
        title={stepTitle}
        underTitle={<WizardProgress step={step} total={totalSteps} />}
        compactRightColumn={compactRightColumn}
      >
        {stepContent}
      </TwoColumnsContent>
    </Dialog>
  );
};

export default withRouter(ConnectWizard);

ConnectWizard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};
