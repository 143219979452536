import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-contexify';

import AnimationContextMenuItems from '../AnimationsContextMenuList/AnimationContextMenuItems';

const AnimationContextMenu = props => {
  const { menuId, animationTypes, activeAnimation, onAnimationSelect } = props;

  return (
    <Menu id={`${menuId}_contextMenu`}>
      <AnimationContextMenuItems
        animationTypes={animationTypes}
        activeAnimation={activeAnimation}
        selectAnimationHandler={className => onAnimationSelect(className)}
      />
    </Menu>
  );
};

AnimationContextMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  animationTypes: PropTypes.instanceOf(Array).isRequired,
  activeAnimation: PropTypes.string.isRequired,
  onAnimationSelect: PropTypes.instanceOf(Function).isRequired,
};

export default AnimationContextMenu;
