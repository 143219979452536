import { css } from 'styled-components';

import GoogleSansRegular from 'assets/fonts/GoogleSans-Regular.woff2';
import GoogleSansItalic from 'assets/fonts/GoogleSans-Italic.woff2';
import GoogleSansMedium from 'assets/fonts/GoogleSans-Medium.woff2';
import GoogleSansMediumItalic from 'assets/fonts/GoogleSans-MediumItalic.woff2';
import GoogleSansBold from 'assets/fonts/GoogleSans-Bold.woff2';
import GoogleSansBoldItalic from 'assets/fonts/GoogleSans-BoldItalic.woff2';
import BurbankBigCondensedBold from 'assets/fonts/BurbankBigCondensed-Bold.woff2';
import BurbankBigRegularBold from 'assets/fonts/BurbankBigRegular-Bold.woff2';
import BebasNeueRegular from 'assets/fonts/BebasNeue-Regular.woff2';
import GilroyExtraBold from 'assets/fonts/GilroyExtra-Bold.woff2';

export default css`
  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Regular'), local('GoogleSans-Regular'),
      url(${GoogleSansRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Italic'), local('GoogleSans-Italic'),
      url(${GoogleSansItalic}) format('woff2');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Medium'), local('GoogleSans-Medium'),
      url(${GoogleSansMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Medium Italic'), local('GoogleSans-MediumItalic'),
      url(${GoogleSansMediumItalic}) format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Bold'), local('GoogleSans-Bold'), url(${GoogleSansBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Google Sans';
    src: local('Google Sans Bold Italic'), local('GoogleSans-BoldItalic'),
      url(${GoogleSansBoldItalic}) format('woff2');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Burbank Big';
    src: url(${BurbankBigCondensedBold}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Burbank Big';
    src: url(${BurbankBigRegularBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Regular'), local('BebasNeue-Regular'),
      url(${BebasNeueRegular}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy Extra';
    src: url(${GilroyExtraBold}) format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Sora:wght@400..800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@400;700&display=swap');
`;
