import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import priceFormatter from 'utils/priceFormatter';
import { setCurrentElement } from 'store/actions/templates';

import AbstractElements from 'pages/Configurators/shared/AbstractElements';
import TextElement from 'pages/Configurators/components/Elements/Text';

import ProgressBar from './components/ProgressBar';
import GoalNumbers from './components/GoalNumbers';
import AmountPaid from './components/AmountPaid';

import { Provider } from './Context';

class Elements extends AbstractElements {
  render() {
    const {
      createHistoryElement,
      activeTemplate,
      configuratorWrapper,
      configuratorConfig,
      updateConfig,
      setCurrentElementDispatch,
    } = this.props;
    const { elementsOptions } = createHistoryElement();

    const elementsMap = {
      goalNumbers: GoalNumbers,
      progressBar: ProgressBar,
      amountPaid: AmountPaid,
    };

    return (
      <Provider
        value={{
          updateElement: this.updateElement,
          toggleTextEditor: (elementName, e, mode) => this.toggleTextEditor(elementName, e, mode),
          getElementOptions: elementName => this.getElementOptions(elementName),
          state: this.state,
          activeTemplate,
        }}
      >
        {elementsOptions.goalName.isVisible && (
          <TextElement
            configuratorWrapper={configuratorWrapper}
            options={elementsOptions.goalName}
            updateElement={newConfig => {
              if (newConfig && newConfig.goalName && newConfig.goalName.textValue) {
                updateConfig({ goalName: newConfig.goalName.textValue });
                return;
              }
              this.updateElement(newConfig);
              setCurrentElementDispatch('goalName');
            }}
            activeTemplate={activeTemplate}
            configuratorType="TIPS_GOAL"
            forcedTextValue="Przykładowa nazwa celu"
            name="goalName"
            contextMenuOptions={['editText', 'changeText', 'delete']}
            onKeyDown={this.handleKeyDownElement}
          />
        )}

        {Object.keys(elementsOptions).map(elementName => {
          let options = elementsOptions[elementName];
          const Element = elementsMap[elementName];

          if (elementName === 'goalNumbers') {
            options = {
              ...options,
            };

            options.children.to.textValue = priceFormatter(configuratorConfig.goalValue);
          }

          if (options.isVisible && Element) {
            return (
              <Fragment key={elementName}>
                <Element
                  configuratorWrapper={configuratorWrapper}
                  options={options}
                  updateElement={this.updateElement}
                  activeTemplate={activeTemplate}
                  configuratorType="TIPS_GOAL"
                  name={elementName}
                  onKeyDown={this.handleKeyDownElement}
                />
              </Fragment>
            );
          }

          return null;
        })}
      </Provider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentElementDispatch: name => dispatch(setCurrentElement(name)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Elements);
