import styled from 'styled-components';

import Box from '../styles/Box';

const ConfigBox = styled(Box)`
  padding-top: 12.5px;
  padding-bottom: 12.5px;
`;

export default ConfigBox;
