import { connect } from 'react-redux';

import {
  fetchMediaIfNeeded,
  insertMediumToStore,
  deleteMediaFromServer,
} from 'store/actions/mediaManager';

import MediaManager from './MediaManager';

const mapStateToProps = state => ({
  mediaSpaceUsage: state.mediaManager.usage,
  uploadedMedia: state.mediaManager.items.filter(item => !item.default),
  mediaAreFetched: state.mediaManager.isFetched,
  mediaAreFetching: state.mediaManager.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchMediaIfNeeded: () => dispatch(fetchMediaIfNeeded()),
  insertMediumInStore: media => dispatch(insertMediumToStore(media)),
  deleteMedia: mediaIds => dispatch(deleteMediaFromServer(mediaIds)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaManager);
