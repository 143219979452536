import http from 'utils/http';

import { openAlert } from 'components/Alert/utils';
import shouldFetchData from '../utils/shouldFetchData';

const requestTips = () => ({
  type: 'REQUEST_TIPS',
});

const receiveTip = tip => ({
  type: 'RECEIVE_TIP',
  tip,
});

const receiveTips = tips => ({
  type: 'RECEIVE_TIPS',
  tips,
});

const failureTips = () => ({
  type: 'FAILURE_TIPS',
});

const resend = () => ({
  type: 'RESEND_TIP',
});

const resent = () => ({
  type: 'TIP_RESENT',
});

const failureResend = () => ({
  type: 'FAILURE_RESEND_TIP',
});

const deleteTipT = () => ({
  type: 'DELETE_TIP',
});

const deleted = id => ({
  type: 'TIP_DELETED',
  id,
});

const failureDelete = () => ({
  type: 'FAILURE_DELETE_TIP',
});

const fetchTips = () => async dispatch => {
  dispatch(requestTips());

  try {
    const response = await http.get('/user/tips?limit=12');

    dispatch(receiveTips(response.data));
  } catch (error) {
    dispatch(failureTips());
  }
};

const fetchTipsWithLimitOffset = (limit, offset, filter, search) => async dispatch => {
  dispatch(requestTips());

  try {
    const response = await http.get(
      `/user/tips?limit=${limit}&offset=${offset}&filter=${filter}&search=${search}`,
    );

    dispatch(receiveTips(response.data));
  } catch (error) {
    dispatch(failureTips());
  }
};

export const resendTip = id => async dispatch => {
  dispatch(resend());

  try {
    await http.post(`/tip/${id}/resend`);

    dispatch(resent());
  } catch (error) {
    dispatch(failureResend());
  }
};

export const deleteTip = id => async dispatch => {
  dispatch(deleteTipT());

  try {
    const response = await http.delete(`/tip/${id}`);

    if (response.data === 'DeleteDayLimitExceeded') {
      dispatch(failureDelete());
      openAlert(
        'error',
        'Coś się pokićkało',
        'Przekroczono dzisiejszy limit usuwania wiadomości. Skontaktuj się z administratorem.',
      );
    } else {
      dispatch(deleted(id));
    }
  } catch (error) {
    dispatch(failureDelete());
  }
};

export const receivedTip = tip => dispatch => dispatch(receiveTip(tip));

export const fetchTipsIfNeeded = () => (dispatch, getState) => {
  const { tips } = getState();

  if (shouldFetchData(tips)) {
    dispatch(fetchTips());
  }
};

export const fetchTipsForTipsListPage = (limit, offset, filter, search) => dispatch => {
  dispatch(fetchTipsWithLimitOffset(limit, offset, filter, search));
};
