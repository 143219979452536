import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  100%  {
    transform: scale(1) translate(-50%, 15px);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  40% {
    width: 300px;
    overflow: hidden;
  }

  70% {
    opacity: 1;
  }

  100%  {
    width: 250px;
    transform: scale(0.2) translate(-50%, -50%);
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: ${props => props.width}px;
  height: 66px;
  color: #454545;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.04);
  border-radius: 33px;
  transform-origin: 0;
  animation-duration: ${props => props.unmountDelay - 100}ms;
  animation-fill-mode: forwards;

  &.fade-enter {
    opacity: 0;
    transform: scale(0.2) translate(-50%, -30px);
  }

  &.fade-enter-active,
  &.fade-enter-done {
    animation-name: ${fadeIn};
    animation-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
  }

  &.fade-exit {
    transform: scale(1) translate(-50%, 15px);

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      box-shadow: inset 0px 0px 20px 5px var(--white);
      border-radius: inherit;
    }
  }

  &.fade-exit-active {
    animation-name: ${fadeOut};
    animation-timing-function: ease-in;

    &::after {
      content: '';
    }
  }
`;
