import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as userDataActions from 'store/actions/userData';

const WithUserData = Component => props => <Component {...props} />;

const mapStateToProps = ({ userData }) => ({
  userDataInfo: userData.info,
  userDataProfile: userData.profile,
});

const mapDispatchToProps = dispatch => bindActionCreators(userDataActions, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  WithUserData,
);
