import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as statisticsActions from 'store/actions/statistics';
import * as tipsActions from 'store/actions/tips';
import * as announcementActions from 'store/actions/announcements';

import UserPanel from './UserPanel';

const mapStateToProps = ({
  statistics: { data: statisticsData },
  tips: { tips },
  userData,
  announcements: { forceMoreInfoModal },
}) => ({
  statisticsData,
  tips,
  userData,
  forceMoreInfoModal,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...statisticsActions, ...tipsActions, ...announcementActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPanel);
