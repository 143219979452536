import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ConfigContext from '../ConfigContext';

const bigFocus = css`
  width: calc(100% + 30px);
  height: calc(100% + 20px);
`;

const Styled = styled.div`
  width: inherit;
  height: inherit;

  position: relative;
  cursor: pointer;

  &:focus,
  &:active,
  &[data-focused='true'] {
    outline: none;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 15px);
      height: calc(100% + 8px);
      ${props => props.bigFocus && bigFocus};

      background-image: repeating-linear-gradient(
          to right,
          var(--border-color) 0%,
          var(--border-color) 65%,
          transparent 35%,
          transparent 100%
        ),
        repeating-linear-gradient(
          to right,
          var(--border-color) 0%,
          var(--border-color) 65%,
          transparent 35%,
          transparent 100%
        ),
        repeating-linear-gradient(
          to bottom,
          var(--border-color) 0%,
          var(--border-color) 65%,
          transparent 35%,
          transparent 100%
        ),
        repeating-linear-gradient(
          to bottom,
          var(--border-color) 0%,
          var(--border-color) 65%,
          transparent 35%,
          transparent 100%
        );

      background-position: left top, left bottom, left top, right top;
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 0px 0px, 0px 0px, 0px 0px, 0px 0px;

      opacity: 0.3;
      border: 4px dashed var(--white-white);
      border-radius: 5px;
      pointer-events: none;
      user-select: none;
    }

    .react-resizable-handle {
      display: block;
    }
  }
`;

const FocusedElement = ({ children, ...props }) => {
  const ref = useRef();
  const { screenRef, focusedElementName } = useContext(ConfigContext);
  const [isFocused, setFocusedState] = useState(false);

  useEffect(() => {
    if (!focusedElementName) setFocusedState(false);
  }, [focusedElementName]);

  const checkClick = event => !ref.current.contains(event.target) && setFocusedState(false);

  const handleOutsideClick = event => {
    if (!!screenRef && !!screenRef.current) {
      screenRef.current.contains(event.target) && checkClick(event);
    } else {
      checkClick(event);
    }
  };
  const setFocused = () => setFocusedState(true);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    document.addEventListener('doubleclick', handleOutsideClick, false);
    document.addEventListener('contextmenu', handleOutsideClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('doubleclick', handleOutsideClick, false);
      document.removeEventListener('contextmenu', handleOutsideClick, false);
    };
  }, []);

  return (
    <Styled
      {...props}
      data-focused={isFocused}
      tabIndex="0"
      ref={ref}
      onMouseDown={setFocused}
      onDoubleClick={setFocused}
      onContextMenu={setFocused}
    >
      {children}
    </Styled>
  );
};

FocusedElement.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FocusedElement;
