import React, { useContext } from 'react';

import { ReactComponent as IconTextShadow } from 'assets/icons/text-shadow.svg';
import { TextEditor } from '../Context';
import { Option, OptionIcon, AnimationToggleOption } from '../styles';

const TYPE = 'textShadow';
const VALUE_DEFAULT = '0px 0px 8px rgba(0, 0, 0, 0.67)';
const VALUE_NONE = 'none';

const TextShadow = props => {
  const {
    style: { textShadow },
    updateStyle,
    isNotStyle,
  } = useContext(TextEditor);

  return (
    <Option
      onClick={() => {
        const value = textShadow !== VALUE_NONE ? VALUE_NONE : VALUE_DEFAULT;
        updateStyle(TYPE, value, true);
      }}
      {...props}
    >
      <AnimationToggleOption isInactive={isNotStyle} delayOut={0.25}>
        <OptionIcon isActive={textShadow !== VALUE_NONE}>
          <IconTextShadow />
        </OptionIcon>
      </AnimationToggleOption>
    </Option>
  );
};

export default TextShadow;
