import styled from 'styled-components';

import hover from './hover';

export default styled.button`
  width: 30px;
  height: 30px;

  border: 0;
  border-radius: 100%;

  padding: 0 0 2px 2px;

  background-color: var(--blue);

  transition: all 0.25s ease;
  transition-delay: 0.1s;

  > svg {
    fill: #fff;
  }

  ${props => props.hover && hover};
`;
