import styled from 'styled-components';

import WhiteBox from 'styles/WhiteBox';

export default styled(WhiteBox)`
  padding: 30px 20px;

  &::before {
    content: '';
    position: absolute;
    left: -3px;
    top: 50%;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: transparent transparent transparent var(--bg-color-light-gray);

    transform: translate(-100%, -50%);
  }
`;
