import React from 'react';
import PropTypes from 'prop-types';

import getUserAvatar from 'utils/getUserAvatar';
import WithUserData from 'containers/WithUserData';
import Text from 'components/Text';
import CopyUserUrlButton from '../CopyUserUrlButton';

import Avatar from './styles/Avatar';
import Wrapper from './styles/Wrapper';

const Hello = ({ userDataProfile }) => {
  const avatar = getUserAvatar(userDataProfile);

  return (
    <Wrapper isCollapsible={false}>
      <Avatar
        style={{
          backgroundImage: avatar ? `url(${avatar})` : null,
        }}
      />
      <Text size={18} weight="medium" margin="0 0 5px">
        Witaj
        {!!userDataProfile.fullname}
      </Text>
      <Text size={18} weight="medium" color="light-gray">
        Co tam u Ciebie?
      </Text>
      {userDataProfile.link && <CopyUserUrlButton link={userDataProfile.link} />}
    </Wrapper>
  );
};

Hello.propTypes = {
  userDataProfile: PropTypes.instanceOf(Object).isRequired,
};

Hello.defaultProps = {};

export default WithUserData(Hello);
