import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-overflow: elipsis;
  overflow: hidden;

  line-height: 1;
  font-size: 15px;
  text-align: left;

  padding: 15px 25px;

  outline: none;
  color: var(--text-color);
  background-color: var(--white-box-background);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  > span {
    display: inline-block;
    white-space: nowrap;
    margin-right: 15px;
  }
`;
