import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_6.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step9 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Po zapisaniu zmian, pojawią się dodatkowe opcje jak np. „Business information”, a więc miejsce
      dla osób z działalnością gospodarczą, oraz opcja dla nas wszystkich, a więc „Payments Means” i
      „Bank Account”.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      Należy przejść do Payments means i włączyć metodę Paysafecard, oczywiście można też inne.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
  </StepContent>
);

export default Step9;

Step9.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
