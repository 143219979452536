import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchVotingConfig } from 'store/actions/goalsVoting';
import { updateTemplate, changeActiveTemplate } from 'store/actions/templates';

import { RoundedInfoWrapper } from 'components/RoundedInfoWrapper';
import { ArrowInCircleButton } from 'components/Buttons';
import OnScreenWarning from 'components/OnScreenWarning';

import { emit } from 'utils/emitter';

import { ActionCatcher } from '../../components/ActionCatcher';
import AlignPopup from '../../components/AlignPopup';
import AnimationManager from '../../components/AnimationManager';
import BottomTip from '../../components/BottomTip';
import ChangeScreenBackground from '../../components/ChangeScreenBackground';
import ColumnBottomHelp from '../../components/ColumnBottomHelp';
import ConfigBox from '../../components/ConfigBox';
import CustomCode from '../../components/CustomCode';
import Panel from '../../components/Panel';
import RangePopup from '../../components/RangePopup';
import Screen from '../../components/Screen';
import Switcher from '../../components/Switcher';
import TemplatesManager from '../../components/TemplatesManager';
import WidgetUrl from '../../components/WidgetUrl';

import ConfigUnderScreen from '../../styles/ConfigUnderScreen';

import { GoalsUnderScreenButton } from '../TipsGoal/components/GoalsUnderScreenButton';

import GoalsSelector from './components/GoalSelector';
import OnBoarding from './components/OnBoarding';
import PreviewIFrame from './components/PreviewIFrame';
import SetEndDate from './components/SetEndDate';

import { CONFIGURATOR_TYPE } from './constants';

import { useActiveTemplate, useAllTemplates, useVotingStore } from './hooks';

const GoalVoting = () => {
  const dispatch = useDispatch();
  const store = useVotingStore();
  const { isCreating, created } = useSelector(state => ({
    isCreating: state.templates.isCreating,
    created: state.templates.created.filter(i => i.type === CONFIGURATOR_TYPE),
  }));
  const votingTemplates = useAllTemplates(CONFIGURATOR_TYPE);
  const activeTemplate = useActiveTemplate(CONFIGURATOR_TYPE);
  const [openSelector, setOpenSelector] = useState(false);

  useEffect(() => {
    document.title = 'Tipply | Konfigurator (Głosowanie celów)';
  }, []);

  useEffect(() => {
    dispatch(fetchVotingConfig());
  }, [dispatch]);

  const { isFetched } = store;

  if (!isFetched) return null;

  const {
    config: {
      editable,
      options: {
        barAnimation,
        showLegend,
        showValues,
        showGoals,
        borderRadius,
        align,
        legendAlign,
        reverse,
        showTte,
        showPercents,
        biggerPercentage,
      },
    },
  } = activeTemplate;

  const updateTemplateConfig = newConfig => {
    if (editable) {
      dispatch(
        updateTemplate(
          {
            options: {
              ...newConfig,
            },
          },
          activeTemplate.id,
          CONFIGURATOR_TYPE,
        ),
      );
    } else if (!isCreating) {
      if (created.length) {
        dispatch(changeActiveTemplate(created[0].id, CONFIGURATOR_TYPE));
      } else {
        emit('create-new-template');
      }
    }
  };

  return (
    <>
      <Panel>
        {/* Lewa kolumna */}
        <GoalsSelector
          open={openSelector}
          onOpen={() => setOpenSelector(true)}
          onClose={() => setOpenSelector(false)}
        />
        <RangePopup
          text="Wielkość zaokrąglenia paska"
          onAfterChange={value =>
            updateTemplateConfig({
              borderRadius: value,
            })
          }
          value={borderRadius}
          activeTemplateId={activeTemplate.id}
          isEditable={editable}
          minValue={0}
          maxValue={100}
          whiteColorMinValue={9}
          whiteColorMaxValue={93}
        />
        <Switcher
          text="Animuj w paski zwycięzce"
          name="bar-animation"
          value={barAnimation}
          onChange={value =>
            updateTemplateConfig({
              barAnimation: value,
            })
          }
        />
        <AlignPopup
          label="Zmień wyrównanie elementów"
          onSelectedValue={value =>
            updateTemplateConfig({
              align: value,
            })
          }
          value={align}
          isEditable={editable}
        />
        <AlignPopup
          label="Zmień wyrównanie legendy"
          onSelectedValue={value =>
            updateTemplateConfig({
              legendAlign: value,
            })
          }
          value={legendAlign}
          isEditable={editable}
        />
        <Switcher
          text="Odbij pozycję elementów"
          name="order"
          value={reverse}
          onChange={value =>
            updateTemplateConfig({
              reverse: value,
            })
          }
        />
        <Switcher
          text="Wyświetlaj czas do końca"
          name="tte"
          value={showTte}
          onChange={value =>
            updateTemplateConfig({
              showTte: value,
            })
          }
        />
        <SetEndDate />
        <Switcher
          text="Widok % większy niż kwota"
          name="biggerPercentage"
          value={biggerPercentage}
          onChange={value =>
            updateTemplateConfig({
              biggerPercentage: value,
            })
          }
        />
        <ConfigBox>
          <span>Zmień kolory celów</span>
          <ArrowInCircleButton background="green" onClick={() => setOpenSelector(true)} />
        </ConfigBox>
        <CustomCode
          template={{
            type: activeTemplate.type,
            config: {
              ...activeTemplate.config.options,
              editable: activeTemplate.config.editable,
            },
          }}
          onChange={updateTemplateConfig}
        />

        <ColumnBottomHelp>
          Aby wyzerować środki zebrane w głosowaniu musisz opróżnić kwotę zebraną w poszczególnych
          celach. Aby to wykonać użyj przycisku “Ustawienia celów”.
        </ColumnBottomHelp>
      </Panel>
      <div>
        {/* Środkowa kolumna */}
        <Screen>
          <ActionCatcher activeTemplate={activeTemplate} />
          <OnScreenWarning>
            Konfigurator z którego korzystasz ma ograniczone możliwości.
            <br />
            Nie skorzystasz więc tutaj z wybranych możliwości.
          </OnScreenWarning>
          <PreviewIFrame activeTemplate={activeTemplate} />
        </Screen>
        <ConfigUnderScreen position="start">
          <AnimationManager
            isAnimated={false}
            onClickControl={() => null}
            autoplayPanel
            autoplayValue
            disabled
          />
          <WidgetUrl configuratorType={CONFIGURATOR_TYPE} templates={votingTemplates} />
          <GoalsUnderScreenButton />
        </ConfigUnderScreen>
        <BottomTip />
      </div>
      <Panel>
        {/* Prawa kolumna */}
        <RoundedInfoWrapper info="Mimo ograniczonej konfiguracji, nadal możesz utworzyć swój szablon lub użyć domyślnego">
          <TemplatesManager type={CONFIGURATOR_TYPE} />
        </RoundedInfoWrapper>
        <ChangeScreenBackground />
        <Switcher
          text="Wyświetlaj legendę celu"
          name="legend"
          value={showLegend}
          onChange={value =>
            updateTemplateConfig({
              showLegend: value,
            })
          }
        />
        <Switcher
          text="Wyświetlaj kwoty celów"
          name="values"
          value={showValues}
          onChange={value =>
            updateTemplateConfig({
              showValues: value,
            })
          }
        />
        <Switcher
          text="Wyświetlaj tytuły celów"
          name="title"
          value={showGoals}
          onChange={value =>
            updateTemplateConfig({
              showGoals: value,
            })
          }
        />
        <Switcher
          text="Wyświetlaj procenty celów"
          name="showPercents"
          value={showPercents}
          onChange={value =>
            updateTemplateConfig({
              showPercents: value,
            })
          }
        />
      </Panel>

      <OnBoarding buttonAction={() => setOpenSelector(true)} />
    </>
  );
};

export default GoalVoting;
