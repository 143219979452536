import React, { useState } from 'react';

import Input from 'styles/Input';
import { PersonalDataValidator } from 'utils';
import { ButtonWithAnimatedArrow } from '../../Buttons';
import { SimpleCheckbox } from '../../Checkboxes';
import EmojiLabel from '../components/EmojiLabel';
import FormWrapper from '../components/FormWrapper';
import Spinner from '../components/Spinner';
import StepWrapper from '../components/StepWrapper';
import { StepPropTypes } from '../types';

const Validator = new PersonalDataValidator();

const SocialMediaStep = ({ processStep }) => {
  const [working, setWorking] = useState(false);
  const [data, setData] = useState({
    socialProfile1: '',
    socialProfile2: '',
    socialProfile3: '',
    noSocialProfile: false,
  });

  const [errors, setErrors] = useState({
    socialProfile1: false,
    socialProfile2: false,
    socialProfile3: false,
  });

  const updateData = async (key, value) => {
    setData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const validateSocialLink = async (index, required = false) => {
    let hasError;

    if (data[`socialProfile${index}`]) {
      hasError = !(await Validator.socialMediaUrl(data[`socialProfile${index}`]));
    } else {
      hasError = required;
    }

    return hasError;
  };

  const validate = async () => {
    if (data.noSocialProfile) {
      processStep(data);
    } else {
      setWorking(true);
      const result = {
        socialProfile1: await validateSocialLink(1, true),
        socialProfile2: await validateSocialLink(2),
        socialProfile3: await validateSocialLink(3),
      };

      if (Object.values(result).some(error => error)) {
        setErrors(result);
      } else {
        processStep(data);
      }
      setWorking(false);
    }
  };

  return (
    <StepWrapper>
      <p>
        Świetnie. Teraz, musimy uzyskać o Tobie dodatkowe szczegóły. Jak dobrze wiesz, Tipply jest
        witryną wyłącznie dla wszelkiego rodzaju streamerów, youtuberów czy influencerów.
      </p>
      <p>
        By uzyskać weryfikację, dodatkowo zweryfikujemy Twoje kanały social media na których
        streamujesz, tworzysz treści w internecie, czy wrzucasz filmy.
      </p>
      <p>Podaj proszę minimalnie jeden kanał społecznościowy, gdzie udzielasz się najczęściej.</p>
      <FormWrapper>
        {[1, 2, 3].map(i => (
          <div className="row" key={i}>
            <div className="cell wide">
              <EmojiLabel label={`Social media ${i}`} />
              <Input
                type="text"
                onChange={e => updateData(`socialProfile${i}`, e.target.value)}
                value={data[`socialProfile${i}`]}
                disabled={working || data.noSocialProfile}
                error={errors[`socialProfile${i}`]}
              />
            </div>
          </div>
        ))}
        <SimpleCheckbox
          checked={data.noSocialProfile}
          onCheck={checked => updateData('noSocialProfile', checked)}
          text="Nie posiadam swoich kanałów social media"
        />
      </FormWrapper>
      <div className="button-container">
        <ButtonWithAnimatedArrow onClick={validate} disabled={working}>
          Przechodzę dalej
        </ButtonWithAnimatedArrow>
        {working && <Spinner />}
      </div>
    </StepWrapper>
  );
};

SocialMediaStep.propTypes = StepPropTypes;

export default SocialMediaStep;
