import deepmerge from 'deepmerge';

export default (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_STATISTICS':
      return {
        ...state,
        data: deepmerge(state.data, action.updatedData),
      };
    case 'REQUEST_STATISTICS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'RECEIVE_STATISTICS':
      return {
        ...state,
        failureFetching: false,
        isFetching: false,
        isFetched: true,

        data: action.recievedData,
      };
    default:
      return state;
  }
};
