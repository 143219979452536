import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SettingsIcon } from 'assets/icons/gear.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';

import Button from 'components/Button';
import { RoundedInfoWrapper } from 'components/RoundedInfoWrapper';

const ButtonOverride = styled(Button)`
  height: 55px;
  padding: 0 30px;
  border-radius: 10px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    justify-content: center;
  }

  .suffixIcon {
    justify-self: flex-end;
    transition: all 200ms ease-out;
    transform: translateX(30%);

    svg {
      transform: rotateZ(-90deg);
      width: 14px;
      height: 14px;
    }
  }

  &:hover {
    .suffixIcon {
      transform: translateX(75%);
    }
  }
`;

export const GeneralSettingsButton = ({
  info,
  label,
  color,
  onClick,
  icon,
  reverse,
  withArrow,
}) => {
  const getIcon = () => icon || <SettingsIcon />;
  const getIconSuffix = () => withArrow && <ArrowIcon />;

  return (
    <RoundedInfoWrapper info={info} reverse={reverse}>
      <ButtonOverride
        block
        background={color}
        icon={getIcon()}
        iconSuffix={getIconSuffix()}
        type="button"
        onClick={onClick}
      >
        {label}
      </ButtonOverride>
    </RoundedInfoWrapper>
  );
};

GeneralSettingsButton.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.instanceOf(Function).isRequired,
  icon: PropTypes.node,
  reverse: PropTypes.bool,
  withArrow: PropTypes.bool,
};

GeneralSettingsButton.defaultProps = {
  icon: null,
  reverse: false,
  withArrow: false,
};
