import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { externalTipsSourceDisconnected } from 'store/actions/userData';
import http from 'utils/http';

import ConnectedMethodSettings from '../../ConnectedMethodSettings';

const Settings = ({ enabled, onClose, onChange }) => {
  const [working, setWorking] = useState(false);
  const dispatch = useDispatch();

  const disconnect = () => {
    setWorking(true);
    http
      .post('/user/disconnect-external-tips-source')
      .then(() => dispatch(externalTipsSourceDisconnected()))
      .catch(() => setWorking(false));
  };

  return (
    <ConnectedMethodSettings
      disconnect={disconnect}
      working={working}
      enabled={enabled}
      onChange={onChange}
      onClose={onClose}
    />
  );
};

export default Settings;

Settings.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
