/**
 * Get duplicates from passed array
 *
 * @param {array} items
 * @returns {array}
 */
const getDuplicatesFromArray = items =>
  items.reduce((previousValue, currentValue, currentIndex, array) => {
    if (array.indexOf(currentValue) !== currentIndex && previousValue.indexOf(currentValue) < 0) {
      previousValue.push(currentValue);
    }

    return previousValue;
  }, []);

export default getDuplicatesFromArray;
