import React from 'react';
import PropTypes from 'prop-types';

import bytesToSize from 'utils/bytesToSize';
import { apiUrl } from 'utils/http';

import StyledMedium from '../styles/StyledMedium';
import CancelSVG from '../../../../../icons/cancel.svg';

import { Consumer } from '../../../Context';
import Progress from '../../Progress';

const Medium = ({ config, onDelete, rejected }) => (
  <Consumer>
    {({ uploadedMedia }) => {
      let backgroundImage = null;

      if (config.id !== null) {
        const filteredItem = uploadedMedia.find(medium => medium.id === config.id);

        if (filteredItem && filteredItem.formats) {
          backgroundImage = `${apiUrl}${filteredItem.formats.user_banner.url}`;
        }
      }

      return (
        <StyledMedium>
          <div>
            <p className="medium_name">
              {config.medium.name}
              <br />
            </p>
            {rejected ? (
              <span className="medium_error">Nie można przesłać tego pliku</span>
            ) : (
              <span className="medium_size">{bytesToSize(config.medium.size)}</span>
            )}
          </div>

          <div>
            {backgroundImage ? (
              <div
                style={{
                  width: 125,
                  height: 70,
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundRepat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            ) : (
              <Progress value={config.progress} />
            )}
            <button className="medium_cancel" type="button" onClick={() => onDelete()}>
              <img src={CancelSVG} alt="Cancel" />
            </button>
          </div>
        </StyledMedium>
      );
    }}
  </Consumer>
);

Medium.defaultProps = {
  rejected: false,
};

Medium.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.instanceOf(Function).isRequired,
  rejected: PropTypes.bool,
};

export default Medium;
