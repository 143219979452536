import styled from 'styled-components';

import Text from 'components/Text';

export default styled(Text)`
  text-align: center;
  margin: 30px 0;

  .red {
    color: red;
  }

  .blue {
    color: var(--blue);
  }

  > span {
    display: inline-block;
    font-weight: 500;
  }
`;
