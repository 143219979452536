import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TipModeration from './TipModeration';

class TipsModerationList extends Component {
  static propTypes = {
    tipsModeration: PropTypes.instanceOf(Object).isRequired,
    showGoals: PropTypes.bool.isRequired,
    approveModerationPanel: PropTypes.instanceOf(Function).isRequired,
    rejectModerationPanel: PropTypes.instanceOf(Function).isRequired,
  };

  render() {
    const { tipsModeration, showGoals, approveModerationPanel, rejectModerationPanel } = this.props;

    return tipsModeration.map(modtip => (
      <TipModeration
        createdAt={modtip.created_at}
        nickname={modtip.nickname}
        email={modtip.email}
        id={modtip.id}
        key={modtip.id}
        message={modtip.message}
        amount={modtip.amount}
        goalTitle={modtip.goal_title ? modtip.goal_title : 'Cel domyślny'}
        paymentType={modtip.payment_method ? modtip.payment_method : ''}
        showGoals={showGoals}
        approveModerationPanel={approveModerationPanel}
        rejectModerationPanel={rejectModerationPanel}
        audioUrl={modtip.audio_url ? modtip.audio_url : ''}
      />
    ));
  }
}

export default TipsModerationList;
