import React from 'react';
import PropTypes from 'prop-types';

import OptionsPopup from 'styles/OptionsPopup';

import Popup from './Popup';

const OptionsMenu = ({ visible, children, placement }) => (
  <Popup visible={visible} placement={placement}>
    <OptionsPopup>{children}</OptionsPopup>
  </Popup>
);

OptionsMenu.propTypes = {
  placement: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

OptionsMenu.defaultProps = {
  placement: 'bottom',
};

export default OptionsMenu;
