import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const StatusWrapper = styled.div`
  border-radius: 10px;
  background-color: var(--white-box-background);
  text-align: center;
  padding: 25px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StatusControl = styled.div`
  font-size: 19px;
  font-weight: 900;
  color: ${({ variant }) => (variant === 'success' ? 'var(--brand-green)' : 'var(--red)')};
`;

const Status = ({ targetEl, connected }) =>
  ReactDOM.createPortal(
    <StatusWrapper>
      <div>Status połączenia Tipply z Twitch</div>
      {connected ? (
        <StatusControl variant="success">Połączono</StatusControl>
      ) : (
        <>
          <StatusControl variant="error">Niepołączono</StatusControl>
          <div>
            By włączyć funkcje, połącz Tipply z Twitchem. Zrobisz to w ustawieniach konta Tipply.
          </div>
        </>
      )}
    </StatusWrapper>,
    targetEl,
  );

export default Status;
