import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Styled = styled.div`
  height: 15px;
  background: var(--bg-color-light-gray);
  border-radius: 10px;
  width: 142px;
  overflow: hidden;

  > div {
    width: 0%;
    height: 100%;
    background: var(--brand-blue);
  }
`;

const Progress = ({ value }) => (
  <Styled>
    <div style={{ width: `${value}%` }} />
  </Styled>
);

Progress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Progress;
