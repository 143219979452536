import styled from 'styled-components';

export default styled.ul`
  font-size: 14px;
  list-style: none;

  padding: 7px 20px;
  color: var(--brand-green);
  background-color: rgba(43, 210, 131, 0.15);
  border: 1px solid var(--brand-green);
  border-radius: 5px;

  > li {
    margin: 3px 0;
  }
`;
