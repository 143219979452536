import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import styled from 'styled-components';

import { withDesinence } from 'utils/suffix';

const Value = styled.span`
  font-family: var(--cdn-google-sora);
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  color: inherit;
  position: relative;
  z-index: 2;

  user-select: none;
  pointer-events: none;
`;

const Root = styled.div`
  position: relative;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  height: 100%;
  padding: 0 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .rc-slider {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 16px;

    box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.05);
    background-color: transparent;

    .rc-slider-rail,
    .rc-slider-step,
    .rc-slider-track {
      height: 100%;
      background: none transparent;
    }

    .rc-slider-track {
      background-color: var(--brand-blue);
      border-radius: 0;
    }

    .rc-slider-handle {
      top: 0;

      width: 8px;
      height: 100%;

      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 0;
      outline: 0;

      transform: translate(-50%, 0%);
      background: none;
      box-shadow: none;
    }
  }
`;

const Range = ({ value, ...props }) => (
  <Root>
    <Value>{withDesinence(value, 'sekunda', 'sekundy', 'sekund')}</Value>

    <Slider {...props} min={3} max={60} step={0.1} />
  </Root>
);

Range.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Range;
