import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import IconInCircle from '../../styled/buttons/IconInCircle';

const Rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

const workingStyles = css`
  animation: ${Rotation} 2s infinite linear !important;
  opacity: 0.6;
`;

const disabledStyles = css`
  background-color: var(--bg-color-grey-button);
  opacity: 0.6;
`;

const Button = styled(IconInCircle)`
  background-color: var(--bg-color-grey-button);
  padding: 0;
  width: 38px;
  height: 38px;

  ${({ working, disabled }) => (!working && disabled ? disabledStyles : null)};
  ${({ working }) => (working ? workingStyles : null)};
`;

const Label = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: -50px;
  right: 19px;
  font-size: 12px;
  color: var(--white-white);
  background-color: var(--blue);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px 0 0 19px;
  transition: all 0.25s ease;
  transition-delay: 0.1s;

  span {
    padding-right: 10px;
  }
`;

const Wrapper = styled.div`
  position: relative;

  &:hover {
    ${Button} {
      background-color: ${({ working }) => !working && 'var(--blue-darker)'};
      ${({ disabled }) => disabled && disabledStyles};
    }

    ${Label} {
      opacity: 1;
    }
  }
`;

const TinyActionButton = ({ label, children, disabled, working, ...props }) => (
  <Wrapper working={working}>
    {!disabled && !working && (
      <Label>
        <span>{label}</span>
      </Label>
    )}
    <Button disabled={disabled} working={working} {...props}>
      {children}
    </Button>
  </Wrapper>
);

TinyActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
};

export default TinyActionButton;
