import styled, { css } from 'styled-components';

import Capsule from './Capsule';

const secondaryCapsuleIn = css`
  transform: scale(1);
  opacity: 1;
`;

const secondaryCapsuleOut = css`
  transform: scale(0.4);
  opacity: 0;
  visibility: hidden;
`;

export default styled(Capsule)`
  ${props => (props.isActive ? secondaryCapsuleIn : secondaryCapsuleOut)};
  position: absolute;
  overflow: hidden;

  background-color: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.04);

  top: calc(100% + 15px);
  left: 0;

  border-radius: 20px;
  padding-left: 0;
  padding-right: 0;

  z-index: 9;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
`;
