import React from 'react';

import { Time as TimeElement } from 'pages/Configurators/components/Elements';
import { Consumer } from 'pages/Configurators/views/CounterToEndLive/Context';

const Time = props => (
  <Consumer>
    {({ isAnimated, autoplayAnimation }) => (
      <TimeElement {...props} isAnimated={isAnimated} autoplayAnimation={autoplayAnimation} />
    )}
  </Consumer>
);

export default Time;
