import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

import socket from 'utils/socket';
import { fetchAccountsInfo } from 'store/actions/userData';
import { fetchInitStatisticsIfNeeded, fetchInitStatistics } from 'store/actions/statistics';
import Container from 'components/styled/Container';
import Grid from 'components/Grid';

import { Pending, Payment, Statistics, Withdrawals, Documents } from './components';

const LayoutGrid = styled(Grid)`
  margin: 0 0 15px;
`;

const Payments = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountsInfo());
    dispatch(fetchInitStatisticsIfNeeded());

    const instance = socket(
      throttle(json => {
        const tip = JSON.parse(json);
        if (!tip.resent && !tip.test) {
          dispatch(fetchInitStatistics());
          dispatch(fetchAccountsInfo());
        }
      }, 500),
    );

    instance.on();

    return () => {
      instance.off();
    };
  }, []);

  return (
    <Container>
      <LayoutGrid columns="100%">
        <Pending />
        <Payment />
        <Statistics />
        <Documents />
        <Withdrawals />
      </LayoutGrid>
    </Container>
  );
};

export default Payments;
