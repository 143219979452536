import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import StyledCopyInput from './styles/StyledCopyInput';
import StyledInput from './styles/StyledInput';

class CopyInput extends Component {
  static propTypes = {
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  textHasBeenCopied() {
    const { copied } = this.state;
    if (!copied) {
      this.setState({ copied: true });
      setTimeout(() => this.setState({ copied: false }), 1500);
    }
  }

  render() {
    const { value } = this.props;
    const { copied } = this.state;

    const text = copied ? 'Skopiowano do schowka...' : value;

    return (
      <StyledCopyInput>
        <span className="input-wrap">
          <StyledInput className={copied ? 'text-copied' : ''}>
            <span>{text}</span>
          </StyledInput>
          <CopyToClipboard
            text={value}
            className="hover-animation-blue"
            onCopy={() => this.textHasBeenCopied()}
          >
            <button type="button" className="hover-animation-blue">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 488.3 488.3"
                style={{ enableBackground: 'new 0 0 488.3 488.3' }}
                xmlSpace="preserve"
              >
                <path
                  d="M135.4,124l0,325.7c0,21.3,17.3,38.6,38.6,38.6h227c21.3,0,38.6-17.3,38.6-38.6V124c0-21.3-17.3-38.6-38.6-38.6h-227
    C152.8,85.4,135.6,102.7,135.4,124z M162.6,124c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6v325.6
    c0,6.4-5.2,11.6-11.6,11.6h-227c-6.4,0-11.6-5.2-11.6-11.6L162.6,124z"
                />
                <path
                  d="M48.7,38.6v325.7c0,21.3,17.3,38.6,38.6,38.6c7.5,0,13.5-6,13.5-13.5s-6-13.5-13.5-13.5c-6.4,0-11.6-5.2-11.6-11.6V38.6
    c0-6.4,5.2-11.6,11.6-11.6h227c6.4,0,11.6,5.2,11.6,11.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5c0-21.3-17.3-38.6-38.6-38.6
    l-227,0C65.9,0,48.7,17.3,48.7,38.6z"
                />
              </svg>
            </button>
          </CopyToClipboard>
        </span>
      </StyledCopyInput>
    );
  }
}

export default CopyInput;
