import { createElement } from 'react';
import styled, { css } from 'styled-components';

const withDynamicTag = Component => {
  const bucket = Object.create(null);

  const DynamicTag = ({ tag, ...props }) => {
    if (typeof tag !== 'string' || !Object.prototype.hasOwnProperty.call(styled, tag)) {
      return createElement(Component, props);
    }

    if (bucket[tag] === undefined) {
      bucket[tag] = Component.withComponent(tag);
    }

    return createElement(bucket[tag], props);
  };

  const name = Component.displayName || Component.constructor.name;

  if (name) {
    DynamicTag.displayName = `DynamicTag(${name})`;
  }

  return DynamicTag;
};

const TextBase = styled.p.attrs(({ color, weight, family, margin, noMargin, mb, align }) => {
  let setColor;
  let setWeight;
  let setFontFamily;
  let setMargin = margin || '0 0 10px';
  const setAlign = align || 'unset';

  switch (family) {
    case 'noto':
      setFontFamily = 'noto-font';
      break;
    case 'lato':
      setFontFamily = 'lato-font';
      break;
    case 'sora':
      setFontFamily = 'google-sora-cdn';
      break;
    default:
      setFontFamily = 'google-sora-cdn';
  }

  switch (color) {
    case 'blue':
      setColor = 'text-color-blue';
      break;
    case 'light-blue':
      setColor = 'text-color-light-blue';
      break;
    case 'green':
      setColor = 'green';
      break;
    case 'light-gray':
      setColor = 'text-color-light-gray';
      break;
    case 'white-gray':
      setColor = 'text-color-white-gray';
      break;
    case 'grey':
      setColor = 'grey';
      break;
    case 'red':
      setColor = 'error-color';
      break;
    case 'yellow':
      setColor = 'yellow';
      break;
    case 'white':
      setColor = 'white';
      break;
    case 'white-white':
      setColor = 'white-white';
      break;
    default:
      setColor = 'text-color';
  }

  switch (weight) {
    case 'medium':
      setWeight = 500;
      break;
    case 'bold':
      setWeight = 700;
      break;
    case 'black':
      setWeight = 900;
      break;
    default:
      setWeight = 400;
  }

  if (noMargin) {
    setMargin = 0;
  }

  return {
    color: `var(--${setColor})`,
    weight: setWeight,
    fontFamily: `var(--${setFontFamily})`,
    textAlign: setAlign,
    margin: setMargin,
  };
})`
  font-family: ${props => props.fontFamily};
  font-size: ${props => (props.size ? props.size : 14)}px;
  font-weight: ${props => props.weight};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'unset')};
  text-align: ${props => props.align};

  color: ${props => props.color};

  margin: ${props => props.margin};
  ${props =>
    props.mb
      ? css`
          margin-bottom: ${props.mb}px !important;
        `
      : null}
`;

export default withDynamicTag(TextBase);
