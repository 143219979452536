/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';

import { subscribe } from 'utils/emitter';

import FloatingMenu from 'components/FloatingMenu';
import { alert } from 'components/Alert';
import Loading from 'components/Loading';
import Toggle from 'components/Toggle';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import OptionsPopup from 'styles/OptionsPopup';
import Input from 'styles/Input';

import InputWrapper from './styles/InputWrapper';
import CreateButton from './styles/CreateButton';
import Separator from './styles/Separator';
import Wrapper from './styles/Wrapper';

import Context from './Context';

import ListSort from './components/ListSort';
import List from './components/List';

export default class TemplatesManager extends Component {
  static propTypes = {
    defaults: PropTypes.instanceOf(Array).isRequired,
    created: PropTypes.instanceOf(Array).isRequired,
    activeId: PropTypes.string.isRequired,
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    templateConfig: PropTypes.instanceOf(Object).isRequired,
    dispatchChangeActiveTemplate: PropTypes.instanceOf(Function).isRequired,
    createTemplate: PropTypes.instanceOf(Function).isRequired,
    deleteTemplate: PropTypes.instanceOf(Function).isRequired,
    updateTemplate: PropTypes.instanceOf(Function).isRequired,
    dispatchCancelCreateTemplate: PropTypes.instanceOf(Function).isRequired,
  };

  state = {
    prepareCreateTemplate: false,
    newTemplateName: '',
    loading: false,
    isActive: false,
  };

  node = React.createRef();

  componentDidMount() {
    const { dispatchChangeActiveTemplate } = this.props;

    const { created } = this.props;

    if (created.length) {
      dispatchChangeActiveTemplate(this.getLastModifiedTemplateId());
    }

    this.cntEvn = subscribe('create-new-template', () => {
      if (created.length) {
        dispatchChangeActiveTemplate(this.getLastModifiedTemplateId());
      } else {
        this.setState(
          {
            newTemplateName: `Nowy szablon #${created.length + 1}`,
            prepareCreateTemplate: true,
            isActive: true,
          },
          () => {
            this.handleCreateTemplate(true);
          },
        );
      }
    });
  }

  componentWillUnmount() {
    this.cntEvn.unsubscribe();
  }

  /**
   * Return last modified user's template id
   *
   * @returns {string} - template id
   */
  getLastModifiedTemplateId = () => {
    const { created } = this.props;
    let latest = null;

    for (let i = 0; i < created.length; i += 1) {
      const tmpl = created[i];

      if (latest === null || new Date(tmpl.updated_at) > new Date(latest.updated_at)) {
        latest = tmpl;
      }
    }

    if (latest === null) {
      return created[0].id;
    }

    return latest.id;
  };

  /**
   * Funkcja tworząca nowy szablon
   *
   * @param {boolean} setActiveAfterCreate
   * @returns {void}
   */
  handleCreateTemplate = async (setActiveAfterCreate = false) => {
    const { prepareCreateTemplate, loading } = this.state;
    const { created, dispatchCancelCreateTemplate, dispatchChangeActiveTemplate } = this.props;

    if (loading) {
      return;
    }

    if (created.length >= 150) {
      alert.open({
        config: {
          type: 'error',
          title: 'Coś się pokićkało',
          description: <span>Maksymalna liczba szablonów wynosi 150.</span>,
          confirmButtonText: 'Innym razem',
        },
      });

      dispatchCancelCreateTemplate();
      return;
    }

    if (prepareCreateTemplate) {
      const { newTemplateName } = this.state;
      const { templateConfig, createTemplate } = this.props;

      if (newTemplateName) {
        const config = {
          ...templateConfig,
          title: newTemplateName,
          editable: true,
        };

        this.setState({
          newTemplateName: '',
          prepareCreateTemplate: false,
        });

        this.setLoader(true);

        try {
          const response = await createTemplate(config);
          if (setActiveAfterCreate) {
            dispatchChangeActiveTemplate(response.data);
            alert.open({
              config: {
                type: 'success',
                title: 'Sukces',
                description: (
                  <span>
                    Kliknąłeś w domyślny szablon. Gratulacje, tworzysz nowy szablon - teraz tutaj
                    projektuj swój widżet.
                  </span>
                ),
                confirmButtonText: 'Rozumiem',
              },
            });
          }
        } catch (error) {
          dispatchCancelCreateTemplate();
          // do nothing
        }

        this.setLoader(false);
      }
    } else {
      dispatchCancelCreateTemplate();
      this.setState({
        prepareCreateTemplate: true,
      });
    }
  };

  /**
   * Funkcja obsługująca skróty klawiszowe dla stworzenia szablonu
   *
   * @param {Object} event
   * @returns {void}
   */
  handleCreateTemplateHotkey = event => {
    const keyCodeType = keycode(event);

    if (keyCodeType === 'enter') {
      this.handleCreateTemplate();
    }
  };

  /**
   * Funkcja zarzązająca widocznością popupu
   *
   * @param {boolean} forcedValue
   * @returns {void}
   */
  togglePopupVisibility = forcedValue => {
    this.setState(prevState => ({
      prepareCreateTemplate: false,
      isActive: forcedValue !== undefined ? forcedValue : !prevState.isActive,
    }));
  };

  /**
   * Funkcja aktualizująca tytuł szablonu
   *
   * @param {string} id - id aktualizowanego szablonu
   * @param {string} value - nowy tytuł szablonu
   * @returns {void}
   */
  updateTitle = async (id, value) => {
    const { updateTemplate, created } = this.props;

    // Sprawdzamy czy tekst rzeczywiście się zmienił, żeby zapobiegnąć niepotrzebnemu zapytaniu do serwera
    const editedTemplate = created.find(template => template.id === id);
    if (editedTemplate.config.title === value) return;

    updateTemplate({ title: value }, id);
  };

  /**
   * Funkcja usuwająca szablon
   *
   * @param {string} id
   * @returns {void}
   */
  deleteTemplate = async id => {
    const { deleteTemplate } = this.props;

    this.setLoader(true);

    try {
      await deleteTemplate(id);
    } catch (error) {
      // do nothing
    }

    this.setLoader(false);
  };

  /**
   * Funkcja ustawiająca stan animacji ładowania
   *
   * @param {bool} value
   * @returns {void}
   */
  setLoader = value => {
    this.setState({
      loading: value,
    });
  };

  render() {
    const {
      defaults,
      created,
      activeId,
      activeTemplate,
      dispatchChangeActiveTemplate,
    } = this.props;
    const { prepareCreateTemplate, newTemplateName, loading, isActive } = this.state;

    return (
      <Context.Provider
        value={{
          activeId,
          dispatchChangeActiveTemplate: id => dispatchChangeActiveTemplate(id),
          deleteTemplate: this.deleteTemplate,
          updateTitle: this.updateTitle,
        }}
      >
        <Wrapper ref={this.node}>
          <Toggle active={isActive} type="button" onClick={() => this.togglePopupVisibility()}>
            <span>{activeTemplate.config.title}</span>
            <ArrowDown />
          </Toggle>

          <FloatingMenu
            visible={isActive}
            position="bottom"
            onClose={() => this.togglePopupVisibility(false)}
            parent={this.node.current || document.body}
          >
            <OptionsPopup style={{ position: 'relative' }}>
              <List items={defaults} />

              <Separator />
              {!!created.length && (
                <>
                  <ListSort controls items={created} />
                  <Separator />
                </>
              )}

              <CreateButton active={prepareCreateTemplate} onClick={this.handleCreateTemplate}>
                Stwórz nowy szablon
              </CreateButton>

              {loading && <Loading style={{ borderRadius: 20 }} />}
            </OptionsPopup>

            <InputWrapper visible={prepareCreateTemplate}>
              <Input
                type="text"
                placeholder="Wpisz nazwę szablonu"
                value={newTemplateName}
                onChange={e => this.setState({ newTemplateName: e.target.value })}
                onKeyPress={this.handleCreateTemplateHotkey}
              />
            </InputWrapper>
          </FloatingMenu>
        </Wrapper>
      </Context.Provider>
    );
  }
}
