import styled, { css } from 'styled-components';

import NumberInput from 'components/NumberInput';

const tinyVariantOverride = css`
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 10px 15px;
  min-width: 110px;

  &:disabled {
    background: var(--bg-color-white-gray);
  }
`;

export default styled(NumberInput)`
  font-family: var(--google-sora-cdn);
  font-size: 15px;

  padding: 15px 23px;
  border: none;
  height: 100%;
  margin: 0;
  transform: none;

  &:hover {
    border-color: var(--brand-blue);
  }

  ${({ variant }) => (variant === 'tiny' ? tinyVariantOverride : null)};
`;
