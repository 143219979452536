/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import dateFormatter from 'utils/dateFormatter';
import priceFormatter from 'utils/priceFormatter';
import getUIAvatar from 'utils/getUIAvatar';
import Flexbox from 'components/Flexbox';
import Text from 'components/Text';
import moment from 'moment';
import ListItem from './styles/ListItem';
import Button from './styles/Button';
import Bold from './styles/Bold';
import Time from './styles/Time';
import Avatar from './styles/Avatar';
import ExpandedInfo from './styles/ExpandedInfo';

const ref = React.createRef();
const Item = ({ item }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ListItem>
      <Flexbox justify="space-between">
        <Flexbox align="center">
          <Avatar src={getUIAvatar(item.nickname)} />
          <div>
            <Text size={16} family="lato" weight="medium" color="light-gray" margin="0 0 5px">
              <Bold>@{item.nickname}</Bold> przekazał:{' '}
              <Bold>{priceFormatter(item.amount, true, 'code')}</Bold>
            </Text>
            <Time dateTime={item.created_at}>
              {dateFormatter.format(new Date(item.created_at), 'DD MMM YYYY, HH:mm')}
              &nbsp;({moment(item.created_at).fromNow()})
            </Time>
          </div>
        </Flexbox>
        <Button
          background="gray"
          expanded={expanded}
          noWrapper
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <ArrowDown />
        </Button>
      </Flexbox>
      <ExpandedInfo
        ref={ref}
        expanded={expanded}
        style={{ height: expanded ? ref.current.scrollHeight + 20 : 0 }}
      >
        <Text className="wrapped-text clamped" color="light-gray">
          <span dangerouslySetInnerHTML={{ __html: item.message }} />
        </Text>
        {item.paymentMethod && (
          <Text noMargin color="light-gray" size={13}>
            Metoda płatności <Bold>{item.paymentMethod}</Bold>
          </Text>
        )}
      </ExpandedInfo>
    </ListItem>
  );
};

Item.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

export default Item;
