import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import Text from 'components/Text';

import Announcement from './components/Announcement';
import Heading from '../../../../styles/Heading';
import { Consumer } from '../../Context';

const Announcements = () => (
  <Consumer>
    {({
      announcementsList,
      markAnnouncementsAsRead,
      announcementsFailurePatching,
      announcementsfailureFetching,
      announcementsArePatching,
      announcementsAreFetching,
    }) => (
      <div className="text-center">
        <Heading>Ogłoszenia</Heading>

        {announcementsfailureFetching && (
          <Text color="red" noMargin>
            Wystąpił błąd podczas pobierania ogłoszenia
          </Text>
        )}

        {!announcementsfailureFetching && announcementsList.length ? (
          <>
            {announcementsFailurePatching && (
              <Text color="red" noMargin>
                Brak ogłoszeń do wyświetlenia
              </Text>
            )}

            <Scrollbars className="scrollbar" style={{ height: 520 }}>
              {announcementsList.map(item => (
                <Announcement
                  key={item.id}
                  createdAt={item.created_at}
                  title={item.title}
                  content={item.content}
                  piclink={item.piclink}
                  piclinkhref={item.piclinkhref}
                />
              ))}
            </Scrollbars>
          </>
        ) : (
          <Text noMargin>Brak ogłoszeń do wyświetlenia</Text>
        )}
      </div>
    )}
  </Consumer>
);

export default Announcements;
