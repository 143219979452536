import { isObject } from './validators';
import { apiUrl } from './http';

/**
 * Funkcja pobierająca avatar użytkownka z przekazanego obiektu
 *
 * @param {Object} userData
 * @returns {(null|string)}
 */
export default function getUserAvatar(userData) {
  if (!isObject(userData)) return null;

  let avatar = null;
  if (Object.keys(userData).length > 0) {
    avatar = userData.google_avatar_url;

    if (userData.avatar && userData.avatar.formats) {
      avatar = `${apiUrl}${userData.avatar.formats.user_avatar.url}`;
    }
  }

  return avatar;
}
