import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { useTitle } from 'utils/hooks';

import notFoundGif from 'assets/gifs/notfound.gif';
import Button from 'components/Button';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  height: 100vh;
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  filter: blur(2px);
  height: inherit;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
`;

const Image = styled.img`
  height: 100%;
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 80px;
  margin: 0;
`;

const Text = styled.p`
  font-weight: 500;
  margin: 15px 0 30px;
`;

const NotFound = ({ history }) => {
  useTitle('Tipply | 404');

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={notFoundGif} alt="404 - strony nie znaleziono" />
      </ImageWrapper>
      <TextWrapper>
        <Heading>404</Heading>
        <Text>Strona, której szukasz nie istnieje lub została usunięta...</Text>
        <Button
          background="blue"
          type="button"
          onClick={() => {
            history.push('/panel-uzytkownika');
          }}
        >
          Przejdź do panelu użytkownika
        </Button>
      </TextWrapper>
    </Wrapper>
  );
};

NotFound.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(NotFound);
