import { useState, useEffect } from 'react';
import deepmerge from 'deepmerge';

import defaultTemplates from 'store/data/defaultTemplates';

/**
 * Funkcja rekurencyjna iterująca po każdym elemencie sprawdzająca przy tym,
 * czy klucz elementu isVisible jest wartością fałszywą.
 *
 * @param {Object} object - elementy z najowszego obiektu historii
 * @param {Object} elements - opcje dla elementów z globalego obiektu konfiguracyjnego
 * @returns {Array}
 */
function mapHiddenElementsToArray(object, elements) {
  const arrayKeys = [];

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key) && key !== 'customCode') {
      const elementObject = { key, title: elements[key].title };

      if (Object.prototype.hasOwnProperty.call(object[key], 'children')) {
        const mappedChildren = mapHiddenElementsToArray(
          object[key].children,
          elements[key].children,
        );

        if (mappedChildren.length) {
          arrayKeys.push([
            elementObject,
            mapHiddenElementsToArray(object[key].children, elements[key].children),
          ]);
        }
      } else if (!object[key].isVisible) {
        arrayKeys.push(elementObject);
      }
    }
  }

  return arrayKeys;
}

/**
 * Tworzy strukturę obiektu widocznego elementu
 *
 * @param {Array<string>} keys
 * @param {number} deepIndex
 * @returns {Object}
 */
function createUpdateObject(keys, deepIndex) {
  const value =
    deepIndex + 1 === keys.length
      ? { isVisible: true }
      : { children: createUpdateObject(keys, deepIndex + 1) };

  return {
    [keys[deepIndex]]: value,
  };
}

function sanitizeConfig(config, type) {
  const sanitizedConfig = { ...config };
  const defaultTemplate = defaultTemplates.find(template => template.type === type);
  const defaultElOpts = defaultTemplate.config.elementsOptions;

  for (const key in defaultElOpts) {
    if (Object.prototype.hasOwnProperty.call(defaultElOpts, key)) {
      sanitizedConfig.elementsOptions[key] = {
        ...defaultElOpts[key],
        ...config.elementsOptions[key],
      };

      if (['visualObject', 'usernameAction'].includes(key)) {
        for (let i = 1; i < 4; i += 1) {
          sanitizedConfig.elementsOptions[key + i] = {
            ...defaultElOpts[key],
            ...config.elementsOptions[key + i],
          };
        }
      }
    }
  }

  return sanitizedConfig;
}

const useTemplateConfigHistory = (templateConfig, type) => {
  const sanitizedConfig = sanitizeConfig(templateConfig, type);
  const [configEntries, setConfigEntries] = useState([sanitizedConfig]);
  const [latestTemplateConfig, setLatestTemplateConfig] = useState(sanitizedConfig);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setLatestTemplateConfig(configEntries[configEntries.length - 1]);
    setHasChanges(configEntries.length > 1);
  }, [configEntries]);

  const resetHistory = newConfig => {
    const newSanitizedConfig = sanitizeConfig(newConfig, type);
    setConfigEntries([newSanitizedConfig]);
  };

  /**
   * Tworzy nowy zapis historii na podstawie ostatniego zapisu
   * @returns {Object}
   */
  const createHistoryEntry = () => deepmerge({}, latestTemplateConfig);

  /**
   * Dodaje nowy wpis historii zmian
   *
   * @param {Object} updatedData
   * @returns {void}
   */
  const pushHistoryEntry = updatedData => {
    const historyElement = createHistoryEntry();
    const mergedHistory = deepmerge(historyElement, updatedData);
    setConfigEntries(configEntries.concat(mergedHistory));
  };

  /**
   * Dodaje nowy wpis historii elementów
   *
   * @param {Object} updatedData
   * @returns {void}
   */
  const pushElementHistoryEntry = updatedData => {
    pushHistoryEntry({ elementsOptions: updatedData });
  };

  /**
   * Cofa historię o jeden krok, jeżeli to możliwe
   * @returns {void}
   */
  const undoStep = () => {
    if (configEntries.length > 1) {
      setConfigEntries(configEntries.slice(0, -1));
    }
  };

  // Screen specific methods

  /**
   * Wyświetla elment
   *
   * @param {string} elementKeys
   * @returns {void}
   */
  const showElement = elementKeys => {
    const keys = elementKeys.split('|');

    pushElementHistoryEntry(createUpdateObject(keys, 0));
  };

  /**
   * Zwraca wszystkie ukryte elementy
   * @property {Array<Object>} elements
   * @returns {Array}
   */
  const getHiddenElementsList = elements =>
    mapHiddenElementsToArray(latestTemplateConfig.elementsOptions, elements);

  // Local event methods

  /**
   * Wywołuje cofnięcie kroku w historii przy
   * kombinacji klawiszy **Ctrl + Z**
   * @param {KeyboardEvent} event
   * @returns {void}
   */
  const handleKeyPress = event => {
    if (event.ctrlKey && event.code === 'KeyZ') {
      undoStep();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return {
    latestTemplateConfig,
    resetHistory,
    pushHistoryEntry,
    pushElementHistoryEntry,
    createHistoryEntry,
    undoStep,
    hasChanges,
    setHasChanges,

    showElement,
    getHiddenElementsList,
  };
};

export default useTemplateConfigHistory;
