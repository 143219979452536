import styled, { css } from 'styled-components';

import DialogWindow from './DialogWindow';

const visible = css`
  opacity: 1;
  z-index: 1000;

  ${DialogWindow} {
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;

  opacity: 0;
  overflow: auto;

  z-index: -1;
  transition: all 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);

  &[hidden] {
    display: none;
  }

  ${props => props.isVisible && visible}
`;

export default Wrapper;
