import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import keycode from 'keycode';

import { setCurrentElement } from 'store/actions/templates';
import { mapElementsObjectToArray } from 'pages/Configurators/utils';
import { TextEditor } from 'components/ElementEditor';

import DragWrapper from '../../../../../../components/DragWrapper';

import Wrapper from './styles/Wrapper';
import PositionWrapper from './styles/PositionWrapper';
import GoalNumberItem from './components/GoalNumberItem';
import GoalNumberShadow from './components/GoalNumberShadow';

const elementsOrder = ['from', 'separator', 'to', 'percent'];

const GoalNumbers = ({
  activeTemplate,
  options,
  updateElement,
  configuratorWrapper,
  configuratorType,
  onKeyDown,
}) => {
  const [textEditorMounted, setTextEditorMounted] = useState({
    counter: false,
    colon: false,
    price: false,
    nickname: false,
  });
  const [textEditorMode, setTextEditorMode] = useState('style');
  const elements = mapElementsObjectToArray(options.children, elementsOrder);
  const dispatch = useDispatch();

  const fromElementOptions = elements[0][1];
  const separatorElementOptions = elements[1][1];
  const toElementOptions = elements[2][1];
  const percentElementOptions = elements[3][1];

  return (
    <>
      {options.changePosition && (
        <>
          <DragWrapper
            updateElement={updateElement}
            elementOptions={options}
            elementName="from"
            configuratorType={configuratorType}
          >
            <div>
              <PositionWrapper shadow>
                <GoalNumberShadow key="from" elementName="from" options={fromElementOptions} />
              </PositionWrapper>
              <PositionWrapper>
                <GoalNumberItem
                  isEditable={activeTemplate.config.editable}
                  key="from"
                  elementName="from"
                  options={fromElementOptions}
                  focused={textEditorMounted.from}
                  onDoubleClick={elementRef =>
                    setTextEditorMounted({ ...textEditorMounted, from: elementRef })
                  }
                  onSelectedOption={(option, elementRef) => {
                    if (option === 'editText' || option === 'changeText') {
                      setTextEditorMounted({ ...textEditorMounted, from: elementRef });
                      dispatch(setCurrentElement('from'));
                    }

                    if (option === 'editText') {
                      setTextEditorMode('style');
                    } else if (option === 'changeText') {
                      setTextEditorMode('text');
                    } else if (option === 'delete') {
                      updateElement({
                        goalNumbers: { children: { from: { isVisible: false } } },
                      });
                    }
                  }}
                  onKeyDown={event => {
                    const keyCode = keycode(event);

                    if (keyCode === 'delete') {
                      updateElement({
                        goalNumbers: { children: { from: { isVisible: false } } },
                      });
                    }
                  }}
                />
              </PositionWrapper>
            </div>
          </DragWrapper>
          <DragWrapper
            updateElement={updateElement}
            elementOptions={options}
            elementName="separator"
            configuratorType={configuratorType}
            onKeyDown={event => onKeyDown(event, 'goalNumbers', ['delete'])}
          >
            <div>
              <PositionWrapper shadow>
                <GoalNumberShadow
                  key="separator"
                  elementName="separator"
                  options={separatorElementOptions}
                />
              </PositionWrapper>
              <PositionWrapper>
                <GoalNumberItem
                  isEditable={activeTemplate.config.editable}
                  key="separator"
                  elementName="separator"
                  options={separatorElementOptions}
                  focused={textEditorMounted.separator}
                  onDoubleClick={elementRef =>
                    setTextEditorMounted({ ...textEditorMounted, separator: elementRef })
                  }
                  onSelectedOption={(option, elementRef) => {
                    if (option === 'editText' || option === 'changeText') {
                      setTextEditorMounted({ ...textEditorMounted, separator: elementRef });
                      dispatch(setCurrentElement('separator'));
                    }

                    if (option === 'editText') {
                      setTextEditorMode('style');
                    } else if (option === 'changeText') {
                      setTextEditorMode('text');
                    } else if (option === 'delete') {
                      updateElement({
                        goalNumbers: { children: { separator: { isVisible: false } } },
                      });
                    }
                  }}
                  onKeyDown={event => {
                    const keyCode = keycode(event);

                    if (keyCode === 'delete') {
                      updateElement({
                        goalNumbers: { children: { separator: { isVisible: false } } },
                      });
                    }
                  }}
                />
              </PositionWrapper>
            </div>
          </DragWrapper>
          <DragWrapper
            updateElement={updateElement}
            elementOptions={options}
            elementName="to"
            configuratorType={configuratorType}
            onKeyDown={event => onKeyDown(event, 'goalNumbers', ['delete'])}
          >
            <div>
              <PositionWrapper shadow>
                <GoalNumberShadow key="to" elementName="to" options={toElementOptions} />
              </PositionWrapper>
              <PositionWrapper>
                <GoalNumberItem
                  isEditable={activeTemplate.config.editable}
                  key="to"
                  elementName="to"
                  options={toElementOptions}
                  focused={textEditorMounted.to}
                  onDoubleClick={elementRef =>
                    setTextEditorMounted({ ...textEditorMounted, to: elementRef })
                  }
                  onSelectedOption={(option, elementRef) => {
                    if (option === 'editText' || option === 'changeText') {
                      setTextEditorMounted({ ...textEditorMounted, to: elementRef });
                      dispatch(setCurrentElement('to'));
                    }

                    if (option === 'editText') {
                      setTextEditorMode('style');
                    } else if (option === 'changeText') {
                      setTextEditorMode('text');
                    } else if (option === 'delete') {
                      updateElement({
                        goalNumbers: { children: { to: { isVisible: false } } },
                      });
                    }
                  }}
                  onKeyDown={event => {
                    const keyCode = keycode(event);

                    if (keyCode === 'delete') {
                      updateElement({
                        goalNumbers: { children: { to: { isVisible: false } } },
                      });
                    }
                  }}
                />
              </PositionWrapper>
            </div>
          </DragWrapper>
          <DragWrapper
            updateElement={updateElement}
            elementOptions={options}
            elementName="percent"
            configuratorType={configuratorType}
            onKeyDown={event => onKeyDown(event, 'goalNumbers', ['delete'])}
          >
            <div>
              <PositionWrapper shadow>
                <GoalNumberShadow
                  key="percent"
                  elementName="percent"
                  options={percentElementOptions}
                />
              </PositionWrapper>
              <PositionWrapper>
                <GoalNumberItem
                  isEditable={activeTemplate.config.editable}
                  key="percent"
                  elementName="percent"
                  options={percentElementOptions}
                  focused={textEditorMounted.percent}
                  onDoubleClick={elementRef =>
                    setTextEditorMounted({ ...textEditorMounted, percent: elementRef })
                  }
                  onSelectedOption={(option, elementRef) => {
                    if (option === 'editText' || option === 'changeText') {
                      setTextEditorMounted({ ...textEditorMounted, percent: elementRef });
                      dispatch(setCurrentElement('percent'));
                    }

                    if (option === 'editText') {
                      setTextEditorMode('style');
                    } else if (option === 'changeText') {
                      setTextEditorMode('text');
                    } else if (option === 'delete') {
                      updateElement({
                        goalNumbers: { children: { percent: { isVisible: false } } },
                      });
                    }
                  }}
                  onKeyDown={event => {
                    const keyCode = keycode(event);

                    if (keyCode === 'delete') {
                      updateElement({
                        goalNumbers: { children: { percent: { isVisible: false } } },
                      });
                    }
                  }}
                />
              </PositionWrapper>
            </div>
          </DragWrapper>
        </>
      )}

      {!options.changePosition && (
        <DragWrapper
          updateElement={updateElement}
          elementOptions={options}
          elementName="goalNumbers"
          configuratorType={configuratorType}
          onKeyDown={event => onKeyDown(event, 'goalNumbers', ['delete'])}
        >
          <div>
            <Wrapper shadow>
              {elements.map(([elementName, elementOptions]) => (
                <GoalNumberShadow
                  key={elementName}
                  elementName={elementName}
                  options={elementOptions}
                />
              ))}
            </Wrapper>
            <Wrapper>
              {elements.map(([elementName, elementOptions]) => (
                <GoalNumberItem
                  isEditable={activeTemplate.config.editable}
                  key={elementName}
                  elementName={elementName}
                  options={elementOptions}
                  focused={textEditorMounted[elementName]}
                  onDoubleClick={elementRef =>
                    setTextEditorMounted({ ...textEditorMounted, [elementName]: elementRef })
                  }
                  onSelectedOption={(option, elementRef) => {
                    if (option === 'editText' || option === 'changeText') {
                      setTextEditorMounted({ ...textEditorMounted, [elementName]: elementRef });
                      dispatch(setCurrentElement(elementName));
                    }

                    if (option === 'editText') {
                      setTextEditorMode('style');
                    } else if (option === 'changeText') {
                      setTextEditorMode('text');
                    } else if (option === 'delete') {
                      updateElement({
                        goalNumbers: { children: { [elementName]: { isVisible: false } } },
                      });
                    }
                  }}
                  onKeyDown={event => {
                    const keyCode = keycode(event);

                    if (keyCode === 'delete') {
                      updateElement({
                        goalNumbers: { children: { [elementName]: { isVisible: false } } },
                      });
                    }
                  }}
                />
              ))}
            </Wrapper>
          </div>
        </DragWrapper>
      )}

      {elements.map(([elementName, elementOptions]) => (
        <TextEditor
          key={elementName}
          activeTemplate={activeTemplate}
          isMounted={!!textEditorMounted[elementName]}
          snapTo={textEditorMounted[elementName]}
          defaultStyle={elementOptions.styles}
          onChange={styles =>
            updateElement({ goalNumbers: { children: { [elementName]: { styles } } } })
          }
          onChangeText={newText =>
            updateElement({ goalNumbers: { children: { [elementName]: { textValue: newText } } } })
          }
          onHide={() => setTextEditorMounted({ ...textEditorMounted, [elementName]: false })}
          showIn={configuratorWrapper.current}
          mode={textEditorMode}
          text={elementOptions.textValue}
        />
      ))}
    </>
  );
};

GoalNumbers.propTypes = {
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  configuratorWrapper: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  onKeyDown: PropTypes.instanceOf(Function).isRequired,
  configuratorType: PropTypes.string.isRequired,
};

export default GoalNumbers;
