import styled from 'styled-components';

export default styled.button`
  position: absolute;
  top: 15px;
  right: 13px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: var(--bg-color-light-gray);

  outline: none;
  padding: 0;
  border: 0;

  svg {
    fill: var(--text-color);
    width: 100%;
    margin: 0;
  }
`;
