import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { alert } from 'components/Alert';
import Grid from 'components/Grid';
import Item from './components/Item';
import ExternalMethod from './components/ExternalMethod';
import PayPalMethod from './components/PayPalMethod';

export default class PaymentMethods extends Component {
  static propTypes = {
    fetchPaymentMethods: PropTypes.instanceOf(Function).isRequired,
    fetchPaymentMethodsConfiguration: PropTypes.instanceOf(Function).isRequired,
    submitChangedPaymentMethod: PropTypes.instanceOf(Function).isRequired,
    paymentMethods: PropTypes.instanceOf(Object).isRequired,
    paymentMethodsConfiguration: PropTypes.instanceOf(Object).isRequired,
    profile: PropTypes.instanceOf(Object).isRequired,
    userDataInfo: PropTypes.instanceOf(Object).isRequired,
  };

  componentDidMount() {
    const { fetchPaymentMethods, fetchPaymentMethodsConfiguration } = this.props;

    fetchPaymentMethods();
    fetchPaymentMethodsConfiguration();
  }

  onChange(methodName, field, value) {
    const { submitChangedPaymentMethod, profile } = this.props;

    if (methodName === 'paypal' && field === 'enabled' && value === true && !profile.paypal_email) {
      alert.open({
        config: {
          type: 'error',
          title: 'Coś się pokićkało',
          description: <span>Musisz najpierw ustawić swój e-mail PayPal.</span>,
        },
      });
      return;
    }

    submitChangedPaymentMethod(methodName, field, value);
  }

  render() {
    const { paymentMethods, paymentMethodsConfiguration, profile, userDataInfo } = this.props;
    const isPpe = !profile.paypal_email;

    return (
      <Grid columns="1fr 1fr" gap="15px 15px">
        {Object.keys(paymentMethods).map(methodName => {
          const paymentName = paymentMethods[methodName].human_method_name;
          const methodState = paymentMethodsConfiguration[methodName].state;
          const tooltipText = paymentMethodsConfiguration[methodName].tooltip_text;
          const hoverText = paymentMethodsConfiguration[methodName].on_hover_text;
          const disabledForNotVerified = paymentMethodsConfiguration[methodName].is_for_verified;
          const systemMinAmount = paymentMethodsConfiguration[methodName].minimal_amount;
          const handleOnChange = (field, value) => this.onChange(methodName, field, value);
          const paymentsDisabled = userDataInfo.payments_disabled;
          const missingPersonalData = userDataInfo.missing_personal_data;
          const fraudDetected = userDataInfo.fraud;

          const isPaymentEnabled =
            paymentsDisabled || missingPersonalData || fraudDetected
              ? false
              : paymentMethods[methodName].enabled;

          const currentMinAmount =
            paymentMethods[methodName].minimal_amount > systemMinAmount
              ? paymentMethods[methodName].minimal_amount / 100
              : systemMinAmount / 100;

          switch (methodName) {
            case 'gt_psc':
              return (
                <ExternalMethod
                  key={methodName}
                  onChange={handleOnChange}
                  paymentName={paymentName}
                  enabled={isPaymentEnabled}
                  methodState={methodState}
                  isPpe={isPpe}
                  tooltip={tooltipText}
                  hoverText={hoverText}
                />
              );
            case 'paypal':
              return (
                <PayPalMethod
                  key={methodName}
                  onChange={handleOnChange}
                  paymentName={paymentName}
                  enabled={isPaymentEnabled}
                  methodState={methodState}
                  tooltip={tooltipText}
                  hoverText={hoverText}
                  currentMinAmount={currentMinAmount}
                />
              );
            default:
              return (
                <Item
                  key={methodName}
                  onChange={handleOnChange}
                  paymentName={paymentName}
                  price={currentMinAmount}
                  enabled={isPaymentEnabled}
                  methodState={methodState}
                  isPpe={isPpe}
                  tooltip={tooltipText}
                  hoverText={hoverText}
                  disabledForNotVerified={disabledForNotVerified}
                />
              );
          }
        })}
      </Grid>
    );
  }
}
