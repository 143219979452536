import http from 'utils/http';

const requestPaymentMethodsConfiguration = () => ({
  type: 'REQUEST_PAYMENT_METHODS_CONFIGURATION',
});

const receivePaymentMethodsConfiguration = configuration => ({
  type: 'RECEIVE_PAYMENT_METHODS_CONFIGURATION',
  receivedData: configuration,
});

const failurePaymentMethodsConfiguration = () => ({
  type: 'FAILURE_PAYMENT_METHODS_CONFIGURATION',
});

export const fetchPaymentMethodsConfiguration = () => async dispatch => {
  dispatch(requestPaymentMethodsConfiguration());

  try {
    const commissions = await http.get('/payment-methods-configuration');

    dispatch(receivePaymentMethodsConfiguration(commissions.data));
  } catch (error) {
    failurePaymentMethodsConfiguration();
  }
};
