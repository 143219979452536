import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import IconInCircle from 'components/styled/buttons/IconInCircle';

const Button = styled(IconInCircle)`
  > svg {
    transform: rotate(-90deg);
  }

  &:hover {
    transform: scale(1.2) rotate(-10deg);
  }
`;

const ArrowInCircleButton = props => (
  <Button type="button" {...props}>
    <ArrowDown />
  </Button>
);

export default ArrowInCircleButton;
