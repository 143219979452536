import styled from 'styled-components';

export default styled.div`
  display: inline-block;

  > div {
    display: block;
    margin: 0;
  }
`;
