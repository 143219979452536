import styled from 'styled-components';

export default styled.div`
  display: flex;

  justify-self: end;
  margin: 0 -5px;

  > button {
    margin: 0 5px;
  }
`;
