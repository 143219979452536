import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 12px 18px;
  border: 1px solid var(--light-gray);
  height: 10px;
  background-color: var(--white);

  > span {
    font-size: 12px;
    font-weight: 900;
    color: var(--text-color);
  }
`;
