/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { RangeSlider } from 'components/RangeSlider';
import { SimpleCheckbox } from 'components/Checkboxes';
import NumberInput from 'components/NumberInput';
import PopupDialog from 'components/Modal/styles/PopupDialogWrapper';
import PopupContent from 'components/Modal/styles/PopupContent';
import PopupArrow from 'components/Modal/styles/PopupArrow';

import Maximum from './styles/Maximum';
import Separator from './styles/Separator';
import CheckboxWrapper from './styles/CheckboxWrapper';
import Volume from './styles/Volume';
import Pitch from './styles/Pitch';

import Context from './Context';

import List from './components/List';

export default class SpeechSynthesizerManager extends Component {
  static propTypes = {
    config: PropTypes.instanceOf(Object).isRequired,
    speechSynthesizerVoices: PropTypes.instanceOf(Array).isRequired,
    testVoices: PropTypes.instanceOf(Object).isRequired,
    activeTemplateId: PropTypes.string.isRequired,
    onChange: PropTypes.instanceOf(Function).isRequired,
    currentSettings: PropTypes.instanceOf(Object).isRequired,
    triggerRef: PropTypes.instanceOf(Object).isRequired,
    visible: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      currentSettings: {
        options: { volume, pitch, maxEmotes, maxSigns },
      },
      activeTemplateId,
    } = this.props;

    this.state = {
      volumeValue: Number.parseInt(volume * 100, 10),
      pitchValue: Number.parseInt(pitch, 10),
      maxEmotes: Number.parseInt(maxEmotes, 10),
      maxSigns: Number.parseInt(maxSigns, 10),
      trianglePosition: 100,
      activeTemplateId,
    };

    this.audioRef = React.createRef();
    this.node = React.createRef();

    this.portal = document.createElement('div');
  }

  static getDerivedStateFromProps(props, state) {
    const returnedState = {};

    if (props.activeTemplateId !== state.activeTemplateId) {
      returnedState.volumeValue = Number.parseInt(props.config.options.volume * 100, 10);
      returnedState.pitchValue = Number.parseInt(props.config.options.pitch, 10);
      returnedState.maxEmotes = Number.parseInt(props.config.options.maxEmotes, 10);
      returnedState.maxSigns = Number.parseInt(props.config.options.maxSigns, 10);
      returnedState.activeTemplateId = props.activeTemplateId;
    }

    return returnedState;
  }

  componentDidMount() {
    document.body.append(this.portal);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { triggerRef, visible } = this.props;
    const { visible: prevVisible } = prevProps;

    if (triggerRef.current && this.node.current && !prevVisible && visible) {
      const vbox = triggerRef.current.getBoundingClientRect();
      const dialogHeight = this.node.current.getBoundingClientRect().height;
      const targetTopOffset = window.innerHeight * 0.5 - dialogHeight * 0.5;

      this.setTrianglePosition(dialogHeight * 0.5);
      this.node.current.style.top = `${targetTopOffset}px`;
      this.node.current.style.left = `${vbox.left + vbox.width + 57}px`;
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.portal);
  }

  setTrianglePosition(trianglePosition) {
    this.setState({ trianglePosition });
  }

  handleUpdateConfig(key, value, inside = 'options') {
    const { onChange, currentSettings } = this.props;
    const updated = { ...currentSettings };

    if (inside) {
      updated[inside][key] = value;
    } else {
      updated[key] = value;
    }

    onChange(updated);
  }

  render() {
    const {
      speechSynthesizerVoices,
      testVoices,
      currentSettings: { voiceType, options },
      visible,
    } = this.props;
    const { volumeValue, pitchValue, maxEmotes, maxSigns, trianglePosition } = this.state;

    const activeVoice = speechSynthesizerVoices.find(item => item.id === voiceType);

    const activeVoiceUrl = activeVoice.id === 'DISABLED' ? '/' : testVoices[activeVoice.id];

    const showPitch = voiceType.includes('GOOGLE');

    return ReactDOM.createPortal(
      <Context.Provider
        value={{
          activeId: voiceType,
          onChangeOption: id => this.handleUpdateConfig('voiceType', id, false),
        }}
      >
        <PopupDialog ref={this.node} visible={visible}>
          <PopupArrow position={trianglePosition} />
          <PopupContent>
            <List items={speechSynthesizerVoices} />
            <Separator />

            <CheckboxWrapper>
              <SimpleCheckbox
                checked={options.readNickname}
                onCheck={value => this.handleUpdateConfig('readNickname', value)}
                text="Czyta nazwę użytkownika"
                name="speechsynthesizer-read-nickname"
              />
            </CheckboxWrapper>

            <CheckboxWrapper>
              <SimpleCheckbox
                checked={options.readAmount}
                onCheck={value => this.handleUpdateConfig('readAmount', value)}
                text="Czyta kwotę"
                name="speechsynthesizer-read-price"
              />
            </CheckboxWrapper>

            <CheckboxWrapper>
              <SimpleCheckbox
                checked={options.readMessage}
                onCheck={value => this.handleUpdateConfig('readMessage', value)}
                text="Czyta wiadomość"
                name="speechsynthesizer-read-message"
              />
            </CheckboxWrapper>

            <CheckboxWrapper>
              <SimpleCheckbox
                checked={options.readLink}
                onCheck={value => this.handleUpdateConfig('readLink', value)}
                text="Czyta link"
                name="speechsynthesizer-read-link"
              />
            </CheckboxWrapper>

            <CheckboxWrapper>
              <span>Interpretuj nazwy linków:</span>
              <span>
                <SimpleCheckbox
                  checked={options.interLink}
                  onCheck={value => this.handleUpdateConfig('interLink', value)}
                  name="speechsynthesizer-inter-link"
                />
              </span>
            </CheckboxWrapper>

            <CheckboxWrapper>
              <Maximum>
                <SimpleCheckbox
                  checked={options.readEmoji}
                  onCheck={value => this.handleUpdateConfig('readEmoji', value)}
                  text="Czyta emotkę"
                  name="speechsynthesizer-read-emoji"
                />

                <div>
                  Maksymalnie:
                  <NumberInput
                    defaultValue={maxEmotes || 255}
                    decimalScale={0}
                    allowEmptyFormatting
                    allowNegative={false}
                    suffix=" emotek"
                    style={{ margin: 5, maxWidth: 120, maxHeight: 28, textAlign: 'center' }}
                    isAllowed={({ floatValue }) => floatValue >= 1 && floatValue < 256}
                    onValueChange={({ floatValue }) => {
                      this.setState({ maxEmotes: floatValue });
                      this.handleUpdateConfig('maxEmotes', floatValue);
                    }}
                  />
                </div>
              </Maximum>
            </CheckboxWrapper>

            <CheckboxWrapper>
              <SimpleCheckbox
                checked={options.readSpecChar}
                onCheck={value => this.handleUpdateConfig('readSpecChar', value)}
                text="Czyta znak specjalny"
                name="speechsynthesizer-read-specchar"
              />
              <Maximum>
                <div>
                  Maksymalnie:
                  <NumberInput
                    defaultValue={maxSigns || 255}
                    decimalScale={0}
                    allowEmptyFormatting
                    allowNegative={false}
                    suffix=" znaków"
                    style={{ margin: 5, maxWidth: 120, maxHeight: 28, textAlign: 'center' }}
                    isAllowed={({ floatValue }) => floatValue >= 1 && floatValue < 256}
                    onValueChange={({ floatValue }) => {
                      this.setState({ maxSigns: floatValue });
                      this.handleUpdateConfig('maxSigns', floatValue);
                    }}
                  />
                </div>
              </Maximum>
            </CheckboxWrapper>

            <Separator />
            <Volume>
              <span>Głośność</span>
              <RangeSlider
                position="vertical"
                min={0}
                max={100}
                value={volumeValue}
                defaultValue={volumeValue}
                onChange={value => this.setState({ volumeValue: value })}
                onBeforeChange={() => {
                  this.audioRef.current.pause();
                  this.audioRef.current.currentTime = 0;
                }}
                onAfterChange={value => {
                  this.audioRef.current.volume = volumeValue / 100;
                  this.audioRef.current.play();

                  this.handleUpdateConfig('volume', value / 100);
                }}
              />

              <audio
                ref={this.audioRef}
                src={activeVoiceUrl}
                volume={volumeValue / 100}
                controls={false}
              />
            </Volume>

            {showPitch && (
              <Pitch>
                <span>Ton</span>
                <RangeSlider
                  position="vertical"
                  valuePrefix=""
                  min={-10}
                  max={10}
                  step={5}
                  value={pitchValue || 0}
                  defaultValue={pitchValue || 0}
                  onChange={value => this.setState({ pitchValue: value })}
                  onAfterChange={value => {
                    this.handleUpdateConfig('pitch', value);
                  }}
                />
              </Pitch>
            )}
          </PopupContent>
        </PopupDialog>
      </Context.Provider>,
      this.portal,
    );
  }
}
