import React from 'react';

import Logo from './components/Logo';
import MainMenu from './components/MainMenu';
import Tools from './components/Tools';

import StyledMenu from './styles/StyledMenu';

const MenuTop = () => (
  <>
    <div style={{ paddingTop: 'var(--menu-height)', paddingBottom: '15px' }} />
    <StyledMenu>
      <div className="app-container menu-bar">
        <Logo />
        <MainMenu />
        <Tools />
      </div>
    </StyledMenu>
  </>
);

export default MenuTop;
