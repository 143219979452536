import React from 'react';

import { useTitle } from 'utils/hooks';

import withConfiguration from '../containers/withConfiguration';
import withHistory from '../containers/withHistory';
import ListView from './ListView';

const CONFIGURATOR_TYPE = 'LARGEST_DONATES';

const LargestDonates = props => {
  useTitle('Tipply | Konfigurator (Ranking wiadomości)');

  return <ListView widgetsUrl="LARGEST_MESSAGES" configuratorType={CONFIGURATOR_TYPE} {...props} />;
};

export default withConfiguration(withHistory(LargestDonates), CONFIGURATOR_TYPE);
