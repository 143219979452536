import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ButtonWithAnimatedArrow } from 'components/Buttons';

import SelectedPlatform from './SelectedPlatform';
import BackButton from './BackButton';

const flexCenteredContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const fixedHeight = css`
  height: 552px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 5fr 2fr;

  .content {
    justify-content: center;
    ${flexCenteredContent};
  }
`;

export const StepContentWrapper = styled.div`
  border-radius: 50px;
  background-color: var(--light-gray);
  padding: 28px 45px 22px;
  ${props => props.fixedHeight && fixedHeight};

  .footer {
    justify-content: flex-end;
    ${flexCenteredContent}
  }
`;

const StepContent = ({
  provider,
  children,
  processStep,
  goPrev,
  processButtonLabel,
  disabled = false,
}) => (
  <StepContentWrapper>
    <SelectedPlatform provider={provider} style={{ marginBottom: 39 }} />
    {children}
    <div className="footer">
      <ButtonWithAnimatedArrow onClick={processStep} earlyHoverDetect={false} disabled={disabled}>
        {processButtonLabel || 'Przejdź dalej'}
      </ButtonWithAnimatedArrow>
      <BackButton onClick={goPrev} disabled={disabled}>
        Wstecz
      </BackButton>
    </div>
  </StepContentWrapper>
);

export default StepContent;

StepContent.propTypes = {
  provider: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function),
  processButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

StepContent.defaultProps = {
  goPrev: () => {},
  processButtonLabel: null,
  disabled: false,
};
