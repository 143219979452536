import { connect } from 'react-redux';

import { fetchAnnouncementsIfNeeded, markAnnouncementsAsRead } from 'store/actions/announcements';
import { fetchExtraAnnouncementsIfNeeded } from 'store/actions/extraAnnouncements';
import {
  fetchUserProfileIfNeeded,
  submitChangedPassword,
  submitChangedEmail,
  sendEmailAuthenticatorCode,
  enableEmailAuthenticator,
  disableEmailAuthenticator,
  connectGoogleAuthenticator,
  enableGoogleAuthenticator,
  disableGoogleAuthenticator,
  disconnectService,
} from 'store/actions/userData';

import Tools from './Tools';

const mapStateToProps = ({ templates, announcements, userData, mediaManager }) => ({
  announcementsList: announcements.items,
  announcementsFailurePatching: announcements.failurePatching,
  announcementsFailureFetching: announcements.failureFetching,
  announcementsArePatching: announcements.isPatching,
  announcementsAreFetching: announcements.isFetching,
  templates: [...templates.defaults, ...templates.created],
  userDataInfo: userData.info,
  userDataProfile: userData.profile,
  userDataIsPatching: userData.isPatching,
  showManager: mediaManager.showManager,
});

const mapDispatchToProps = dispatch => ({
  fetchAnnouncementsIfNeeded() {
    dispatch(fetchAnnouncementsIfNeeded());
  },
  fetchExtraAnnouncementsIfNeeded() {
    dispatch(fetchExtraAnnouncementsIfNeeded());
  },
  markAnnouncementsAsRead() {
    dispatch(markAnnouncementsAsRead());
  },
  fetchUserProfileIfNeeded() {
    dispatch(fetchUserProfileIfNeeded());
  },
  submitChangedPassword(passwords) {
    return dispatch(submitChangedPassword(passwords));
  },
  submitChangedEmail(emails) {
    return dispatch(submitChangedEmail(emails));
  },
  sendEmailAuthenticatorCode() {
    return dispatch(sendEmailAuthenticatorCode());
  },
  enableEmailAuthenticator(confirmationCode) {
    return dispatch(enableEmailAuthenticator(confirmationCode));
  },
  disableEmailAuthenticator() {
    return dispatch(disableEmailAuthenticator());
  },
  connectGoogleAuthenticator() {
    return dispatch(connectGoogleAuthenticator());
  },
  enableGoogleAuthenticator(confirmationCode) {
    return dispatch(enableGoogleAuthenticator(confirmationCode));
  },
  disableGoogleAuthenticator() {
    return dispatch(disableGoogleAuthenticator());
  },
  disconnectService(service) {
    return dispatch(disconnectService(service));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tools);
