import React from 'react';

import { convertToGrosze, convertToZlotys } from 'utils/priceConverter';
import { useDelayedState } from 'utils/hooks';
import NumberInput from 'components/NumberInput';
import WhiteBox from 'styles/WhiteBox';

import styled, { css } from 'styled-components';
import { SimpleCheckbox } from 'components/Checkboxes';
import useViewConfig from '../../Configurators/hooks/useViewConfig';

const Badge = styled.div`
  padding: 5px 0 5px 0;
  color: var(--brand-blue);
  font-size: 14px;
  font-weight: 900;
  font-family: var(--google-sora-cdn);
`;

const Headers = styled.div`
  padding: 5px 0 5px 0;
  color: var(---text-color);
  font-size: 25px;
  font-weight: 900;
  font-family: var(--google-sora-cdn);
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  color: var(--gray);
`;

const DisplayBox = styled.div`
  font-family: var(--google-sora-cdn);
  display: flex;
  flex-direction: row;
  height: 180px;
  align-items: flex-start;
  column-gap: 32px;
`;

const Lef = styled.div`
  width: 420px;
  font-family: var(--google-sora-cdn);
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Right = styled.div`
  margin-left: 60px;
  width: 120px;
  align-self: center;
`;

const InfoText = styled.div`
  font-size: 14px;
  font-weight: 900;
  width: 450px;
`;

function infoVariant(variant) {
  switch (variant) {
    case 'success':
      return '--brand-green';
    case 'error':
      return '--red';
    default:
      return '--brand-blue';
  }
}

const Info = styled.div`
  padding: 10px 20px;
  width: auto;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid var(${({ $variant }) => infoVariant($variant)});
  background: color-mix(in srgb, var(${({ $variant }) => infoVariant($variant)}) 10%, transparent);
`;

const OnOff = styled.div`
  width: 100px;
  margin-bottom: 15px;
  font-weight: 900;
`;

const OneRow = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
  font-weight: 900;

  > label {
    height: 52px;
    border-radius: 10px;
    border: 2px solid var(--border-color);
    width: auto;
  }

  input {
    display: inline-block;
    color: var(--text-color);
    font-weight: 900;
    text-align: left;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.6;
      input {
        color: inherit;
      }
    `}
`;

const VoiceMessages = () => {
  const configurationType = 'TIP_ALERT';
  const { configurationsAreFetched, configuratorConfig, updateConfig } = useViewConfig(
    configurationType,
  );
  const { enable, amount } = configuratorConfig.voiceMessages;

  const updateValue = (fieldName, newValue) => {
    const fragment = { voiceMessages: { [fieldName]: newValue } };
    updateConfig(fragment);
  };

  const [innerEnabled, setInnerEnabled] = useDelayedState(enable, 'enable', updateValue);
  const [innerAmount, setInnerAmount] = useDelayedState(amount, 'amount', updateValue);

  return (
    <WhiteBox id="glosowki_od_widzow" style={{ padding: '30px 60px' }}>
      <DisplayBox>
        <Lef>
          <Badge>Polecane</Badge>
          <Headers>Głosówki od widzów</Headers>
          <Description>
            Jest to maksymalnie 15 sekundowa wiadomość głosowa, która może zostać dołączona do
            wysyłanego dla Ciebie donejta. Ustaw kwotę minimalną od której widz, będzie mógł nagrać
            taką wiadomość! Pamiętaj, że dzięki moderacji wiadomości, możesz Ty albo Twój moderator
            je moderować!
          </Description>
        </Lef>

        <Center>
          <InfoText>
            WYBIERZ KWOTĘ OD KTÓREJ
            <br />
            WIDZ MOŻE NAGRAĆ WIADOMOŚĆ GŁOSOWĄ
          </InfoText>

          <OneRow $disabled={!innerEnabled}>
            <NumberInput
              decimalSeparator=","
              value={convertToZlotys(innerAmount)}
              defaultValue={convertToZlotys(innerAmount)}
              decimalScale={2}
              allowEmptyFormatting
              allowNegative={false}
              suffix=" PLN"
              style={{ maxWidth: 110 }}
              isAllowed={({ floatValue }) => floatValue >= 0 && floatValue < 100001}
              onValueChange={({ floatValue }) => {
                setInnerAmount(convertToGrosze(floatValue));
              }}
              disabled={!innerEnabled && configurationsAreFetched}
            />
          </OneRow>

          <Info $variant={innerEnabled ? 'success' : 'error'}>
            {`Głosówki od widzów są ${innerEnabled ? '' : 'nie'} aktywne na Twoim profilu`}
          </Info>
        </Center>

        <Right>
          <OnOff>WŁ / WYŁ</OnOff>
          <SimpleCheckbox
            color="green"
            apperance="switch"
            checked={innerEnabled}
            onCheck={value => setInnerEnabled(value)}
          />
        </Right>
      </DisplayBox>
    </WhiteBox>
  );
};

export default VoiceMessages;
