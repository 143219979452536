import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ClearButton } from './styled';

const PauseIcon = styled.div`
  width: 100%;
  height: 100%;
  color: var(--text-color);
  display: inline-flex;
  justify-content: space-between;

  &::before,
  &::after {
    content: '';
    background-color: currentColor;
    display: block;
    width: 33%;
    height: 100%;
    border-radius: 2px;
  }

  &:hover {
    color: var(--blue);
  }
`;

const MediaPlayButton = ({ audioFile }) => {
  if (!audioFile.formats) {
    // load when fully ready
    return null;
  }

  const soundUrl = process.env.REACT_APP_API_URL + audioFile.formats.reference.url;
  const [audio] = useState(new Audio(soundUrl));
  const [isPLaying, setIsPlaying] = useState(false);

  useEffect(() => {
    audio.addEventListener('ended', () => setIsPlaying(false));
  }, [audio]);

  const handlePlay = evt => {
    evt.stopPropagation();

    if (isPLaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    } else {
      audio.play();
      setIsPlaying(true);
    }
  };

  return <ClearButton onClick={handlePlay}>{isPLaying ? <PauseIcon /> : <PlayIcon />}</ClearButton>;
};

MediaPlayButton.propTypes = {
  audioFile: PropTypes.instanceOf(Object).isRequired,
};

export default MediaPlayButton;
