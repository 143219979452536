import styled, { css } from 'styled-components';

import Button from 'components/Button';

const expanded = css`
  transform: rotate(-180deg);

  &:not(:disabled) {
    &:hover {
      transform: scale(1.2) rotate(-190deg);
    }
  }
`;

export default styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 5px;
  width: 40px;
  height: 40px;
  margin-right: 62px;

  transition: all 0.25s ease;

  &:not(:disabled) {
    &:hover {
      transform: scale(1.2) rotate(-10deg);
    }
  }

  > svg {
    width: 10px;
    height: 6px;
    fill: var(--text-color);

    top: 1px;
  }

  ${props => props.expanded && expanded}
`;
