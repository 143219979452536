import styled from 'styled-components';
import { ClearButton } from 'components/styled';

const DisabledOverlay = styled(ClearButton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  cursor: not-allowed;
  pointer-events: all;
`;

export default DisabledOverlay;
