import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import Input from '../../../styles/Input';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const CopiedOverlay = styled.div.attrs(({ copied }) => ({
  style: {
    opacity: copied ? 1 : 0,
  },
}))`
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.75);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  transition: opacity 0.2s ease-in-out;
  text-transform: uppercase;
`;

const InputWithCopy = ({ value, visibleValue }) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef(null);

  const handleClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setCopied(true);
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard text={value} onCopy={handleClick}>
      <Wrapper>
        <Input type="text" value={visibleValue || value} readOnly />
        <CopiedOverlay copied={copied}>Skopiowano!</CopiedOverlay>
      </Wrapper>
    </CopyToClipboard>
  );
};

InputWithCopy.propTypes = {
  value: PropTypes.string.isRequired,
  visibleValue: PropTypes.string,
};

InputWithCopy.defaultProps = {
  visibleValue: null,
};

export default InputWithCopy;
