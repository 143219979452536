import React from 'react';
import PropTypes from 'prop-types';

import { SimpleCheckbox } from 'components/Checkboxes';
import InfoTooltip from 'components/InfoTooltip';
import Box from '../styles/Box';

const Switcher = ({ text, name, value, tooltipContent, onChange, disabled }) => (
  <Box>
    <span style={{ position: 'relative', fontSize: 14 }}>
      {text}

      {!!tooltipContent && (
        <InfoTooltip
          style={{ position: 'absolute', right: -20 }}
          content={tooltipContent}
          placement="right"
          theme="dark"
        />
      )}
    </span>

    <SimpleCheckbox
      name={name}
      checked={value}
      onCheck={checkboxValue => onChange(checkboxValue)}
      color="green"
      apperance="switch"
      disabled={disabled}
    />
  </Box>
);

Switcher.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  disabled: PropTypes.bool,
};

Switcher.defaultProps = {
  tooltipContent: '',
  disabled: false,
};

export default Switcher;
