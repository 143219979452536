import styled from 'styled-components';

export default styled.div`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  border: 2px solid var(--border-color);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.05);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto 20px;
`;
