export default {
  in: {
    label: 'Wejście',
    list: [
      {
        name: 'bounce',
        animations: [
          { name: 'bounceIn', className: 'bounceIn' },
          { name: 'bounceInDown', className: 'bounceInDown' },
          { name: 'bounceInLeft', className: 'bounceInLeft' },
          { name: 'bounceInRight', className: 'bounceInRight' },
          { name: 'bounceInUp', className: 'bounceInUp' },
        ],
      },
      {
        name: 'fade',
        animations: [
          { name: 'fadeIn', className: 'fadeIn' },
          { name: 'fadeInDown', className: 'fadeInDown' },
          { name: 'fadeInDownBig', className: 'fadeInDownBig' },
          { name: 'fadeInLeft', className: 'fadeInLeft' },
          { name: 'fadeInLeftBig', className: 'fadeInLeftBig' },
          { name: 'fadeInRight', className: 'fadeInRight' },
          { name: 'fadeInRightBig', className: 'fadeInRightBig' },
          { name: 'fadeInUp', className: 'fadeInUp' },
          { name: 'fadeInUpBig', className: 'fadeInUpBig' },
        ],
      },
      {
        name: 'rotate',
        animations: [
          { name: 'rotateInDownLeft', className: 'rotateInDownLeft' },
          { name: 'rotateInDownRight', className: 'rotateInDownRight' },
          { name: 'rotateInUpLeft', className: 'rotateInUpLeft' },
          { name: 'rotateInUpRight', className: 'rotateInUpRight' },
        ],
      },
      {
        name: 'slide',
        animations: [
          { name: 'slideInUp', className: 'slideInUp' },
          { name: 'slideInDown', className: 'slideInDown' },
          { name: 'slideInLeft', className: 'slideInLeft' },
          { name: 'slideInRight', className: 'slideInRight' },
        ],
      },
      {
        name: 'zoom',
        animations: [
          { name: 'zoomInUp', className: 'zoomInUp' },
          { name: 'zoomInDown', className: 'zoomInDown' },
          { name: 'zoomInLeft', className: 'zoomInLeft' },
          { name: 'zoomInRight', className: 'zoomInRight' },
        ],
      },
      {
        name: 'specials',
        animations: [
          { name: 'rollIn', className: 'rollIn' },
          { name: 'jackInTheBox', className: 'jackInTheBox' },
        ],
      },
    ],
  },
  during: {
    label: 'W trakcie',
    list: [
      {
        name: 'Podtrzymujące uwagę',
        animations: [
          { name: 'bounce', className: 'bounce' },
          { name: 'flash', className: 'flash' },
          { name: 'pulse', className: 'pulse' },
          { name: 'rubberBand', className: 'rubberBand' },
          { name: 'shake', className: 'shake' },
          { name: 'swing', className: 'swing' },
          { name: 'tada', className: 'tada' },
          { name: 'wobble', className: 'wobble' },
          { name: 'jello', className: 'jello' },
          { name: 'heartBeat', className: 'heartBeat' },
        ],
      },
    ],
  },
  out: {
    label: 'Wyjście',
    list: [
      {
        name: 'bounce',
        animations: [
          { name: 'bounceOut', className: 'bounceOut' },
          { name: 'bounceOutDown', className: 'bounceOutDown' },
          { name: 'bounceOutLeft', className: 'bounceOutLeft' },
          { name: 'bounceOutRight', className: 'bounceOutRight' },
          { name: 'bounceOutUp', className: 'bounceOutUp' },
        ],
      },
      {
        name: 'fade',
        animations: [
          { name: 'fadeOut', className: 'fadeOut' },
          { name: 'fadeOutDown', className: 'fadeOutDown' },
          { name: 'fadeOutDownBig', className: 'fadeOutDownBig' },
          { name: 'fadeOutLeft', className: 'fadeOutLeft' },
          { name: 'fadeOutLeftBig', className: 'fadeOutLeftBig' },
          { name: 'fadeOutRight', className: 'fadeOutRight' },
          { name: 'fadeOutRightBig', className: 'fadeOutRightBig' },
          { name: 'fadeOutUp', className: 'fadeOutUp' },
          { name: 'fadeOutUpBig', className: 'fadeOutUpBig' },
        ],
      },
      {
        name: 'rotate',
        animations: [
          { name: 'rotateOutDownLeft', className: 'rotateOutDownLeft' },
          { name: 'rotateOutDownRight', className: 'rotateOutDownRight' },
          { name: 'rotateOutUpLeft', className: 'rotateOutUpLeft' },
          { name: 'rotateOutUpRight', className: 'rotateOutUpRight' },
        ],
      },
      {
        name: 'slide',
        animations: [
          { name: 'slideOutUp', className: 'slideOutUp' },
          { name: 'slideOutDown', className: 'slideOutDown' },
          { name: 'slideOutLeft', className: 'slideOutLeft' },
          { name: 'slideOutRight', className: 'slideOutRight' },
        ],
      },
      {
        name: 'zoom',
        animations: [
          { name: 'zoomOutUp', className: 'zoomOutUp' },
          { name: 'zoomOutDown', className: 'zoomOutDown' },
          { name: 'zoomOutLeft', className: 'zoomOutLeft' },
          { name: 'zoomOutRight', className: 'zoomOutRight' },
        ],
      },
      {
        name: 'specials',
        animations: [{ name: 'rollOut', className: 'rollOut' }],
      },
    ],
  },
};
