import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'icons/close.svg';

const Wrapper = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 11px;

    path {
      fill: var(--black);
    }
  }
`;

const CloseButton = ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <CloseIcon />
  </Wrapper>
);

export default CloseButton;

CloseButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};
