import { css } from 'styled-components';

export default css`
  position: relative;
  color: var(--text-color);

  &::before {
    content: '';
    position: absolute;
    background: var(--rgb-gradient);
    inset: -2px;
    z-index: 0;
    pointer-events: none;
    border-radius: 36px;
    padding: 2px;
    mask-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
    mask-clip: content-box, border-box;
    -webkit-mask-composite: clear;
    mask-composite: exclude;
  }
`;
