import styled from 'styled-components';

export default styled.button`
  position: relative;
  width: 25px;
  height: 25px;
  border: 2px solid gray;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 4;

  transition: var(--hover-transition);
  &:active {
    transform: scale(0.96);
  }
`;
