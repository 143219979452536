import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StreamElementsLogo, TipeeeLogo } from 'styles/Logas';
import Text from 'components/Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const SelectedPlatform = ({ provider, label, variant = 'normal', ...props }) => {
  let logo = null;

  switch (provider) {
    case 'stream_elements':
      logo = (
        <StreamElementsLogo style={variant === 'large' ? { width: 229, height: 45 } : undefined} />
      );
      break;
    case 'tipeee':
      logo = <TipeeeLogo style={variant === 'large' ? { width: 229, height: 49 } : undefined} />;
      break;
    default:
      throw Error('Wrong provider specified');
  }

  return (
    <Wrapper {...props}>
      <Text size={variant === 'large' ? 12 : 9} noMargin>
        {label || 'Wybrano platformę:'}
      </Text>
      {logo}
    </Wrapper>
  );
};

export default SelectedPlatform;

SelectedPlatform.propTypes = {
  provider: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
};

SelectedPlatform.defaultProps = {
  variant: 'normal',
  label: null,
};
