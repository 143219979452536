const toggleShowTrue = () => ({
  type: 'SHOW_GOALS_TRUE',
});

const toggleShowFalse = () => ({
  type: 'SHOW_GOALS_FALSE',
});

export const showGoalsToggle = () => (dispatch, getState) => {
  const { tiplist } = getState();
  if (tiplist.showGoals) {
    dispatch(toggleShowFalse());
  } else dispatch(toggleShowTrue());
};

const playAudioFalse = () => ({
  type: 'PLAY_AUDIO_FALSE',
});

const playAudioTrue = () => ({
  type: 'PLAY_AUDIO_TRUE',
});

export const playAudioSet = cookieState => dispatch => {
  if (cookieState) {
    dispatch(playAudioTrue());
  } else dispatch(playAudioFalse());
};
