import styled from 'styled-components';

export default styled.span`
  font-weight: 500;
  color: ${props =>
    props.isActive
      ? 'var(--text-color-text-editor-active)'
      : 'var(--text-color-text-editor-notactive)'};
  transition: color var(--hover-transition);
`;
