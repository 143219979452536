import styled, { keyframes } from 'styled-components';
import dropDownArrow from 'assets/img/dropdownarrow.png';
import OptionsPopup from 'styles/OptionsPopup';

const fadeIn = keyframes`
    100%  {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
`;

const fadeOut = keyframes`
    100%  {
      opacity: 0;
      visibility: hidden;
      transform: scaleY(0);
    }
`;

export default styled(OptionsPopup)`
  position: absolute;
  max-height: calc(100vh - 110px);
  ${props => (props.height ? `height: ${props.height};` : '')}
  top: 38px;
  right: -9px;
  z-index: 200;
  border: 1px solid var(--border-color);
  transform-origin: 100% 0;
  text-align: left;
  width: ${props => (props.width ? props.width : '424px')};
  padding: ${props => (props.padding ? props.padding : '35px')};
  animation-duration: 250ms;
  animation-fill-mode: forwards;

  opacity: 0;
  transform: scaleY(0);
  visibility: hidden;

  &.fade-enter {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }

  &.fade-enter-active,
  &.fade-enter-done {
    animation-name: ${fadeIn};
  }

  &.fade-exit {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }

  &.fade-exit-active,
  &.fade-exit-done {
    animation-name: ${fadeOut};
  }

  &:after{
    content: url('${dropDownArrow}');
    position: absolute;
    right: 19px;
    top: -16px;
  }
`;
