import styled from 'styled-components';

import Button from 'components/Button';

const ClearButton = styled(Button)`
  background-color: var(--brand-green);
  display: block;
  margin: 10px auto;
  padding: 10px 15px;
`;

export default ClearButton;
