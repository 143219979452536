import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TipsImage } from 'assets/images/keyboard_shortcuts.svg';

const Root = styled.div`
  color: var(--bg-text-editor-color);
  padding: 48px 0;

  p {
    color: inherit;
    margin: 0;
    text-align: center;
    font-family: var(--google-sora-cdn);
    font-size: 10px;
    font-weight: 800;
    line-height: normal;
  }

  strong {
    color: var(--grey);
  }
`;

export default () => (
  <Root>
    <TipsImage style={{ objectFit: 'contain', width: '100%', opacity: 0.5 }} />
  </Root>
);
