import styled from 'styled-components';

import Input from 'styles/Input';

export default styled(Input)`
  margin: 0 0 15px;
  font-size: 15px;
  padding: 15px;
  background-color: var(--bg-color-text-input);
`;
