import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Line from 'components/ElementEditor/editors/TextEditor/components/TextAlign/styles/Line';

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;
`;

const Icon = styled.div`
  width: var(--element-size);
  height: var(--element-size);
`;

const AlignListItem = ({ align, active }) => {
  const [label, setLabel] = useState('Do lewej');
  const [lineAlign, setLineAlign] = useState('left');

  useEffect(() => {
    switch (align) {
      case 'flex-end':
        setLabel('Do prawej');
        setLineAlign('right');
        break;
      case 'center':
        setLabel('Wyśrodkuj');
        setLineAlign('center');
        break;
      default:
        setLabel('Do lewej');
        setLineAlign('left');
    }
  }, [align]);

  return (
    <ItemContainer>
      <Icon>
        <Line black={active} />
        <Line black={active} />
        <Line black={active} short align={lineAlign} />
      </Icon>
      <div>{label}</div>
    </ItemContainer>
  );
};

AlignListItem.propTypes = {
  align: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default AlignListItem;
