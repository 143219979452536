import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Datetime from 'react-datetime';
import styled from 'styled-components';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

import dateFormatter from 'utils/dateFormatter';
import { useDelayedState } from 'utils/hooks';

import OptionsPopup from 'styles/OptionsPopup';

import FloatingMenu from 'components/FloatingMenu';
import { GeneralSettingsButton } from 'components/Buttons';

import DatePickerWrapper from '../../../components/DatePickerWrapper';

const ButtonLabelWrapper = styled.div`
  transform: translateY(2px);

  .date {
    font-size: 0.7em;
    margin-top: 3px;
  }
`;

const ButtonLabel = ({ value }) => {
  const formatValue = () => dateFormatter.format(new Date(value), 'DD MMMM YYYY, HH:mm');

  return (
    <ButtonLabelWrapper>
      Wybierz czas zakończenia LIVE
      <div className="date">
        {'Aktualnie: '}
        {formatValue()}
      </div>
    </ButtonLabelWrapper>
  );
};

ButtonLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  position: relative;
`;

const StartDatePicker = ({ value, onChange }) => {
  const wrapperRef = useRef();
  const [visible, setVisible] = useState(false);
  const [currentValue, setCurrentValue] = useDelayedState(value, 'date', (field, newValue) => {
    onChange(newValue);
  });

  const onDateChange = newDate => {
    const newDateMoment = moment(newDate);
    newDateMoment.set({ second: 0 });
    setCurrentValue(newDateMoment.format());
  };

  return (
    <Wrapper ref={wrapperRef}>
      <GeneralSettingsButton
        reverse
        withArrow
        color="blue"
        label={<ButtonLabel value={value} />}
        info={
          <>
            <p>
              {'Wybierz raz, '}
              <u>tylko przed</u>
              {' startem transmisji.'}
            </p>
            <p>
              {'Zmieniając tę wartość '}
              <u>usuniesz</u>
              {' dodany czas za wpłaty i suby z Twitcha pozyskane w trakcie LIVE!'}
            </p>
          </>
        }
        onClick={() => setVisible(true)}
        icon={<ClockIcon />}
      />

      <FloatingMenu
        visible={visible}
        position="right"
        onClose={() => setVisible(false)}
        parent={wrapperRef.current || document.body}
      >
        <OptionsPopup>
          <DatePickerWrapper>
            <Datetime
              open
              value={currentValue ? new Date(currentValue) : new Date()}
              onChange={onDateChange}
              timeFormat="HH:mm"
              input={false}
              locale="pl"
            />
          </DatePickerWrapper>
        </OptionsPopup>
      </FloatingMenu>
    </Wrapper>
  );
};

StartDatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function),
};

StartDatePicker.defaultProps = {
  onChange: () => null,
};

export default StartDatePicker;
