import { css } from 'styled-components';

export default css`
  position: relative;

  width: 100%;
  padding: 7.5px var(--item-x-margin);

  border: 0;
  background-color: transparent;
  color: var(--text-color-dark-gray);

  font-size: 13px;
  text-align: left;

  cursor: pointer;

  transition: 0.2s ease;

  > span {
    color: var(--text-color-medium-gray);
    font-size: 10px;
    text-transform: uppercase;
  }

  > span,
  &::before {
    transition: 0.2s ease;
  }

  &:hover {
    background-color: var(--bg-color-light-secondary-gray);
  }
`;
