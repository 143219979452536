import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as ExpandIcon } from 'assets/icons/expand.svg';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
import { ReactComponent as QuoteIcon } from 'assets/icons/quote.svg';
import {
  setDisplaySettingsDialogCurrentView,
  setDisplaySettingsDialogVisibility,
} from 'store/actions/configuratorsConfigs';
import NotificationsContext from '../../../../../NotificationsContext';

import ButtonContainer from '../styles/ButtonContainer';
import DisplaySettingButtons from '../styles/DisplaySettingButtons';
import SettingButton from '../styles/SettingButton';
import UsesSavedTemplateNotification, {
  UseSavedTemplateNotificationId,
} from './UsesSavedTemplateNotification';

const ButtonsToolbar = () => {
  const dispatch = useDispatch();
  const { currentNotification } = useContext(NotificationsContext);
  const [isSaveNotificationVisible, setIsSaveNotificationVisible] = useState(
    currentNotification === UseSavedTemplateNotificationId,
  );

  useEffect(() => {
    setIsSaveNotificationVisible(currentNotification === UseSavedTemplateNotificationId);
  }, [currentNotification]);

  const handleClick = view => {
    dispatch(setDisplaySettingsDialogCurrentView(view));
    dispatch(setDisplaySettingsDialogVisibility(true));
  };

  return (
    <DisplaySettingButtons>
      <ButtonContainer>
        <SettingButton onClick={() => handleClick('templates')} rgb={isSaveNotificationVisible}>
          <ExpandIcon />
          <span>
            <strong>Ustaw zależnie od kwoty:</strong>
            Szablon wiadomości
          </span>
        </SettingButton>

        {isSaveNotificationVisible && <UsesSavedTemplateNotification />}
      </ButtonContainer>

      <SettingButton onClick={() => handleClick('sounds')}>
        <MusicIcon />
        <span>
          <strong>Ustaw zależnie od kwoty:</strong>
          Dźwięk przy wiadomości
        </span>
      </SettingButton>

      <SettingButton onClick={() => handleClick('synth')}>
        <QuoteIcon />
        <span>
          <strong>Ustaw zależnie od kwoty:</strong>
          Czytanie wiadomości
        </span>
      </SettingButton>
    </DisplaySettingButtons>
  );
};

export default ButtonsToolbar;
