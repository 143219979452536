/**
 * Funkcja sprawdzająca czy powinno pobierać się dane z serwera,
 *
 * @param {object} state
 * @returns {boolean}
 */
const shouldFetchData = state => {
  const { isFetching, isFetched } = state;

  return !isFetched && !isFetching;
};

export default shouldFetchData;
