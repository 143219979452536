import styled from 'styled-components';

export default styled.div`
  position: absolute;
  border: 2px solid var(--border-color);
  transform: rotateZ(45deg);
  width: 20px;
  height: 20px;
  left: -9px;
  z-index: 100;
  background-color: var(--white);
  border-right: 0;
  border-top: 0;
  top: ${props => props.position}px;
`;
