import { contextMenu } from 'react-contexify';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { RoundedInfoWrapper } from 'components/RoundedInfoWrapper';
import BottomTip from '../../components/BottomTip';

import noEditableAlert from '../../lib/noEditableAlert';

import { ActionCatcher } from '../../components/ActionCatcher';
import AnimationManager from '../../components/AnimationManager';
import ChangeScreenBackground from '../../components/ChangeScreenBackground';
import ColumnBottomHelp from '../../components/ColumnBottomHelp';
import CustomCode from '../../components/CustomCode';
import { GradientPickerBox } from '../../components/GradientPickerBox';
import Panel from '../../components/Panel';
import RangePopup from '../../components/RangePopup';
import Screen from '../../components/Screen';
import ScreenContextMenu from '../../components/ScreenContextMenu';
import Switcher from '../../components/Switcher';
import SwitcherOrange from '../../components/SwitcherOrange';
import SendTestTip from '../../components/SendTestTip';
import TemplatesManager from '../../components/TemplatesManager';
import { TextInputBox } from '../../components/TextInputBox';
import { DisabledWidgetUrl } from '../../components/WidgetUrl';

import withConfiguration from '../../containers/withConfiguration';
import withHistory from '../../containers/withHistory';

import ConfigUnderScreen from '../../styles/ConfigUnderScreen';

import { GoalsUnderScreenButton } from './components/GoalsUnderScreenButton';
import { GoalsBlueButton } from './components/GoalsBlueButton';
import { GradientLabel } from './components/GradientLabel';
import Elements from './components/Elements';

import { Provider } from './Context';

const CONFIGURATOR_TYPE = 'TIPS_GOAL';

class TipsGoal extends Component {
  static propTypes = {
    activeTemplateId: PropTypes.string.isRequired,
    elements: PropTypes.instanceOf(Object).isRequired,
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    configuratorConfig: PropTypes.instanceOf(Object).isRequired,

    updateConfig: PropTypes.instanceOf(Function).isRequired,
    updateTemplate: PropTypes.instanceOf(Function).isRequired,
    configurationsAreFetched: PropTypes.bool.isRequired,

    getHiddenElements: PropTypes.instanceOf(Function).isRequired,
    showElement: PropTypes.instanceOf(Function).isRequired,

    pushHistory: PropTypes.instanceOf(Function).isRequired,
    createHistoryElement: PropTypes.instanceOf(Function).isRequired,
    getLatestHistoryElement: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    const { activeTemplateId, configuratorConfig } = props;

    this.state = {
      activeTemplateId,
      configuratorConfig,
      autoplayAnimation: false,
      isAnimated: false,
    };

    this.configuratorWrapper = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let returnedData = {
      configuratorConfig: props.configuratorConfig,
    };

    if (state.activeTemplateId !== props.activeTemplateId) {
      returnedData = {
        ...returnedData,
        activeTemplateId: props.activeTemplateId,
      };
    }

    return returnedData;
  }

  componentDidMount() {
    document.title = 'Tipply | Konfigurator (Twój cel)';
  }

  /**
   * Funkcja uruchamiająca menu kontekstowe dla całego widoku
   *
   * @param {Object} e
   * @returns {void}
   */
  handleShowScreenContextMenu = e => {
    e.preventDefault();
    contextMenu.show({
      id: 'screenContextMenu',
      event: e,
    });
  };

  handleAnimationState = value => {
    this.setState({
      isAnimated: value,
    });
  };

  resetElementPosition(elementName) {
    const { elements, pushHistory } = this.props;

    pushHistory({ [elementName]: { position: elements[elementName].defaults.position } });
  }

  render() {
    const { configuratorConfig, isAnimated, autoplayAnimation } = this.state;
    const {
      elements,
      activeTemplate,
      configurationsAreFetched,
      pushHistory,
      createHistoryElement,
      getLatestHistoryElement,
      updateConfig,
      updateTemplate,
      getHiddenElements,
      showElement,
    } = this.props;

    if (configurationsAreFetched) {
      const hiddenElements = getHiddenElements();
      const history = getLatestHistoryElement();
      const {
        editable,
        elementsOptions: {
          progressBar,
          amountPaid,
          goalNumbers: {
            changePosition,
            children: { to, separator },
          },
        },
      } = history;

      return (
        <Provider
          value={{
            handleAnimationState: this.handleAnimationState,
            isAnimated,
            autoplayAnimation,
          }}
        >
          <Panel>
            <GoalsBlueButton />

            <RangePopup
              text="Wielkość zaokrąglenia"
              activeTemplateId={activeTemplate.id}
              onAfterChange={value => {
                pushHistory({
                  progressBar: { styles: { borderRadius: value } },
                });
              }}
              isEditable={editable}
              value={progressBar.styles.borderRadius}
              minValue={0}
              maxValue={100}
              whiteColorMinValue={9}
              whiteColorMaxValue={93}
            />

            <Switcher
              text="Animacja w paski celu"
              name="toggle-stripes-animation"
              value={progressBar.stripes}
              onChange={checkboxValue => {
                if (editable) {
                  pushHistory({
                    progressBar: { stripes: checkboxValue },
                  });
                } else {
                  noEditableAlert();
                }
              }}
            />

            <GradientPickerBox
              isEditable={editable}
              label={<GradientLabel />}
              value={{
                color: progressBar.progressColor,
                color2: progressBar.gradientColor,
              }}
              onChange={(progressColor, gradientColor) => {
                if (progressColor !== gradientColor) {
                  pushHistory({
                    progressBar: { progressColor, gradientColor, stripes: false },
                  });
                } else {
                  pushHistory({
                    progressBar: { progressColor, gradientColor },
                  });
                }
              }}
            />

            <GradientPickerBox
              isEditable={editable}
              label={<GradientLabel bg />}
              value={{
                color: progressBar.styles.backgroundColor,
                color2: progressBar.styles.gradientColor,
              }}
              onChange={(backgroundColor, gradientColor) => {
                pushHistory({
                  progressBar: { styles: { backgroundColor, gradientColor } },
                });
              }}
            />

            <SwitcherOrange
              text="Odblokuj pozycje elementów"
              name="toggle-change-position"
              value={!!changePosition}
              tooltipContent="Odblokuj możliwość poruszania elementów po szablonie: kwoty zebranej, separatora, kwoty do zebrania oraz procentów. Odblokowując te cztery elementy będziesz miał możliwość poruszania nimi osobno, po całym konfiguratorze!"
              onChange={checkboxValue => {
                if (editable) {
                  pushHistory({
                    goalNumbers: { changePosition: checkboxValue },
                  });
                } else {
                  noEditableAlert();
                }
              }}
            />

            <TextInputBox
              label="Zmień treść Separatora"
              value={separator.textValue}
              disabled={!editable}
              maxLength={5}
              onChange={value => {
                pushHistory({
                  goalNumbers: { children: { separator: { textValue: value } } },
                });
              }}
            />

            <CustomCode template={activeTemplate} onChange={updateTemplate} />

            <SendTestTip />

            <ColumnBottomHelp>
              Kliknij PPM na dowolny element w konfiguratorze by edytować czcionkę czy kolor tekstu
            </ColumnBottomHelp>
          </Panel>
          <div ref={this.configuratorWrapper}>
            <Screen>
              <ActionCatcher
                activeTemplate={activeTemplate}
                onContextMenu={event => {
                  this.handleShowScreenContextMenu(event);
                }}
              />

              <div style={{ width: '100%', height: '100%' }}>
                <Elements
                  activeTemplate={activeTemplate}
                  configuratorConfig={configuratorConfig}
                  configuratorWrapper={this.configuratorWrapper}
                  pushHistory={historyObject => pushHistory(historyObject)}
                  createHistoryElement={() => createHistoryElement()}
                  getLatestHistoryElement={() => getLatestHistoryElement()}
                  updateConfig={updateConfig}
                />
              </div>

              <ScreenContextMenu
                elements={elements}
                hiddenElements={hiddenElements}
                onResetElementPosition={elementName => this.resetElementPosition(elementName)}
                onShowElement={showElement}
                updateScreen={config => this.updateScreen(config)}
              />
            </Screen>
            <ConfigUnderScreen position="start" style={{ justifyContent: 'unset' }}>
              <AnimationManager
                isAnimated={isAnimated}
                onClickControl={type => this.handleAnimationState(type)}
                onChangeAutoplay={autoplayValue => {
                  this.setState({
                    autoplayAnimation: autoplayValue,
                  });
                }}
                autoplayValue={autoplayAnimation}
                autoplayPanel
              />
              <DisabledWidgetUrl message="Skopiowanie URL nie jest już możliwe w tym miejscu. Przejdź do ustawień celu, by skopiować URL." />
              <GoalsUnderScreenButton />
            </ConfigUnderScreen>
            <BottomTip />
          </div>
          <Panel>
            <RoundedInfoWrapper info="Kliknij poniżej, by wybrać lub stworzyć nowy wygląd celu">
              <TemplatesManager type={CONFIGURATOR_TYPE} />
            </RoundedInfoWrapper>

            <ChangeScreenBackground />

            <Switcher
              text="Animacja dodania kwoty"
              name="add-tip-animation"
              value={amountPaid.isVisible}
              onChange={checkboxValue => {
                if (editable) {
                  pushHistory({
                    amountPaid: { isVisible: checkboxValue },
                  });
                } else {
                  noEditableAlert();
                }
              }}
            />

            <Switcher
              text="Prezentuj kwotę do zebrania"
              name="toggle-target-amount"
              value={to.isVisible}
              onChange={checkboxValue => {
                if (editable) {
                  pushHistory({
                    goalNumbers: { children: { to: { isVisible: checkboxValue } } },
                  });
                } else {
                  noEditableAlert();
                }
              }}
            />
          </Panel>
        </Provider>
      );
    }

    return null;
  }
}

export default withConfiguration(withHistory(TipsGoal), CONFIGURATOR_TYPE);
