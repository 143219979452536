import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-2.svg';
import Button from 'components/Button';

const Wrapper = styled.div`
  margin: 0 0 70px 0;
  font-family: var(--google-sora-cdn);
  font-size: 15px;
  text-align: center;

  p {
    margin: 0 0 25px;
    line-height: 19px;
  }

  .under-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--gray);
    margin-bottom: 60px;
  }

  .red-text {
    color: var(--red);
    font-weight: 700;
  }

  .button-container {
    margin: 35px auto;

    button {
      font-family: var(--google-sora-cdn);
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #16814e;
      padding-right: 50px;
      padding-left: 50px;
    }
  }

  .small-text {
    font-size: 12px;
    color: var(--gray);
    margin-top: 35px;
  }
`;

const UnverifiedUserMessage = () => (
  <Wrapper>
    <p className="under-title">Czegoś nam chyba brakuje</p>
    <p className="red-text">Na Twoim koncie nie wprowadzono jeszcze żadnych danych osobowych!</p>
    <div className="button-container">
      <Button
        background="green"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_PERSONALDATA_URL}`;
        }}
        iconSuffix={<ArrowIcon />}
      >
        Uzupełnij formularz
      </Button>
    </div>
    <p>
      Bez danych osobowych nie będziemy w stanie rozpocząć przyjmowania wiadomości dla Twojego
      konta. Wprowadź dane klikając powyższy przycisk by rozpocząć współpracę!
    </p>
    <p>Po wpisaniu danych w formularzu, będziesz mógł wracać tutaj, by je edytować</p>
    <p className="small-text">
      Po przesłaniu danych w ciągu 24 godzin zostaną one zweryfikowane, a możliwość przyjmowania
      wiadomości zostanie odblokowana.
    </p>
  </Wrapper>
);

export default UnverifiedUserMessage;
