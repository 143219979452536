import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-2.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/complete-icon.svg';
import { ReactComponent as SpinnerIcon } from 'assets/icons/spinner.svg';

import { toggleVerifyWithBankWizard } from 'store/actions/userData';
import { fetchInitStatisticsIfNeeded } from 'store/actions/statistics';
import { bankTransferValidationDisabledSelector } from 'store/selector';

const Wrapper = styled.div`
  background-color: var(--white-white);
  display: flex;
  column-gap: 20px;
  align-items: center;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px;
  color: black;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  .arrow {
    width: 18px;
  }
`;

const CircleCheckmark = styled.div`
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--brand-green);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    opacity: 0.5;
  }
`;

const CircleSpinner = styled(CircleCheckmark)`
  background-color: rgba(0, 0, 0, 0.5);

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  svg {
    opacity: 0.8;
    animation: spin 1.5s linear infinite;
  }
`;

const Label = styled.div`
  font-family: var(--google-sora-cdn);
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  flex: 1;

  .subtitle {
    font-size: 10px;
    opacity: 0.5;
    margin-bottom: 3px;
  }
`;

const Badge = styled.div`
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  margin: -14px 0 28px;
  color: var(--brand-green);
`;

const DisableMessage = styled.div`
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  line-height: 1.5;
  color: var(--error-color);
  margin-bottom: 24px;
  text-align: center;
`;

const BankTransferValidationStatus = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(({ userData }) => userData);
  const {
    has_pending_banktransfer_validation_request: pending,
    validated_with_bank_transfer: validated,
  } = info;

  const isDisabled = useSelector(bankTransferValidationDisabledSelector);

  useEffect(() => {
    dispatch(fetchInitStatisticsIfNeeded());
  }, []);

  const handleClick = () => {
    if (!isDisabled) dispatch(toggleVerifyWithBankWizard(true));
  };

  if (pending) {
    return (
      <Wrapper>
        <CircleSpinner>
          <SpinnerIcon />
        </CircleSpinner>
        <Label>
          <div className="subtitle">Może to potrwać 7 dni</div>
          Sprawdzamy twój wniosek
        </Label>
      </Wrapper>
    );
  }

  if (validated) {
    return <Badge>Posiadasz aktywną odznakę weryfikacji</Badge>;
  }

  return (
    <>
      <Wrapper
        onClick={handleClick}
        disabled={isDisabled}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      >
        <CircleCheckmark>
          <CheckIcon />
        </CircleCheckmark>
        <Label>
          <div className="subtitle">Nowość</div>
          <div>Zdobądź odznakę weryfikacji</div>
        </Label>
        <div className="arrow">
          <ArrowRightIcon />
        </div>
      </Wrapper>
      {isDisabled && (
        <DisableMessage>
          Aktualnie nie spełniasz kryteriów by móc mieć odznakę weryfikacji. Spróbuj ponownie
          posiadając więcej wiadomości w profilu
        </DisableMessage>
      )}
    </>
  );
};

export default BankTransferValidationStatus;
