import styled from 'styled-components';

export default styled.span`
  display: block;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  height: 0;
`;
