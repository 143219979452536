import React from 'react';
import styled, { css } from 'styled-components';

import Tooltip from 'components/SmallTooltip';

const variantText = css`
  background-color: transparent;
  color: var(${({ $color }) => css`--${$color || 'primary-contrast'}`}, inherit);
  border: none;
`;

const variantOutlined = css`
  background-color: transparent;
  color: ${({ $color, $customColor }) =>
    $customColor || css`var(--${$color || 'primary-main'}, inherit)`};
  border-width: 1px;
  border-style: solid;
  border-color: currentColor;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.15;
    background-color: var(${({ $color }) => css`--${$color || 'primary-main'}`}, #606060);
    z-index: -1;
  }

  svg,
  path {
    fill: currentColor;
  }
`;

function bgColorVar(colorName) {
  switch (colorName) {
    case 'yellow':
    case 'white':
      return 'black';
    case 'blue':
    case 'black':
    default:
      return 'white';
  }
}

const variantContained = css`
  background-color: var(${({ $color }) => css`--${$color || 'primary-main'}`}, #adadad);
  color: var(${({ $color }) => css`--${bgColorVar($color) || 'primary-contrast'}`}, black);
  border: none;
`;

const sizeSmall = css`
  height: 27px;
  border-radius: 14px;

  svg {
    height: 14px;
  }
`;

const sizeMedium = css`
  height: 32px;
  border-radius: 16px;
  padding-left: 24px;
  padding-right: 24px;

  svg {
    height: 14px;
  }
`;

const sizeLarge = css`
  height: 36px;
  border-radius: 18px;
  padding-left: 16px;
  padding-right: 16px;

  svg {
    height: 14px;
  }
`;

const baseStyle = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  ${({ $variant }) => {
    switch ($variant) {
      case 'outlined':
        return variantOutlined;
      case 'contained':
        return variantContained;
      default:
        return variantText;
    }
  }}
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return sizeSmall;
      case 'large':
        return sizeLarge;
      default:
        return sizeMedium;
    }
  }}
  + ${Tooltip} {
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, 0);
    transition: all .25s ease-in-out;
  }

  &:disabled {
    opacity: 40%;

    + ${Tooltip} {
      background-color: #FF6767;
      color: #7C3434;

      &::after {
        border-color: #FF6767 transparent transparent transparent;
      }
    }
  }

  &:hover {
    + ${Tooltip} {
      opacity: 1;
      transform: translate(-50%, -20px);
      transition: all .25s ease-in-out 1s;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
  z-index: 1;
`;

const IconButtonRoot = styled.button`
  ${baseStyle};

  padding: 0;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  transition: var(--hover-transition);

  &:active {
    transform: scale(0.96);
  }
`;

const ButtonRoot = styled.button`
  ${baseStyle};

  font-family: var(--google-sora-cdn);
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;

  display: inline-flex;
  column-gap: 8px;
  transition: var(--hover-transition);

  &:active {
    transform: scale(0.96);
  }
`;

const IconButton = ({ children, tooltip, tooltipDisabled, ...props }) => {
  const tooltipText = props.disabled ? tooltipDisabled : tooltip;

  const button = <IconButtonRoot {...props}>{children}</IconButtonRoot>;

  return (
    (!!tooltipText && (
      <ButtonWrapper>
        {button}
        <Tooltip>{tooltipText}</Tooltip>
      </ButtonWrapper>
    )) || <>{button}</>
  );
};

const Button = ({ children, tooltip, tooltipDisabled, ...props }) => {
  const tooltipText = props.disabled ? tooltipDisabled : tooltip;

  const button = <ButtonRoot {...props}>{children}</ButtonRoot>;

  return (
    (!!tooltipText && (
      <ButtonWrapper>
        {button}
        <Tooltip>{tooltipText}</Tooltip>
      </ButtonWrapper>
    )) || <>{button}</>
  );
};

export default IconButton;

export { Button };
