import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TickIcon } from 'assets/icons/checkbox-tick.svg';

import Context from '../Context';

import Item from '../styles/Item';

const Wrapper = styled.div`
  position: relative;
`;

const Buttons = styled.div`
  --button-margin: 4px;

  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  margin: 0 calc(var(--button-margin) * -1);

  > button {
    display: block;
    border: 0;
    padding: 0;
    margin: 0 var(--button-margin);
    background: none;

    > svg {
      display: block;
      fill: var(--text-color);

      &.tick {
        fill: none;
        stroke: var(--text-color);
        stroke-width: 2px;
      }
    }
  }
`;

const ListItem = ({ id, title, controls }) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(title);

  return (
    <Context.Consumer>
      {({ activeId, dispatchChangeActiveTemplate, deleteTemplate, updateTitle }) => (
        <Wrapper>
          <Item
            edited={id === activeId}
            onClick={() => {
              if (!editable) {
                dispatchChangeActiveTemplate(id);
              }
            }}
          >
            {editable ? (
              <input value={value} onChange={e => setValue(e.target.value)} type="text" />
            ) : (
              title
            )}
          </Item>

          {controls && (
            <Buttons>
              <button type="button" onClick={() => setEditable(!editable)}>
                {editable ? (
                  <TickIcon
                    className="tick"
                    width="14px"
                    onClick={() => {
                      updateTitle(id, value);
                      setEditable(false);
                    }}
                  />
                ) : (
                  <EditIcon width="14px" />
                )}
              </button>
              <button type="button" onClick={() => deleteTemplate(id)}>
                <CloseIcon width="12px" />
              </button>
            </Buttons>
          )}
        </Wrapper>
      )}
    </Context.Consumer>
  );
};

ListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  controls: PropTypes.bool,
};

ListItem.defaultProps = {
  controls: false,
};

export default ListItem;
