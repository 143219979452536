import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ThresholdContext from './ThresholdContext';

import AlertTemplateThreshold from './AlertTemplateThreshold';
import AudioThreshold from './AudioThreshold';
import TextToSpeechThreshold from './TextToSpeechThreshold';

const Threshold = ({
  view,
  views,
  onChange,
  isDefault,
  config,
  templates,
  media,
  onDelete,
  onTriggerShowMediaManager,
  onOpenSynthConf,
  openSynthConf,
  ...otherProps
}) => {
  const handleChange = (fieldName, newValue) => {
    onChange({ [fieldName]: newValue });
  };

  const isUpdating = useSelector(state => state.configuratorsConfigs.isUpdating);

  const contextValues = {
    view,
    views,
    onChange,
    isDefault,
    config,
    templates,
    media,
    onDelete,
    onTriggerShowMediaManager,
    onOpenSynthConf,
    openSynthConf,
    ...otherProps,
    isUpdating,
    handleChange,
  };

  return (
    <ThresholdContext.Provider value={contextValues}>
      {view === views.TEMPLATES && <AlertTemplateThreshold />}
      {view === views.SOUNDS && <AudioThreshold />}
      {view === views.SYNTH && <TextToSpeechThreshold />}
    </ThresholdContext.Provider>
  );
};

Threshold.propTypes = {
  isDefault: PropTypes.bool,
  config: PropTypes.instanceOf(Object).isRequired,
  templates: PropTypes.instanceOf(Array),
  media: PropTypes.instanceOf(Array),
  view: PropTypes.string.isRequired,
  views: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  onDelete: PropTypes.instanceOf(Function),
  onTriggerShowMediaManager: PropTypes.instanceOf(Function),
  onOpenSynthConf: PropTypes.instanceOf(Function),
  openSynthConf: PropTypes.bool,
};

Threshold.defaultProps = {
  isDefault: false,
  openSynthConf: false,
  templates: [],
  media: [],
  onTriggerShowMediaManager: () => {},
  onDelete: () => {},
  onOpenSynthConf: () => {},
};

export default Threshold;
