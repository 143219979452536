function removeImages(text) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const images = doc.querySelectorAll('img');
  images.forEach(img => {
    img.parentNode.removeChild(img);
  });

  return doc.documentElement.querySelector('body').innerHTML;
}

export default function firstUrl(message) {
  const parsedMessage = removeImages(message);
  const urlRegex =
    'http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+';
  return parsedMessage.match(urlRegex);
}
