import { useSelector } from 'react-redux';

export const useVotingStore = () => useSelector(store => store.goalsVoting);

export const useAllTemplates = configType =>
  useSelector(store => {
    const { defaults, created } = store.templates;
    const filteredCreated = created.filter(item => item.type === configType);
    const filteredDefaults = defaults.filter(item => item.type === configType);

    return [...filteredDefaults, ...filteredCreated];
  });

export const useActiveTemplate = configType => {
  const allTemplates = useAllTemplates(configType);

  return useSelector(store => {
    const { active } = store.templates;
    const activeId = active[configType];

    return { ...allTemplates.find(item => item.id === activeId) };
  });
};
