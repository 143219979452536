import styled from 'styled-components';

import BlankButton from 'styles/BlankButton';

export default styled(BlankButton)`
  position: relative;
  width: var(--element-size);
  height: var(--element-size);

  z-index: 1;
  margin: ${props => (props.margin ? '0 var(--element-margin)' : '0')};

  > span {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
