import styled, { css } from 'styled-components';

const active = css`
  background-color: var(--light-gray);
  border-color: var(--light-gray);

  > svg {
    fill: #fff;
  }
`;

export default styled.li`
  display: inline-block;
  width: 30px;
  height: 30px;

  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin: 0 var(--item-margin);
  padding: 1px 0px;
  text-align: center;
  cursor: pointer;

  transition: 0.3s ease;

  > svg {
    width: 16px;
    fill: var(--text-color-medium-gray);
    transition: 0.3s ease;
  }

  ${props => props.active && active};
`;
