const ALLOWED_TYPES = {
  image: ['image/png', 'image/x-png', 'image/jpeg', 'image/pjpeg', 'image/gif'],
  video: ['video/webm'],
  audio: ['audio/mpeg', 'audio/mp3'],
  font: ['font/ttf', '.ttf'],
};

export { ALLOWED_TYPES };
export default {
  allowedMediumTypes: [
    ...ALLOWED_TYPES.image,
    ...ALLOWED_TYPES.video,
    ...ALLOWED_TYPES.audio,
    ...ALLOWED_TYPES.font,
  ],
  allowedExtensions: ['webm', 'mp3', 'png', 'jpg', 'jpeg', 'gif', 'ttf'], // wartości są tylko listowane pod uploadem zdjęć do informacji użytkownika
  allowedSingleItemWeight: 55000000, // w bajtach (50 MB)
  allowedItemsWeight: 250000000, // w bajtach (250 MB),
};
