import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Scrollbars } from 'react-custom-scrollbars';
import { CancelToken } from 'axios';

import http from 'utils/http';
import getMimeType from 'utils/getMimeType';
import bytesToSize from 'utils/bytesToSize';
import serverAlert from 'store/utils/serverAlert';
import Loading from 'components/Loading';

import store from 'store';
import CONFIG from '../../config';

import StyledUploadMedium from './styles/StyledUploadMedium';
import Medium from './components/Medium';
import Button from '../../../../styles/Button';

export default class UploadMedia extends Component {
  static propTypes = {
    hideUploadMediumComponent: PropTypes.instanceOf(Function).isRequired,
    insertMediumInStore: PropTypes.instanceOf(Function).isRequired,
    deleteMedia: PropTypes.instanceOf(Function).isRequired,
  };

  static getMediumId(medium) {
    return `${medium.name}${medium.lastModified}${Math.random()}`;
  }

  state = {
    mediaList: [],
    uploadedCount: 0,
    deleteLoading: false,
    cancelLoading: false,
  };

  /**
   * Funkcja zwrotna która odpala się po wrzuceniu poprawnych plików do Dropzone.
   * Wykonywane jest zapytanie AJAX z wysyłką plików na serwer.
   *
   * @param {Array} media - tablica z poprawnie zwalidowanymi plikami
   * @returns {void}
   */
  handleDropAccepted = media => {
    const { insertMediumInStore } = this.props;
    const preparedMedia = [];

    media.map(medium => {
      const cancelToken = CancelToken.source();

      const temporaryId = UploadMedia.getMediumId(medium);
      const updatedMedium = {
        medium,
        id: null,
        temporaryId,
        cancelToken,
        dropStatus: 'accepted',
        uploadProgress: 0,
      };

      preparedMedia.push(updatedMedium);

      const formData = new FormData();
      formData.append('binaryContent', medium);

      const format = getMimeType(medium.type) || 'font';

      this.setState(prevState => ({
        uploadedCount: prevState.uploadedCount + 1,
      }));

      return http
        .post(`/medium/${format}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: ({ loaded, total }) => {
            const progress = (loaded * 100) / total;

            this.updateMediumConfig(temporaryId, 'progress', progress);
          },
          cancelToken: cancelToken.token,
        })
        .then(response => {
          const responseData = { ...response.data };
          const { mediaManager } = store.getState();

          if (mediaManager.usage + responseData.size > CONFIG.allowedItemsWeight) {
            this.updateMediumConfig(temporaryId, 'dropStatus', 'rejected');
            this.deleteMedia([responseData.id]).then();
          } else {
            insertMediumInStore(responseData);
            this.updateMediumConfig(temporaryId, 'id', responseData.id);
          }

          this.setState(prevState => ({
            uploadedCount: prevState.uploadedCount - 1,
          }));
        })
        .catch(error => {
          this.updateMediumConfig(temporaryId, 'dropStatus', 'rejected');

          this.setState(prevState => ({
            uploadedCount: prevState.uploadedCount - 1,
          }));
        });
    });

    this.addMediaToList(preparedMedia);
  };

  /**
   * Funkcja zwrotna która odpala się po wrzuceniu niepoprawnych plików do Dropzone.
   * Plki są dodawane na listę plików z statusem rejected.
   *
   * @param {Array} media - tablica z niepoprawnie zwalidowanymi plikami
   * @returns {void}
   */
  handleDropRejected = media => {
    const updatedMedia = media.map(medium => ({
      medium,
      id: null,
      temporaryId: UploadMedia.getMediumId(medium),
      dropStatus: 'rejected',
    }));

    this.addMediaToList(updatedMedia);
  };

  /**
   * Funkcja anulująca przesyłanie wybranych plików.
   * Jeżeli nie zostanie przekazany ID pliku, anulowane są wszystkie
   *
   * @param {(Number|String)} mediumId
   * @returns {void}
   */
  cancelUpload = (mediumId = null) => {
    const { mediaList } = this.state;
    const mediaToRemove = [];

    if (mediumId) {
      const filteredMedium = mediaList.find(medium => medium.temporaryId === mediumId);
      filteredMedium.cancelToken.cancel();

      mediaToRemove.push(mediumId);
    } else {
      mediaList.forEach(medium => medium.cancelToken.cancel());
    }
  };

  /**
   * Funkcja anulująca wszystkie transfery, usuwająca wgrane pliki oraz wychodząca z widoku.
   *
   * @returns {void}
   */
  cancelAndLeave = async () => {
    const { mediaList } = this.state;
    const { hideUploadMediumComponent } = this.props;

    const mediaIds = mediaList
      .map(medium => {
        if (!medium.id) {
          this.cancelUpload(medium.temporaryId);
        }

        return medium.id;
      })
      .filter(medium => medium);

    if (mediaIds.length > 0) {
      this.setState({ cancelLoading: true });

      try {
        await this.deleteMedia(mediaIds);
        hideUploadMediumComponent();
      } catch (error) {
        serverAlert('Wystąpił bład podczas usuwania mediów.');
      }
    } else {
      hideUploadMediumComponent();
    }

    this.setState({ cancelLoading: false });
  };

  /**
   * Funkcja usuwająca medium z listy
   *
   * @returns {Promise}
   */
  deleteMedia = async mediaIds => {
    const { deleteMedia } = this.props;

    this.setState({ deleteLoading: true });

    try {
      await deleteMedia(mediaIds);
      this.removeMediaFromList(mediaIds);
    } catch (error) {
      serverAlert('Wystąpił bład podczas usuwania mediów.');
    }
  };

  /**
   * Funkcja usuwająca pliki z listy wysyłanych oraz anuluje przesyłanie jeżeli takie isteieje.
   *
   * @param {Array} mediaId
   * @returns {void}
   */
  removeMediaFromList(mediaIds) {
    this.setState(({ mediaList }) => {
      const media =
        mediaIds.length > 0
          ? mediaList.filter(medium =>
              medium.id ? !mediaIds.includes(medium.id) : !mediaIds.includes(medium.temporaryId),
            )
          : mediaIds;

      return {
        mediaList: media,
        deleteLoading: false,
      };
    });
  }

  /**
   * Funkcja aktualizująca obiekt konfiguracyjny z plikiem
   *
   * @param {(String|Number)} mediumId
   * @param {(String|Number)} key
   * @param {any} value
   * @returns {void}
   */
  updateMediumConfig(mediumId, key, value) {
    this.setState(({ mediaList }) => {
      const updatedMedia = mediaList.map(medium => {
        if (medium.temporaryId === mediumId) {
          return {
            ...medium,
            [key]: value,
          };
        }

        return medium;
      });

      return {
        mediaList: updatedMedia,
      };
    });
  }

  /**
   * Funkcja dodająca pliki przekazane do Dropzone na listę plików
   *
   * @param {Array} media
   * @returns {void}
   */
  addMediaToList(media) {
    this.setState(prevState => ({
      mediaList: [...prevState.mediaList, ...media],
    }));
  }

  render() {
    const { mediaList, uploadedCount, cancelLoading, deleteLoading } = this.state;
    const { hideUploadMediumComponent } = this.props;

    return (
      <StyledUploadMedium>
        <Dropzone
          multiple
          accept={CONFIG.allowedMediumTypes}
          maxSize={CONFIG.allowedSingleItemWeight}
          onDropRejected={this.handleDropRejected}
          onDropAccepted={this.handleDropAccepted}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={isDragActive ? 'dropzone dropzone--isActive' : 'dropzone'}
            >
              <div className="dropzone__insider">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="dropzone__text">Puszczaj !!!</p>
                ) : (
                  <p className="dropzone__text">
                    Przytrzymaj i upuść pliki tu,
                    <br />
                    albo kliknij tutaj:
                    <br />
                    <span className="dropzone__chose_media">Wybierz Media</span>
                    <br />
                    <span className="dropzone__small">
                      {`Maksymalna waga pliku to ${bytesToSize(CONFIG.allowedSingleItemWeight)}.`}
                    </span>
                  </p>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone__media_wrap">
          {deleteLoading && (
            <Loading
              style={{ zIndex: 2, borderRadius: 20, backgroundColor: 'rgba(246, 246, 246, 0.85)' }}
            />
          )}
          <Scrollbars>
            {mediaList.map(medium => (
              <Medium
                config={medium}
                name={medium.medium.name}
                key={medium.temporaryId}
                id={medium.temporaryId}
                size={medium.medium.size}
                onDelete={() => {
                  if (medium.id) {
                    this.deleteMedia([medium.id]);
                  } else {
                    this.cancelUpload(medium.temporaryId);
                    this.removeMediaFromList([medium.temporaryId]);
                  }
                }}
                rejected={medium.dropStatus === 'rejected'}
              />
            ))}
          </Scrollbars>
        </div>
        <p className="dropzone__allowed_extensions">
          {`Dozwolone formaty: ${CONFIG.allowedExtensions.join(', ')}`}
        </p>
        <div className="dropzone__upload_buttons_wrap">
          <Button disabled={cancelLoading} loading={cancelLoading} onClick={this.cancelAndLeave}>
            Anuluj wszystkie
          </Button>
          <Button
            onClick={() => {
              if (uploadedCount <= 0) {
                hideUploadMediumComponent();
              }
            }}
            clicked
            disabled={uploadedCount > 0}
          >
            Zapisz i wyjdź
          </Button>
        </div>
      </StyledUploadMedium>
    );
  }
}
