/* eslint-disable no-unused-vars */
import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestStatistics = () => ({
  type: 'REQUEST_STATISTICS',
});

const updateStatistics = updatedData => ({
  type: 'UPDATE_STATISTICS',
  updatedData,
});

const receiveStatistics = recievedData => ({
  type: 'RECEIVE_STATISTICS',
  recievedData,
});

const failureStatistics = () => ({
  type: 'FAILURE_STATISTICS',
});

export const updateTipsData = tip => (dispatch, getState) => {
  const {
    statistics: {
      data: { tips },
    },
  } = getState();

  const newValues = {
    tips: {
      count: tips.count + 1,
      messages_length: tips.messages_length + tip.message.length,
    },
  };

  dispatch(updateStatistics(newValues));
};

export const increaseIncome = value => (dispatch, getState) => {
  const {
    statistics: {
      data: { income },
    },
  } = getState();

  const newValues = {
    income: {
      last7: income.last7 + value,
      last28: income.last28 + value,
      current_month: income.current_month + value,
      total: income.total + value,
    },
  };

  dispatch(updateStatistics(newValues));
};

export const fetchPoints = () => async dispatch => {
  dispatch(requestStatistics());

  try {
    const points = await http.get('/user/points');

    dispatch(updateStatistics({ points: points.data }));

    return Promise.resolve();
  } catch (error) {
    dispatch(failureStatistics());
    return Promise.reject();
  }
};

export const fetchInitStatistics = () => async dispatch => {
  dispatch(requestStatistics());

  try {
    const [points, tips, income] = await Promise.all([
      http.get('/user/points'),
      http.get('/user/statistics/tips'),
      http.get('/user/statistics/income'),
    ]);

    dispatch(receiveStatistics({ points: points.data, tips: tips.data, income: income.data }));

    return Promise.resolve();
  } catch (error) {
    dispatch(failureStatistics());
    return Promise.reject();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchInitStatisticsIfNeeded = () => (dispatch, getState) => {
  const { statistics } = getState();

  if (shouldFetchData(statistics)) {
    return dispatch(fetchInitStatistics());
  }

  return Promise.resolve();
};
