import styled from 'styled-components';

export default styled.button`
  cursor: pointer;

  border: 0;
  padding: 0;

  line-height: 1;

  background: none;
  outline: none;
`;
