import styled from 'styled-components';

import SecondaryCapsule from '../../styles/SecondaryCapsule';

export default styled(SecondaryCapsule)`
  width: 200px;
  border-radius: 5px;
  padding: 5px;
  box-sizing: content-box;
  background-color: var(--white);

  > div {
    margin: 10px 10px 10px 10px;
  }
`;
