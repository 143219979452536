import React from 'react';
import styled from 'styled-components';

import paySafeCardLogo from 'assets/images/paysafecard-logo.png';
import streamElementsLogo from 'assets/images/streamelements-logo.png';
import tipeeeLogo from 'assets/images/tipeee-logo.svg';

const BaseLogoElement = styled.img`
  display: block;
`;

const PaySafeCardLogoElement = styled(BaseLogoElement)`
  width: 195px;
  height: 33px;
`;

export const PaySafeCardLogo = props => <PaySafeCardLogoElement src={paySafeCardLogo} {...props} />;

const StreamElementsLogoElement = styled(BaseLogoElement)`
  width: 163px;
  height: 32px;
`;

export const StreamElementsLogo = props => (
  <StreamElementsLogoElement src={streamElementsLogo} {...props} />
);

const TipeeeLogoElement = styled(BaseLogoElement)`
  width: 117px;
  height: 25px;
`;

export const TipeeeLogo = props => <TipeeeLogoElement src={tipeeeLogo} {...props} />;
