import { useEffect, useState } from 'react';

export const useDelayedState = (initialState, fieldName, onChangeCallback) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (state !== initialState) {
        onChangeCallback(fieldName, state);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return [state, setState];
};
