import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectUserData } from 'store/selector';
import { useCopyToClipboard } from 'utils/hooks';

import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';

import { StyledButton } from '../../../components/ElementActionButton';
import ConfigContext from '../../../ConfigContext';

const ButtonRoot = styled(StyledButton)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  width: 100%;
  background-color: var(--text-color-white);
  border: 1px solid #606060;
  column-gap: 12px;
  color: var(--text-color);

  &::before {
    inset: -1px;
    padding: 1px;
  }

  svg {
    width: 24px;
    max-width: 24px;
    height: auto;
    max-height: unset;
    margin-bottom: -1px;
  }
`;

const CopyLink = () => {
  const [copied, copyLink] = useCopyToClipboard();
  const { configuratorType } = useContext(ConfigContext);
  const userData = useSelector(selectUserData);
  const startUrl = `${process.env.REACT_APP_WIDGETS_URL}/${configuratorType}/${userData.info.id}`;

  const handleClick = () => {
    copyLink(startUrl);
  };

  return (
    <ButtonRoot onClick={handleClick} rgb>
      {!copied && (
        <>
          <LinkIcon />
          Skopiuj URL i dodaj widget do OBS
        </>
      )}
      {!!copied && 'Skopiowano!'}
    </ButtonRoot>
  );
};

export default CopyLink;
