import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

const Title = ({ children, ...props }) => (
  <Text {...props} color="blue" weight="medium" margin="0 0 15px" size={15}>
    {children}
  </Text>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
