/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import { apiUrl } from 'utils/http';
import serverAlert from 'store/utils/serverAlert';

import Grid from 'components/Grid';
import Text from 'components/Text';
import MediaManager from 'components/MediaManager';

import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-arrow.svg';
import { ReactComponent as ExternalIcon } from 'assets/icons/external-link.svg';

import Button from './components/Button';
import Buttons from './components/Buttons';
import Wrapper from './components/Wrapper';

import { Consumer } from '../../Context';

const ImagePanel = ({ mediumType, onSelectedItem, onDeleteItem, ...props }) => {
  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  return (
    <Consumer>
      {({ userDataProfile }) => {
        let mediumData = userDataProfile[mediumType];
        let isUploaded = true;

        if (!mediumData) {
          mediumData = {};
          isUploaded = false;
        }

        let imageUrl = null;
        if (mediumType === 'avatar') {
          imageUrl = mediumData.google_avatar_url || null;
        }

        if (mediumData && mediumData.formats) {
          imageUrl = `${apiUrl}${mediumData.formats[`user_${mediumType}`].url}`;
        }

        return (
          <>
            <Wrapper {...props}>
              <Grid columns="1fr 1fr 1fr" style={{ alignItems: 'center' }}>
                <div>
                  <Text size={17} weight="bold" color="blue" margin="0 0 2.5px">
                    {mediumData.created_at
                      ? fecha.format(new Date(mediumData.created_at), 'DD/MM/YYYY')
                      : '-'}
                  </Text>
                  <Text size={13} weight="bold" color="light-gray" noMargin>
                    DATA PRZESŁANIA
                  </Text>
                </div>
                <div>
                  <Text size={17} weight="bold" margin="0 0 2.5px">
                    {mediumData.name ? mediumData.name : '-'}
                  </Text>
                  <Text size={13} weight="bold" color="light-gray" noMargin>
                    NAZWA PLIKU
                  </Text>
                </div>
                <Buttons style={{ justifySelf: 'end' }}>
                  <Button
                    background="gray"
                    noWrapper
                    type="button"
                    onClick={() => setMediaManagerVisibility(true)}
                    loading={addLoading}
                    disabled={addLoading}
                  >
                    <EditIcon
                      style={{ opacity: addLoading ? 0 : 1 }}
                      width="20"
                      fill="var(--text-color-dark-gray)"
                    />
                  </Button>
                  <Button
                    background="gray"
                    noWrapper
                    type="button"
                    loading={deleteLoading}
                    disabled={!isUploaded || deleteLoading}
                    onClick={async () => {
                      setDeleteLoading(true);

                      try {
                        await onDeleteItem(mediumType);
                      } catch (error) {
                        serverAlert(error.response.data.message);
                      }

                      setDeleteLoading(false);
                    }}
                  >
                    <CloseIcon
                      style={{ opacity: deleteLoading ? 0 : 1 }}
                      fill="var(--error-color)"
                      width="16"
                    />
                  </Button>
                  <Button background="gray" noWrapper type="button" disabled={!imageUrl}>
                    {imageUrl && <a href={imageUrl} target="_blank" rel="noopener noreferrer" />}
                    <ExternalIcon width="17" fill="var(--text-color-dark-gray)" />
                  </Button>
                  <Button background="gray" noWrapper type="button" disabled={!imageUrl}>
                    {imageUrl && (
                      <a href={`${apiUrl}/media/download/${mediumData.id}/reference`} download />
                    )}
                    <DownloadIcon width="19" fill="var(--text-color-dark-gray)" />
                  </Button>
                </Buttons>
              </Grid>
            </Wrapper>

            <MediaManager
              allowedTypes={['image']}
              onClose={() => setMediaManagerVisibility(false)}
              onSelectedItem={async item => {
                setAddLoading(true);
                setMediaManagerVisibility(false);

                try {
                  await onSelectedItem(mediumType, item);
                } catch (error) {
                  serverAlert(error.response.data.message);
                }

                setAddLoading(false);
              }}
              isMounted={mediaManagerVisibility}
            />
          </>
        );
      }}
    </Consumer>
  );
};

ImagePanel.propTypes = {
  mediumType: PropTypes.string.isRequired,
  onSelectedItem: PropTypes.instanceOf(Function).isRequired,
  onDeleteItem: PropTypes.instanceOf(Function).isRequired,
};

export default ImagePanel;
