import styled from 'styled-components';

import DialogWindow from 'components/Dialog/components/DialogWindow';

export default styled(DialogWindow)`
  font-family: var(--google-sora-cdn);
  transform: none;
  padding: 15px 30px;
  text-align: left;
  width: 310px;
`;
