import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from 'store/actions/userData';
import TopDonators from './components/TopDonators';

const mapStateToProps = ({ userData: { topDonators } }) => ({
  topDonators,
});

const mapDispatchToProps = dispatch => bindActionCreators(userActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopDonators);
