import React from 'react';
import styled from 'styled-components';
import Odometer from 'components/Odometer';

const Wrapper = styled.div`
  .odometer {
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    font-weight: 900;
    color: var(--green);
    margin-right: 10px;
    margin-bottom: -9px;
  }

  .odometer-formatting-mark {
    position: relative;
    top: 3px;
  }
`;

const StyledOdometer = props => (
  <Wrapper>
    <Odometer {...props} />
  </Wrapper>
);

export default StyledOdometer;
