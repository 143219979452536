import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import Wrapper from './styles/Wrapper';
import NumberWrapper from './styles/NumberWrapper';

const Range = ({ max, value, vertical, whiteColorMinValue, whiteColorMaxValue, ...props }) => (
  <Wrapper data-range>
    <NumberWrapper vertical={vertical} white={value >= whiteColorMinValue} style={{ bottom: 20 }}>
      {value}
    </NumberWrapper>
    <NumberWrapper vertical={vertical} white={value >= whiteColorMaxValue} style={{ top: 20 }}>
      {max}
    </NumberWrapper>

    <Slider {...props} max={max} vertical={vertical} value={value} />
  </Wrapper>
);

Range.propTypes = {
  value: PropTypes.number.isRequired,
  whiteColorMinValue: PropTypes.number.isRequired,
  whiteColorMaxValue: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
  max: PropTypes.number,
};

Range.defaultProps = {
  vertical: false,
  max: 100,
};

export default Range;
