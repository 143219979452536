/**
 * Abbreviated call to Object.prototype.hasOwnProperty
 *
 * @param {object} object
 * @param {string} key
 * @returns {boolean}
 */
export default function hop(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
