import deepmerge from 'deepmerge';
import http from 'utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const fetchModeratorsRequest = () => ({
  type: 'MODERATOR_FETCH_REQUEST',
});

const fetchModeratorsSuccess = payload => ({
  type: 'MODERATOR_FETCH_SUCCESS',
  payload,
});

const fetchModeratorsError = () => ({
  type: 'MODERATOR_FETCH_ERROR',
});

const fetchModerators = () => dispatch => {
  dispatch(fetchModeratorsRequest());

  return http
    .get('/moderators')
    .then(response => response.data)
    .then(moderators => {
      dispatch(fetchModeratorsSuccess(moderators));
    })
    .catch(() => dispatch(fetchModeratorsError()));
};

export const fetchModeratorsIfNeeded = () => (dispatch, getState) => {
  const { moderators } = getState();

  if (shouldFetchData(moderators)) {
    dispatch(fetchModerators());
  }
};

const createModeratorRequest = () => ({
  type: 'MODERATOR_CREATE_REQUEST',
});

const createModeratorSuccess = payload => ({
  type: 'MODERATOR_CREATE_SUCCESS',
  payload,
});

const createModeratorError = () => ({
  type: 'MODERATOR_CREATE_ERROR',
});

export const createModerator = () => (dispatch, getState) => {
  dispatch(createModeratorRequest());

  const newModerator = {
    moderator_name: 'Nazwa moderatora',
    link_time: 100,
    link: '',
  };

  http
    .post('/moderators', newModerator)
    .then(response => response.data)
    .then(moderator => dispatch(createModeratorSuccess(moderator)))
    .catch(() => dispatch(createModeratorError()));
};

const updateModeratorRequest = payload => ({
  type: 'MODERATOR_UPDATE_FIELD_REQUEST',
  payload,
});

const updateModeratorSuccess = () => ({
  type: 'MODERATOR_UPDATE_FIELD_SUCCESS',
});

const updateModeratorError = () => ({
  type: 'MODERATOR_UPDATE_FIELD_ERROR',
});

export const updateModeratorField = (id, field, value) => (dispatch, getState) => {
  dispatch(updateModeratorRequest({ id, field, value }));

  const {
    moderators: { items },
  } = getState();

  const moderator = items.find(i => i.id === id);
  const data = deepmerge(moderator, { [field]: value });

  http
    .patch(`/moderators/${id}`, data)
    .then(() => {
      dispatch(updateModeratorSuccess());
    })
    .catch(() => dispatch(updateModeratorError()));
};

const deleteModeratorRequest = id => ({
  type: 'MODERATOR_DELETE_REQUEST',
  id,
});

const deleteModeratorSuccess = () => ({
  type: 'MODERATOR_DELETE_SUCCESS',
});

const deleteModeratorError = () => ({
  type: 'MODERATOR_DELETE_ERROR',
});

export const deleteModerator = id => dispatch => {
  dispatch(deleteModeratorRequest(id));

  http
    .delete(`/moderators/${id}`)
    .then(() => dispatch(deleteModeratorSuccess()))
    .catch(() => dispatch(deleteModeratorError()));
};

export const emptyModerator = id => dispatch => {
  dispatch(updateModeratorField(id, 'count_from', new Date()));
  dispatch(updateModeratorField(id, 'initial_value', 0));
};
