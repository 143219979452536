function desinence(value, singleton, underFive, rest) {
  const lastDigit = parseInt(
    Math.floor(value)
      .toString()
      .slice(-1),
    10,
  );
  if (lastDigit === 1) {
    return singleton;
  }
  if (lastDigit && lastDigit < 4) {
    return underFive;
  }
  return rest;
}

function withDesinence(value, singleton, underFive, rest) {
  return `${value} ${desinence(value, singleton, underFive, rest)}`;
}

export { desinence, withDesinence };
