import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import './Tooltip.scss';

/**
 * Tooltip component based on Tippy.js
 * @see https://github.com/atomiks/tippyjs
 * @see https://github.com/atomiks/tippyjs-react
 *
 * @returns {React.FC}
 */
export const Tooltip = ({ children, ...props }) => <Tippy {...props}>{children}</Tippy>;

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};
