import React from 'react';
import PropTypes from 'prop-types';

import { SimpleCheckbox } from 'components/Checkboxes';
import InfoTooltip from 'components/InfoTooltip';
import Box from '../styles/Box';

const Switcher = ({ text, name, value, tooltipContent, onChange }) => (
  <Box>
    <span style={{ position: 'relative' }}>
      {text}

      {Boolean(tooltipContent) && (
        <InfoTooltip
          style={{ position: 'absolute', top: -10, right: -20 }}
          content={tooltipContent}
          placement="right"
          theme="dark"
        />
      )}
    </span>

    <SimpleCheckbox
      name={name}
      checked={value}
      onCheck={checkboxValue => onChange(checkboxValue)}
      color="yellow"
      apperance="switch"
    />
  </Box>
);

Switcher.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

Switcher.defaultProps = {
  tooltipContent: '',
};

export default Switcher;
