import styled from 'styled-components';

export default styled.span`
  position: relative;
  display: block;
  width: 60px;
  height: 25px;
  border-radius: 100px;
  background-color: var(--bg-switch);
  transition: 0.3s ease;

  &:active {
    &::after {
      width: 30px;
    }
  }

  &::after {
    background-color: var(--bg-text-editor-color);
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 19px;
    height: 19px;
    border-radius: 100px;
    transition: 0.3s ease;
  }
`;
