import styled, { css } from 'styled-components';

import colorBorder from 'styles/colorBorder';

const StyledButton = styled.button`
  display: inline-flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 17px;
  box-sizing: border-box;
  border-radius: 32px;
  color: #adadad;
  background-color: rgba(36, 37, 38, 0.2);
  border: 1px solid #606060;
  font-family: var(--google-sora-cdn);
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: auto;
  min-width: unset;
  transition: var(--hover-transition);

  &:hover {
    color: #fff;
    background-color: rgba(36, 37, 38, 0.5);
  }

  &:active {
    transform: scale(0.96);
  }

  ${props => props.rgb && colorBorder}

  ${props =>
    props.blue &&
    css`
      border-color: #037cfa;
      color: var(--text-color);
      background: rgba(3, 124, 250, 0.2);
      &:hover {
        background: rgba(3, 124, 250, 0.5);
      }
    `}

  &::before {
    border-radius: 32px;
    inset: -1px;
    padding: 1px;
  }

  svg {
    height: 14px;
    max-width: 14px;

    path {
      fill: currentColor;
    }
  }
`;

const ElementActionButton = styled(StyledButton)`
  background-color: white;
  border-color: white;
  color: black;
`;

export { StyledButton };

export default ElementActionButton;
