import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Overlay from 'components/Modal/styles/Overlay';

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 1200px;
  max-height: 100%;
  overflow: auto;
  padding: 64px 32px;
  box-sizing: border-box;

  transition: all 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  &.fade-enter {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0);
    }
  }

  &.fade-enter-active,
  &.fade-enter-done {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0.7);
    }

    ${Content} {
      opacity: 1;
    }
  }

  &.fade-exit,
  &.fade-exit-active {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0);
      visibility: hidden;
      transition: all ${props => props.unmountDelay}ms cubic-bezier(0.17, 0.84, 0.44, 1);
    }

    ${Content} {
      opacity: 0;
      transition: all ${props => props.unmountDelay - 200}ms cubic-bezier(0.17, 0.84, 0.44, 1);
      transform: scale(0.2) translate(-50%, -50%);
    }
  }
`;

const AnnouncementOverlay = ({ onClose, children, unmountDelay }) =>
  ReactDOM.createPortal(
    <Wrapper unmountDelay={unmountDelay}>
      <Overlay onClick={onClose} />
      <Content>{children}</Content>
    </Wrapper>,
    document.body,
  );

AnnouncementOverlay.propTypes = {
  unmountDelay: PropTypes.number.isRequired,
  onClose: PropTypes.instanceOf(Function),
  children: PropTypes.node.isRequired,
};

AnnouncementOverlay.defaultProps = {
  onClose: () => {},
  unmountDelay: 500,
};

export default AnnouncementOverlay;
