import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  setDisplaySettingsDialogCurrentView,
  setDisplaySettingsDialogVisibility,
} from 'store/actions/configuratorsConfigs';
import NotificationsContext from '../../../../../NotificationsContext';

const Root = styled.div`
  position: absolute;
  top: calc(100% + 18px);
  left: 0;
  z-index: 10;

  width: 100%;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  padding: 42px 36px 12px;
  flex-shrink: 0;
  border-radius: 25px;
  background: color-mix(in srgb, var(--white-box-background) 90%, transparent);
  backdrop-filter: blur(6px);
  border: 1px solid var(--border-color);
`;

const Title = styled.h3`
  margin: 0;
  color: var(--text-color);
  font-family: var(--google-sora-cdn);
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
`;

const Info = styled.p`
  margin: 0;
  color: #adadad;
  font-family: var(--google-sora-cdn);
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  row-gap: 16px;
  flex-direction: column;
`;

const GeneralButton = styled.button`
  color: inherit;
  background: transparent none;
  font-family: var(--google-sora-cdn);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: block;
  border: none;
`;

const CloseButton = styled(GeneralButton)`
  color: var(--brand-green);
  text-transform: uppercase;
  font-weight: 800;
`;

const DisableNotificationButton = styled(GeneralButton)`
  color: color-mix(in srgb, var(--white-box-background) 80%, white);
`;

const UseSavedTemplateNotificationId = 'USE_SAVED_TEMPLATE';

const UsesSavedTemplateNotification = () => {
  const dispatch = useDispatch();
  const { setCurrentNotification } = useContext(NotificationsContext);

  const handleClick = () => {
    dispatch(setDisplaySettingsDialogCurrentView('templates'));
    dispatch(setDisplaySettingsDialogVisibility(true));
  };

  const close = evt => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    setCurrentNotification('');
  };

  const handleDisableNotification = evt => {
    close(evt);
    localStorage.setItem(UseSavedTemplateNotificationId, 'disabled');
  };

  return (
    <Root onClick={handleClick}>
      <Title>Używaj stworzonego szablonu</Title>
      <Info>
        Aby używać stworzonego szablonu, wejdź w menu powyżej i zdecyduj od jakiej kwoty stworzony
        widok ma być widoczny w OBS
      </Info>
      <Controls>
        <CloseButton onClick={close}>Zamknij</CloseButton>
        <DisableNotificationButton onClick={handleDisableNotification}>
          Nie przypominaj mi o tym
        </DisableNotificationButton>
      </Controls>
    </Root>
  );
};

export { UseSavedTemplateNotificationId };

export default UsesSavedTemplateNotification;
