import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { SimpleCheckbox } from 'components/Checkboxes';
import NumberInput from 'components/NumberInput';

import Status from './components/Status';

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 25px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;

  > span {
    min-width: 70px;
    text-align: right;

    input {
      border-radius: 8px;

      &:hover,
      &:active,
      &:focus {
        border-color: var(--brand-green) !important;
      }
    }
  }
`;

const MainWrapper = styled.div`
  width: 260px;
  padding: 25px 30px 50px;
`;

export const redirectToTwitchAuthorization = () => {
  const params = new URLSearchParams();
  params.set('response_type', 'token');
  params.set('scope', 'channel:read:subscriptions bits:read');
  params.set('state', Date.now().toString());
  params.set('client_id', process.env.REACT_APP_TWITCH_CLIENT_ID);
  params.set('redirect_uri', process.env.REACT_APP_TWITCH_CALLBACK_URL);

  const redirectUri = `https://id.twitch.tv/oauth2/authorize?${params}`;

  setTimeout(() => {
    window.location.assign(redirectUri);
  }, 4000);
};

const TwitchActivity = ({
  configuratorConfig,
  updateConfig,
  userData: {
    info: { twitch_id: twitchId },
  },
  dispatchTwitchUnsubscribe,
}) => {
  const mainWrapperRef = useRef();
  const statusPortalContainerRef = useRef(document.createElement('div'));

  useEffect(() => {
    if (mainWrapperRef.current) {
      mainWrapperRef.current.parentNode.parentNode.prepend(statusPortalContainerRef.current);
    }
  }, [mainWrapperRef, statusPortalContainerRef]);

  const isTwitchConnected = twitchId && twitchId.length > 0;
  const numberInputStyles = { maxWidth: 90, textAlign: 'center' };

  return (
    <MainWrapper ref={mainWrapperRef}>
      <Status targetEl={statusPortalContainerRef.current} connected={isTwitchConnected} />

      <CheckboxWrapper>
        {twitchId && (
          <SimpleCheckbox
            name="twitchActivity"
            checked={configuratorConfig.twitchActivity || false}
            color="green"
            apperance="switch"
            onCheck={value => {
              updateConfig({ twitchActivity: value, twitchActivityBegin: moment().format() });

              if (!configuratorConfig.twitchActivity) {
                redirectToTwitchAuthorization();
              } else {
                const unsubscribeTwitch = `{"broadcaster_user_id":"${twitchId}"}`;
                dispatchTwitchUnsubscribe(unsubscribeTwitch);
              }
            }}
          />
        )}
      </CheckboxWrapper>

      <TextWrapper>
        {isTwitchConnected
          ? 'Włącz przedłużenie stream poprzez aktywność Twitch'
          : 'Aby włączyć, najpierw połącz się z Twitch'}
      </TextWrapper>

      <InputWrapper>
        <span>Sub tier 1 =</span>
        <span>
          <NumberInput
            decimalSeparator=","
            defaultValue={configuratorConfig.subTier1 || 0}
            decimalScale={0}
            allowEmptyFormatting
            allowNegative={false}
            suffix=" sek"
            style={numberInputStyles}
            onValueChange={value => {
              updateConfig({ subTier1: value.value });
            }}
          />
        </span>
      </InputWrapper>
      <InputWrapper>
        <span>Sub tier 2 =</span>
        <span>
          <NumberInput
            decimalSeparator=","
            defaultValue={configuratorConfig.subTier2 || 0}
            decimalScale={0}
            allowEmptyFormatting
            allowNegative={false}
            suffix=" sek"
            style={numberInputStyles}
            onValueChange={value => {
              updateConfig({ subTier2: value.value });
            }}
          />
        </span>
      </InputWrapper>
      <InputWrapper>
        <span>Sub tier 3 =</span>
        <span>
          <NumberInput
            decimalSeparator=","
            defaultValue={configuratorConfig.subTier3 || 0}
            decimalScale={0}
            allowEmptyFormatting
            allowNegative={false}
            suffix=" sek"
            style={numberInputStyles}
            onValueChange={value => {
              updateConfig({ subTier3: value.value });
            }}
          />
        </span>
      </InputWrapper>
      <InputWrapper>
        <span>100 bits =</span>
        <span>
          <NumberInput
            decimalSeparator=","
            defaultValue={configuratorConfig.subBits || 0}
            decimalScale={0}
            allowEmptyFormatting
            allowNegative={false}
            suffix=" sek"
            style={numberInputStyles}
            onValueChange={value => {
              updateConfig({ subBits: value.value });
            }}
          />
        </span>
      </InputWrapper>
    </MainWrapper>
  );
};

TwitchActivity.propTypes = {
  configuratorConfig: PropTypes.instanceOf(Object).isRequired,
  updateConfig: PropTypes.instanceOf(Function).isRequired,
  userData: PropTypes.instanceOf(Object).isRequired,
  dispatchTwitchUnsubscribe: PropTypes.instanceOf(Function).isRequired,
};

export default TwitchActivity;
