import { openAlert } from 'components/Alert/utils';

export default (state = [], action) => {
  switch (action.type) {
    case 'VOTING_FETCH_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'VOTING_FETCH_ERROR':
      openAlert(
        'error',
        'Błąd zapisu konfiguracji głosowania',
        `Podczas zapisu konfiguracji wystąpił błąd #${action.error}`,
      );
      return {
        ...state,
        isFetching: false,
      };
    case 'VOTING_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        config: {
          ...state.config,
          ...action.payload,
        },
      };
    case 'VOTING_CHANGE_CONFIG':
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};
