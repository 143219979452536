import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_5.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step8 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Przejdź do zakładki „Settings” i wybierz interesującą nas metodę płatności a więc Paysafecard.
      Po kliknięciu, otworzy Ci się okno, w którym musisz uzupełnić wszelkie wymagane dane osobowe.
      Pamiętaj, aby były one autentyczne.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
  </StepContent>
);

export default Step8;

Step8.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
