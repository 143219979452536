import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from 'assets/icons/arrow-right-1.svg';

import TextInput from './TextInput';

const Wrapper = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 15px;
    right: 23px;
    width: 10px;
    height: 16px;
    fill: var(--text-color);
  }
`;

const TextInputWithChevron = props => (
  <Wrapper>
    <TextInput {...props} />
    <ChevronIcon />
  </Wrapper>
);

export default TextInputWithChevron;
