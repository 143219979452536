import styled from 'styled-components';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 70px;
  height: 70px;
  border-radius: 100%;

  svg {
    fill: #fff;
  }

  img {
    max-width: 75px;
  }
`;

export default Icon;
