/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { openAlert } from 'components/Alert/utils';

import { selectAccountWithHighestBalance, selectUserData, selectWithdrawals } from 'store/selector';
import { findUserAccount } from 'store/utils';

import http from 'utils/http';
import priceFormatter from 'utils/priceFormatter';

import Grid from 'components/Grid';

import { AccountBalance, CustomAmount, MoneyTransfer } from './components';
import usePaymentState, { Context } from './state';
import { useWithdrawalMethods } from './utils';

const openErrorAlert = errorResponse => {
  const { status, data } = errorResponse;

  let errorMessage = '';

  switch (status) {
    case 400:
      errorMessage = data;
      break;
    case 403:
      errorMessage =
        'Twoje konto jeszcze nie zostało zweryfikowane przez administratora. Dopiero po weryfikacji będziesz mógł dokonywać wypłaty środków.';
      break;
    case 422:
      errorMessage =
        'Uzupełnij dane osobowe aby móc zrealizować wypłatę. Po uzupełnieniu oczekuj na akceptację danych, a następnie spróbuj ponownie.';
      break;
    case 429:
      errorMessage = 'Wykorzystałeś limit wypłat dla danej metody płatności';
      break;
    default:
      errorMessage = `Wystąpił błąd na serwerze. Błąd: ${status}`;
  }

  openAlert('error', 'Coś się pokićkało', errorMessage);
};

const openSuccessAlert = withdrawal2fa => {
  if (withdrawal2fa) {
    openAlert(
      'loading',
      'Jeszczę trochę..',
      'Potwierdź operację klikając w link wysłany w mailu.',
      { confirmButtonText: 'Zamknij okno' },
    );
  } else {
    openAlert(
      'success',
      'Zlecenie zostało przyjęte do realizacji',
      'Na Twój adres e-mail, zostało wysłane potwierdzenie zlecenia.',
      { confirmButtonText: 'Zamknij okno' },
    );
  }
};

const standardMethodTooltip = (bank, paypal, minimum) =>
  `Metoda wypłaty bez ograniczeń kwoty. Czas realizacji przelewu to średnio 1-2 dni, a maksymalnie
  4 dni robocze. ${bank.commission}% - nie mniej niż ${priceFormatter(bank.minimalCommission)} -
  prowizja za przelew ekspresowy na konto bankowe i ${paypal.commission}% - nie mniej niż
  ${priceFormatter(paypal.minimalCommission)} - za wypłatę na konto PayPal. Kwota minimalna do
  wypłaty: ${priceFormatter(minimum)}`;

const Payment = () => {
  const [state, dispatch] = usePaymentState();
  const userData = useSelector(selectUserData);
  const { customAmountValue, customAmountEnabled, activeType, values } = state;

  const maxLimit = 4;
  const countDays = 7;
  let counter = 0;
  const nowDate = new Date();
  const startDate = new Date();
  startDate.setDate(nowDate.getDate() - countDays);
  const { items } = useSelector(selectWithdrawals);

  items.forEach(item => {
    if (item.requested_at) {
      const itemDate = new Date(item.requested_at);
      if (itemDate && startDate < itemDate) {
        counter += 1;
      }
    }
  });

  const setActiveType = newType => dispatch('SET_TYPE', newType);

  const richestAccount = useSelector(selectAccountWithHighestBalance);
  const [richestAccountSelected, setRichestAccountSelected] = useState(false);

  useEffect(() => {
    if (richestAccount && !richestAccountSelected) {
      setRichestAccountSelected(true);
      setActiveType(richestAccount.type);
    }
  }, [richestAccount, richestAccountSelected]);

  const minimalAmountAllowed = !!userData.info.minimal_amount_allowed;

  const minimumWithdrawalAmount = minimalAmountAllowed ? 1 : 2000;
  const minimumExpressWithdrawalAmount = 15000;

  const expressBankList =
    'https://docs.google.com/document/d/1Cw7dIX8hY6OuJdO_swD-XwPbwnkH_ybIjtsUNIhPHVw';

  const validateMinimumAmount = (amount, withdrawalMethod) => {
    let minimumAmountErrorValue = null;

    if (
      ['bank_express', 'paypal_express'].includes(withdrawalMethod) &&
      amount < minimumExpressWithdrawalAmount
    ) {
      minimumAmountErrorValue = minimumExpressWithdrawalAmount;
    } else if (
      ['bank_standard', 'paypal'].includes(withdrawalMethod) &&
      amount < minimumWithdrawalAmount
    ) {
      minimumAmountErrorValue = minimumWithdrawalAmount;
    }

    if (minimumAmountErrorValue) {
      const errorMessage = `Próbujesz wypłacić kwotę ${priceFormatter(
        amount,
      )}, kiedy kwotą minimalną dla tej metody
        wypłaty jest ${priceFormatter(minimumAmountErrorValue)}. Zgromadź brakujące
        ${priceFormatter(minimumAmountErrorValue - amount)} aby skorzystać z tej metody
        wypłaty.`;
      openAlert('error', 'Coś się pokićkało', errorMessage);
      return false;
    }

    return true;
  };

  const [standardMethods, expressMethods] = useWithdrawalMethods(userData);

  const send = withdrawalMethod => {
    const account = findUserAccount(userData, state.activeType, 'SP');
    const amount = customAmountEnabled ? customAmountValue : account.balance;

    if (!validateMinimumAmount(amount, withdrawalMethod)) {
      return;
    }

    const commission = [...expressMethods, ...standardMethods].find(
      value => value.id === withdrawalMethod,
    );

    const commissionInfo = `zostanie pobrana opłata realizatora płatności w wys.
      ${commission.commission}% - nie mniej niż
      ${priceFormatter(commission.minimalCommission)} - z bilansu konta.`;

    openAlert(
      'confirm',
      'Czy na pewno?',
      <span style={{ fontSize: 14 }}>
        Potężne pieniądze wypłacasz, przemyśl to!
        <br />
        <br />
        Wypłacasz {priceFormatter(amount)}.
      </span>,
      {
        cancelButtonText: 'Anuluj wypłatę',
        confirmButtonText: 'Wystaw rachunek lub fakturę',
        afterConfirmButton: <span style={{ fontSize: 13 }}>{commissionInfo}</span>,
        cancelButton: true,
      },
      () => {
        const data = {
          accountId: account.account_id,
          method: withdrawalMethod,
          amount: customAmountEnabled ? customAmountValue : null,
        };

        http
          .post('/withdrawals', data)
          .then(() => {
            openSuccessAlert(userData.info.withdrawal_2fa_enabled);
          })
          .catch(error => {
            openErrorAlert(error.response);
          });
      },
    );
  };

  if (standardMethods.length === 0 || expressMethods.length === 0) {
    return null;
  }

  const [expressBankCommission, expressPayPalCommission] = expressMethods.map(o => o.commission);
  const [standardBankConfig, standardPayPalConfig] = standardMethods;

  return (
    <Context.Provider
      value={{
        activeType,
        values,
        setActiveType,
      }}
    >
      <Grid columns="460px 1fr">
        <Grid rows="1fr 1fr" gap="20px">
          <AccountBalance type="WAGE" />
          <AccountBalance type="GRANT" />
        </Grid>

        <Grid rows="1fr 1fr" gap="20px">
          <Grid columns="1fr 1fr">
            <MoneyTransfer
              background="blue"
              text="Natychmiastowy przelew"
              description="Wypłata nawet w minutę do 5.000 zł"
              tooltip={`Przelew wykonywany jest automatycznie. Jeżeli przypisałeś konto, które obsługuje
              przelewy natychmiastowe z <a href='${expressBankList}' target='_blank'>tej listy</a>,
              przelew powinien dojść nawet w minutę! ${expressBankCommission}% dla przelewów bankowych
              i ${expressPayPalCommission}% w dla wypłat realizowanych za pomocą PayPal. Możesz wykonać
              ten typ przelewu ${maxLimit} razy w ciągu ${countDays} dni. Maksymalna kwota jednego
              przelewu to 5.000 zł. Kwota minimalna do
              wypłaty: ${priceFormatter(minimumExpressWithdrawalAmount)}, do
              wykorzystania: ${maxLimit - counter > 0 ? maxLimit - counter : 0}`}
              menuOptions={expressMethods}
              onClickOption={type => send(type)}
            />

            <MoneyTransfer
              background="yellow"
              text="Ekspresowy przelew"
              menuOptions={standardMethods}
              description="Wypłata w 1-4 dni, bez ograniczeń kwoty."
              tooltip={standardMethodTooltip(
                standardBankConfig,
                standardPayPalConfig,
                minimumWithdrawalAmount,
              )}
              onClickOption={type => send(type)}
            />
          </Grid>

          <CustomAmount
            enable={customAmountEnabled}
            value={customAmountValue}
            onChangeEnable={value => dispatch('SET_CUSTOM_AMOUNT_ENABLED', value)}
            onChangeValue={value => dispatch('SET_CUSTOM_AMOUNT_VALUE', value)}
          />
        </Grid>
      </Grid>
    </Context.Provider>
  );
};

export { Context };
export default Payment;
