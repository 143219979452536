import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  .title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 17px;
    margin-top: 0;
  }

  .buttons_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .other_buttons {
    text-align: right;
    width: 100%;

    button {
      margin: 0 30px;
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  button.with_checkbox {
    padding-left: 55px;
    padding-right: 40px;
    border-radius: 20px;
    background: var(--bg-media-button);

    &:hover {
      background: var(--brand-blue);
    }
  }
`;
