import React, { useContext, useEffect, useState } from 'react';

import Select from 'components/Select';
import ThresholdContext from './ThresholdContext';

import AmountSelector from './AmountSelector';
import DeleteButton from './DeleteButton';
import InputWrapper from './styles/InputWrapper';
import Options from './styles/Options';
import AmountMin from './styles/AmountMin';

const TemplateSelector = () => {
  function getTemplate(templateId, list) {
    return list.find(t => t.value === templateId) || list[0];
  }

  const { config, templates, onChange, isUpdating } = useContext(ThresholdContext);
  const [template, setTemplate] = useState(getTemplate(config.templateId, templates));

  useEffect(() => {
    setTemplate(getTemplate(config.templateId, templates));
  }, [config.templateId, templates]);

  return (
    <Select
      options={templates}
      value={template}
      placeholder="Nie wybrano żadnego szablonu"
      onChange={value => onChange({ templateId: value.value })}
      isDisabled={isUpdating}
      hoverBorderColor="var(--brand-green)"
    />
  );
};

const AlertTemplateThreshold = () => {
  const { isDefault } = useContext(ThresholdContext);
  const hoverColor = 'var(--brand-green)';

  return (
    <div>
      <InputWrapper>
        <div style={{ flex: 1 }}>
          <TemplateSelector />
        </div>

        {!isDefault && <DeleteButton />}
      </InputWrapper>

      <Options>
        {!isDefault && (
          <AmountMin>
            <span>Wyświetlany od kwoty:</span>
            <AmountSelector hoverColor={hoverColor} />
          </AmountMin>
        )}
      </Options>
    </div>
  );
};

export default AlertTemplateThreshold;
