import React from 'react';
import { useSelector } from 'react-redux';

import { ButtonWithAnimatedArrow } from '../../Buttons';
import DashedBorderBox from '../components/DashedBorderBox';
import EmojiLabel from '../components/EmojiLabel';
import FormWrapper from '../components/FormWrapper';
import InputWithCopy from '../components/InputWithCopy';
import StepWrapper from '../components/StepWrapper';
import { StepPropTypes } from '../types';

const BankTransferStep = ({ processStep }) => {
  const {
    userData: {
      info: { id },
    },
  } = useSelector(state => state);

  return (
    <StepWrapper>
      <p>
        Świetnie, dziękujemy. To już ostatni krok. By&nbsp;potwierdzić swoją tożsamość dokonaj
        przelewu bankowego ze swojego banku kopiując poniższe dane do przelewu.
      </p>
      <DashedBorderBox>
        <FormWrapper className="no-margin">
          <div className="row half-half">
            <div className="cell">
              <EmojiLabel emoji="👤" label="Nazwa odbiorcy" />
              <InputWithCopy value="APPTIZ SPACE Sp. z o.o." />
            </div>
            <div className="cell">
              <EmojiLabel emoji="✍️" label="Tytuł przelewu" />
              <InputWithCopy type="text" value={id} readOnly />
            </div>
          </div>
          <div className="row">
            <div className="cell wide">
              <EmojiLabel emoji="🏦" label="Numer konta bankowego" />
              <InputWithCopy type="text" value="PL96 1140 2004 0000 3002 8412 3566" readOnly />
            </div>
            <div className="cell">
              <EmojiLabel emoji="💲" label="Kwota" />
              <InputWithCopy type="text" visibleValue="0,01 PLN" value="0,01" readOnly />
            </div>
          </div>
        </FormWrapper>
      </DashedBorderBox>
      <p>
        Przelew dotrze do nas w następnym dniu roboczym (pon-pt) na nasze konto bankowe, więc
        weryfikacji swojego profilu możesz oczekiwać dopiero po kilku dniach.&nbsp;
        <strong>Maksymalnie weryfikacja może zająć 7 dni.</strong>
      </p>
      <p>
        Jeśli dokonałeś przelewu ze swojego banku możesz przejść dalej. Będzie to podsumowanie
        Twojego wniosku.
      </p>
      <div className="button-container">
        <ButtonWithAnimatedArrow onClick={() => processStep()}>
          Potwierdzam wysłanie przelewu
        </ButtonWithAnimatedArrow>
      </div>
    </StepWrapper>
  );
};

BankTransferStep.propTypes = StepPropTypes;

export default BankTransferStep;
