import styled from 'styled-components';

export default styled.button`
    border: 0 solid white;
    background: none;
    padding: 0px 16px;
    outline: none;
    cursor: pointer;
    position:relative;

    > svg{
        width ${props => props.width};
        fill: var(--text-color-medium-gray);
        transition: 250ms ease-in-out;
    }

    &::after{
        content: '';
        width: 7px;
        height: 7px;
        background: var(--brand-green);
        position: absolute;
        top: -4px;
        right: 8px;
        border-radius: 5px;
        transition: 400ms;
        opacity: ${props => (props.notify ? 1 : 0)};
    }

    :hover{
        > svg{
            fill: var(--brand-green);
        }
    }
`;
