import styled from 'styled-components';
import colorBorder from 'styles/colorBorder';

export default styled.button`
  width: 309px;
  height: 61px;
  border: 1px solid var(--border-color);
  border-radius: 35px;
  box-sizing: border-box;

  padding: 12px 30px;
  display: grid;
  grid-template-columns: 21px 1fr;
  column-gap: 21px;
  align-items: center;
  transition: var(--hover-transition);

  background-color: var(--white-box-background);
  color: var(--text-color);
  font-family: var(--google-sora-cdn);
  font-size: 15px;
  line-height: normal;

  ${props => props.rgb && colorBorder}

  &:hover {
    background-color: var(--bg-hover-capsule);
  }

  &:active {
    transform: scale(0.96);
  }

  svg {
    fill: var(--text-color);
    width: 21px;
    height: auto;
  }

  span {
    text-align: left;

    strong {
      font-size: 11px;
      font-weight: 800;
      color: var(--grey);
      display: block;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
  }
`;
