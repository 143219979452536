import styled, { css, keyframes } from 'styled-components';

const stripesAnimation = keyframes`
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
`;

export const stripesStyles = css`
  background-image: linear-gradient(
    45deg,
    var(--stripesColor) 25%,
    transparent 25%,
    transparent 50%,
    var(--stripesColor) 50%,
    var(--stripesColor) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: ${stripesAnimation} 2s linear infinite;
`;

const Stripes = styled.div`
  --stripesColor: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${stripesStyles};
`;

export default Stripes;
