import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ColorfulRoundedButton } from 'components/Buttons';
import { usePayPal } from 'components/PayPalConnect/hooks';
import ActiveState from '../ActiveState';
import ConnectedMethodSettings from '../ConnectedMethodSettings';
import ItemContainer from '../ItemContainer';
import MinAmount from '../MinAmount';
import SuccessfullyConnected from './SuccessfullyConnected';
import WaitingForAuthorization from './WaitingForAuthorization';

const PayPalMethod = ({ enabled, onChange, hoverText, currentMinAmount, ...props }) => {
  const { connected, refreshEmail, working, waitingForAuthorization, disconnect } = usePayPal(
    onConnect,
  );

  const [settingsMode, setSettingsMode] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState();

  function onConnect(email) {
    setConnectedEmail(email);
    setTimeout(() => {
      onChange('enabled', true);
      setConnectedEmail(undefined);
    }, 3000);
  }

  function activate() {
    refreshEmail();
  }

  function deactivate() {
    disconnect(() => {
      setSettingsMode(false);
      onChange('enabled', false);
    });
  }

  if (waitingForAuthorization) {
    return <WaitingForAuthorization />;
  }

  if (connectedEmail !== undefined) {
    return <SuccessfullyConnected email={connectedEmail} />;
  }

  if (settingsMode) {
    return (
      <ConnectedMethodSettings
        disconnect={deactivate}
        onChange={onChange}
        enabled={enabled}
        onClose={() => setSettingsMode(false)}
        working={working}
      >
        <MinAmount value={currentMinAmount} onChange={onChange} hoverText={hoverText} />
      </ConnectedMethodSettings>
    );
  }

  return (
    <ItemContainer withMinAmount={false} {...props}>
      {connected ? (
        <ActiveState onSettingsRequested={() => setSettingsMode(true)} />
      ) : (
        <ColorfulRoundedButton onClick={activate} disabled={working}>
          Aktywuj
        </ColorfulRoundedButton>
      )}
    </ItemContainer>
  );
};

PayPalMethod.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hoverText: PropTypes.string.isRequired,
  currentMinAmount: PropTypes.number.isRequired,
};

export default PayPalMethod;
