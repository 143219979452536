import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  width: 100%;
  height: 66px;

  list-style: none;
  padding: 0 0 0 20px;
  margin: 0;
  border-radius: 33px;
  background-color: var(--bg-text-editor-color);
  border: 1px solid var(--white-white);
`;
