/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';
import moment from 'moment';

import { ReactComponent as RejectIcon } from 'assets/icons/reject-x.svg';
import { ReactComponent as OnCheckIcon } from 'assets/icons/ok-check.svg';

import priceFormatter from 'utils/priceFormatter';
import getUIAvatar from 'utils/getUIAvatar';
import parseMessage from 'utils/parseMessage';
import firstUrl from 'utils/firstUrl';

import Link from 'components/Link';
import { SoundPlayButton } from 'components/SoundPlayButton';

import StyledTip from '../styles/StyledTip';

import { BlueDot } from './elements/BlueDot';
import ButtonCap from './elements/ButtonCap';
import PaymentHumanName from './PaymentHumanName';
import { BlueDotSeparator } from './elements/BlueDotSeparator';
import { EMOTE_DOMAINS } from './EmoteDomains';

class TipModeration extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    goalTitle: PropTypes.string,
    showGoals: PropTypes.bool.isRequired,
    approveTipModeration: PropTypes.instanceOf(Function).isRequired,
    rejectTipModeration: PropTypes.instanceOf(Function).isRequired,
    paymentType: PropTypes.string.isRequired,
    audioUrl: PropTypes.string,
    fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {
    goalTitle: '',
    audioUrl: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isRejecting: false,
      isApproving: false,
    };
  }

  async approve() {
    const { id, approveTipModeration } = this.props;

    this.setState({ isApproving: true });

    const { isApproving } = this.state;

    if (!isApproving) {
      await approveTipModeration(id);
      this.setState({ isApproving: false });
    }
  }

  async reject() {
    const {
      id,
      rejectTipModeration,
      fetchTipsModerationBasketForModerators,
      fetchTipsModerationForTipsListPage,
    } = this.props;

    this.setState({ isRejecting: true });

    await rejectTipModeration(id);
    await fetchTipsModerationBasketForModerators();
    await fetchTipsModerationForTipsListPage();
    this.setState({ isRejecting: false });
  }

  render() {
    const {
      nickname,
      amount,
      createdAt,
      message,
      goalTitle,
      showGoals,
      paymentType,
      audioUrl,
    } = this.props;
    const { isApproving, isRejecting } = this.state;

    const name = `${nickname} `;
    const humanMethod = PaymentHumanName(paymentType);

    let isLink = '';
    if (message) {
      const url = firstUrl(message);
      if (url) {
        isLink = url;
      }
    }

    if (isLink) {
      for (const item of EMOTE_DOMAINS) {
        if (isLink[0].includes(item)) {
          isLink = '';
          break;
        }
      }
    }

    return (
      <StyledTip className={isApproving || isRejecting ? 'loading' : ''}>
        <div className="box">
          <div className="donator">
            <div className="flexavatar">
              <div
                className="avatar"
                style={{ backgroundImage: `url("${getUIAvatar(nickname)}")` }}
              />
            </div>
            <p className="no-margin text-white-black ">
              <span className="separator">
                <span className="nickname text-white-black text-large">{name}</span>
                <BlueDotSeparator />
                <span className="tip-amount text-blue text-default">{priceFormatter(amount)}</span>
              </span>
              <div className="timespan google-sora-cdn">
                <time dateTime={createdAt}>
                  {fecha.format(new Date(createdAt), 'DD MMM YYYY, HH:mm')}
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  &nbsp;({moment(createdAt).fromNow()})
                </time>
              </div>
              <span className="text-medium google-sora-cdn paymentmethod">
                Metoda płatności:&nbsp;
                <span className="text-blue">{humanMethod}</span>
              </span>
            </p>
          </div>
          <p className="message no-margin">
            {showGoals && goalTitle.length > 0 && (
              <span className="text-medium text-blue list-block google-sora-cdn">
                Wybrano:
                <span className="text-medium text-white-black" style={{ marginLeft: 10 }}>
                  {goalTitle}
                </span>
              </span>
            )}
            <span
              className="text-medium google-sora-cdn"
              dangerouslySetInnerHTML={{ __html: parseMessage(message) }}
            />
            {isLink && (
              <span className="list-block">
                <Link href={isLink} target="_blank">
                  {isLink}
                </Link>
              </span>
            )}
          </p>
          <div>
            <div className="buttons">
              {audioUrl && <SoundPlayButton audioUrl={audioUrl} />}
              <button type="button" onClick={() => this.approve()}>
                <OnCheckIcon />
              </button>
              <button type="button" onClick={() => this.reject()}>
                <RejectIcon className="reject-icon" />
              </button>
            </div>
            <div className="statuswrap">
              <ButtonCap disabled type="button" background="gray" noWrapper>
                <BlueDot />
                <span>OCZEKUJE NA MODERACJĘ</span>
              </ButtonCap>
            </div>
          </div>
        </div>
      </StyledTip>
    );
  }
}

export default TipModeration;
