import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import PanelBoxContent, { defaultStyle, boxStyle } from './PanelBoxContent';

const LinkRoot = styled.a`
  ${defaultStyle};

  text-decoration: none;
  position: relative;
`;

const ButtonRoot = styled.button`
  ${defaultStyle};
  ${boxStyle};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  transition: var(--hover-transition);

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    opacity: 0.6;
  }
`;

const ButtonLarge = props => {
  const { href, ...rest } = props;

  if (href)
    return (
      <LinkRoot {...rest}>
        <PanelBoxContent {...rest} />
      </LinkRoot>
    );

  return (
    <ButtonRoot {...rest}>
      <PanelBoxContent {...rest} />
    </ButtonRoot>
  );
};

ButtonLarge.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

ButtonLarge.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  color: undefined,
  href: undefined,
};

export default ButtonLarge;
