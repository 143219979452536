import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import autosizeInput from 'autosize-input';

import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';
import Flexbox from 'components/Flexbox';
import CollapsedWhiteBox from 'components/CollapsedWhiteBox';
import { SimpleCheckbox } from 'components/Checkboxes';
import { isNull } from 'utils/validators';
import { convertToGrosze, convertToZlotys } from 'utils/priceConverter';

import { Columns, Input, Separator } from './components';
import { Context } from '../../state';
import { openDisabledAlert } from '../../utils';

const CustomAmount = ({ value, enable, onChangeValue, onChangeEnable }) => {
  const [numberInputFired, setNumberInputFired] = useState(false);
  const { activeType } = useContext(Context);
  const isDisabled = isNull(activeType);

  return (
    <CollapsedWhiteBox
      isCollapsible={false}
      disabled={isDisabled}
      onClickDisabled={openDisabledAlert}
    >
      <Columns style={{ padding: '35px 0' }}>
        <div>
          <Text size={15} margin="0">
            Wybierz kwotę którą chcesz wypłacić
          </Text>
          <Flexbox align="flex-end">
            <Input
              disabled={!enable || isDisabled}
              decimalSeparator=","
              value={convertToZlotys(value)}
              defaultValue={convertToZlotys(value)}
              decimalScale={2}
              allowEmptyFormatting
              fixedDecimalScale
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                onChangeValue(convertToGrosze(floatValue));
              }}
              getInputRef={ref => {
                if (!numberInputFired && ref) {
                  autosizeInput(ref, { minWidth: true });
                  setNumberInputFired(true);
                }
              }}
            />
            <Text weight="bold" size={22} margin="0">
              PLN
            </Text>
          </Flexbox>
        </div>
        <Separator />
        <div>
          <Text size={13}>
            Chcesz wypłacić dedykowaną kwotę?
            <InfoTooltip
              style={{ position: 'relative', top: -5, left: 10 }}
              content={
                <Text margin="0">
                  Potrzebujesz równej kwoty na swoim koncie? Właśnie po to została stworzona ta
                  funkcja! Wpisz wybraną kwotę i zleć ją do wypłaty! Opłata serwisowa za wypłatę
                  zostanie pobrana bezpośrednio z bilansu konta, więc bez obaw. Jeśli jednak
                  próbujesz wypłacić kwotę równą tej z salda, po prostu odznacz przełącznik. Ta
                  funkcja w tym momencie, do niczego Ci się nie przyda.
                </Text>
              }
              placement="right"
              theme="dark"
            />
            <br />
            Aby wypłacić cały bilans konta, nie zaznaczaj.
          </Text>

          <SimpleCheckbox
            apperance="switch"
            color="green"
            checked={enable}
            onCheck={onChangeEnable}
          />
        </div>
      </Columns>
    </CollapsedWhiteBox>
  );
};

CustomAmount.propTypes = {
  value: PropTypes.number.isRequired,
  enable: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.instanceOf(Function).isRequired,
  onChangeEnable: PropTypes.instanceOf(Function).isRequired,
};

export default CustomAmount;
