import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tipsActions from 'store/actions/tips';
import * as tipsModerationActions from 'store/actions/tipsModeration';

import ModeratorListPage from './ModeratorListPage';

const mapStateToProps = ({
  tips: { tips },
  tiplist: { showGoals },
  tipsModeration: {
    tipsModeration,
    tipsModerationBasket,
    forceModerationBasketModal,
    currentModerator,
  },
}) => ({
  tips,
  showGoals,
  tipsModeration,
  tipsModerationBasket,
  forceModerationBasketModal,
  currentModerator,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...tipsActions, ...tipsModerationActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModeratorListPage);
