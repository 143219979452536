import { findUserAccount } from './utils';

export const selectUserData = state => state.userData;
export const selectWithdrawals = state => state.withdrawals;
export const selectReports = state => state.reports;
export const selectInvoices = state => state.invoices;
export const selectUserAccount = (type, company) => ({ userData }) =>
  findUserAccount(userData, type, company);
export const selectAccountWithHighestBalance = ({ userData }) => {
  const { accounts } = userData;
  const activeAccounts = accounts.filter(account => account.company === 'SP');
  const highestBalance = Math.max(...activeAccounts.map(account => account.balance));
  return activeAccounts.find(account => account.balance === highestBalance && account.balance > 0);
};
export const selectStatistics = state => state.statistics;
export const selectUserArchivedAccountsAmount = ({ userData }) =>
  userData.accounts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.balance,
    0,
  );

export const bankTransferValidationDisabledSelector = store =>
  store.statistics.data.tips.count < 20;
