import styled from 'styled-components';

import { ReactComponent as SettingsIcons } from 'assets/icons/gear.svg';

const SettingsButton = styled(SettingsIcons)`
  cursor: pointer;
  margin-left: 15px;
  width: 24px;
  height: 25px;
  fill: var(--icon-gray);

  &:hover {
    fill: var(--gray);
  }
`;

export default SettingsButton;
