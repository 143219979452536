import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_7.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step11 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Przechodzimy do zakładki „Donation Page” i w niej, najważniejszym jest aby wybrać stronę
      Classic, gdyż tej potrzebujemy do realizacji płatności. Z tego miejsca będziemy musieli
      skopiować nazwę użytkownika.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      Skopiuj proszę, tylko fragment linku, który zawiera twoją nazwę użytkownika – nie użyjemy
      całego linku. Jeśli wpiszesz niepoprawną nazwę użytkownika, płatności nie będą działały na
      stronie streamera Tipply.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
    <Text lineHeight={1.58} align="center" mb={20}>
      Skopiuj jedynie nazwę użytkownika – nie link – i wpisz w następnym kroku.
    </Text>
  </StepContent>
);

export default Step11;

Step11.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
