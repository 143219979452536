import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step10 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      W rubryce „Bank Account” dodajemy odpowiednie informacje, dzięki którym będziemy mieli
      możliwość wypłacenia zarobionych środków. Środki w Tipeee są wypłacane automatycznie co
      poniedziałek, tylko na konto bankowe. Wypłata przychodzi w ciągu 1-3 dni zależnie od banku,
      gdyż jest to przelew międzynarodowy.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      Pamiętaj, że IBAN wymaga oznaczenia np. „PL” na początku numeru konta. Po uzupełnieniu danych
      bankowych, jesteśmy gotowi pójść dalej.
    </Text>
  </StepContent>
);

export default Step10;

Step10.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
