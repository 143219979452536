import styled from 'styled-components';

import TextLabel from 'components/Modal/styles/TextLabel';

import Input from 'styles/Input';

const Wrapper = styled.div`
  margin-bottom: 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  ${TextLabel} {
    margin-bottom: 15px;
  }

  ${Input} {
    &[readonly] {
      border-color: var(--brand-blue);
      user-select: none;
      cursor: pointer;
    }
  }
`;

export default Wrapper;
