import React from 'react';

import { ButtonWithAnimatedArrow } from '../../Buttons';
import Logo from '../../MenuTop/components/Logo';
import { StepPropTypes } from '../types';
import StepWrapper from '../components/StepWrapper';

export const ForcedFirstStep = ({ processStep }) => (
  <StepWrapper>
    <div className="logo-container">
      <Logo noLink />
    </div>
    <p className="step-title">Twoje konto zostało zablokowane</p>
    <p>
      Możliwość operacji na Twoim koncie została wstrzymana do czasu potwierdzenia tożsamości w
      serwisie Tipply.
    </p>
    <p>
      Prosimy o wykonanie weryfikacji tożsamości. W razie dodatkowych pytań w tej kwestii, skieruj
      swoją wiadomość, pisząc na
      <br />
      <a href="mailto:blokada@tipply.pl">blokada@tipply.pl</a>
    </p>
    <div className="button-container">
      <ButtonWithAnimatedArrow onClick={processStep}>
        Rozpocznij weryfikację
      </ButtonWithAnimatedArrow>
    </div>
  </StepWrapper>
);

ForcedFirstStep.propTypes = StepPropTypes;

export const NormalFirstStep = ({ processStep }) => (
  <StepWrapper>
    <div className="logo-container">
      <Logo noLink />
    </div>
    <p className="step-title">Uzyskaj darmową weryfikację</p>
    <p>
      Potwierdzając swoją tożsamość, natychmiast uzyskasz znaczek weryfikacji, utwierdzający Widza w
      przekonaniu, że trafił na prawidłową stronę, a w przyszłości będziesz pierwszy by uzyskać
      nadchodzące tylko dla zweryfikowanych użytkowników specjalne funkcje i możliwości serwisu!
    </p>
    <p>
      {'Uzyskanie weryfikacji jest '}
      <strong>w pełni darmowe!</strong>
    </p>
    <div className="button-container">
      <ButtonWithAnimatedArrow onClick={() => processStep()}>
        Rozpocznij weryfikację
      </ButtonWithAnimatedArrow>
    </div>
  </StepWrapper>
);

NormalFirstStep.propTypes = StepPropTypes;
