import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TextEditor } from 'components/ElementEditor';
import Odometer from 'components/Odometer';

import Shadow from 'pages/Configurators/styles/Shadow';

import DragWrapper from '../DragWrapper';
import FocusedElement from '../FocusedElement';
import ContextMenuElement from '../ContextMenuElement';

const contextMenuOptions = ['editText'];

const OdometerWrapper = styled.div`
  .odometer.odometer-theme-default.odometer-animating {
    .odometer-ribbon-inner {
      transition-duration: 1000ms !important;
    }
  }
`;

const Time = ({
  activeTemplate,
  updateElement,
  configuratorWrapper,
  configuratorType,
  forcedTextValue,
  name,
  options,
  onKeyDown,
  shadow,
  isAnimated,
  autoplayAnimation,
}) => {
  const elementRef = useRef();
  const [value, setValue] = useState(214456);
  const [intervalId, setIntervalId] = useState(null);
  const [mountedTextEditor, setMountedTextEditor] = useState({
    mounted: false,
    mode: 'style',
  });

  useEffect(() => {
    if (isAnimated && options.isAnimated) {
      setValue(prevVal => prevVal - 1);

      const id = setInterval(
        () => {
          setValue(prevVal => prevVal - 1);
        },
        autoplayAnimation ? 1000 : 1100,
      );

      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      clearInterval(intervalId);
      setIntervalId(null);
    };
  }, [isAnimated]);

  return (
    <>
      <DragWrapper
        updateElement={updateElement}
        elementOptions={options}
        elementName={name}
        configuratorType={configuratorType}
        onKeyDown={event => onKeyDown(event, name)}
      >
        <FocusedElement style={{ textAlign: options.styles.textAlign }}>
          {shadow && <Shadow style={{ ...options.styles }}>{shadow}</Shadow>}

          <ContextMenuElement
            id={`${name}-element-list`}
            elementName={name}
            options={contextMenuOptions}
            isEditable={activeTemplate.config.editable}
            zIndex={Number.parseInt(options.styles.zIndex, 10)}
            onSelectedOption={(option, config) => {
              if (option === 'editText') {
                setMountedTextEditor({ mounted: true, mode: 'style' });
              } else if (option === 'delete') {
                updateElement({ [name]: { isVisible: false } });
              } else if (option === 'zIndex') {
                updateElement(config);
              }
            }}
          >
            <OdometerWrapper
              ref={elementRef}
              style={{
                margin: 0,
                ...options.styles,
                backfaceVisibility: 'hidden',
                display: 'inline-block',
              }}
            >
              <Odometer duration={1000} format="dd:dd:dd" value={value} />
            </OdometerWrapper>
          </ContextMenuElement>
        </FocusedElement>
      </DragWrapper>

      <TextEditor
        isMounted={mountedTextEditor.mounted}
        snapTo={elementRef.current}
        defaultStyle={options.styles}
        onChange={styles => {
          updateElement({ [name]: { styles } });
        }}
        onChangeText={changedText => {
          updateElement({ [name]: { textValue: changedText } });
        }}
        onHide={() => {
          setMountedTextEditor({ mounted: false, mode: mountedTextEditor.mode });
        }}
        showIn={configuratorWrapper.current}
        mode={mountedTextEditor.mode}
        text={forcedTextValue || options.textValue || ''}
      />
    </>
  );
};

Time.propTypes = {
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  configuratorWrapper: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  onKeyDown: PropTypes.instanceOf(Function).isRequired,
  configuratorType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  forcedTextValue: PropTypes.string,
  shadow: PropTypes.string,
  isAnimated: PropTypes.bool,
  autoplayAnimation: PropTypes.bool,
};

Time.defaultProps = {
  forcedTextValue: '',
  shadow: null,
  isAnimated: false,
  autoplayAnimation: false,
};

export default Time;
