import styled from 'styled-components';

export default styled.span`
  position: relative;
  left: 2px;
  top: 1px;

  display: inline-block;
  width: 0;
  height: 0;

  border-top: 7px solid transparent;
  border-left: 11px solid var(--blue);
  border-bottom: 7px solid transparent;
`;
