import styled from 'styled-components';

import CollapsedWhiteBox from 'components/CollapsedWhiteBox';

export default styled(CollapsedWhiteBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;
  height: 310px;
`;
