const parseMessage = message => {
  let parsedMessage = message;
  const dummy = document.createElement('div');
  dummy.innerHTML = message;
  const cleanText = dummy.textContent;
  const longWords = cleanText.split(' ').filter(str => str.length >= 20);

  longWords.forEach(word => {
    parsedMessage = parsedMessage.replace(
      word,
      `<span style="word-break: break-all;">${word}</span>`,
    );
  });

  dummy.innerHTML = parsedMessage;

  return dummy.innerHTML;
};

export default parseMessage;
