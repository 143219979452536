import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'styles/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import CodeEditor from './CodeEditor';
import TabSelector from './TabSelector';

import { CODE_BOILERPLATE, TYPE_CSS, TYPE_HTML, TYPE_JS } from '../constants';

const CodeModal = ({ template, isMounted, onChange, onClose }) => {
  const { type: templateType } = template;

  const [customCode, setCustomCode] = useState(template.config.customCode || {});
  const [currentTab, setCurrentTab] = useState(TYPE_CSS);

  const [code, setCode] = useState({
    [TYPE_CSS]: '',
    [TYPE_HTML]: '',
    [TYPE_JS]: '',
  });

  useEffect(() => {
    setCustomCode(template.config.customCode || {});
  }, [template]);

  useEffect(() => {
    setCurrentTab(TYPE_CSS);
    setCode({
      [TYPE_CSS]: customCode.css || CODE_BOILERPLATE[templateType],
      [TYPE_HTML]: customCode.html || `<!-- Dodaj własny kod HTML -->`,
      [TYPE_JS]: customCode.javascript || `// Dodaj własny kod JavaScript`,
    });
  }, [customCode]);

  const onCodeChange = value => {
    setCode(prevState => ({
      ...prevState,
      [currentTab]: value,
    }));
  };

  const saveChanges = () => {
    onChange({ customCode: code });
    onClose();
  };

  return (
    <Modal isMounted={isMounted} onClose={onClose} width={900} height={700}>
      <Text size={18} weight="bold">
        Zaawansowana modyfikacja szablonu
      </Text>

      <div style={{ marginBottom: 10 }}>
        <TabSelector isActive={currentTab === TYPE_CSS} onClick={() => setCurrentTab(TYPE_CSS)}>
          CSS
        </TabSelector>
        <TabSelector isActive={currentTab === TYPE_HTML} onClick={() => setCurrentTab(TYPE_HTML)}>
          HTML
        </TabSelector>
        <TabSelector isActive={currentTab === TYPE_JS} onClick={() => setCurrentTab(TYPE_JS)}>
          JavaScript
        </TabSelector>
      </div>

      <CodeEditor code={code[currentTab]} language={currentTab} onChange={onCodeChange} />

      <Button background="var(--brand-blue)" onClick={saveChanges}>
        Zatwierdź zmiany
      </Button>
    </Modal>
  );
};

CodeModal.propTypes = {
  template: PropTypes.instanceOf(Object).isRequired,
  isMounted: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CodeModal;
