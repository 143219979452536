export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_TIPS_MODERATION':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'REQUEST_TIPS_MODERATION_BASKET':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'RECEIVE_TIP_MODERATION':
      state.tipsModeration.unshift(action.tipModeration);
      return {
        ...state,
        tipsModeration: state.tipsModeration,
      };
    case 'RECEIVE_TIPS_MODERATION':
      return {
        ...state,
        failureFetching: false,
        isFetching: false,
        isFetched: true,
        tipsModeration: action.tipsModeration,
      };
    case 'RECEIVE_TIPS_MODERATION_BASKET':
      return {
        ...state,
        failureFetching: false,
        isFetching: false,
        isFetched: true,
        tipsModerationBasket: action.tipsModerationBasket,
      };
    case 'RECEIVE_CURRENT_MODERATOR_DATA':
      return {
        ...state,
        currentModerator: action.currentModerator,
      };
    case 'APPROVE_TIPS_MODERATION':
      return {
        ...state,
        tipsModeration: state.tipsModeration.filter(basket => !action.mid.includes(basket.id)),
        failureApproving: false,
        isApproving: true,
      };
    case 'REJECT_TIPS_MODERATION':
      return {
        ...state,
        tipsModerationBasket: [
          ...state.tipsModerationBasket,
          state.tipsModeration.filter(basket => action.mid.includes(basket.id))[0],
        ],
        tipsModeration: state.tipsModeration.filter(basket => !action.mid.includes(basket.id)),
        failureRejecting: false,
        isRejecting: true,
      };
    case 'RESTORE_TIPS_MODERATION':
      return {
        ...state,
        tipsModeration: [
          ...state.tipsModeration,
          state.tipsModerationBasket.filter(basket => action.mid.includes(basket.id))[0],
        ],
        tipsModerationBasket: state.tipsModerationBasket.filter(
          basket => !action.mid.includes(basket.id),
        ),
        failureRejecting: false,
        isRestoring: true,
      };
    case 'FAILURE_TIPS_MODERATION':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'FAILURE_TIPS_MODERATION_BASKET':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'DISPLAY_MODERATION_BASKET_MODAL':
      return {
        ...state,
        forceModerationBasketModal: true,
      };
    case 'HIDE_MODERATION_BASKET_MODAL':
      return {
        ...state,
        forceModerationBasketModal: false,
      };
    default:
      return state;
  }
};
