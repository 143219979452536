import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Item, Submenu } from 'react-contexify';

import ConfigContext from '../../../ConfigContext';
import AnimationsContextMenuList from '../../../components/AnimationsContextMenuList';

const ScreenContextMenu = props => {
  const { showElement, resetElementPosition } = props;
  const { elementConfigs, hiddenElements, handleSelectAlertAnimation } = useContext(ConfigContext);

  return (
    <Menu id="screen_contextMenu">
      <Submenu label="Dodaj element" disabled={() => !hiddenElements.length}>
        {hiddenElements.map(element => (
          <Item key={element.key} onClick={() => showElement(element.key)}>
            {element.name}
          </Item>
        ))}
      </Submenu>

      <Submenu label="Zresetuj pozycję">
        {Object.keys(elementConfigs).map(elementName => (
          <Item key={elementName} onClick={() => resetElementPosition(elementName)}>
            {elementConfigs[elementName].title}
          </Item>
        ))}
      </Submenu>

      <AnimationsContextMenuList
        label="Animacja dla wszystkich"
        onAnimationSelect={({ direction, animation }) =>
          handleSelectAlertAnimation(direction, animation)
        }
      />
    </Menu>
  );
};

ScreenContextMenu.propTypes = {
  resetElementPosition: PropTypes.instanceOf(Function).isRequired,
  showElement: PropTypes.instanceOf(Function).isRequired,
};

export default ScreenContextMenu;
