import React from 'react';

import LogoModeratorPanel from './components/LogoModeratorPanel';
import ModeratorMenu from './components/ModeratorMenu';
import StyledMenu from './styles/StyledMenu';

const ModeratorTop = moderatorId => (
  <>
    <div style={{ paddingTop: 'var(--menu-height)' }} />
    <StyledMenu>
      <div className="app-container menu-bar">
        <LogoModeratorPanel moderatorId={moderatorId} />
        <ModeratorMenu moderatorId={moderatorId} />
      </div>
    </StyledMenu>
  </>
);

export default ModeratorTop;
