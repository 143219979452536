import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TipModeration from './TipModeration';

class TipsModerationList extends Component {
  static propTypes = {
    tipsModeration: PropTypes.instanceOf(Object).isRequired,
    showGoals: PropTypes.bool.isRequired,
    approveTipModeration: PropTypes.instanceOf(Function).isRequired,
    rejectTipModeration: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
    fetchTipsForTipsListPage: PropTypes.instanceOf(Function).isRequired,
    paginationItemsCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
  };

  render() {
    const {
      tipsModeration,
      showGoals,
      approveTipModeration,
      rejectTipModeration,
      fetchTipsModerationForTipsListPage,
      fetchTipsForTipsListPage,
      paginationItemsCount,
      page,
      fetchTipsModerationBasketForModerators,
    } = this.props;

    return tipsModeration.map(modtip => (
      <TipModeration
        createdAt={modtip.created_at}
        nickname={modtip.nickname}
        email={modtip.email}
        id={modtip.id}
        key={modtip.id}
        message={modtip.message}
        amount={modtip.amount}
        goalTitle={modtip.goal_title ? modtip.goal_title : 'Cel domyślny'}
        audioUrl={modtip.audio_url ? modtip.audio_url : ''}
        paymentType={modtip.payment_method ? modtip.payment_method : ''}
        showGoals={showGoals}
        approveTipModeration={approveTipModeration}
        rejectTipModeration={rejectTipModeration}
        paginationItemsCount={paginationItemsCount}
        page={page}
        fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
        fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
        fetchTipsForTipsListPage={fetchTipsForTipsListPage}
      />
    ));
  }
}

export default TipsModerationList;
