import React from 'react';
import PropTypes from 'prop-types';

import BlankList from 'styles/BlankList';

import ListItem from './ListItem';

const List = ({ items }) => (
  <BlankList>
    {items.map(({ id, config: { title } }) => (
      <ListItem key={id} id={id} title={title} />
    ))}
  </BlankList>
);

List.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default List;
