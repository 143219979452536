const deleteTip = (id, items) => items.filter(item => item.id !== id);

export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_TIPS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'RECEIVE_TIP':
      state.tips.unshift({
        ...action.tip,
        fromSocket: true,
      });
      return {
        ...state,
        tips: state.tips,
      };
    case 'RECEIVE_TIPS':
      return {
        ...state,
        failureFetching: false,
        isFetching: false,
        isFetched: false, // tip163 -> false = always fetch

        tips: action.tips,
      };
    case 'RESEND_TIP':
      return {
        ...state,
        failureResending: false,
        isResending: true,
      };
    case 'TIP_RESENT':
      return {
        ...state,
        failureResending: false,
        isResending: false,
        isResent: true,
      };
    case 'FAILURE_RESEND_TIP':
      return {
        ...state,
        failureResending: true,
        isResending: false,
        isResent: false,
      };
    case 'DELETE_TIP':
      return {
        ...state,
        failureDeleting: false,
        isDeleting: true,
        isDeleted: false,
      };
    case 'TIP_DELETED':
      return {
        ...state,
        failureDeleting: false,
        isDeleting: false,
        isDeleted: true,

        tips: deleteTip(action.id, state.tips),
      };
    case 'FAILURE_DELETE_TIP':
      return {
        ...state,
        failureDeleting: true,
        isDeleting: false,
        isDeleted: false,
      };

    default:
      return state;
  }
};
