import React, { useContext } from 'react';

import { ReactComponent as IconBold } from 'assets/icons/bold.svg';
import TextEditorContext from '../Context';
import { Option, OptionIcon, AnimationToggleOption } from '../styles';

const FontWeight = props => {
  const {
    style: { fontWeight },
    updateStyle,
    isNotStyle,
  } = useContext(TextEditorContext);

  const isBold = fontWeight === 900;

  const handleClick = () => {
    const value = isBold ? 400 : 900;
    updateStyle('fontWeight', value, true);
  };

  return (
    <Option onClick={handleClick} {...props}>
      <AnimationToggleOption isInactive={isNotStyle} delayOut={0.3}>
        <OptionIcon isActive={isBold}>
          <IconBold />
        </OptionIcon>
      </AnimationToggleOption>
    </Option>
  );
};

export default FontWeight;
