export default (state = [], action) => {
  switch (action.type) {
    case 'CHANGE_BACKGROUND': {
      localStorage.setItem('configurator-background', action.background);

      return {
        ...state,
        active: action.background,
      };
    }
    default:
      return state;
  }
};
