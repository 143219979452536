import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/pl';

import store from 'store';

import { ArrowInCircleButton } from 'components/Buttons';
import FloatingMenu from 'components/FloatingMenu';
import OptionsPopup from 'styles/OptionsPopup';

import '../../styles/datetime.scss';
import noEditableAlert from '../../lib/noEditableAlert';
import Box from '../../styles/Box';

import DatePickerWrapper from '../DatePickerWrapper';

import OptionsPopupItem from './styles/Item';
import Goalpart from './styles/goalpart';

const optionsToSelect = [
  { type: 'LAST_DAY', title: 'Ranking z dzisiaj' },
  { type: 'LAST_WEEK', title: 'Ranking z tego tygodnia' },
  { type: 'LAST_MONTH', title: 'Ranking z tego miesiąca' },
  { type: 'LAST_YEAR', title: `Ranking z ${moment().year()} r.` },
  { type: 'ALL', title: 'Wyświetlaj wszystkie' },
];

const Wrapper = styled.div`
  position: relative;
`;

export default class ChooseDate extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Object).isRequired,
    isEditable: PropTypes.bool.isRequired,
    onSelectedValue: PropTypes.instanceOf(Function),
  };

  static defaultProps = {
    onSelectedValue: value => {},
  };

  static getDerivedStateFromProps(props, state) {
    const returnedState = {};

    if (!props.isEditable) {
      returnedState.visibility = {
        options: false,
        calendar: false,
      };
    }

    return returnedState;
  }

  node = React.createRef();

  state = {
    visibility: {
      options: false,
      calendar: false,
    },
  };

  /**
   * Funkcja zarzązająca widocznością popupów
   *
   * @param {Boolean} forceValue - wartość która wymusza stan
   * @returns {void}
   */
  setVisible(config) {
    const { isEditable } = this.props;

    if (!isEditable) {
      noEditableAlert();
      return;
    }

    this.setState(prevState => {
      const returnedState = {
        ...prevState.visibility,
        ...config,
      };

      if (config.options && config.options === 'toggle') {
        returnedState.options = !prevState.visibility.options;
      }

      return { visibility: returnedState };
    });
  }

  onChangeDate = (momentDateObject, test) => {
    const { onSelectedValue } = this.props;

    onSelectedValue({
      type: 'FROM_DAY',
      value: moment(momentDateObject).format(),
    });
  };

  render() {
    const { text, onSelectedValue, value } = this.props;
    const { visibility } = this.state;
    const { goals } = store.getState();

    return (
      <Wrapper ref={this.node}>
        <Box style={{ paddingTop: 12.5, paddingBottom: 12.5 }}>
          <span>{text}</span>

          <ArrowInCircleButton
            background="green"
            onClick={() =>
              this.setVisible({ calendar: false, options: visibility.calendar ? false : 'toggle' })
            }
          />
        </Box>

        <FloatingMenu
          visible={visibility.options}
          position="right"
          onClose={() => this.setVisible({ options: false })}
          parent={this.node.current || document.body}
        >
          <OptionsPopup>
            {optionsToSelect.map(item => (
              <OptionsPopupItem
                edited={value.type === item.type}
                key={item.type}
                onClick={() => onSelectedValue({ type: item.type, value: null, goal: '' })}
              >
                {item.title}
                <span>UŻYWANE</span>
              </OptionsPopupItem>
            ))}
            <OptionsPopupItem
              onClick={() => {
                this.setVisible({ calendar: true, options: false });
                onSelectedValue({ goal: '' });
              }}
              edited={value.type === 'FROM_DAY'}
            >
              Wyświetlaj od daty
              <span>UŻYWANE</span>
            </OptionsPopupItem>

            <Goalpart>Wyświetlaj według celu:</Goalpart>

            {goals.items.map(item => (
              <OptionsPopupItem
                edited={value.goal === item.id && value.type === 'FROM_GOAL'}
                key={item.id}
                onClick={() =>
                  onSelectedValue({ type: 'FROM_GOAL', value: item.count_from, goal: item.id })
                }
              >
                {item.title}
                <span>UŻYWANE</span>
              </OptionsPopupItem>
            ))}
          </OptionsPopup>
        </FloatingMenu>

        <FloatingMenu
          visible={visibility.calendar}
          position="right"
          onClose={() => this.setVisible({ calendar: false })}
          parent={this.node.current || document.body}
        >
          <OptionsPopup>
            <DatePickerWrapper>
              <Datetime
                value={value.value ? new Date(value.value) : new Date()}
                onChange={this.onChangeDate}
                timeFormat="HH:mm"
                open
                input={false}
                locale="pl"
              />
            </DatePickerWrapper>
          </OptionsPopup>
        </FloatingMenu>
      </Wrapper>
    );
  }
}
