import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';
import { ReactComponent as OkCheck } from 'assets/icons/ok-check.svg';

import { MIN_TABLET } from 'styles/base/breakpoints';

import ModeratorStatus from '../styles/ModeratorStatus';
import StyledModeratorBar from '../styles/StyledModeratorBar';

import { BlueDot } from './elements/BlueDot';
import ButtonCap from './elements/ButtonCap';
import { GreenDot } from './elements/GreenDot';
import { OrangeDot } from './elements/OrangeDot';

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: flex-start;
  align-items: flex-start;
  @media (${MIN_TABLET}) {
    flex-direction: row;
  }
`;

function ModeratorBar() {
  const moderationMode = useSelector(state => state.userData.info.moderation_mode);

  return (
    <StyledModeratorBar className="box">
      <Legend>
        <ButtonCap disabled type="button" background="gray" noWrapper>
          <GreenDot />
          <span>WYŚWIETLONE</span>
        </ButtonCap>
        <ButtonCap disabled type="button" background="gray" noWrapper>
          <OrangeDot />
          <span>W KOLEJCE WIADOMOŚCI</span>
        </ButtonCap>
        <ButtonCap disabled type="button" background="gray" noWrapper>
          <BlueDot />
          <span>OCZEKUJE NA MODERACJĘ</span>
        </ButtonCap>
      </Legend>

      {moderationMode ? (
        <ModeratorStatus>
          <OkCheck fill="var(--brand-green)" />
          <span>MODERACJA AKTYWNA</span>
        </ModeratorStatus>
      ) : (
        <ModeratorStatus>
          <CloseIcon fill="var(--error-color)" />
          <span>MODERACJA NIEAKTYWNA</span>
        </ModeratorStatus>
      )}
    </StyledModeratorBar>
  );
}

export default ModeratorBar;
