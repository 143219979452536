import { hop } from 'utils';

/**
 * @param {object} elements
 * @param {array} order
 */
const mapObjectElementsToArray = (elements, order = []) =>
  order
    .map(elementKey => {
      if (hop(elements, elementKey)) {
        return [elementKey, elements[elementKey]];
      }

      return null;
    })
    .filter(Boolean);

export default mapObjectElementsToArray;
