import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestExtraAnnouncements = () => ({
  type: 'REQUEST_EXTRAANNOUNCEMENTS',
});

const patchExtraAnnouncements = () => ({
  type: 'PATCH_EXTRAANNOUNCEMENTS',
});

const receiveExtraAnnouncements = extraAnnouncements => ({
  type: 'RECEIVE_EXTRAANNOUNCEMENTS',
  extraAnnouncements,
});

const failureExtraAnnouncements = () => ({
  type: 'FAILURE_EXTRAANNOUNCEMENTS',
});

const failurePatchExtraAnnouncements = () => ({
  type: 'FAILURE_PATCH_EXTRAANNOUNCEMENTS',
});

const fetchExtraAnnouncements = () => dispatch => {
  dispatch(requestExtraAnnouncements());

  return http
    .get('/extraannouncements')
    .then(response => response.data)
    .then(extraAnnouncements => dispatch(receiveExtraAnnouncements(extraAnnouncements)))
    .catch(error => failureExtraAnnouncements());
};

export const fetchExtraAnnouncementsIfNeeded = () => (dispatch, getState) => {
  const { extraAnnouncements } = getState();

  if (shouldFetchData(extraAnnouncements)) {
    dispatch(fetchExtraAnnouncements());
  }
};

export const markExtraAnnouncementsAsRead = id => (dispatch, getState) => {
  const {
    extraAnnouncements: { items },
  } = getState();
  const isMoreEA = items.filter(item => item.id !== id.eaId);

  dispatch(receiveExtraAnnouncements(isMoreEA));
  dispatch(patchExtraAnnouncements());

  http
    .patch(`/extraannouncements/read?id=${id.eaId}`)
    .catch(error => dispatch(failurePatchExtraAnnouncements()));
};
