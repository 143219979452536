import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CodeModal from './components/CodeModal';

import SettingsButton from './components/SettingsButton';

const CustomCode = ({ template, onChange }) => {
  const [openDialog, setOpenDialog] = useState(false);

  if (!template.config.editable) {
    return null;
  }

  return (
    <>
      <SettingsButton onClick={() => setOpenDialog(true)} />

      <CodeModal
        template={template}
        onChange={onChange}
        onClose={() => setOpenDialog(false)}
        isMounted={openDialog}
      />
    </>
  );
};

CustomCode.propTypes = {
  template: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default CustomCode;
