import styled from 'styled-components';

export default styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 12.5px;
  border-radius: 20px;

  background-color: var(--bg-color-capsule);
  transition: background-color 0.2s ease;

  height: 50px;

  > * {
    margin: 0 7.5px;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 0.8;
  }

  &:hover {
    background-color: var(--bg-hover-capsule);
  }
`;
