import React, { useContext } from 'react';

import { ReactComponent as AlignIcon } from 'assets/icons/align-center.svg';
import { TextEditor } from '../../Context';
import { Option, OptionIcon, AbsoluteLayer, AnimationToggleOption } from '../../styles';

import AlignIcons from './components/AlignIcons';

const TYPE = 'textAlign';

const TextAlign = props => {
  const {
    style: { textAlign },
    updateStyle,
    isActive,
    isActiveEditor,
  } = useContext(TextEditor);

  return (
    <Option {...props} style={{ marginTop: 2 }}>
      <AnimationToggleOption isActive={!!isActiveEditor} delayOut={0.1}>
        <OptionIcon isActive>
          <AlignIcon />
        </OptionIcon>
      </AnimationToggleOption>

      <AbsoluteLayer isCentered isActive={isActive(TYPE)} style={{ zIndex: 5 }}>
        <AlignIcons
          currentAlign={textAlign}
          updateStyle={direction => updateStyle(TYPE, direction, true)}
        />
      </AbsoluteLayer>
    </Option>
  );
};

export default TextAlign;
