import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WizardStatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .bg {
    opacity: 0.2;
    border-radius: 16px;
    background-image: linear-gradient(to bottom, #0f78e2, #05217a);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .label {
    padding: 11px 0 11px 26px;
    color: var(--white-white);
    z-index: 1;
  }

  .steps {
    border-radius: 16px;
    background-color: #f3f5f4;
    padding: 11px 26px;
    z-index: 1;
    font-weight: bold;
    color: #0b58bb;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 12px;

  .bar {
    height: 3px;
    background: var(--white-white);
    border-radius: 1.5px;
    min-width: 10px;
  }
`;

const WizardProgress = ({ step, total }) => (
  <div className="wizard-progress">
    <ProgressBar className="wizard-progress__bar">
      <div className="bar" style={{ flex: step }} />
      <div className="bar" style={{ flex: total - step }} />
    </ProgressBar>
    <WizardStatsWrapper className="wizard-progress__stats-wrapper">
      <div className="bg" />
      <div className="label">Postęp</div>
      <div className="steps">
        {step}
        {' / '}
        {total}
      </div>
    </WizardStatsWrapper>
  </div>
);

export default WizardProgress;

WizardProgress.propTypes = {
  step: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
