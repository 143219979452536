import styled from 'styled-components';

const CancelButton = styled.button`
  display: block;
  position: absolute;

  border: 0;
  padding: 10px;
  background: none;

  font-size: 14px;
  color: #fff;

  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  transform: translate(-50%, 100%);
  bottom: 0;
  left: 50%;

  margin: 0;
`;

export default CancelButton;
