import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dateFormatter from 'utils/dateFormatter';
import { selectReports, selectInvoices, selectUserData } from 'store/selector';
import { fetchReportsIfNeeded } from 'store/actions/reports';
import { fetchInvoicesIfNeeded } from 'store/actions/invoices';

import Text from 'components/Text';
import Grid from 'components/Grid';
import Bill from './Bill';

const Documents = () => {
  const dispatch = useDispatch();
  const { items: reports } = useSelector(selectReports);
  const { items: invoices } = useSelector(selectInvoices);
  const { profile } = useSelector(selectUserData);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    dispatch(fetchReportsIfNeeded());
    if (profile.is_company) {
      dispatch(fetchInvoicesIfNeeded());
    }
  }, [profile.is_company]);

  useEffect(() => {
    setDocuments(
      [
        ...invoices.map(invoice => ({
          type: 'invoice',
          number: invoice.invoice_number,
          date: new Date(invoice.issued_at),
          isDownloadable: invoice.is_downloadable,
          id: invoice.id,
        })),
        ...reports.map(report => ({
          type: 'report',
          number: report.report_number,
          date: new Date(report.generated_at),
          isDownloadable: report.is_downloadable,
          id: report.id,
        })),
      ].sort((a, b) => b.date - a.date),
    );
  }, [invoices, reports]);

  if (documents.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Text noMargin color="blue">
        ZESTAWIENIA MIESIĘCZNE
      </Text>
      <Grid>
        {documents.map(doc => (
          <Bill
            key={doc.id}
            date={dateFormatter.format(doc.date, 'DD/MM/YYYY')}
            number={doc.number}
            docType={doc.type === 'invoice' ? 'Faktura' : 'Rachunek miesięczny SMS'}
            monthPeriod={dateFormatter.format(doc.date, 'MM/YYYY')}
            pdf={`${process.env.REACT_APP_PROXY_URL}/user/${
              doc.type === 'invoice' ? 'invoices' : 'reports'
            }/${doc.id}/pdf`}
            pdfEnabled={doc.isDownloadable}
          />
        ))}
      </Grid>
      <hr />
    </React.Fragment>
  );
};

export default Documents;
