import TIP_ALERT_BOILERPLATE from './boilerplates/TIP_ALERT.plate';
import LIST_DONATES_BOILERPLATE from './boilerplates/LIST_DONATES.plate';
import TIPS_GOAL_BOILERPLATE from './boilerplates/TIPS_GOAL.plate';
import COUNTER_TO_END_LIVE_BOILERPLATE from './boilerplates/COUNTER_TO_END_LIVE.plate';
import GOAL_VOTING_BOILERPLATE from './boilerplates/GOAL_VOTING.plate';

export const CODE_BOILERPLATE = {
  TIP_ALERT: TIP_ALERT_BOILERPLATE,
  LATEST_DONATES: LIST_DONATES_BOILERPLATE,
  LARGEST_DONATES: LIST_DONATES_BOILERPLATE,
  TIPS_GOAL: TIPS_GOAL_BOILERPLATE,
  COUNTER_TO_END_LIVE: COUNTER_TO_END_LIVE_BOILERPLATE,
  GOAL_VOTING: GOAL_VOTING_BOILERPLATE,
};

export const TYPE_CSS = 'css';
export const TYPE_HTML = 'html';
export const TYPE_JS = 'javascript';
