import styled from 'styled-components';

import GrayButtonContainer from 'styles/buttons/GrayContainer';
import hover from 'styles/buttons/CircleIcon/hover';

import Button from './Button';

export default styled(GrayButtonContainer)`
  cursor: pointer;

  &:hover {
    ${Button} {
      ${hover};
    }
  }
`;
