import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 5px;
  width: 40px;
  height: 40px;

  transition: all 0.25s ease;

  &:not(:disabled) {
    &:hover {
      transform: scale(1.2) rotate(-10deg);
    }
  }

  > a {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 100%;
    z-index: 2;
  }
`;
