const elementProps = {
  from: {
    textValue: '523,00 zł',
    menuOptions: ['editText', 'delete'],
    shadow: '10 000,00 zł',
  },
  separator: {
    menuOptions: ['editText', 'changeText', 'delete'],
    shadow: null,
    textValue: '/',
  },
  to: {
    menuOptions: ['editText', 'delete'],
    shadow: '10 000,00 zł',
  },
  percent: {
    textValue: '(98%)',
    menuOptions: ['editText', 'delete'],
    shadow: '(99.99%)',
  },
};

export default elementProps;
