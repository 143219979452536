import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SimpleCheckbox } from 'components/Checkboxes';

const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

const MethodStateSwitch = ({ enabled, onChange, color = 'green' }) => (
  <SwitcherContainer>
    <SimpleCheckbox
      apperance="switch"
      color={color}
      name={String(Math.random())}
      checked={enabled}
      onCheck={value => onChange('enabled', value)}
    />
  </SwitcherContainer>
);

export default MethodStateSwitch;

MethodStateSwitch.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  color: PropTypes.string,
};

MethodStateSwitch.defaultProps = {
  color: 'green',
};
