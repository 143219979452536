import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import Text from 'components/Text';
import { HoverTooltip } from './HoverTooltip';

const Input = styled(NumberFormat)`
  font-size: 18px;
  font-weight: 700;
  color: var(--text-color-blue);
  background: var(--bg-color-text-input);
  max-width: 90px;
  border: 0;
  margin: 0;
`;

const MinAmount = ({ value, onChange, hoverText, ...props }) => (
  <div {...props}>
    <Text size={16} mb={5}>
      od kwoty:
    </Text>
    <Input
      decimalSeparator=","
      value={value}
      decimalScale={2}
      allowEmptyFormatting
      allowNegative={false}
      suffix=" PLN"
      isAllowed={({ floatValue }) => floatValue >= 1}
      onValueChange={({ floatValue }) => {
        onChange('minimalAmount', floatValue * 100);
      }}
    />
    {hoverText && (
      <HoverTooltip
        content={<Text margin="0" dangerouslySetInnerHTML={{ __html: hoverText }} />}
        placement="top"
        theme="light"
        trigger="mouseenter"
      />
    )}
  </div>
);

export default MinAmount;

MinAmount.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  hoverText: PropTypes.string.isRequired,
};
