import styled from 'styled-components';

import Button from 'styles/Button';

const TabSelector = styled(Button).attrs(props => {
  if (props.isActive) {
    return {
      ...props,
      background: 'var(--brand-blue)',
      color: 'var(--text-color-white)',
    };
  }
  return props;
})`
  color: ${({ color }) => color || 'var(--text-color)'};

  &:hover {
    color: var(--text-color-white);
  }
`;

export default TabSelector;
