import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const SingleFileUploadButtonInput = styled.input`
  display: block;
  border: 1px solid var(--border-color);
  border-radius: var(--block-border-radius);
  padding: 14px;
  width: 90%;
  cursor: pointer;

  &::file-selector-button {
    font-family: var(--google-sora-cdn);
    font-weight: bold;
    font-size: 12px;
    appearance: none;
    color: var(--white-white);
    background-color: var(--blue-secondary);
    padding: 12px 26px;
    border-radius: var(--block-border-radius);
    border: 0;
    margin-right: 12px;

    &:hover {
      background-color: var(--blue-darker);
    }
  }
`;

const SingleFileUploadButton = ({ accept = 'application/pdf', ...props }) => (
  <div className="single-file-input">
    <SingleFileUploadButtonInput type="file" accept={accept} {...props} />
  </div>
);

SingleFileUploadButton.propTypes = {
  accept: PropTypes.string,
};

SingleFileUploadButton.defaultProps = {
  accept: 'application/pdf',
};

export default SingleFileUploadButton;
