import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 0;
  border-radius: ${({ reverse }) => (reverse ? '10px 10px 20px 20px' : '20px 20px 10px 10px')};
  box-shadow: 0 0 0 1px var(--border-color) inset;
  background-color: var(--white);
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
`;

const Text = styled.div`
  padding: 15px 30px;
  text-align: center;
  color: var(--text-color);

  > p {
    margin: 0 0 12.5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const RoundedInfoWrapper = ({ children, info, reverse }) => (
  <Wrapper reverse={reverse}>
    <Text>{info}</Text>
    {children}
  </Wrapper>
);

RoundedInfoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  info: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
};

RoundedInfoWrapper.defaultProps = {
  reverse: false,
};
