import styled from 'styled-components';

const Title = styled.h2`
  font-family: var(--lato-font);
  font-weight: 900;

  margin: 0 0 25px;
`;

export default Title;
