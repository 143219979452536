import React from 'react';
import PropTypes from 'prop-types';

import AlignIcon from './AlignIcon';

import Line from '../styles/Line';
import Ghost from '../styles/Ghost';
import Wrapper from '../styles/Wrapper';

const AlignIcons = props => {
  const { updateStyle, currentAlign } = props;

  return (
    <Wrapper>
      <AlignIcon
        align="left"
        margin
        style={{ marginLeft: 0 }}
        onClick={() => updateStyle('left')}
      />
      <AlignIcon align="center" margin onClick={() => updateStyle('center')} />
      <AlignIcon
        align="right"
        margin
        style={{ marginRight: 0 }}
        onClick={() => updateStyle('right')}
      />

      <Ghost align={currentAlign}>
        <Line black />
        <Line black />
        <Line short black />
      </Ghost>
    </Wrapper>
  );
};

AlignIcons.propTypes = {
  updateStyle: PropTypes.instanceOf(Function).isRequired,
  currentAlign: PropTypes.string.isRequired,
};

export default AlignIcons;
