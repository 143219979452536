import React from 'react';
import PropTypes from 'prop-types';

import BlankList from 'styles/BlankList';

import ListItem from './ListItem';

const ListSort = ({ controls, items }) => (
  <BlankList>
    {items
      .sort((a, b) => (a.config.title > b.config.title ? 1 : -1))
      .map(({ id, config: { title } }) => (
        <ListItem key={id} id={id} title={title} controls={controls} />
      ))}
  </BlankList>
);

ListSort.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  controls: PropTypes.bool,
};

ListSort.defaultProps = {
  controls: false,
};

export default ListSort;
