import styled from 'styled-components';

import { MIN_TABLET } from 'styles/base/breakpoints';

export default styled.div`
  &.box {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    background-color: var(--white);
    @media (${MIN_TABLET}) {
      padding: 7px 60px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
