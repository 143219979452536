import styled from 'styled-components';

export const GradientText = styled.span.attrs(({ startColor, endColor }) => ({
  startColor: startColor || 'var(--orange-red)',
  endColor: endColor || 'var(--yellow)',
}))`
  font-weight: bold;
  background: linear-gradient(
    90deg,
    ${({ startColor }) => startColor},
    ${({ endColor }) => endColor}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
