import styled, { css } from 'styled-components';

import BlankButton from './BlankButton';

const optionActive = css`
  transform: translateX(50%);
  opacity: 0;
  transition-delay: 0s;
`;

export default styled(BlankButton)`
  transition-delay: ${props => props.delayOut}s;
  ${props => props.isInactive && optionActive};

  transition: var(--hover-transition);
  &:active {
    transform: scale(0.96);
  }
`;
