import styled from 'styled-components';

export const ToolsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;
