import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as tipsActions from 'store/actions/tips';
import * as tipsModerationActions from 'store/actions/tipsModeration';
import * as tiplist from 'store/actions/tiplist';

import TipsListPage from './TipsListPage';

const mapStateToProps = ({
  tips: { tips },
  moderators: { items },
  userData: { info },
  tiplist: { showGoals },
  tipsModeration: { tipsModeration, tipsModerationBasket, forceModerationBasketModal },
}) => ({
  tips,
  items,
  info,
  showGoals,
  tipsModeration,
  tipsModerationBasket,
  forceModerationBasketModal,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...tipsActions, ...tipsModerationActions, ...tiplist }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TipsListPage);
