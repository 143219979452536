import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useCopyToClipboard } from 'utils/hooks';

import FloatingMenu from 'components/FloatingMenu';
import { Tooltip } from 'components/Tooltip';

import CopiedText from 'styles/CopiedText';
import OptionsPopup from 'styles/OptionsPopup';

import OptionsTitle from './styles/OptionsTitle';
import OptionsPopupItem from './styles/Item';
import Separator from './styles/Separator';
import Wrapper from './styles/Wrapper';
import Button from './styles/Button';

const mapStateToProps = ({ userData }) => ({ userData });

const WidgetUrl = ({ templates, configuratorType, userData }) => {
  const node = useRef(null);
  const [visibilityPopup, setVisibilityPopup] = useState(false);
  const [copied, copyLink] = useCopyToClipboard();
  const startUrl = `${process.env.REACT_APP_WIDGETS_URL}/${configuratorType}/${userData.info.id}`;

  return (
    <Wrapper
      ref={node}
      onClick={() => {
        if (templates.length > 0) {
          setVisibilityPopup(!visibilityPopup);
        } else {
          copyLink(startUrl);
        }
      }}
      copied={copied}
    >
      <span>URL Widgetu</span>

      <div className="p-relative">
        <CopiedText copied={copied}>Skopiowano!</CopiedText>
        <Button active={copied} background="blue" type="button" style={{ padding: '0 12px' }}>
          Skopiuj
        </Button>

        {templates.length > 0 && (
          <FloatingMenu
            visible={visibilityPopup}
            position="top-left"
            onClose={() => setVisibilityPopup(false)}
            parent={node.current || document.body}
          >
            <OptionsPopup>
              <OptionsTitle>Skopiuj link do szablonu</OptionsTitle>
              <Separator />

              {templates.map(template => (
                <OptionsPopupItem
                  key={template.id}
                  onClick={() => {
                    copyLink(`${startUrl}/${template.id}`);
                  }}
                >
                  {template.config.title}
                </OptionsPopupItem>
              ))}
            </OptionsPopup>
          </FloatingMenu>
        )}
      </div>
    </Wrapper>
  );
};

WidgetUrl.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
  templates: PropTypes.instanceOf(Array),
  configuratorType: PropTypes.string.isRequired,
};

WidgetUrl.defaultProps = {
  templates: [],
};

export const DisabledWidgetUrl = ({ message }) => (
  <Wrapper>
    <span>URL Widgetu</span>
    <div className="p-relative">
      <Tooltip content={message} placement="top" theme="dark-theme" trigger="click">
        <div>
          <Button background="blue" style={{ padding: '0 12px' }}>
            Skopiuj
          </Button>
        </div>
      </Tooltip>
    </div>
  </Wrapper>
);

DisabledWidgetUrl.propTypes = {
  message: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  {},
)(WidgetUrl);
