import DEFAULT_MEDIUM_IMAGE_1 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_1.png';
import DEFAULT_MEDIUM_IMAGE_2 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_2.gif';
import DEFAULT_MEDIUM_IMAGE_3 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_3.gif';
import DEFAULT_MEDIUM_IMAGE_4 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_4.gif';
import DEFAULT_MEDIUM_IMAGE_5 from 'assets/defaultMedia/images/DEFAULT_MEDIUM_IMAGE_5.gif';
import DEFAULT_MEDIUM_VIDEO_1 from 'assets/defaultMedia/videos/DEFAULT_MEDIUM_VIDEO_1.webm';
import DEFAULT_MEDIUM_AUDIO_1 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_1.mpga';
import DEFAULT_MEDIUM_AUDIO_2 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_2.mpga';
import DEFAULT_MEDIUM_AUDIO_3 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_3.mpga';
import DEFAULT_MEDIUM_AUDIO_4 from 'assets/defaultMedia/audios/DEFAULT_MEDIUM_AUDIO_4.mpga';

export default [
  {
    id: 'DEFAULT_MEDIUM_IMAGE_1',
    name: 'domyślny obrazek #1',
    default: true,
    content_type: 'image/png',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_IMAGE_1,
        provider_name: 'sonata.media.provider.image',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_IMAGE_2',
    name: 'domyślny obrazek #2',
    default: true,
    content_type: 'image/gif',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_IMAGE_2,
        provider_name: 'sonata.media.provider.image',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_IMAGE_3',
    name: 'domyślny obrazek #3',
    default: true,
    content_type: 'image/gif',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_IMAGE_3,
        provider_name: 'sonata.media.provider.image',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_IMAGE_4',
    name: 'domyślny obrazek #4',
    default: true,
    content_type: 'image/gif',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_IMAGE_4,
        provider_name: 'sonata.media.provider.image',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_IMAGE_5',
    name: 'domyślny obrazek #5',
    default: true,
    content_type: 'image/gif',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_IMAGE_5,
        provider_name: 'sonata.media.provider.image',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_VIDEO_1',
    name: 'domyślny plik video #1',
    default: true,
    content_type: 'video/webm',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_VIDEO_1,
        provider_name: 'sonata.media.provider.video',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_AUDIO_1',
    name: 'Domyślny plik audio #1.mp3',
    default: true,
    content_type: 'audio/mpeg',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_AUDIO_1,
        provider_name: 'sonata.media.provider.user_audio',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_AUDIO_2',
    name: 'Domyślny plik audio #2 - SAINt JHN - ROSES (Imanbek Remix).mp3',
    default: true,
    content_type: 'audio/mpeg',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_AUDIO_2,
        provider_name: 'sonata.media.provider.user_audio',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_AUDIO_3',
    name: 'Domyslny plik audio #3 - Major Lazer - Que Calor (feat. J Balvin & El Alfa).mp3',
    default: true,
    content_type: 'audio/mpeg',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_AUDIO_3,
        provider_name: 'sonata.media.provider.user_audio',
      },
    },
  },
  {
    id: 'DEFAULT_MEDIUM_AUDIO_4',
    name: 'Domyślny plik audio #4 - C-Bool, Isabelle - Body & Soul.mp3',
    default: true,
    content_type: 'audio/mpeg',
    formats: {
      reference: {
        url: DEFAULT_MEDIUM_AUDIO_4,
        provider_name: 'sonata.media.provider.user_audio',
      },
    },
  },
];
