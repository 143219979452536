import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'styles/Button';

const BaseButtonStyles = styled(Button).attrs(({ variant = 'blue' }) => {
  let background;
  let textShadow;

  switch (variant) {
    case 'green':
      background = 'linear-gradient(to bottom, #04ee04, #16a216)';
      break;
    case 'brand-green':
      background = '#2BD283';
      break;
    case 'red':
      background = 'linear-gradient(to bottom, #f50000, #c30202)';
      break;
    case 'yellow':
      background = 'linear-gradient(to bottom, #f5e200, #ffa900)';
      textShadow = '0 2px 16px rgba(0, 0, 0, 0.3)';
      break;
    default:
      background = 'linear-gradient(to bottom, #0e61d7, #0538c3)';
      textShadow = '0 2px 16px rgba(0, 0, 0, 0.7)';
  }

  return {
    disableHover: true,
    background,
    textShadow,
  };
})`
  background: ${props => props.background};
  color: var(--white-white);
  margin: 0;
  padding: 13px 42px;
  font-family: var(--gilroy-font);
  text-transform: uppercase;
  text-shadow: ${props => props.textShadow};
  border-radius: 21px;

  &[disabled] {
    opacity: 0.5;
  }
`;

const ColorfulRoundedButton = ({ children, ...props }) => (
  <BaseButtonStyles {...props}>{children}</BaseButtonStyles>
);

export default ColorfulRoundedButton;

ColorfulRoundedButton.propTypes = {
  children: PropTypes.node.isRequired,
};
