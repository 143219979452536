/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';
import Flexbox from 'components/Flexbox';
import InfoTooltip from 'components/InfoTooltip';
import CollapsedWhiteBox from 'components/CollapsedWhiteBox';
import ChatIcon from 'components/StatisticsBox/components/ChatIcon';

import Button from './styles/Button';
import ExpandedText from './styles/ExpandedText';

import Odometer from './components/Odometer';

const Points = ({ value, additionalText, button, initExpanded, compact }) => {
  const [expandedText, setExpandedText] = useState(initExpanded);
  const [height, setHeight] = useState(initExpanded || 0);
  const expandedTextRef = useRef(null);

  useEffect(() => {
    if (initExpanded) {
      setHeight(expandedTextRef.current.scrollHeight);
    }
  }, []);

  return (
    <CollapsedWhiteBox isCollapsible={false} style={{ minHeight: 145 }}>
      <Flexbox style={{ position: 'relative', padding: '42px 0' }}>
        <ChatIcon iconColor="yellow-secondary" />
        <div>
          <Flexbox align="flex-end" style={{ position: 'relative' }}>
            <Odometer value={value} format="( ddd)" />
            <Text weight="black" family="lato" size={30} margin="0 0 4px">
              {compact ? 'pkt.' : 'punktów'}
            </Text>
            {!compact && (
              <InfoTooltip
                style={{ position: 'absolute', top: 0, right: -20 }}
                content={
                  <Text margin="0">
                    Zachęcaj swoich widzów do wysyłania Ci wiadomości, twoje zarobki zostaną
                    nagrodzone punktami, które będziesz mógł wykorzystać w ciekawy sposób.. Uwaga:
                    Jedyny reset punktów wkrótce. W momencie resetu ujawnimy więcej szczegółów!
                  </Text>
                }
                placement="right"
                theme="dark"
              />
            )}
          </Flexbox>
          <Text size={15} margin="5px 0 0" color="light-gray">
            Wszystkie punkty
          </Text>
          {!compact && (
            <Button
              type="button"
              background="yellow-secondary"
              onClick={() => {
                const newValue = !expandedText;
                setExpandedText(newValue);
                setHeight(newValue ? expandedTextRef.current.scrollHeight : 0);
              }}
              expanded={expandedText}
            />
          )}
        </div>
      </Flexbox>
      {!compact && (
        <ExpandedText style={{ height }} ref={expandedTextRef}>
          <Text color="light-gray" expanded={expandedText} margin="0 0 18px">
            Uwaga, punkty zostaną&nbsp;
            <Text tag="span" color="yellow">
              zresetowane wkrótce.
            </Text>
            <br />W tym również dniu, funkcja zostanie usprawniona oraz podamy więcej szczegółów.
          </Text>
        </ExpandedText>
      )}
    </CollapsedWhiteBox>
  );
};

Points.propTypes = {
  value: PropTypes.number.isRequired,
  additionalText: PropTypes.bool,
  button: PropTypes.bool,
  initExpanded: PropTypes.bool,
  compact: PropTypes.bool,
};

Points.defaultProps = {
  additionalText: false,
  button: false,
  initExpanded: false,
  compact: false,
};

export default Points;
