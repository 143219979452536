import styled from 'styled-components';

import Text from 'components/Text';

export default styled(Text)`
  color: var(--gray);
  font-family: var(--google-sora-cdn);
  font-size: 12.5px;
  text-align: center;
  line-height: normal;
  margin: 0 0 35px;
`;
