import styled, { css } from 'styled-components';

export const BaseTextBoxCss = css`
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  text-transform: ${props => (props.textTransform ? props.textTransform : 'none')};
  padding: ${props => (props.padding ? props.padding : '12px 15px')};
  ${props => (props.margin ? `margin: ${props.margin};` : '')}
  border: 2px solid var(--border-color);
  color: var(--text-color-dark-gray);
  background: var(--white);
  transition: 0.2s ease;
  border-radius: 3px;
  line-height: 1;
  outline: none;

  &::placeholder {
    color: var(--text-color-dark-gray);
  }

  &:hover {
    border-color: ${props => (props.hoverBorderColor ? props.hoverBorderColor : 'var(--blue)')};
  }

  &:not([readonly]) {
    &:focus,
    &:active {
      border-color: ${props => (props.hoverBorderColor ? props.hoverBorderColor : 'var(--blue)')};
    }
  }
`;

export const TextInput = styled.input.attrs(() => ({ type: 'text' }))`
  ${BaseTextBoxCss};
`;
