import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import refreshBtn from 'assets/images/refresh_btn.png';
import Hint from './Hint';

const Content = styled.div`
  a {
    color: var(--text-color-blue);
  }

  img {
    margin-top: 20px;
  }
`;

const HelperText = styled.p`
  color: var(--text-color-light-gray);
  font-size: 10px;
  margin-top: 6px;
  margin-bottom: 20px;

  a {
    color: inherit;
  }
`;

function HintUnwantedMessages({ discardHandler }) {
  return (
    <Hint question="Jak pominąć niechcianą wiadomość?" discardHandler={discardHandler}>
      <Content>
        <p>Pominąć wiadomość, możesz odświeżając widget w programie OBS</p>
        <img src={refreshBtn} alt="OSB Studio refresh button" />
        <HelperText>
          <a href="https://imgur.com/a/6itPDgP" target="_blank" rel="noopener noreferrer">
            Gdzie znajdę ten przycisk?
          </a>
        </HelperText>
        <p>
          Możesz również zbindować
          <br />
          <a href="https://imgur.com/a/QhREyae" target="_blank" rel="noopener noreferrer">
            pomijanie wiadomości pod przycisk
          </a>
        </p>
      </Content>
    </Hint>
  );
}

HintUnwantedMessages.propTypes = {
  discardHandler: PropTypes.instanceOf(Function).isRequired,
};

export default HintUnwantedMessages;
