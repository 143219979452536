import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';

import gifSEImage from 'assets/images/StreamElementsTutorial/gif_SE_step_3.gif';
import { TutorialImage } from 'styles/TutorialImages';

import StepContent from '../../../components/StepContent';
import { ColorfulRoundedButton } from '../../../../../../../../../../../components/Buttons';

const Step6 = ({ processStep, goPrev, noSEPAYButton }) => (
  <StepContent provider="stream_elements" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={30}>
      Rozwiń zakładkę menu, i sprawdź czy posiadasz aktywny przycisk „SE.PAY”. Jeśli tak – to super,
      możesz przejść dalej, jeśli nie kliknij przycisk, że nie posiadasz przycisku.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifSEImage} />
    <Text lineHeight={1.58} align="center" mb={30}>
      <ColorfulRoundedButton onClick={noSEPAYButton}>
        NIE WIDZĘ PRZYCISKU SE.PAY
      </ColorfulRoundedButton>
    </Text>
  </StepContent>
);

export default Step6;

Step6.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
  noSEPAYButton: PropTypes.instanceOf(Function).isRequired,
};
