import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Base, Range, Confirm } from '../shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 35px;
  height: 100%;
  background: var(--bg-color-light-gray);
  border: 2px solid var(--bg-color-light-gray);
  border-radius: 100px;

  > div > span {
    border: 2px solid var(--light-gray);
    color: var(--text-color);
  }

  > *:nth-child(2) {
    margin: 0 20px;
    flex-grow: 1;
  }
`;

export const RangeEditor = ({
  value: initialValue,
  valueDivide,
  rangeValuePrefix,
  onHide,
  onConfirm,
  min,
  max,
  icon,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Base {...props} onHide={onHide}>
      <Wrapper>
        <Range
          icon={icon}
          value={value}
          valuePrefix={rangeValuePrefix}
          min={min}
          max={max}
          valueDivide={valueDivide}
          onChange={newValue => setValue(newValue)}
        />

        <Confirm
          onClick={() => {
            onConfirm(value);
            onHide();
          }}
        />
      </Wrapper>
    </Base>
  );
};

RangeEditor.propTypes = {
  onConfirm: PropTypes.instanceOf(Function).isRequired,
  onHide: PropTypes.instanceOf(Function).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  icon: PropTypes.node,
  rangeValuePrefix: PropTypes.string,
  valueDivide: PropTypes.number,
};

RangeEditor.defaultProps = {
  icon: null,
  rangeValuePrefix: '%',
  valueDivide: 1,
};
