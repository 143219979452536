import styled from 'styled-components';

import PasswordInputComponent from 'components/PasswordInput';

const PasswordInput = styled(PasswordInputComponent)`
  margin: 0 0 15px;

  > input {
    font-size: 15px;
    padding: 15px;
    background-color: var(--bg-color-text-input);
  }
`;

export default PasswordInput;
