import React from 'react';
import ReactSelect, { components, Creatable } from 'react-select';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';

const selectStylesOverride = css`
  .react-select {
    cursor: pointer;
    user-select: none;

    &__control {
      border: 2px solid var(--border-color);
      border-radius: 30px;
      font-family: var(--google-sora-cdn);
      font-size: 13px;
      padding: 7.5px 28px 5.5px;
      transition: 0.2s ease;
      background-color: var(--white-box-background);

      &--menu-is-open,
      &:hover {
        border-color: ${props => (props.hoverBorderColor ? props.hoverBorderColor : 'var(--blue)')};
        outline: none;
        box-shadow: none;
      }

      &--is-focused {
        outline: none;
        box-shadow: none;
      }

      .react-select__dropdown-indicator > svg {
        right: 25px;
      }

      &--menu-is-open {
        .react-select__dropdown-indicator {
          > svg {
            transform: rotateX(180deg) translateY(50%);
          }
        }
      }
    }

    &__menu {
      width: 90%;
      right: 0;
      z-index: 23;

      margin: 10px 0;
      padding: 7.5px 0;

      border-radius: 10px;
      border: 1px solid var(--border-color);
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
      background-color: var(--white-box-background);
    }

    &__placeholder {
      color: var(--text-color-dark-gray);
    }

    &__menu-list {
      padding: 0;
    }

    &__menu-notice {
      font-size: 13px;
      padding: 7.5px;
      color: var(--text-color-light-gray);
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      > svg {
        position: absolute;
        width: 10px;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        transition: 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
        fill: var(--text-color-dark-gray);
      }
    }

    &__value-container {
      padding-left: 0;
      padding-right: 0;

      > div {
        color: var(--text-color);
      }
    }

    &__option {
      position: relative;
      width: 100%;

      padding: 7.5px 25px 7.5px 35px;

      border: 0;
      background-color: transparent;
      color: var(--text-color-dark-gray);
      outline: none;

      font-size: 13px;
      text-align: left;

      transition: 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: var(--bg-color-light-secondary-gray);
      }

      &::before {
        content: '';
        position: absolute;

        top: 50%;
        left: 15px;
        transform: translateY(-50%) scale(0);

        width: 7px;
        height: 7px;

        border-radius: 100%;
        background-color: var(--blue);
        transition: 0.2s ease;
      }

      &--is-selected {
        &::before {
          transform: translateY(-50%) scale(1);
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
`;

const DropdownIndicator = props =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );

const sharedProps = {
  components: DropdownIndicator,
  noOptionsMessage: () => 'Brak opcji',
  classNamePrefix: 'react-select',
};

const CustomSelect = styled(ReactSelect)`
  ${selectStylesOverride};
`;

const CustomSelectCreatable = styled(Creatable)`
  ${selectStylesOverride};
`;

export default props => <CustomSelect {...sharedProps} {...props} />;

export const CreatableCustomSelect = props => <CustomSelectCreatable {...sharedProps} {...props} />;
