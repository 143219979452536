import styled from 'styled-components';
import Button from '../../../styles/Button';

export default styled(Button)`
  width: 100%;
  font-weight: bold;
  margin: 0;
  font-size: 16px;
  border-radius: 10px;
  color: var(--black);
  &:hover {
    color: var(--white);
  }
`;
