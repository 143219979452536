import io from 'socket.io-client';
import http from 'utils/http';

import { openAlert } from 'components/Alert/utils';

const socket = io(process.env.REACT_APP_SOCKET_URL);

const fetchVotingConfigRequest = () => ({
  type: 'VOTING_FETCH_REQUEST',
});

const fetchVotingConfigSuccess = payload => ({
  type: 'VOTING_FETCH_SUCCESS',
  payload,
});

const fetchVotingConfigError = error => ({
  type: 'VOTING_FETCH_ERROR',
  error,
});

export const fetchVotingConfig = () => dispatch => {
  dispatch(fetchVotingConfigRequest());

  return http
    .get('/user/voting')
    .then(response => response.data)
    .then(data => {
      dispatch(fetchVotingConfigSuccess(data));
    })
    .catch(reason => dispatch(fetchVotingConfigError(reason)));
};

export const changeConfig = newConfig => (dispatch, getState) => {
  const {
    goalsVoting: { config: currenConfig },
    userData: {
      info: { id: userId },
    },
  } = getState();

  const payload = {
    ...currenConfig,
    ...newConfig,
  };

  dispatch(updateConfig(payload));

  http
    .put('/user/voting', payload)
    .then(() => {
      socket.emit('voting', userId);
      dispatch(fetchVotingConfig());
    })
    .catch(() =>
      openAlert(
        'error',
        'Błąd zapisu konfiguracji głosowania',
        `Podczas zapisu konfiguracji wystąpił błąd. Spróbuj ponownie później.`,
      ),
    );
};

const updateConfig = payload => ({
  type: 'VOTING_CHANGE_CONFIG',
  payload,
});
