import React from 'react';
import PropTypes from 'prop-types';

import gifSEImage from 'assets/images/StreamElementsTutorial/gif_SE_step_4.gif';
import { TutorialImage } from 'styles/TutorialImages';

import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step7 = ({ processStep, goPrev }) => (
  <StepContent provider="stream_elements" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Wejdź w zakładkę SE.PAY, a następnie kliknij ogromny przycisk „Create Account”.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      <strong>Pamiętaj uzupełniając dane wybrać koniecznie walutę PLN oraz kraj Poland</strong>
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifSEImage} />
    <Text lineHeight={1.58} align="center" mb={20}>
      Pamiętaj, że wypłata środków i miejsce do wpisania numeru konta, pojawi się dopiero po
      pozyskaniu jakichkolwiek środków na koncie StreamElements. Warto pamiętać, że StreamElements
      również nie wypłaca na PayPal.
    </Text>
  </StepContent>
);

export default Step7;

Step7.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
