import React from 'react';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import play from '../../../assets/icons/play.svg';
import { playAudioSet } from '../../../store/actions/tiplist';
import AudioClipOn from '../../../assets/defaultMedia/audios/SoundOn.mp3';
import AudioClipOff from '../../../assets/defaultMedia/audios/SoundOff.mp3';
import SpeakerButton from '../../TipsList/styles/SpeakerButton';

const Togglebox = styled.span`
  margin-left: 140px;
  padding-left: 0px;
  padding-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
`;

export const ToggleAudioPlay = () => {
  const dispatch = useDispatch();
  const isdisabled = useSelector(state => state.tiplist.playAudio);
  const audioOn = new Audio(AudioClipOn);
  const audioOff = new Audio(AudioClipOff);
  let playAudio = '';
  const initialsetup = Cookies.get('playAudio');

  if (initialsetup === 'true') {
    dispatch(playAudioSet(true));
  } else {
    dispatch(playAudioSet(false));
  }

  const handleToggle = () => {
    playAudio = Cookies.get('playAudio');

    if (playAudio === 'true') {
      audioOff.play();
      Cookies.set('playAudio', false, { expires: 365 });
      dispatch(playAudioSet(false));
    } else {
      audioOn.play();
      Cookies.set('playAudio', true, { expires: 365 });
      dispatch(playAudioSet(true));
    }
  };

  return (
    <>
      <Togglebox>
        <SpeakerButton type="button" clicked={!isdisabled} onClick={() => handleToggle()}>
          <img src={play} alt="play" />
        </SpeakerButton>
      </Togglebox>
    </>
  );
};
