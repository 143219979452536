import styled, { css } from 'styled-components';

import OptionsPopupItem from 'styles/OptionsPopupItem';

const edited = css`
  > span {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    transform: translateY(-50%) scale(1);
  }
`;

export default styled.li`
  ${OptionsPopupItem};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  margin: 0 -10px;
  width: auto;

  > span {
    opacity: 0;
    visibility: hidden;
  }

  ${params => params.edited && edited};
`;
