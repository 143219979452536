import styled, { css } from 'styled-components';

import OptionsPopupItem from 'styles/OptionsPopupItem';

const edited = css`
  > span,
  &::before {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    transform: translateY(-50%) scale(1);
  }
`;

export default styled.li`
  ${OptionsPopupItem};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 70px;

  > input {
    padding: 0;
    margin: 0;
    border: none;
    background: var(--bg-color-text-input);
    color: var(--text-color);
    font-style: italic;
    width: 100%;
    height: 100%;
  }

  > span,
  &::before {
    opacity: 0;
    visibility: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15px;

    transform: translateY(-50%) scale(0);

    width: 7px;
    height: 7px;

    border-radius: 100%;

    background-color: var(--blue);
  }

  ${params => params.edited && edited};
`;
