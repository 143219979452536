import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import getMimeType from 'utils/getMimeType';
import { apiUrl } from 'utils/http';
import MediaManager from 'components/MediaManager';
import ConfigContext from '../../../../../ConfigContext';

import ContextMenu from '../ContextMenu';

const mapStateToProps = state => {
  const { items } = state.mediaManager;

  return { media: items };
};

const mapDispatchToProps = dispatch => ({});

const VisualObject = props => {
  const { name, options, animation, updateElement, media } = props;

  const { focusedElementName, showMediaSelector, handleImageChange } = useContext(ConfigContext);

  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);

  useEffect(() => {
    if (name === focusedElementName && showMediaSelector) setMediaManagerVisibility(true);
  }, [focusedElementName, showMediaSelector]);

  let source = 'http://via.placeholder.com/350x250';
  let mimeType = 'image';

  const mediaObj = media.find(medium => medium.id === options.mediumId);
  if (mediaObj) {
    mimeType = getMimeType(mediaObj.content_type);
    source = mediaObj.formats
      ? `${mediaObj.default ? '' : apiUrl}${mediaObj.formats.reference.url}`
      : null;
  }

  const handleMediaMangerSelectItem = medium => {
    setMediaManagerVisibility(false);
    handleImageChange(false);
    updateElement({ elementName: name, mode: 'source', mediumId: medium.id });
  };

  const handleMediaManagerClose = () => {
    setMediaManagerVisibility(false);
    handleImageChange(false);
  };

  const elementStyle = {
    ...options.styles,
    width: '100%',
    pointerEvents: 'none',
    backfaceVisibility: 'hidden',
  };

  const isImage = mimeType === 'image';

  return (
    <React.Fragment>
      {isImage && (
        <img
          id={`${name}__screen_element`}
          className={animation}
          src={source}
          alt=""
          style={elementStyle}
        />
      )}

      {!isImage && (
        <video
          id={`${name}__screen_element`}
          loop
          muted
          autoPlay
          controls={false}
          key={source}
          style={elementStyle}
        >
          <source src={source} type={mediaObj.content_type} />
        </video>
      )}

      <MediaManager
        isMounted={mediaManagerVisibility}
        allowedTypes={['image', 'video']}
        onClose={handleMediaManagerClose}
        onSelectedItem={handleMediaMangerSelectItem}
      />

      <ContextMenu
        elementName={name}
        fields={['animation', 'setImage']}
        onSelectedMediaManager={() => setMediaManagerVisibility(true)}
      />
    </React.Fragment>
  );
};

VisualObject.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  media: PropTypes.instanceOf(Array).isRequired,
  animation: PropTypes.string.isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisualObject);
