import React from 'react';

const Context = React.createContext({});

const { Provider } = Context;
const { Consumer } = Context;

export { Provider, Consumer };

export default Context;
