import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

import { ReactComponent as SettingsIcon } from 'assets/icons/gear.svg';
import GrayButtonContainer from 'styles/buttons/GrayContainer';

const Wrapper = styled(GrayButtonContainer)`
  cursor: pointer;

  svg {
    width: 16px;
    fill: var(--text-color);
  }
`;

export const GoalsUnderScreenButton = withRouter(({ history }) => (
  <Wrapper onClick={() => history.push('/cele')}>
    <SettingsIcon />
    <span>Ustawienia celu</span>
  </Wrapper>
));
