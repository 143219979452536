import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export

const base = css`
  position: relative;
  font-family: var(--lato-font);
  font-weight: 900;
`;

export const H2 = styled.h2`
  ${base};
  font-family: var(--google-sora-cdn);
  font-size: 24px;
  margin: 0 0 10px;
`;

export const H3 = styled.h3`
  ${base};
  font-size: 19px;
  margin: 0 0 5px;
`;
