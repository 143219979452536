import styled from 'styled-components';

export default styled.ul`
  --item-margin: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  list-style: none;
`;
