import styled from 'styled-components';

export default styled.div`
  position: relative;
  background: var(--white);
  border-radius: 10px;
  overflow-y: auto;
  margin-bottom: 20px;

  .content_insider {
    padding: 10px;
  }
`;
