import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CheckboxTick } from 'assets/icons/checkbox-tick.svg';

const Background = styled.span.attrs(({ background }) => ({
  backgroundColor: `var(--${background})`,
}))`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 0;
  margin: 0;
  padding: 0;

  background-color: ${props => props.backgroundColor};
  padding: 10px 7px 8px 7px;

  > svg {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
  }
`;

const Label = styled.label`
  display: inline-block;
  user-select: none;
  cursor: pointer;

  ${props => props.loading && 'cursor: wait;'}
`;

const Input = styled.input`
  display: none;

  &:checked {
    + ${Background} {
        > svg {
          stroke-dashoffset: 0;
        }
      }
    }
  }
`;

const Item = ({ checked, value, disabled, ...props }) => (
  <Label loading={disabled}>
    <Input checked={checked} value={value} name="theme" type="radio" {...props} />

    <Background background={value} disabled={disabled}>
      <CheckboxTick />
    </Background>
  </Label>
);

Item.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

Item.defaultProps = {
  disabled: false,
};

export default Item;
