import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import AnnouncementOverlay from './AnnouncementOverlay';

const AnimatedOverlay = ({ isMounted, unmountDelay, onTransitionExiting, ...props }) => (
  <CSSTransition
    in={isMounted}
    timeout={unmountDelay}
    onExiting={onTransitionExiting}
    classNames="fade"
    unmountOnExit
  >
    <AnnouncementOverlay {...props} />
  </CSSTransition>
);

AnimatedOverlay.propTypes = {
  onTransitionExiting: PropTypes.instanceOf(Function),
  isMounted: PropTypes.bool,
  unmountDelay: PropTypes.number,
};

AnimatedOverlay.defaultProps = {
  onTransitionExiting: () => {},
  isMounted: false,
  unmountDelay: 500,
};

export default AnimatedOverlay;
