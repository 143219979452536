import React from 'react';
import PropTypes from 'prop-types';

import WithAnnouncementsStore from 'containers/WithAnnouncementsStore';
import Text from 'components/Text';

import Announcement from './components/Announcement';
import AnnouncementList from './components/AnnouncementList';
import AnnouncementModalItem from './components/AnnouncementModalItem';

const Announcements = ({ announcementsList, announcementsfailureFetching, display }) => {
  const filteredItems = announcementsList;

  if (filteredItems.length === 0) return <Text align="center">...</Text>;

  if (announcementsfailureFetching) {
    return (
      <Text color="red" align="center" noMargin>
        Wystąpił błąd podczas pobierania ogłoszenia
      </Text>
    );
  }

  if (display === 'latestModalItem') return <AnnouncementModalItem {...filteredItems[0]} />;

  return (
    <div className="text-center">
      {display === 'all' && <AnnouncementList entries={filteredItems} />}

      {display === 'latestOne' && (
        <Announcement
          createdAt={filteredItems[0].created_at}
          title={filteredItems[0].title}
          content={filteredItems[0].content}
          piclink={filteredItems[0].piclink}
          piclinkhref={filteredItems[0].piclinkhref}
          poster={false}
        />
      )}
    </div>
  );
};

Announcements.propTypes = {
  announcementsList: PropTypes.instanceOf(Array).isRequired,
  announcementsfailureFetching: PropTypes.bool.isRequired,
  display: PropTypes.oneOf(['all', 'latestOne', 'latestModalItem']),
};

Announcements.defaultProps = {
  display: 'all',
};

export default WithAnnouncementsStore(Announcements);
