import React, { useContext } from 'react';

import { convertToZlotys, convertToGrosze } from 'utils/priceConverter';
import { useDelayedState } from 'utils/hooks';
import ThresholdContext from './ThresholdContext';

import Input from './styles/Input';

const AmountSelector = () => {
  const { config, handleChange, hoverColor, isUpdating } = useContext(ThresholdContext);
  const [innerAmount, setInnerAmount] = useDelayedState(config.amount, 'amount', handleChange);

  const handleValueChange = ({ floatValue }) => {
    setInnerAmount(convertToGrosze(floatValue));
  };

  return (
    <Input
      decimalSeparator=","
      disabled={isUpdating}
      value={convertToZlotys(innerAmount)}
      defaultValue={convertToZlotys(innerAmount)}
      decimalScale={2}
      allowEmptyFormatting
      allowNegative={false}
      suffix=" PLN"
      style={{ maxWidth: 115, textAlign: 'center' }}
      isAllowed={({ floatValue }) => floatValue >= 0 && floatValue < 10000}
      hoverBorderColor={hoverColor}
      onValueChange={handleValueChange}
    />
  );
};

export default AmountSelector;
