import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import PopupDialogWrapper from 'components/Modal/styles/PopupDialogWrapper';
import PopupContent from 'components/Modal/styles/PopupContent';
import PopupArrow from 'components/Modal/styles/PopupArrow';

const Content = styled(PopupContent)`
  min-height: 100px;
  padding: 10px;
`;

const Arrow = styled(PopupArrow)`
  top: calc(50% - 10px);
`;

const PopupDialog = ({ children, visible, snapTo, onClose }) => {
  const portalRef = useRef(document.createElement('div'));
  const rootRef = useRef(document.createElement('div'));

  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const outsideClickHandler = e => {
    if (rootRef.current && visible) {
      if (!rootRef.current.contains(e.target)) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler, false);

    return () => {
      document.removeEventListener('click', outsideClickHandler, false);
    };
  }, [visible]);

  useEffect(() => {
    if (portalRef.current) {
      document.body.append(portalRef.current);
    }
  }, [portalRef.current]);

  useEffect(() => {
    if (visible && snapTo && rootRef.current) {
      const { x, y, width, height } = snapTo.getBoundingClientRect();
      const { height: contentHeight } = rootRef.current.getBoundingClientRect();
      setPopupPosition({ x: x + width + 20, y: y + height / 2 - contentHeight / 2 });
    }
  }, [snapTo, visible, rootRef.current]);

  return ReactDOM.createPortal(
    <PopupDialogWrapper
      ref={rootRef}
      visible={visible}
      style={{
        left: popupPosition.x,
        top: popupPosition.y,
      }}
    >
      <Arrow />
      <Content>{children}</Content>
    </PopupDialogWrapper>,
    portalRef.current,
  );
};

export default PopupDialog;
