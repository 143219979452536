import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import defaultOptions from 'store/data/tipAlertDefaultOptions';
import { setDisplaySettingsDialogCurrentView } from 'store/actions/configuratorsConfigs';

import MediaManager from 'components/MediaManager';
import RootModal from 'components/Modal';
import SaveInfo from 'components/Modal/SaveInfo';
import TextTop from 'components/Modal/styles/TextTop';
import TextHeader from 'components/Modal/styles/TextHeader';
import TextDescription from 'components/Modal/styles/TextDescription';
import SettingsDialogContent from 'components/Modal/styles/SettingsDialogContent';
import TextLabel from 'components/Modal/styles/TextLabel';
import Footer from 'components/Modal/styles/Footer';
import FooterButton from 'components/Modal/styles/FooterButton';

import { ReactComponent as LayoutsIcon } from 'assets/icons/expand.svg';
import { ReactComponent as SoundsIcon } from 'assets/icons/music.svg';
import { ReactComponent as SynthIcon } from 'assets/icons/quote.svg';

import Threshold from './components/Threshold';
import Switch from './styles/Switch';
import SwitchItem from './styles/SwitchItem';

class Modal extends Component {
  static propTypes = {
    templates: PropTypes.instanceOf(Array).isRequired,
    media: PropTypes.instanceOf(Array).isRequired,
    config: PropTypes.instanceOf(Object).isRequired,
    dispatchUpdateConfig: PropTypes.instanceOf(Function).isRequired,
    fetchMediaIfNeeded: PropTypes.instanceOf(Function).isRequired,
    isMounted: PropTypes.bool.isRequired,
    currentView: PropTypes.string,
    setCurrentView: PropTypes.instanceOf(Function),
    isConfigUpdating: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    currentView: 'templates',
    setCurrentView: () => {},
  };

  views = {
    TEMPLATES: 'templates',
    SOUNDS: 'sounds',
    SYNTH: 'synth',
  };

  state = {
    addThresholdButtonColor: 'var(--brand-green)',
    openSynthConfIndex: null,
    mediaManager: {
      enable: false,
      index: '',
    },
  };

  componentDidMount() {
    const { fetchMediaIfNeeded } = this.props;
    this.setButtonColor();

    fetchMediaIfNeeded();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentView, isMounted } = this.props;

    if (prevProps.currentView !== currentView) {
      this.setButtonColor();
      this.resetCurrentOpenSynthConf();
    }

    if (!prevProps.isMounted && isMounted) {
      this.resetCurrentOpenSynthConf();
    }
  }

  setButtonColor() {
    const { currentView } = this.props;
    let addThresholdButtonColor = '';
    switch (currentView) {
      case 'templates':
        addThresholdButtonColor = 'var(--brand-green)';
        break;
      case 'sounds':
        addThresholdButtonColor = 'var(--brand-blue)';
        break;
      case 'synth':
        addThresholdButtonColor = 'var(--yellow)';
        break;
      default:
        addThresholdButtonColor = 'var(--brand-green)';
    }
    this.setState({ addThresholdButtonColor });
  }

  /**
   * Funkcja aktualizująca próg kwotowy
   *
   * @param {String} viewName - nazwa widoku szablonu
   * @param {Object} updated - obiekt z zaaktualizowanymi wartościami
   * @param {Object} reference - referencja z aktualnymi wartościami
   * @returns {void}
   */
  updateDefaults = (viewName, updated, reference) => {
    const updatedDefault = {
      ...reference,
      ...updated,
    };

    this.updateConfig({
      defaults: {
        [viewName]: updatedDefault,
      },
    });
  };

  /**
   * Funkcja aktualizująca próg kwotowy
   *
   * @param {String} viewName - nazwa widoku szablonu
   * @param {Number} index - indeks progu w tablicy
   * @param {Object} updated - obiekt z zaaktualizowanymi wartościami
   * @returns {void}
   */
  updateTreshold = (viewName, index, updated) => {
    const { config } = this.props;

    const items = [...config.tresholds[viewName]];
    const item = { ...items[index] };

    const updatedTreshold = { ...item, ...updated };

    items.splice(index, 1, updatedTreshold);

    this.updateConfig({
      tresholds: {
        [viewName]: items,
      },
    });
  };

  /**
   * Funkcja usuwająca próg kwotowy
   *
   * @param {String} viewName - nazwa widoku szablonu
   * @param {Number} index - indeks progu w tablicy
   * @returns {void}
   */
  deleteTreshold = (viewName, index) => {
    const { config } = this.props;

    const items = [...config.tresholds[viewName]];
    items.splice(index, 1);

    this.updateConfig({
      tresholds: {
        [viewName]: items,
      },
    });
  };

  /**
   * Funkcja dodająca nowy próg kwotowy
   *
   * @returns {void}
   */
  addThreshold = () => {
    const { config, currentView } = this.props;

    let threshold = {
      amount: 0,
    };

    if (this.isActiveView(this.views.SYNTH)) {
      const sps = { ...defaultOptions.speechSynthesizer };

      threshold = {
        templateId: 'DEFAULT_TIP_ALERT_1',
        amount: 0,
        ...sps,
      };
    }

    if (this.isActiveView(this.views.SOUNDS)) {
      threshold.volume = 0;
      threshold.mediumId = '';
    }

    if (this.isActiveView(this.views.TEMPLATES)) {
      threshold.templateId = 'DEFAULT_TIP_ALERT_1';
    }

    if (!config.tresholds[currentView]) {
      config.tresholds[currentView] = [];
    }

    this.updateConfig({
      tresholds: {
        [currentView]: [...config.tresholds[currentView], threshold],
      },
    });
  };

  /**
   * Funkcja aktualizująca konfiguracje konfiguratora
   *
   * @param {Object} config
   * @returns {void}
   */
  updateConfig = config => {
    const { dispatchUpdateConfig } = this.props;

    dispatchUpdateConfig({
      displaySettings: {
        ...config,
      },
    });
  };

  resetCurrentOpenSynthConf() {
    this.setState({ openSynthConfIndex: null });
  }

  /**
   * Funkcja zmieniająca aktywny widok
   *
   * @param {String} viewName - nazwa widoku do aktywacji
   * @returns {Boolean}
   */
  changeView(viewName) {
    const { setCurrentView } = this.props;
    setCurrentView(viewName);
  }

  /**
   * Funkcja sprawdzająca czy przekazana nazwa widoku jest aktywna
   *
   * @param {String} viewName - nazwa widoku do porównania
   * @returns {Boolean}
   */
  isActiveView(viewName) {
    const { currentView } = this.props;
    return currentView === viewName;
  }

  /**
   * Funkcja formatująca tablicę z szablonami dla komponentu react-select
   *
   * @returns {Array}
   */
  formatTemplates() {
    const { templates } = this.props;

    const defaultTemplate = 'Domyślny szablon';

    const defaults = templates
      .filter(t => t.config.title.includes(defaultTemplate))
      .map(template => ({
        label: template.config.title,
        value: template.id,
      }));

    const customs = templates
      .filter(t => !t.config.title.includes(defaultTemplate))
      .sort((a, b) => (a.config.title > b.config.title ? 1 : -1))
      .map(template => ({
        label: template.config.title,
        value: template.id,
      }));

    return defaults.concat(customs);
  }

  /**
   * Funkcja zarządzająca widocznością biblioteki plików
   *
   * @param {Boolean} enable - stan widoczności
   * @param {Number} index - indeks aktywnego elementu
   * @returns {Boolean}
   */
  toggleMediaManager(enable, index = '') {
    this.setState({
      mediaManager: {
        enable,
        index,
      },
    });
  }

  render() {
    const { mediaManager, addThresholdButtonColor, openSynthConfIndex } = this.state;
    const {
      config: { tresholds, defaults },
      media,
      isConfigUpdating,
      ...props
    } = this.props;

    const templates = this.formatTemplates();

    return (
      <>
        <RootModal
          withSpinner={isConfigUpdating}
          onTransitionExiting={() => this.resetCurrentOpenSynthConf()}
          titleSlot={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch>
              <SwitchItem
                active={this.isActiveView(this.views.TEMPLATES)}
                onClick={() => {
                  this.changeView(this.views.TEMPLATES);
                }}
              >
                <LayoutsIcon />
              </SwitchItem>
              <SwitchItem
                active={this.isActiveView(this.views.SOUNDS)}
                onClick={() => {
                  this.changeView(this.views.SOUNDS);
                }}
              >
                <SoundsIcon />
              </SwitchItem>
              <SwitchItem
                active={this.isActiveView(this.views.SYNTH)}
                onClick={() => {
                  this.changeView(this.views.SYNTH);
                }}
              >
                <SynthIcon />
              </SwitchItem>
            </Switch>
          }
          width={510}
          background="dark"
          {...props}
        >
          {this.isActiveView(this.views.TEMPLATES) && (
            <SettingsDialogContent>
              <div>
                <TextTop>USTAWIENIA ZALEŻNE OD KWOTY</TextTop>
                <TextHeader>Szablon wiadomości</TextHeader>
                <TextDescription>
                  Ustaw od jakiej kwoty system ma wyświetlać
                  <br />
                  stworzony przez Ciebie szablon, określający
                  <br />
                  wygląd powiadomienia o nowej wiadomości.
                  <br />
                  Wygląd zaprojektujesz po zamknięciu tego okna.
                </TextDescription>
              </div>
              <Scrollbars
                className="scrollbar scrollbar--outside"
                style={{ height: '100%' }}
                hideTracksWhenNotNeeded
              >
                <TextLabel color="var(--brand-green)">
                  Szablon domyślny,
                  <br />
                  wyświetlany od kwoty 0 PLN
                </TextLabel>
                <Threshold
                  isDefault
                  config={defaults.templates}
                  views={this.views}
                  view={this.views.TEMPLATES}
                  templates={templates}
                  onChange={value =>
                    this.updateDefaults(this.views.TEMPLATES, value, defaults.templates)
                  }
                />
                <TextLabel color="var(--brand-green)">Szablon od konkretnej kwoty</TextLabel>
                {!!tresholds.templates.length && (
                  <>
                    {tresholds.templates.map((item, index) => (
                      <Threshold
                        key={parseInt(index.toString(), 10)}
                        config={item}
                        views={this.views}
                        view={this.views.TEMPLATES}
                        templates={templates}
                        onChange={value => this.updateTreshold(this.views.TEMPLATES, index, value)}
                        onDelete={() => this.deleteTreshold(this.views.TEMPLATES, index)}
                      />
                    ))}
                  </>
                )}
              </Scrollbars>
            </SettingsDialogContent>
          )}

          {this.isActiveView(this.views.SOUNDS) && (
            <SettingsDialogContent>
              <div>
                <TextTop>USTAWIENIA ZALEŻNE OD KWOTY</TextTop>
                <TextHeader>Dźwięki przy wiadomości</TextHeader>
                <TextDescription>
                  Ustaw od jakiej kwoty system ma odtwarzać
                  <br />
                  wybrany przez Ciebie efekt dźwiękowy
                  <br />
                  gdy ktoś wpłaci określoną kwotę
                  <br />
                  Pamiętaj o regulacji głośności powiadomienia!
                </TextDescription>
              </div>
              <Scrollbars
                className="scrollbar scrollbar--outside"
                hideTracksWhenNotNeeded
                style={{ height: '100%' }}
              >
                <TextLabel color="var(--brand-blue)">
                  Dźwięk domyślny
                  <br />
                  odtwarzany od kwoty 0 PLN
                </TextLabel>
                <Threshold
                  isDefault
                  config={defaults.sounds}
                  views={this.views}
                  view={this.views.SOUNDS}
                  media={media}
                  onChange={value => this.updateDefaults(this.views.SOUNDS, value, defaults.sounds)}
                  onTriggerShowMediaManager={() => this.toggleMediaManager(true)}
                />
                <TextLabel color="var(--brand-blue)">Dźwięk od konkretnej kwoty</TextLabel>
                {!!tresholds.sounds.length && (
                  <>
                    {tresholds.sounds.map((item, index) => (
                      <Threshold
                        key={parseInt(index.toString(), 10)}
                        config={item}
                        views={this.views}
                        view={this.views.SOUNDS}
                        media={media}
                        onChange={value => this.updateTreshold(this.views.SOUNDS, index, value)}
                        onDelete={() => this.deleteTreshold(this.views.SOUNDS, index)}
                        onTriggerShowMediaManager={() => this.toggleMediaManager(true, index)}
                      />
                    ))}
                  </>
                )}
              </Scrollbars>
            </SettingsDialogContent>
          )}

          {this.isActiveView(this.views.SYNTH) && (
            <SettingsDialogContent>
              <div>
                <TextTop>USTAWIENIA ZALEŻNE OD KWOTY</TextTop>
                <TextHeader>Czytanie wiadomości</TextHeader>
                <TextDescription>
                  Ustaw od jakiej kwoty chciałbyś aby aktywowany
                  <br />
                  został syntezator mowy czytający treść wiadomości
                  <br />
                  wysłanej przez Twojego widza!
                  <br />
                  Każdy głos możesz dowolnie skonfigurować!
                  <br />
                  Jeżeli nie chcesz czytania usuń wszystkie progi.
                </TextDescription>
              </div>
              <Scrollbars
                className="scrollbar scrollbar--outside"
                hideTracksWhenNotNeeded
                style={{ height: '100%' }}
              >
                {!!tresholds.synth && (
                  <>
                    {tresholds.synth.map((item, index) => (
                      <Threshold
                        key={parseInt(index.toString(), 10)}
                        config={item}
                        views={this.views}
                        view={this.views.SYNTH}
                        templates={templates}
                        onChange={value => this.updateTreshold(this.views.SYNTH, index, value)}
                        onDelete={() => this.deleteTreshold(this.views.SYNTH, index)}
                        openSynthConf={openSynthConfIndex === index}
                        onOpenSynthConf={() => {
                          this.setState({
                            openSynthConfIndex: index === openSynthConfIndex ? null : index,
                          });
                        }}
                      />
                    ))}
                  </>
                )}
              </Scrollbars>
            </SettingsDialogContent>
          )}

          <Footer>
            <FooterButton
              disabled={isConfigUpdating}
              background={addThresholdButtonColor}
              type="button"
              onClick={this.addThreshold}
            >
              Dodaj kolejny próg
            </FooterButton>

            <SaveInfo />
          </Footer>
        </RootModal>

        <MediaManager
          allowedTypes={['audio']}
          onSelectedItem={medium => {
            const updated = {
              mediumId: medium.id,
            };

            if (typeof mediaManager.index === 'number') {
              this.updateTreshold(this.views.SOUNDS, mediaManager.index, updated);
            } else {
              this.updateDefaults(this.views.SOUNDS, updated, defaults.sound);
            }

            this.toggleMediaManager(false);
          }}
          onClose={() => this.toggleMediaManager(false)}
          isMounted={mediaManager.enable}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentView: state.configuratorsConfigs.displaySettingsDialog.currentView,
  isConfigUpdating: state.configuratorsConfigs.isUpdating,
});
const mapDispatchToProps = dispatch => ({
  setCurrentView: view => dispatch(setDisplaySettingsDialogCurrentView(view)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
