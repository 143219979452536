import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { style as menuTextStyle, hover as menuTextHover } from 'styles/ui/menuTextHover';

const hoverSvgColor = css`
  > svg {
    fill: var(--brand-green);
  }
`;

const featuredDot = css`
  &:before {
    display: block;
    content: '';
    background: var(--red);
    position: absolute;
    top: 10px;
    right: 15px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`;

const StyledLi = styled.li`
  height: 64px;

  &:hover {
    > a,
    > p {
      > span {
        ${menuTextHover};
      }
    }
  }

  > a,
  > p {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 30px;
    margin: 0;
    cursor: pointer;

    > span {
      ${menuTextStyle};
      z-index: 2;
    }

    > svg {
      display: block;

      min-width: 20px;
      fill: var(--text-color-medium-gray);
      transition: 250ms;
      transform: scale(1);

      &.menu-svg {
        width: 25px;
      }
    }

    &:hover,
    &.active {
      ${hoverSvgColor}
    }

    ${props => props.isActive && hoverSvgColor}

    &:hover {
      > svg {
        transform: scale(1.2);
      }
    }

    ${props => props.featured && featuredDot}
  }
`;

const MenuItem = React.forwardRef(
  ({ children, isPopup, onClick, isActive, featured, ...props }, ref) => (
    <StyledLi ref={ref} onClick={onClick} isActive={isActive} featured={featured}>
      {isPopup ? (
        <p>{children}</p>
      ) : (
        <NavLink {...props} activeClassName="active">
          {children}
        </NavLink>
      )}
    </StyledLi>
  ),
);

MenuItem.propTypes = {
  onClick: PropTypes.instanceOf(Function),
  children: PropTypes.node.isRequired,
  isPopup: PropTypes.bool,
  isActive: PropTypes.bool,
  featured: PropTypes.bool,
};

MenuItem.defaultProps = {
  onClick: () => {},
  isPopup: false,
  isActive: false,
  featured: false,
};

export default MenuItem;
