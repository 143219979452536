import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ConfigContext from '../ConfigContext';

import AddElementButton from './AddElementButton';
import DeleteIconButton from './DeleteIconButton';
import UndoButton from './UndoButton';
import SaveConfigButton from './SaveConfigButton';

const Root = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  flex-grow: 1;
  font-family: var(--google-sora-cdn);
  text-transform: uppercase;
  text-align: center;
  color: #adadad;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px; /* 125% */

  &::first-line {
    color: var(--text-color);
    font-size: 12px;
  }
`;

const ConfigActions = props => {
  const { hasChanges, hasUnsavedChanges, hiddenElements, openContextMenu } = useContext(
    ConfigContext,
  );
  const { onUndo, onSave } = props;

  const handleAddElementClick = evt => {
    openContextMenu('addElement', evt);
  };
  const handleRemoveElementClick = evt => {
    openContextMenu('removeElement', evt);
  };

  return (
    <Root>
      <AddElementButton onClick={handleAddElementClick} disabled={!hiddenElements.length} />
      <DeleteIconButton onClick={handleRemoveElementClick} />

      <Text>
        Wybierz dowolny element powyżej
        <br />
        aby go w pełni modyfikować
      </Text>

      <UndoButton onClick={onUndo} disabled={!hasChanges} />
      <SaveConfigButton onClick={onSave} disabled={!hasUnsavedChanges} />
    </Root>
  );
};

ConfigActions.propTypes = {
  onUndo: PropTypes.instanceOf(Function).isRequired,
  onSave: PropTypes.instanceOf(Function).isRequired,
};

export default ConfigActions;
