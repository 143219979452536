import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { apiUrl } from 'utils/http';
import WhiteBox from 'styles/WhiteBox';
import Text from 'components/Text';
import Grid from 'components/Grid';
import MediaManager from 'components/MediaManager';

import ImagePanel from './ImagePanel';

import { Consumer } from '../Context';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import Button from './ImagePanel/components/Button';
import serverAlert from '../../../store/utils/serverAlert';

const Banner = styled.div`
  background-color: var(--bg-color-white-gray);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const EditButton = styled.div`
  transform: translate(150px, -80px);
`;

const SetBanner = ({ mediumType, onSelectedItem, ...props }) => {
  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  return (
    <Consumer>
      {({ userDataProfile }) => {
        // Ustawianie avatara użytkownika
        let userBanner = null;
        if (userDataProfile.banner && userDataProfile.banner.formats) {
          userBanner = `url(${apiUrl}${userDataProfile.banner.formats.user_banner.url})`;
        }

        return (
          <Grid columns="420px 1fr">
            <WhiteBox style={{ padding: 6, textAlign: 'center' }}>
              <Banner
                style={{
                  backgroundImage: userBanner,
                }}
              />
              <WhiteBox
                style={{
                  padding: '0px 30px 3px',
                  display: 'inline-block',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <Text color="blue" size={11} margin="0">
                  Wymagana rozdzielczość: 1200x400
                </Text>
              </WhiteBox>
              <EditButton>
                <Button
                  background="gray"
                  noWrapper
                  type="button"
                  onClick={() => setMediaManagerVisibility(true)}
                  loading={addLoading}
                  disabled={addLoading}
                >
                  <EditIcon width="20" fill="var(--text-color-dark-gray)" />
                </Button>
              </EditButton>
            </WhiteBox>
            <ImagePanel
              onSelectedItem={onSelectedItem}
              mediumType={mediumType}
              {...props}
              style={{ paddingLeft: 80 }}
            />
            <MediaManager
              allowedTypes={['image']}
              onClose={() => setMediaManagerVisibility(false)}
              onSelectedItem={async item => {
                setAddLoading(true);
                setMediaManagerVisibility(false);
                try {
                  await onSelectedItem(mediumType, item);
                } catch (error) {
                  serverAlert(error.response.data.message);
                }
                setAddLoading(false);
              }}
              isMounted={mediaManagerVisibility}
            />
          </Grid>
        );
      }}
    </Consumer>
  );
};

SetBanner.propTypes = {
  mediumType: PropTypes.string.isRequired,
  onSelectedItem: PropTypes.instanceOf(Function).isRequired,
};

export default SetBanner;
