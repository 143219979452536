import styled from 'styled-components';

export default styled.div.attrs(({ display, justify, align, direction }) => ({
  display: display || 'flex',
  justify: justify || 'flex-start',
  align: align || 'flex-start',
  direction: direction || 'row',
}))`
  display: ${props => props.display};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  flex-direction: ${props => props.direction};
`;
