import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 15px;

  > svg {
    width: 18px;
    height: 18px;
    fill: var(--gray);
  }

  > span {
    font-family: var(--google-sora-cdn);
    font-size: 12.5px;
    color: var(--gray);
  }
`;

const SaveInfo = () => (
  <Wrapper>
    <SaveIcon />
    <span>Zapisywane automatycznie</span>
  </Wrapper>
);

export default SaveInfo;
