import countriesData from 'assets/constants/countries.json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Input from 'styles/Input';
import { ButtonWithAnimatedArrow } from '../../Buttons';
import EmojiLabel from '../components/EmojiLabel';
import FormWrapper from '../components/FormWrapper';
import StepWrapper from '../components/StepWrapper';
import { StepPropTypes } from '../types';

const Flag = styled.img`
  width: auto;
  height: 14px;
  position: absolute;
  left: 17px;
  bottom: 15px;
`;

const ConfirmDataStep = ({ processStep }) => {
  const [countryName, setCountryName] = useState('');
  const [countryFlag, setCountryFlag] = useState('');
  const {
    userData: { profile },
  } = useSelector(state => state);

  const { fullname, bank_number: bankNumber, address } = profile || {};
  const { city, street, postal_code: postalCode, country } = address || {};

  useEffect(() => {
    if (!country) {
      return;
    }

    const countryData = countriesData.find(({ value }) => value === country);

    if (countryData) {
      setCountryName(countryData.label);
      setCountryFlag(`/flags/4x3/${country.toLowerCase()}.svg`);
    }
  }, [country]);

  return (
    <StepWrapper>
      <p>
        Zanim rozpoczniesz proces weryfikacji swoich danych, upewnij się że dane zgromadzone w
        Tipply są aktualne i pokrywają się z tymi w banku.
      </p>
      <FormWrapper>
        <div className="row half-half">
          <div className="cell">
            <EmojiLabel emoji="👤" label="Imię i nazwisko" />
            <Input type="text" value={fullname} readOnly />
          </div>
          <div className="cell">
            <EmojiLabel emoji="🏠" label="Adres zamieszkania" />
            <Input type="text" value={street} readOnly />
          </div>
        </div>
        <div className="row">
          <div className="cell">
            <EmojiLabel emoji="📌" label="Kod pocztowy" />
            <Input type="text" value={postalCode} readOnly />
          </div>
          <div className="cell">
            <EmojiLabel emoji="🏦" label="Numer konta bankowego" />
            <Input type="text" value={bankNumber} readOnly />
          </div>
        </div>
        <div className="row">
          <div className="cell">
            <EmojiLabel emoji="📌" label="Miasto" />
            <Input type="text" value={city} readOnly />
          </div>
          <div className="cell">
            <EmojiLabel emoji="🇵🇱" label="Kraj zamieszkania" />
            <Flag src={countryFlag} alt="" />
            <Input type="text" value={countryName} readOnly padding="12px 15px 12px 42px" />
          </div>
        </div>
      </FormWrapper>
      <p>Jeśli dane są aktualne — przejdź dalej</p>
      <p>
        Jeśli nie - zaktualizuj je i dopiero przystąp do weryfikacji tożsamości. Jeśli nie możesz
        zmienić danych np. przez blokadę konta, po prostu przejdź dalej.
      </p>
      <div className="button-container">
        <ButtonWithAnimatedArrow onClick={() => processStep()}>
          Dane są aktualne
        </ButtonWithAnimatedArrow>
      </div>
    </StepWrapper>
  );
};

ConfirmDataStep.propTypes = StepPropTypes;

export default ConfirmDataStep;
