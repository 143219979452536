import styled from 'styled-components';

export default styled.span`
  font-size: 22px;
  line-height: 0;
  color: ${props =>
    props.isActive
      ? 'var(--text-color-text-editor-active)'
      : 'var(--text-color-text-editor-notactive)'};
  transition: all 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    transition: inherit;
  }
`;
