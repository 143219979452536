import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import RootModal from 'components/Modal';
import * as moderatorActions from 'store/actions/moderator';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as RedBin } from 'assets/icons/redbin.svg';

import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import Simple from '../../../components/Checkboxes/components/Simple';
import Button from '../../../components/Button';

import { createModerator, fetchModeratorsIfNeeded } from '../../../store/actions/moderator';
import { toggleModerationMode } from '../../../store/actions/userData';
import Moderator from '../../TipsList/blocks/Moderator';

const ToggleText = styled.span`
  margin-left: 30px;
`;

const ToggleBox = styled.span`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 25px 0 25px 0px;
`;

const InfoText = styled.span`
  font-size: 15px;
  color: var(--text-color-light-gray);
`;

const ListHeader = styled.span`
  display: inline-block;
  padding: 5px;
`;

const ModeratorModal = ({ onClose, displayModerationBasketModal, ...props }) => {
  const dispatch = useDispatch();
  const moderationMode = useSelector(state => state.userData.info.moderation_mode);
  const moderators = useSelector(state => state.moderators.items);

  useEffect(() => {
    dispatch(fetchModeratorsIfNeeded());
  }, []);

  const handleCreate = () => {
    dispatch(createModerator());
  };

  const openBasketModal = () => {
    dispatch(displayModerationBasketModal());
  };

  const linkTimeLeft = (linkTime, created) => {
    const now = new Date();
    const start = new Date(created);
    const timeLeft = Math.abs(now - start) / 36e5;

    return Math.round(linkTime - timeLeft);
  };

  const handleToggle = () => {
    dispatch(toggleModerationMode(moderationMode));
  };

  return (
    <RootModal width={960} height="auto" onClose={onClose} {...props}>
      <h4 style={{ marginTop: 0 }}>Moderacja wiadomości</h4>
      <InfoText>
        Dzięki tej opcji, będziesz mógł moderować przychodzące wiadomości od Twoich widzów.
        Moderacją, jeżeli chcesz będzie mógł się zając Twój moderator, wystarczy że podeślesz mu
        URL. Moderować możesz również przychodzące wiadomości głosowe, dołączone do wiadomości.
      </InfoText>
      <ToggleBox>
        <Simple apperance="switch" color="green" checked={moderationMode} onCheck={handleToggle} />
        <ToggleText>Włącz moderację wiadomości</ToggleText>
      </ToggleBox>
      <div style={{ margin: '25px 0 25px 0' }}>
        <Button background="blue" type="button" icon={<Plus />} onClick={handleCreate}>
          Dodaj moderatora wiadomości
        </Button>
      </div>
      <InfoText>
        Dodanie moderatora, spowoduje wygenerowanie jednorazowego linku o konkretnej długości
        działania, który
        <strong> w pełni udostępni możliwość zarządzania kolejką Twoich wiadomości. </strong>
        Należy go przesłać swojemu modaratorowi, by mógł moderować kolejkę. Jeżeli chcesz usunąć
        moderatora i jego dostęp do wiadomości, usuń go klikając ikonę kosza przy nazwie, lub wyłącz
        moderację wiadomości.
      </InfoText>

      {moderators.length > 0 && (
        <div style={{ margin: '10px 0 5px 0' }}>
          <ListHeader style={{ width: '315px' }}>
            <strong>Nazwa Twojego moderatora</strong>
          </ListHeader>
          <ListHeader style={{ width: '215px' }}>
            <strong>Czas ważności linku</strong>
          </ListHeader>
          <ListHeader>
            <strong>Skopiuj link</strong>
          </ListHeader>
        </div>
      )}
      {moderators.length > 5 && (
        <Scrollbars className="scrollbar" style={{ height: 300, maxHeight: 600 }}>
          {moderators.map(moderator => (
            <Moderator
              id={moderator.id}
              moderatorName={moderator.moderator_name}
              linkTime={linkTimeLeft(moderator.link_time, moderator.created)}
              link={moderator.link}
            />
          ))}
        </Scrollbars>
      )}
      {moderators.length <= 5 && (
        <>
          {moderators.map(moderator => (
            <Moderator
              id={moderator.id}
              key={moderator.id}
              moderatorName={moderator.moderator_name}
              linkTime={linkTimeLeft(moderator.link_time, moderator.created)}
              link={moderator.created}
            />
          ))}
        </>
      )}
      <div>
        <Button
          style={{ margin: '20px 0 0px 0', background: 'var(--light-red)' }}
          type="button"
          icon={<RedBin style={{ color: 'var(--red)' }} />}
          onClick={openBasketModal}
        >
          <span style={{ color: 'var(--red)' }}>Wyświetl wiadomości usunięte przez moderatora</span>
        </Button>
      </div>
    </RootModal>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...moderatorActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ModeratorModal);

ModeratorModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  displayModerationBasketModal: PropTypes.instanceOf(Function).isRequired,
};
