import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'assets/icons/close-fat.svg';
import * as announcementActions from 'store/actions/announcements';
import Announcements from './Announcements';
import AnimatedOverlay from './Announcements/AnimatedOverlay';

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none;
  border-radius: 50%;
  border: none;
  aspect-ratio: 1 / 1;
  padding: 8px;
  background: none;
  color: white;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 32px;
    aspect-ratio: 1 / 1;
  }

  svg,
  path {
    fill: currentColor;
  }
`;

const AnnouncementsModal = ({ onClose, ...props }) => (
  <AnimatedOverlay onClose={onClose} {...props}>
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
    <Announcements display="latestModalItem" />
  </AnimatedOverlay>
);

const mapDispatchToProps = dispatch => bindActionCreators({ ...announcementActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(AnnouncementsModal);

AnnouncementsModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  displayMoreInfoModal: PropTypes.instanceOf(Function).isRequired,
};
