import styled from 'styled-components';
import Button from '../../../styles/Button';

const clickedAfter = `
    transform: translate(-50%, -50%) rotate(155deg);
    opacity: 1;
    width: 120%;
`;

const clickedButton = `
transform: scale(0.9) rotate(-25deg);
`;

const clickedImg = `
    opacity: 0.3;
`;

export default styled(Button)`
  border-radius: 50px;
  padding: 5px 0 0 0;
  width: 40px;
  height: 40px;
  background: var(--light-gray);
  position: relative;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transform: scale(1) rotate(0);
  transition-delay: 100ms;
  ${props => (props.clicked ? clickedButton : '')};

  &:hover {
    background: var(--light-gray);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  img {
    width: 17px;
    opacity: 1;
    transition: 200ms;
    margin-left: 2px;
    ${props => (props.clicked ? clickedImg : '')};
  }

  &:after {
    content: '';
    width: 80%;
    height: 2px;
    background: var(--error-color);
    position: absolute;
    left: 50%;
    border-radius: 50px;
    top: 50%;
    transition: 200ms;
    transform: translate(-50%, -50%) rotate(90deg);
    opacity: 0;
    ${props => (props.clicked ? clickedAfter : '')};
  }
`;
