import moment from 'moment';

/* eslint-disable import/prefer-default-export */

const defaultConfigurations = {
  TIP_ALERT: {
    voiceMessages: {
      amount: 500,
      enable: true,
    },
    displaySettings: {
      defaults: {
        sounds: {
          fileId: '',
          volume: 1,
          fileName: '',
          mediumId: 'DEFAULT_MEDIUM_AUDIO_1',
        },
        templates: {
          templateId: 'DEFAULT_TIP_ALERT_1',
        },
        synth: {
          voiceType: 'DISABLED',
          templateId: 'DEFAULT_TIP_ALERT_1',
        },
      },
      tresholds: {
        sounds: [
          {
            amount: 500,
            volume: 0.98,
            mediumId: 'DEFAULT_MEDIUM_AUDIO_2',
          },
          {
            amount: 2000,
            volume: 0.97,
            mediumId: 'DEFAULT_MEDIUM_AUDIO_3',
          },
          {
            amount: 11000,
            volume: 1,
            mediumId: 'DEFAULT_MEDIUM_AUDIO_4',
          },
        ],
        templates: [
          {
            amount: 500,
            templateId: 'DEFAULT_TIP_ALERT_2',
          },
          {
            amount: 2500,
            templateId: 'DEFAULT_TIP_ALERT_3',
          },
          {
            amount: 10000,
            templateId: 'DEFAULT_TIP_ALERT_4',
          },
        ],
        synth: [],
      },
    },
  },
  LARGEST_DONATES: {},
  LATEST_DONATES: {},
  TIPS_GOAL: {
    goalName: 'Przykładowa nazwa celu',
    goalValue: 100000,
    baseValue: 50000,
    currentValue: 50000,
    sumPaymentsFrom: moment().format(),
    amountWithoutCommission: true,
  },
  COUNTER_TO_END_LIVE: {
    extraTime: 60,
    beginDate: moment().format(),
    startDate: moment().format(),
    priceFromAddTime: 0,
    addTimeWithoutCommission: false,
    initialAddAmount: 0,
    userAddAmount: 0,
  },
};

export { defaultConfigurations };
