import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import Wrapper from './components/Wrapper';

const OnScreenWarning = ({ children, style }) => (
  <Wrapper style={style}>
    <WarningIcon />
    <div>{children}</div>
  </Wrapper>
);

OnScreenWarning.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.instanceOf(Object),
};

OnScreenWarning.defaultProps = {
  style: {},
};

export default OnScreenWarning;
