import styled from 'styled-components';

import CircleIcon from 'components/styled/buttons/IconInCircle';

export default styled(CircleIcon)`
  padding: 0;
  background-color: var(--light-gray);

  &[disabled] {
    background-color: var(--light-gray);
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
