export default bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 0;

  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1000)), 10);

  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1000 ** i).toFixed(2)} ${sizes[i]}`;
};
