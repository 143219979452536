import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openAlert } from 'components/Alert/utils';

import { fetchWithdrawalMethodsConfiguration } from 'store/actions/withdrawalMethodsConfiguration';

export const openDisabledAlert = () => {
  openAlert(
    'error',
    'Coś się pokićkało',
    'Dokonaj wyboru z którego bilansu konta chcesz skorzystać, aby zlecić wypłatę. Kliknij odpowiedni kafelek, a aktywuje on możliwość wypłaty środków.',
  );
};

export const useWithdrawalMethods = userData => {
  const dispatch = useDispatch();
  const { config } = useSelector(state => state.withdrawalMethodsConfiguration);

  const [expressMethods, setExpressMethods] = useState([]);
  const [standardMethods, setStandardMethods] = useState([]);

  useEffect(() => {
    dispatch(fetchWithdrawalMethodsConfiguration());
  }, []);

  useEffect(() => {
    const methodDescriptions = {
      bank_express: 'Natychmiastowy na konto bankowe',
      paypal_express: 'Natychmiastowy na konto PayPal',
      bank_standard: 'Ekspresowy na konto bankowe',
      paypal: 'Ekspresowy na konto PayPal',
    };

    const { info } = userData;

    const assembleMethod = name => {
      const methodDisabledKey = name === 'paypal' ? 'paypal_standard_disabled' : `${name}_disabled`;
      return {
        id: name,
        title: methodDescriptions[name],
        disabled: info[methodDisabledKey],
        globallyEnabled: config[name].enabled,
        commission: config[name].commission,
        minimalCommission: config[name].minimal_commission,
      };
    };

    setExpressMethods(['bank_express', 'paypal_express'].map(assembleMethod));
    setStandardMethods(['bank_standard', 'paypal'].map(assembleMethod));
  }, [userData, config]);

  return [standardMethods, expressMethods];
};
