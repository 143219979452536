import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EyeIcon } from 'assets/icons/password-eye.svg';
import Input from 'styles/Input';

import StyledPasswordInput from './styles/StyledPasswordInput';

const PasswordInput = ({ className, ...props }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <StyledPasswordInput className={className} passwordVisibility={passwordVisibility}>
      <Input {...props} type={passwordVisibility ? 'text' : 'password'} autoComplete="off" />

      <button type="button" onClick={() => setPasswordVisibility(!passwordVisibility)}>
        <EyeIcon width="22px" height="22px" />
      </button>
    </StyledPasswordInput>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
};

PasswordInput.defaultProps = {
  className: '',
};

export default PasswordInput;
