import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ConfigContext from '../ConfigContext';
import ConfigActions from './ConfigActions';
import TextActions from './TextActions';
import ImageActions from './ImageActions';
import AdditionalTextActions from './AdditionalTextActions';

const Root = styled.div`
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  border-radius: 64px;
  border: 1px solid rgba(161, 161, 161, 0.2);
  background: ${({ $isExpanded }) => ($isExpanded ? '#151616' : '#242526')};
  color: #adadad;
  overflow: hidden;
  z-index: 1;
  margin-top: 20px;
  transition: var(--hover-transition);
  padding: 0 10px;

  ${({ $disabled }) =>
    !!$disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 200%;
  transition: var(--hover-transition);
  transform: translateY(${props => (props.isExpanded ? 0 : -50)}%);

  > div {
    height: 52px;
  }
`;

/**
 * Zwraca zestaw akcji elementu w zależności od jego nazwy
 * @param {string|null} elementName
 * @returns {JSX.Element|null}
 * @constructor
 */
const ElementActions = ({ elementName, enabled }) => {
  if (!elementName) return null;

  if (['username', 'price', 'message'].includes(elementName))
    return <TextActions enabled={enabled} />;

  if (elementName.includes('visualObject')) return <ImageActions enabled={enabled} />;

  if (elementName.includes('usernameAction')) return <AdditionalTextActions enabled={enabled} />;

  return null;
};

ElementActions.propTypes = {
  elementName: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  enabled: PropTypes.bool,
};

ElementActions.defaultProps = {
  elementName: null,
  enabled: false,
};

const ConfigActionsBar = props => {
  const { focusedElementName, activeTemplate } = useContext(ConfigContext);

  const [isExpanded, setIsExpanded] = useState(!!focusedElementName);
  const [lastFocusedElementName, setLastFocusedElementName] = useState(focusedElementName);

  useEffect(() => {
    setIsExpanded(!!focusedElementName);
  }, [focusedElementName]);

  useEffect(() => {
    if (focusedElementName) setLastFocusedElementName(focusedElementName);
  }, [focusedElementName]);

  return (
    <Root $disabled={!activeTemplate.config.editable} $isExpanded={isExpanded}>
      <ContentWrapper isExpanded={isExpanded}>
        <ElementActions elementName={lastFocusedElementName} enabled={isExpanded} />
        <ConfigActions isExpanded={isExpanded} {...props} />
      </ContentWrapper>
    </Root>
  );
};

export default ConfigActionsBar;
