import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/pl';

import OptionsPopup from 'styles/OptionsPopup';

import { ArrowInCircleButton } from 'components/Buttons';
import FloatingMenu from 'components/FloatingMenu';
import InfoTooltip from 'components/InfoTooltip';

import '../styles/datetime.scss';
import Box from '../styles/Box';

const Wrapper = styled.div`
  position: relative;
`;

export default class FloatingInput extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    popupComponent: PropTypes.instanceOf(Object).isRequired,
    tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onSelectedValue: PropTypes.instanceOf(Function),
    popupPosition: PropTypes.string,
  };

  static defaultProps = {
    onSelectedValue: value => {},
    tooltipContent: '',
    popupPosition: 'right',
  };

  node = React.createRef();

  state = {
    visibility: {
      options: false,
      calendar: false,
    },
  };

  /**
   * Funkcja zarzązająca widocznością popupów
   *
   * @param {Boolean} forceValue - wartość która wymusza stan
   * @returns {void}
   */
  setVisible(config) {
    this.setState(prevState => {
      const returnedState = {
        ...prevState.visibility,
        ...config,
      };

      if (config.options && config.options === 'toggle') {
        returnedState.options = !prevState.visibility.options;
      }

      return { visibility: returnedState };
    });
  }

  onChangeDate = (momentDateObject, test) => {
    const { onSelectedValue } = this.props;

    onSelectedValue(moment(momentDateObject).format());
  };

  render() {
    const { text, popupComponent, tooltipContent, popupPosition } = this.props;
    const { visibility } = this.state;

    return (
      <Wrapper ref={this.node}>
        <Box style={{ paddingTop: 12.5, paddingBottom: 12.5 }}>
          <span style={{ position: 'relative' }}>
            {text}

            {Boolean(tooltipContent) && (
              <InfoTooltip
                style={{ position: 'absolute', top: -10, right: -20 }}
                content={tooltipContent}
                placement="right"
                theme="dark"
              />
            )}
          </span>

          <ArrowInCircleButton
            background="green"
            onClick={() => this.setVisible({ options: 'toggle' })}
          />
        </Box>

        <FloatingMenu
          visible={visibility.options}
          position={popupPosition}
          onClose={() => this.setVisible({ options: false })}
          parent={this.node.current || document.body}
          style={{ minWidth: 1 }}
        >
          <OptionsPopup style={{ minWidth: '112px', padding: '5px' }}>
            {popupComponent}
          </OptionsPopup>
        </FloatingMenu>
      </Wrapper>
    );
  }
}
