import { connect } from 'react-redux';

import { submitUpdatedGlobalConfiguration } from 'store/actions/configuratorsConfigs';

import FilterWords from './FilterWords';

const mapStateToProps = ({ configuratorsConfigs: { globals } }) => ({
  profanityFilter: globals.profanity_filter,
  forbiddenWords: globals.forbidden_words,
});

const mapDispatchToProps = dispatch => ({
  submitUpdatedGlobalConfiguration(...args) {
    dispatch(submitUpdatedGlobalConfiguration(...args));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterWords);
