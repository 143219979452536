import React from 'react';
import PropTypes from 'prop-types';

import Shadow from 'pages/Configurators/styles/Shadow';

import MarginX from '../styles/MarginX';

import elementProps from '../data/elementsProps';

const GoalNumbersShadow = ({ options, elementName }) => {
  if (options.isVisible) {
    return (
      <MarginX>
        <Shadow style={{ ...options.styles }} data-type={elementName}>
          {elementProps[elementName].shadow === null
            ? options.textValue || elementProps[elementName].textValue
            : elementProps[elementName].shadow}
        </Shadow>
      </MarginX>
    );
  }

  return null;
};

GoalNumbersShadow.propTypes = {
  elementName: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
};

export default GoalNumbersShadow;
