import styled from 'styled-components';

export default styled.div`
  height: 100%;

  .dropzone {
    margin-top: 24px;
    border: 1px dashed #747474;
    height: 270px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 1400px) {
      height: 180px;
    }

    &__insider {
      justify-content: center;
      width: 100%;
      text-align: center;
    }

    &__text {
      font-size: 22px;
      color: var(--text-color);
      line-height: 150%;
    }

    &__small {
      font-size: 11px;
    }

    &__chose_media {
      font-size: 10px;
      padding: 5px 10px;
      background: var(--bg-color-white-gray);
      border: 1px solid var(--gray);
    }

    &__media_wrap {
      position: relative;
      height: calc(100% - 440px);
      margin: 10px 0 25px 0;

      @media screen and (max-width: 1400px) {
        height: calc(100% - 333px);
      }
    }

    &__allowed_extensions {
      color: #8e8e8e;
      font-size: 13px;
      margin-bottom: 17px;
    }

    &__upload_buttons_wrap {
      text-align: center;
      display: flex;
      justify-content: space-around;

      button {
        width: 274px;
        padding: 20px 25px;
        border-radius: 10px;

        &:first-child {
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
`;
