import { useState } from 'react';

const DEFAULT_STYLE_MENU_OPTS = {
  elementName: '',
  variant: '',
  target: null,
  closeOnConfirm: false,
};

const useTextEditor = () => {
  const [textEditor, setTextEditor] = useState(DEFAULT_STYLE_MENU_OPTS);

  const toggleTextEditor = (elementName, variant = 'style', forceClose = false) => {
    let state = DEFAULT_STYLE_MENU_OPTS;

    if (!elementName) setTextEditor(state);

    const target = document.getElementById(`${elementName}__screen_element`);
    if (target) {
      const closeOnConfirm = textEditor.elementName !== elementName ? forceClose : false;
      state = { elementName, variant, target, closeOnConfirm };
    }

    setTextEditor(state);
  };

  return {
    textEditor,
    setTextEditor,
    toggleTextEditor,
  };
};

export default useTextEditor;
