import styled from 'styled-components';

import { MIN_DESKTOP } from 'styles/base/breakpoints';

export default styled.div`
  &.box {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    background-color: var(--white);
    padding: 30px;
    gap: 25px;
    @media (${MIN_DESKTOP}) {
      flex-direction: row;
      justify-content: space-between;
      padding: 13px 60px;
    }
  }
`;
