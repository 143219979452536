import styled from 'styled-components';

export default styled.div`
  width: 4px;
  height: 4px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1.5px, rgba(0, 0, 0, 0.3) 0px 0px 1px 1px inset,
    rgba(0, 0, 0, 0.4) 0px 0px 1px 2px;
  border-radius: 50%;
  transform: translate(-2px, -2px);
`;
