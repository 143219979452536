import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ColorfulRoundedButton } from 'components/Buttons';

import ItemContainer from '../ItemContainer';
import ActiveState from '../ActiveState';
import Settings from './components/Settings';
import ConnectWizard from './components/ConnectWizard';

const ExternalMethod = ({ match, history, enabled, onChange, ...props }) => {
  const [settingsMode, setSettingsMode] = useState(false);
  const currentSource = useSelector(store => {
    const {
      userData: { info },
    } = store;
    return Object.prototype.hasOwnProperty.call(info, 'tips_external_source') &&
      info.tips_external_source.length
      ? info.tips_external_source
      : null;
  });

  useEffect(() => {
    if (!currentSource) {
      setSettingsMode(false);
    }
  }, [currentSource]);

  const wizardRoute = `${match.url}/podlacz-zewnetrzne-zrodlo`;

  return settingsMode && currentSource ? (
    <Settings enabled={enabled} onClose={() => setSettingsMode(false)} onChange={onChange} />
  ) : (
    <ItemContainer withMinAmount={false} {...props}>
      {currentSource ? (
        <ActiveState onSettingsRequested={() => setSettingsMode(true)} />
      ) : (
        <ColorfulRoundedButton onClick={() => history.push(wizardRoute)}>
          Aktywuj
        </ColorfulRoundedButton>
      )}
      <Router history={history}>
        <Switch>
          <Route path={wizardRoute} exact>
            {currentSource ? <Redirect to={match.url} /> : <ConnectWizard />}
          </Route>
        </Switch>
      </Router>
    </ItemContainer>
  );
};

export default withRouter(ExternalMethod);

ExternalMethod.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
