import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as statisticsActions from 'store/actions/statistics';
import * as userDataActions from 'store/actions/userData';
import * as commissionsActions from 'store/actions/commissions';

import PointsAndBonuses from './PointsAndBonuses';

const mapStateToProps = ({
  statistics: { data: statisticsData, isFetched: statisticsAreFetched },
  userData,
  commissions: { tresholds: commissionsThresholds },
}) => ({
  statisticsData,
  statisticsAreFetched,
  userData,
  commissionsThresholds,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...statisticsActions, ...userDataActions, ...commissionsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointsAndBonuses);
