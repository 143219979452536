import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PaintIcon } from 'assets/icons/paint.svg';
import { ReactComponent as TypographyIcon } from 'assets/icons/typography.svg';
import { ReactComponent as FontSizeIcon } from 'assets/icons/font-size.svg';
import { ReactComponent as CogIcon } from 'assets/icons/cog.svg';

import ConfigContext from '../ConfigContext';
import { StyledButton } from './ElementActionButton';
import ElementActions from './ElementActions';

const TextActions = ({ enabled }) => {
  const { focusedElementName, toggleTextEditor } = useContext(ConfigContext);

  const eventHandler = (evt, option, flag = false) => {
    if (enabled) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      toggleTextEditor(focusedElementName, option, flag);
    }
  };

  const handleChangeColor = evt => {
    eventHandler(evt, 'color', true);
  };

  const handleChangeFont = evt => {
    eventHandler(evt, 'fontFamily', true);
  };

  const handleChangeSize = evt => {
    eventHandler(evt, 'fontSize', true);
  };

  const handleOtherOptions = evt => {
    eventHandler(evt, 'style');
  };

  return (
    <ElementActions enabled={enabled}>
      <StyledButton onClick={handleChangeColor} rgb>
        <PaintIcon />
        Zmień kolor
      </StyledButton>

      <StyledButton onClick={handleChangeFont}>
        <TypographyIcon />
        Zmień czcionkę
      </StyledButton>

      <StyledButton onClick={handleChangeSize}>
        <FontSizeIcon />
        Zmień rozmiar
      </StyledButton>

      <StyledButton onClick={handleOtherOptions}>
        <CogIcon />
        Pozostałe opcje
      </StyledButton>
    </ElementActions>
  );
};

TextActions.propTypes = {
  enabled: PropTypes.bool,
};

TextActions.defaultProps = {
  enabled: false,
};

export default TextActions;
