import styled, { css } from 'styled-components';
import WhiteBox from 'styles/WhiteBox';
import Content from './Content';
import { Dot } from './Toggle';

const visible = css`
  height: 45px !important;

  ${Content} {
    opacity: 0;
  }

  ${Dot} {
    transform: translateY(-50%) rotate(90deg);
  }
`;

const faded = css`
  background-color: var(--bg-color-disabled);
  transition: height 0.2s cubic-bezier(0.65, 0.05, 0.36, 1),
    background-color 0.25s var(--ease-out-cubic);

  > * {
    opacity: 0.2;
    transition: opacity 0.25s var(--ease-out-cubic);
  }
`;

const disabled = css`
  cursor: not-allowed;
  user-select: none;

  > * {
    pointer-events: none;
  }
`;

const Container = styled(WhiteBox)`
  display: flex;
  padding: 0 35px;
  height: 100%;
  transition: height 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  .overlay {
    z-index: 20;
  }

  ${props => !props.visible && visible}
  ${props =>
    props.oHidden &&
    css`
      overflow: hidden;
    `}

  ${props => props.disabled && disabled}
  ${props => props.faded && faded}
`;

export default Container;
