import React from 'react';
import PropTypes from 'prop-types';

import Item from './components/Item';

const LatestTips = ({ items }) => (
  <ul className="reset-list">
    {items.map(item => (
      <Item key={item.id} item={item} />
    ))}
  </ul>
);

LatestTips.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default LatestTips;
