import React from 'react';

import { useTitle } from 'utils/hooks';

import withConfiguration from '../containers/withConfiguration';
import withHistory from '../containers/withHistory';
import ListView from './ListView';

const CONFIGURATOR_TYPE = 'LATEST_DONATES';

const LatestDonates = props => {
  useTitle('Tipply | Konfigurator (Lista ostatnich wiadomości)');

  return <ListView widgetsUrl="LATEST_MESSAGES" configuratorType={CONFIGURATOR_TYPE} {...props} />;
};

export default withConfiguration(withHistory(LatestDonates), CONFIGURATOR_TYPE);
