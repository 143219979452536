import styled from 'styled-components';

export default styled.div`
  animation: 1s tipIn ease;
  position: relative;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0px 0px 0px 0px;
    background-color: var(--white);
    margin-top: 10px;

    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 35px;
    }
  }

  .donator {
    width: 150px;
    min-width: 150px;
    padding: 0 0px;
    display: flex;

    p {
      width: 100%;
    }

    @media screen and (max-width: 500px) {
      display: block;
      text-align: center;
    }
  }

  .message {
    width: 515px;
    padding: 0 15px;

    @media screen and (max-width: 992px) {
      width: 100%;
      text-align: center;
      margin: 25px 0;
    }
  }

  .nicknamewrap {
    width: 100px;
    word-wrap: break-word;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;

    button {
      background: var(--light-gray);
      margin: 5px;
      border: 0px;
      outline: 0px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      border-radius: 50px;
      vertical-align: top;
      transition: 250ms;
      transform: scale(1);
      transition-delay: 250ms;

      &:hover {
        transform: scale(0.8);

        img {
          transform: rotate(-25deg);
        }
        svg {
          transform: rotate(-25deg);
        }
      }

      img {
        padding-top: 4px;
        width: 20px;
        transform: rotate(0deg);
        transition: 250ms;
      }
      svg {
        padding-top: 4px;
        width: 20px;
        transform: rotate(0deg);
        transition: 250ms;
      }
    }
  }

  &.loading {
    user-select: none;
    cursor: wait;
    opacity: 0.8;
  }

  .spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    .spinner-path {
      stroke: var(--green);
    }
  }
`;
