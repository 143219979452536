import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px 18px;

  > svg {
    width: 22px;
    margin-right: 15px;
  }

  > span {
    font-size: 14px;
    font-weight: 400;
  }
`;
