import styled from 'styled-components';

export default styled.div`
  width: 38px;
  height: 38px;
  border: 2px solid var(--border-color);
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
