import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';

import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';

import { GeneralSettingsButton } from 'components/Buttons';
import Modal from 'components/Modal';
import SaveInfo from 'components/Modal/SaveInfo';
import Footer from 'components/Modal/styles/Footer';
import FooterButton from 'components/Modal/styles/FooterButton';
import SettingsDialogContent from 'components/Modal/styles/SettingsDialogContent';
import TextDescription from 'components/Modal/styles/TextDescription';
import TextHeader from 'components/Modal/styles/TextHeader';
import TextTop from 'components/Modal/styles/TextTop';

import { fetchGoalsIfNeeded } from 'store/actions/goals';
import { changeConfig } from 'store/actions/goalsVoting';

import { MAX_GOALS_IN_VOTING } from '../../constants';
import { useVotingStore } from '../../hooks';

import GoalSlot from './components/GoalSlot';

const GoalsSelector = ({ open, onOpen, onClose }) => {
  const dispatch = useDispatch();
  const [alreadySelected, setAlreadySelected] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const {
    config: { goals },
  } = useVotingStore();

  const [selectors, setSelectors] = useState(
    goals.map((goal, index) => ({
      index,
      goal,
    })),
  );

  useEffect(() => {
    dispatch(fetchGoalsIfNeeded());
  }, []);

  useEffect(() => {
    setAlreadySelected(getSelectorsWithValues().map(s => s.goal.goal.id));
  }, [selectors]);

  useEffect(() => {
    if (hasChanged) {
      dispatch(
        changeConfig({
          goals: getSelectorsWithValues().map(s => s.goal),
        }),
      );
      setHasChanged(false);
    }
  }, [selectors, hasChanged]);

  const getSelectorsWithValues = () => selectors.filter(s => s.goal.goal !== null);

  const onSelectorChange = (slot, goal) => {
    setHasChanged(true);
    setSelectors(prevState => [
      ...prevState.slice(0, slot.index),
      ...[{ ...slot, goal }],
      ...prevState.slice(slot.index + 1),
    ]);
  };

  const onSelectorRemove = slot => {
    setSelectors(prevState =>
      prevState.filter(s => s.index !== slot.index).map((s, index) => ({ ...s, index })),
    );

    if (slot.goal.goal) {
      setHasChanged(true);
    }
  };

  const addGoal = () => {
    setSelectors(prevState => [
      ...prevState,
      {
        index: prevState.length,
        goal: {
          goal: null,
          color: null,
        },
      },
    ]);
  };

  const addingDisabled = selectors.length >= MAX_GOALS_IN_VOTING;

  return (
    <>
      <GeneralSettingsButton
        onClick={onOpen}
        color="blue"
        label="Wybór celów do głosowania"
        info="Ustal jakie cele mają brać udział w głosowaniu. Stwórz konkurencję!"
        icon={<PercentIcon />}
      />

      <Modal isMounted={open} onClose={onClose}>
        <SettingsDialogContent>
          <div>
            <TextTop>Stwórz głosowanie na cele</TextTop>
            <TextHeader>
              Wybierz cele
              <br />
              {'i stwórz głosowanie'}
            </TextHeader>
            <TextDescription>
              Dzięki tej funkcji możesz stworzyć głosowanie
              <br />
              w którym będzie konkurował cel 1 z celem 2.. itd.
              <br />
              Przeprowadź wielką wojnę w formie głosowania
              <br />
              i niech cel z większym bilansem wygra! Wyłoń zwycięzce!
              <br />
              Kwota “do zebrania” w wybranych celach nie ma znaczenia.
            </TextDescription>
          </div>
          <Scrollbars
            className="scrollbar scrollbar--outside"
            hideTracksWhenNotNeeded
            style={{ height: '100%' }}
          >
            {selectors.map(slot => (
              <GoalSlot
                key={slot.index}
                value={slot}
                alreadySelected={alreadySelected}
                onChange={e => onSelectorChange(slot, e)}
                onRemove={() => onSelectorRemove(slot)}
              />
            ))}
          </Scrollbars>
        </SettingsDialogContent>
        <Footer>
          <FooterButton
            background="var(--brand-blue)"
            type="button"
            onClick={addGoal}
            disabled={addingDisabled}
          >
            {addingDisabled
              ? `Możesz wybrać maksymalnie ${MAX_GOALS_IN_VOTING} cele`
              : 'Dodaj kolejny cel do głosowania'}
          </FooterButton>
          <SaveInfo />
        </Footer>
      </Modal>
    </>
  );
};

GoalsSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.instanceOf(Function).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default GoalsSelector;
