import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';

import ArrowButton from 'components/Modal/ArrowButton';
import PopupDialog from 'components/Modal/PopupDialog';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const ColorSelector = ({ color, onChange, buttonProps }) => {
  const buttonRef = useRef();
  const [showPicker, setShowPicker] = useState(false);

  return (
    <Wrapper>
      Wybierz kolor dla tego celu:
      <div>
        <ArrowButton
          ref={buttonRef}
          label={color}
          color={color}
          withTextShadow
          onClick={() => setShowPicker(!showPicker)}
          {...buttonProps}
        />
        <PopupDialog
          visible={showPicker}
          snapTo={buttonRef.current}
          onClose={() => setShowPicker(false)}
        >
          {showPicker && (
            <ChromePicker color={color} disableAlpha width="100%" onChangeComplete={onChange} />
          )}
        </PopupDialog>
      </div>
    </Wrapper>
  );
};

ColorSelector.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  buttonProps: PropTypes.instanceOf(Object),
};

ColorSelector.defaultProps = {
  buttonProps: {},
};

export default ColorSelector;
