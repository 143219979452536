import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Root = styled.div`
  color: #adadad;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  column-gap: 12px;

  h6,
  p {
    margin: 0;
    font-family: var(--google-sora-cdn);
    font-size: 10px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    color: var(--grey);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: #606060;
    flex-grow: 1;
  }
`;

const TitledSeparator = ({ title, hint, ...rest }) => (
  <Root {...rest}>
    <div>
      <h6>{title}</h6>
      {hint && <p>{hint}</p>}
    </div>
  </Root>
);

TitledSeparator.propTypes = {
  title: PropTypes.string.isRequired,
  hint: PropTypes.string,
};

TitledSeparator.defaultProps = {
  hint: null,
};

export default TitledSeparator;
