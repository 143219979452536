import styled from 'styled-components';

export default styled.button`
  cursor: pointer;

  font-size: 30px;
  line-height: 30px;

  background: none;
  color: #c4c4c4;

  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;

  transition: var(--hover-transition);
  &:active {
    transform: scale(0.96);
  }
`;
