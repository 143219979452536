import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: 345px 802px 345px;
  grid-gap: 0 39px;
  align-content: start;
  height: 100%;
  width: 1570px;
  margin-bottom: 2em;
`;
