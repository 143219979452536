import styled, { css } from 'styled-components';

import BlankButton from '../styles/BlankButton';

const active = css`
  > svg {
    transform: rotateX(180deg);
  }

  &::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export default styled(BlankButton).attrs(({ rounded }) => ({
  borderRadius: rounded ? '23px' : '10px',
  padding: rounded ? '16px 20px 17px' : '20px 30px',
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  position: relative;
  width: 100%;

  text-align: left;
  font-weight: 500;

  background-color: var(--green);
  color: #fff;

  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};

  &::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background-color: var(--green-secondary);
    border-radius: 100%;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  > svg {
    fill: #fff;
    width: 15px;
    transition: 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  * {
    position: relative;
    z-index: 1;
  }

  ${params => params.active && active};
`;
