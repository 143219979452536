import React, { Component } from 'react';
import StyledSearch from '../styles/StyledSearch';
import SearchSvg from '../../../icons/search.svg';
import { Consumer as ContextConsumer } from '../Context';

class Search extends Component {
  constructor(props) {
    super(props);

    this.searchValue = '';
  }

  handleChange(event) {
    this.searchValue = event.target.value;
  }

  render() {
    return (
      <ContextConsumer>
        {({ search }) => (
          <StyledSearch>
            <button type="button" onClick={() => search(this.searchValue)}>
              <img src={SearchSvg} alt="search" />
            </button>
            <input
              type="text"
              placeholder="Szukaj..."
              onChange={event => {
                this.handleChange(event);
                search(this.searchValue);
              }}
              onKeyPress={() => search(this.searchValue)}
            />
          </StyledSearch>
        )}
      </ContextConsumer>
    );
  }
}

export default Search;
