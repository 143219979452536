import React from 'react';
import styled from 'styled-components';

import Odometer from 'components/Odometer';

const Wrapper = styled.div`
  .odometer {
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 900;
    color: var(--text-color);
    margin-right: 10px;
  }

  .odometer-formatting-mark {
    position: relative;
    top: 3px;
  }
`;

export default props => (
  <Wrapper>
    <Odometer {...props} />
  </Wrapper>
);
