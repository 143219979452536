import React from 'react';
import PropTypes from 'prop-types';

import ItemContainer from './ItemContainer';
import MethodStateSwitch from './MethodStateSwitch';

const Item = ({
  paymentName,
  enabled,
  onChange,
  price,
  methodState,
  isPpe,
  tooltip,
  hoverText,
  disabledForNotVerified,
}) => (
  <ItemContainer
    paymentName={paymentName}
    tooltip={tooltip}
    onChange={onChange}
    currentMinAmount={price}
    methodState={methodState}
    isPpe={isPpe}
    hoverText={hoverText}
    disabledForNotVerified={disabledForNotVerified}
  >
    <MethodStateSwitch enabled={enabled} onChange={onChange} />
  </ItemContainer>
);

export default Item;

Item.propTypes = {
  paymentName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  methodState: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  isPpe: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  disabledForNotVerified: PropTypes.bool.isRequired,
};
