import 'swiper/swiper-bundle.css';
import { Player } from '@lottiefiles/react-lottie-player';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-2.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import StopTimer from 'assets/animations/stopwatch.json';
import StopTimerDark from 'assets/animations/stopwatch-dark.json';
import Confetti from 'assets/animations/confetti.json';
import Text from 'components/Text';
import WithUserData from 'containers/WithUserData';
import { fetchLatestWithdrawalsIfNeeded } from 'store/actions/latestWithdrawals';
import WhiteBox from 'styles/WhiteBox';

const statusText = {
  step_1: {
    label: 'Krok 1',
    title: 'Przelew został zlecony 💨',
  },
  step_2: {
    label: 'Krok 2',
    title: 'Wypłata została wysłana! 👀',
  },
  step_3: {
    label: 'Wykonana',
    title: 'Wypłata zrealizowana! 👏',
    description: (
      <>
        Wypłata jest już u Ciebie na koncie! Ale szybkie to było 🥵💨
        <br />
        Kiedy zlecamy kolejną? 🌚 Zgłoś problem z wypłatą
      </>
    ),
  },
  error: {
    label: 'Błąd?',
    title: 'Chyba mamy jakiś problem? 🤔',
  },
};

const Root = styled(WhiteBox)`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 64px;
`;

const Header = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const StopWatchAnimation = styled.div``;

const WithdrawalInfo = styled.div`
  border-radius: 5px;
  padding: 6px 12px;
  background: var(--blue-secondary);
  color: white;
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 7px;
`;

const Heading = styled.h2`
  font-family: var(--google-sora-cdn);
  font-size: 20px;
  font-weight: 800;
  line-height: 102.06%;
  text-transform: uppercase;
  margin: 0;
`;

const Information = styled.div`
  padding: 17px 18px;
  border-radius: 10px;
  border: 1px dashed #606060;
  display: grid;
  row-gap: 6px;
`;

const Info = styled(Text)`
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const Subtitle = styled.strong`
  text-transform: uppercase;
  font-weight: 700;
`;

const StepInfo = styled.div`
  max-width: 325px;
  flex-grow: 1;
`;

const StepInfoHeader = styled.div`
  display: flex;
  column-gap: 11px;
  align-items: center;
`;

const StatusLabel = styled.div`
  box-sizing: border-box;
  padding: 0 13px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid
    ${({ hasError }) => (hasError && css`var(--color-danger)`) || css`var(--blue-secondary)`};
  border-radius: 5px;
  background-color: ${({ hasError, isFilled }) =>
    ((hasError || isFilled) &&
      ((hasError && css`var(--color-danger)`) || css`var(--blue-secondary)`)) ||
    'transparent'};
  color: ${({ hasError, isFilled }) =>
    ((hasError || isFilled) && css`var(--white-white)`) || css`var(--text-color)`};
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`;

const StatusTitle = styled.h4`
  font-family: var(--google-sora-cdn);
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

const StatusDescription = styled.p`
  color: #adadad;
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  margin: 7px 0 12px;
`;

const ChatLink = styled.a`
  color: ${({ variant }) => (variant === 'info' ? css`var(--black)` : css`var(--color-danger)`)};
  cursor: pointer;
  font: inherit;
  font-weight: bold;
`;

const IndicatorsRoot = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 11px;
  height: 9px;
`;

const Indicator = styled.div`
  @keyframes loadBar {
    0% {
      left: -110%;
    }

    100% {
      left: 110%;
    }
  }

  position: relative;
  overflow: hidden;
  color: ${({ isLit, hasError }) =>
    (isLit && ((hasError && css`var(--color-danger)`) || css`var(--blue-secondary)`)) ||
    css`var(--bg-indicator)`};
  background-color: currentColor;
  border-radius: 5px;
  flex-grow: 1;
  ${({ isLit, isGlowing }) =>
    isLit &&
    isGlowing &&
    css`
      box-shadow: 0 0 15.9px 0 currentColor;
    `}

  ${({ currentStep, hasError, isGlowing }) =>
    currentStep &&
    !hasError &&
    !isGlowing &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--bg-indicator);
        animation: loadBar 2s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
      }
    `}
`;

const OutlinedIconButton = styled.button`
  color: var(--gray);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 32px;
  aspect-ratio: 1 / 1;
  border: 1px solid;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: var(--color-white);
  }

  svg {
    width: 16px;

    path {
      fill: currentColor;
    }
  }
`;

const SwiperContainer = styled.div`
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    margin: 0;
    top: 50%;
    width: 32px;
    height: 32px;
    aspect-ratio: 1 / 1;
    background-color: var(--white-black);
    color: var(--text-color-white);
    cursor: pointer;
    border: none;
    box-shadow: none;
    border-radius: 50%;

    &:hover {
      background-color: var(--bg-color-grey-button);
    }

    &::before,
    &::after {
      content: unset;
    }

    svg {
      width: 14px;
    }
  }

  .swiper-button-prev {
    left: 0;
    transform: translate(-50%, -50%);

    svg {
      transform: rotate(180deg);
    }
  }

  .swiper-button-next {
    right: 0;
    transform: translate(50%, -50%);
  }
`;

const OpenLiveChatLink = ({ children, variant = 'danger' }) => {
  const handleClick = () => {
    window.LiveChatWidget.call('maximize');
  };

  return (
    <ChatLink onClick={handleClick} variant={variant}>
      {children}
    </ChatLink>
  );
};

OpenLiveChatLink.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['danger', 'info']),
};

OpenLiveChatLink.defaultProps = {
  variant: 'danger',
};

const StepIndicators = ({ stepNumber, stepCount, ...props }) => {
  const steps = [...Array(stepCount).keys()].map(v => v + 1);

  return (
    <IndicatorsRoot>
      {steps.map(step => (
        <Indicator
          key={`step_${step}`}
          currentStep={step === stepNumber}
          isLit={step <= stepNumber}
          {...props}
        />
      ))}
    </IndicatorsRoot>
  );
};

StepIndicators.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  isGlowing: PropTypes.bool,
  hasError: PropTypes.bool,
};

StepIndicators.defaultProps = {
  isGlowing: false,
  hasError: false,
};

const CloseButton = props => (
  <OutlinedIconButton {...props}>
    <CloseIcon />
  </OutlinedIconButton>
);

const PendingWithdrawal = ({
  userDataInfo,
  withdrawal,
  withdrawalNumber,
  withdrawalCount,
  onClose,
}) => {
  const stepCount = 3;
  const isDarkTheme = userDataInfo.color_theme === 'dark';
  const { id, stepNumber, status, amount, method, requestedAt, targetAccount } = withdrawal;
  const hasError = status === 'error';
  const isComplete = stepNumber === stepCount;

  const statusInfo = statusText[status];

  const closeHandle = () => {
    Cookies.set(`hide_payment_${id}`, 1, { expires: 7 });
    onClose();
  };

  const animationProps = {
    autoplay: true,
    loop: true,
    style: { height: '72px', width: '72px' },
  };

  return (
    <Root>
      <Header>
        <StopWatchAnimation>
          {isComplete ? (
            <Player {...animationProps} src={Confetti} />
          ) : (
            <Player {...animationProps} src={isDarkTheme ? StopTimerDark : StopTimer} />
          )}
        </StopWatchAnimation>
        <div>
          <WithdrawalInfo>{`${withdrawalNumber} z ${withdrawalCount}`}</WithdrawalInfo>
          <Heading>
            Status
            <br />
            wypłaty
          </Heading>
        </div>
      </Header>

      <Information>
        <Info>
          <Subtitle>Na kwotę: </Subtitle>
          {`${amount} PLN`}
        </Info>
        <Info>
          <Subtitle>Metodą: </Subtitle>
          {method}
        </Info>
        <Info>
          <Subtitle>Zlecono dnia: </Subtitle>
          {requestedAt}
        </Info>
        <Info>
          <Subtitle>Na konto: </Subtitle>
          {targetAccount}
        </Info>
      </Information>

      <StepInfo>
        <StepInfoHeader>
          <StatusLabel hasError={hasError} isFilled={isComplete}>
            {statusInfo.label}
          </StatusLabel>
          <StatusTitle>{statusInfo.title}</StatusTitle>
        </StepInfoHeader>
        <StatusDescription>
          {status === 'step_1' && (
            <>
              Przygotowujemy Twoją wypłatę! Środki zostaną do Ciebie wysłane w ciągu 1-2 dni, a
              maksymalnie 4 dni roboczych.
            </>
          )}

          {status === 'step_2' && (
            <>
              Twoja wypłata wyruszyła w podróż! Przed nią teraz cudowna droga do Twojego portfela!
              Dotrze w najbliższy dzień roboczy!
            </>
          )}

          {status === 'step_3' && (
            <>
              Wypłata jest już u Ciebie na koncie! Ale szybkie to było 🥵💨
              <br />
              {'Kiedy zlecamy kolejną? 🌚 '}
              <OpenLiveChatLink variant="info">Zgłoś problem z wypłatą</OpenLiveChatLink>
            </>
          )}

          {hasError && (
            <>
              Twoja wypłata chyba do Ciebie nie dotarła...
              <br />
              {'Możliwe, że wystąpił jakiś problem. '}
              <OpenLiveChatLink>Napisz do nas na LiveChat!</OpenLiveChatLink>
            </>
          )}
        </StatusDescription>
        <StepIndicators
          stepNumber={stepNumber}
          stepCount={stepCount}
          isGlowing={isComplete}
          hasError={hasError}
        />
      </StepInfo>

      <div>
        <CloseButton onClick={closeHandle} />
      </div>
    </Root>
  );
};

PendingWithdrawal.propTypes = {
  userDataInfo: PropTypes.instanceOf(Object).isRequired,
  withdrawal: PropTypes.shape({
    stepNumber: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    commissionedOn: PropTypes.string.isRequired,
    targetAccount: PropTypes.string.isRequired,
  }).isRequired,
  withdrawalNumber: PropTypes.number.isRequired,
  withdrawalCount: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

const PendingWithdrawalItem = WithUserData(PendingWithdrawal);

SwiperCore.use([Navigation]);

const Pending = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLatestWithdrawalsIfNeeded());
  }, []);

  const withdrawals = useSelector(state => state.latestWithdrawals.items);
  const [visibleWithdrawals, setVisibleWithdrawals] = useState([]);

  const [forceUpdate, setForceUpdate] = useState(false);

  function hideClosedWithdrawals() {
    setVisibleWithdrawals(withdrawals.filter(p => !Cookies.get(`hide_payment_${p.id}`)));
  }

  useEffect(() => {
    hideClosedWithdrawals();
  }, [withdrawals]);

  useEffect(() => {
    if (forceUpdate) {
      setForceUpdate(false);
      hideClosedWithdrawals();
    }
  }, [forceUpdate, withdrawals]);

  const closeItemHandle = () => {
    setForceUpdate(true);
  };

  if (!visibleWithdrawals.length) return null;

  return (
    <SwiperContainer>
      {withdrawals.length > 1 && (
        <>
          <button type="button" className="swiper-button-prev">
            <ArrowRight />
          </button>
          <button type="button" className="swiper-button-next">
            <ArrowRight />
          </button>
        </>
      )}
      <Swiper
        slidesPerView={1}
        navigation={
          withdrawals.length > 1 && {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }
        }
      >
        {visibleWithdrawals.map((payment, index) => (
          <SwiperSlide key={`payment_slide_${payment.id}`}>
            <PendingWithdrawalItem
              withdrawal={payment}
              withdrawalNumber={index + 1}
              withdrawalCount={visibleWithdrawals.length}
              onClose={closeItemHandle}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default Pending;
