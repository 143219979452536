import styled from 'styled-components';

import Input from 'styles/Input';

export default styled.div`
  position: relative;

  > ${Input} {
    padding-right: 50px;
  }

  > button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);

    font-size: 0;
    padding: 0;
    border: 0;
    background: none;

    > svg {
      transition: fill 0.2s ease;
      fill: ${props => (props.passwordVisibility ? 'var(--blue)' : 'var(--text-color-light-gray)')};
    }
  }
`;
