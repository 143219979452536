import React, { useState, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { SimpleCheckbox } from 'components/Checkboxes';
import { ReactComponent as Stopwatch } from 'assets/icons/stopwatch.svg';
import { DisplayDurationHint } from 'store/data/hints';
import PanelBlock from '../../../../components/PanelBlock';
import ConfigContext from '../../../../ConfigContext';
import Range from './components/Range';

const Controls = styled.div`
  position: relative;
  overflow: hidden;
  height: 33px;

  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 18px;
`;

const FloatingContainer = styled.div`
  position: relative;
  z-index: 2;

  input + span svg {
    stroke: var(--text-color-white);
  }
`;

const LabelContainer = styled(FloatingContainer)`
  overflow: hidden;
  flex-grow: 1;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const CheckboxLabel = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  transform: translateX(0);
  transition: all 0.25s ease-in-out;

  font-family: var(--cdn-google-sora);
  font-weight: 800;
  font-size: 10px;
  color: var(--text-color);
  text-transform: uppercase;

  ${({ isWrapped }) =>
    isWrapped &&
    css`
      transform: translateX(calc(100% - 30px));
      pointer-events: none;
    `}
`;

const RangeContainer = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  overflow: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}
`;

const Root = styled.div`
  display: grid;
  row-gap: 8px;
  position: relative;
  z-index: 1;
`;

const AnimationDuration = props => {
  const { onChange } = props;

  const { activeTemplate, latestTemplateConfig, editableProxy } = useContext(ConfigContext);

  const templateAutoDuration = animationSettings =>
    animationSettings && !!animationSettings.displayDuration
      ? !!animationSettings.autoDisplayDuration
      : true;

  const templateDisplayDuration = animationSettings =>
    animationSettings ? animationSettings.displayDuration || 7 : 7;

  const [autoDisplayDuration, setAutoDisplayDuration] = useState(
    templateAutoDuration(latestTemplateConfig.animation),
  );
  const [displayDuration, setDisplayDuration] = useState(
    templateDisplayDuration(latestTemplateConfig.animation),
  );

  const handleToggleAutoDuration = value => {
    editableProxy(() => {
      onChange({ autoDisplayDuration: value, displayDuration });
    });
  };

  const handleChangeDuration = value => {
    editableProxy(() => {
      onChange({ displayDuration: value });
    });
  };

  useEffect(() => {
    setAutoDisplayDuration(templateAutoDuration(latestTemplateConfig.animation));
    setDisplayDuration(templateDisplayDuration(latestTemplateConfig.animation));
  }, [activeTemplate, latestTemplateConfig]);

  return (
    <Root>
      <PanelBlock
        title="Zmień długość wyświetlania donejta"
        startIcon={<Stopwatch />}
        hint={<DisplayDurationHint />}
      >
        Długość wyświetlania
      </PanelBlock>

      <Controls>
        <RangeContainer isVisible={!autoDisplayDuration}>
          <Range
            value={displayDuration}
            defaultValue={templateDisplayDuration(latestTemplateConfig.animation)}
            onChange={handleChangeDuration}
          />
        </RangeContainer>

        <LabelContainer isDisabled={!autoDisplayDuration}>
          <CheckboxLabel htmlFor="auto-donation-time" isWrapped={!autoDisplayDuration}>
            Automatyczny czas trwania donejta
          </CheckboxLabel>
        </LabelContainer>

        <FloatingContainer>
          <SimpleCheckbox
            color="brand-green"
            name="auto-donation-time"
            checked={autoDisplayDuration}
            onCheck={handleToggleAutoDuration}
          />
        </FloatingContainer>
      </Controls>
    </Root>
  );
};

AnimationDuration.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default AnimationDuration;
