import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Line from '../styles/Line';
import Element from '../styles/Element';

const AlignIcon = props => {
  const { align } = props;

  return (
    <Element type="button" {...props}>
      {align === 'justify' ? (
        <Fragment>
          <Line />
          <Line short align="center" />
          <Line />
        </Fragment>
      ) : (
        <Fragment>
          <Line />
          <Line />
          <Line short align={align} />
        </Fragment>
      )}
    </Element>
  );
};

AlignIcon.propTypes = {
  align: PropTypes.string.isRequired,
};

export default AlignIcon;
