import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_2.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step5 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={50}>
      Po poprawnym zarejestrowaniu się do serwisu, zobaczysz komunikat, który będzie wymagał od
      Ciebie wpisania adresu e-mail, zrób to. Uwaga: Odrzucony zostanie e-mail już zarejestrowany w
      systemie TipeeeStream.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
  </StepContent>
);

export default Step5;

Step5.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
