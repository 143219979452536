import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { openAlert } from 'components/Alert/utils';
import FloatingMenu from 'components/FloatingMenu';

import { ReactComponent as SettingsIcons } from 'assets/icons/gear.svg';
import OptionsPopup, { OptionsPopupItem } from 'styles/OptionsPopup';

import { BaseRoundedInputStyles } from './RoundedInputs';

const SettingsButtonContainer = styled.div`
  position: relative;
  height: 100%;
  width: 42px;
`;

const IconWrapper = styled.div`
  ${BaseRoundedInputStyles};
  width: 42px;
  height: 42px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 22px;
    height: 22px;
    fill: var(--text-color-medium-gray);
  }
`;

export const SettingsButton = ({ onEditTemplate, onRemove, removeDisabled }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuParentRef = useRef();

  const removeConfirmAlert = () => {
    openAlert(
      'confirm',
      'Czy na pewno?',
      'Kompletne usunięcie celu usunie go z Twojej strony streamera, usunie wszelkie statystyki celu m.in. ilość wpłaconych środków. Tego nie da się odzyskać. Czy jesteś pewien?',
      {
        cancelButtonText: 'Anuluj proces',
        confirmButtonText: 'Lecimy z tym',
        cancelButton: true,
      },
      () => {
        onRemove();
      },
    );
  };

  const cantRemoveAlert = () => {
    openAlert(
      'error',
      'Informacja',
      'Nie możesz usunąć domyślnego celu. Jeśli cel się zakończył i chcesz zbierać na inną rzecz, to po prostu opróżnij cel z zarobków i wpisz inny tytuł. Nie ma konieczności usuwania domyślnego celu.',
      {
        confirmButtonText: 'Rozumiem',
      },
    );
  };

  const handleRemove = () => {
    if (!removeDisabled) {
      removeConfirmAlert();
    } else {
      cantRemoveAlert();
    }
  };

  return (
    <SettingsButtonContainer ref={menuParentRef}>
      <IconWrapper onClick={() => setMenuOpen(prevState => !prevState)}>
        <SettingsIcons />
      </IconWrapper>

      <FloatingMenu
        visible={menuOpen}
        position="bottom"
        onClose={() => setMenuOpen(false)}
        parent={menuParentRef.current || document.body}
      >
        <OptionsPopup>
          <OptionsPopupItem onClick={onEditTemplate}>Edytuj wygląd celu</OptionsPopupItem>
          <OptionsPopupItem onClick={handleRemove} color="red">
            Usuń cel kompletnie
          </OptionsPopupItem>
        </OptionsPopup>
      </FloatingMenu>
    </SettingsButtonContainer>
  );
};

SettingsButton.propTypes = {
  onEditTemplate: PropTypes.instanceOf(Function).isRequired,
  onRemove: PropTypes.instanceOf(Function).isRequired,
  removeDisabled: PropTypes.bool.isRequired,
};
