/** Ryszard's note: this is re-factor based on old component. That means it still shares some of the old one's logic.
 * Some problems ware solved, but elements in the text menu still require work. I've refactored them a little bit:
 * changed to chars to svg icons and added some alignments, the horizontal margins are just touch-ups to match the
 * Figma project.
 * */

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ConfigContext from '../../../../pages/Configurators/ConfigContext';
import { Base } from '../../shared';
import TextColorPicker from './components/Color';
import FontFamily from './components/FontFamily';
import FontSize from './components/FontSize';
import FontStyle from './components/FontStyle';
import FontTransparency from './components/FontTransparency';
import FontWeight from './components/FontWeight';
import ShadowEditor from './components/ShadowEditor';
import TextAlign from './components/TextAlign';
import TextShadow from './components/TextShadow';
import TextEditorContext from './Context';

import { Confirm, Container, Input, OptionContainer, ItemsContent } from './styles';

import { getTextShadowRGBA, updateParam, toHexWithAlpha } from './helpers';

function colorMixer(opacity, color) {
  let alpha = opacity ? opacity.toString() : '16';
  if (alpha.length < 2) alpha = `0${alpha}`;
  let hex = color;
  if (color.length === 4) hex = `${color}${color.substring(-3)}`;
  if (color.length > 7) hex = `${color.substring(0, 7)}`;

  return hex + alpha;
}

function isSubmitActive(editorVariant) {
  const variants = [
    'color',
    'fontFamily',
    'fontSize',
    'textAlign',
    'fontTransparency',
    'textShadow',
  ];
  return variants.includes(editorVariant);
}

const isVariantText = variant => variant === 'text';

const StyleControls = ({ disableTextAlign }) => {
  const { style, updateStyle, setEditorVariant } = useContext(TextEditorContext);
  const [shadowColor, setShadowColor] = useState(getTextShadowRGBA(style.textShadow));

  useEffect(() => {
    setShadowColor(getTextShadowRGBA(style.textShadow));
  }, [style]);

  const handleTextColorChange = ({ rgb, hex }) => {
    const hexWithAlpha = toHexWithAlpha(rgb, hex);
    updateStyle('color', hexWithAlpha, true);
  };

  const updateShadowColor = rgba => {
    let t = updateParam(style.textShadow, rgba.r, 0);
    t = updateParam(t, rgba.g, 1);
    t = updateParam(t, rgba.b, 2);
    t = updateParam(t, rgba.a, 3);

    updateStyle('textShadow', t, true);
  };

  const handleShadowChange = ({ rgb }) => {
    updateShadowColor(rgb);
  };

  const handleColorClick = () => setEditorVariant('color');
  const handleTextShadowClick = () => setEditorVariant('textShadow');
  const handleFontFamilyClick = () => setEditorVariant('fontFamily');
  const handleFontSizeClick = () => setEditorVariant('fontSize');
  const handleFontTransparencyClick = () => setEditorVariant('fontTransparency');
  const handleTextAlignClick = () => setEditorVariant('textAlign');

  return (
    <>
      <OptionContainer>
        <TextColorPicker
          title="Kolor"
          color={style.color}
          onClick={handleColorClick}
          onChange={handleTextColorChange}
        />
      </OptionContainer>

      <OptionContainer style={{ marginLeft: 2 }}>
        <FontWeight title="Pogrubienie" />
      </OptionContainer>

      <OptionContainer style={{ columnGap: 7 }}>
        <TextShadow title="Cień" />
        <ShadowEditor
          title="Cień"
          color={shadowColor}
          onClick={handleTextShadowClick}
          onChange={handleShadowChange}
        />
      </OptionContainer>

      <OptionContainer style={{ marginLeft: -9 }}>
        <FontStyle title="Pochylenie" />
      </OptionContainer>

      <OptionContainer style={{ marginLeft: -3 }}>
        <FontSize onClick={handleFontSizeClick} />
      </OptionContainer>

      {!disableTextAlign && (
        <OptionContainer style={{ marginLeft: -3 }}>
          <TextAlign title="Wyrównanie" onClick={handleTextAlignClick} />
        </OptionContainer>
      )}

      <OptionContainer>
        <FontTransparency title="Przezroczystość" onClick={handleFontTransparencyClick} />
      </OptionContainer>

      <OptionContainer style={{ marginLeft: -3 }}>
        <FontFamily title="Czcionka" onClick={handleFontFamilyClick} />
      </OptionContainer>
    </>
  );
};

StyleControls.propTypes = {
  disableTextAlign: PropTypes.bool,
};

StyleControls.defaultProps = {
  disableTextAlign: false,
};

const TextControls = ({ textContent, onChangeText }) => {
  const handleTextChange = event => onChangeText(event.target.value);

  return <Input type="text" value={textContent} onChange={handleTextChange} text />;
};

TextControls.propTypes = {
  textContent: PropTypes.string,
  onChangeText: PropTypes.instanceOf(Function),
};

TextControls.defaultProps = {
  textContent: '',
  onChangeText: () => {},
};

const TextStyleMenu = props => {
  const { elementStyle, variant, onChange, disableTextAlign, textContent, onChangeText } = props;
  const { textEditor, toggleTextEditor } = useContext(ConfigContext);

  const [style, setStyle] = useState(elementStyle);
  const [editorVariant, setEditorVariant] = useState(variant);
  const [isActiveSubmit, setIsActiveSubmit] = useState(isSubmitActive(editorVariant));
  const [isTextVariant, setIsTextVariant] = useState(isVariantText(variant));

  useEffect(() => {
    setStyle(elementStyle);
  }, [elementStyle]);

  useEffect(() => {
    setEditorVariant(variant);
  }, [variant]);

  useEffect(() => {
    setIsTextVariant(isVariantText(variant));
    setIsActiveSubmit(isSubmitActive(editorVariant));
  }, [editorVariant]);

  const processAndCommitChange = (newStyle = {}) => {
    const combinedStyle = { ...style, ...newStyle };
    if (!(parseInt(combinedStyle.fontSize, 10) > 0)) {
      combinedStyle.fontSize = 16;
    }

    onChange(combinedStyle);
  };

  const updateStyle = (key, value, confirmed) => {
    const newStyle = { ...style, [key]: value };
    if (key === 'fontTransparency') {
      newStyle.color = colorMixer(value, style.color);
    }

    setStyle(newStyle);
    if (confirmed) {
      processAndCommitChange(newStyle);
    }
  };

  const handleConfirmClick = () => {
    processAndCommitChange();
    if (textEditor.closeOnConfirm) {
      toggleTextEditor('');
    } else {
      setEditorVariant('style');
    }
  };

  const isActiveVariant = testVariant => editorVariant === testVariant;

  return (
    <TextEditorContext.Provider
      value={{
        editorVariant,
        setEditorVariant,
        style,
        updateStyle,
        isActive: isActiveVariant,
        isNotStyle: editorVariant !== 'style',
      }}
    >
      <Base width={disableTextAlign ? 420 : 450} {...props}>
        <Container style={{ borderColor: '#A1A1A180' }}>
          {!isTextVariant && (
            <ItemsContent>
              <StyleControls disableTextAlign={disableTextAlign} />
            </ItemsContent>
          )}

          {isTextVariant && <TextControls textContent={textContent} onChangeText={onChangeText} />}

          <Confirm isActive={isActiveSubmit} onClick={handleConfirmClick}>
            OK
          </Confirm>
        </Container>
      </Base>
    </TextEditorContext.Provider>
  );
};

TextStyleMenu.propTypes = {
  elementStyle: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.string.isRequired,
  isMounted: PropTypes.bool.isRequired,
  unmountDelay: PropTypes.number,
  onChange: PropTypes.instanceOf(Function),
  onChangeText: PropTypes.instanceOf(Function),
  onHide: PropTypes.instanceOf(Function),
  textContent: PropTypes.string,
  disableTextAlign: PropTypes.bool,
};

TextStyleMenu.defaultProps = {
  onChange: () => {},
  onChangeText: () => {},
  onHide: () => {},
  textContent: '',
  disableTextAlign: false,
  unmountDelay: 0,
};

export default TextStyleMenu;
