/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';
import moment from 'moment';

import rejectIcon from 'assets/icons/reject-x.svg';
import okCheck from 'assets/icons/ok-check.svg';

import priceFormatter from 'utils/priceFormatter';
import getUIAvatar from 'utils/getUIAvatar';

import Link from 'components/Link';
import { SoundPlayButton } from 'components/SoundPlayButton';
import parseMessage from '../../../utils/parseMessage';

import StyledTip from '../../TipsList/styles/StyledTip';
import PaymentHumanName from '../../TipsList/blocks/PaymentHumanName';
import { BlueDotSeparator } from '../../TipsList/blocks/elements/BlueDotSeparator';
import { EMOTE_DOMAINS } from '../../TipsList/blocks/EmoteDomains';

class TipModeration extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    goalTitle: PropTypes.string,
    showGoals: PropTypes.bool.isRequired,
    approveModerationPanel: PropTypes.instanceOf(Function).isRequired,
    rejectModerationPanel: PropTypes.instanceOf(Function).isRequired,
    paymentType: PropTypes.string.isRequired,
    audioUrl: PropTypes.string,
  };

  static defaultProps = {
    goalTitle: '',
    audioUrl: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isRejecting: false,
      isApproving: false,
    };
  }

  async approve() {
    const { id, approveModerationPanel } = this.props;

    this.setState({ isApproving: true });
    const { isApproving } = this.state;

    if (!isApproving) {
      await approveModerationPanel(id);
      this.setState({ isApproving: false });
    }
  }

  async reject() {
    const { id, rejectModerationPanel } = this.props;
    this.setState({ isRejecting: true });
    await rejectModerationPanel(id);
    this.setState({ isRejecting: false });
  }

  render() {
    const {
      nickname,
      amount,
      createdAt,
      message,
      goalTitle,
      showGoals,
      paymentType,
      audioUrl,
    } = this.props;

    const { isApproving, isRejecting } = this.state;
    const name = `${nickname} `;
    const humanMethod = PaymentHumanName(paymentType);

    let isLink = '';
    if (message) {
      const urlRegex =
        'http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+';
      const url = message.match(urlRegex);
      if (url) {
        isLink = url;
      }
    }

    if (isLink) {
      for (const item of EMOTE_DOMAINS) {
        if (isLink[0].includes(item)) {
          isLink = '';
          break;
        }
      }
    }

    return (
      <StyledTip className={isApproving || isRejecting ? 'loading' : ''}>
        <div className="box">
          <div className="donator">
            <div className="flexavatar">
              <div
                className="avatar"
                style={{ backgroundImage: `url("${getUIAvatar(nickname)}")` }}
              />
            </div>
            <p className="no-margin text-white-black ">
              <span className="separator">
                <span className="nickname text-white-black text-large">{name}</span>
                <BlueDotSeparator />
                <span className="tip-amount text-blue text-default">{priceFormatter(amount)}</span>
              </span>
              <div className="timespan">
                <time dateTime={createdAt}>
                  {fecha.format(new Date(createdAt), 'DD MMM YYYY, HH:mm')}
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  &nbsp;({moment(createdAt).fromNow()})
                </time>
              </div>
              <span className="text-medium google-sora-cdn paymentmethod">
                Metoda płatności:&nbsp;
                <span className="text-blue">{humanMethod}</span>
              </span>
            </p>
          </div>
          <p className="message no-margin">
            {showGoals && goalTitle.length > 0 && (
              <span className="text-medium text-blue list-block google-sora-cdn">
                Wybrano:
                <span className="text-medium text-white-black" style={{ marginLeft: 10 }}>
                  {goalTitle}
                </span>
              </span>
            )}
            <span
              className="text-medium google-sora-cdn"
              dangerouslySetInnerHTML={{ __html: parseMessage(message) }}
            />
            {isLink && (
              <span className="list-block">
                <Link href={isLink} target="_blank">
                  {isLink}
                </Link>
              </span>
            )}
          </p>
          <div>
            <div className="buttons">
              {audioUrl && <SoundPlayButton audioUrl={audioUrl} />}
              <button type="button" onClick={() => this.approve()} title="Akceptuj wiadomość">
                <img src={okCheck} alt="accept" />
              </button>
              <button type="button" onClick={() => this.reject()} title="Przenieś do kosza">
                <img src={rejectIcon} alt="reject" />
              </button>
            </div>
          </div>
        </div>
      </StyledTip>
    );
  }
}

export default TipModeration;
