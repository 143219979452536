import styled from 'styled-components';

import { MIN_DESKTOP } from 'styles/base/breakpoints';

export default styled.div`
  font-family: var(--google-sora-cdn);
  margin-top: 30px;

  .tips-header {
    padding: 30px;
    background-color: var(--white);
    @media (${MIN_DESKTOP}) {
      padding: 60px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 8px;
    }

    &__intro {
      max-width: 450px;
      text-align: center;
      margin: 0 auto 25px;
      @media (${MIN_DESKTOP}) {
        text-align: left;
        margin: 0;
      }

      h4 {
        font-size: 25px;
        margin: 0 0 10px;
      }

      p {
        font-size: 14px;
        color: var(--gray);
        margin: 0;
      }
    }

    &__right-part {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      max-width: 500px;
      gap: 10px;
      margin: 0 auto;
      @media (${MIN_DESKTOP}) {
        flex-direction: row;
        margin: 0;
        gap: 24px;
        max-width: unset;
      }
    }

    &__new_window_button,
    &__moderator_button {
      appearance: none !important;
      border: 0;
      outline: 0;
      width: 100%;
      border-radius: 20px;
      text-decoration: none;
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      padding: 11px 15px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      @media (${MIN_DESKTOP}) {
        font-size: 15px;
        justify-content: center;
        text-align: center;
        padding-right: 35px;
        padding-left: 35px;
      }
    }

    &__new_window_button {
      background: var(--brand-light-blue);
      color: white;
      margin-bottom: 10px;
    }

    &__moderator_button {
      background: var(--light-gray);
      color: var(--text-color);
    }
  }
`;
