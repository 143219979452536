import styled, { css } from 'styled-components';

const absoluteLayerHidden = css`
  width: calc(100% - 60px);
  mask-image: none;
  overflow: visible;

  * {
    opacity: 1;
  }
`;

export default styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  ${props => props.isCentered && 'justify-content: center'};

  height: 55px;
  background: var(--bg-input-color-editor);
  transform: translate(20px, -50%);
  width: 0;
  left: 0;
  top: 50%;
  transition: 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: mask-image, width;
  z-index: 3;
  mask-image: linear-gradient(to right, transparent, black 0px, black 70%, transparent);
  overflow: hidden;

  * {
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  }

  ${props => props.isActive && absoluteLayerHidden};
`;
