import http from '../../utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const requestLatestWithdrawals = () => ({
  type: 'REQUEST_LATEST_WITHDRAWALS',
});

const receiveLatestWithdrawals = receivedData => ({
  type: 'RECEIVE_LATEST_WITHDRAWALS',
  receivedData,
});

const failureLatestWithdrawals = () => ({
  type: 'FAILURE_LATEST_WITHDRAWALS',
});

const fetchLatestWithdrawals = () => async dispatch => {
  dispatch(requestLatestWithdrawals());

  try {
    const withdrawals = await http.get('/user/withdrawals/latest');

    dispatch(receiveLatestWithdrawals(withdrawals.data));
  } catch (error) {
    dispatch(failureLatestWithdrawals());
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchLatestWithdrawalsIfNeeded = () => (dispatch, getState) => {
  const { latestWithdrawals } = getState();

  if (shouldFetchData(latestWithdrawals)) {
    dispatch(fetchLatestWithdrawals());
  }
};
