import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import priceFormatter from 'utils/priceFormatter';
import firstUrl from 'utils/firstUrl';
import StyledTipModBasket from '../styles/StyledTipModBasket';
import rejectIcon from '../../../icons/reload2.svg';

import Link from '../../../components/Link';
import PaymentHumanName from './PaymentHumanName';

class TipsModerationBasket extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    nickname: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    goalTitle: PropTypes.string,
    showGoals: PropTypes.bool.isRequired,
    paymentType: PropTypes.string.isRequired,
    restoreTipModeration: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
    fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {
    goalTitle: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isRejecting: false,
      isApproving: false,
    };
  }

  async restore() {
    const {
      id,
      restoreTipModeration,
      fetchTipsModerationBasketForModerators,
      fetchTipsModerationForTipsListPage,
    } = this.props;

    await restoreTipModeration(id);
    await fetchTipsModerationBasketForModerators();
    await fetchTipsModerationForTipsListPage();
  }

  render() {
    const { nickname, amount, createdAt, message, goalTitle, showGoals, paymentType } = this.props;
    const { isApproving, isRejecting } = this.state;

    const name = `${nickname} `;
    const humanMethod = PaymentHumanName(paymentType);

    let isLink = '';
    if (message) {
      const url = firstUrl(message);
      if (url) {
        isLink = url;
      }
    }

    return (
      <StyledTipModBasket className={isApproving || isRejecting ? 'loading' : ''}>
        <div className="box">
          <div className="donator">
            <p className="no-margin text-white-black">
              <span className="text-small">
                {name.length < 16 && (
                  <span className="text-white-black noto-sans">
                    <strong>{name}</strong>
                  </span>
                )}
                {name.length > 15 && (
                  <span className="text-white-black noto-sans nicknamewrap">
                    <strong>{name}</strong>
                  </span>
                )}
                <b className="text-blue text-small">
                  {' - '}
                  {priceFormatter(amount)}
                </b>
              </span>
              <br />
              <time dateTime={createdAt}>
                {fecha.format(new Date(createdAt), 'DD MMM YYYY, HH:mm')}
              </time>
              <br />
              <span className="text-small">
                Metoda płatności:&nbsp;
                <span className="text-blue">{humanMethod}</span>
              </span>
            </p>
          </div>
          <p className="message no-margin">
            {showGoals && goalTitle.length > 0 && (
              <span className="text-small text-blue list-block">
                Wybrano:
                <span className="text-small text-white-black" style={{ marginLeft: 10 }}>
                  {goalTitle}
                </span>
              </span>
            )}
            <span className="text-small">{message}</span>
            <span className="list-block">
              <Link href={isLink} target="_blank">
                {isLink}
              </Link>
            </span>
          </p>

          <div className="buttons">
            <button type="button" onClick={() => this.restore()}>
              <img src={rejectIcon} alt="reject" />
            </button>
          </div>
        </div>
      </StyledTipModBasket>
    );
  }
}

export default TipsModerationBasket;
