import styled from 'styled-components';

export default styled.button`
  position: absolute;
  z-index: 5;

  width: 30px;
  height: 30px;

  background-color: var(--brand-blue);
  color: var(--white-white);
  font-size: 25px;
  font-weight: 500;

  border: 0;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;

  transform: translate(10px, 0);
`;
