import googleSigninLogo from 'assets/images/google_signin_dark.png';

import Button from 'components/Button';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import serverAlert from 'store/utils/serverAlert';

import Badge from 'styles/Badge';
import ErrorsWrapper from 'styles/ErrorsWrapper';

import http, { apiUrl } from 'utils/http';
import TextInput from '../../../../../TextInput';
import Heading from '../../../../styles/Heading';

import StyledAcountSettingsDropDownContent from '../../../../styles/StyledAcountSettingsDropDownContent';

import Context, { Consumer } from '../../Context';
import EmailInput from './components/EmailInput';
import PasswordInput from './components/PasswordInput';
import Title from './components/Title';

export default class AccountSettings extends Component {
  static contextType = Context;

  static propTypes = {
    submitChangedPassword: PropTypes.instanceOf(Function).isRequired,
    submitChangedEmail: PropTypes.instanceOf(Function).isRequired,
    sendEmailAuthenticatorCode: PropTypes.instanceOf(Function).isRequired,
    enableEmailAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disableEmailAuthenticator: PropTypes.instanceOf(Function).isRequired,
    connectGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    enableGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disableGoogleAuthenticator: PropTypes.instanceOf(Function).isRequired,
    disconnectService: PropTypes.instanceOf(Function).isRequired,
  };

  passwordInputs = [
    { name: 'current-password', placeholder: 'Stare hasło' },
    { name: 'new-password-first', placeholder: 'Nowe hasło' },
    { name: 'new-password-second', placeholder: 'Powtórz nowe hasło' },
  ];

  state = {
    googleQRCode: '',
    googleConfirmCode: '',
    googleConfirmCodeError: '',
    emailAuthEnableStart: false,
    emailAuthConfirmCode: '',
    emailAuthConfirmCodeError: '',
    facebookIsDisconnecting: false,
    twitchIsDisconnecting: false,
    youtubeIsDisconnecting: false,
    passwords: {
      'current-password': '',
      'new-password-first': '',
      'new-password-second': '',
    },
    passwordsErrors: {
      'current-password': [],
      'new-password-first': [],
      'new-password-second': [],
    },
    email: {
      'new-email': '',
      'current-password': '',
    },
    emailErrors: {
      'new-email': [],
      'current-password': [],
    },
    loadings: {
      password: false,
      email: false,
      googleAuth: false,
      googleAuthConfirmation: false,
      emailAuth: false,
      emailAuthConfirmation: false,
      widget: false,
    },
  };

  /**
   * Funkcja normalizująca zwracane dane przez API.
   * Jeżeli nie ma błędów oddaje pusty obiekt, więc zmieniamy go na tablicę.
   *
   * @param {(Object|Array)} data
   * @returns {Array}
   */
  normalizeErrorsArray = data => {
    if (data instanceof Array) return data;

    return [];
  };

  /**
   * Funkcja aktualizująca stan dla loaderów
   *
   * @param {string} key
   * @param {boolean} value
   * @returns {void}
   */
  setLoaderState = (key, value) => {
    this.setState(prevState => ({
      loadings: {
        ...prevState.loadings,
        [key]: value,
      },
    }));
  };

  /**
   * Funkcja aktualizująca wartości w inputach.
   *
   * @param {Event} event
   * @param {string} stateObject
   */
  onChangeInput = (event, stateObject) => {
    const updatedValue = { [event.target.name]: event.target.value };

    this.setState(prevState => ({
      [stateObject]: {
        ...prevState[stateObject],
        ...updatedValue,
      },
    }));
  };

  onSubmitPassword = event => {
    const { submitChangedPassword } = this.props;
    const { passwords } = this.state;

    event.preventDefault();

    this.setLoaderState('password', true);

    submitChangedPassword(passwords)
      .then(response => {
        this.setLoaderState('password', false);

        if (response.status === 204) {
          serverAlert('Hasło zostało zmienione', 'success');

          this.setState({
            passwords: {
              'current-password': '',
              'new-password-first': '',
              'new-password-second': '',
            },
            passwordsErrors: {
              'current-password': [],
              'new-password-first': [],
              'new-password-second': [],
            },
          });
        }
      })
      .catch(({ response: { status, data } }) => {
        this.setLoaderState('password', false);

        if (status === 400) {
          this.setState({
            passwordsErrors: {
              'current-password': this.normalizeErrorsArray(
                data.errors.children.current_password.errors,
              ),
              'new-password-first': this.normalizeErrorsArray(
                data.errors.children.plainPassword.children.first.errors,
              ),
              'new-password-second': this.normalizeErrorsArray(
                data.errors.children.plainPassword.children.second.errors,
              ),
            },
          });
        } else {
          serverAlert('Wystąpił błąd', 'error');
        }
      });
  };

  onSubmitEmail = event => {
    const { submitChangedEmail } = this.props;
    const { email } = this.state;

    event.preventDefault();

    this.setLoaderState('email', true);

    submitChangedEmail(email)
      .then(response => {
        this.setLoaderState('email', false);

        if (response.status === 204) {
          const { userDataInfo } = this.context;
          const { email: currentEmail } = userDataInfo;

          serverAlert(
            <div>
              <Text>
                {`W celu zwiększenia bezpieczeństwa Twojego konta, na Twój aktualny adres e-mail `}
                <strong>{currentEmail}</strong>
                {` został wysłany link aktywacyjny. Postępuj zgodnie z instrukcjami w
                wiadomości, aby dokończyć zmianę adresu e-mail.`}
              </Text>
              <Text>
                {'Nie masz dostępu do aktualnego e-maila? Napisz na '}
                <a href="mailto:support@tipply.pl" style={{ color: 'currentColor' }}>
                  support@tipply.pl
                </a>
              </Text>
            </div>,
            'success',
          );

          this.setState({
            email: {
              'new-email': '',
              'current-password': '',
            },
            emailErrors: {
              'new-email': [],
              'current-password': [],
            },
          });
        }
      })
      .catch(({ response: { status, data } }) => {
        this.setLoaderState('email', false);

        if (status === 400) {
          this.setState({
            emailErrors: {
              'new-email': this.normalizeErrorsArray(data.errors.children.email.errors),
              'current-password': this.normalizeErrorsArray(
                data.errors.children.current_password.errors,
              ),
            },
          });
        } else {
          serverAlert('Wystąpił błąd', 'error');
        }
      });
  };

  // Google Authenticator methods

  connectGoogleAuthenticator = () => {
    const { connectGoogleAuthenticator } = this.props;

    this.setLoaderState('googleAuth', true);

    connectGoogleAuthenticator()
      .then(response => {
        this.setState({
          googleQRCode: response.data,
        });
      })
      .catch(error => {
        serverAlert('Wystąpił błąd generacji kodu QR dla Google Authenticator');
      })
      .finally(() => {
        this.setLoaderState('googleAuth', false);
      });
  };

  onGoogleConfirmCodeChange = event => {
    const inputValue = event.target.value;

    this.setState({
      googleConfirmCode: inputValue,
    });

    if (inputValue.length >= 6) {
      this.enableGoogleAuthenticator(inputValue);
    }
  };

  enableGoogleAuthenticator = code => {
    const { enableGoogleAuthenticator } = this.props;

    this.setLoaderState('googleAuthConfirmation', true);

    enableGoogleAuthenticator(code)
      .then(() => {
        this.cancelEnableGoogleAuthenticator();
      })
      .catch(({ response: { status } }) => {
        if (status === 400) {
          this.setState({
            googleConfirmCode: '',
            googleConfirmCodeError: 'Niepoprawny kod Google Authenticator',
          });
        } else {
          serverAlert('Wystąpił błąd podczas włączania Google Authenticator');
        }
      })
      .finally(() => {
        this.setLoaderState('googleAuthConfirmation', false);
      });
  };

  disableGoogleAuthenticator = () => {
    const { disableGoogleAuthenticator } = this.props;

    this.setLoaderState('googleAuth', true);

    disableGoogleAuthenticator()
      .catch(error => {
        serverAlert('Nie udało się wyłączyć Google Authenticator');
      })
      .finally(() => {
        this.setLoaderState('googleAuth', false);
      });
  };

  cancelEnableGoogleAuthenticator = () => {
    this.setState({
      googleQRCode: '',
      googleConfirmCode: '',
      googleConfirmCodeError: '',
    });
  };

  // Email authenticator methods

  startEnableEmailAuth = () => {
    const { sendEmailAuthenticatorCode } = this.props;

    this.setLoaderState('emailAuth', true);

    sendEmailAuthenticatorCode()
      .then(() => {
        this.setState({
          emailAuthEnableStart: true,
        });
      })
      .catch(error => {
        serverAlert('Nie udało się wysłać kodu potwierdzającego. Spróbuj jeszcze raz');
      })
      .finally(() => {
        this.setLoaderState('emailAuth', false);
      });
  };

  onEmailAuthConfirmCodeChange = event => {
    const inputValue = event.target.value;

    this.setState({
      emailAuthConfirmCode: inputValue,
    });

    if (inputValue.length >= 6) {
      this.enableEmailAuthenticator(inputValue);
    }
  };

  enableEmailAuthenticator = code => {
    const { enableEmailAuthenticator } = this.props;

    this.setLoaderState('emailAuthConfirmation', true);

    enableEmailAuthenticator(code)
      .then(() => {
        this.cancelEnableEmailAuthenticator();
      })
      .catch(({ response: { status } }) => {
        if (status === 400) {
          this.setState({
            emailAuthConfirmCode: '',
            emailAuthConfirmCodeError: 'Niepoprawny kod',
          });
        } else {
          serverAlert('Wystąpił błąd podczas włączania weryfikacji dwuetapowej');
        }
      })
      .finally(() => {
        this.setLoaderState('emailAuthConfirmation', false);
      });
  };

  disableEmailAuthenticator = () => {
    const { disableEmailAuthenticator } = this.props;

    this.setLoaderState('emailAuth', true);

    disableEmailAuthenticator()
      .catch(error => {
        serverAlert('Nie udało się wyłączyć weryfikacji dwuetapowem e-mailem');
      })
      .finally(() => {
        this.setLoaderState('emailAuth', false);
      });
  };

  cancelEnableEmailAuthenticator = () => {
    this.setState({
      emailAuthEnableStart: false,
      emailAuthConfirmCode: '',
      emailAuthConfirmCodeError: '',
    });
  };

  disconnectService(service) {
    const { disconnectService } = this.props;

    if (service === 'facebook') {
      this.setState(
        {
          facebookIsDisconnecting: true,
        },
        () => {
          disconnectService(service)
            .then(response => {
              this.setState({ facebookIsDisconnecting: false });
            })
            .catch(error => {
              serverAlert('Rozłączenie konta Facebook nie powiodło się', 'error');
              this.setState({ facebookIsDisconnecting: false });
            });
        },
      );
    } else if (service === 'twitch') {
      this.setState(
        {
          twitchIsDisconnecting: true,
        },
        () => {
          disconnectService(service)
            .then(response => {
              this.setState({ twitchIsDisconnecting: false });
            })
            .catch(error => {
              serverAlert('Rozłączenie konta Twitch nie powiodło się', 'error');
              this.setState({ twitchIsDisconnecting: false });
            });
        },
      );
    } else if (service === 'youtube') {
      this.setState(
        {
          youtubeIsDisconnecting: true,
        },
        () => {
          disconnectService(service)
            .then(response => {
              this.setState({ youtubeIsDisconnecting: false });
            })
            .catch(error => {
              serverAlert('Rozłączenie konta Youtube nie powiodło się', 'error');
              this.setState({ youtubeIsDisconnecting: false });
            });
        },
      );
    }
  }

  render() {
    const {
      googleQRCode,
      googleConfirmCode,
      googleConfirmCodeError,
      emailAuthEnableStart,
      emailAuthConfirmCode,
      emailAuthConfirmCodeError,
      facebookIsDisconnecting,
      twitchIsDisconnecting,
      youtubeIsDisconnecting,
      passwords,
      passwordsErrors,
      email,
      emailErrors,
      loadings,
    } = this.state;

    return (
      <Consumer>
        {({ userDataIsPatching, userDataInfo, userDataProfile }) => (
          <StyledAcountSettingsDropDownContent>
            <Heading>Ustawienia konta</Heading>

            <form className="form-group" onSubmit={this.onSubmitPassword}>
              <Title>Zmiana hasła</Title>
              {this.passwordInputs.map(input => (
                <Fragment key={input.name}>
                  <PasswordInput
                    value={passwords[input.name]}
                    onChange={event => this.onChangeInput(event, 'passwords')}
                    name={input.name}
                    placeholder={input.placeholder}
                  />
                  {Boolean(passwordsErrors[input.name].length) && (
                    <ErrorsWrapper style={{ margin: '0 0 15px' }}>
                      {passwordsErrors[input.name].map(error => (
                        <li key={error}>{error}</li>
                      ))}
                    </ErrorsWrapper>
                  )}
                </Fragment>
              ))}
              <Button
                loading={loadings.password}
                type="submit"
                noWrapper
                block
                background="blue"
                disabled={userDataIsPatching}
              >
                Zmień hasło
              </Button>
            </form>

            <form className="form-group" onSubmit={this.onSubmitEmail}>
              <Title>Zmiana e-mail</Title>

              <EmailInput
                type="email"
                value={email['new-email']}
                onChange={event => this.onChangeInput(event, 'email')}
                name="new-email"
                placeholder="Nowy e-mail"
              />

              {Boolean(emailErrors['new-email'].length) && (
                <ErrorsWrapper style={{ margin: '0 0 15px' }}>
                  {emailErrors['new-email'].map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ErrorsWrapper>
              )}

              <PasswordInput
                value={email['current-password']}
                onChange={event => this.onChangeInput(event, 'email')}
                name="current-password"
                placeholder="Hasło"
              />

              {Boolean(emailErrors['current-password'].length) && (
                <ErrorsWrapper style={{ margin: '0 0 15px' }}>
                  {emailErrors['current-password'].map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ErrorsWrapper>
              )}

              <Button
                loading={loadings.email}
                type="submit"
                noWrapper
                block
                background="blue"
                disabled={userDataIsPatching}
              >
                Zmień e-mail
              </Button>
            </form>

            <div className="form-group">
              <Title>
                Weryfikacja dwuetapowa
                <Badge>Zalecane</Badge>
              </Title>
              <Button
                noWrapper
                background={userDataInfo.google_auth_enabled ? 'blue' : 'gray'}
                block
                onClick={() => {
                  if (userDataInfo.google_auth_enabled) {
                    this.disableGoogleAuthenticator();
                  } else if (googleQRCode.length) {
                    this.cancelEnableGoogleAuthenticator();
                  } else {
                    this.connectGoogleAuthenticator();
                  }
                }}
                style={{ margin: '0 0 10px' }}
                disabled={
                  userDataIsPatching || loadings.googleAuth || loadings.googleAuthConfirmation
                }
                loading={loadings.googleAuth}
              >
                {`Google Authenticator (${
                  userDataInfo.google_auth_enabled ? 'włączony' : 'wyłączony'
                })`}
              </Button>
              {Boolean(googleQRCode) && (
                <div style={{ textAlign: 'center', margin: '0 0 30px' }}>
                  <Text size={15} weight="medium" margin="10px 0 3px">
                    Zeskanuj kod QR...
                  </Text>
                  <img src={process.env.REACT_APP_API_URL + googleQRCode} alt="Kod QR" />
                  <Text size={15} weight="medium">
                    ...a następnie wprowadź kod z aplikacji, żeby zakończyć aktywację weryfikacji
                    dwuetapowej za pomocą Google&nbsp;Authenticator
                  </Text>
                  <TextInput
                    value={googleConfirmCode}
                    onChange={event => this.onGoogleConfirmCodeChange(event)}
                    maxLength={6}
                    placeholder="Kod Google Authenticator"
                    disabled={loadings.googleAuthConfirmation}
                    loading={loadings.googleAuthConfirmation}
                  />
                  {Boolean(googleConfirmCodeError.length) && (
                    <ErrorsWrapper style={{ margin: '20px 0' }}>
                      <li>{googleConfirmCodeError}</li>
                    </ErrorsWrapper>
                  )}
                </div>
              )}
              <Button
                noWrapper
                background={userDataInfo.email_auth_enabled ? 'blue' : 'gray'}
                block
                onClick={() => {
                  if (userDataInfo.email_auth_enabled) {
                    this.disableEmailAuthenticator();
                  } else if (emailAuthEnableStart) {
                    this.cancelEnableEmailAuthenticator();
                  } else {
                    this.startEnableEmailAuth();
                  }
                }}
                loading={loadings.emailAuth}
                disabled={
                  userDataIsPatching || loadings.emailAuth || loadings.emailAuthConfirmation
                }
              >
                {`Kod e-mail (${userDataInfo.email_auth_enabled ? 'włączony' : 'wyłączony'})`}
              </Button>
              {emailAuthEnableStart && (
                <div style={{ textAlign: 'center', margin: '0 0 30px' }}>
                  <Text size={15} weight="medium" margin="20px 0 15px">
                    Za chwilę otrzymasz kod weryfikacyjny na swój adres mailowy. Wprowadź go w
                    poniższym polu aby zakończyć proces aktywacji weryfikacji dwuetapowej.
                  </Text>
                  <TextInput
                    value={emailAuthConfirmCode}
                    onChange={event => this.onEmailAuthConfirmCodeChange(event)}
                    maxLength={6}
                    placeholder="Kod weryfikacyjny"
                    disabled={loadings.emailAuthConfirmation}
                    loading={loadings.emailAuthConfirmation}
                  />
                  <Text margin="10px 0 0" style={{ fontStyle: 'italic' }}>
                    Wysłano na&nbsp;
                    {userDataInfo.email}
                  </Text>
                  {Boolean(emailAuthConfirmCodeError.length) && (
                    <ErrorsWrapper style={{ margin: '20px 0' }}>
                      <li>{emailAuthConfirmCodeError}</li>
                    </ErrorsWrapper>
                  )}
                </div>
              )}
            </div>

            <div className="form-group">
              <Title>Połącz swoje konto</Title>

              <Button
                noWrapper
                background={userDataInfo.facebook_id ? 'blue' : 'gray'}
                block
                onClick={() => {
                  if (userDataInfo.facebook_id && !facebookIsDisconnecting) {
                    this.disconnectService('facebook');
                  } else if (!userDataInfo.facebook_id) {
                    window.location.href = `${apiUrl}/user/connect/facebook`;
                  }
                }}
                disabled={facebookIsDisconnecting}
              >
                {userDataInfo.facebook_id ? 'Rozłącz konto Facebook' : 'Połącz konto z Facebook'}
              </Button>
              <p />
              <Button
                noWrapper
                background={userDataInfo.twitch_id ? 'blue' : 'gray'}
                block
                onClick={() => {
                  if (userDataInfo.twitch_id && !twitchIsDisconnecting) {
                    this.disconnectService('twitch');
                  } else if (!userDataInfo.twitch_id) {
                    window.location.href = `${apiUrl}/user/connect/twitch`;
                  }
                }}
                disabled={twitchIsDisconnecting}
              >
                {userDataInfo.twitch_id ? 'Rozłącz konto Twitch' : 'Połącz konto z Twitch'}
              </Button>
              <p />
              <Button
                noWrapper
                background={userDataInfo.youtube_id ? 'blue' : 'gray'}
                block
                onClick={() => {
                  if (userDataInfo.youtube_id && !youtubeIsDisconnecting) {
                    this.disconnectService('youtube');
                  } else if (!userDataInfo.youtube_id) {
                    window.location.href = `${apiUrl}/user/connect/youtube`;
                  }
                }}
                disabled={youtubeIsDisconnecting}
              >
                {userDataInfo.youtube_id ? 'Rozłącz konto YouTube' : 'Połącz konto z YouTube'}
                <br />
                <img src={googleSigninLogo} alt="" />
              </Button>

              <p>
                {'Zapoznaj się z naszą '}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a target="_blank" href="https://tipply.pl/polityka-prywatnosci">
                  Polityką prywatności
                </a>
              </p>
            </div>

            <form
              className="form-group"
              onSubmit={async event => {
                event.preventDefault();
                await http.post(`${apiUrl}/user/toggle-widget-message`);
                window.location.reload();
              }}
            >
              <Title>Informacja o aktualizacji zmian szablonów</Title>
              <Button
                loading={loadings.widget}
                type="submit"
                noWrapper
                block
                background={userDataInfo.widget_message_disabled ? 'gray' : 'blue'}
                disabled={userDataIsPatching}
              >
                {userDataInfo.widget_message_disabled ? 'Nie pokazuj' : 'Pokazuj'}
              </Button>
              <Text size={12} weight="medium" margin="20px 0 15px">
                * Pamiętaj, odśwież widget aby zobaczyć efekt.
              </Text>
            </form>
          </StyledAcountSettingsDropDownContent>
        )}
      </Consumer>
    );
  }
}
