import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Item, Submenu } from 'react-contexify';

import { isArray } from 'utils/validators';

const ScreenContextMenu = props => {
  const { hiddenElements, elements, onShowElement, onResetElementPosition } = props;

  /**
   * Funkcja renderująca strukturę dla ukrytych elementów
   *
   * @param {Array} items
   * @param {Array} parentKeys
   */
  const renderHiddenElements = (items = [], parentKeys = []) => {
    const nodeItems = [];

    items.map(hiddenElement => {
      if (isArray(hiddenElement) && isArray(hiddenElement[1]) && hiddenElement[1].length > 0) {
        return nodeItems.push(
          <Submenu key={hiddenElement[0].key} label={hiddenElement[0].title}>
            {renderHiddenElements(hiddenElement[1], [hiddenElement[0].key])}
          </Submenu>,
        );
      }

      const key = [...parentKeys, hiddenElement.key].join('|');
      return nodeItems.push(
        <Item onClick={() => onShowElement(key)} key={key}>
          {hiddenElement.title}
        </Item>,
      );
    });

    return nodeItems;
  };

  return (
    <Menu id="screenContextMenu">
      <Submenu label="Dodaj element" disabled={() => !hiddenElements.length}>
        {!!hiddenElements.length && renderHiddenElements(hiddenElements)}
      </Submenu>
      {onResetElementPosition ? (
        <Submenu label="Zresetuj pozycję">
          {Object.keys(elements).map(elementName => (
            <Item key={elementName} onClick={() => onResetElementPosition(elementName)}>
              {elements[elementName].title}
            </Item>
          ))}
        </Submenu>
      ) : null}
    </Menu>
  );
};

ScreenContextMenu.propTypes = {
  elements: PropTypes.instanceOf(Object).isRequired,
  hiddenElements: PropTypes.instanceOf(Array).isRequired,
  onResetElementPosition: PropTypes.instanceOf(Function),
  onShowElement: PropTypes.instanceOf(Function).isRequired,
};

ScreenContextMenu.defaultProps = {
  onResetElementPosition: null,
};

export default ScreenContextMenu;
