import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTemplates } from 'store/selectors/templates';
import { emit } from 'utils/emitter';
import Loading from 'components/Loading';
import noEditableAlert from '../lib/noEditableAlert';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  ${props =>
    props.$active &&
    css`
      z-index: 9;
      cursor: pointer;
    `}

  .loading-container {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: wait;
    transition: 0.2s ease;
    transition-property: opacity, visibility;
  }

  ${props =>
    props.$loading &&
    css`
      .loading-container {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    `}
`;

export const ActionCatcher = ({ activeTemplate, onContextMenu }) => {
  const { isCreating } = useTemplates();

  return (
    <Container
      $loading={isCreating}
      $active={!activeTemplate.config.editable}
      onClick={() => {
        if (!activeTemplate.config.editable && !isCreating) {
          emit('create-new-template');
        }
      }}
      onContextMenu={event => {
        event.preventDefault();

        if (activeTemplate.config.editable) {
          onContextMenu(event);
        } else {
          noEditableAlert();
        }
      }}
    >
      <Loading />
    </Container>
  );
};

ActionCatcher.propTypes = {
  onContextMenu: PropTypes.instanceOf(Function),
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
};

ActionCatcher.defaultProps = {
  onContextMenu: () => null,
};
