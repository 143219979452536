import deepmerge from 'deepmerge';
import debounce from 'lodash/debounce';
import io from 'socket.io-client';

import { defaultConfigurations } from 'store/data/configurators';

import http from 'utils/http';
import overwriteMerge from 'utils/overwriteMerge';

import serverAlert from '../utils/serverAlert';
import shouldFetchData from '../utils/shouldFetchData';

const socket = io(process.env.REACT_APP_SOCKET_URL);

export const updateLocalConfiguration = (configuratorType, updatedData) => ({
  type: 'UPDATE_CONFIGURATION',
  configuratorType,
  updatedData,
});

export const updateLocalConfigFragment = (configuratorType, fragment) => ({
  type: 'UPDATE_CONFIGURATION_FRAGMENT',
  configuratorType,
  fragment,
});

const requestConfigurations = () => ({
  type: 'REQUEST_CONFIGURATIONS',
});

const receiveConfigurations = configurations => ({
  type: 'RECEIVE_CONFIGURATIONS',
  configurations,
});

const failureConfigurations = () => ({
  type: 'FAILURE_CONFIGURATIONS',
});

const updateGlobalConfiguration = config => ({
  type: 'UPDATE_GLOBAL_CONFIGURATION',
  config,
});

export const fetchBaseConfig = () => (dispatch, getState) => {
  http
    .get('/user/configuration')
    .then(response => response.data)
    .then(configurationsList => {
      // Przygotowujemy szablony do zapisu w store aplikacji.
      const preparedConfigs = {};

      Object.keys(defaultConfigurations).forEach(configuratorName => {
        // Szukamy czy konfiguracja znajduje się w odebranych konfiguracjach z serwera.
        const findConfig = configurationsList.find(
          configuration => configuration.type === configuratorName,
        );

        // Jeżeli użytkownik nie miał wcześniej utworzonego szablonu, przypisujemy mu domyślny i wysyłamy na serwer.
        if (findConfig) {
          preparedConfigs[configuratorName] = findConfig.config;
        } else {
          const defaultConfiguration = defaultConfigurations[configuratorName];

          preparedConfigs[configuratorName] = defaultConfiguration;

          http
            .post(`/user/configuration/${configuratorName}`, defaultConfiguration)
            .catch(error => {
              serverAlert('Wystąpił błąd przy tworzeniu konfiguracji.');
            });
        }
      });

      // sort thresholds

      ['templates', 'sounds', 'synth'].forEach(category => {
        if (preparedConfigs.TIP_ALERT.displaySettings.tresholds[category]) {
          preparedConfigs.TIP_ALERT.displaySettings.tresholds[
            category
          ] = preparedConfigs.TIP_ALERT.displaySettings.tresholds[category].sort((a, b) => {
            if (a.amount > b.amount) return 1;
            if (a.amount === b.amount) return 0;
            return -1;
          });
        }
      });

      dispatch(receiveConfigurations(preparedConfigs));
    })
    .catch(error => {
      serverAlert('Wystąpił błąd przy odczytywaniu konfiguracji.');
      dispatch(failureConfigurations());
    });
};

const fetchConfigurators = () => (dispatch, getState) => {
  dispatch(requestConfigurations());

  http
    .get('/user/configuration/global/forbidden_words')
    .then(response => response.data)
    .then(data => {
      dispatch(updateGlobalConfiguration({ forbidden_words: data }));
    });

  http
    .get('/user/configuration/global/profanity_filter')
    .then(response => response.data)
    .then(data => {
      dispatch(updateGlobalConfiguration({ profanity_filter: data }));
    });

  dispatch(fetchBaseConfig());
};

const debouncedGlobalConfiguraion = debounce(
  (type, updatedData) => http.put(`/user/configuration/global/${type}`, updatedData),
  1000,
);

export const submitUpdatedGlobalConfiguration = (type, updatedData) => async dispatch => {
  try {
    dispatch(updateGlobalConfiguration({ [type]: updatedData }));
    await debouncedGlobalConfiguraion(type, updatedData);
  } catch (e) {
    serverAlert('Wystąpił błąd przy zapisywaniu konfiguracji');
  }
};

export const fetchConfiguratorsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchData(getState().configuratorsConfigs)) {
    dispatch(fetchConfigurators());
  }
};

export const forceConfigFetch = () => (dispatch, getState) => {
  dispatch(fetchBaseConfig());
};

export const debouncedUpdate = debounce((configurationType, updatedData, userId) => {
  http
    .put(`/user/configuration/${configurationType}`, updatedData)
    .then(() => {
      socket.emit('configuration', configurationType, userId, updatedData);
    })
    .catch(() => {
      serverAlert('Wystąpił błąd przy zapisywaniu konfiguracji');
    });
}, 1000);

export const setConfigurationUpdating = isUpdating => ({
  type: 'SET_CONFIGURATION_UPDATING',
  payload: isUpdating,
});

export const updateConfig = (configurationType, updatedFragment) => (dispatch, getState) => {
  const {
    configuratorsConfigs: { configurations },
    userData: { info: userInfo },
  } = getState();

  dispatch(setConfigurationUpdating(true));

  http
    .get('/user/configuration')
    .then(response => response.data)
    .then(configurationsList => {
      if (configurations[configurationType]) {
        const currentTwitchActivity = configurationsList[0].config.twitchActivityTimer || 0;
        const currentSavedConfig = { ...configurations[configurationType] };
        const configTwitchActivityUpdated = deepmerge(
          currentSavedConfig,
          { twitchActivityTimer: currentTwitchActivity },
          {
            arrayMerge: overwriteMerge,
          },
        );

        const updatedData = deepmerge(configTwitchActivityUpdated, updatedFragment, {
          arrayMerge: overwriteMerge,
        });

        dispatch(updateLocalConfiguration(configurationType, updatedData));
        debouncedUpdate(configurationType, updatedData, userInfo.id);
      } else {
        serverAlert('Wystąpił błąd przy zapisywaniu konfiguracji');
      }
    })
    .finally(() => {
      dispatch(setConfigurationUpdating(false));
    });
};

export const setDisplaySettingsDialogVisibility = visible => ({
  type: 'SET_DISPLAY_SETTINGS_VISIBILITY',
  payload: visible,
});

export const setDisplaySettingsDialogCurrentView = view => ({
  type: 'SET_DISPLAY_SETTINGS_CURRENT_VIEW',
  payload: view,
});
