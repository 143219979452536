import styled, { css } from 'styled-components';

const elementWrapperShadow = css`
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.shadow && elementWrapperShadow}
`;

export default Wrapper;
