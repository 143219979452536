import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Button from 'styles/Button';

const Layout = styled.div`
  display: grid;
  row-gap: 3rem;
  width: 80vw;
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 10dvh 32px;
    column-gap: 3.6rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
    width: 100%;
    border-radius: 20px;
  }
`;

const TextGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
`;

const ButtonContainer = styled.div``;

const ConfirmButton = styled(Button)`
  padding: 10px 22px;
  height: 50px;
  width: auto;
  border-radius: 25px;
  font-family: var(--google-sora-cdn);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;

  background-color: var(--light-gray);
  color: var(--text-color);
`;

const Heading = styled.h2`
  font-family: var(--google-sora-cdn);
  font-size: 48px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4rem;
`;

const TextContainer = styled.p`
  font-family: var(--google-sora-cdn);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
`;

const AnnouncementModalItem = ({ title, content, piclink, piclinkhref, btnLabel }) => (
  <Layout>
    <ImageContainer>
      <img src={piclink} alt="" />
    </ImageContainer>
    <TextGrid>
      <div>
        <Heading>{title}</Heading>
        <TextContainer>
          {content.split('\n').map(line => (
            <React.Fragment key={`line_${uuidv4()}`}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </TextContainer>
      </div>

      <ButtonContainer>
        <a href={piclinkhref} target="_blank" rel="noopener noreferrer">
          <ConfirmButton>{btnLabel || 'Więcej informacji'}</ConfirmButton>
        </a>
      </ButtonContainer>
    </TextGrid>
  </Layout>
);

AnnouncementModalItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  piclink: PropTypes.string.isRequired,
  piclinkhref: PropTypes.string.isRequired,
  btnLabel: PropTypes.string,
};

AnnouncementModalItem.defaultProps = {
  btnLabel: null,
};

export default AnnouncementModalItem;
