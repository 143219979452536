import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deepmerge from 'deepmerge';

export default function withHistory(WrappedComponent, selectData) {
  return class extends Component {
    static propTypes = {
      activeTemplateId: PropTypes.string.isRequired,
      templateConfig: PropTypes.instanceOf(Object).isRequired,
      updateTemplate: PropTypes.instanceOf(Function).isRequired,
      elements: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
      super(props);

      const { templateConfig, activeTemplateId } = props;

      this.state = {
        history: [templateConfig],
        activeTemplateId,
      };
    }

    componentDidMount() {
      window.addEventListener('keydown', this.undoHistory);
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.undoHistory);
    }

    static getDerivedStateFromProps(props, state) {
      let returnedData = {};

      /**
       * Porównując id aktywnego szablonu zapisanego w state komponentu oraz globalnego
       * sprawdzamy czy zresetować historię konfiguratora.
       */
      if (state.activeTemplateId !== props.activeTemplateId) {
        returnedData = {
          activeTemplateId: props.activeTemplateId,
          history: [props.templateConfig],
        };
      }

      return returnedData;
    }

    /**
     * Zwraca wszystkie ukryte elementy
     *
     * @returns {Array}
     */
    getHiddenElements = () => {
      /**
       * Funkcja rekurencyjna iterująca po każdym elemencie sprawdzająca przy tym
       * czy klucz elementu isVisible jest wartością fałszywą.
       *
       * @param {Object} object - elementy z najowszego obiektu historii
       * @param {Object} elements - opcje dla elementów z globalego obiektu konfiguracyjnego
       * @returns {Array}
       */
      const mapHiddenElementsToArray = (object, elements) => {
        const arrayKeys = [];

        for (const key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key) && key !== 'customCode') {
            const elementObject = { key, title: elements[key].title };

            if (Object.prototype.hasOwnProperty.call(object[key], 'children')) {
              const mappedChildren = mapHiddenElementsToArray(
                object[key].children,
                elements[key].children,
              );

              if (mappedChildren.length) {
                arrayKeys.push([
                  elementObject,
                  mapHiddenElementsToArray(object[key].children, elements[key].children),
                ]);
              }
            } else if (!object[key].isVisible) {
              arrayKeys.push(elementObject);
            }
          }
        }

        return arrayKeys;
      };

      const { elements } = this.props;
      const history = this.getLatestHistoryElement();

      return mapHiddenElementsToArray(history.elementsOptions, elements);
    };

    /**
     * Funkcja ustawiająca widocznośc dla elementu
     *
     * @param {String} elementKey
     * @returns {void}
     */
    showElement = elementKey => {
      const splittedKeys = elementKey.split('|');

      /**
       * Funkcja tworząca strukturę obiektu widocznego elementu
       *
       * @param {number} deepIndex
       * @returns {Object}
       */
      const createUpdateObject = deepIndex => {
        const value =
          deepIndex + 1 === splittedKeys.length
            ? { isVisible: true }
            : { children: createUpdateObject(deepIndex + 1) };

        return {
          [splittedKeys[deepIndex]]: value,
        };
      };

      this.pushHistory(createUpdateObject(0));
    };

    /**
     * Cofa historię o jeden jeżeli to możliwe
     *
     * @param {Object} historyElement
     * @returns {void}
     */
    undoHistory = event => {
      const { history } = this.state;
      const { updateTemplate } = this.props;

      if (event.keyCode === 90 && event.ctrlKey && history.length > 1) {
        const cloneHistory = [...history];
        cloneHistory.pop();

        this.setState(
          {
            history: cloneHistory,
          },
          () => {
            updateTemplate({ elementsOptions: cloneHistory[0].elementsOptions });
          },
        );
      }
    };

    /**
     * Tworzy nowy element historii
     *
     * @returns {Object}
     */
    createHistoryElement = () => {
      const { history } = this.state;

      return JSON.parse(JSON.stringify(history[history.length - 1]));
    };

    /**
     * Dodaje nowy element historii
     *
     * @param {Object} historyElement
     * @returns {void}
     */
    pushHistory = updatedData => {
      const { updateTemplate } = this.props;
      const { history } = this.state;

      const historyElement = this.createHistoryElement();
      const mergedHistory = deepmerge(historyElement, { elementsOptions: updatedData });

      updateTemplate({ elementsOptions: updatedData });

      this.setState({
        history: [...history, mergedHistory],
      });
    };

    /**
     * Zwraca najnowszy element historii
     *
     * @returns {Object}
     */
    getLatestHistoryElement = () => {
      const { history } = this.state;

      return history[history.length - 1];
    };

    /**
     * Funkcja resetująca historię
     *
     * @returns {Object}
     */
    resetHistory = () => {
      const { history } = this.state;

      return history[history.length - 1];
    };

    render() {
      const { history } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          showElement={this.showElement}
          getHiddenElements={this.getHiddenElements}
          history={history}
          pushHistory={this.pushHistory}
          createHistoryElement={this.createHistoryElement}
          getLatestHistoryElement={this.getLatestHistoryElement}
        />
      );
    }
  };
}
