import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import OptionsPopup, { OptionsPopupItem } from 'styles/OptionsPopup';

import Toggle from 'components/Toggle';
import FloatingMenu from 'components/FloatingMenu';

const Wrapper = styled.div`
  position: relative;
  width: 350px;
`;

export const TemplateSelector = ({ selected, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const rootRef = useRef();

  const availableTemplates =
    useSelector(({ templates }) => {
      const createdTemplates = [...templates.created];
      createdTemplates.sort((a, b) => (a.config.title > b.config.title ? 1 : -1));
      const fullTemplatesList = [...templates.defaults, ...createdTemplates];
      return fullTemplatesList.filter(value => value.type === 'TIPS_GOAL');
    }) || [];

  const selectedTemplate = () => {
    let foundTemplate = availableTemplates.find(value => value.id === selected);

    if (!foundTemplate) {
      [foundTemplate] = availableTemplates;
    }

    return foundTemplate;
  };

  const onItemSelect = id => {
    onChange(id);
    setIsActive(false);
  };

  return (
    <Wrapper ref={rootRef}>
      <Toggle
        active={isActive}
        type="button"
        onClick={() => setIsActive(prevState => !prevState)}
        rounded
      >
        <span>{selectedTemplate().config.title}</span>
        <ArrowDown />
      </Toggle>
      <FloatingMenu
        visible={isActive}
        position="bottom-left"
        onClose={() => setIsActive(false)}
        parent={rootRef.current || document.body}
      >
        <OptionsPopup style={{ position: 'relative' }}>
          {availableTemplates.map(item => (
            <OptionsPopupItem key={item.id} onClick={() => onItemSelect(item.id)}>
              {item.config.title}
            </OptionsPopupItem>
          ))}
        </OptionsPopup>
      </FloatingMenu>
    </Wrapper>
  );
};

TemplateSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
