import styled from 'styled-components';

export default styled.div`
  text-align: right;
  width: 523px;

  .tools-menu {
    background: var(--bg-color-white-gray);
    border-radius: 50px;
    padding: 0px 10px;
    display: inline-flex;
    height: 57px;
    align-items: center;

    li {
      list-style-type: none;
      position: relative;
    }
  }
`;
