import styled from 'styled-components';

import ItemDeleteButton from './ItemRedButton';

export default styled(ItemDeleteButton)`
  background-color: var(--brand-blue);

  &:hover {
    background-color: var(--brand-blue);
    box-shadow: 0 0 26px 0 var(--brand-blue);
  }

  > svg {
    width: 23px;
  }
`;
