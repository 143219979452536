const templates = [
  {
    title: 'Domyślny szablon #1',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 210,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#95fc006b',
          zIndex: 0,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 335,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 2,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#95fc00ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#95fc00ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 152,
          y: 285.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 590,
          height: 73,
        },
        styles: {
          zIndex: 1,
          borderRadius: 100,
          gradientColor: '#000000ff',
          backgroundColor: '#00000045',
        },
        stripes: true,
        position: {
          x: 105,
          y: 263.5,
        },
        isVisible: true,
        gradientColor: '#95fc00b8',
        progressColor: '#95fc00b8',
      },
    },
  },
  {
    title: 'Domyślny szablon #2',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 210,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#40404085',
          zIndex: 0,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 335,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 2,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#95fc00ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#95fc00ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 152,
          y: 285.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 590,
          height: 73,
        },
        styles: {
          zIndex: 1,
          borderRadius: 100,
          gradientColor: '#000000d4',
          backgroundColor: '#0a0a0a00',
        },
        stripes: false,
        position: {
          x: 105,
          y: 263.5,
        },
        isVisible: true,
        gradientColor: '#040404ff',
        progressColor: '#36363600',
      },
    },
  },
  {
    title: 'Domyślny szablon #3',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sniglet',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 210,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#fce40082',
          zIndex: 0,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sniglet',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 226.5,
          y: 334,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#fce400ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#fce400ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: '/',
          },
        },
        position: {
          x: 157.5,
          y: 282.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 590,
          height: 73,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#0a090ac7',
          backgroundColor: '#00000000',
        },
        stripes: false,
        position: {
          x: 105,
          y: 263.5,
        },
        isVisible: true,
        gradientColor: '#000000ff',
        progressColor: '#00000024',
      },
    },
  },
  {
    title: 'Domyślny szablon #4',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sniglet',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 210,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#0078ff85',
          zIndex: 1,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sniglet',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 226.5,
          y: 334,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#4999f3ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#4999f3ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: '/',
          },
        },
        position: {
          x: 157.5,
          y: 282.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 590,
          height: 73,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#27098de8',
          backgroundColor: '#06021900',
        },
        stripes: false,
        position: {
          x: 105,
          y: 263.5,
        },
        isVisible: true,
        gradientColor: '#0078ffff',
        progressColor: '#0078ff00',
      },
    },
  },
  {
    title: 'Domyślny szablon #5',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Darker Grotesque',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 332.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#fe000085',
          zIndex: 2,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Darker Grotesque',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 230.5,
          y: 223,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#fd0202ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#fd0000ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: '/',
          },
        },
        position: {
          x: 156.5,
          y: 279.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 557,
          height: 69,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#070707ff',
          backgroundColor: '#00000000',
        },
        stripes: false,
        position: {
          x: 121.5,
          y: 265.5,
        },
        isVisible: true,
        gradientColor: '#f20202b5',
        progressColor: '#b50c0c17',
      },
    },
  },
  {
    title: 'Domyślny szablon #6',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 35,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Darker Grotesque',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 332.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#2bd28387',
          zIndex: 2,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Darker Grotesque',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 230.5,
          y: 223,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#2bd283ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          separator: {
            styles: {
              color: '#2bd283ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Darker Grotesque',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: '/',
          },
        },
        position: {
          x: 157.5,
          y: 279.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 557,
          height: 69,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#070707ff',
          backgroundColor: '#00000000',
        },
        stripes: false,
        position: {
          x: 121.5,
          y: 265.5,
        },
        isVisible: true,
        gradientColor: '#2bd283b5',
        progressColor: '#2bd28300',
      },
    },
  },
  {
    title: 'Domyślny szablon #7',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 25,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 75,
          y: 285.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#60606078',
          zIndex: 1,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 346,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#4999f3ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: false,
          },
          separator: {
            styles: {
              color: '#606060ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 395.5,
          y: 285.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 733,
          height: 68,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#000000ff',
          backgroundColor: '#00000061',
        },
        stripes: false,
        position: {
          x: 33.5,
          y: 266,
        },
        isVisible: true,
        gradientColor: '#000000e6',
        progressColor: '#00000059',
      },
    },
  },
  {
    title: 'Domyślny szablon #8',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 25,
          fontStyle: 'normal',
          textAlign: 'left',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 75,
          y: 285.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#7b07f780',
          zIndex: 1,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 346,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 4,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#4999f3ff',
              zIndex: 2,
              fontSize: 30,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sniglet',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: false,
          },
          separator: {
            styles: {
              color: '#5707f7ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 395.5,
          y: 285.5,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 733,
          height: 68,
        },
        styles: {
          zIndex: 2,
          borderRadius: 100,
          gradientColor: '#000000ab',
          backgroundColor: '#00000000',
        },
        stripes: false,
        position: {
          x: 33.5,
          y: 266,
        },
        isVisible: true,
        gradientColor: '#5c0ae2de',
        progressColor: '#6416e800',
      },
    },
  },
  {
    title: 'Domyślny szablon #9',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 25,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 231.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#40404085',
          zIndex: 2,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 365,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 2,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#fce400ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: false,
          },
          separator: {
            styles: {
              color: '#404040ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 118,
          y: 277,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 475,
          height: 24,
        },
        styles: {
          zIndex: 1,
          borderRadius: 100,
          gradientColor: '#00000003',
          backgroundColor: '#00000030',
        },
        stripes: false,
        position: {
          x: 161.5,
          y: 341,
        },
        isVisible: true,
        gradientColor: '#060606ff',
        progressColor: '#404040ff',
      },
    },
  },
  {
    title: 'Domyślny szablon #10',
    editable: false,
    elementsOptions: {
      goalName: {
        styles: {
          color: '#fff',
          width: 800,
          zIndex: 3,
          fontSize: 25,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 0,
          y: 231.5,
        },
        isVisible: true,
        textValue: 'Przykładowa nazwa celu',
      },
      amountPaid: {
        styles: {
          color: '#f72c6f75',
          zIndex: 2,
          fontSize: 51,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Sora',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 189.5,
          y: 365,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '4,43zł',
      },
      goalNumbers: {
        styles: {
          zIndex: 2,
        },
        children: {
          to: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'right',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          from: {
            styles: {
              color: '#fff',
              zIndex: 2,
              fontSize: 40,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
          },
          percent: {
            styles: {
              color: '#fce400ff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'left',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: false,
          },
          separator: {
            styles: {
              color: '#f72c6fff',
              zIndex: 2,
              fontSize: 25,
              fontStyle: 'normal',
              textAlign: 'center',
              fontFamily: 'Sora',
              fontWeight: 900,
              textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
            },
            isVisible: true,
            textValue: 'z',
          },
        },
        position: {
          x: 118,
          y: 277,
        },
        isVisible: true,
      },
      progressBar: {
        size: {
          width: 475,
          height: 24,
        },
        styles: {
          zIndex: 1,
          borderRadius: 100,
          gradientColor: '#f72c6f42',
          backgroundColor: '#00000003',
        },
        stripes: true,
        position: {
          x: 162.5,
          y: 341,
        },
        isVisible: true,
        gradientColor: '#f72c6f75',
        progressColor: '#f72c6f75',
      },
    },
  },
];

export default templates;
