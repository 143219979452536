import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';
import { PaySafeCardLogo } from 'styles/Logas';
import { ButtonWithAnimatedArrow } from 'components/Buttons';
import TwoColumnsContent from 'components/Dialog/components/TwoColumnsContent';

const ContentWrapper = styled.div`
  max-width: 362px;

  em {
    font-style: normal;
    color: var(--orange-red);
  }
`;

const ExternalTipsSourceDisconnectedNotification = ({ onActionButtonClick }) => (
  <TwoColumnsContent title="Deaktywowano metodę płatności Paysafecard" type="danger">
    <PaySafeCardLogo style={{ marginBottom: 32 }} />
    <ContentWrapper>
      <Text size={14} lineHeight="1.36" margin="0 0 20px">
        <em>Wystąpił błąd</em>
        {' przy ostatniej próbie połączenia się systemu z platformą odpowiedzialną za przyjęcie wiadomości ' +
          'Paysafecard.'}
      </Text>
      <Text size={14} lineHeight="1.36" margin="0 0 25px">
        Bardzo możliwe, że to dlatego, że zmieniono klucz API w ustawieniach serwisu. Dokonaj
        połączenia ponownie.
      </Text>
      <ButtonWithAnimatedArrow onClick={onActionButtonClick}>
        Spróbuj ponownie
      </ButtonWithAnimatedArrow>
    </ContentWrapper>
  </TwoColumnsContent>
);

export default ExternalTipsSourceDisconnectedNotification;

ExternalTipsSourceDisconnectedNotification.propTypes = {
  onActionButtonClick: PropTypes.instanceOf(Function).isRequired,
};
