import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg';
import ElementActionButton from './ElementActionButton';

const AddElementButton = ({ onClick }) => (
  <ElementActionButton onClick={onClick} style={{ width: 110 }}>
    <LayersIcon />
    Dodaj
  </ElementActionButton>
);

AddElementButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default AddElementButton;
