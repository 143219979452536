import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Modal from '../Modal';
import Footer from '../Modal/styles/Footer';
import FooterButton from '../Modal/styles/FooterButton';

import Content from './components/Content';
import Description from './components/Description';
import List from './components/List';
import ListItem from './components/List/components/ListItem';
import Subtitle from './components/Subtitle';
import Title from './components/Тitle';

const OnBoardingDialog = ({ id, buttonAction, children }) => {
  const onBoardingId = `onboarded-${id}`;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (localStorage.getItem(onBoardingId) !== 'y') {
        setShow(true);
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onClose = () => {
    localStorage.setItem(onBoardingId, 'y');
    setShow(false);
    buttonAction();
  };

  return (
    <Modal isMounted={show} onClose={onClose} withCloseButton={false} height={890}>
      <Content>
        <Subtitle>Nowość</Subtitle>
        {children}
      </Content>
      <Footer>
        <FooterButton background="var(--brand-blue)" type="button" onClick={onClose}>
          Sprawdź nową funkcję
        </FooterButton>
      </Footer>
    </Modal>
  );
};

OnBoardingDialog.Title = Title;
OnBoardingDialog.Description = Description;
OnBoardingDialog.List = List;
OnBoardingDialog.ListItem = ListItem;

OnBoardingDialog.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonAction: PropTypes.instanceOf(Function),
};

OnBoardingDialog.defaultProps = {
  buttonAction: () => null,
};

export default OnBoardingDialog;
