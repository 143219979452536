import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ColorfulRoundedButton } from 'components/Buttons';
import Flexbox from 'components/Flexbox';
import WhiteBox from 'styles/WhiteBox';

import SettingsButton from './SettingsButton';
import MethodStateSwitch from './MethodStateSwitch';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${SettingsButton} {
    position: relative;
    top: -1px;
  }
`;
const ConnectedMethodSettings = ({ disconnect, working, enabled, onChange, onClose, children }) => (
  <WhiteBox style={{ padding: 25 }}>
    <Wrapper>
      <ColorfulRoundedButton
        variant="red"
        onClick={disconnect}
        disabled={working}
        style={{
          padding: '13px 23px',
        }}
      >
        Rozłącz
      </ColorfulRoundedButton>
      {children}
      <Flexbox>
        <MethodStateSwitch enabled={enabled} onChange={onChange} />
        <SettingsButton onClick={onClose} />
      </Flexbox>
    </Wrapper>
  </WhiteBox>
);

ConnectedMethodSettings.propTypes = {
  disconnect: PropTypes.instanceOf(Function).isRequired,
  working: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
  children: PropTypes.node,
};

ConnectedMethodSettings.defaultProps = {
  children: null,
};

export default ConnectedMethodSettings;
