import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeBackground } from 'store/actions/configuratorsBackground';

import ChangeScreenBackground from './ChangeScreenBackground';

const mapStateToProps = state => {
  const { defaults, active } = state.configuratorsBackground;

  return {
    defaultBackgrounds: defaults,
    activeBackground: active,
  };
};
const mapDispatchToProps = dispatch => ({
  changeBackground: background => {
    dispatch(changeBackground(background));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ChangeScreenBackground),
);
