import { useDispatch } from 'react-redux';

import { useTemplates } from 'store/selectors/templates';
import {
  createTemplate,
  updateTemplate,
  deleteTemplate,
  changeActiveTemplate,
} from 'store/actions/templates';

const useViewTemplates = type => {
  const { defaults, created, active } = useTemplates();
  const dispatch = useDispatch();

  const defaultTemplates = defaults.filter(template => template.type === type);
  const createdTemplates = created.filter(template => template.type === type);
  const allTemplates = [...defaultTemplates, ...createdTemplates];

  const latest = createdTemplates
    .sort((a, b) => {
      const dateA = new Date(a.updated_at).getTime();
      const dateB = new Date(b.updated_at).getTime();
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    })
    .at(0);

  const getTemplateById = id => allTemplates.find(template => template.id === id);

  const activeTemplate = getTemplateById(active[type]);
  const recentTemplate = latest || defaultTemplates.at(0);

  const dispatchChangeActiveTemplate = (templateId = null) => {
    dispatch(changeActiveTemplate(templateId, type));
  };

  const dispatchCreateTemplate = (updatedFragment = null) => {
    dispatch(createTemplate({ ...updatedFragment, editable: true }, type));
  };

  const dispatchUpdateTemplate = (updatedFragment, templateId = null) => {
    dispatch(updateTemplate({ ...updatedFragment, editable: true }, templateId, type));
  };

  const dispatchDeleteTemplate = (templateId = null) => {
    dispatch(deleteTemplate(templateId, type));
  };

  return {
    defaultTemplates,
    createdTemplates,
    allTemplates,
    activeTemplate,
    recentTemplate,
    getTemplateById,
    setActiveTemplateId: dispatchChangeActiveTemplate,
    createTemplate: dispatchCreateTemplate,
    updateTemplate: dispatchUpdateTemplate,
    deleteTemplate: dispatchDeleteTemplate,
  };
};

export default useViewTemplates;
