import React, { useContext } from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation, EditableInput, Alpha } from 'react-color/lib/components/common';

import { toHexWithAlpha } from '../../helpers';
import { TextEditor } from '../../Context';
import ColorPickerElements from './components/ColorPickerElements';
import SaturationWrapper from './components/SaturationWrapper';
import HueWrapper from './components/HueWrapper';
import SaturationPointer from './components/SaturationPointer';
import HuePointer from './components/HuePointer';
import AlphaPointer from './components/AlphaPointer';
import AlphaWrapper from './components/AlphaWrapper';

import { Option, ColorPreview, AbsoluteLayer } from '../../styles';

const TYPE = 'color';

const editableInputStyles = {
  input: {
    margin: '0 0 0 60px',
    fontSize: '16px',
    height: '25px',
    lineHeight: '25px',
    border: 'none',
    padding: 0,
    letterSpacing: '0.5px',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'var(--bg-input-color-editor)',
    color: 'var(--text-color)',
  },
  label: {
    display: 'none',
  },
};

const ColorPicker = CustomPicker(({ onChange, ...props }) => {
  const { hex, rgb } = props;
  const { isActive } = useContext(TextEditor);

  const hexWithAlpha = toHexWithAlpha(rgb, hex);

  return (
    <Option {...props} style={{ top: -1 }}>
      <ColorPreview
        style={{
          backgroundColor: hexWithAlpha,
          height: 30,
          width: 30,
          boxSizing: 'border-box',
          border: `solid 5px color-mix(in srgb, ${hexWithAlpha} 50%, black)`,
        }}
      />

      <AbsoluteLayer isActive={isActive(TYPE)}>
        <EditableInput
          {...props}
          onChange={onChange}
          value={hexWithAlpha}
          label="hex"
          style={editableInputStyles}
        />
      </AbsoluteLayer>

      <ColorPickerElements isActive={isActive(TYPE)}>
        <SaturationWrapper>
          <Saturation {...props} onChange={onChange} pointer={SaturationPointer} />
        </SaturationWrapper>
        <HueWrapper>
          <Hue {...props} onChange={onChange} pointer={HuePointer} />
        </HueWrapper>
        <AlphaWrapper>
          <Alpha {...props} onChange={onChange} pointer={AlphaPointer} />
        </AlphaWrapper>
      </ColorPickerElements>
    </Option>
  );
});

export default ColorPicker;
