export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_LATEST_WITHDRAWALS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'FAILURE_LATEST_WITHDRAWALS':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'RECEIVE_LATEST_WITHDRAWALS':
      return {
        ...state,
        failureFetching: false,
        isFetching: false,
        isFetched: true,
        items: action.receivedData,
      };
    default:
      return state;
  }
};
