import { useState } from 'react';

import copyToClipboard from '../copyToClipboard';

let timeout;
export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  function copyLink(linkToCopy) {
    clearTimeout(timeout);

    copyToClipboard(linkToCopy);
    setCopied(true);

    timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  return [copied, copyLink];
};
