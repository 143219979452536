import styled from 'styled-components';

const ExpandedInfo = styled.div`
  height: 0;
  padding: 10px 0;
  overflow: hidden;

  transition: 0.2s ease;

  ${props =>
    !props.expanded &&
    `
    padding: 0;
  `}
`;

export default ExpandedInfo;
