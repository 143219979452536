import styled from 'styled-components';

const AlertBox = styled.div`
  position: relative;

  width: 440px;
  border-radius: 15px;
  padding: 80px 60px 45px;
  z-index: 2;

  background-color: var(--white);
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--border-color);
  border-radius: 20px;

  text-align: center;

  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);
`;

export default AlertBox;
