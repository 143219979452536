import styled, { css } from 'styled-components';

import OptionsPopup from 'styles/OptionsPopup';

const visibilityStyles = css`
  transform: scale(1);
  opacity: 1;
  visibility: visible;
`;

export default styled(OptionsPopup)`
  margin: 10px 0 0;
  padding: 10px;

  opacity: 0;
  visibility: hidden;

  transform: scale(0);
  transform-origin: top;

  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  ${props => props.visible && visibilityStyles};
`;
