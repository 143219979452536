import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RootModal from 'components/Modal';
import Text from 'components/Text';
import Scrollbars from 'react-custom-scrollbars';
import Button from 'components/Button';
import { alert } from 'components/Alert';
import Input from '../styles/Input';
import FormRow from '../styles/FormRow';
import InputWrapper from '../styles/InputWrapper';
import NumberWrapper from '../styles/NumberWrapper';
import View from '../styles/View';
import TextWrapper from '../styles/TextWrapper';
import NumberInput from '../styles/NumberInput';
import TextTop from '../../../../../components/Modal/styles/TextTop';
import TextHeader from '../../../../../components/Modal/styles/TextHeader';
import TextDescription from '../../../../../components/Modal/styles/TextDescription';

export default class TopDonators extends Component {
  static propTypes = {
    fetchTopDonators: PropTypes.instanceOf(Function).isRequired,
    defineTopDonators: PropTypes.instanceOf(Function).isRequired,
    removeTopDonators: PropTypes.instanceOf(Function).isRequired,
    topDonators: PropTypes.instanceOf(Array).isRequired,
    isMounted: PropTypes.bool,
    onClose: PropTypes.instanceOf(Function).isRequired,
  };

  static defaultProps = {
    isMounted: false,
  };

  constructor(props) {
    super(props);

    this.donators = [...Array(7)].map(() => ({
      nickname: '',
      amount: 0,
    }));
  }

  componentDidMount() {
    const { fetchTopDonators } = this.props;

    fetchTopDonators();
  }

  async onSubmit(event) {
    event.preventDefault();

    const { defineTopDonators, removeTopDonators } = this.props;

    const donators = this.donators.filter(donator => donator.nickname.length > 0);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < donators.length; i++) {
      if (donators[i].amount === 0) {
        alert.open({
          config: {
            type: 'error',
            description: `Kwota dla nazwy użytkownika "${donators[i].nickname}" jest pusta`,
          },
        });
        return;
      }
    }

    const findDuplicates = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < donators.length; i++) {
      if (findDuplicates.includes(donators[i].nickname)) {
        alert.open({
          config: {
            type: 'error',
            description: `Duplikat nazwy użytkownika "${donators[i].nickname}"`,
          },
        });
        return;
      }
      findDuplicates.push(donators[i].nickname);
    }

    const saveDonators = donators.map(d => ({
      nickname: d.nickname,
      amount: d.amount,
    }));

    if (donators.length === 0) {
      await removeTopDonators();
    } else {
      await defineTopDonators(saveDonators);
    }

    alert.open({
      config: {
        type: 'success',
        description: `Zmiany zapisane`,
        confirmButtonText: 'OK',
      },
    });
  }

  updateNickname(i, value) {
    this.donators[i].nickname = value;
  }

  updateAmount(i, value) {
    this.donators[i].amount = value * 100;
  }

  render() {
    const { topDonators, onClose, isMounted } = this.props;
    let existingMax = [];

    if (topDonators) {
      topDonators.sort((a, b) => (a.amount < b.amount ? 1 : -1));
      this.donators = topDonators.map(donator => ({
        nickname: donator.nickname,
        amount: donator.amount,
        id: donator.id,
      }));
      existingMax = topDonators.slice(0, 50);
    }

    const newDonators = [...Array(50 - existingMax.length)].map(() => ({
      nickname: '',
      amount: 0,
    }));

    this.donators = this.donators.concat(newDonators);
    this.donators.sort((a, b) => (a.amount < b.amount ? 1 : -1));

    return (
      <RootModal isMounted={isMounted} onClose={onClose}>
        <View>
          <div>
            <TextTop>ZMIEŃ SWÓJ RANKING WIDZÓW</TextTop>
            <TextHeader>Edytuj ranking wiadomości</TextHeader>
            <TextDescription>
              Uzupełniając rubryki, wprowadzasz widzów,
              <br />
              którzy znajdą się w Twoim rankingu wiadomości!
              <br />
              Możesz tego użyć na przykład kiedy już miałeś
              <br />
              swój ranking największych wpłat. Teraz możesz go dodać tutaj!
            </TextDescription>
          </div>
          <form
            ref={this.form}
            onSubmit={event => {
              this.onSubmit(event);
            }}
            noValidate
            style={{ marginTop: '25px' }}
          >
            <>
              <Scrollbars className="scrollbar" style={{ height: '80%', marginBottom: '30px' }}>
                {this.donators.map((e, i) => (
                  <FormRow key={Math.random()}>
                    <InputWrapper>
                      <Input
                        onChange={event => {
                          this.updateNickname(i, event.target.value);
                        }}
                        defaultValue={e.nickname}
                        name="nickname"
                        type="text"
                        placeholder="Nazwa użytkownika"
                      />
                    </InputWrapper>
                    <NumberWrapper>
                      <NumberInput
                        name="amount"
                        onValueChange={values => this.updateAmount(i, values.floatValue)}
                        decimalSeparator=","
                        decimalScale={2}
                        defaultValue={e.amount / 100}
                        allowEmptyFormatting
                        allowNegative={false}
                        suffix=" PLN"
                        placeholder="Kwota"
                        style={{
                          padding: '15px',
                          fontSize: '13px',
                          borderRadius: '30px',
                        }}
                      />
                    </NumberWrapper>
                  </FormRow>
                ))}
              </Scrollbars>
              <TextWrapper>
                <Text>
                  By wprowadzone zmiany były widoczne, należy odświeżyć widget, po zatwierdzeniu
                </Text>
              </TextWrapper>
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button type="submit" background="blue">
                  Zatwierdź i zapisz
                </Button>
              </span>
            </>
          </form>
        </View>
      </RootModal>
    );
  }
}
