import styled from 'styled-components';

import Text from '../../Text';

const Paragraph = styled(Text)`
  font-size: 16px;
  margin: 0 0 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Paragraph;
