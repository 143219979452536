import styled from 'styled-components';

export default styled.div`
  border: 1px solid var(--bg-color-light-gray);
  background: var(--bg-color-text-input);
  border-radius: 5px;
  padding: 14px 20px;
  width: 86%;
  transition: 250ms;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  cursor: not-allowed;

  > span {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    filter: blur(5px);
    line-height: 0.7;
  }

  &.text-copied {
    animation: increaseLeftPadding 1.5s ease-in-out;
    color: var(--brand-blue);

    > span {
      filter: blur(0);
      overflow: unset;
    }
  }
`;
