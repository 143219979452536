import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserAccount } from 'store/selector';
import { USER_ACCOUNT_TYPE } from 'store/constants';
import { convertToZlotys } from 'utils/priceConverter';
import { isNull } from 'utils/validators';

import Text from 'components/Text';
import Flexbox from 'components/Flexbox';
import { ClearButton } from 'components/styled';

import CollapsedWhiteBox from 'components/CollapsedWhiteBox';
import Odometer from '../../Odometer';
import { Context } from '../state';

const LANG = {
  GRANT: {
    text: 'BLIK, Przelew, PaySafeCard, Karty płatnicze',
    tooltip: 'Zawiera on wpłaty systemami: BLIK, Przelew, PaySafeCard i Karty Płatnicze.',
  },
  WAGE: {
    text: 'SMS, SMS Plus, SMS FULL',
    tooltip: 'Zawiera on wpłaty systemami: SMS, SMS PLUS i SMS FULL.',
  },
};

const Button = styled(ClearButton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const AccountBalance = ({ type }) => {
  const { activeType, setActiveType } = useContext(Context);
  const account = useSelector(selectUserAccount(type, 'SP'));
  const accountIsNull = isNull(account);
  const isChecked = activeType === type;
  const isDisabled = accountIsNull || account.status === 'CLOSED';

  const handleClick = () => {
    setActiveType(isChecked ? null : type);
  };

  return (
    <CollapsedWhiteBox
      localStorageName={`accountBalance${type}`}
      overlay={<Button type="button" onClick={() => handleClick()} />}
      faded={isDisabled || !isChecked}
      disabled={isDisabled}
    >
      <Text size={15} margin="0">
        Bilans konta
        <br />
        <strong>{LANG[type].text}</strong>
      </Text>
      <Flexbox align="flex-end">
        <Odometer value={accountIsNull ? 0 : convertToZlotys(account.balance)} format="( ddd),dd" />
        <Text weight="bold" size={22} color="gray" margin="0">
          PLN
        </Text>
      </Flexbox>
    </CollapsedWhiteBox>
  );
};

AccountBalance.propTypes = {
  type: PropTypes.oneOf(USER_ACCOUNT_TYPE).isRequired,
};

export default AccountBalance;
