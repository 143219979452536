import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WhiteBox from 'styles/WhiteBox';

import Grid from 'components/Grid';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Flexbox from 'components/Flexbox';
import serverAlert from 'store/utils/serverAlert';

import { Consumer } from '../../Context';

const TipsUrl = ({ userDataProfile }) => {
  const [link, setLinkValue] = useState(userDataProfile.link || '');
  const [loading, setLoading] = useState(false);
  const [cachedLinkValue, setCachedLinkValue] = useState(userDataProfile.link || '');

  return (
    <Consumer>
      {({ updateTipsPageSettings }) => (
        <Grid columns="1fr 200px 200px" style={{ alignItems: 'center' }}>
          <WhiteBox style={{ padding: '10px 20px' }}>
            <Flexbox align="center" justify="between">
              <Text size={16} margin="0 25px 0 0" style={{ whiteSpace: 'nowrap' }}>
                Ustaw URL do swojej strony zamówień:
              </Text>
              <TextInput
                value={link}
                prefix="https://tipply.pl/@"
                maxLength={32}
                onChange={({ target }) => setLinkValue(target.value)}
                copyButton
                copyWithPrefix
              />
            </Flexbox>
          </WhiteBox>

          <Button
            background="blue"
            type="button"
            disabled={link === userDataProfile.link || loading}
            loading={loading}
            onClick={async () => {
              if (userDataProfile.link === link) return;
              setLoading(true);

              try {
                await updateTipsPageSettings({ link });
                setCachedLinkValue(link);
              } catch (error) {
                setLinkValue(cachedLinkValue);
                serverAlert(error.response.data.errors.children.link.errors.join(', '));
              }

              setLoading(false);
            }}
          >
            Zapisz URL
          </Button>
          <Button
            background="yellow"
            type="button"
            disabled={link === userDataProfile.link || loading}
            onClick={() => {
              setLinkValue(cachedLinkValue);
            }}
          >
            Anuluj zmiany
          </Button>
        </Grid>
      )}
    </Consumer>
  );
};

TipsUrl.propTypes = {
  userDataProfile: PropTypes.instanceOf(Object).isRequired,
};

export default TipsUrl;
