export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_ANNOUNCEMENTS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'PATCH_ANNOUNCEMENTS':
      return {
        ...state,
        isPatching: true,
        failurePatching: false,
      };
    case 'RECEIVE_ANNOUNCEMENTS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.announcements,
      };
    case 'FAILURE_ANNOUNCEMENTS':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'FAILURE_PATCH_ANNOUNCEMENTS':
      return {
        ...state,
        isPatching: false,
        failurePatching: true,
      };
    case 'DELETE_ANNOUNCEMENTS':
      return {
        ...state,
        isFetching: false,
        isPatching: false,
        items: state.items.filter(item => !action.ids.includes(item.id)),
      };
    case 'DISPLAY_MORE_INFO_MODAL':
      return {
        ...state,
        forceMoreInfoModal: true,
      };
    case 'HIDE_MORE_INFO_MODAL':
      return {
        ...state,
        forceMoreInfoModal: false,
      };
    default:
      return state;
  }
};
