import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import socket from 'utils/socket';

import Container from 'components/styled/Container';
import Grid from 'components/Grid';
import StatisticsBox from 'components/StatisticsBox';
import Points from 'components/Points';
import LatestTips from './components/LatestTips';
import Announcements from './components/Announcements';
import Hello from './components/Hello';
import Modal from './components/Modal';

import ComponentWrapper from './components/ComponentWrapper';

import Wrapper from './styles/Wrapper';

class UserPanel extends Component {
  static propTypes = {
    fetchInitStatisticsIfNeeded: PropTypes.instanceOf(Function).isRequired,
    fetchTipsIfNeeded: PropTypes.instanceOf(Function).isRequired,
    fetchInitStatistics: PropTypes.instanceOf(Function).isRequired,
    receivedTip: PropTypes.instanceOf(Function).isRequired,
    statisticsData: PropTypes.instanceOf(Object).isRequired,
    userData: PropTypes.instanceOf(Object).isRequired,
    tips: PropTypes.instanceOf(Array).isRequired,
    forceMoreInfoModal: PropTypes.bool.isRequired,
    displayMoreInfoModal: PropTypes.instanceOf(Function).isRequired,
    hideMoreInfoModal: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    this.socket = socket(throttle(this.receiveTip, 500));
  }

  componentDidMount() {
    const { fetchInitStatisticsIfNeeded, fetchTipsIfNeeded } = this.props;

    fetchInitStatisticsIfNeeded();
    fetchTipsIfNeeded();

    this.socket.on();

    document.title = 'Tipply | Panel użytkownika';
  }

  componentWillUnmount() {
    this.socket.off();
  }

  receiveTip = json => {
    const { receivedTip, fetchInitStatistics } = this.props;

    const parsed = JSON.parse(json);
    if (!parsed.resent && !parsed.test) {
      fetchInitStatistics();
      receivedTip(parsed);
      this.forceUpdate();
    }
  };

  render() {
    const {
      statisticsData,
      userData,
      tips,
      forceMoreInfoModal,
      displayMoreInfoModal,
      hideMoreInfoModal,
    } = this.props;
    const latestTips = tips.filter(tip => !tip.test && !tip.resent).slice(0, 12);

    return (
      <Container>
        <Wrapper>
          <Grid columns="1fr 1.5fr">
            <Hello />

            <Grid columns="1fr 1fr" gap="20px 20px">
              <StatisticsBox
                numberFormat="d"
                value={statisticsData.tips.count || 0}
                percent={statisticsData.tips.count_percentage}
                percentTooltip="W porównianiu do poprzedniego miesiąca"
                text="Wyświetlenia powiadomień"
                iconColor="purple"
                localStorageName="tipsCountStatistics"
              />

              <StatisticsBox
                numberFormat="d"
                value={userData.profile.hits || 0}
                text="Odwiedzin strony"
                iconColor="green"
                localStorageName="pageHitsStatistics"
              />

              <StatisticsBox
                numberFormat="d"
                value={statisticsData.tips.messages_length || 0}
                percent={statisticsData.tips.messages_length_percentage}
                percentTooltip="W porównianiu do poprzedniego miesiąca"
                text="Znaków w wiadomościach"
                iconColor="blue"
                localStorageName="charsInTipsMessages"
              />

              <Points compact value={statisticsData.points} />
            </Grid>
          </Grid>

          <Grid columns="1fr 1fr" style={{ alignItems: 'start' }}>
            <ComponentWrapper
              title="Ogłoszenia"
              description="Powiadomienia oraz nowości"
              buttonColor="green"
              onButtonClick={() => displayMoreInfoModal()}
            >
              <Announcements display="latestOne" />
            </ComponentWrapper>
            <ComponentWrapper
              title="Lista wiadomości"
              description="Aktualizowana w czasie rzeczywistym"
              buttonLink="/lista-wiadomosci"
              buttonColor="green"
            >
              <LatestTips items={latestTips} />
            </ComponentWrapper>
          </Grid>
        </Wrapper>

        <Modal isMounted={forceMoreInfoModal} onClose={() => hideMoreInfoModal()} />
      </Container>
    );
  }
}

export default UserPanel;
