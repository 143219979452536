import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close-2.svg';
import ConfigContext from '../ConfigContext';
import ElementActionButton from './ElementActionButton';

const Root = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const Divider = styled.span`
  width: 2px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--white-box-background);
`;

const Actions = styled.div`
  display: flex;
  column-gap: 7px;
`;

const ElementActions = ({ enabled, children }) => {
  const { toggleTextEditor, handleFocusElement } = useContext(ConfigContext);

  const handeCloseClick = () => {
    toggleTextEditor(false);
    handleFocusElement('');
  };

  const handleClick = evt => {
    if (enabled) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
    }
  };

  return (
    <Root onClick={handleClick}>
      <ElementActionButton onClick={handeCloseClick}>
        <CloseIcon />
        Zamknij
      </ElementActionButton>
      <Divider />
      <Actions>{children}</Actions>
    </Root>
  );
};

ElementActions.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool,
};

ElementActions.defaultProps = {
  enabled: false,
};

export default ElementActions;
