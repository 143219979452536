import React from 'react';
import PropTypes from 'prop-types';

import BlankList from 'styles/BlankList';

import Context from '../Context';
import Item from '../styles/Item';

const List = ({ items }) => (
  <Context.Consumer>
    {({ activeId, onChangeOption }) => (
      <BlankList>
        {items.map(({ id, title }) => (
          <Item edited={id === activeId} key={id} onClick={() => onChangeOption(id)}>
            {title}

            <span>używane</span>
          </Item>
        ))}
      </BlankList>
    )}
  </Context.Consumer>
);

List.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
};

export default List;
