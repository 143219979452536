import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
import { RangeSlider } from 'components/RangeSlider';
import MediaPlayButton from 'components/MediaPlayButton';
import InputMediaManager from 'styles/Input';
import ThresholdContext from './ThresholdContext';
import AmountSelector from './AmountSelector';
import DeleteButton from './DeleteButton';

import AmountMin from './styles/AmountMin';
import MediaFile from './styles/MediaFieldContainer';
import InputWrapper from './styles/InputWrapper';
import Options from './styles/Options';

const SoundSelector = () => {
  function getAudioFile(mediumId, list) {
    if (mediumId) return list.find(medium => medium.id === mediumId);
    return undefined;
  }

  const { config, media, onTriggerShowMediaManager, onChange } = useContext(ThresholdContext);
  const [audioFile, setAudioFile] = useState(getAudioFile(config.mediumId, media));
  const [audioFileName, setAudioFileName] = useState('');

  useEffect(() => {
    setAudioFile(getAudioFile(config.mediumId, media));
  }, [config.mediumId, media]);

  useEffect(() => {
    setAudioFileName(audioFile ? audioFile.name : '');
    if (!audioFile) onChange({ mediumId: '' });
  }, [audioFile]);

  return (
    <MediaFile>
      <InputMediaManager
        value={audioFileName}
        readOnly
        style={{ cursor: 'pointer' }}
        onClick={onTriggerShowMediaManager}
        placeholder="Nie wybrano żadnego dźwięku"
        hoverBorderColor="var(--brand-blue)"
      />
      {audioFile && <MediaPlayButton audioFile={audioFile} />}
      <AttachmentIcon />
    </MediaFile>
  );
};

const VolumeSelectorRoot = styled('div')({
  maxWidth: 180,
  minWidth: 180,
  margin: '0 auto 0 0',
  paddingLeft: 5,
});

const VolumeSelector = () => {
  const { config, handleChange, isUpdating } = useContext(ThresholdContext);
  const [innerVolume, setInnerVolume] = useState(config.volume * 100);

  useEffect(() => {
    setInnerVolume(Math.round(config.volume * 100));
  }, [config.volume]);

  const handleAfterChange = value => {
    handleChange('volume', value / 100);
  };

  return (
    <VolumeSelectorRoot>
      <RangeSlider
        position="horizontal"
        value={innerVolume}
        min={0}
        max={100}
        onChange={value => setInnerVolume(value)}
        onAfterChange={handleAfterChange}
        disabled={isUpdating}
      />
    </VolumeSelectorRoot>
  );
};

const AudioThreshold = () => {
  const { isDefault } = useContext(ThresholdContext);
  const hoverColor = 'var(--brand-blue)';

  return (
    <div>
      <InputWrapper>
        <div style={{ flex: 1 }}>
          <SoundSelector />
        </div>

        {!isDefault && <DeleteButton />}
      </InputWrapper>

      <Options between>
        <VolumeSelector />

        {!isDefault && (
          <AmountMin>
            <span>odtwarzany od kwoty:</span>
            <AmountSelector hoverColor={hoverColor} />
          </AmountMin>
        )}
      </Options>
    </div>
  );
};

export default AudioThreshold;
