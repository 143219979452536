import { connect } from 'react-redux';

import { updateConfig } from 'store/actions/configuratorsConfigs';
import { fetchMediaIfNeeded } from 'store/actions/mediaManager';

import DisplaySettings from './DisplaySettings';

const mapStateToProps = state => {
  const { items: media } = state.mediaManager;
  const config = state.configuratorsConfigs.configurations.TIP_ALERT.displaySettings;
  const showConfigModal = state.configuratorsConfigs.showModal;

  return {
    config,
    media,
    showConfigModal,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchUpdateConfig: config => {
    dispatch(updateConfig('TIP_ALERT', config));
  },
  fetchMediaIfNeeded: config => {
    dispatch(fetchMediaIfNeeded());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisplaySettings);
