import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import countriesData from 'assets/constants/countries.json';

import Select from 'components/Select';

const SelectInput = styled(Select)`
  margin-bottom: 17px;

  .react-select {
    &__control {
      font-size: 15px;
      border-color: var(--border-color);
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 30px;
      background-color: var(--bg-color-text-input);
    }

    &__value-container {
      transform: translate(-15px, 0px);
    }

    &__placeholder {
      color: rgb(109, 109, 109);
    }

    &__option {
      &--is-selected {
        &::before {
          display: none;
        }
      }
    }
  }

  .react-select--is-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }
`;

const CountryFlagImage = styled.img.attrs(() => ({
  width: 25,
}))`
  margin-right: 10px;
  transform: translateY(4px);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const flagPropTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  biggerMargin: PropTypes.bool,
};

const flagDefaultProps = {
  biggerMargin: false,
  label: '',
  value: '',
};

const CountryFlag = ({ label, value, biggerMargin = false }) =>
  value ? (
    <CountryFlagImage
      src={`/flags/4x3/${value.toLowerCase()}.svg`}
      alt={label}
      style={{
        marginRight: biggerMargin ? 20 : 20,
      }}
    />
  ) : null;

CountryFlag.propTypes = flagPropTypes;
CountryFlag.defaultProps = flagDefaultProps;

const PlaceholderWrapper = styled.div.attrs(() => ({
  className: 'country-option-menu',
}))`
  transform: translate(0px, -3px);
`;

const Placeholder = ({ label, value, biggerMargin }) => (
  <PlaceholderWrapper>
    <CountryFlag label={label} value={value} biggerMargin={biggerMargin} />
    <span>{label}</span>
  </PlaceholderWrapper>
);

Placeholder.propTypes = flagPropTypes;
Placeholder.defaultProps = flagDefaultProps;

const CountrySelect = ({ menuIsOpen, onMenuOpen, onMenuClose, onChange, value, readOnly }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    const options = countriesData.map(({ label, value: v }) => ({ label, value: v }));
    setSelectOptions(options);
  }, []);

  useEffect(() => {
    setSelected(selectOptions.find(obj => obj.value === value));
  }, [value, selectOptions]);

  return (
    <SelectInput
      isDisabled={readOnly}
      maxMenuHeight={150}
      menuIsOpen={menuIsOpen}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      closeMenuOnSelect={false}
      classNamePrefix="react-select"
      name="country"
      options={countriesData}
      value={selected}
      placeholder={<Placeholder {...selected} />}
      formatOptionLabel={option => <Placeholder biggerMargin {...option} />}
      onChange={newValue => onChange(newValue.value)}
    />
  );
};

CountrySelect.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  onMenuOpen: PropTypes.instanceOf(Function).isRequired,
  onMenuClose: PropTypes.instanceOf(Function).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

CountrySelect.defaultProps = {
  value: 'PL',
};

export default CountrySelect;
