import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';

import Button from 'styles/Button';

const svgShadowFilter = css`
  filter: drop-shadow(0 0 6px black);
`;

const ButtonWrapper = styled(Button)`
  padding: 0 20px 0 24px;
  height: 40px;
  white-space: nowrap;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  font-family: var(--google-sora-cdn);
  font-size: 12.5px;
  margin: 2px;
  background-color: ${props => (props.isActive ? props.activeColor : props.color)};
  text-shadow: ${props => (props.withTextShadow ? '0 0 8px black' : 'none')};

  &:hover {
    background-color: ${props => (props.isActive ? props.activeColor : props.color)};
    box-shadow: 0 0 26px 0 ${props => (props.isActive ? props.activeColor : props.color)};
  }

  > svg {
    min-width: 14px;
    height: 14px;
    ${props => props.withTextShadow && svgShadowFilter}
  }
`;

const ArrowButton = React.forwardRef(
  ({ label, color, activeColor, withTextShadow, onClick, disabled }, ref) => (
    <ButtonWrapper
      color={color}
      activeColor={activeColor}
      ref={ref}
      onClick={onClick}
      withTextShadow={withTextShadow}
      disabled={disabled}
    >
      {`${label} `}
      <ArrowRight />
    </ButtonWrapper>
  ),
);

ArrowButton.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  withTextShadow: PropTypes.bool,
  onClick: PropTypes.instanceOf(Function),
  disabled: PropTypes.bool,
};

ArrowButton.defaultProps = {
  color: 'var(--yellow-secondary)',
  activeColor: 'var(--brand-blue)',
  withTextShadow: false,
  onClick: () => null,
  disabled: false,
};

export default ArrowButton;
