import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import priceFormatter from 'utils/priceFormatter';
import { NotificationPropTypes } from '../types';
import Paragraph from './Paragraph';

const WithdrawalRejectedNotification = ({ notification, methodName }) => (
  <>
    <Paragraph>{notification.payload.reason[0]}</Paragraph>
    <Paragraph>
      {methodName}
      <br />
      {`z dnia: ${fecha.format(new Date(notification.payload.requested_at), 'DD/MM/YY HH:mm')}`}
      <br />
      <br />
      {`kwota: ${priceFormatter(notification.payload.amount - notification.payload.commission)}`}
    </Paragraph>
  </>
);

export default WithdrawalRejectedNotification;

WithdrawalRejectedNotification.propTypes = {
  ...NotificationPropTypes,
  methodName: PropTypes.string.isRequired,
};
