import styled, { css } from 'styled-components';

import AlertBox from './AlertBox';

const visible = css`
  opacity: 1;
  pointer-events: auto;

  ${AlertBox} {
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;

  z-index: 1000;

  &[hidden] {
    display: none;
  }

  ${props => props.isVisible && visible}
`;

export default Wrapper;
