import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import Text from '../../Text';

const ProfileVerifiedNotification = ({ notification }) => (
  <>
    <Text size={21} margin="0 0 40px">
      Twoje dane osobowe wyglądają naprawdę dobrze! Możesz teraz wypłacać kase!
    </Text>

    <Text marign="0 0 50px">
      Zaakceptowano:
      <br />
      {fecha.format(new Date(notification.payload.at), 'DD/MM/YY HH:mm')}
    </Text>
  </>
);

export default ProfileVerifiedNotification;

ProfileVerifiedNotification.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
};
