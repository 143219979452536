import styled from 'styled-components';

const Subtitle = styled.div`
  font-size: 12.5px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 0 29px var(--orange-red-2);
  color: var(--orange-red-2);
  margin-bottom: 20px;
  margin-top: 30px;
`;

export default Subtitle;
