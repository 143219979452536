import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import { ReactComponent as TrashBinIcon } from 'assets/icons/redbin.svg';

const DeleteIconButton = ({ onClick, ...props }) => (
  <IconButton $variant="outlined" $color="red" onClick={onClick} {...props}>
    <TrashBinIcon />
  </IconButton>
);

DeleteIconButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default DeleteIconButton;
