import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';
import styled from 'styled-components';

import Wrapper from './styles/Wrapper';
import Content from './styles/Content';
import Close from './styles/Close';
import Title from './styles/Title';
import Description from './styles/Description';
import Overlay from './styles/Overlay';

const UpdatingIndicator = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  opacity: 0.3;
  top: 12px;
  left: 14px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Modal = ({
  title,
  titleSlot,
  description,
  width,
  height,
  onClose,
  children,
  unmountDelay,
  background,
  withCloseButton,
  withSpinner,
}) =>
  ReactDOM.createPortal(
    <Wrapper unmountDelay={unmountDelay}>
      <Overlay onClick={onClose} />
      <Content background={background} style={{ width, height }}>
        <div>
          {withSpinner && (
            <UpdatingIndicator>
              <MaterialSpinner />
            </UpdatingIndicator>
          )}

          {withCloseButton && (
            <Close type="button" onClick={onClose}>
              <CloseIcon />
            </Close>
          )}

          <Title>
            {title}
            {titleSlot}
          </Title>
          {description && <Description>{description}</Description>}
        </div>

        <div>{children}</div>
      </Content>
    </Wrapper>,
    document.body,
  );

Modal.propTypes = {
  unmountDelay: PropTypes.number.isRequired,
  title: PropTypes.string,
  titleSlot: PropTypes.node,
  onClose: PropTypes.instanceOf(Function),
  description: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
  withCloseButton: PropTypes.bool,
  withSpinner: PropTypes.bool,
};

Modal.defaultProps = {
  titlePortal: <></>,
  onClose: () => {},
  description: '',
  width: 510,
  height: null,
  background: 'white',
  title: '',
  withCloseButton: true,
  withSpinner: false,
};

export default Modal;
