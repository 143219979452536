import styled, { css } from 'styled-components';

const hasBackground = css`
  color: #fff;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
`;

export default styled.div`
  background: var(--bg-color-media-asset);

  border-radius: 10px;
  width: calc(50% - 20px);
  margin: 10px 10px 5px 10px;
  height: 185px;
  display: inline-block;
  font-size: 13px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  div.item_inside {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 16px;

    ${props => props.hasBackground && hasBackground}
    > div {
      font-size: 11px;
    }

    > button,
    > svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      margin-bottom: 10px;
      vertical-align: bottom;
    }

    > svg,
    > button svg {
      fill: var(--text-color);

      &:hover {
        fill: var(--blue);
      }
    }
  }

  p:first-child {
    font-size: 11px;
    margin-bottom: 5px;
  }

  p:nth-child(2) {
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
