import React from 'react';
import PropTypes from 'prop-types';
import { convertToGrosze, convertToZlotys } from 'utils/priceConverter';
import NumberInput from './TopDonators/styles/NumberInput';

const PriceInput = ({ defaultValue, maxValue, onChange }) => (
  <NumberInput
    decimalSeparator=","
    defaultValue={convertToZlotys(defaultValue)}
    decimalScale={2}
    allowEmptyFormatting
    allowNegative={false}
    suffix=" PLN"
    isAllowed={({ floatValue }) => floatValue >= 0 && floatValue < maxValue}
    onValueChange={({ floatValue }) => {
      onChange(convertToGrosze(floatValue));
    }}
  />
);

PriceInput.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

PriceInput.defaultProps = {
  maxValue: 100,
};

export default PriceInput;
