import styled, { css } from 'styled-components';

const optionActive = css`
  transform: translateX(50%);
  opacity: 0;
  transition-delay: 0s;
`;

export default styled.div`
  display: flex;
  align-items: center;
  jusitfy-content: center;

  height: 25px;

  padding: 0 10px;
  color: #454545;
  transition: color 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  transition: all 0.2s ease-out;
  transition-delay: ${props => props.delayOut}s;
  ${props => props.isActive && optionActive};
`;
