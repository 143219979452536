import http from 'utils/http';

const requestWithdrawalMethodsConfiguration = () => ({
  type: 'REQUEST_WITHDRAWAL_METHODS_CONFIGURATION',
});

const receiveWithdrawalMethodsConfiguration = configuration => ({
  type: 'RECEIVE_WITHDRAWAL_METHODS_CONFIGURATION',
  receivedData: configuration,
});

const failureWithdrawalMethodsConfiguration = () => ({
  type: 'FAILURE_WITHDRAWAL_METHODS_CONFIGURATION',
});

export const fetchWithdrawalMethodsConfiguration = () => async dispatch => {
  dispatch(requestWithdrawalMethodsConfiguration());

  try {
    const config = await http.get('/withdrawal-methods-configuration');

    dispatch(receiveWithdrawalMethodsConfiguration(config.data));
  } catch (error) {
    failureWithdrawalMethodsConfiguration();
  }
};
