import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as TrashBinIcon } from 'assets/icons/trashbin.svg';
import { ReactComponent as CopyPasteIcon } from 'assets/icons/copy-paste.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pencil.svg';
import { ReactComponent as CodeIcon } from 'assets/icons/code.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';

import {
  setDisplaySettingsDialogCurrentView,
  setDisplaySettingsDialogVisibility,
} from 'store/actions/configuratorsConfigs';

import TitledSeparator from 'components/TitledSeparator';
import IconButton, { Button } from 'components/IconButton';
import BoxLinkButton from 'components/BoxLinkButton';
import Hideable from 'components/Hideable';
import Context from './Context';
import ConfigContext from '../../ConfigContext';
import useViewTemplates from '../../hooks/useViewTemplates';
import CodeModal from '../CustomCode/components/CodeModal';
import PanelButton from '../PanelButton';
import DeleteIconButton from '../DeleteIconButton';

import ActionGroup from './styles/ActionGroup';
import CustomTemplates from './styles/CustomTemplates';
import DefaultTemplates from './styles/DefaultTemplates';
import Indicator from './styles/Indicator';
import IndicatorContainer from './styles/IndicatorContainer';
import Separator from './styles/Separator';
import ListSort from './components/ListSort';
import List from './components/List';
import SlideOutMenu from './components/SlideOutMenu';
import NoTemplates from './components/NoTemplates';

const Root = styled.div`
  position: relative;
  border-radius: 15px;
`;

const ButtonText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: '...';
  display: block;
`;

const TopInfo = styled.div`
  display: flex;
  column-gap: 14px;
`;

const TopInfoItem = styled.span`
  display: flex;
  column-gap: 15px;
  color: var(--text-grey);
  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
`;

const TextButton = styled.button`
  background-color: transparent;
  box-shadow: none;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: inline;
  color: inherit;
  border: none;
  text-transform: uppercase;
`;

const MustBeEditableMsg = 'Musisz wybrać własny szablon by tego użyć';

const TemplateManager = () => {
  const dispatch = useDispatch();

  const {
    configuratorType,
    configuratorConfig,
    latestTemplateConfig,
    pushHistoryEntry,
  } = useContext(ConfigContext);

  const {
    defaultTemplates,
    createdTemplates,
    activeTemplate,
    recentTemplate,
    getTemplateById,
    setActiveTemplateId,
    createTemplate,
    updateTemplate,
    deleteTemplate,
  } = useViewTemplates(configuratorType);

  /**
   * @return {string[]}
   */
  function getUsedTemplateIdList() {
    const { defaults, tresholds: thresholds } = configuratorConfig.displaySettings;
    const rest = thresholds.templates.map(threshold => threshold.templateId);

    return [defaults.templates.templateId, ...rest];
  }

  const [recentTemplateCount, setRecentTemplateCount] = useState(createdTemplates.length);
  const [editMode, setEditMode] = useState(false);
  const [switchToEditMore, setSwitchToEditMode] = useState(false);
  const [isEditable, setIsEditable] = useState(activeTemplate.config.editable);
  const [openCodeDialog, setOpenCodeDialog] = useState(false);
  const [usedTemplateIds, setUsedTemplateIds] = useState(getUsedTemplateIdList());
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);

  const rootRef = useRef();

  useEffect(() => {
    if (switchToEditMore) {
      setEditMode(true);
      setSwitchToEditMode(false);
    }

    setIsEditable(activeTemplate.config.editable);
  }, [activeTemplate]);

  useEffect(() => {
    if (createdTemplates.length !== recentTemplateCount) {
      setActiveTemplateId(recentTemplate.id);
      setRecentTemplateCount(createdTemplates.length);
    }
  }, [createdTemplates, recentTemplateCount, recentTemplate]);

  useEffect(() => {
    setUsedTemplateIds(getUsedTemplateIdList());
  }, [configuratorConfig]);

  const createNewTemplate = (config = {}) => {
    setSwitchToEditMode(true);
    createTemplate({ ...config });
  };

  const handleMainButtonClick = () => {
    if (!createdTemplates.length && !menuIsExpanded) {
      createNewTemplateEntry();
    }
    if (menuIsExpanded) setMenuIsExpanded(menuIsExpanded);
    setMenuIsExpanded(!menuIsExpanded);
  };

  const createNewTemplateEntry = () => {
    createNewTemplate({
      ...latestTemplateConfig,
      title: `Nowy szablon #${createdTemplates.length + 1}`,
    });
  };

  const handleAddButtonClick = () => {
    createNewTemplateEntry();
  };

  const handleDuplicateTemplate = targetId => {
    const targetTemplate = getTemplateById(targetId);

    createNewTemplate({
      ...targetTemplate.config,
      title: `${targetTemplate.config.title} (duplikat)`,
    });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const changeActiveTemplate = templateId => {
    setEditMode(false);
    setActiveTemplateId(templateId);
  };

  const handleDeleteTemplate = async id => {
    setEditMode(false);
    deleteTemplate(activeTemplate.id);
  };

  const handleModalLinkClick = () => {
    dispatch(setDisplaySettingsDialogCurrentView('templates'));
    dispatch(setDisplaySettingsDialogVisibility(true));
  };

  useEffect(() => {
    const handleOutsideClick = event => {
      if (event.target.contains(rootRef.current)) {
        setMenuIsExpanded(false);
      }
    };

    if (menuIsExpanded) {
      document.addEventListener('click', handleOutsideClick, false);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [rootRef, menuIsExpanded]);

  return (
    <Context.Provider
      value={{
        activeTemplate,
        changeActiveTemplate,
        updateTemplate,
        handleDuplicateTemplate,
        editMode,
        setEditMode,
        usedTemplateIds,
      }}
    >
      <Root ref={rootRef}>
        <PanelButton
          title={
            !!createdTemplates.length || menuIsExpanded ? 'Aktualny szablon, który edytujesz:' : ''
          }
          endIcon={
            (!!createdTemplates.length || menuIsExpanded) && (
              <ChevronDown
                style={{
                  transform: `rotate(${menuIsExpanded ? 180 : 0}deg)`,
                  width: 14,
                  height: 14,
                }}
              />
            )
          }
          onClick={handleMainButtonClick}
          color="blue"
          fullWidth
          style={{
            border: `1px solid #0051A6`,
            columnGap: 24,
            rowGap: 2,
            zIndex: 3,
            paddingTop: 15,
            paddingRight: 30,
            paddingBottom: 13,
            paddingLeft: 27,
          }}
        >
          {((!!createdTemplates.length || menuIsExpanded) && (
            <ButtonText>{activeTemplate.config.title}</ButtonText>
          )) || (
            <span
              style={{
                textAlign: 'center',
                display: 'block',
                width: '100%',
                textTransform: 'uppercase',
              }}
            >
              <ButtonText style={{ fontSize: 14, fontWeight: 800 }}>Stwórz Pierwszy</ButtonText>
              <span style={{ fontSize: 12, fontWeight: 500 }}>własny wygląd donejta</span>
            </span>
          )}
        </PanelButton>

        <SlideOutMenu
          isExpanded={menuIsExpanded}
          style={{ zIndex: 2 }}
          autoHeight={!createdTemplates.length}
        >
          <TopInfo style={{ marginBottom: 12 }}>
            <TopInfoItem>
              <IndicatorContainer style={{ color: `var(--text-grey)` }}>
                <Indicator />
              </IndicatorContainer>
              Edytujesz
            </TopInfoItem>

            <TopInfoItem>
              <IndicatorContainer style={{ color: `var(--text-grey)` }}>
                <Indicator style={{ backgroundColor: `var(--text-grey)` }} />
              </IndicatorContainer>
              <TextButton onClick={handleModalLinkClick}>Używany w OBS</TextButton>
            </TopInfoItem>
          </TopInfo>

          <DefaultTemplates style={{ marginBottom: 9 }}>
            <List items={defaultTemplates} />
          </DefaultTemplates>

          <TitledSeparator
            title="Twoje szablony"
            style={{ padding: 0, marginTop: 17, marginBottom: 19 }}
          />

          <CustomTemplates>
            {(!!createdTemplates.length && <ListSort controls items={createdTemplates} />) || (
              <NoTemplates />
            )}
          </CustomTemplates>

          <Separator style={{ marginTop: 19, marginBottom: 22 }} />

          <ActionGroup style={{ marginBottom: 19 }}>
            <IconButton
              onClick={handleEditClick}
              $variant="outlined"
              $size="large"
              disabled={!isEditable}
              tooltip="Zmień nazwę szablonu"
              tooltipDisabled={MustBeEditableMsg}
              style={{ color: '#adadad' }}
            >
              <EditIcon />
            </IconButton>

            <Button
              $size="large"
              $variant="contained"
              $color="black"
              onClick={handleAddButtonClick}
            >
              <AddIcon />
              Nowy szablon
            </Button>

            <IconButton
              $variant="outlined"
              $size="large"
              $customColor="#7F8BF6"
              disabled={!isEditable}
              onClick={() => setOpenCodeDialog(true)}
              tooltip="Własny kod CSS, HTML i JavaScript"
              tooltipDisabled={MustBeEditableMsg}
            >
              <CodeIcon />
            </IconButton>
            <CodeModal
              template={{ type: configuratorType, config: latestTemplateConfig }}
              onChange={pushHistoryEntry}
              onClose={() => setOpenCodeDialog(false)}
              isMounted={openCodeDialog}
            />

            <DeleteIconButton
              $size="large"
              onClick={handleDeleteTemplate}
              disabled={!isEditable}
              tooltip="Usuń szablon"
              tooltipDisabled={MustBeEditableMsg}
            />
          </ActionGroup>

          <BoxLinkButton onClick={handleModalLinkClick} endIcon={<ArrowRight />}>
            Ustaw szablony zależnie od kwoty
          </BoxLinkButton>
        </SlideOutMenu>

        <Hideable delayAppear=".3s" style={{ zIndex: 1, opacity: menuIsExpanded ? 0 : 1 }}>
          <ActionGroup style={{ columnGap: 5 }}>
            <IconButton
              $variant="outlined"
              $size="large"
              disabled={!isEditable}
              tooltip="Duplikuj aktualny szablon"
              tooltipDisabled={MustBeEditableMsg}
              onClick={() => handleDuplicateTemplate(activeTemplate.id)}
              style={{ color: '#adadad' }}
            >
              <CopyPasteIcon style={{ width: 18, height: 18 }} />
            </IconButton>

            <Button
              $variant="contained"
              $size="large"
              $color="black"
              onClick={handleAddButtonClick}
              style={{ flexGrow: 1 }}
            >
              <AddIcon style={{ with: 13, height: 13, columnGap: 12 }} />
              Nowy szablon
            </Button>

            <Button
              $variant="outlined"
              $size="large"
              $color="red"
              onClick={handleDeleteTemplate}
              disabled={!isEditable}
              tooltipDisabled={MustBeEditableMsg}
            >
              <TrashBinIcon style={{ with: 12, height: 12, columnGap: 12 }} />
              Usuń szablon
            </Button>
          </ActionGroup>
        </Hideable>
      </Root>
    </Context.Provider>
  );
};

export default TemplateManager;
