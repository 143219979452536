import React from 'react';
import PropTypes from 'prop-types';

import gifSEImage from 'assets/images/StreamElementsTutorial/gif_SE_step_2.gif';
import { TutorialImage } from 'styles/TutorialImages';

import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step5 = ({ processStep, goPrev }) => (
  <StepContent provider="stream_elements" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={30}>
      Po poprawnym zarejestrowaniu konta, rekomendujemy pominięcie samouczka, wprowadzającego Cię do
      serwisu StreamElements
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifSEImage} />
  </StepContent>
);

export default Step5;

Step5.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
