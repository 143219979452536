import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as announcementsActions from 'store/actions/announcements';

const WithAnnouncementsStore = Component => props => <Component {...props} />;

const mapStateToProps = ({ announcements }) => ({
  announcementsList: announcements.items,
  announcementsFailurePatching: announcements.failurePatching,
  announcementsfailureFetching: announcements.failureFetching,
  announcementsArePatching: announcements.isPatching,
  announcementsAreFetching: announcements.isFetching,
});

const mapDispatchToProps = dispatch => bindActionCreators(announcementsActions, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  WithAnnouncementsStore,
);
