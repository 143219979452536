import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 700px;
  border: 1px solid #fff;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto 10px;
`;
