import React from 'react';
import Paragraph from '../Paragraph';

const Tip20Achievement = () => (
  <div>
    <Paragraph>
      Informujemy, że swoją aktywnością na koncie odblokowałeś/aś możliwość uzyskania znaku
      weryfikacji!
      <br />
      Odznaka może dać Ci pewne benefity! Sprawdź więcej informacji w zakładce dane osobowe.
    </Paragraph>
  </div>
);

export default Tip20Achievement;
