import React from 'react';
import PropTypes from 'prop-types';
import { RangeSlider } from 'components/RangeSlider';
import { Icon } from './Icon';

export const Range = ({ icon, ...props }) => (
  <>
    {icon && <Icon>{icon}</Icon>}
    <RangeSlider position="horizontal" alternate {...props} />
  </>
);

Range.propTypes = {
  icon: PropTypes.node,
};

Range.defaultProps = {
  icon: undefined,
};
