import styled from 'styled-components';

const BigStrokedNumber = styled.div`
  font-size: 46.45px;
  font-weight: bold;
  line-height: 1.2;
  color: var(--white-box-background);
  text-shadow: -1px -1px 0 var(--black), 1px -1px 0 var(--black), -1px 1px 0 var(--black),
    1px 1px 0 var(--black);
`;

export default BigStrokedNumber;
