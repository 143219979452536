import styled from 'styled-components';

import Button from 'components/Button';

export default styled(Button)`
  margin: 0 auto;
  display: block;
  padding: 10px 20px;
  background-color: red;
`;
