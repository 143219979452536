import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Submenu } from 'react-contexify';
import ConfigContext from '../../ConfigContext';

import animatesList from './animatesList';
import AnimationContextMenuItems from './AnimationContextMenuItems';

const AnimationsContextMenuList = props => {
  const { onAnimationSelect, label } = props;
  const { latestTemplateConfig, focusedElementName } = useContext(ConfigContext);
  const target = focusedElementName
    ? latestTemplateConfig.elementsOptions[focusedElementName]
    : latestTemplateConfig;

  return (
    <Submenu label={label}>
      {Object.keys(animatesList).map(directionName => {
        const direction = animatesList[directionName];
        const activeAnimation = target.animation[directionName];
        const labelName = `${direction.label}${activeAnimation ? ` (${activeAnimation})` : ''}`;

        const handleAnimationSelect = animationName =>
          onAnimationSelect({
            direction: directionName,
            animation: animationName,
          });

        return (
          <Submenu key={direction.label} label={labelName}>
            <AnimationContextMenuItems
              animationTypes={direction.list}
              activeAnimation={activeAnimation}
              selectAnimationHandler={handleAnimationSelect}
            />
          </Submenu>
        );
      })}
    </Submenu>
  );
};

AnimationsContextMenuList.propTypes = {
  onAnimationSelect: PropTypes.instanceOf(Function).isRequired,
  label: PropTypes.string.isRequired,
};

export default AnimationsContextMenuList;
