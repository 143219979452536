import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .react-contexify {
    z-index: 150;
  }

  .app-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: var(--container-width);
  }

  .box {
    padding: 35px;
    background: white;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, .06);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    padding: 0;
    margin: 0;

    &.reset-list {
      list-style: none;
    }
  }

  h4 {
    font-size: 25px;
  }

  .text-light {
    color: var(--text-color-light-gray);
  }

  .text-white-black {
    color: var(--white-black);
  }

  .text-blue {
    color: var(--brand-blue);
  }

  .text-default {
    font-size: 18px;
  }

  .text-big {
    font-size: 16px;
  }

  .text-xlarge {
    font-size: 22px;
  }

  .text-small {
    font-size: 12px;
  }

  .text-medium {
    font-size: 16px;
  }

  .text-large {
    font-size: 20px;
  }

  .no-margin {
    margin: 0;
  }

  .list-block {
    display: block;
    margin: 10px 0px 10px 0px;
  }

  .text-bold {
    font-weight: 700;
  }

  .line-height-160 {
    line-height: 160%;
  }

  .open-sans {
    font-family: 'Open Sans', sans-serif;
  }

  .gilroy-extra {
    font-family: 'Gilroy Extra', sans-serif;
  }

  .google-sora {
    font-family: 'Google Sora', sans-serif;
  }

  .noto-sans {
    font-family: 'Noto Sans', sans-serif;
  }

  .hover-animation-green {
    &:hover, &:focus {
      animation: hoverAnimationGreen .4s ease;
    }
  }

  .hover-animation-blue {
    &:hover, &:focus {
      animation: hoverAnimationBlue .4s ease;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .font-weight-semibold {
    font-weight: 500;
  }

  .p-relative {
    position: relative;
  }

  .wrapped-text {
    overflow-wrap: break-word;
    word-break: break-all;
    hyphens: auto;

    &.clamped {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;
