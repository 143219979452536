import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 10;

  width: 100%;
  height: inherit;
`;

export default Content;
