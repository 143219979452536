import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { toggleVerifyWithBankWizard } from 'store/actions/userData';
import { bankTransferValidationDisabledSelector } from 'store/selector';

import WhiteBox from 'styles/WhiteBox';
import Flexbox from 'components/Flexbox';
import Text from 'components/Text';
import { Tooltip } from 'components/Tooltip';

import MinAmount from './MinAmount';
import { HoverTooltip } from './HoverTooltip';

const Wrapper = styled(WhiteBox)`
  padding: 25px;
`;

const RequireVerificationWarning = styled.div`
  font-family: var(--google-sora-cdn);
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  line-height: 1.3;
  color: var(--red);
  max-width: 160px;

  span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: var(--orange-red);
    }
  }

  &.with-tooltip {
    cursor: help;
  }
`;

const MethodName = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  position: relative;
  display: inline-block;
`;

const ItemContainer = ({
  children,
  paymentName,
  tooltip,
  onChange,
  methodState,
  currentMinAmount = 0,
  withMinAmount = true,
  isPpe,
  hoverText,
  disabledForNotVerified = false,
}) => {
  const { validated_with_bank_transfer: validatedWithTransfer } = useSelector(
    ({ userData }) => userData.info,
  );
  const dispatch = useDispatch();

  const bankTransferValidationDisabled = useSelector(bankTransferValidationDisabledSelector);

  const openVerifyWizard = () => {
    dispatch(toggleVerifyWithBankWizard(true));
  };

  const renderDefaultSettings = () => (
    <>
      {withMinAmount && (
        <MinAmount value={currentMinAmount} onChange={onChange} hoverText={hoverText} />
      )}
      <div>{children}</div>
    </>
  );

  if (methodState === 'hide') {
    return null;
  }

  return (
    <Wrapper>
      <Flexbox justify="space-between" align="center">
        <div style={{ maxWidth: 135 }}>
          <Text size={16} mb={5}>
            Metoda płatności:
          </Text>
          <MethodName color="blue" noMargin>
            {paymentName === 'paysafecard' ? `${paymentName} - bezpośrednio` : paymentName}
            {tooltip && (
              <HoverTooltip
                content={<Text margin="0" dangerouslySetInnerHTML={{ __html: tooltip }} />}
                placement="top"
                theme="light"
                trigger="mouseenter"
              />
            )}
          </MethodName>
        </div>
        {disabledForNotVerified && bankTransferValidationDisabled && (
          <Tooltip
            content="Musisz otrzymać conajmniej 20 wpłat aby aktywować tę metodę płatności"
            placement="top"
            theme="light"
            trigger="mouseenter"
          >
            <RequireVerificationWarning className="with-tooltip">
              Nie możesz aktywować
            </RequireVerificationWarning>
          </Tooltip>
        )}
        {disabledForNotVerified && !bankTransferValidationDisabled && !validatedWithTransfer && (
          <RequireVerificationWarning>
            <span aria-hidden onClick={openVerifyWizard}>
              Uzyskaj odznakę
            </span>
            <br />
            weryfikacji by
            <br />
            aktywować
          </RequireVerificationWarning>
        )}
        {disabledForNotVerified && validatedWithTransfer && renderDefaultSettings()}
        {!disabledForNotVerified && renderDefaultSettings()}
      </Flexbox>
      {isPpe && paymentName === 'PayPal' && (
        <Text size={15} weight="bold" color="green" margin="10px 0 0 0">
          Aktywuj wpisując email PayPal w danych osobowych
        </Text>
      )}
    </Wrapper>
  );
};

export default ItemContainer;

ItemContainer.propTypes = {
  children: PropTypes.node.isRequired,
  paymentName: PropTypes.string.isRequired,
  methodState: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  currentMinAmount: PropTypes.number,
  withMinAmount: PropTypes.bool,
  isPpe: PropTypes.bool.isRequired,
  hoverText: PropTypes.string.isRequired,
  disabledForNotVerified: PropTypes.bool,
};

ItemContainer.defaultProps = {
  tooltip: null,
  onChange: () => {},
  currentMinAmount: 0,
  withMinAmount: true,
  disabledForNotVerified: false,
};
