import styled, { keyframes } from 'styled-components';

import CustomCheckbox from './CustomCheckbox';
import Box from '../../../styles/Box';

const checkboxChecked = keyframes`
  50% {
      transform: scale(0.9);
  }
`;

export default styled.input.attrs(({ color }) => ({
  background: `var(--${color})`,
}))`
  display: none;

  &:checked {
    + ${CustomCheckbox} {
      ${Box} {
        background-color: ${props => props.background};
        border-color: ${props => props.background};
        animation: ${checkboxChecked} 0.4s ease;

        &::before {
          transform: scale(3.5);
          opacity: 0;
          transition: all 0.6s ease;
        }

        > svg {
          stroke-dashoffset: 0;
        }
      }
    }
  }
`;
