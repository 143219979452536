import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';
import { apiUrl } from 'utils/http';
import MediaManager from 'components/MediaManager';
import TitledSeparator from 'components/TitledSeparator';
import BoxLinkButton from 'components/BoxLinkButton';
import DefaultsBackgroundsContainer from './components/DefaultsBackgroundsContainer';
import DefaultBackground from './components/DefaultBackground';

const CheckScreenBackground = ({ activeBackground, defaultBackgrounds, changeBackground }) => {
  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);

  const handleMediaManagerClick = () => {
    setMediaManagerVisibility(true);
  };

  return (
    <div>
      <TitledSeparator title="Przykładowe tło konfiguratora" hint="(Niewidoczne w OBS)" />

      <DefaultsBackgroundsContainer>
        {defaultBackgrounds.map(({ id, background }) => (
          <DefaultBackground
            key={id}
            bgImageUrl={background}
            onClick={() => changeBackground(background)}
            active={activeBackground === background}
          />
        ))}
      </DefaultsBackgroundsContainer>

      <BoxLinkButton
        onClick={handleMediaManagerClick}
        endIcon={<ArrowRight style={{ height: 11 }} />}
      >
        Lub wybierz z biblioteki
      </BoxLinkButton>

      <MediaManager
        allowedTypes={['image']}
        onClose={() => setMediaManagerVisibility(false)}
        onSelectedItem={medium => {
          setMediaManagerVisibility(false);
          changeBackground(`${apiUrl}${medium.formats.user_banner.url}`);
        }}
        isMounted={mediaManagerVisibility}
      />
    </div>
  );
};

CheckScreenBackground.propTypes = {
  activeBackground: PropTypes.string.isRequired,
  defaultBackgrounds: PropTypes.instanceOf(Array).isRequired,
  changeBackground: PropTypes.instanceOf(Function).isRequired,
};

export default CheckScreenBackground;
