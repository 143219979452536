import styled from 'styled-components';

const Title = styled.div`
  color: var(--black);
  font-size: 33.33px;
  font-weight: bold;
  margin-bottom: 65px;
`;

export default Title;
