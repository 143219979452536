import React from 'react';

import { ButtonWithAnimatedArrow } from '../../Buttons';
import Logo from '../../MenuTop/components/Logo';
import { StepPropTypes } from '../types';
import StepWrapper from '../components/StepWrapper';

export const ForcedConfirmationStep = ({ processStep }) => (
  <StepWrapper>
    <div className="logo-container">
      <Logo noLink />
    </div>
    <p className="step-title">Dziękujemy!</p>
    <p>
      Daj nam proszę trochę czasu na weryfikację Twojego konta. Może to zająć od jednego, do siedmiu
      dni.
    </p>
    <p>
      {'Twoje konto zostało zablokowane dlatego prosimy napisz na e-mail: '}
      <a href="mailto:blokada@tipply.pl">blokada@tipply.pl</a>
      {' w celu wyjaśnienia działalności prowadzonej na swoim koncie Tipply. Weryfikacja ' +
        'tożsamości była konieczna, abyśmy w ogóle mogli rozpocząć proces wyjaśniania sprawy.'}
    </p>
    <p>Dziękujemy.</p>
    <div className="button-container">
      <ButtonWithAnimatedArrow onClick={processStep}>Zakończ i wyloguj</ButtonWithAnimatedArrow>
    </div>
  </StepWrapper>
);

ForcedConfirmationStep.propTypes = StepPropTypes;

export const NormalConfirmationStep = ({ processStep }) => (
  <StepWrapper>
    <div className="logo-container">
      <Logo noLink />
    </div>
    <p className="step-title">Dziękujemy!</p>
    <p>
      Dziękujemy bardzo. Cieszymy się, że chcesz dołączyć do paczki w pełni zweryfikowanych
      streamerów Tipply!
    </p>
    <p>
      Daj nam proszę trochę czasu na weryfikację Twojego konta. Może to zająć od jednego, do siedmiu
      dni. W międzyczasie dokonując weryfikacji dobrowolnie, możesz w pełni swobodnie korzystać ze
      swojego konta w Tipply.
    </p>
    <p>
      Do usłyszenia niebawem!
      <span role="img" aria-label="Smile">
        😎
      </span>
    </p>
    <div className="button-container">
      <ButtonWithAnimatedArrow onClick={processStep}>Zakończ weryfikację</ButtonWithAnimatedArrow>
    </div>
  </StepWrapper>
);

NormalConfirmationStep.propTypes = StepPropTypes;
