import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_3.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step6 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={50}>
      Po wpisaniu maila, klikamy bardzo widoczny duży przycisk „Active My Account” u góry strony,
      aby aktywować płatności Tipeee.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
  </StepContent>
);

export default Step6;

Step6.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
