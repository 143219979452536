import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Gradient from 'components/ColorPicker/components/Gradient';
import { ArrowInCircleButton } from 'components/Buttons';
import FloatingMenu from 'components/FloatingMenu';
import Button from 'components/Button';
import Link from 'components/Link';

import OptionsPopup from 'styles/OptionsPopup';

import noEditableAlert from '../lib/noEditableAlert';
import Box from '../styles/Box';

const PanelButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const GradientPickerBox = ({ label, isEditable, value, onChange }) => {
  const rootRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const { color, color2 } = value;

  const handleArrowClick = () => {
    if (isEditable) {
      setIsOpen(prevState => !prevState);
    } else {
      noEditableAlert();
    }
  };

  const handleConfirm = () => {
    onChange(localValue.color, localValue.color2);
    setIsOpen(false);
  };

  const handleRemoveGradient = () => {
    onChange(localValue.color, localValue.color);
    setIsOpen(false);
  };

  return (
    <div style={{ position: 'relative' }} ref={rootRef}>
      <Box style={{ paddingTop: 12.5, paddingBottom: 12.5 }}>
        <span>{label}</span>
        <ArrowInCircleButton background="green" onClick={handleArrowClick} />
      </Box>

      <FloatingMenu
        visible={isOpen}
        position="right"
        onClose={() => {
          setIsOpen(false);
          handleConfirm();
        }}
        parent={rootRef.current || document.body}
      >
        <OptionsPopup style={{ padding: 15 }}>
          {isOpen && (
            <Gradient
              pickerPosition="static"
              color={color}
              color2={color2}
              onChange={(newColor1, newColor2) => {
                setLocalValue({
                  color: newColor1,
                  color2: newColor2,
                });
              }}
            />
          )}
          <PanelButtonsWrapper>
            <Button background="green" onClick={handleConfirm}>
              Zatwierdź
            </Button>
            <Link color="var(--red)" onClick={handleRemoveGradient}>
              Usuń gradient
            </Link>
          </PanelButtonsWrapper>
        </OptionsPopup>
      </FloatingMenu>
    </div>
  );
};

GradientPickerBox.propTypes = {
  label: PropTypes.node.isRequired,
  isEditable: PropTypes.bool.isRequired,
  value: PropTypes.shape({
    color: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};
