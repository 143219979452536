import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components/Tooltip';

export const HoverTooltip = ({
  content,
  placement,
  theme,
  trigger,
  arrow,
  hoverText,
  ...props
}) => (
  <Tooltip content={content} placement={placement} theme={theme} trigger={trigger} arrow={arrow}>
    <div>{hoverText}</div>
  </Tooltip>
);

HoverTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.string,
  theme: PropTypes.string,
  trigger: PropTypes.string,
  arrow: PropTypes.bool,
  hoverText: PropTypes.string.isRequired,
};

HoverTooltip.defaultProps = {
  placement: 'top',
  theme: 'dark',
  trigger: 'mouseenter',
  arrow: true,
};

export default HoverTooltip;
