import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Text from 'components/Text';

const normalCss = css`
  background-image: linear-gradient(to bottom, #0f78e2, #05217a);
`;

const dangerGradient = css`
  background-image: linear-gradient(to bottom, #e10f0f, #770c0c);
`;

const dangerCss = css`
  ${dangerGradient};

  .wizard-progress {
    .wizard-progress__stats-wrapper {
      .bg {
        ${dangerGradient};
        opacity: 1;
      }

      .steps {
        color: #b50e0e;
      }
    }
  }
`;

const successGradient = css`
  background-image: linear-gradient(to bottom, #b7e10f, #387a05);
`;

const successCss = css`
  ${successGradient};

  .wizard-progress {
    .wizard-progress__stats-wrapper {
      .bg {
        ${successGradient};
        opacity: 1;
      }

      .steps {
        color: #85b90b;
      }
    }
  }
`;

const Wrapper = styled.div.attrs(props => {
  switch (props.type) {
    case 'danger':
      return dangerCss;
    case 'success':
      return successCss;
    default:
      return normalCss;
  }
})`
  display: flex;
  text-align: left;
  background: #eef4f8;
  border-radius: 20px;
  overflow: hidden;

  > div {
    padding: 63px 34px;
    flex: 1;

    &.left {
      min-width: 240px;
      max-width: 240px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 25px;
      ${({ type }) => type === 'normal' && normalCss};
      ${({ type }) => type === 'danger' && dangerCss};
      ${({ type }) => type === 'success' && successCss};
    }

    &.right {
      padding: ${props => (props.rightColumnPadding ? props.rightColumnPadding : '63px 34px')};
      background-color: var(--white);
    }
  }
`;

const TwoColumnsContent = ({ title, children, type, underTitle, compactRightColumn = false }) => {
  const rightColumnPadding = compactRightColumn ? '30px' : undefined;

  return (
    <Wrapper type={type} rightColumnPadding={rightColumnPadding}>
      <div className="left">
        <Text weight="bold" size={23} color="white-white" lineHeight="normal">
          {title}
        </Text>
        {underTitle && <>{underTitle}</>}
      </div>
      <div className="right">{children}</div>
    </Wrapper>
  );
};

export default TwoColumnsContent;

TwoColumnsContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['normal', 'danger', 'success']),
  underTitle: PropTypes.node,
  compactRightColumn: PropTypes.bool,
};

TwoColumnsContent.defaultProps = {
  type: 'normal',
  underTitle: null,
  compactRightColumn: false,
};
