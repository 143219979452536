import styled, { css } from 'styled-components';

import { ArrowInCircleButton } from 'components/Buttons';

const expandedStyles = css`
  transform: rotate(90deg);
`;

export default styled(ArrowInCircleButton)`
  position: absolute;
  top: calc(50% - 15px);
  right: 0;

  ${props => props.expanded && expandedStyles}

  &:hover {
    transform: scale(1.2) ${props => (props.expanded ? 'rotate(80deg)' : 'rotate(-10deg)')};
  }
`;
