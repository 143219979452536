import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ArrowButton from 'components/Modal/ArrowButton';
import ThresholdContext from './ThresholdContext';

import SpeechSynthesizerManager from '../SpeechSynthesizerManager';
import AmountSelector from './AmountSelector';
import DeleteButton from './DeleteButton';

import InputWrapper from './styles/InputWrapper';
import Options from './styles/Options';
import AmountMin from './styles/AmountMin';
import TextInputWithChevron from './styles/TextInputWithChevron';

const VoiceSelector = () => {
  function getVoiceTitle(voiceType, list) {
    return list.find(v => v.id === voiceType).title;
  }

  const availableVoices = useSelector(state => state.speechSynthesizerVoices.available);
  const { config, onOpenSynthConf } = useContext(ThresholdContext);
  const [voiceTitle, setVoiceTitle] = useState(getVoiceTitle(config.voiceType, availableVoices));

  useEffect(() => {
    setVoiceTitle(getVoiceTitle(config.voiceType, availableVoices));
  }, [availableVoices, config.voiceType]);

  return (
    <TextInputWithChevron
      readOnly
      value={voiceTitle}
      onClick={onOpenSynthConf}
      hoverBorderColor="var(--yellow)"
    />
  );
};

const TextToSpeechThreshold = () => {
  const { config, isDefault, onChange, onOpenSynthConf, openSynthConf } = useContext(
    ThresholdContext,
  );
  const selfRef = useRef();
  const hoverColor = 'var(--yellow)';

  return (
    <div ref={selfRef}>
      <InputWrapper>
        <div style={{ flex: 1 }}>
          <VoiceSelector />
        </div>

        {!isDefault && <DeleteButton />}
      </InputWrapper>

      <Options between>
        <div>
          <ArrowButton
            label="Konfiguracja głosu"
            onClick={onOpenSynthConf}
            isActive={openSynthConf}
          />
          <SpeechSynthesizerManager
            onChange={onChange}
            currentSettings={config}
            visible={openSynthConf}
            triggerRef={selfRef}
          />
        </div>

        {!isDefault && (
          <AmountMin>
            <span>głos działa od kwoty:</span>
            <AmountSelector hoverColor={hoverColor} />
          </AmountMin>
        )}
      </Options>
    </div>
  );
};

export default TextToSpeechThreshold;
