import styled from 'styled-components';

export default styled.span`
  background: var(--brand-blue);
  text-transform: uppercase;
  color: white;
  padding: 2px 14px;
  border-radius: 10px;
  font-size: 12px;
  margin: 0 10px;
`;
