import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { contextMenu } from 'react-contexify';

import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';
import BottomTip from '../../components/BottomTip';
import noEditableAlert from '../../lib/noEditableAlert';

import ChooseDate from '../../components/ChooseDate';
import CustomCode from '../../components/CustomCode';
import RangePopup from '../../components/RangePopup';
import Screen from '../../components/Screen';
import Panel from '../../components/Panel';
import FilterWords from '../../components/FilterWords';
import TemplatesManager from '../../components/TemplatesManager';
import WidgetUrl from '../../components/WidgetUrl';
import SendTestTip from '../../components/SendTestTip';
import ChangeScreenBackground from '../../components/ChangeScreenBackground';
import Switcher from '../../components/Switcher';
import ScreenContextMenu from '../../components/ScreenContextMenu';
import AnimationManager from '../../components/AnimationManager';
import { ActionCatcher } from '../../components/ActionCatcher';
import AlignPopup from '../../components/AlignPopup';
import Elements from './components/Elements';

import ConfigUnderScreen from '../../styles/ConfigUnderScreen';
import TopDonators from '../../components/TopDonators';
import Button from '../TipAlert/components/DisplaySettings/styles/Button';
import Wrapper from '../TipAlert/components/DisplaySettings/styles/Wrapper';

import { Provider } from './Context';

/**
 * Widok podległy pod widoku LargestDonates oraz LatestDonates
 *
 * @class
 */
export default class ListView extends Component {
  static propTypes = {
    activeTemplateId: PropTypes.string.isRequired,
    elements: PropTypes.instanceOf(Object).isRequired,
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    configuratorConfig: PropTypes.instanceOf(Object).isRequired,
    allTemplates: PropTypes.instanceOf(Array).isRequired,

    updateTemplate: PropTypes.instanceOf(Function).isRequired,
    configurationsAreFetched: PropTypes.bool.isRequired,

    getHiddenElements: PropTypes.instanceOf(Function).isRequired,
    showElement: PropTypes.instanceOf(Function).isRequired,

    pushHistory: PropTypes.instanceOf(Function).isRequired,
    createHistoryElement: PropTypes.instanceOf(Function).isRequired,
    getLatestHistoryElement: PropTypes.instanceOf(Function).isRequired,
    configuratorType: PropTypes.string.isRequired,
    widgetsUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const { activeTemplateId, configuratorConfig } = props;

    this.state = {
      activeTemplateId,
      configuratorConfig,
      enableAnimation: false,
      topDonatorsModalVisibile: false,
    };

    this.configuratorWrapper = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let returnedData = {
      configuratorConfig: props.configuratorConfig,
    };

    if (state.activeTemplateId !== props.activeTemplateId) {
      returnedData = {
        ...returnedData,
        activeTemplateId: props.activeTemplateId,
      };
    }

    return returnedData;
  }

  /**
   * Funkcja uruchamiająca menu kontekstowe dla całego widoku
   *
   * @param {Object} e
   * @returns {void}
   */
  handleShowScreenContextMenu = e => {
    e.preventDefault();
    contextMenu.show({
      id: 'screenContextMenu',
      event: e,
    });
  };

  disableAnimation = () => {
    this.setState({
      enableAnimation: false,
    });
  };

  render() {
    const { configuratorConfig, enableAnimation, topDonatorsModalVisibile } = this.state;
    const {
      elements,
      activeTemplate,
      configurationsAreFetched,
      updateTemplate,
      pushHistory,
      createHistoryElement,
      getLatestHistoryElement,
      allTemplates,
      getHiddenElements,
      showElement,
      configuratorType,
      widgetsUrl,
    } = this.props;

    if (configurationsAreFetched) {
      const hiddenElements = getHiddenElements();
      const history = getLatestHistoryElement();

      return (
        <Provider value={{ enableAnimation, updateTemplate }}>
          <>
            <Panel>
              <RangePopup
                text="Ilość wyświetlanych elementów"
                activeTemplateId={activeTemplate.id}
                onAfterChange={value => {
                  this.disableAnimation();
                  updateTemplate({ numberDisplayedItems: value });
                }}
                isEditable={activeTemplate.config.editable}
                value={activeTemplate.config.numberDisplayedItems}
                minValue={1}
                maxValue={100}
                whiteColorMinValue={3}
                whiteColorMaxValue={99}
              />

              <RangePopup
                text="Odległość pomiędzy elementami"
                activeTemplateId={activeTemplate.id}
                isEditable={activeTemplate.config.editable}
                onChange={value => {
                  this.disableAnimation();
                  updateTemplate({ spacingBetweenElements: value });
                }}
                value={activeTemplate.config.spacingBetweenElements}
                minValue={0}
                maxValue={200}
                whiteColorMinValue={5}
                whiteColorMaxValue={47}
              />

              <ChooseDate
                text="Wyświetlaj ranking od daty"
                onSelectedValue={value => {
                  updateTemplate({ showDonatesFromDate: value });
                }}
                activeTemplateId={activeTemplate.id}
                value={activeTemplate.config.showDonatesFromDate}
                isEditable={activeTemplate.config.editable}
              />

              {activeTemplate.config.displayDirection === 'vertical' && (
                <AlignPopup
                  onSelectedValue={value => {
                    pushHistory({ list: { align: value } });
                  }}
                  value={activeTemplate.config.elementsOptions.list.align}
                  isEditable={activeTemplate.config.editable}
                />
              )}

              <CustomCode template={activeTemplate} onChange={updateTemplate} />

              <FilterWords />
              <SendTestTip />
            </Panel>
            <div ref={this.configuratorWrapper}>
              <Screen>
                <ActionCatcher
                  activeTemplate={activeTemplate}
                  onContextMenu={event => {
                    this.handleShowScreenContextMenu(event);
                  }}
                />

                <div style={{ width: '100%', height: '100%' }}>
                  <Elements
                    configuratorType={configuratorType}
                    activeTemplate={activeTemplate}
                    configuratorConfig={configuratorConfig}
                    configuratorWrapper={this.configuratorWrapper}
                    pushHistory={historyObject => pushHistory(historyObject)}
                    createHistoryElement={() => createHistoryElement()}
                    getLatestHistoryElement={() => getLatestHistoryElement()}
                  />
                </div>

                <ScreenContextMenu
                  elements={elements}
                  hiddenElements={hiddenElements}
                  onShowElement={showElement}
                />
              </Screen>
              <ConfigUnderScreen position="start">
                <AnimationManager
                  isAnimated={enableAnimation}
                  onClickControl={value => this.setState({ enableAnimation: value })}
                  autoplayPanel={false}
                  disabled={!activeTemplate.config.animation.enable}
                />
                <WidgetUrl configuratorType={widgetsUrl} templates={allTemplates} />
                <Wrapper onClick={() => this.setState({ topDonatorsModalVisibile: true })}>
                  <span>Edytuj swój ranking wpłat</span>
                  <Button type="button">
                    <ArrowDown />
                  </Button>
                </Wrapper>
                <TopDonators
                  isMounted={topDonatorsModalVisibile}
                  onClose={() => this.setState({ topDonatorsModalVisibile: false })}
                />
              </ConfigUnderScreen>
              <BottomTip />
            </div>
            <Panel>
              <TemplatesManager type={configuratorType} />
              <ChangeScreenBackground />
              <Switcher
                text="Wyświetl numerację"
                name="list-numbering"
                value={history.elementsOptions.list.children.counter.isVisible}
                onChange={checkboxValue => {
                  if (activeTemplate.config.editable) {
                    this.disableAnimation();
                    pushHistory({
                      list: { children: { counter: { isVisible: checkboxValue } } },
                    });
                  } else {
                    noEditableAlert();
                  }
                }}
              />
              <Switcher
                text="Wyświetlanie w poziomie"
                name="display-direction"
                value={activeTemplate.config.displayDirection === 'horizontal'}
                onChange={checkboxValue => {
                  if (activeTemplate.config.editable) {
                    this.disableAnimation();
                    updateTemplate({ displayDirection: checkboxValue ? 'horizontal' : 'vertical' });
                  } else {
                    noEditableAlert();
                  }
                }}
              />
              <Switcher
                text="Kwota bez opłaty serwisowej"
                name="amount-without-commission"
                value={activeTemplate.config.amountWithoutCommission}
                onChange={checkboxValue => {
                  if (activeTemplate.config.editable) {
                    updateTemplate({ amountWithoutCommission: checkboxValue });
                  } else {
                    noEditableAlert();
                  }
                }}
              />
            </Panel>
          </>
        </Provider>
      );
    }

    return null;
  }
}
