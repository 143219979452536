import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  z-index: 5;
  width: 45px;
  height: 45px;
  background-color: #0078ff;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;
`;

export const Confirm = props => <Button {...props}>OK</Button>;
