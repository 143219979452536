import styled from 'styled-components';

export default styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #21262b;
  padding: 5px 10px;
  color: white;
  text-align: center;
  font-size: 16px;

  @media (min-width: 900px) {
    span {
      margin-right: 15px;
    }
  }

  @media (max-width: 899px) {
    padding: 10px;

    span {
      display: block;
    }

    > button {
      margin-top: 15px;
    }
  }
`;
