import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import { isObject } from 'utils/validators';
import FocusedElement from '../FocusedElement';
import { Provider } from './Context';
import DraggableContainer from './styles/DraggableContainer';

const DragWrapper = props => {
  const {
    elementName,
    elementOptions,
    isResizable,
    isFocused,
    updateElement: onUpdate,
    resizeAxis,
    resizeMinConstraints,
    children,
  } = props;

  function getElementPosition() {
    const position = { ...elementOptions.position };

    const elementOffsets = {
      from: 0,
      separator: 170,
      to: 200,
      percent: 350,
    };

    if (Object.keys(elementOffsets).includes(elementName) && !isDragged) {
      if (elementOptions.children[elementName].position) {
        position.x = elementOptions.children[elementName].position.x;
        position.y = elementOptions.children[elementName].position.y;
      } else {
        position.x += elementOffsets[elementName];
      }
    }

    return position;
  }

  function getSize() {
    return isResizable && isObject(elementOptions.size) ? elementOptions.size : {};
  }

  const [position, setPosition] = useState(getElementPosition());
  const [size, setSize] = useState(getSize());
  const [axisX, setAxisX] = useState(true);
  const [axisY, setAxisY] = useState(true);

  const [isDragged, setIsDragged] = useState(false);
  const [isResized, setIsResized] = useState(false);

  useEffect(() => {
    setPosition({ ...elementOptions.position });
    setSize({ ...elementOptions.size });
  }, [elementOptions]);

  const updatePosition = () => {
    const { x, y } = position;

    if (['to', 'from', 'percent', 'separator'].includes(elementName)) {
      onUpdate({ mode: 'childrenPosition', elementName, currentPosition: { x, y } });
    } else {
      onUpdate({ mode: 'position', elementName, currentPosition: { x, y } });
    }
  };

  /**
   * Funkcja obliczająca "przyklejania" się do środka osi X oraz Y
   *
   * @param {Object} position
   * @returns {void}
   */
  const updatePositionData = ({ x, y, node }) => {
    const snapSize = 12;
    const nodeMiddleX = node.clientWidth / 2;
    const nodeMiddleY = node.clientHeight / 2;
    const parentMiddleX = node.offsetParent.clientWidth / 2;
    const parentMiddleY = node.offsetParent.clientHeight / 2;
    const middleX = x + nodeMiddleX;
    const middleY = y + nodeMiddleY;

    const isBetweenX = parentMiddleX - snapSize < middleX && middleX < parentMiddleX + snapSize;
    const isBetweenY = parentMiddleY - snapSize < middleY && middleY < parentMiddleY + snapSize;
    const newPos = {
      x: !axisY ? parentMiddleX - nodeMiddleX : x,
      y: !axisX ? parentMiddleY - nodeMiddleY : y,
    };

    setPosition(newPos);
    setAxisY(!isBetweenX);
    setAxisX(!isBetweenY);
  };

  const updateSize = updatedSize => {
    const newSize = {};
    for (const key in size) {
      if (Object.prototype.hasOwnProperty.call(size, key) && updatedSize[key] !== null) {
        newSize[key] = updatedSize[key];
      }
    }

    setSize(newSize);
  };

  const handleDrag = (e, dragPosition) => {
    if (!isResized) updatePositionData(dragPosition);
  };

  const handleDragStop = () => {
    setIsDragged(false);
    updatePosition();
  };

  const handleMouseDown = () => setIsDragged(true);

  const handleResizeState = resized => {
    setIsResized(resized);
    if (!resized) onUpdate({ [elementName]: { size } });
  };

  const handleResizeStart = () => {
    setIsResized(true);
  };

  const handleResizeStop = () => {
    setIsResized(false);
    onUpdate({ [elementName]: { size } });
  };

  /**
   * Funkcja oplatająca wszystkie dzieci w komponent do zmiany wielkości elementu
   */
  function renderAsResizable() {
    return (
      <FocusedElement bigFocus>
        <ResizableBox
          width={size.width || 0}
          height={size.height || Infinity}
          minConstraints={resizeMinConstraints}
          axis={resizeAxis}
          onResizeStart={handleResizeStart}
          onResizeStop={handleResizeStop}
          onResize={(evt, { size: updatedSize }) => updateSize(updatedSize)}
        >
          {children}
        </ResizableBox>
      </FocusedElement>
    );
  }

  let draggableStyles = { zIndex: elementOptions.styles ? elementOptions.styles.zIndex : {} };
  if (size) {
    draggableStyles = { ...draggableStyles, ...size };
  }

  return (
    <Draggable
      onDrag={handleDrag}
      onStop={handleDragStop}
      onMouseDown={handleMouseDown}
      position={position}
      axis="none"
    >
      <DraggableContainer
        {...props}
        isFocused={isFocused || isDragged || isResized}
        bigFocus={isResizable}
        style={{ ...draggableStyles }}
        tabIndex="0"
      >
        <Provider value={{ size, handleResizeState, updateSize }}>
          {isResizable ? renderAsResizable() : children}
        </Provider>
      </DraggableContainer>
    </Draggable>
  );
};

DragWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  elementName: PropTypes.string.isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  elementOptions: PropTypes.instanceOf(Object).isRequired,
  isFocused: PropTypes.bool,

  isResizable: PropTypes.bool,
  resizeAxis: PropTypes.string,
  resizeMinConstraints: PropTypes.instanceOf(Array),
};

DragWrapper.defaultProps = {
  isFocused: false,
  isResizable: false,
  resizeAxis: 'both',
  resizeMinConstraints: [null, null],
};

export default DragWrapper;
