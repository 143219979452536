import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FloatingMenu from 'components/FloatingMenu';
import { ArrowInCircleButton } from 'components/Buttons';

import Box from '../../styles/Box';
import noEditableAlert from '../../lib/noEditableAlert';
import OptionsPopup from '../../../../styles/OptionsPopup';
import OptionsPopupItem from '../ChooseDate/styles/Item';

import { alignOptions } from './constants';
import AlignListItem from './AlignListItem';

const Wrapper = styled.div`
  position: relative;
`;

const AlignPopup = ({ isEditable, value, onSelectedValue, label }) => {
  const node = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleArrowClick = () => {
    if (!isEditable) {
      noEditableAlert();
    } else {
      setPopupVisible(!popupVisible);
    }
  };

  const selectValue = align => {
    onSelectedValue(align);
    setPopupVisible(false);
  };

  return (
    <Wrapper ref={node}>
      <Box style={{ paddingTop: 12.5, paddingBottom: 12.5 }}>
        <span>{label}</span>
        <ArrowInCircleButton background="green" onClick={handleArrowClick} />
      </Box>

      <FloatingMenu
        visible={popupVisible}
        position="right"
        onClose={() => setPopupVisible(false)}
        parent={node.current || document.body}
      >
        <OptionsPopup>
          {alignOptions.map(item => (
            <OptionsPopupItem key={item} onClick={() => selectValue(item)}>
              <AlignListItem align={item} active={item === value} />
            </OptionsPopupItem>
          ))}
        </OptionsPopup>
      </FloatingMenu>
    </Wrapper>
  );
};

AlignPopup.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onSelectedValue: PropTypes.instanceOf(Function).isRequired,
  label: PropTypes.string,
};

AlignPopup.defaultProps = {
  label: 'Zmień wyrównanie listy',
};

export default AlignPopup;
