import styled, { css } from 'styled-components';

const defaultFont = css`
  font-size: 1.7em;
  font-weight: bold;
`;

const cell = css`
  ${defaultFont};
  background-color: var(--bg-color-white-gray);
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;

  &:hover {
    background-color: var(--bg-color-light-secondary-gray);
  }
`;

export default styled.div`
  > div > div {
     {
      background-color: var(--white);
    }
  }

  .rdtPicker .rdtTimeToggle {
    ${cell};
  }

  .rdtCounter .rdtCount {
    ${cell};
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .rdtCounterSeparator {
    line-height: 4.79em;
    ${defaultFont};
  }

  .rdtTime .rdtSwitch {
    ${cell};
    font-size: 1.2em;
  }
`;
