import React from 'react';
import { alert } from 'components/Alert';

export default (message, type = 'error') => {
  alert.open({
    config: {
      type,
      title: type === 'error' ? 'Coś się pokićkało' : 'Sukces',
      description: <span>{message}</span>,
      confirmButtonText: type === 'error' ? 'Innym razem' : 'Dzięki wielkie',
    },
  });
};
