import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GoalsIcon } from '../../../assets/icons/mainmenu/goals.svg';
import { showGoalsToggle } from '../../../store/actions/tiplist';

const ToggleButton = styled.button`
  margin-left: 30px;
  padding-top: 7px;
  background-color: var(--white);
  border: 0px;

  > span {
    margin-left: 15px;
    padding-top: 3px;
    color: var(--white-black);
    position: absolute;
  }

  .goalicon {
    background-color: var(--bg-color-light-gray);
    border: 0px;
    fill: var(--white-black);
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    > span {
      margin-left: 15px;
      padding-top: 3px;
      color: var(--white-black);
      transform: translateX(25px);
    }
  }

  &:hover {
    > span {
      color: var(--brand-blue);
    }
    .goalicon {
      fill: var(--brand-blue);
    }
  }
`;

export const ToggleGoals = () => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.tiplist.showGoals);

  const handleToggle = () => {
    dispatch(showGoalsToggle());
  };

  return (
    <>
      <ToggleButton
        type="button"
        onClick={() => {
          handleToggle();
        }}
      >
        <GoalsIcon className="goalicon" width="22px" height="22px" />
        {show && <span>Ukryj nazwę celu</span>}
        {!show && <span>Pokaż nazwę celu</span>}
      </ToggleButton>
    </>
  );
};
