import styled, { css } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';

const themes = {
  gray: css`
    background-color: var(--light-gray);
    border: 1px solid var(--gray);
  `,
  orange: css`
    background-color: var(--yellow);
    border: 1px solid var(--gray);
  `,
  red: css`
    background-color: var(--red);
    border: 1px solid var(--gray);
  `,
};

const WrapperBox = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: flex-start;

  line-height: 1;
  font-size: 15px;
  text-align: left;

  padding: 15px 15px;
  margin: 5px 0 15px;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.05);

  color: var(--text-color);
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 4px;

  ${props => themes[props.theme]}
`;

const FramedText = ({ text, theme, color, ...props }) => (
  <WrapperBox theme={theme}>
    <Text color={color} margin="0" dangerouslySetInnerHTML={{ __html: text }} />
  </WrapperBox>
);

FramedText.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.string,
  color: PropTypes.string,
};

FramedText.defaultProps = {
  text: 'default text',
  theme: 'gray',
  color: 'black',
};

export default FramedText;
