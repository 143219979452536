import React from 'react';
import PropTypes from 'prop-types';
import gifSEImage from 'assets/images/StreamElementsTutorial/gif_SE_step_5.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step8 = ({ processStep, goPrev }) => (
  <StepContent provider="stream_elements" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Nice! Najważniejsze mamy za sobą! Teraz, po poprawnym przejściu procesu uzupełnienia danych
      osobowych, przejdźmy do zakładki „Tipping settings” wybierając z listy po lewej stronie.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifSEImage} />
  </StepContent>
);

export default Step8;

Step8.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
