export default (price, convertFromGrosze = true, suffixType = 'default') => {
  let formatter;

  switch (suffixType) {
    case 'default':
      formatter = Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
      break;
    case 'symbol':
    case 'code':
    case 'name':
      formatter = Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
        currencyDisplay: suffixType,
      });
      break;
    case 'none':
      formatter = Intl.NumberFormat('pl-PL');
      break;
    default:
      throw new TypeError('Invalid suffixType');
  }

  return formatter.format(convertFromGrosze ? price / 100 : price);
};
