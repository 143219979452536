import styled from 'styled-components';

export default styled.div`
  font-family: var(--google-sora-cdn);

  &.box {
    padding: 18px 42px 15px 60px;
    color: var(--text-color);
    display: flex;
    justify-content: flex-end;
    background-color: var(--white);

    @media screen and (max-width: 992px) {
      padding-right: 30px;
    }
  }

  .pagination-value {
    margin: 0 10px;
  }

  .pagination-info {
    @media screen and (max-width: 450px) {
      display: none;
    }
  }

  button {
    display: inline-block;
    background: none;
    border: 0;
    outline: 0;
    padding: 3px 8px;
    margin: 0 10px;
    cursor: pointer;

    svg {
      width: 12px;
      fill: var(--text-color);
      transition: 250ms;
      transform: scale(1);
    }

    &.down {
      svg {
        width: 10px;
      }
    }

    &:hover {
      svg {
        transform: scale(1.5);
        fill: var(--brand-blue);
      }
    }
  }

  .list-wrap {
    position: relative;
  }

  .pagination-List {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    list-style-type: none;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

    li {
      text-align: center;
      button {
        padding: 5px;
        color: var(--light-text-gray);

        &:hover {
          transition: 250ms;
          color: var(--brand-blue);
        }
      }
    }
  }
`;
