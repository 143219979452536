import React from 'react';

const SvgComponent = props => (
  <svg id="BeBold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M7.023,10.368l-.92.92A2.4,2.4,0,0,1,2.712,7.9l.92-.92A1.005,1.005,0,0,0,2.21,5.556l-.919.92a4.407,4.407,0,1,0,6.233,6.233l.92-.919a1.005,1.005,0,0,0-1.421-1.422Z" />
    <path d="M7.711,4.868,4.868,7.711A1,1,0,0,0,6.289,9.132L9.132,6.289A1,1,0,0,0,7.711,4.868Z" />
    <path d="M12.709,1.291a4.407,4.407,0,0,0-6.233,0l-.92.919A1.005,1.005,0,0,0,6.977,3.632l.92-.92A2.4,2.4,0,0,1,11.288,6.1l-.92.92A1.005,1.005,0,0,0,11.79,8.444l.919-.92a4.407,4.407,0,0,0,0-6.233Z" />
  </svg>
);

export default SvgComponent;
