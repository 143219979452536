import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';
import Link from 'components/Link';

import StepContent from '../../../components/StepContent';

const Step4 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Zacznijmy od zalogowania się do serwisu TipeeeStream. Zrób to korzystajac z poniższego
      odnośnika:
    </Text>
    <Text align="center" mb={30}>
      <Link href="https://www.tipeeestream.com/login" target="_blank">
        https://www.tipeeestream.com/login
      </Link>
    </Text>
  </StepContent>
);

export default Step4;

Step4.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
