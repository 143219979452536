import React from 'react';
import PropTypes from 'prop-types';
import { Submenu, Item } from 'react-contexify';
import styled from 'styled-components';

import Indicator from 'styles/Indicator';

const ActiveIndicator = styled(Indicator)`
  transform: translateX(calc(100% + 16px));
`;

const AnimationContextMenuItems = ({ animationTypes, activeAnimation, selectAnimationHandler }) => (
  <React.Fragment>
    <Item
      onClick={() => selectAnimationHandler('')}
      disabled={() => activeAnimation === ''}
      style={{ marginBottom: 4 }}
    >
      Brak animacji
    </Item>
    {animationTypes.map(type => (
      <Submenu key={type.name} label={type.name}>
        {type.animations.map(animation => (
          <Item
            key={animation.className}
            onClick={() => selectAnimationHandler(animation.className)}
            disabled={activeAnimation === animation.className}
          >
            {animation.name}
            {activeAnimation === animation.className && <ActiveIndicator />}
          </Item>
        ))}
      </Submenu>
    ))}
  </React.Fragment>
);

AnimationContextMenuItems.propTypes = {
  animationTypes: PropTypes.instanceOf(Array).isRequired,
  activeAnimation: PropTypes.string.isRequired,
  selectAnimationHandler: PropTypes.instanceOf(Function).isRequired,
};

export default AnimationContextMenuItems;
