import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Item } from 'react-contexify';

const AddElementContextMenu = ({ menuId, elements, onShowElement }) => (
  <Menu id={`${menuId}_contextMenu`}>
    {elements.map(element => (
      <Item key={element.key} onClick={() => onShowElement(element.key)}>
        {element.name}
      </Item>
    ))}
  </Menu>
);

AddElementContextMenu.propTypes = {
  menuId: PropTypes.string.isRequired,
  elements: PropTypes.instanceOf(Array).isRequired,
  onShowElement: PropTypes.instanceOf(Function).isRequired,
};

export default AddElementContextMenu;
