import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Modal from './Modal';

const Component = props => {
  const { isMounted, unmountDelay, onTransitionExiting } = props;

  return (
    <CSSTransition
      in={isMounted}
      timeout={unmountDelay}
      onExiting={onTransitionExiting}
      classNames="fade"
      unmountOnExit
    >
      <Modal {...props} />
    </CSSTransition>
  );
};

Component.propTypes = {
  onTransitionExiting: PropTypes.instanceOf(Function),
  isMounted: PropTypes.bool,
  unmountDelay: PropTypes.number,
};

Component.defaultProps = {
  onTransitionExiting: () => null,
  isMounted: false,
  unmountDelay: 500,
};

export default Component;
