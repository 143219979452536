import React from 'react';
import styled from 'styled-components';

import PayPalLogo from 'assets/images/paypal-logo.png';
import Link from 'components/Link';
import { usePayPal } from 'components/PayPalConnect/hooks';
import Label from './Label';

const WaitingForAuthorization = () => {
  const { refreshEmail } = usePayPal();
  return (
    <Label style={{ textAlign: 'center' }}>
      <span>Otwarto w nowym oknie</span>
      <br />
      {'Okno nie wyskoczyło? '}
      <Link onClick={refreshEmail}>Spróbuj ponownie</Link>
    </Label>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  cursor: pointer;
`;

const BigLogo = styled.img.attrs(() => ({
  src: PayPalLogo,
}))`
  width: auto;
  height: 24px;
  display: block;
`;

const NotConnected = () => {
  const { refreshEmail, working, waitingForAuthorization } = usePayPal();

  if (waitingForAuthorization) {
    return <WaitingForAuthorization />;
  }

  return (
    <Wrapper onClick={() => refreshEmail()}>
      <Label>
        Wypłata na PayPal?
        <br />
        <span>{working ? 'Łączę...' : 'Kliknij i połącz'}</span>
      </Label>
      <BigLogo />
    </Wrapper>
  );
};

export default NotConnected;
