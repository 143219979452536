import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../../store/actions/userData';
import http from '../../utils/http';
import { openAlert } from '../Alert/utils';

export const usePayPal = (onConnect = email => {}) => {
  const dispatch = useDispatch();
  const { paypal_email: paypalEmail, bank_number: bankNumber, id, pending } = useSelector(
    state => state.userData.profile,
  );
  const [connected, setConnected] = useState(false);
  const [working, setWorking] = useState(false);
  const [waitingForAuthorization, setWaitingForAuthorization] = useState(false);
  const dialogOpenMonitor = createRef();
  const dialogWindowRef = createRef();
  const isReady = id !== undefined;

  useEffect(() => {
    const onPayPalConnected = event => {
      const { message, email } = event.data;
      if (message === 'paypal_connected') {
        updatePayPalEmailInStore(email);
        onConnect(email);
      }
    };

    window.addEventListener('message', onPayPalConnected);

    return () => {
      window.removeEventListener('message', onPayPalConnected);
    };
  }, []);

  useEffect(() => {
    setConnected(!!paypalEmail);
  }, [paypalEmail]);

  function showPendingWithdrawalError() {
    showError('Nie możesz zmienić adresu PayPal, ponieważ masz wypłatę oczekującą na realizację.');
  }

  function disconnect(onSuccess = () => {}) {
    if (`${bankNumber}`.length === 0) {
      showError('Nie możesz odłączyć konta PayPal, jeśli nie podałeś numeru konta bankowego.');
      return;
    }

    setWorking(true);

    http
      .put('/user/disconnect-paypal-email')
      .then(() => {
        updatePayPalEmailInStore('');
        onSuccess();
      })
      .catch(error => {
        if (error.response.status === 400) {
          const { data } = error.response;
          if (data.reason === 'pending_withdrawal') {
            showPendingWithdrawalError();
            return;
          }
        }
        showError(
          'Nie udało się odłączyć Twojego konta od PayPal. Odśwież stronę i spróbuj ponownie.',
        );
      })
      .finally(() => {
        setWorking(false);
      });
  }

  function updatePayPalEmailInStore(email) {
    if (pending) {
      showError(
        'Nie możesz zmienić adresu PayPal w trakcie weryfikacji zmiany w danych osobowych.',
      );
      return;
    }

    if (email !== paypalEmail) {
      dispatch(updateUserData({ profile: { paypal_email: email } }));
    }
  }

  function showError(description) {
    openAlert('error', 'Błąd aktualizacji danych', description);
  }

  function refreshEmail() {
    setWorking(true);

    http
      .put('/user/refresh-paypal-email')
      .then(({ data }) => {
        updatePayPalEmailInStore(data.email);
      })
      .catch(error => {
        if (error.response.status === 400) {
          const { data } = error.response;
          switch (data.reason) {
            case 'need_refresh_token':
              openAuthorizationDialog(data.url);
              return;
            case 'pending_withdrawal':
              showPendingWithdrawalError();
              return;
            default:
              break;
          }
        }
        showError('Nie udało się pobrać nowego adresu PayPal. Odśwież stronę i spróbuj ponownie.');
      })
      .finally(() => {
        setWorking(false);
      });
  }

  function openAuthorizationDialog(url) {
    resetDialogOpenMonitor();
    setWaitingForAuthorization(true);

    const width = Math.round(Math.min(window.innerWidth * 0.9, 600));
    const height = Math.round(Math.min(window.innerHeight * 0.9, 800));
    const left = Math.round((window.innerWidth - width) / 2);
    const top = Math.round((window.innerHeight - height) / 2);

    const dialogOptions = {
      width,
      height,
      left,
      top,
    };

    dialogWindowRef.current = window.open(
      url,
      'paypal',
      new URLSearchParams(dialogOptions).toString().replaceAll('&', ','),
    );

    dialogOpenMonitor.current = setInterval(() => {
      if (dialogWindowRef.current.closed) {
        resetDialogOpenMonitor();
        setWaitingForAuthorization(false);
      }
    }, 500);
  }

  function resetDialogOpenMonitor() {
    if (dialogOpenMonitor.current) {
      clearInterval(dialogOpenMonitor.current);
      dialogOpenMonitor.current = null;
    }
  }

  return {
    isReady,
    connected,
    working,
    waitingForAuthorization,
    disconnect,
    refreshEmail,
    paypalEmail,
  };
};
