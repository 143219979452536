import React, { useReducer, useEffect, useState } from 'react';

import http from 'utils/http';

import RootModal from 'components/Modal';
import Text from 'components/Text';
import Textarea from 'components/Textarea';
import NumberInput from 'components/NumberInput';
import Button from 'components/Button';
import { alert } from 'components/Alert';

import { convertToGrosze } from 'utils/priceConverter';

function openAlert(type, title, description) {
  alert.open({
    config: {
      type,
      title,
      description: <span>{description}</span>,
      cancelButtonText: 'Zamknij',
    },
    events: {
      onConfirm: () => {
        // Usunięte
      },
    },
  });
}

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_MESSAGE':
      return {
        ...state,
        message: action.message,
      };
    case 'CHANGE_AMOUNT':
      return {
        ...state,
        amount: action.amount,
      };
    case 'START_SEND':
      return {
        ...state,
        loading: true,
      };
    case 'END_SEND':
      return {
        ...state,
        loading: false,
      };
    default:
      throw new Error();
  }
}

const SHOW_MODAL_EVENT = new CustomEvent('SHOW_TEST_TIP_MODAL');
const HIDE_MODAL_EVENT = new CustomEvent('HIDE_TEST_TIP_MODAL');

const Modal = props => {
  const [state, dispatch] = useReducer(reducer, {
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed risus non elit dictum porta eu non velit. Etiam at eros vitae mi faucibus accumsan nec non dolor. Curabitur sed molestie dolor, a vehicula nisi.',
    amount: 10,
  });
  const [mounted, setMounted] = useState(false);

  function show() {
    setMounted(true);
  }

  function hide() {
    setMounted(false);
  }

  useEffect(() => {
    window.addEventListener('SHOW_TEST_TIP_MODAL', show);
    window.addEventListener('HIDE_TEST_TIP_MODAL', hide);

    return () => {
      window.removeEventListener('SHOW_TEST_TIP_MODAL', show);
      window.removeEventListener('HIDE_TEST_TIP_MODAL', hide);
    };
  }, []);

  return (
    <>
      <RootModal
        title="Wyślij wiadomość testową"
        width={510}
        height="auto"
        isMounted={mounted}
        onClose={hide}
      >
        <form
          onSubmit={async event => {
            event.preventDefault();

            dispatch({ type: 'START_SEND' });

            try {
              await http.post('/test-tip', {
                message: state.message,
                amount: convertToGrosze(state.amount),
              });

              openAlert('success', 'Sukces', 'Testowa wiadomość została wysłana');
            } catch (error) {
              openAlert('error', 'Wystąpił błąd', 'Testowa wiadomość nie została wysłana');
            }

            dispatch({ type: 'END_SEND' });
          }}
        >
          <div style={{ margin: '0 0 15px' }}>
            <Text>Wiadomość</Text>
            <Textarea
              maxLength={225}
              value={state.message}
              onChange={({ target }) => dispatch({ type: 'CHANGE_MESSAGE', message: target.value })}
            />
          </div>
          <div style={{ margin: '0 0 15px' }}>
            <Text>Kwota</Text>
            <NumberInput
              decimalSeparator=","
              value={state.amount}
              defaultValue={state.amount}
              decimalScale={2}
              allowEmptyFormatting
              allowNegative={false}
              suffix=" PLN"
              style={{ maxWidth: 110, textAlign: 'center' }}
              isAllowed={({ floatValue }) => floatValue >= 0 && floatValue < 100001}
              onValueChange={({ floatValue }) => {
                dispatch({ type: 'CHANGE_AMOUNT', amount: floatValue });
              }}
            />
          </div>

          <Button
            disabled={state.loading || !state.amount || !state.message}
            loading={state.loading}
            block
            type="submit"
            background="blue"
          >
            Wyślij
          </Button>
        </form>
      </RootModal>
    </>
  );
};

export function showModal() {
  window.dispatchEvent(SHOW_MODAL_EVENT);
}

export function hideModal() {
  window.dispatchEvent(HIDE_MODAL_EVENT);
}

export default Modal;
