import styled from 'styled-components';

import Input from './Input';

export default styled.div`
  position: relative;

  > svg {
    width: 16px;
    height: 16px;
    fill: var(--text-color);
    pointer-events: none;
    position: absolute;
    top: 14px;
    right: 20px;
  }

  > span,
  > button {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 14px;
    right: 60px;

    svg {
      fill: var(--text-color);
    }

    &:hover svg {
      fill: var(--blue);
    }
  }

  ,
  ${Input} {
    display: block;
    max-width: none;
  }
`;
