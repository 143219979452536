import styled from 'styled-components';

import Select from 'components/Select';

export default styled(Select)`
  margin-bottom: 17px;

  .react-select {
    &__control {
      font-size: 15px;
      border-color: var(--border-color);
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 5px;
    }

    &__placeholder {
      color: rgb(109, 109, 109);
    }

    &__option {
      &--is-selected {
        &::before {
          display: none;
        }
      }
    }
  }
`;
