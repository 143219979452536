import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LabelWrapper = styled.div`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  padding-left: 5px;
`;

const EmojiLabel = ({ emoji, label }) => (
  <LabelWrapper className="label">
    {emoji && (
      <span role="img" aria-label={label}>
        {emoji}
      </span>
    )}
    <span>{label}</span>
  </LabelWrapper>
);

EmojiLabel.propTypes = {
  label: PropTypes.string.isRequired,
  emoji: PropTypes.string,
};

EmojiLabel.defaultProps = {
  emoji: '',
};

export default EmojiLabel;
