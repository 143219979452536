import deepmerge from 'deepmerge';
import io from 'socket.io-client';

import http from 'utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const socket = io(process.env.REACT_APP_SOCKET_URL);

const fetchGoalsRequest = () => ({
  type: 'GOALS_FETCH_REQUEST',
});

const fetchGoalsSuccess = payload => ({
  type: 'GOALS_FETCH_SUCCESS',
  payload,
});

const fetchGoalsError = () => ({
  type: 'GOALS_FETCH_ERROR',
});

const fetchGoals = () => dispatch => {
  dispatch(fetchGoalsRequest());

  return http
    .get('/user/goals')
    .then(response => response.data)
    .then(goals => dispatch(fetchGoalsSuccess(goals)))
    .catch(() => dispatch(fetchGoalsError()));
};

export const fetchGoalsIfNeeded = () => (dispatch, getState) => {
  const { goals } = getState();

  if (shouldFetchData(goals)) {
    dispatch(fetchGoals());
  }
};

const createGoalRequest = () => ({
  type: 'GOAL_CREATE_REQUEST',
});

const createGoalSuccess = (goal, slot) => ({
  type: 'GOAL_CREATE_SUCCESS',
  payload: {
    goal,
    slot,
  },
});

const createGoalError = () => ({
  type: 'GOAL_CREATE_ERROR',
});

export const createGoal = (title = 'Nowy cel', slot = -1) => dispatch => {
  dispatch(createGoalRequest());

  const newGoal = {
    title,
    target: 100000,
    initial_value: 0,
    without_commission: true,
    template_id: 'DEFAULT_TIPS_GOAL_1',
  };

  http
    .post('/user/goals', newGoal)
    .then(response => response.data)
    .then(goal => dispatch(createGoalSuccess(goal, slot)))
    .catch(() => dispatch(createGoalError()));
};

const updateGoalRequest = payload => ({
  type: 'GOAL_UPDATE_FIELD_REQUEST',
  payload,
});

const updateGoalSuccess = () => ({
  type: 'GOAL_UPDATE_FIELD_SUCCESS',
});

const updateGoalError = () => ({
  type: 'GOAL_UPDATE_FIELD_ERROR',
});

export const updateGoalField = (id, field, value) => (dispatch, getState) => {
  dispatch(updateGoalRequest({ id, field, value }));

  const {
    goals: { items },
    userData: {
      info: { id: userId },
    },
  } = getState();

  const goal = items.find(i => i.id === id);
  const data = deepmerge(goal, { [field]: value });

  http
    .patch(`/user/goals/${id}`, data)
    .then(() => {
      socket.emit('goals', userId, id);
      dispatch(updateGoalSuccess());
    })
    .catch(() => dispatch(updateGoalError()));
};

const deleteGoalRequest = id => ({
  type: 'GOAL_DELETE_REQUEST',
  id,
});

const deleteGoalSuccess = () => ({
  type: 'GOAL_DELETE_SUCCESS',
});

const deleteGoalError = () => ({
  type: 'GOAL_DELETE_ERROR',
});

export const deleteGoal = id => dispatch => {
  dispatch(deleteGoalRequest(id));

  http
    .delete(`/user/goals/${id}`)
    .then(() => dispatch(deleteGoalSuccess()))
    .catch(() => dispatch(deleteGoalError()));
};

const emptyGoalRequest = id => ({
  type: 'GOAL_EMPTY_REQUEST',
  id,
});

export const emptyGoal = id => (dispatch, getState) => {
  dispatch(emptyGoalRequest(id));

  const {
    userData: {
      info: { id: userId },
    },
  } = getState();

  http
    .patch(`/user/goals/${id}/reset`)
    .then(() => {
      socket.emit('goals', userId, id);
      dispatch(updateGoalSuccess());
    })
    .catch(() => dispatch(updateGoalError()));
};

export const resetCreatedGoal = () => ({
  type: 'GOAL_RESET_CREATED_GOAL',
});
