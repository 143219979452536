import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Tick } from 'assets/icons/checkbox-tick.svg';

import CustomCheckbox from './styles/CustomCheckbox';
import Switch from './styles/Switch';
import Input from './styles/Input';

import Label from '../../styles/Label';
import Box from '../../styles/Box';
import Text from '../../styles/Text';

/**
 * Komponent prostego elementu radio|checkbox
 *
 * @param {Object} props
 * @param {Bool} props.checked - obowiązkowa wartość wskazująca czy elementu ma być zaznaczony
 * @param {String} props.text - wartość przekazująca tekst dla elementu
 * @param {Function} props.onCheck - callback przy zaznaczeniu elementu
 * @param {String} props.type - możliwośc ustalenia atrybutu type elementu (radio|checkbox)
 * @param {String} props.name - możliwośc ustalenia atrybutu name elementu
 * @param {String} props.color - możliwośc ustalenia koloru koloru dla elementu (blue|green)
 * @param {String} props.apperance - wygląd elemnetu (box|switch)
 */
const Simple = props => {
  const { checked, text, onCheck, type, name, color, apperance, disabled } = props;

  return (
    <Label>
      <Input
        checked={checked}
        value={checked}
        color={color}
        type={type}
        name={name}
        onChange={e => onCheck(e.target.checked)}
        disabled={disabled}
      />

      {apperance === 'box' ? (
        <CustomCheckbox>
          <Box color={color}>
            <Tick />
          </Box>
          {text && <Text>{text}</Text>}
        </CustomCheckbox>
      ) : (
        <Switch />
      )}
    </Label>
  );
};

Simple.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  apperance: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  onCheck: PropTypes.instanceOf(Function),
  disabled: PropTypes.bool,
};

Simple.defaultProps = {
  onCheck: value => {},
  color: 'blue',
  type: 'checkbox',
  text: '',
  name: '',
  apperance: 'box',
  disabled: false,
};

export default Simple;
