/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import merge from 'deepmerge';

import Button from 'components/Button';

import { ReactComponent as CompleteIcon } from 'assets/icons/complete-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/error-icon.svg';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

import ThinkingFace from 'assets/images/thinking-face.png';

import AlertBox from '../styles/AlertBox';
import Overlay from '../styles/Overlay';
import Wrapper from '../styles/Wrapper';
import Icon from '../styles/Icon';
import Title from '../styles/Title';
import Description from '../styles/Description';
import CancelButton from '../styles/CancelButton';
import AfterConfirmButtonContainer from '../styles/AfterConfirmButtonContainer';

import Manager from './Manager';

export default class Container extends Component {
  constructor(props) {
    super(props);

    this.defaultState = this.getConfig({ params: props, isInit: true });

    this.state = this.defaultState;

    Manager.setDefault(this.defaultState, this.defaultsEvents);

    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.closeImagebox = Manager.close.bind(Manager);
  }

  componentWillMount() {
    Manager.addChangeListener(this.handleStoreChange);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown.bind(this));
    Manager.removeChangeListener(this.handleStoreChange);
  }

  onClosed() {
    const { onClosed } = this.state;
    onClosed && onClosed();
    this.setState(this.defaultState);
  }

  onKeyDown(e) {
    const { show } = this.state;

    if (show && e.keyCode === 27) {
      this.closeImagebox();
    }
  }

  getConfig({ events, params, isInit }) {
    const defaultConfig = {
      type: 'success',

      title: null,
      description: null,

      confirmButtonText: 'Akceptuję',
      confirmButton: true,
      afterConfirmButton: null,
      cancelButtonText: 'Anuluj',
      cancelButton: false,

      show: false,

      transitionSpeed: 400,

      onConfirm() {},
      onCancel() {},
      onOpen() {},
      onOpened() {},
      onClose() {},
      onClosed() {},
      onCleanup() {},
    };

    if (isInit && !params) return defaultConfig;

    const customConfig = {
      ...params,
      ...events,
    };

    return merge(isInit ? defaultConfig : this.defaultState, customConfig);
  }

  getIcon() {
    const { type } = this.state;
    let icon;

    switch (type) {
      case 'error':
        icon = <CloseIcon width="35px" height="35px" />;
        break;
      case 'success':
        icon = <CompleteIcon width="40px" height="40px" />;
        break;
      case 'loading':
        icon = <LoadingIcon width="40px" height="40px" />;
        break;
      case 'confirm':
        icon = <img src={ThinkingFace} alt="" />;
        break;
      default:
        icon = <CompleteIcon width="40px" height="40px" />;
    }

    return icon;
  }

  cleanUp() {
    const { callback } = this.state;

    clearTimeout(callback);
  }

  handleStoreChange(params) {
    this.cleanUp();

    const { show, config, events } = params;

    const currentConfig = this.getConfig({ params: config, isInit: false, events });
    const { transitionSpeed } = currentConfig;

    if (show) {
      const { onOpened, onOpen } = currentConfig;

      this.setState(
        merge(currentConfig, {
          show: true,
          transition: `all ${transitionSpeed / 1000}s ease-in-out`,
          callback: setTimeout(() => {
            onOpened && onOpened();
          }, transitionSpeed),
        }),
      );

      onOpen();
    } else {
      const { onCleanUp, onClose } = currentConfig;

      onCleanUp && onCleanUp();
      onClose();

      this.setState({
        show: false,
        transition: `all ${transitionSpeed / 1000}s ease-in-out`,
        callback: setTimeout(() => {
          this.onClosed();
        }, transitionSpeed),
      });
    }
  }

  /** Mapuje kolory błędów na */
  mapTypeToColor() {
    const { type } = this.state;
    let color;

    switch (type) {
      case 'error':
        color = 'red';
        break;
      case 'success':
        color = 'green';
        break;
      case 'confirm':
        color = 'yellow';
        break;
      case 'loading':
        color = 'blue';
        break;
      default:
        color = 'green';
    }

    return color;
  }

  render() {
    const { show, style, className, transition, type } = this.state;

    const {
      title,
      description,
      confirmButtonText,
      confirmButton,
      cancelButtonText,
      cancelButton,
      afterConfirmButton,
    } = this.state;
    const { onConfirm, onCancel } = this.state;

    const color = this.mapTypeToColor();

    return (
      <Wrapper style={{ transition }} isVisible={show} className={type}>
        <AlertBox className={`${className ? ` ${className}` : ''}`} style={style}>
          <Icon style={{ backgroundColor: `var(--${color})` }}>{this.getIcon()}</Icon>

          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}

          {confirmButton && (
            <Button
              noWrapper
              block
              background={color}
              onClick={() => {
                onConfirm();
                this.closeImagebox();
              }}
            >
              {confirmButtonText}
            </Button>
          )}

          {afterConfirmButton !== null && (
            <AfterConfirmButtonContainer>{afterConfirmButton}</AfterConfirmButtonContainer>
          )}

          {cancelButton && (
            <CancelButton
              type="button"
              onClick={() => {
                onCancel();
                this.closeImagebox();
              }}
            >
              {cancelButtonText}
            </CancelButton>
          )}
        </AlertBox>

        <Overlay />
      </Wrapper>
    );
  }
}
