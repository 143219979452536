import WebFont from 'webfontloader';
import { hop } from 'utils';

/**
 * Funkcja rekurencyjna interująca po wszystkich elementach szablonu
 * wyszukując użytych fontów.
 *
 * @param {Array} templates - lista szablonów pobranych z serwera
 * @returns {Array}
 */
function extractStyles(elementsOptions, passedFonts = []) {
  let fontsFamilies = passedFonts;

  for (const key in elementsOptions) {
    if (Object.prototype.hasOwnProperty.call(elementsOptions, key)) {
      const element = elementsOptions[key];

      if (Object.prototype.hasOwnProperty.call(element, 'children')) {
        fontsFamilies = fontsFamilies.concat(extractStyles(element.children, fontsFamilies));
      } else if (hop(element, 'styles') && element.styles.fontFamily) {
        fontsFamilies.push(element.styles.fontFamily);
      }
    }
  }

  return fontsFamilies;
}

/**
 * Funkcja ładująca wyszukane z szablonów fonty z Google Fonts API
 *
 * @param {Array} templates - lista szablonów pobranych z serwera
 * @returns {void}
 */
export default function loadWebfontsFromTemplates(templates = []) {
  let fonts = [];

  for (let i = 0; i < templates.length; i += 1) {
    const {
      config: { elementsOptions },
    } = templates[i];

    fonts = [...new Set(fonts.concat(extractStyles(elementsOptions)))];
  }

  if (fonts.length > 0) {
    WebFont.load({
      google: {
        families: fonts.map(font => `${font}:400,700,900`),
      },
    });
  }
}
