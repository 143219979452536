import styled, { css } from 'styled-components';

import GrayButtonContainer from 'styles/buttons/GrayContainer';

const copied = css`
  pointer-events: none;
`;

export default styled(GrayButtonContainer)`
  cursor: pointer;

  ${props => props.copied && copied};
`;
