const templates = [
  {
    mode: 'COUNTER',
    title: 'Domyślny szablon #1',
    editable: false,
    animation: {
      enable: false,
      duration: 0,
    },
    elementsOptions: {
      text: {
        styles: {
          color: '#fff',
          zIndex: 2,
          fontSize: 24,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 451,
          y: 86,
        },
        isVisible: false,
        textValue: 'Tekst',
      },
      textInput: {
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 64,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 271,
          y: 256.5,
        },
        isVisible: true,
        textValue: '04:20:30',
        isAnimated: true,
      },
      visualObject: {
        size: {
          width: 44,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_3',
        position: {
          x: 378,
          y: 151.5,
        },
        isVisible: true,
      },
      additionalTime: {
        styles: {
          color: '#00ff22',
          zIndex: 4,
          fontSize: 30,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 419,
          y: 238,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '+10 min',
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'ALL',
    },
    numberDisplayedItems: 5,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
  {
    mode: 'CLOCK',
    title: 'Domyślny szablon #2',
    editable: false,
    animation: {
      enable: false,
      duration: 0,
    },
    elementsOptions: {
      text: {
        styles: {
          color: '#fff',
          zIndex: 2,
          fontSize: 24,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 451,
          y: 86,
        },
        isVisible: false,
        textValue: 'Tekst',
      },
      textInput: {
        styles: {
          color: '#fff',
          zIndex: 3,
          fontSize: 64,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 271,
          y: 256.5,
        },
        isVisible: true,
        textValue: '04:20:30',
        isAnimated: true,
      },
      visualObject: {
        size: {
          width: 44,
        },
        styles: {
          zIndex: 1,
        },
        mediumId: 'DEFAULT_MEDIUM_IMAGE_3',
        position: {
          x: 378,
          y: 151.5,
        },
        isVisible: true,
      },
      additionalTime: {
        styles: {
          color: '#00ff22',
          zIndex: 4,
          fontSize: 30,
          fontStyle: 'normal',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontWeight: 900,
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.67)',
        },
        position: {
          x: 419,
          y: 238,
        },
        animation: {
          enable: false,
        },
        isVisible: true,
        textValue: '+10 min',
      },
    },
    displayDirection: 'vertical',
    showDonatesFromDate: {
      type: 'ALL',
    },
    numberDisplayedItems: 5,
    spacingBetweenElements: 5,
    amountWithoutCommission: true,
  },
];

export default templates;
