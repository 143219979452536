import styled from 'styled-components';

import { MIN_TABLET } from 'styles/base/breakpoints';

export default styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
  @media (${MIN_TABLET}) {
    margin-bottom: 0;
  }

  .filter-list {
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translate(-50%, 0);
    list-style-type: none;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    padding: 0 10px;
    width: 250px;
    z-index: 9999;

    li {
      list-style-type: none;

      &:last-child {
        button {
          border-bottom: 0 solid white;
        }
      }
    }

    button {
      background: none;
      border: 0;
      padding: 10px;
      outline: none;
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid var(--light-gray);
      color: var(--text-light);
      transition: 200ms;

      &:hover {
        color: var(--brand-blue);
      }
    }
  }

  span {
    transition: 350ms;
  }

  .filters_wrap {
    width: 30px;
    height: 30px;
    position: relative;
    transform: translateX(-4px);

    div {
      background: var(--white-black);
      transition: 350ms;
      height: 4px;
      position: absolute;
      border-radius: 5px;

      left: 50%;
      transform: translate(-50%, -50%);

      &:first-child {
        width: 20px;
        top: 8px;
      }

      &:nth-child(2) {
        width: 20px;
        top: 16px;
      }

      &:nth-child(3) {
        width: 10px;
        top: 24px;
      }
    }
  }

  &:hover {
    span {
      color: var(--brand-blue);
    }

    .filters_wrap {
      div {
        background: var(--brand-blue);

        &:first-child {
          width: 10px;
          top: 8px;
        }

        &:nth-child(2) {
          width: 30px;
          top: 16px;
        }

        &:nth-child(3) {
          width: 20px;
          top: 24px;
        }
      }
    }
  }
`;
