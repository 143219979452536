import React from 'react';

const AnimationSettingsHint = () => (
  <React.Fragment>
    Zarządzaj animacjami elementów, jak i całego powiadomienia w tym miejscu! Pamiętaj o tym, że
    Animacja widgetu (ta ogólna) jest ważniejsza i dopóki ona jest włączona, nie wyświetli się inna
    animacja z podziałem na konkretne elementy. By zarządzać animacją widgetu (ogólną) nie zaznaczaj
    żadnego elementu by ją edytować. By zarządzać animacją konkretnego elementu → kliknij na niego i
    edytuj animację w tym oknie.
    <br />
    <br />
    Pomocne: Domyślne szablony mają ustawioną animacje ogólną na wejście oraz wyjście. Animacja „w
    trakcie” jest z podziałem na konkretne elementy.
  </React.Fragment>
);

const DisplayDurationHint = () => (
  <React.Fragment>
    Nowość! Zarządzaj długością wyświetlania powiadomienia na ekranie! Określasz dzięki temu czas,
    przez który powiadomienie będzie widoczne na ekranie, co nie wpływa na dźwięk. W momencie, kiedy
    ustawisz np. 2 sekundy, a dźwięk donejta wraz z TTS może potrwać nawet 10 sekund – to tylko
    przez 2 sekundy alert będzie widoczny, jednak słyszalny tak jak powinien – 10 sekund.
    <br />
    <br />
    Tryb automatyczny: Dzięki niemu jak dawniej, widoczność powiadomienia będzie zarządzane w taki
    sposób, by alert był widoczny tyle samo czasu co tekst.
  </React.Fragment>
);

const AmountWithoutProvisionHint = () => (
  <React.Fragment>
    Mając tę opcję zaznaczoną w powiadomieniach wyświetlana będzie kwota już po odjęciu pobranych od
    Ciebie prowizji. Mając odznaczoną – wyświetlamy pełną kwotę.
  </React.Fragment>
);

const WordFilterHint = () => (
  <React.Fragment>
    Ta opcja nakłada cenzurę na wybrane słowa, przekleństwa oraz wyrażenia obraźliwe czy
    rasistowskie. Nie musisz dzięki temu samemu wypisywać listy zakazanych słów. Spokojnie – gdyby
    już jakieś niecenzuralne słowo się pojawiło, a Ty streamujesz na Twitchu – wystarczy przeprosić,
    nie otrzymasz za to bana :)
  </React.Fragment>
);

const CensorWordsHint = () => (
  <React.Fragment>
    W tym miejscu możesz wypisać listę słów, które nie chcesz by pojawiły się widzom na ekranie.
    Pamiętaj jednak, że tego rodzaju filtr, można łatwo ominąć więc jeśli bardzo obawiasz się o
    treść wpłat – rekomendujemy ich moderację. Przycisk od tego znajdziesz poniżej bądź na liście
    wiadomości.
    <br />
    <br />
    Słowa możesz wpisywać pojedynczo, zatwierdzając Enterem lub wpisać listę slów np. z YouTube
    oddzielaną przecinkami.
  </React.Fragment>
);

export {
  AnimationSettingsHint,
  DisplayDurationHint,
  AmountWithoutProvisionHint,
  WordFilterHint,
  CensorWordsHint,
};
