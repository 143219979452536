import styled from 'styled-components';

const SlotWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .goal-selector {
    flex: 1;
  }
`;

export default SlotWrapper;
