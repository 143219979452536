import React from 'react';
import PropTypes from 'prop-types';
import fecha from 'fecha';

import styled from 'styled-components';
import Text from '../../Text';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-icon.svg';
import { ReactComponent as CompleteIcon } from '../../../assets/icons/complete-icon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/mainmenu/link.svg';

const ReasonChecklistRow = styled.div`
  display: flex;
  align-content: start;
  justify-content: start;
`;

const Checkmark = styled.span`
  transform: translateX(250px);
`;

const HelpText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--gray);
  text-decoration: none;

  > span {
    text-decoration: none;
    color: var(--gray);
    margin-top: 10px;
    font-size: 15px;
  }

  > svg {
    transform: translateY(4px);
    margin-right: 10px;
    width: 12px;
    height: 12px;
    fill: var(--gray);
  }
`;

const ProfileRejectedNotification = ({ notification, isCompany }) => (
  <>
    <Text size={15} margin="0 0 40px">
      Dane które przesłałeś zawierają parę nieprawidłowości. Zaznaczyliśmy je poniżej. Popraw je
      wysyłając wniosek ponownie.
    </Text>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.fullname === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.fullname === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      {isCompany && <Text>Nazwa firmy:</Text>}
      {!isCompany && <Text>Imię i nazwisko:</Text>}
    </ReasonChecklistRow>

    {isCompany && (
      <ReasonChecklistRow>
        <Checkmark>
          {notification.payload.adminFeedback.personalNumber === 'on' && (
            <ErrorIcon fill="var(--red)" width="16px" height="16px" />
          )}
          {notification.payload.adminFeedback.personalNumber === 'off' && (
            <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
          )}
        </Checkmark>
        <Text>NIP</Text>
      </ReasonChecklistRow>
    )}

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.addressStreet === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.addressStreet === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Ulica:</Text>
    </ReasonChecklistRow>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.addressCity === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.addressCity === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Miasto:</Text>
    </ReasonChecklistRow>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.addressPostalCode === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.addressPostalCode === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Kod pocztowy:</Text>
    </ReasonChecklistRow>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.addressCountry === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.addressCountry === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Kraj:</Text>
    </ReasonChecklistRow>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.bankNumber === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.bankNumber === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Numer konta bankowego:</Text>
    </ReasonChecklistRow>

    <ReasonChecklistRow>
      <Checkmark>
        {notification.payload.adminFeedback.socialMediaLink === 'on' && (
          <ErrorIcon fill="var(--red)" width="16px" height="16px" />
        )}
        {notification.payload.adminFeedback.socialMediaLink === 'off' && (
          <CompleteIcon fill="var(--brand-green)" width="16px" height="16px" />
        )}
      </Checkmark>
      <Text>Link do Profilu:</Text>
    </ReasonChecklistRow>

    <Text>
      Odrzucono: 
      {fecha.format(new Date(notification.payload.at), 'HH:mm DD/MM/YY')}
    </Text>

    <Text margin="45px 0 0px 0">W razie pytań bądź wątpliwości</Text>
    <HelpText target="_blank" rel="noopener noreferrer" href="https://fb.com/tipplypl">
      <LinkIcon />
      <span>skontaktuj się z nami!</span>
    </HelpText>
  </>
);

export default ProfileRejectedNotification;

ProfileRejectedNotification.propTypes = {
  notification: PropTypes.instanceOf(Object).isRequired,
  isCompany: PropTypes.instanceOf(Object).isRequired,
};
