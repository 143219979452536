import styled, { css } from 'styled-components';

const styles = css`
  width: 100%;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  line-height: 1;

  border: 2px solid var(--border-color);
  border-radius: 30px;
  background-color: var(--white-box-background);
  padding: ${props => (props.padding ? props.padding : '12px 15px')};
  color: var(--text-color-dark-gray);
  outline: none;
  transition: 0.2s ease;
  ${props => (props.margin ? `margin: ${props.margin};` : '')}

  &:hover {
    border-color: ${props =>
      props.hoverBorderColor ? props.hoverBorderColor : 'var(--border-color)'};
  }

  &::placeholder {
    color: var(--text-color-dark-gray);
  }

  &:not([readonly]) {
    &:focus,
    &:active {
      border-color: ${props => (props.hoverBorderColor ? props.hoverBorderColor : 'var(--blue)')};
    }
  }

  &[readonly] {
    cursor: not-allowed;
    user-select: none;
  }
`;

const errorCss = css`
  border-color: var(--error-color) !important;
`;

export { styles };
export default styled.input`
  ${styles}
  ${props => props.error && errorCss}
`;
