import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-rows: 25px;
  align-items: center;
  align-content: right;

  position: absolute;

  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-weight: 500;

  width: 180px;
  height: 45px;

  list-style: none;

  padding: 0 20px;
  margin: 0;
  border-radius: 100px;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;

  transform: translate(0, 275px);
`;
