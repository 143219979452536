import React from 'react';

import ToggleGoals from '../../TipsList/blocks/ToggleGoals';
import StyledToolbar from '../../TipsList/styles/StyledToolbar';

function Toolbar() {
  return (
    <StyledToolbar className="box">
      <h2>Koszyk odrzuconych wiadomości</h2>
      <ToggleGoals />
    </StyledToolbar>
  );
}

export default Toolbar;
