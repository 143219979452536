import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import { isObject } from 'utils/validators';

import FocusedElement from '../FocusedElement';

import DraggableContainer from './styles/DraggableContainer';

import noEditableAlert from '../../lib/noEditableAlert';

import { Provider } from './Context';

class DragWrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    elementName: PropTypes.string.isRequired,
    activeTemplate: PropTypes.instanceOf(Object).isRequired,
    updateElement: PropTypes.instanceOf(Function).isRequired,
    elementOptions: PropTypes.instanceOf(Object).isRequired,
    isFocused: PropTypes.bool,

    isResizable: PropTypes.bool,
    resizeAxis: PropTypes.string,
    resizeMinConstraints: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    isFocused: false,
    isResizable: false,
    resizeAxis: 'both',
    resizeMinConstraints: [null, null],
  };

  constructor(props) {
    super(props);
    const { elementOptions, activeTemplate, isResizable } = this.props;

    let size = {};
    if (isResizable && isObject(elementOptions.size)) {
      size = { ...elementOptions.size };
    }

    this.state = {
      activeTemplateId: activeTemplate.id,
      position: { ...elementOptions.position },
      size,
      snapSize: 12,
      axisX: true,
      axisY: true,
      isDragged: false,
      isResized: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { isDragged } = state;
    const returnedData = {};

    const { position } = props.elementOptions;

    if (!isDragged) {
      if (state.position.x !== position.x || state.position.y !== position.y) {
        returnedData.position = { x: position.x, y: position.y };
      }
      if (state.size !== props.elementOptions.size) {
        returnedData.size = { ...props.elementOptions.size };
      }
    }

    if (state.activeTemplateId !== props.activeTemplate.id) {
      returnedData.position = { x: position.x, y: position.y };
      returnedData.size = { ...props.elementOptions.size };
      returnedData.activeTemplateId = props.activeTemplate.id;
    }

    return returnedData;
  }

  /**
   * Funkcja zwracająca wysokość i szerokość rodzica elementu
   *
   * @param {Node} node
   * @returns {Object}
   */
  getBoundSize = node => {
    const { offsetParent } = node;

    return { width: offsetParent.clientWidth, height: offsetParent.clientHeight };
  };

  /**
   * Funkcja zwracająca wysokość i szerokość elementu
   *
   * @param {Node} node
   * @returns {Object}
   */
  getNodeSize = node => ({ width: node.clientWidth, height: node.clientHeight });

  /**
   * Funkcja aktualizująca wielkość elementu
   *
   * @param {Object} updatedSize
   */
  updateSize = updatedSize => {
    const { activeTemplate } = this.props;
    const { size } = this.state;

    if (!activeTemplate.config.editable) return;

    const newSize = {};
    for (const key in size) {
      if (Object.prototype.hasOwnProperty.call(size, key) && updatedSize[key] !== null) {
        newSize[key] = updatedSize[key];
      }
    }

    this.setState({ size: newSize });
  };

  /**
   * Funkcja uaktualniająca pozycję elementu
   *
   * @param {Node} node
   * @returns {Object}
   */
  updatePosition() {
    const {
      position: { x, y },
    } = this.state;
    const { updateElement, elementName } = this.props;

    this.setState({
      isDragged: false,
    });

    if (['to', 'from', 'percent', 'separator'].includes(elementName)) {
      updateElement({ mode: 'childrenPosition', elementName, currentPosition: { x, y } });
    } else {
      updateElement({ mode: 'position', elementName, currentPosition: { x, y } });
    }
  }

  /**
   * Funkcja obliczająca "przyklejania" się do środka osi X oraz Y
   *
   * @param {Object} position
   * @returns {void}
   */
  calculatePosition(position) {
    const { snapSize, axisX, axisY, isResized } = this.state;
    const { x, y, node } = position;

    if (isResized) {
      return;
    }

    const boundSize = this.getBoundSize(node);
    const nodeSize = this.getNodeSize(node);

    const positions = {
      nodeSizeHalfWidth: nodeSize.width / 2,
      nodeSizeHalfHeight: nodeSize.height / 2,
      xBoundHalfWidth: boundSize.width / 2,
      yBoundHalfWidth: boundSize.height / 2,
    };

    positions.xMiddlePosition = x + positions.nodeSizeHalfWidth;
    positions.yMiddlePosition = y + positions.nodeSizeHalfHeight;

    const stateToUpdate = {
      position: {
        x: !axisY ? positions.xBoundHalfWidth - positions.nodeSizeHalfWidth : x,
        y: !axisX ? positions.yBoundHalfWidth - positions.nodeSizeHalfHeight : y,
      },
    };

    if (
      positions.xMiddlePosition > positions.xBoundHalfWidth - snapSize &&
      positions.xMiddlePosition < positions.xBoundHalfWidth + snapSize
    ) {
      if (axisY) {
        stateToUpdate.axisY = false;
      }
    } else {
      stateToUpdate.axisY = true;
    }

    if (
      positions.yMiddlePosition > positions.yBoundHalfWidth - snapSize &&
      positions.yMiddlePosition < positions.yBoundHalfWidth + snapSize
    ) {
      if (axisX) {
        stateToUpdate.axisX = false;
      }
    } else {
      stateToUpdate.axisX = true;
    }

    this.setState(stateToUpdate);
  }

  /**
   * Funkcja aktualizująca stan komponentu gdy element jest skalowany.
   *
   * @param {boolean} isResized
   */
  handleResizeState(isResized) {
    const { updateElement } = this.props;

    this.setState({ isResized });

    if (!isResized) {
      const { size } = this.state;
      const { elementName } = this.props;

      updateElement({ [elementName]: { size } });
    }
  }

  /**
   * Funkcja oplatająca wszystkie dzieci w komponent do zmiany wielkości elementu
   */
  renderAsResizable() {
    const { children, activeTemplate, resizeMinConstraints, resizeAxis } = this.props;
    const { size } = this.state;

    return (
      <FocusedElement bigFocus>
        <ResizableBox
          width={size.width || 0}
          height={size.height || Infinity}
          minConstraints={resizeMinConstraints}
          axis={activeTemplate.config.editable ? resizeAxis : 'none'}
          onResizeStart={() => this.handleResizeState(true)}
          onResizeStop={() => this.handleResizeState(false)}
          onResize={(evt, { size: updatedSize }) => this.updateSize(updatedSize)}
        >
          {children}
        </ResizableBox>
      </FocusedElement>
    );
  }

  render() {
    const {
      children,
      elementOptions,
      activeTemplate,
      isFocused,
      isResizable,
      elementName,
      ...props
    } = this.props;
    const { position, size, isResized, isDragged } = this.state;

    let draggableStyles = { zIndex: elementOptions.styles ? elementOptions.styles.zIndex : {} };
    if (size) {
      draggableStyles = { ...draggableStyles, ...size };
    }

    const elementOffsets = {
      from: 0,
      separator: 170,
      to: 200,
      percent: 350,
    };
    if (Object.keys(elementOffsets).includes(elementName) && !isDragged) {
      if (elementOptions.children[elementName].position) {
        position.x = elementOptions.children[elementName].position.x;
        position.y = elementOptions.children[elementName].position.y;
      } else {
        position.x += elementOffsets[elementName];
      }
    }

    return (
      <Draggable
        onStop={() => {
          if (activeTemplate.config.editable) {
            this.updatePosition();
          } else {
            noEditableAlert();
          }
        }}
        onDrag={(e, dragPosition) => {
          if (activeTemplate.config.editable) {
            this.calculatePosition(dragPosition);
          }
        }}
        onMouseDown={() => this.setState({ isDragged: true })}
        position={position}
        axis="none"
      >
        <DraggableContainer
          {...props}
          isFocused={isFocused || isDragged || isResized}
          bigFocus={isResizable}
          style={{ ...draggableStyles }}
          tabIndex="0"
        >
          <Provider
            value={{
              handleResizeState: state => {
                if (activeTemplate.config.editable) {
                  this.handleResizeState(state);
                }
              },
              updateSize: this.updateSize,
              size,
            }}
          >
            {isResizable ? this.renderAsResizable() : children}
          </Provider>
        </DraggableContainer>
      </Draggable>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { created, defaults, active } = state.templates;

  const defaultTemplates = created.filter(item => item.type === ownProps.configuratorType);
  const createdTemplates = defaults.filter(item => item.type === ownProps.configuratorType);
  const allTemplates = [...defaultTemplates, ...createdTemplates];

  const activeTemplateId = active[ownProps.configuratorType];
  const activeTemplate = allTemplates.find(template => activeTemplateId === template.id);

  return {
    activeTemplate,
  };
};

export default connect(
  mapStateToProps,
  {},
)(DragWrapper);
