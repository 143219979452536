function PaymentHumanName(paymentType) {
  let result;
  switch (paymentType) {
    case 'cashbill_blik':
      result = 'BLIK';
      break;
    case 'cashbill':
      result = 'Przelew';
      break;
    case 'paypal':
      result = 'PayPal';
      break;
    case 'cashbill_credit_card':
      result = 'Karty płatnicze';
      break;
    case 'gt_psc':
      result = 'PaySafeCard';
      break;
    case 'justpay':
      result = 'SMS Plus';
      break;
    case 'justpay_classic':
      result = 'SMS';
      break;
    case 'justpay_full':
      result = 'SMS FULL';
      break;
    default:
      result = paymentType;
  }
  return result;
}

export default PaymentHumanName;
