import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TrashBinIcon } from 'assets/icons/redbin.svg';
import { StyledButton } from './ElementActionButton';

const DeleteButton = ({ onClick, ...props }) => (
  <StyledButton
    style={{
      backgroundColor: 'rgba(255, 89, 89, 0.2); ',
      borderColor: 'var(--red)',
      color: 'var(--red)',
      ':hover': {
        background: 'rgba(255, 89, 89, 0.5)',
      },
    }}
    onClick={onClick}
    {...props}
  >
    <TrashBinIcon />
    Usuń
  </StyledButton>
);

DeleteButton.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
};

export default DeleteButton;
