import styled from 'styled-components';

export default styled.img`
  display: block;

  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid var(--border-color);

  margin: 0 15px 0 0;
`;
