import styled from 'styled-components';
import DefaultTemplates from './DefaultTemplates';

export default styled(DefaultTemplates)`
  flex-grow: 1;
  margin-right: -16px;
  margin-left: -16px;
  display: grid;
  position: relative;
`;
