export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_WEBFONTS':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'RECEIVE_WEBFONTS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        // Sortujemy alfabetycznie wszystkie webfonty
        // items: [...state.items, ...action.recievedData].sort((a, b) =>
        //   a.family.localeCompare(b.family),
        // ),
        items: [...state.items, ...action.recievedData],
      };
    case 'ALL_FONTS_WEBFONTS':
      return {
        ...state,
        items: action.allFontsData,
      };
    case 'FAILURE_WEBFONTS':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    default:
      return state;
  }
};
