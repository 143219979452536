import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestCommissions = () => ({
  type: 'REQUEST_COMMISSIONS',
});

const receiveCommissions = commissions => ({
  type: 'RECEIVE_COMMISSIONS',
  recievedData: commissions,
});

const failureCommissions = () => ({
  type: 'FAILURE_COMMISSIONS',
});

const fetchCommissions = () => async dispatch => {
  dispatch(requestCommissions());

  try {
    const commissions = await http.get('/commissions');

    dispatch(receiveCommissions(commissions.data));
  } catch (error) {
    failureCommissions();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchCommissionsIfNeeded = () => (dispatch, getState) => {
  const { commissions } = getState();

  if (shouldFetchData(commissions)) {
    dispatch(fetchCommissions());
  }
};
