import React, { Fragment } from 'react';
import AbstractElements from 'pages/Configurators/shared/AbstractElements';
import List from '../List';
import { Provider } from './Context';

export default class Elements extends AbstractElements {
  render() {
    const {
      createHistoryElement,
      activeTemplate,
      configuratorWrapper,
      configuratorType,
    } = this.props;
    const { elementsOptions } = createHistoryElement();

    const elementsMap = {
      list: List,
    };

    return (
      <Provider
        value={{
          updateElement: this.updateElement,
          toggleTextEditor: (elementName, e, mode) => this.toggleTextEditor(elementName, e, mode),
          getElementOptions: elementName => this.getElementOptions(elementName),
          state: this.state,
          activeTemplate,
        }}
      >
        {Object.keys(elementsOptions).map(elementName => {
          const options = elementsOptions[elementName];
          const Element = elementsMap[elementName];

          return (
            <Fragment key={elementName}>
              <Element
                options={options}
                name={elementName}
                updateElement={this.updateElement}
                activeTemplate={activeTemplate}
                configuratorWrapper={configuratorWrapper}
                configuratorType={configuratorType}
                onKeyDown={this.handleKeyDownElement}
              />
            </Fragment>
          );
        })}
      </Provider>
    );
  }
}
