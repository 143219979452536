import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { TextEditor } from 'components/ElementEditor';

import Shadow from 'pages/Configurators/styles/Shadow';

import DragWrapper from '../DragWrapper';
import FocusedElement from '../FocusedElement';
import ContextMenuElement from '../ContextMenuElement';

const Text = ({
  activeTemplate,
  options,
  contextMenuOptions,
  updateElement,
  configuratorWrapper,
  configuratorType,
  forcedTextValue,
  name,
  onKeyDown,
  shadow,
}) => {
  const [mountedTextEditor, setMountedTextEditor] = useState({
    mounted: false,
    mode: 'style',
  });
  const elementRef = useRef();
  const text = forcedTextValue || options.textValue;

  return (
    <>
      <DragWrapper
        updateElement={updateElement}
        elementOptions={options}
        elementName={name}
        configuratorType={configuratorType}
        onKeyDown={event => onKeyDown(event, name)}
      >
        <FocusedElement style={{ textAlign: options.styles.textAlign }}>
          {shadow && <Shadow style={{ ...options.styles }}>{shadow}</Shadow>}

          <ContextMenuElement
            id={`${name}-element-list`}
            elementName={name}
            options={contextMenuOptions}
            isEditable={activeTemplate.config.editable}
            zIndex={Number.parseInt(options.styles.zIndex, 10)}
            onSelectedOption={(option, config) => {
              if (option === 'editText') {
                setMountedTextEditor({ mounted: true, mode: 'style' });
              } else if (option === 'changeText') {
                setMountedTextEditor({ mounted: true, mode: 'text' });
              } else if (option === 'delete') {
                updateElement({ [name]: { isVisible: false } });
              } else if (option === 'zIndex') {
                updateElement(config);
              }
            }}
          >
            <p
              ref={elementRef}
              style={{
                margin: 0,
                ...options.styles,
                backfaceVisibility: 'hidden',
                display: 'inline-block',
              }}
            >
              {text || (
                <span style={{ fontStyle: 'italic', textDecoration: 'underline', fontWeight: 300 }}>
                  Brak tekstu
                </span>
              )}
            </p>
          </ContextMenuElement>
        </FocusedElement>
      </DragWrapper>

      <TextEditor
        isMounted={mountedTextEditor.mounted}
        snapTo={elementRef.current}
        defaultStyle={options.styles}
        onChange={styles => {
          updateElement({ [name]: { styles } });
        }}
        onChangeText={changedText => {
          updateElement({ [name]: { textValue: changedText } });
        }}
        onHide={() => {
          setMountedTextEditor({ mounted: false, mode: mountedTextEditor.mode });
        }}
        showIn={configuratorWrapper.current}
        mode={mountedTextEditor.mode}
        text={forcedTextValue || options.textValue || ''}
      />
    </>
  );
};

Text.propTypes = {
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
  contextMenuOptions: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  configuratorWrapper: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  onKeyDown: PropTypes.instanceOf(Function).isRequired,

  configuratorType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  forcedTextValue: PropTypes.string,
  shadow: PropTypes.string,
};

Text.defaultProps = {
  forcedTextValue: '',
  shadow: null,
};

export default Text;
