import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

import Wrapper from './components/Wrapper';
import Value from './components/Value';

export const RangeSlider = ({ alternate, value, valueDivide, valuePrefix, position, ...props }) => (
  <Wrapper position={position} alternate={alternate}>
    <Slider {...props} value={value} defaultValue={value} />
    <Value alternate={alternate}>{`${value / valueDivide}${valuePrefix}`}</Value>
  </Wrapper>
);

RangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  valuePrefix: PropTypes.string,
  position: PropTypes.string,
  alternate: PropTypes.bool, // new range slider styles, temporary property
  valueDivide: PropTypes.number,
};

RangeSlider.defaultProps = {
  valuePrefix: '%',
  position: 'vertical',
  alternate: false,
  valueDivide: 1,
};
