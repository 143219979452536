import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_8.gif';
import { TutorialImage } from 'styles/TutorialImages';
import StepContent from '../../components/StepContent';
import { BackendError } from '../../components/Form';
import { useConnectFom } from '../../hooks';

const StepForm = ({ processStep, goPrev }) => {
  const provider = 'tipeee';
  const fields = {
    apiKey: 'Bez klucza API nie będziemy mogli odbierać wiadomości z TipeeeStream',
    username: 'Potrzebujemy Twojej nazwy użytkownika, żeby poprawni zainicjować płatność',
  };

  const { disabled, backendError, renderField, validate, prev } = useConnectFom(
    provider,
    fields,
    processStep,
    goPrev,
  );

  return (
    <StepContent
      provider={provider}
      processStep={validate}
      goPrev={prev}
      processButtonLabel="Połącz konto"
      disabled={disabled}
    >
      <Text lineHeight={1.58} align="center" mb={20}>
        Super, wpisz proszę poniżej, swoją nazwę użytkownika w TipeeeStream, a więc fragment Twojej
        nazwy z skopiowanego linku. Uwaga: gdy podasz błędny, płatności nie będą możliwe.
      </Text>
      {renderField('apiKey', 'Klucz API')}
      {renderField('username', 'Nazwa użytkownika')}
      {backendError && <BackendError>Coś się pokićkało :( Spróbuj ponownie później</BackendError>}
      <Text lineHeight={1.58} align="center" mb={20}>
        Musisz jeszcze wpisać klucz API. Przechodząc do zakładki &quot;Developers&quot;, która
        pokaże się po kliknięciu profilówki, w prawym górnym rogu znajdziesz klucz. Wpisz powyżej
        skopiowany klucz API.
      </Text>
      <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
    </StepContent>
  );
};

export default StepForm;

StepForm.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
