export default (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_TEMPLATES':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_TEMPLATES':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        created: action.templates,
      };
    case 'CHANGE_ACTIVE_TEMPLATE':
      return {
        ...state,
        active: {
          ...state.active,
          [action.templateType]: action.templateId,
        },
        currentTemplateId: action.templateId,
        currentTemplateType: action.templateType,
      };
    case 'SET_CURRENT_ELEMENT':
      return {
        ...state,
        currentElementName: action.name,
      };
    case 'CREATE_TEMPLATE':
      return {
        ...state,
        isCreating: true,
      };
    case 'CANCEL_CREATE_TEMPLATE':
      return {
        ...state,
        isCreating: false,
      };
    case 'INSERT_TEMPLATE':
      return {
        ...state,
        created: [...state.created, action.template],
        isCreating: false,
      };
    case 'UPDATE_TEMPLATE':
      return {
        ...state,
        created: state.created.map(template =>
          template.id === action.id ? action.updatedData : template,
        ),
      };
    case 'DELETE_TEMPLATE':
      return {
        ...state,
        created: state.created.filter(template => template.id !== action.id),
      };
    case 'FAILURE_TEMPLATES':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    default:
      return state;
  }
};
