import React, { useEffect, useState } from 'react';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import '@tipply/animate.css';

import { getBackgroundFromLocalStorage } from 'store/actions/configuratorsBackground';
import { fetchConfiguratorsIfNeeded } from 'store/actions/configuratorsConfigs';
import { fetchTemplatesIfNeeded, setCustomFont } from 'store/actions/templates';
import MediaManager from 'components/MediaManager';
import NotificationsContext from './NotificationsContext';

import { toggleShowFalse } from '../../store/actions/mediaManager';
import { fetchGoalsIfNeeded } from '../../store/actions/goals';

import LayoutGrid from './styles/LayoutGrid';
import {
  Container as PagesSwitcherContainer,
  Item as PagesSwitcherItem,
} from './components/PagesSwitcher';
import ButtonsToolbar from './views/TipAlert/components/DisplaySettings/components/ButtonsToolbar';
import TipAlert from './views/TipAlert/TipAlert';
import LargestDonates from './views/LargestDonates';
import LatestDonates from './views/LatestDonates';
import TipsGoal from './views/TipsGoal';
import GoalVoting from './views/GoalVoting';

import CounterToEndLive from './views/CounterToEndLive';

const configuratorsList = [
  {
    path: 'powiadomienie-o-wiadomosci',
    title: 'Powiadomienie o wiadomości',
    component: TipAlert,
  },
  {
    path: 'ranking-wiadomosci',
    title: 'Ranking wiadomości',
    component: LargestDonates,
  },
  {
    path: 'lista-ostatnich-wiadomosci',
    title: 'Lista ostatnich wiadomości',
    component: LatestDonates,
  },
  {
    path: 'twoj-cel',
    title: 'Twój cel',
    component: TipsGoal,
  },
  {
    path: 'odlicznik-czasu-do-konca-transmisji',
    title: 'Odlicznik czasu do końca transmisji',
    component: CounterToEndLive,
  },
  {
    path: 'glosowania',
    title: 'Głosowanie',
    component: GoalVoting,
  },
  {
    soon: true,
    path: 'licznik-polubien',
    title: 'Wkrótce',
    component: () => <p>Licznik polubień</p>,
  },
];

const configuratorsPaths = [];

configuratorsList.forEach(item => configuratorsPaths.push(item.path));

const Configurators = ({ match, history }) => {
  const [currentNotification, setCurrentNotification] = useState('');
  const showFontSelector = useSelector(state => state.mediaManager.showManager);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplatesIfNeeded());
    dispatch(fetchConfiguratorsIfNeeded());
    dispatch(getBackgroundFromLocalStorage());
    dispatch(fetchGoalsIfNeeded());

    if (!configuratorsPaths.includes(match.params.configurator)) {
      history.push('/404');
    }
  }, []);

  return (
    <NotificationsContext.Provider value={{ currentNotification, setCurrentNotification }}>
      <PagesSwitcherContainer>
        {configuratorsList.map(item => (
          <PagesSwitcherItem key={item.path}>
            <NavLink
              activeClassName="active"
              to={`/konfigurator/${item.path}`}
              onClick={e => {
                if (item.soon) {
                  e.preventDefault();
                }
              }}
            >
              <span>{item.title}</span>
            </NavLink>
          </PagesSwitcherItem>
        ))}
      </PagesSwitcherContainer>

      <Route path="/konfigurator/powiadomienie-o-wiadomosci" component={ButtonsToolbar} />

      <LayoutGrid>
        <Switch>
          {configuratorsList.map(item => {
            const ConfiguratorPage = item.component;
            return (
              <Route key={item.path} exact path={`/konfigurator/${item.path}`}>
                <ConfiguratorPage />
              </Route>
            );
          })}
        </Switch>
      </LayoutGrid>

      <MediaManager
        allowedTypes={['font']}
        isMounted={showFontSelector}
        onClose={() => {
          dispatch(toggleShowFalse());
        }}
        onSelectedItem={fontItem => {
          dispatch(setCustomFont(fontItem));
          dispatch(toggleShowFalse());
        }}
      />
    </NotificationsContext.Provider>
  );
};

Configurators.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Configurators);
