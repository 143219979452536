const templates = [
  {
    title: 'Domyślny szablon #1',
    options: {
      _v: 'v1.86',
      align: 'flex-start',
      reverse: false,
      showTte: true,
      showGoals: true,
      showLegend: true,
      showValues: true,
      legendAlign: 'flex-start',
      barAnimation: true,
      borderRadius: 100,
      showPercents: true,
      biggerPercentage: true,
    },
    editable: false,
  },
  {
    title: 'Domyślny szablon #2',
    options: {
      _v: 'v1.86',
      align: 'center',
      reverse: false,
      showTte: true,
      showGoals: true,
      showLegend: true,
      showValues: true,
      legendAlign: 'center',
      barAnimation: true,
      borderRadius: 100,
      showPercents: true,
      biggerPercentage: true,
    },
    editable: false,
  },
  {
    title: 'Domyślny szablon #3',
    options: {
      _v: 'v1.86',
      align: 'flex-start',
      reverse: true,
      showTte: true,
      showGoals: true,
      showLegend: true,
      showValues: true,
      legendAlign: 'flex-start',
      barAnimation: true,
      borderRadius: 100,
      showPercents: true,
      biggerPercentage: false,
    },
    editable: false,
  },
  {
    title: 'Domyślny szablon #4',
    options: {
      _v: 'v1.86',
      align: 'center',
      reverse: false,
      showTte: true,
      showGoals: true,
      showLegend: false,
      showValues: true,
      legendAlign: 'center',
      barAnimation: true,
      borderRadius: 33,
      showPercents: true,
      biggerPercentage: true,
    },
    editable: false,
  },
  {
    title: 'Domyślny szablon #5',
    options: {
      _v: 'v1.86',
      align: 'flex-start',
      reverse: true,
      showTte: true,
      showGoals: true,
      showLegend: true,
      showValues: true,
      legendAlign: 'flex-start',
      barAnimation: true,
      borderRadius: 69,
      showPercents: true,
      biggerPercentage: false,
    },
    editable: false,
  },
  {
    title: 'Domyślny szablon #6',
    options: {
      _v: 'v1.86',
      align: 'flex-end',
      reverse: false,
      showTte: false,
      showGoals: true,
      showLegend: true,
      showValues: true,
      legendAlign: 'flex-end',
      barAnimation: true,
      borderRadius: 69,
      showPercents: true,
      biggerPercentage: true,
    },
    editable: false,
  },
];

export default templates;
