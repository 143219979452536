import http from 'utils/http';
import shouldFetchData from '../utils/shouldFetchData';

const requestInvoices = () => ({
  type: 'REQUEST_INVOICES',
});

const receiveInvoices = receivedData => ({
  type: 'RECEIVE_INVOICES',
  receivedData,
});

const failureInvoices = () => ({
  type: 'FAILURE_INVOICES',
});

const fetchInvoices = () => async dispatch => {
  dispatch(requestInvoices());

  try {
    const reports = await http.get('/user/invoices');
    dispatch(receiveInvoices(reports.data));
  } catch (error) {
    dispatch(failureInvoices());
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchInvoicesIfNeeded = () => (dispatch, getState) => {
  const { invoices } = getState();

  if (shouldFetchData(invoices)) {
    dispatch(fetchInvoices());
  }
};
