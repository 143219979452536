import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTemplates } from 'store/selectors/templates';
import Loading from 'components/Loading';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  ${props =>
    props.$active &&
    css`
      z-index: 9;
      cursor: pointer;
    `}

  .loading-container {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: wait;
    transition: 0.2s ease;
    transition-property: opacity, visibility;
  }

  ${props =>
    props.$loading &&
    css`
      .loading-container {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    `}
`;

export const ScreenActionCatcher = ({ templateEditable, onClick, onContextMenu }) => {
  const { isCreating } = useTemplates();

  return (
    <Container
      $loading={isCreating}
      $active={!templateEditable}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <Loading />
    </Container>
  );
};

ScreenActionCatcher.propTypes = {
  templateEditable: PropTypes.bool.isRequired,
  onClick: PropTypes.instanceOf(Function),
  onContextMenu: PropTypes.instanceOf(Function),
};

ScreenActionCatcher.defaultProps = {
  onClick: () => null,
  onContextMenu: () => null,
};
