import React from 'react';

import RootModal from 'components/Modal';
import Announcements from './Announcements';

const Modal = props => (
  <RootModal title="Wszystkie ogłoszenia" width={600} height="auto" {...props}>
    <Announcements display="all" />
  </RootModal>
);

export default Modal;
