import { css } from 'styled-components';

export const style = css`
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;

  position: absolute;
  left: 50%;
  top: 100%;

  font-size: 12px;
  color: #fff;

  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  transform: translateX(-50%);
  border-radius: 5px;

  min-width: 80px;
  text-align: center;
`;

export const hover = css`
  opacity: 1;
  visibility: visible;
`;
