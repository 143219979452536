import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxWithBackground } from 'components/Checkboxes';
import GrayContainer from 'styles/buttons/GrayContainer';

import Play from './components/Play';
import Stop from './components/Stop';
import Button from './components/Button';

const AnimationManager = props => {
  const {
    onChangeAutoplay,
    onClickControl,
    isAnimated,
    autoplayValue,
    autoplayPanel,
    disabled,
  } = props;

  return (
    <>
      <GrayContainer>
        <Button
          disabled={isAnimated || disabled}
          onClick={() => onClickControl(true)}
          type="button"
          background="white"
        >
          <Play />
        </Button>

        <Button
          disabled={!isAnimated || disabled}
          onClick={() => onClickControl(false)}
          type="button"
          background="white"
        >
          <Stop />
        </Button>
      </GrayContainer>

      {autoplayPanel && (
        <CheckboxWithBackground
          text="Autoodtwarzanie"
          name="autoplay-animation"
          checked={autoplayValue}
          onCheck={value => onChangeAutoplay(value)}
        />
      )}
    </>
  );
};

AnimationManager.propTypes = {
  onClickControl: PropTypes.instanceOf(Function).isRequired,
  onChangeAutoplay: PropTypes.instanceOf(Function),
  isAnimated: PropTypes.bool.isRequired,
  autoplayValue: PropTypes.bool,
  autoplayPanel: PropTypes.bool,
  disabled: PropTypes.bool,
};

AnimationManager.defaultProps = {
  onChangeAutoplay: () => {},
  autoplayValue: false,
  autoplayPanel: false,
  disabled: false,
};

export default AnimationManager;
