import styled from 'styled-components';

const StepWrapper = styled.div`
  font-family: var(--google-sora-cdn);

  .logo-container {
    margin-bottom: 45px;

    svg {
      width: 102px;
      height: 36px;
    }
  }

  .button-container {
    margin-top: 45px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  p {
    margin: 0 0 1.8em;
    font-size: 13px;
    max-width: 310px;

    &.step-title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 33px;
    }

    a {
      color: #4d9feb;
    }
  }
`;

export default StepWrapper;
