import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import TextElement from '../../../../../components/Elements/Text';

import { Consumer } from '../../../Context';

const animation = keyframes`
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  40% {
    opacity: 0.5;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateY(-250%);
  }
`;

const AnimatedPrice = styled.span`
  position: absolute;
  opacity: 0;
  transform: translateY(0%);

  animation: ${animation} 1s ease;
  animation-fill-mode: forwards;

  * {
    position: unset;
    transform: none !important;
  }
`;

const AdditionalTime = props => (
  <Consumer>
    {({ handleAnimationState, isAnimated, autoplayAnimation }) =>
      isAnimated ? (
        <AnimatedPrice
          onAnimationEnd={() => {
            if (!autoplayAnimation) {
              handleAnimationState(false);
            }
          }}
          style={{
            ...props.options.styles,
            top: props.options.position.y,
            left: props.options.position.x,
            animationIterationCount: autoplayAnimation ? 'infinite' : null,
          }}
        >
          <TextElement {...props} shadow="+10 min" contextMenuOptions={['editText', 'delete']} />
        </AnimatedPrice>
      ) : (
        <>
          <TextElement {...props} shadow="+10 min" contextMenuOptions={['editText', 'delete']} />
        </>
      )
    }
  </Consumer>
);

AdditionalTime.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
};

export default AdditionalTime;
