import React from 'react';
import PropTypes from 'prop-types';
import gifSEImage from 'assets/images/StreamElementsTutorial/gif_SE_step_7.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../components/StepContent';
import { BackendError } from '../../components/Form';
import { useConnectFom } from '../../hooks';

const StepForm = ({ processStep, goPrev }) => {
  const provider = 'stream_elements';
  const fields = {
    apiKey: 'Bez tokena JWT nie możemy dokończyć integracji',
    tipPageUrl: 'Potrzebujemy adresu strony donacji, aby poprawnie inicjować płatność',
  };

  const { disabled, backendError, renderField, validate, prev } = useConnectFom(
    provider,
    fields,
    processStep,
    goPrev,
  );

  return (
    <StepContent
      provider={provider}
      processStep={validate}
      goPrev={prev}
      processButtonLabel="Połącz konto"
      disabled={disabled}
    >
      <Text lineHeight={1.58} align="center" mb={20}>
        Świetnie Ci poszło! Teraz, możemy wkleić skopiowany link do płatności:
      </Text>
      {renderField('tipPageUrl', 'Adres strony donacji')}
      {renderField('apiKey', 'JWT Token')}
      {backendError && <BackendError>Coś się pokićkało :( Spróbuj ponownie później</BackendError>}
      <Text lineHeight={1.58} align="center" mb={20}>
        Dodatkowo, musimy wpisać też JWT token, który jest potrzebny do integracji płatności. Spójrz
        na instrukcję poniżej i szukaj klucza JWT zgodnie z instrukcją. Ważnym jest, aby skopiować
        cały klucz i nic nie uciąć.
      </Text>
      <TutorialImage style={{ marginBottom: 32 }} src={gifSEImage} />
    </StepContent>
  );
};

export default StepForm;

StepForm.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
