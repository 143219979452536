/**
 * Funkcja konwertująca złotówki do groszy
 *
 * @param {number} price
 * @returns {number}
 */
export const convertToGrosze = price => price * 100;

/**
 * Funkcja konwertująca grosze do złotówek
 *
 * @param {number} price
 * @returns {number}
 */
export const convertToZlotys = price => price / 100;
