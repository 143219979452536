import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from 'store/actions/userData';
import * as paymentMethodsConfigurationActions from 'store/actions/paymentMethodsConfiguration';
import PaymentMethods from './PaymentMethods';

const mapStateToProps = ({ userData, paymentMethodsConfiguration }) => ({
  paymentMethods: userData.paymentMethods,
  profile: userData.profile,
  paymentMethodsConfiguration: paymentMethodsConfiguration.paymentMethods,
  userDataInfo: userData.info,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...userActions, ...paymentMethodsConfigurationActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethods);
