import PropTypes from 'prop-types';
import React, { Component } from 'react';
import socket from 'utils/socket';
import TipsModerationList from './blocks/TipsModerationList';
import Toolbar from './blocks/Toolbar';
import { Provider } from './Context';
import StyledTipsList from '../TipsList/styles/StyledTipsList';
import AudioClipOn from '../../assets/defaultMedia/audios/SoundOn.mp3';

export default class ModeratorBasketPage extends Component {
  static propTypes = {
    showGoals: PropTypes.bool.isRequired,
    approveTipModeration: PropTypes.instanceOf(Function).isRequired,
    rejectTipModeration: PropTypes.instanceOf(Function).isRequired,
    tipsModerationBasket: PropTypes.instanceOf(Object).isRequired,
    restoreModerationPanel: PropTypes.instanceOf(Function).isRequired,
    tipsModeration: PropTypes.instanceOf(Object).isRequired,
    receivedTip: PropTypes.instanceOf(Function).isRequired,
    receivedTipModeration: PropTypes.instanceOf(Function).isRequired,
    refreshTipsModerationListOnTip: PropTypes.instanceOf(Function).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      mute: false,
      tipSoundOn: new Audio(AudioClipOn),
    };

    this.socket = socket(this.receiveTip, this.receiveTipModeration);
  }

  componentDidMount() {
    this.socket.on();

    document.title = 'Tipply | Panel moderatora | Koszyk';
  }

  componentWillUnmount() {
    this.socket.off();
  }

  receiveTip = json => {
    const { receivedTip, tipsModeration, refreshTipsModerationListOnTip } = this.props;
    const { tipSoundOn, mute } = this.state;
    const parsed = JSON.parse(json);
    if (!parsed.resent && !parsed.test) {
      const filterResult = tipsModeration.filter(item => item.payment_id !== parsed.payment_id);
      if (filterResult !== tipsModeration) {
        refreshTipsModerationListOnTip(filterResult);
      }
      receivedTip(parsed);
      this.forceUpdate();
      if (!mute) {
        tipSoundOn.play();
      }
    }
  };

  receiveTipModeration = json => {
    const { receivedTipModeration } = this.props;
    const { tipSoundOn, mute } = this.state;
    const parsed = JSON.parse(json);
    if (!parsed.resent && !parsed.test) {
      receivedTipModeration(parsed);
      this.forceUpdate();
      if (!mute) {
        tipSoundOn.play();
      }
    }
  };

  toggleMute() {
    const { mute } = this.state;

    this.setState({ mute: mute === false });
  }

  render() {
    const {
      showGoals,
      approveTipModeration,
      rejectTipModeration,
      restoreModerationPanel,
    } = this.props;

    const { tipsModerationBasket } = this.props;

    return (
      <Provider>
        <StyledTipsList className="app-container tips ">
          <Toolbar />
          <TipsModerationList
            tipsModeration={tipsModerationBasket}
            showGoals={showGoals}
            approveTipModeration={approveTipModeration}
            rejectTipModeration={rejectTipModeration}
            restoreModerationPanel={restoreModerationPanel}
          />
        </StyledTipsList>
      </Provider>
    );
  }
}
