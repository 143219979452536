export default (state = [], action) => {
  switch (action.type) {
    case 'SHOW_GOALS_TRUE': {
      return {
        ...state,
        showGoals: true,
      };
    }
    case 'SHOW_GOALS_FALSE': {
      return {
        ...state,
        showGoals: false,
      };
    }
    case 'PLAY_AUDIO_TRUE': {
      return {
        ...state,
        playAudio: true,
      };
    }
    case 'PLAY_AUDIO_FALSE': {
      return {
        ...state,
        playAudio: false,
      };
    }
    default:
      return state;
  }
};
