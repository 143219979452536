import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ArrowInCircleButton } from 'components/Buttons';

import FloatingMenu from 'components/FloatingMenu';
import Range from './components/Range';

import noEditableAlert from '../../lib/noEditableAlert';
import Box from '../../styles/Box';

const Wrapper = styled.div`
  position: relative;
`;

export default class RangePopup extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.instanceOf(Function),
    onAfterChange: PropTypes.instanceOf(Function),
    isEditable: PropTypes.bool.isRequired,
    activeTemplateId: PropTypes.string.isRequired,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    whiteColorMinValue: PropTypes.number.isRequired,
    whiteColorMaxValue: PropTypes.number.isRequired,
  };

  static defaultProps = {
    minValue: 0,
    maxValue: 100,
    onChange: value => {},
    onAfterChange: value => {},
  };

  constructor(props) {
    super(props);

    const { value, activeTemplateId } = props;

    this.node = React.createRef();

    this.state = {
      showPopup: false,
      count: value,
      activeTemplateId,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const returnedState = {};

    if (props.activeTemplateId !== state.activeTemplateId) {
      returnedState.count = props.value;
      returnedState.activeTemplateId = props.activeTemplateId;
    }

    if (!props.isEditable) {
      returnedState.showPopup = false;
    }

    return returnedState;
  }

  /**
   * Funkcja zarzązająca widocznością popupu
   *
   * @param {Boolean} forceValue - wartość która wymusza stan
   * @returns {void}
   */
  setVisible(forceValue) {
    const { isEditable } = this.props;

    if (!isEditable) {
      noEditableAlert();
      return;
    }

    this.setState(prevState => {
      const value = forceValue !== undefined ? forceValue : !prevState.showPopup;

      return {
        showPopup: value,
      };
    });
  }

  setCount(value) {
    this.setState({ count: value });
  }

  render() {
    const { showPopup, count } = this.state;
    const {
      text,
      onChange,
      onAfterChange,
      minValue,
      maxValue,
      whiteColorMinValue,
      whiteColorMaxValue,
    } = this.props;

    return (
      <Wrapper ref={this.node}>
        <Box style={{ paddingTop: 12.5, paddingBottom: 12.5 }}>
          <span>{text}</span>

          <ArrowInCircleButton background="green" onClick={() => this.setVisible()} />
        </Box>

        <FloatingMenu
          visible={showPopup}
          position="right"
          onClose={() => this.setVisible(false)}
          parent={this.node.current || document.body}
          style={{ minWidth: 60 }}
        >
          <Range
            vertical
            value={count}
            min={minValue}
            max={maxValue}
            whiteColorMinValue={whiteColorMinValue}
            whiteColorMaxValue={whiteColorMaxValue}
            onChange={value => {
              this.setCount(value);
              onChange(value);
            }}
            onAfterChange={value => onAfterChange(value)}
          />
        </FloatingMenu>
      </Wrapper>
    );
  }
}
