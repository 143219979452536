import React, { useContext } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, EditableInput, Hue, Saturation } from 'react-color/lib/components/common';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down-small.svg';
import { getTextShadowParams, updateParam } from '../../helpers';
import { RangeSlider } from '../../../../../RangeSlider';
import { TextEditor } from '../../Context';

import { AbsoluteLayer, AnimationToggleOption, Option } from '../../styles';
import AlphaPointer from '../Color/components/AlphaPointer';
import AlphaWrapper from '../Color/components/AlphaWrapper';
import HuePointer from '../Color/components/HuePointer';
import HueWrapper from '../Color/components/HueWrapper';
import SaturationPointer from '../Color/components/SaturationPointer';
import SaturationWrapper from '../Color/components/SaturationWrapper';
import ShadowEditorElements from './ShadowEditorElements';

const TYPE = 'textShadow';

const editableInputStyles = {
  input: {
    margin: '0 0 0 60px',
    fontSize: '16px',
    height: '25px',
    lineHeight: '25px',
    border: 'none',
    padding: 0,
    letterSpacing: '0.5px',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'var(--bg-input-color-editor)',
    color: 'var(--text-color)',
  },
  label: {
    display: 'none',
  },
};

function getParam(textShadow, index) {
  const params = getTextShadowParams(textShadow);
  return params[index];
}

const ShadowEditor = CustomPicker(({ onChange, ...props }) => {
  const { hex, rgb } = props;
  const {
    style: { textShadow },
    updateStyle,
    isActive,
    isActiveEditor,
  } = useContext(TextEditor);

  let alpha = Math.round(rgb.a * 255).toString(16);
  if (alpha.length < 2) alpha = `0${alpha}`;
  const hexWithAlpha = hex + alpha;

  return (
    <Option {...props} style={{ top: -1 }}>
      <AnimationToggleOption isActive={!!isActiveEditor} delayOut={0.25}>
        <span
          style={{
            width: 14,
            height: 14,
            borderRadius: 7,
            display: 'flex',
            placeContent: 'center',
            placeItems: 'center',
            backgroundColor: `color-mix(in srgb, var(--text-color-white) 50%, transparent)`,
            color: `var(--text-color)`,
          }}
        >
          <ChevronDownIcon style={{ width: 7, height: 4 }} />
        </span>
      </AnimationToggleOption>

      <AbsoluteLayer isActive={isActive(TYPE)}>
        <EditableInput
          {...props}
          onChange={() => {}}
          value={hexWithAlpha}
          label="hex"
          style={editableInputStyles}
        />
      </AbsoluteLayer>

      <ShadowEditorElements isActive={isActive(TYPE)}>
        <div>
          <RangeSlider
            position="horizontal"
            valuePrefix="px"
            value={getParam(textShadow, 4)}
            min={-30}
            max={30}
            onChange={value => {
              updateStyle(TYPE, updateParam(textShadow, value, 4), true);
            }}
          />
        </div>

        <div>
          <RangeSlider
            position="horizontal"
            valuePrefix="px"
            value={getParam(textShadow, 5)}
            min={-30}
            max={30}
            onChange={value => {
              updateStyle(TYPE, updateParam(textShadow, value, 5), true);
            }}
          />
        </div>

        <div>
          <RangeSlider
            position="horizontal"
            valuePrefix="%"
            value={getParam(textShadow, 6)}
            min={0}
            max={30}
            onChange={value => {
              updateStyle(TYPE, updateParam(textShadow, value, 6), true);
            }}
          />
        </div>

        <div>
          <SaturationWrapper>
            <Saturation {...props} onChange={onChange} pointer={SaturationPointer} />
          </SaturationWrapper>
          <HueWrapper>
            <Hue {...props} onChange={onChange} pointer={HuePointer} />
          </HueWrapper>
          <AlphaWrapper>
            <Alpha {...props} onChange={onChange} pointer={AlphaPointer} />
          </AlphaWrapper>
        </div>
      </ShadowEditorElements>
    </Option>
  );
});

export default ShadowEditor;
