import styled from 'styled-components';

export default styled.div`
  --wrapper-width: 135px;
  --wrapper-height: 25px;
  --element-size: var(--wrapper-height);
  --element-margin: 15px;

  --line-width: var(--wrapper-height);
  --line-width-small: 13px;
  --line-height: 3px;

  position: relative;

  width: var(--wrapper-width);
  height: var(--element-size);
`;
