import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import styled from 'styled-components';
import WhiteBox from 'styles/WhiteBox';
import Button from 'components/Button';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';
import { ReactComponent as PercentIcon } from 'assets/icons/percent.svg';

const TopRightSpinner = styled(MaterialSpinner).attrs(() => ({ className: 'spinner' }))`
  --spinner-size: 16px;

  position: absolute;
  right: var(--spinner-size);
  top: var(--spinner-size);
  width: var(--spinner-size);
  height: var(--spinner-size);
  opacity: 0.5;

  .spinner-path {
    stroke: var(--blue) !important;
  }
`;

const Wrapper = styled(WhiteBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 75px 35px 30px;
`;

const BigButton = styled(Button)`
  font-family: var(--font-sora-cdn);
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 50px 15px 40px;
`;

export const Intro = ({ working }) => (
  <Wrapper>
    <div>
      <h3 style={{ fontSize: 25, fontWeight: 700, margin: '0 0 10px' }}>Utwórz swoje cele wpłat</h3>
      <Text noMargin>
        Poinformuj widzów na co zbierasz! Nowy komputer, nowa myszka lub klawiatura?
        <br />
        Widzowie chętnie pomogą Ci w realizacji Twoich marzeń. Skonfiguruj swój cel już teraz!
      </Text>
      {working && <TopRightSpinner />}
    </div>
    <div>
      <Link to="/konfigurator/glosowania">
        <BigButton icon={<PercentIcon />} background="blue">
          Stwórz głosowanie na cele
        </BigButton>
      </Link>
    </div>
  </Wrapper>
);

Intro.propTypes = {
  working: PropTypes.bool.isRequired,
};
