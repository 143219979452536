import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-rows: 25px;
  align-items: center;
  align-content: center;

  background-color: var(--bg-text-editor-color);
  border: 2px solid var(--white-white);

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  width: 350px;
  height: inherit;

  list-style: none;

  padding: 0 20px;
  margin: 0;
  border-radius: 100px;
`;
