import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestAnnouncements = () => ({
  type: 'REQUEST_ANNOUNCEMENTS',
});

const patchAnnouncements = () => ({
  type: 'PATCH_ANNOUNCEMENTS',
});

const receiveAnnouncements = announcements => ({
  type: 'RECEIVE_ANNOUNCEMENTS',
  announcements,
});

const failureAnnouncements = () => ({
  type: 'FAILURE_ANNOUNCEMENTS',
});

const failurePatchAnnouncements = () => ({
  type: 'FAILURE_PATCH_ANNOUNCEMENTS',
});

export const displayMoreInfoModal = () => ({
  type: 'DISPLAY_MORE_INFO_MODAL',
});

export const hideMoreInfoModal = () => ({
  type: 'HIDE_MORE_INFO_MODAL',
});

const fetchAnnouncements = () => dispatch => {
  dispatch(requestAnnouncements());

  return http
    .get('/announcements')
    .then(response => response.data)
    .then(announcements => dispatch(receiveAnnouncements(announcements)))
    .catch(error => failureAnnouncements());
};

export const fetchAnnouncementsIfNeeded = () => (dispatch, getState) => {
  const { announcements } = getState();

  if (shouldFetchData(announcements)) {
    dispatch(fetchAnnouncements());
  }
};

export const markAnnouncementsAsRead = () => (dispatch, getState) => {
  const {
    announcements: { items },
  } = getState();

  const announcementsIds = items.map(announcement => announcement.id);

  dispatch(patchAnnouncements());

  http
    .patch(`/announcements/read?ids=${JSON.stringify(announcementsIds)}`)
    .catch(error => dispatch(failurePatchAnnouncements()))
    .then(() => dispatch(fetchAnnouncements()));
};
