import React from 'react';
import PropTypes from 'prop-types';

import { CustomPicker } from 'react-color';
import { Hue, Saturation, EditableInput, Alpha } from 'react-color/lib/components/common';

import ColorPickerElements from './components/ColorPickerElements';
import SaturationWrapper from './components/SaturationWrapper';
import HueWrapper from './components/HueWrapper';
import SaturationPointer from './components/SaturationPointer';
import HuePointer from './components/HuePointer';
import AlphaPointer from './components/AlphaPointer';
import AlphaWrapper from './components/AlphaWrapper';

import { Option, ColorPreview, AbsoluteLayer } from '../../styles';

const editableInputStyles = {
  input: {
    margin: '0 0 0 60px',
    fontSize: '16px',
    height: '25px',
    lineHeight: '25px',
    border: 'none',
    padding: 0,
    letterSpacing: '0.5px',
    cursor: 'pointer',
    outline: 'none',
    color: 'var(--text-color)',
    background: 'var(--bg-color-text-input)',
  },
  label: {
    display: 'none',
  },
};

const ColorPicker = ({ onChange, ...props }) => {
  const { hex, rgb } = props;

  let alpha = Math.round(rgb.a * 255).toString(16);
  if (alpha.length < 2) alpha = `0${alpha}`;
  const hexWithAlpha = hex + alpha;

  return (
    <Option {...props} style={{ top: -1 }}>
      <ColorPreview style={{ backgroundColor: hexWithAlpha }} />

      <AbsoluteLayer isActive>
        <EditableInput
          {...props}
          onChange={onChange}
          label="hex"
          value={hexWithAlpha}
          style={editableInputStyles}
        />
      </AbsoluteLayer>
      <ColorPickerElements isActive>
        <SaturationWrapper>
          <Saturation {...props} onChange={onChange} pointer={SaturationPointer} />
        </SaturationWrapper>
        <HueWrapper>
          <Hue {...props} onChange={onChange} pointer={HuePointer} />
        </HueWrapper>
        <AlphaWrapper>
          <Alpha {...props} onChange={onChange} pointer={AlphaPointer} />
        </AlphaWrapper>
      </ColorPickerElements>
    </Option>
  );
};

ColorPicker.propTypes = {
  hex: PropTypes.string.isRequired,
  rgb: PropTypes.string.isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
};

export default CustomPicker(ColorPicker);
