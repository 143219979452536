import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { setCurrentElement } from 'store/actions/templates';
import AbstractElements from 'pages/Configurators/shared/AbstractElements';
import { Text, VisualObject } from 'pages/Configurators/components/Elements';

import { AdditionalTime, Time } from './components';
import { Provider } from './Context';

class Elements extends AbstractElements {
  render() {
    const {
      createHistoryElement,
      activeTemplate,
      configuratorWrapper,
      configuratorType,
      setCurrentElementDispatch,
    } = this.props;
    const { elementsOptions } = createHistoryElement();

    const elementsMap = {
      textInput: Time,
      text: Text,
      visualObject: VisualObject,
      additionalTime: AdditionalTime,
    };

    return (
      <Provider
        value={{
          updateElement: this.updateElement,
          toggleTextEditor: (elementName, e, mode) => this.toggleTextEditor(elementName, e, mode),
          getElementOptions: elementName => this.getElementOptions(elementName),
          state: this.state,
          activeTemplate,
        }}
      >
        {Object.keys(elementsOptions).map(elementName => {
          const options = elementsOptions[elementName];
          const Element = elementsMap[elementName];

          if (options.isVisible) {
            return (
              <Fragment key={elementName}>
                <Element
                  options={options}
                  name={elementName}
                  contextMenuOptions={['editText', 'changeText', 'delete']}
                  updateElement={config => {
                    setCurrentElementDispatch(config.elementName);
                    this.updateElement(config);
                  }}
                  activeTemplate={activeTemplate}
                  configuratorWrapper={configuratorWrapper}
                  configuratorType={configuratorType}
                  onKeyDown={this.handleKeyDownElement}
                />
              </Fragment>
            );
          }

          return null;
        })}
      </Provider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentElementDispatch: elementName => dispatch(setCurrentElement(elementName)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Elements);
