import styled, { css } from 'styled-components';

const confirmIn = css`
  opacity: 1;
  transform: scale(1) translateY(-50%);
  left: calc(100% - 59px);
`;

const confirmOut = css`
  opacity: 0;
  transform: scale(0) translateY(-50%);
  left: 10px;
`;

export default styled.button`
  position: absolute;
  z-index: 5;

  width: 52px;
  height: 52px;

  background-color: #0078ff;
  color: #fff;
  border: 0;
  padding: 0;
  border-radius: 100%;
  cursor: pointer;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  opacity: 0;
  transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);

  top: 50%;
  left: ${props => (props.left ? props.left : 10)}px;
  transform: scale(0) translateY(-50%);
  transform-origin: top;
  ${props => (props.isActive ? confirmIn : confirmOut)};
`;
