import PropTypes from 'prop-types';
import React from 'react';

import { GradientText } from 'components/GradientText';

export const GradientLabel = ({ bg }) => (
  <>
    {'Dodaj '}
    <GradientText>gradient</GradientText>
    {` do ${bg ? 'tła' : ''} celu`}
  </>
);

GradientLabel.propTypes = {
  bg: PropTypes.bool,
};

GradientLabel.defaultProps = {
  bg: false,
};
