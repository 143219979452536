import styled from 'styled-components';

export default styled.button`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ active }) => (active ? 'var(--grey)' : 'transparent')};
  box-sizing: border-box;

  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;

  background-color: transparent;
  background-image: url('${props => props.bgImageUrl}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: var(--hover-transition);

  &:hover {
    border-color: var(--brand-blue);
  }

  &:active {
    transform: scale(0.96);
  }

  cursor: pointer;
`;
