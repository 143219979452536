import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import serverAlert from 'store/utils/serverAlert';
import getMimeType from 'utils/getMimeType';
import RootModal from 'components/Modal';
import Loading from 'components/Loading';

import { ALLOWED_TYPES } from './config';
import { Provider } from './Context';

import Item from './components/Item';
import SwitchTypeButton from './components/SwitchTypeButton';
import SpaceUsage from './components/SpaceUsage';
import UploadMedia from './components/UploadMedia';

import { ReactComponent as AudioIcon } from './assets/icons/audio.svg';
import { ReactComponent as ImageIcon } from './assets/icons/image.svg';
import { ReactComponent as VideoIcon } from './assets/icons/video.svg';
import { ReactComponent as FontIcon } from './assets/icons/font.svg';

import Wrapper from './styles/Wrapper';
import Content from './styles/Content';
import DeleteSelected from './styles/DeleteSelected';
import Button from '../../styles/Button';
import StyledCheckbox from './styles/StyledCheckbox';

export default class MediaManager extends Component {
  static propTypes = {
    allowedTypes: PropTypes.instanceOf(Array),
    uploadedMedia: PropTypes.instanceOf(Array).isRequired,
    isMounted: PropTypes.bool.isRequired,
    fetchMediaIfNeeded: PropTypes.instanceOf(Function).isRequired,
    insertMediumInStore: PropTypes.instanceOf(Function).isRequired,
    deleteMedia: PropTypes.instanceOf(Function).isRequired,
    mediaSpaceUsage: PropTypes.number.isRequired,
    mediaAreFetched: PropTypes.bool.isRequired,
    mediaAreFetching: PropTypes.bool.isRequired,

    onSelectedItem: PropTypes.instanceOf(Function),
  };

  static defaultProps = {
    allowedTypes: Object.keys(ALLOWED_TYPES),
    onSelectedItem: medium => {},
  };

  constructor(props) {
    super(props);
    const { allowedTypes } = this.props;

    this.state = {
      view: allowedTypes[0],
      selectionMode: false,
      selectedItems: [],
      deleteLoading: false,
    };
  }

  componentWillMount() {
    const { fetchMediaIfNeeded, isMounted } = this.props;

    if (isMounted) {
      fetchMediaIfNeeded();
    }
  }

  componentDidUpdate() {
    const { fetchMediaIfNeeded, isMounted, mediaAreFetched, mediaAreFetching } = this.props;

    if (isMounted && !mediaAreFetched && !mediaAreFetching) {
      fetchMediaIfNeeded();
    }
  }

  /**
   * Funkcja filtrująca pliki, które mają się wyświetlić
   *
   * @returns {Array}
   */
  getFilteredMedia = () => {
    const { uploadedMedia } = this.props;
    const { view } = this.state;
    const viewtype = view === 'font' ? 'application' : view;

    return uploadedMedia.filter(medium => getMimeType(medium.content_type) === viewtype);
  };

  handleItemClick = mediumId => {
    const { selectionMode } = this.state;
    const { onSelectedItem } = this.props;

    if (!selectionMode) {
      this.getFilteredMedia().forEach(item => {
        if (item.id === mediumId) {
          onSelectedItem(item);
        }
      });
    } else {
      this.toggleSelectItem(mediumId);
    }
  };

  removeSelected = async () => {
    const { deleteMedia } = this.props;
    const { selectedItems, deleteLoading } = this.state;

    if (deleteLoading) return;

    this.setState({ deleteLoading: true });

    try {
      await deleteMedia(selectedItems);
      this.setState({
        selectedItems: [],
        selectionMode: false,
        showUploadMedia: false,
      });
    } catch (error) {
      serverAlert('Wystąpił bład podczas usuwania mediów.');
    }

    this.setState({ deleteLoading: false });
  };

  switchView(newView) {
    this.setState({
      view: newView,
      selectedItems: [],
      selectionMode: false,
    });
  }

  toggleSelectionMode() {
    const { selectionMode } = this.state;

    this.setState({
      selectionMode: selectionMode === false,
      selectedItems: [],
    });
  }

  toggleSelectItem(key) {
    let { selectedItems } = this.state;
    if (selectedItems.includes(key)) {
      selectedItems = selectedItems.filter(value => key !== value);
    } else {
      selectedItems.push(key);
    }

    this.setState({ selectedItems });
  }

  thereAreNoSelectedItemsToDelete() {
    const { selectedItems } = this.state;
    return selectedItems.length === 0;
  }

  showUploadMediaComponent() {
    this.setState({ showUploadMedia: true });
  }

  hideUploadMediaComponent() {
    this.setState({ showUploadMedia: false });
  }

  shouldRenderUploadMediaComponent() {
    const { showUploadMedia } = this.state;
    return showUploadMedia;
  }

  renderSwitchButtons() {
    const { allowedTypes } = this.props;
    const { view } = this.state;

    return allowedTypes.map(type => {
      let icon;
      const active = view === type;

      switch (type) {
        case 'audio':
          icon = <AudioIcon width="40px" height="40px" style={{ left: -2 }} />;
          break;
        case 'image':
          icon = <ImageIcon width="36px" height="36px" />;
          break;
        case 'video':
          icon = <VideoIcon width="36px" height="36px" />;
          break;
        case 'font':
          icon = <FontIcon width="36px" height="36px" />;
          break;
        default:
          break;
      }

      return (
        <SwitchTypeButton
          key={type}
          id={type}
          handleClick={newView => this.switchView(newView)}
          icon={icon}
          active={active}
        />
      );
    });
  }

  render() {
    const { selectionMode, selectedItems, deleteLoading } = this.state;
    const {
      deleteMedia,
      mediaSpaceUsage,
      uploadedMedia,
      insertMediumInStore,
      allowedTypes,
      mediaAreFetching,
      ...props
    } = this.props;

    const items = this.getFilteredMedia().map(item => {
      let checked = false;

      if (selectedItems.includes(item.id)) {
        checked = true;
      }

      return (
        <Item
          key={item.id}
          item={item}
          selectionMode={selectionMode}
          handleClick={this.handleItemClick}
          checked={checked}
        />
      );
    });

    return (
      <RootModal title="Biblioteka plików" width={800} background="gray" {...props}>
        <Provider
          value={{
            uploadedMedia,
          }}
        >
          {this.shouldRenderUploadMediaComponent() ? (
            <UploadMedia
              insertMediumInStore={insertMediumInStore}
              hideUploadMediumComponent={() => this.hideUploadMediaComponent()}
              deleteMedia={deleteMedia}
            />
          ) : (
            <Wrapper>
              <div className="buttons_wrap">
                {this.renderSwitchButtons()}
                <div className="other_buttons">
                  <Button
                    clicked
                    type="button"
                    disabled={deleteLoading}
                    onClick={() => this.showUploadMediaComponent()}
                  >
                    Prześlij
                  </Button>
                  <Button
                    type="button"
                    onClick={() => this.toggleSelectionMode()}
                    className="with_checkbox"
                  >
                    <StyledCheckbox checked={selectionMode} left="20px" top="13px" />
                    Zaznaczanie
                  </Button>
                </div>
              </div>

              <Content>
                {(mediaAreFetching || deleteLoading) && <Loading style={{ zIndex: 2 }} />}

                <Scrollbars>
                  <div className="content_insider">{items}</div>
                </Scrollbars>
              </Content>

              {selectionMode ? (
                <DeleteSelected
                  disabled={deleteLoading}
                  clicked={!this.thereAreNoSelectedItemsToDelete()}
                  onClick={this.removeSelected}
                >
                  USUŃ ZAZNACZONE
                </DeleteSelected>
              ) : (
                <SpaceUsage usage={mediaSpaceUsage} />
              )}
            </Wrapper>
          )}
        </Provider>
      </RootModal>
    );
  }
}
