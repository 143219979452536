import { EventEmitter } from 'events';
import merge from 'deepmerge';

const Constants = {
  OPEN: 'open',
  CLOSE: 'close',
  CHANGE: 'change',
};

class Manager extends EventEmitter {
  constructor() {
    super();

    this.events = null;
    this.config = {};

    this.show = false;

    this.defaultConfig = null;
    this.defaultsEvents = null;
  }

  setDefault(defaultConfig, defaultEvents) {
    this.defaultConfig = defaultConfig;
    this.defaultsEvents = defaultEvents;
  }

  close = () => {
    this.show = false;
    this.emitChange();
  };

  open = ({ config = {}, events = {} }) => {
    this.events = events || this.defaultsEvents;
    this.config = config || this.defaultConfig;
    this.show = true;

    this.emitChange();
  };

  update = ({ config = {}, events = {} }) => {
    this.events = merge(this.events, events);
    this.config = merge(this.config, config);
    this.emitChange();
  };

  emitChange() {
    this.emit(Constants.CHANGE, {
      config: this.config,
      events: this.events,
      show: this.show,
    });
  }

  addChangeListener(callback) {
    this.addListener(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Manager();
