import styled from 'styled-components';

export default styled.a.attrs(({ color, weight, family }) => {
  let setWeight;
  let setFontFamily;

  switch (family) {
    case 'noto':
      setFontFamily = 'noto-font';
      break;
    default:
      setFontFamily = 'google-sora';
  }

  switch (weight) {
    case 'medium':
      setWeight = 500;
      break;
    case 'bold':
      setWeight = 700;
      break;
    default:
      setWeight = 400;
  }

  return {
    weight: setWeight,
    fontFamily: `var(--${setFontFamily})`,
    color: color || 'var(--text-color-blue)',
  };
})`
  font-family: ${props => props.fontFamily};
  font-size: ${props => (props.size ? props.size : 14)}px;
  font-weight: ${props => props.weight};
  color: ${props => props.color};
  text-decoration: none;
  cursor: pointer;

  margin: ${props => (props.margin ? props.margin : '0')};
`;
