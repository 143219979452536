import { useSelector, useDispatch } from 'react-redux';

import { defaultConfigurations } from 'store/data/configurators';
import { updateLocalConfigFragment, updateConfig } from 'store/actions/configuratorsConfigs';

const useViewConfig = type => {
  const dispatch = useDispatch();
  const sateConfig = useSelector(state => state.configuratorsConfigs);

  const configuratorConfig = { ...defaultConfigurations[type], ...sateConfig.configurations[type] };

  const dispatchUpdateLocalConfig = fragment => {
    dispatch(updateLocalConfigFragment(type, fragment));
  };

  const dispatchUpdateConfig = fragment => {
    dispatch(updateConfig(type, fragment));
  };

  return {
    configurationsAreFetched: sateConfig.isFetched,
    configuratorConfig,
    updateLocalConfig: dispatchUpdateLocalConfig,
    updateConfig: dispatchUpdateConfig,
  };
};

export default useViewConfig;
