import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import priceFormatter from 'utils/priceFormatter';

import { ArrowInCircleButton } from 'components/Buttons';
import Text from 'components/Text';
import FloatingMenu from 'components/FloatingMenu';

import OptionsPopup, { OptionsPopupItem } from '../styles/OptionsPopup';

import { Consumer as ContextConsumer } from '../Context';

const Block = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 20px;
  margin: 20px 0;
  cursor: pointer;
`;

const Badge = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-1px, -50%);
  padding: 3px 5px;

  background-color: #ff0000;
  color: #fff;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  border-radius: 2px;
`;

const Threshold = ({ data, current, standardThreshold }) => {
  const [popupVisbility, setPopupVisibility] = useState(false);
  const blockRef = useRef(null);

  return (
    <ContextConsumer>
      {({ statisticsData }) => (
        <Block ref={blockRef} onClick={() => setPopupVisibility(!popupVisbility)}>
          {current && <Badge>Aktualne</Badge>}
          <div>
            <Text weight="medium" margin="0 0 1px">
              {data.threshold === 0 ? (
                'Standardowe stawki'
              ) : (
                <>
                  Próg od&nbsp;
                  <strong>{priceFormatter(data.threshold)}</strong>
                </>
              )}
            </Text>
            <Text size={12} color="light-gray" noMargin>
              {statisticsData.income.current_month >= data.threshold
                ? 'Gratulacje, właśnie ten próg posiadasz!'
                : `Aby go zdobyć, zarób ${priceFormatter(
                    data.threshold - statisticsData.income.current_month,
                  )} w tym miesiącu.`}
            </Text>
          </div>
          <ArrowInCircleButton />

          <FloatingMenu
            visible={popupVisbility}
            position="bottom"
            onClose={() => setPopupVisibility(false)}
            parent={blockRef.current || document.body}
          >
            <OptionsPopup>
              {Object.keys(data.methods).map(method => {
                const methodData = data.methods[method];
                let percent = 100;
                let fixed = 0;
                if (method !== 'justpay_full') {
                  percent = parseFloat((methodData.percent * 100).toFixed(1));
                  ({ fixed } = methodData);
                }
                const standardPercent = parseFloat(
                  standardThreshold &&
                    ((standardThreshold.methods[method] &&
                      standardThreshold.methods[method].percent) ||
                      1) * 100,
                );

                return (
                  <OptionsPopupItem key={method}>
                    {methodData.human_method_name}
                    <Text noMargin color="blue">
                      {standardThreshold && method !== 'justpay_full' && (
                        <Text margin="0 6px 0 0" tag="span" size={11}>
                          {`+${parseFloat((percent - standardPercent).toFixed(1))}%`}
                        </Text>
                      )}

                      {`${percent}%`}
                      {fixed > 0 && ` - ${(fixed / 100).toFixed(2)} PLN`}
                    </Text>
                  </OptionsPopupItem>
                );
              })}
            </OptionsPopup>
          </FloatingMenu>
        </Block>
      )}
    </ContextConsumer>
  );
};

Threshold.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  standardThreshold: PropTypes.instanceOf(Object),
  current: PropTypes.bool.isRequired,
};

Threshold.defaultProps = {
  standardThreshold: null,
};

export default Threshold;
