import styled from 'styled-components';

export default styled.div`
  position: relative;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  padding: 5px;
  background-color: var(--bg-color-main-block);
  border-radius: 10px;
  box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.05);

  .rc-slider {
    height: 45px;
    width: 100%;
    overflow: hidden;

    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 8px;

    background-color: var(--bg-color-light-secondary-gray);

    .rc-slider-rail,
    .rc-slider-step,
    .rc-slider-track {
      height: 100%;
      background: none;
    }

    .rc-slider-track {
      background-color: var(--green);
      border-radius: 0;
    }

    .rc-slider-handle {
      top: 0;

      width: 45px;
      height: 100%;

      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 0;
      outline: 0;
      z-index: 1;

      transform: translate(-50%, 0);
      background: none;
      box-shadow: none;
    }
  }

  .rc-slider-vertical {
    width: 50px;
    height: 290px;

    .rc-slider-track,
    .rc-slider-mark,
    .rc-slider-handle {
      width: 100%;
      left: 0;
      transform: none;
    }

    .rc-slider-rail,
    .rc-slider-step {
      width: 100%;
    }

    .rc-slider-handle {
      top: auto;
      left: 0;

      height: 60px;
      width: 100%;

      transform: translate(0, 50%);
    }
  }
`;
