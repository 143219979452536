import React from 'react';
import styled from 'styled-components';

import NewMessageImg from 'assets/img/new-message.png';

const Root = styled.div`
  color: #adadad;
  text-align: center;
  margin: auto;
`;

const Info = styled.p`
  margin: 30px 0 0;
  font-size: 10px;
  font-weight: 800;
  text-transform: uppercase;
`;

const Message = styled.p`
  margin: 14px 0 0;
  font-size: 27px;
  font-weight: 800;
  color: var(--black);
`;

const ImageContainer = styled.div`
  margin-top: -32px;
`;

const NoTemplates = () => (
  <Root>
    <Info>Nie masz żadnych szablonów</Info>
    <Message>Stwórz swój pierwszy szablon</Message>
    <ImageContainer>
      <img src={NewMessageImg} alt="" />
    </ImageContainer>
  </Root>
);

export default NoTemplates;
