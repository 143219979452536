import React from 'react';
import PropTypes from 'prop-types';

import BlankList from 'styles/BlankList';

import ListItem from './ListItem';

const List = ({ controls, items }) => (
  <BlankList>
    {items.map(({ id, config: { title } }) => (
      <ListItem key={id} id={id} title={title} controls={controls} />
    ))}
  </BlankList>
);

List.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  controls: PropTypes.bool,
};

List.defaultProps = {
  controls: false,
};

export default List;
