import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MaterialSpinner } from 'assets/icons/material-spinner.svg';

const Wrapper = styled.div`
  --spinner-size: 30px;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);

  .spinner {
    position: absolute;
    top: calc(50% - calc(var(--spinner-size) / 2));
    left: calc(50% - calc(var(--spinner-size) / 2));
    transform: translate(-50%, -50%);

    width: var(--spinner-size);
    height: var(--spinner-size);

    .spinner-path {
      stroke: var(--blue);
    }
  }
`;

const Loading = props => (
  <Wrapper className="loading-container" {...props}>
    <MaterialSpinner />
  </Wrapper>
);

export default Loading;
