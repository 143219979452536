import React from 'react';
import styled from 'styled-components';

import BaseText from 'components/Text';
import { ColorfulRoundedButton } from 'components/Buttons';

import PropTypes from 'prop-types';
import { StepContentWrapper } from '../components/StepContent';
import SelectedPlatformBase from '../components/SelectedPlatform';
import Link from '../../../../../../../../../components/Link';

const Wrapper = styled(StepContentWrapper)`
  padding-top: 71px;
  padding-bottom: 51px;
`;

const SelectedPlatform = styled(SelectedPlatformBase)`
  margin-bottom: 51px;
`;

const Text = styled(BaseText)`
  text-align: center;
  margin: 0 auto 20px;
  max-width: 300px;
  line-height: 1.36;
`;

const ConfirmButton = styled(ColorfulRoundedButton)`
  width: 270px;
  display: block;
  margin: 0 auto;
`;

const StepFinal = ({ provider, onConfirm }) => (
  <Wrapper>
    <SelectedPlatform provider={provider} />
    <Text lineHeight={1.58} align="center" mb={20}>
      Jeśli nie posiadasz przycisku SE.PAY, oznacza to że nie spełniasz wymagania StreamElements, w
      posiadaniu minimum 100 obserwujących w swoim socialmedia.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      Jeśli jednak masz 100 obserwujących i twierdzisz, że to błąd zgłoś to StreamElements – nie
      Tipply – pisząc im na:
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      <Link href="https://streamelements.com/contact" target="_blank">
        StreamElements – Discord
      </Link>
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      lub wykorzystaj do tego dostępny w dni robocze dymek pomocy widoczny w prawym, dolnym rogu, na
      którym często jest jakiś konsultant.
    </Text>
    <Text lineHeight={1.58} align="center" mb={20}>
      Pamiętaj, że Tipply nie może aktywować tej funkcji, a nie posiadanie SE.PAY, uniemożliwi Ci
      kontynuowanie procesu płatności. Pamiętaj, że możesz spróbować z TipeeeStream.
    </Text>
    <ConfirmButton onClick={onConfirm} variant="blue">
      Spróbuj ponownie
    </ConfirmButton>
  </Wrapper>
);

export default StepFinal;

StepFinal.propTypes = {
  provider: PropTypes.string.isRequired,
  onConfirm: PropTypes.instanceOf(Function).isRequired,
};
