import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestWebfonts = () => ({
  type: 'REQUEST_WEBFONTS',
});

const receiveWebfonts = recievedData => ({
  type: 'RECEIVE_WEBFONTS',
  recievedData,
});

const failureWebfonts = () => ({
  type: 'FAILURE_WEBFONTS',
});

const fetchWebfonts = () => async dispatch => {
  dispatch(requestWebfonts());

  try {
    const { data: webfonts } = await http.get(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      {
        withCredentials: false,
      },
    );

    dispatch(receiveWebfonts(webfonts.items));
    return Promise.resolve();
  } catch (error) {
    dispatch(failureWebfonts());
    return Promise.reject();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchWebfontsIfNeeded = () => (dispatch, getState) => {
  const { webfonts } = getState();
  const apiKeyAvailable = !!process.env.REACT_APP_GOOGLE_API_KEY;

  if (shouldFetchData(webfonts) && apiKeyAvailable) {
    return dispatch(fetchWebfonts());
  }

  return Promise.resolve();
};

export const allFontsList = allFontsData => ({
  type: 'ALL_FONTS_WEBFONTS',
  allFontsData,
});
