import React from 'react';

import Link from 'components/Link';
import { usePayPal } from 'components/PayPalConnect/hooks';
import Text from 'components/Text';

import Wrapper from './Wrapper';

const WaitingForAuthorization = () => {
  const { refreshEmail } = usePayPal();

  return (
    <Wrapper>
      <Text size={16} weight="bold">
        Otwarto w nowym oknie
      </Text>
      <Text color="light-gray">
        {'Okno nie wyskoczyło? '}
        <Link onClick={() => refreshEmail()}>Spróbuj ponownie</Link>
      </Text>
    </Wrapper>
  );
};

export default WaitingForAuthorization;
