import React from 'react';

import Paragraph from './Paragraph';

const BankTransferValidatedNotification = () => (
  <div>
    <Paragraph>Od dziś możesz cieszyć się odznaką weryfikacji na swoim profilu do wpłat!</Paragraph>
    <Paragraph>
      {'Sprawdź jak on pięknie wygląda przy Twoim nicku '}
      <span role="img" aria-label="smile">
        😎
      </span>
    </Paragraph>
  </div>
);

export default BankTransferValidatedNotification;
