import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/icons/close-2.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/edit-pencil.svg';

import { createGoal, resetCreatedGoal } from 'store/actions/goals';

import ItemBlueButton from 'components/Modal/styles/ItemBlueButton';
import ItemRedButton from 'components/Modal/styles/ItemRedButton';
import TextLabel from 'components/Modal/styles/TextLabel';
import { CreatableCustomSelect } from 'components/Select';

import { DEFAULT_SLOT_COLORS } from '../../../../constants';

import ColorSelector from './components/ColorSelector';
import GoalTitleInput from './components/GoalTitleInput';
import SlotWrapper from './components/SlotWrapper';
import Wrapper from './components/Wrapper';

const GoalSlot = ({ value, alreadySelected, onChange, onRemove }) => {
  const {
    index,
    goal: { goal, color },
  } = value;

  const dispatch = useDispatch();
  const { items, isPatching, createdGoal } = useSelector(store => store.goals);
  const [selectedGoal, setSelectedGoal] = useState(goal);
  const [selectedColor, setSelectedColor] = useState(color || DEFAULT_SLOT_COLORS[index]);
  const [isChanged, setIsChanged] = useState(false);
  const [isGoalModifying, setIsGoalModifying] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setSelectValue] = useState({
    value: '',
    label: '',
  });

  const findGoalById = id => items.find(i => i.id === id);

  useEffect(() => {
    if (goal) {
      const selected = findGoalById(goal.id);
      if (selected) {
        setSelectValue({ value: selected.id, label: selected.title });
      }
    } else {
      setSelectValue(undefined);
    }
  }, [goal, items]);

  useEffect(() => {
    setSelectOptions(
      items
        .filter(i => !alreadySelected.includes(i.id))
        .map(item => ({
          label: item.title,
          value: item.id,
        })),
    );
  }, [items, alreadySelected]);

  useEffect(() => {
    if (createdGoal && createdGoal.slot === index) {
      setSelectValue({ value: createdGoal.goal.id, label: createdGoal.goal.title });
      setSelectedGoal(createdGoal.goal);
      dispatch(resetCreatedGoal());
      setIsChanged(true);
    }
  }, [createdGoal]);

  useEffect(() => {
    if (isChanged) {
      onChange({
        goal: selectedGoal,
        color: selectedColor,
      });
      setIsChanged(false);
    }
  }, [isChanged, selectedGoal, selectedColor]);

  const onSelectChange = e => {
    const foundGoal = findGoalById(e.value);

    if (foundGoal) {
      setSelectedGoal(foundGoal);
      setIsChanged(true);
    }
  };

  const onSelectCreateOption = inputValue => {
    dispatch(createGoal(inputValue, index));
  };

  const onColorChange = e => {
    setSelectedColor(e.hex.toUpperCase());
    if (selectedGoal) {
      setIsChanged(true);
    }
  };

  if (!items) return null;

  return (
    <Wrapper>
      <TextLabel color="var(--gray)" innerColor="var(--black)">
        <span>{`Wybierz ${index + 1} cel`}</span>
        <br />
        biorący udział w konkurencji
      </TextLabel>
      <SlotWrapper>
        {isGoalModifying ? (
          <GoalTitleInput goal={goal} onClose={() => setIsGoalModifying(false)} />
        ) : (
          <>
            <CreatableCustomSelect
              placeholder="Wybierz cel z listy"
              className="goal-selector"
              blurInputOnSelect
              noOptionsMessage={() => 'Aby utworzyć nowy cel, zacznij pisać w rubryce powyżej'}
              options={selectOptions}
              value={selectValue}
              formatCreateLabel={inputValue => `Utwórz cel "${inputValue}"`}
              isValidNewOption={inputValue => inputValue.length > 0 && items.length < 10}
              isLoading={isPatching}
              isDisabled={isPatching}
              onChange={onSelectChange}
              onCreateOption={onSelectCreateOption}
            />
            <ItemBlueButton onClick={() => setIsGoalModifying(true)} disabled={isPatching}>
              <PencilIcon />
            </ItemBlueButton>
            <ItemRedButton onClick={onRemove} disabled={isPatching}>
              <CloseIcon />
            </ItemRedButton>
          </>
        )}
      </SlotWrapper>
      <ColorSelector
        color={selectedColor}
        onChange={onColorChange}
        buttonProps={{ disabled: isPatching }}
      />
    </Wrapper>
  );
};

GoalSlot.propTypes = {
  value: PropTypes.shape({
    index: PropTypes.number.isRequired,
    goal: PropTypes.shape({
      goal: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
      color: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }).isRequired,
  }).isRequired,
  alreadySelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.instanceOf(Function).isRequired,
  onRemove: PropTypes.instanceOf(Function).isRequired,
};

export default GoalSlot;
