import styled from 'styled-components';

import { MIN_DESKTOP } from 'styles/base/breakpoints';

export default styled.div`
  font-family: var(--google-sora-cdn);
  animation: 1s tipIn ease;
  position: relative;

  .box {
    animation: 0.5s boxTipIn ease;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    background-color: var(--white);
    @media (${MIN_DESKTOP}) {
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 30px 60px;
    }
  }

  .flexavatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .avatar {
    width: 62px;
    min-width: 62px;
    height: 62px;
    border-radius: 100px;
    overflow: hidden;
    background: var(--light-gray);
    background-size: cover;
    margin: 0 auto 25px;
    @media (${MIN_DESKTOP}) {
      display: inline-block;
      margin: 0 25px 0 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .donator {
    width: 100%;
    padding: 0 0;
    text-align: center;
    @media (${MIN_DESKTOP}) {
      text-align: left;
      display: flex;
      max-width: 400px;
      min-width: 400px;
    }

    p {
      width: 100%;
    }
  }

  .message {
    width: 100%;
    text-align: center;
    margin: 25px 0;
    @media (${MIN_DESKTOP}) {
      width: 450px;
      text-align: left;
      margin: 0;
      padding: 0 15px;
    }

    > span {
      overflow-wrap: break-word;
      > span {
        word-break: break-all;
        hyphens: auto;
      }
    }

    > span > img {
      margin: 5px 0;
      height: 2.4em;
      width: auto;
      vertical-align: -1.1em;
    }

    .goal {
      margin: 5px 0 30px;
    }
  }

  .separator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    @media (${MIN_DESKTOP}) {
      margin-bottom: 5px;
      display: block;
      white-space: nowrap;

      .nickname {
        white-space: normal;
      }
    }

    > span {
      display: inline;
    }
  }

  .timespan {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .paymentmethod {
    font-size: 14px;
  }

  .nicknamewrap {
    width: 200px;
    font-size: 18px;
    word-wrap: break-word;
  }

  .statuswrap {
    padding-top: 10px;

    > button {
      @media (${MIN_DESKTOP}) {
        margin-left: auto;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    @media (${MIN_DESKTOP}) {
      justify-content: flex-end;
    }

    button {
      appearance: none !important;
      background: var(--light-gray);
      margin: 5px;
      border: 0;
      outline: 0;
      width: 42px;
      height: 42px;
      cursor: pointer;
      border-radius: 50px;
      transition: transform 250ms;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale(0.8);

        svg {
          transform: rotate(-25deg);
          top: 0;
          left: 0;
        }
      }

      svg {
        display: block;
        width: 21px;
        height: 21px;
        transition: all 250ms;
        position: relative;
        top: 1px;
        left: 1px;

        &.reload-icon {
          top: 0;
          left: 0;
        }
      }
    }
  }

  &.loading {
    user-select: none;
    cursor: wait;
    opacity: 0.8;
  }

  .spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    .spinner-path {
      stroke: var(--green);
    }
  }
`;
