/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fecha from 'fecha';
import Link from 'components/Link';
import PictureNormal from '../../Hello/styles/PictureNormal';

const Wrapper = styled.div`
  text-align: left;

  > time {
    display: inline-block;
    margin: 10px 0 10px;
    font-weight: 500;
  }

  > p {
    margin: 0;
    font-weight: 700;
  }
`;

const AnnouncementListItem = ({ createdAt, title, content, piclink, poster, piclinkhref }) => (
  <Wrapper>
    <time dateTime={createdAt}>{fecha.format(new Date(createdAt), 'DD.MM.YYYY')}</time>
    <Link href={piclinkhref} target="_blank">
      <PictureNormal
        style={{
          backgroundImage: piclink ? `url(${piclink})` : null,
        }}
      />
    </Link>
    <h3>{title}</h3>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </Wrapper>
);

AnnouncementListItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  piclink: PropTypes.string.isRequired,
  poster: PropTypes.bool.isRequired,
  piclinkhref: PropTypes.string.isRequired,
};

AnnouncementListItem.defaultProps = {
  title: '',
};

export default AnnouncementListItem;
