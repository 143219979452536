import styled from 'styled-components';

export default styled.div`
  position: absolute;
  left: 50%;
  bottom: 100%;
  max-width: 160px;
  width: max-content;
  padding: 6px 8px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  text-align: center;

  font-size: 8px;
  font-weight: 800;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    top: calc(100% - 3px);
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }
`;
