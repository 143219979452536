import deepmerge from 'deepmerge';

import overwriteMerge from 'utils/overwriteMerge';

export default (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_CONFIGURATION': {
      const { configuratorType, updatedData } = action;

      return {
        ...state,
        configurations: {
          ...state.configurations,
          [configuratorType]: updatedData,
        },
      };
    }
    case 'UPDATE_CONFIGURATION_FRAGMENT': {
      const { configuratorType, fragment } = action;
      const local = state.configurations[configuratorType];
      const updated = deepmerge(local, fragment);

      return {
        ...state,
        configurations: {
          ...state.configurations,
          [configuratorType]: updated,
        },
      };
    }
    case 'REQUEST_CONFIGURATIONS':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_CONFIGURATIONS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        configurations: action.configurations,
      };
    case 'UPDATE_GLOBAL_CONFIGURATION':
      return {
        ...state,
        globals: deepmerge(state.globals, action.config, { arrayMerge: overwriteMerge }),
      };
    case 'FAILURE_CONFIGURATIONS':
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case 'SET_DISPLAY_SETTINGS_VISIBILITY':
      return {
        ...state,
        displaySettingsDialog: {
          ...state.displaySettingsDialog,
          open: action.payload,
        },
      };
    case 'SET_DISPLAY_SETTINGS_CURRENT_VIEW':
      return {
        ...state,
        displaySettingsDialog: {
          ...state.displaySettingsDialog,
          currentView: action.payload,
        },
      };
    case 'SET_CONFIGURATION_UPDATING':
      return {
        ...state,
        isUpdating: action.payload,
      };

    default:
      return state;
  }
};
