import styled from 'styled-components';

const ModeratorStatus = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding: 0 !important;
  gap: 10px;
  font-weight: bold;

  > svg {
    width: 15px;
  }
`;

export default ModeratorStatus;
