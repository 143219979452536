import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import WhiteBox from '../../../styles/WhiteBox';
import { updateModeratorField, deleteModerator } from '../../../store/actions/moderator';
import Link from '../../../assets/icons/components/Link';
import Button from '../../../components/Button';
import { ReactComponent as TrashBinIcon } from '../../../assets/icons/trashbin.svg';
import { openAlert } from '../../../components/Alert/utils';
import Trash from './elements/Trash';
import { useCopyToClipboard, useDelayedState } from '../../../utils/hooks';

const Wrapper = styled(WhiteBox)`
  padding: 0px 0px;
  position: relative;
  flexgrow: 1;
`;

const Container = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BaseRoundedInputStyles = styled.input`
  border-radius: 50px;
  border: 0px solid var(--border-color);
  color: var(--text-color-gray);
  padding: 20px 20px 20px 20px;
  width: 200px;
  background: var(--input-bg-mod);
  box-shadow: none;
  outline: none;
  height: 46px;
  font-size: 16px;
  text-align: left;
  display: inline-block;
  float: left;
`;

const BaseRoundedInputNumber = styled(NumberFormat)`
  border-radius: 50px;
  border: 0px solid var(--border-color);
  color: var(--text-color-gray);
  padding: 20px 20px 20px 20px;
  width: 200px;
  background: var(--input-bg-mod);
  box-shadow: none;
  outline: none;
  height: 46px;
  font-size: 16px;
  text-align: right;
  display: inline-block;
  float: right;
`;

const Moderator = ({ id, moderatorName, linkTime, link }) => {
  const dispatch = useDispatch();
  const [copied, copyLink] = useCopyToClipboard();

  const handleDelayed = (fieldName, e) => {
    let newValue = e;
    if (fieldName === 'link_time') {
      newValue = e.replace(/\D/g, '');
    }
    dispatch(updateModeratorField(id, fieldName, newValue));
  };

  const [innerModeratorName, setInnerModeratorName] = useDelayedState(
    moderatorName,
    'moderator_name',
    handleDelayed,
  );
  const [innerLinkTime, setInnerLinkTime] = useDelayedState(linkTime, 'link_time', handleDelayed);

  const assembleModeratorUrl = () => `${process.env.REACT_APP_APP_URL}/panel-moderatora/${id}`;
  const handleCopyGoalUrl = () => {
    copyLink(assembleModeratorUrl());
  };

  const handleRemove = () => {
    openAlert(
      'confirm',
      'Czy na pewno?',
      'Będzie można w przyszłości ponownie dodać tego moderatora. Czy usunąc go teraz?',
      {
        cancelButtonText: 'Anuluj',
        confirmButtonText: 'Tak usuń',
        cancelButton: true,
      },
      () => {
        onRemove();
      },
    );
  };

  const onRemove = () => {
    dispatch(deleteModerator(id));
  };

  return (
    <Wrapper>
      <Container style={{ flexGrow: 1 }}>
        <BaseRoundedInputStyles
          style={{ width: '300px' }}
          name="moderator_name"
          type="text"
          value={innerModeratorName}
          onChange={e => setInnerModeratorName(e.target.value)}
        />
        <BaseRoundedInputNumber
          style={{ width: '200px' }}
          suffix=" godzin"
          name="link_time"
          type="text"
          value={innerLinkTime > 0 ? innerLinkTime : '0'}
          onChange={e => setInnerLinkTime(e.target.value)}
        />
        <div style={{ margin: '0 0 15px 0' }}>
          <Button
            background={copied ? 'green-dark' : 'green'}
            onClick={handleCopyGoalUrl}
            type="button"
            icon={<Link />}
          >
            Skopiuj URL
          </Button>
        </div>
        <div>
          <Trash onClick={handleRemove}>
            <TrashBinIcon width="18" fill="var(--red)" />
          </Trash>
        </div>
      </Container>
    </Wrapper>
  );
};

Moderator.propTypes = {
  id: PropTypes.string.isRequired,
  moderatorName: PropTypes.string.isRequired,
  linkTime: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default withRouter(Moderator);
