import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { markExtraAnnouncementsAsRead } from 'store/actions/extraAnnouncements';
import { ReactComponent as CloseIcon } from 'icons/cancel.svg';
import Container from './styled/Container';
import WhiteBox from '../styles/WhiteBox';
import Button from './Button';
import Text from './Text';
import parseMessage from '../utils/parseMessage';

const NotificationContainer = styled(Container)`
  padding-bottom: 0;
`;

const NotificationContent = styled(WhiteBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 130px;
  padding-right: 130px;
  border-radius: 50px;
  border: 1px solid var(--border-color);
  box-shadow: none;
`;

const NotificationText = styled(Text)`
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '560px')};
  margin: 0;
  font-size: 16px;
  line-height: normal;
`;

const NotificationButton = styled(Button)`
  box-sizing: border-box;
  background-color: transparent;
  color: var(--text-color);
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  border: 2px solid var(--brand-green);
  text-transform: uppercase;
`;

const ExtraAnnouncementButton = styled(Button)`
  box-sizing: border-box;
  background-color: transparent;
  color: var(--text-color);
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  border: 2px solid var(--brand-green);
  text-transform: uppercase;
`;

const CloseX = styled(Button)`
  width: 30px;
  background-color: transparent;
  color: var(--text-color);
  transform: translate(80px, 0px);
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: var(--text-color);
    }
  }
`;

const AdditionalReminder = () => {
  const dispatch = useDispatch();
  const { info: userDataInfo, isFetched: isProfileFetched } = useSelector(state => state.userData);
  const userDataProfile = useSelector(state => state.userData.profile);
  const { items: extraAnnouncementsItems, isFetched: isAnnouncementsFetched } = useSelector(
    state => state.extraAnnouncements,
  );

  if (!isProfileFetched || !isAnnouncementsFetched) {
    return null;
  }

  let isExtraAnnouncement = false;
  let textInfo = '';
  let eaId = '';
  let eaType = '';
  let buttonText = '';
  let borderColor = 'var(--border-color)';
  let buttonLink = null;
  const filteredItems = extraAnnouncementsItems.filter(item => item.is_read === false);

  if (filteredItems.length > 0) {
    isExtraAnnouncement = true;
    textInfo = filteredItems[0].content;
    eaId = filteredItems[0].id;
    eaType = filteredItems[0].color;
    buttonText = filteredItems[0].button_text;
    buttonLink = filteredItems[0].button_link;

    switch (eaType) {
      case 'info':
        borderColor = 'var(--brand-blue)';
        break;
      case 'urgent':
        borderColor = 'var(--yellow)';
        break;
      case 'warning':
        borderColor = 'var(--red)';
        break;
      default:
        borderColor = 'var(--border-color)';
    }
  }

  if (userDataInfo.missing_personal_data && userDataProfile.pending) {
    return (
      <NotificationContainer>
        <NotificationContent style={{ borderColor: 'var(--red)' }}>
          <NotificationText fullWidth>
            <Text noMargin>
              <strong>
                Dziękujemy za przesłanie danych osobowych. Jesteśmy w trakcie ich weryfikowania!
              </strong>
              <br />
              Maksymalnie weryfikacja zajmie nam 24 godziny. Do momentu akceptacji danych Twoja
              strona wpłat nie będzie aktywna i nie będziesz mógł przyjmować wpłat. Oczekuj
              cierpliwie na akceptację danych, by móc rozpocząć współpracę z Tipply!
            </Text>
          </NotificationText>
        </NotificationContent>
      </NotificationContainer>
    );
  }

  if (
    (!userDataInfo.missing_personal_data || userDataProfile.pending) &&
    filteredItems.length === 0
  ) {
    return null;
  }

  if (
    userDataInfo.missing_personal_data &&
    !userDataProfile.pending &&
    filteredItems.length === 0
  ) {
    textInfo =
      'Aby móc rozpocząć przyjmowanie wiadomości od widzów, musisz wpisać swoje dane, abyśmy\n' +
      'wiedzieli, gdzie wysyłać środki.';
  }

  const ButtonParsedText = <span dangerouslySetInnerHTML={{ __html: buttonText }} />;

  const handleClose = () => {
    dispatch(markExtraAnnouncementsAsRead({ eaId }));
  };

  const handleButtonClick = () => {
    if (buttonLink) {
      window.location.href = `${buttonLink}`;
    } else {
      handleClose();
    }
  };

  return (
    <NotificationContainer>
      <NotificationContent
        style={{
          borderColor,
        }}
      >
        <NotificationText>
          <span dangerouslySetInnerHTML={{ __html: parseMessage(textInfo) }} />
        </NotificationText>
        {isExtraAnnouncement && (
          <>
            {eaType === 'info' && (
              <ExtraAnnouncementButton
                style={{
                  borderColor: 'var(--brand-blue)',
                  boxShadow: '0 0 30px rgba(0, 120, 255, .4)',
                }}
                onClick={handleButtonClick}
              >
                {ButtonParsedText}
              </ExtraAnnouncementButton>
            )}
            {eaType === 'urgent' && (
              <ExtraAnnouncementButton
                style={{
                  borderColor: 'var(--yellow)',
                  boxShadow: '0 0 30px rgba(255, 180, 0, .4)',
                }}
                onClick={handleButtonClick}
              >
                {ButtonParsedText}
              </ExtraAnnouncementButton>
            )}
            {eaType === 'warning' && (
              <ExtraAnnouncementButton
                style={{ borderColor: 'var(--red)', boxShadow: '0 0 30px rgba(255, 79, 79, .4)' }}
                onClick={handleButtonClick}
              >
                {ButtonParsedText}
              </ExtraAnnouncementButton>
            )}
            <CloseX onClick={handleClose}>
              <CloseIcon />
            </CloseX>
          </>
        )}
        {!isExtraAnnouncement && (
          <NotificationButton
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_PERSONALDATA_URL}`;
            }}
          >
            Uzupełnij formularz
          </NotificationButton>
        )}
      </NotificationContent>
    </NotificationContainer>
  );
};

export default AdditionalReminder;
