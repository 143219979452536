import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getMimeType from 'utils/getMimeType';
import { apiUrl } from 'utils/http';
import MediaManager from 'components/MediaManager';
import { Consumer as DragConsumer } from 'pages/Configurators/components/DragWrapper/Context';
import DragWrapper from '../DragWrapper';
import ContextMenuElement from '../ContextMenuElement';

const mapStateToProps = state => {
  const { items } = state.mediaManager;

  return {
    media: items,
  };
};

const mapDispatchToProps = dispatch => ({});

const contextMenuOptions = ['setImage'];

const VisualObject = ({
  animation,
  activeTemplate,
  name,
  options,
  updateElement,
  media,
  configuratorType,
  onKeyDown,
}) => {
  const [mediaManagerVisibility, setMediaManagerVisibility] = useState(false);
  let source = null;
  let mimeType = 'image';

  const findMedium = media.find(medium => medium.id === options.mediumId);

  if (findMedium) {
    mimeType = getMimeType(findMedium.content_type);

    if (mimeType === 'image') {
      source = findMedium.formats
        ? `${findMedium.default ? '' : apiUrl}${findMedium.formats.reference.url}`
        : null;
    } else {
      source = findMedium.formats
        ? `${findMedium.default ? '' : apiUrl}${findMedium.formats.reference.url}`
        : null;
    }
  }

  if (source === null) {
    source = 'http://via.placeholder.com/350x250';
    mimeType = 'image';
  }

  return (
    <DragWrapper
      updateElement={updateElement}
      elementOptions={options}
      elementName={name}
      configuratorType={configuratorType}
      isResizable
      resizeMinConstraints={[20, 20]}
      onKeyDown={event => onKeyDown(event, name)}
    >
      <DragConsumer>
        {({ size }) => (
          <>
            <ContextMenuElement
              id={`${name}-element-list`}
              elementName={name}
              options={contextMenuOptions}
              isEditable={activeTemplate.config.editable}
              customOptions={[{ value: 'setImage', label: 'Ustaw obrazek' }]}
              zIndex={Number.parseInt(options.styles.zIndex, 10)}
              onSelectedOption={(option, config) => {
                if (option === 'setImage') {
                  setMediaManagerVisibility(true);
                } else if (option === 'delete') {
                  updateElement({ [name]: { isVisible: false } });
                } else if (option === 'zIndex') {
                  updateElement(config);
                }
              }}
            >
              <div>
                {mimeType === 'image' ? (
                  <img
                    className={animation}
                    style={{
                      ...options.styles,
                      width: size.width,
                      pointerEvents: 'none',
                      backfaceVisibility: 'hidden',
                    }}
                    src={source}
                    alt=""
                  />
                ) : (
                  <video
                    loop
                    muted
                    autoPlay
                    controls={false}
                    key={source}
                    style={{
                      ...options.styles,
                      width: size.width,
                      pointerEvents: 'none',
                      backfaceVisibility: 'hidden',
                    }}
                  >
                    <source src={source} type={findMedium.content_type} />
                  </video>
                )}
              </div>
            </ContextMenuElement>

            <MediaManager
              allowedTypes={['image', 'video']}
              onClose={() => setMediaManagerVisibility(false)}
              onSelectedItem={medium => {
                setMediaManagerVisibility(false);
                updateElement({ elementName: name, mode: 'source', mediumId: medium.id });
              }}
              isMounted={mediaManagerVisibility}
            />
          </>
        )}
      </DragConsumer>
    </DragWrapper>
  );
};

VisualObject.propTypes = {
  activeTemplate: PropTypes.instanceOf(Object).isRequired,
  configuratorType: PropTypes.string.isRequired,
  animation: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  updateElement: PropTypes.instanceOf(Function).isRequired,
  onKeyDown: PropTypes.instanceOf(Function).isRequired,
  media: PropTypes.instanceOf(Array).isRequired,
};

VisualObject.defaultProps = {
  animation: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisualObject);
