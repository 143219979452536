import React, { useContext } from 'react';

import { ReactComponent as IconItalic } from 'assets/icons/italic.svg';
import { TextEditor } from '../Context';
import { Option, OptionIcon, AnimationToggleOption } from '../styles';

const TYPE = 'fontStyle';

const FontStyle = props => {
  const {
    style: { fontStyle },
    updateStyle,
    isNotStyle,
  } = useContext(TextEditor);

  return (
    <Option
      style={{ position: 'relative', top: 1 }}
      onClick={() => {
        const value = fontStyle === 'normal' ? 'italic' : 'normal';
        updateStyle(TYPE, value, true);
      }}
    >
      <AnimationToggleOption isInactive={isNotStyle} delayOut={0.2}>
        <OptionIcon isActive={fontStyle === 'italic'}>
          <IconItalic />
        </OptionIcon>
      </AnimationToggleOption>
    </Option>
  );
};

export default FontStyle;
