import React from 'react';
import styled from 'styled-components';

import PayPalLogoSmall from 'assets/images/paypal-logo-small.png';
import Link from 'components/Link';
import { usePayPal } from 'components/PayPalConnect/hooks';

import Label from './Label';

const ConnectedWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr 50px;
  align-items: center;
  gap: 13px;
`;

const SmallLogo = styled.img.attrs(() => ({
  src: PayPalLogoSmall,
}))`
  width: 24px;
  height: auto;
  display: block;
`;

const ChangeButton = styled(Link)`
  color: var(--text-color);
  text-transform: uppercase;
  font-family: var(--google-sora-cdn);
  font-weight: bold;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }

  &.is-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const Connected = () => {
  const { paypalEmail, refreshEmail, working } = usePayPal();
  return (
    <ConnectedWrapper>
      <SmallLogo />
      <Label>
        Połączono!
        <br />
        <span title={paypalEmail}>{paypalEmail}</span>
      </Label>
      <ChangeButton onClick={refreshEmail} className={working ? 'is-disabled' : ''}>
        Zmień
      </ChangeButton>
    </ConnectedWrapper>
  );
};

export default Connected;
