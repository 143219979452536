/** Ryszard' note: this is not ideal refactor, since it was done based from old class based component.
 * This should work better with redux strictly for template CRUD actions.
 * Also, this component might need splitting, since the layout comes from parent component.
 *
 * Good luck.
 * */

import React, { useEffect, useRef, useState, useContext } from 'react';
import { contextMenu } from 'react-contexify';
import deepmerge from 'deepmerge';

import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-2.svg';

import configuratorsElements from 'store/data/configuratorsElements';
import useTextEditor from 'components/ElementEditor/editors/TextEditor/useTextEditor';
import TitledSeparator from 'components/TitledSeparator';
import BoxLinkButton from 'components/BoxLinkButton';
import { AmountWithoutProvisionHint } from 'store/data/hints';
import AnimationSettings from '../../components/AnimationSettings';
import NotificationsContext from '../../NotificationsContext';
import ConfigContext from '../../ConfigContext';

import noEditableAlert from '../../lib/noEditableAlert';
import useViewConfig from '../../hooks/useViewConfig';
import useViewTemplates from '../../hooks/useViewTemplates';
import useTemplateConfigHistory from '../../hooks/useTemplateConfigHistory';

import Panel from '../../styles/Panel';
import Screen from '../../components/Screen';
import AnimatedFrame from '../../components/Frame';
import CenterColumn from '../../styles/CenterColumn';
import FilterWords from '../../components/FilterWords';
import ChangeScreenBackground from '../../components/ChangeScreenBackground';
import { ScreenActionCatcher } from '../../components/ScreenActionCatcher';
import Switcher from '../../components/Switcher';
import SendTestTip from '../../components/SendTestTip';
import ConfigActionsBar from '../../components/ConfigActionsBar';
import TemplateManager from '../../components/TemplateManager';

import AnimationDuration from './components/AnimationDuration';
import BottomTip from '../../components/BottomTip';
import CopyLink from './components/CopyLink';
import DisplaySettings from './components/DisplaySettings';
import { UseSavedTemplateNotificationId } from './components/DisplaySettings/components/UsesSavedTemplateNotification';
import MainFunctionsContainer from './components/MainFunctionsContainer';
import RemoveElementContextMenu from './components/RemoveElementContextMenu';
import Elements from './components/Elements';
import ScreenContextMenu from './components/ScreenContextMenu';
import AddElementContextMenu from './components/AddElementContextMenu';

const CONFIGURATOR_TYPE = 'TIP_ALERT';

const TipAlert = () => {
  const elementConfigs = configuratorsElements[CONFIGURATOR_TYPE];

  const { configurationsAreFetched, configuratorConfig } = useViewConfig(CONFIGURATOR_TYPE);

  const {
    activeTemplate,
    recentTemplate,
    createdTemplates,
    createTemplate,
    setActiveTemplateId,
    updateTemplate,
  } = useViewTemplates(CONFIGURATOR_TYPE);

  const {
    hasChanges,
    setHasChanges,
    latestTemplateConfig,
    resetHistory,
    createHistoryEntry,
    pushHistoryEntry: pushHistoryEntryCall,
    pushElementHistoryEntry: pushElementHistoryEntryCall,
    undoStep,
  } = useTemplateConfigHistory(activeTemplate.config, CONFIGURATOR_TYPE);

  const { setCurrentNotification } = useContext(NotificationsContext);

  const [templateChanges, setTemplateChanges] = useState(activeTemplate.config);
  const [currentTemplateId, setCurrentTemplateId] = useState(activeTemplate.id);
  const [focusedElementName, setFocusedElementName] = useState('');
  const [isSwitchingElement, setIsSwitchingElement] = useState(false);
  const [savingStatus, setSavingStatus] = useState('waiting');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showMediaSelector, setShowMediaSelector] = useState(false);
  const [moveElementLayer, setMoveElementLayer] = useState('');
  const [isEditableTemplate, setIsEditableTemplate] = useState(activeTemplate.config.editable);

  // Animation states
  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('in');
  const animationSpeed = 1; // times x1
  const [frameAnimationClass, setFrameAnimationClass] = useState('');

  const configuratorWrapperRef = useRef();
  const centerContentRef = useRef();
  const screenRef = useRef();
  const hasUnsavedChangesRef = useRef(hasUnsavedChanges);
  const latestTemplateConfigRef = useRef(latestTemplateConfig);

  const { textEditor, setTextEditor, toggleTextEditor } = useTextEditor();

  function saveChanges(templateId = null) {
    updateTemplate(latestTemplateConfig, templateId);
    setSavingStatus('saved');
    setHasUnsavedChanges(false);
  }

  function autoSave(templateId = null) {
    if (hasUnsavedChanges) saveChanges(templateId);
  }

  const toggleUnsavedStatus = () => {
    if (!hasUnsavedChanges) setHasUnsavedChanges(true);
  };

  const pushHistoryEntry = data => {
    pushHistoryEntryCall(data);
    toggleUnsavedStatus();
  };

  const pushElementHistoryEntry = data => {
    pushElementHistoryEntryCall(data);
    toggleUnsavedStatus();
  };

  useEffect(() => {
    document.title = 'Tipply | Konfigurator (Powiadomienie o wiadomości)';
  }, []);

  useEffect(() => {
    if (!configurationsAreFetched) setActiveTemplateId(recentTemplate.id);
  }, [configurationsAreFetched, recentTemplate]);

  useEffect(() => {
    if (currentTemplateId !== activeTemplate.id) {
      autoSave(currentTemplateId);
      resetHistory(activeTemplate.config);
      setCurrentTemplateId(activeTemplate.id);
      setTemplateChanges(activeTemplate.config);
      setIsEditableTemplate(activeTemplate.config.editable);
    }
  }, [activeTemplate]);

  useEffect(
    () => () => {
      if (hasUnsavedChangesRef.current) {
        updateTemplate(latestTemplateConfigRef.current);
      }
    },
    [hasUnsavedChangesRef, latestTemplateConfigRef],
  );

  useEffect(() => {
    const status = localStorage.getItem(UseSavedTemplateNotificationId);
    if (savingStatus === 'saved') {
      if (status !== 'disabled') setCurrentNotification(UseSavedTemplateNotificationId);
      setSavingStatus('waiting');
    }
  }, [savingStatus]);

  useEffect(() => {
    let autoSaveTimeout;

    const resetTimeout = () => {
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => {
        saveChanges();
      }, 30000); // 30 seconds timeout
    };

    const handleUserActivity = () => {
      if (hasChanges) resetTimeout();
    };

    document.addEventListener('click', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(autoSaveTimeout);
      document.removeEventListener('click', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, [hasChanges, latestTemplateConfig]);

  useEffect(() => {
    if (!isEditableTemplate && hasChanges) {
      createTemplate({
        ...latestTemplateConfig,
        title: `Nowy szablon #${createdTemplates.length + 1}`,
      });
    }
  }, [hasChanges, latestTemplateConfig]);

  useEffect(() => {
    hasUnsavedChangesRef.current = hasUnsavedChanges;
    latestTemplateConfigRef.current = latestTemplateConfig;
  }, [hasUnsavedChanges, latestTemplateConfig]);

  useEffect(() => {
    setIsSwitchingElement(true);

    const timeOut = () => {
      setIsSwitchingElement(false);
    };
    setTimeout(timeOut, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [focusedElementName, setIsSwitchingElement]);

  useEffect(() => {
    let timeoutFn;

    if (playAnimation) {
      const finishAnimation = () => {
        setAnimationDirection('');
        setPlayAnimation(false);
      };

      const setOut = () => {
        setAnimationDirection('out');
        timeoutFn = setTimeout(finishAnimation, 1000 * animationSpeed);
      };

      const setDuring = () => {
        setAnimationDirection('during');
        const { autoDisplayDuration, displayDuration } = latestTemplateConfig.animation;
        const animationDuration = autoDisplayDuration ? 8 : displayDuration;
        timeoutFn = setTimeout(setOut, animationDuration * 1000 * animationSpeed);
      };

      setAnimationDirection('in');
      timeoutFn = setTimeout(setDuring, 1000 * animationSpeed);
    }

    return () => {
      clearTimeout(timeoutFn);
    };
  }, [latestTemplateConfig, playAnimation]);

  useEffect(() => {
    setFrameAnimationClass(playAnimation ? getScreenAnimationClass() : '');
  }, [playAnimation, animationDirection]);

  useEffect(() => {
    let idleTimeout;

    const restartIdleTimes = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        if (hasUnsavedChanges) saveChanges();
      }, 30000);
    };

    if (hasUnsavedChanges) {
      restartIdleTimes();
      document.addEventListener('mousemove', restartIdleTimes);
      document.addEventListener('click', restartIdleTimes);
      document.addEventListener('keydown', restartIdleTimes);
    }

    return () => {
      clearTimeout(idleTimeout);
      document.removeEventListener('mousemove', restartIdleTimes);
      document.removeEventListener('click', restartIdleTimes);
      document.removeEventListener('keydown', restartIdleTimes);
    };
  }, [hasUnsavedChanges]);

  if (!configurationsAreFetched) return <p>Pobieram konfiguracje...</p>;

  /**
   * Zachowuje zmiany konfiguracji aktywnego szablonu
   * proszę nie pchać całego szablonu
   */
  const handleSave = () => {
    saveChanges();
  };

  const handleSendTextTip = () => {
    if (isEditableTemplate) saveChanges();
  };

  // Ustawienia szablonu

  function editableProxy(callback) {
    if (isEditableTemplate || !createdTemplates.length) {
      callback();
    } else {
      noEditableAlert();
    }
  }

  /**
   * Zapisuje ustawienia do szkicu **szablonu**
   * @param {Object} data
   */
  const updateTemplateDraft = data => {
    setTemplateChanges(deepmerge(templateChanges, data));
  };

  /**
   * Przełącznik widoku opłaty z komisją
   * @param {boolean} value
   */
  const handleCommissionChange = value => {
    editableProxy(() => {
      pushHistoryEntry({ amountWithoutCommission: value });
    });
  };

  // Screen actions

  /**
   * Otwiera kontekstowe menu
   * @param {string} menuId nazwa ID
   * @param evt
   */
  const openContextMenu = (menuId, evt) => {
    contextMenu.show({ id: `${menuId}_contextMenu`, event: evt });
  };

  /**
   * Wyświetla kontekstowe menu okna podglądu
   * @param {MouseEvent} evt
   */
  const handleShowScreenContextMenu = evt => {
    evt.preventDefault();
    openContextMenu('screen', evt);
  };

  /**
   * Zapisuje do historii domyślną pozycję elementu
   * @param {string} elementName
   */
  const handleResetElementPosition = elementName => {
    editableProxy(() => {
      pushElementHistoryEntry({
        [elementName]: { position: elementConfigs[elementName].defaults.position },
      });
    });
  };

  /**
   * Dodaje element do szablonu powiadomienia
   * @param elementName {string}
   */
  const handleShowElement = elementName => {
    editableProxy(() => {
      const elementData = { isVisible: true };

      if (elementName.includes('usernameAction')) {
        elementData.styles = activeTemplate.config.elementsOptions[elementName].styles;
      }

      pushElementHistoryEntry({ [elementName]: elementData });
    });
  };

  /**
   * Usuwa element do szablonu powiadomienia
   * @param elementName {string}
   */
  const handleRemoveElement = elementName => {
    editableProxy(() => {
      pushElementHistoryEntry({ [elementName]: { isVisible: false } });
      if (elementName === focusedElementName) setFocusedElementName('');
    });
  };

  /**
   * Zapisuje do stanu nazwę elementu, który jest
   * obecnie zaznaczony. W razie odznaczenia użyć
   * zawartość `null`.
   * @param elementName {string | null}
   */
  const handleFocusElement = elementName => {
    setFocusedElementName(elementName);
  };

  /** Odpala cofnięcie kroku w historii */
  const handleUndo = () => {
    undoStep();
    setHasUnsavedChanges(true);
  };

  const handleImageChange = (force = true) => {
    setShowMediaSelector(force);
  };

  const handleLayerPositionChange = move => {
    setMoveElementLayer(move);
  };

  /**
   * Zwraca grupy widocznych i niewidocznych elementów
   * @returns {Object<Object[], Object[]>}
   */
  const getElementGroupsByVisibility = () => {
    const elementOpts = { ...latestTemplateConfig.elementsOptions };

    /** @type Array<Object> */
    const visibleElements = [];
    const hiddenElements = [];

    for (const key of ['usernameAction', 'visualObject']) {
      for (const index of [1, 2, 3]) {
        const property = key + index;
        if (!Object.prototype.hasOwnProperty.call(elementOpts, property)) {
          elementOpts[property] = elementOpts[key];
        }
      }
    }

    for (const key in elementOpts) {
      if (Object.prototype.hasOwnProperty.call(elementOpts, key)) {
        const element = { key, name: elementConfigs[key].title };
        if (elementOpts[key].isVisible) {
          visibleElements.push(element);
        } else {
          hiddenElements.push(element);
        }
      }
    }

    return { visibleElements, hiddenElements };
  };

  const { visibleElements, hiddenElements } = getElementGroupsByVisibility();

  // Animations

  const toggleAnimationPreview = () => {
    setPlayAnimation(!playAnimation);
  };

  function getScreenAnimationClass() {
    const classList = [];
    const direction = latestTemplateConfig.animation[animationDirection];

    if (direction) {
      classList.push(direction);
      if (playAnimation) classList.push('animated');
      if (animationDirection === 'during') classList.push('infinite');
    }

    return classList.join(' ');
  }

  const handleSelectAlertAnimation = (directionName, animationName) => {
    editableProxy(() => {
      pushHistoryEntry({
        animation: {
          [directionName]: animationName,
        },
      });
    });
  };

  const handleSelectElementAnimation = (directionName, animationName) => {
    editableProxy(() => {
      pushElementHistoryEntry({
        [focusedElementName]: {
          animation: {
            [directionName]: animationName,
          },
        },
      });
    });
  };

  const handleAnimationDurationSettingsChange = fragment => {
    editableProxy(() => {
      pushHistoryEntry({ animation: fragment });
    });
  };

  const handleScreenClick = fragment => {
    if (!isEditableTemplate) {
      setHasChanges(true);
    }
  };

  // @deprecated use {@link latestTemplateConfig} state variable
  const getLatestHistoryElement = () => latestTemplateConfig;

  // @TODO - restyling of text menu
  return (
    <ConfigContext.Provider
      value={{
        configuratorType: CONFIGURATOR_TYPE,
        elementConfigs,
        savingStatus,

        hasChanges,
        hasUnsavedChanges,
        configuratorConfig,
        latestTemplateConfig,
        getLatestHistoryElement,
        createHistoryEntry,
        pushHistoryEntry,
        pushElementHistoryEntry,
        handleRemoveElement,
        editableProxy,

        activeTemplate,
        visibleElements,
        hiddenElements,

        isSwitchingElement,
        focusedElementName,
        handleFocusElement,

        showMediaSelector,
        handleImageChange,
        moveElementLayer,
        handleLayerPositionChange,

        textEditor,
        setTextEditor,
        toggleTextEditor,

        runAnimation: playAnimation,
        playAnimation,
        toggleAnimationPreview,
        animationDirection,
        handleSelectAlertAnimation,

        configuratorWrapperRef,
        centerContentRef,
        screenRef,
        updateTemplateDraft,

        handleShowElement,
        openContextMenu,
      }}
    >
      <Panel>
        <AnimationSettings
          isElementConfig={!!focusedElementName}
          animationConfigs={
            !focusedElementName
              ? latestTemplateConfig.animation
              : latestTemplateConfig.elementsOptions[focusedElementName].animation
          }
          onSelectAnimation={
            !focusedElementName ? handleSelectAlertAnimation : handleSelectElementAnimation
          }
        />
        <AnimationDuration onChange={handleAnimationDurationSettingsChange} />
        <TitledSeparator title="Pozostałe opcje" />
        <Switcher
          text="Kwota po odcięciu prowizji"
          tooltipContent={<AmountWithoutProvisionHint />}
          name="amount-without-commission"
          value={!!latestTemplateConfig.amountWithoutCommission}
          onChange={handleCommissionChange}
        />
        <FilterWords tagsBoxProps={{ style: { height: 180 } }} />
        <BoxLinkButton href="/lista-wiadomosci#moderation-modal" endIcon={<ArrowRight />}>
          Moderyzuj przychodzące wiadomości
        </BoxLinkButton>
        <SendTestTip onClick={handleSendTextTip} />
      </Panel>

      <CenterColumn ref={configuratorWrapperRef}>
        <div ref={centerContentRef}>
          <Screen ref={screenRef}>
            <ScreenActionCatcher
              templateEditable={isEditableTemplate}
              onClick={handleScreenClick}
              onContextMenu={handleShowScreenContextMenu}
            />

            <AnimatedFrame className={frameAnimationClass}>
              <Elements
                screenRef={screenRef}
                elements={visibleElements}
                activeTemplate={activeTemplate}
                getLatestHistoryElement={getLatestHistoryElement}
                createHistoryElement={createHistoryEntry}
                pushHistory={pushElementHistoryEntry}
                onFocusElement={handleFocusElement}
              />
            </AnimatedFrame>

            <ScreenContextMenu
              resetElementPosition={handleResetElementPosition}
              showElement={handleShowElement}
            />
            <AddElementContextMenu
              menuId="addElement"
              elements={hiddenElements}
              onShowElement={handleShowElement}
            />
            <RemoveElementContextMenu onRemoveElement={handleRemoveElement} />
          </Screen>

          <ConfigActionsBar onSave={handleSave} onUndo={handleUndo} />
        </div>
        <BottomTip />
      </CenterColumn>

      <Panel>
        <TemplateManager />
        <MainFunctionsContainer />
        <ChangeScreenBackground />
        <CopyLink />
      </Panel>

      <DisplaySettings />
    </ConfigContext.Provider>
  );
};

export default TipAlert;
