import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { H3 } from 'styles/ui/headings';

import Text from 'components/Text';

import Whitebox from './styles/Whitebox';
import Button from './styles/Button';
import Header from './styles/Header';

const ComponentWrapper = ({
  title,
  description,
  buttonColor,
  buttonLink,
  children,
  onButtonClick,
  history,
}) => (
  <Whitebox isCollapsible={false}>
    <Header>
      <div>
        <H3>{title}</H3>
        <Text family="noto">{description}</Text>
      </div>
      <div>
        <Button
          background={buttonColor}
          onClick={() => {
            if (buttonLink) {
              history.push(buttonLink);
            } else {
              onButtonClick();
            }
          }}
        >
          Wyświetl wszystkie
        </Button>
      </div>
    </Header>
    <div>{children}</div>
  </Whitebox>
);

ComponentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  buttonColor: PropTypes.node.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,

  buttonLink: PropTypes.string,
  onButtonClick: PropTypes.instanceOf(Function),
};

ComponentWrapper.defaultProps = {
  buttonLink: null,
  onButtonClick: () => {},
};

export default withRouter(ComponentWrapper);
