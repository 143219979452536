import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import TitledSeparator from 'components/TitledSeparator';

import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { ReactComponent as TextIcon } from 'assets/icons/text.svg';
import { ReactComponent as MicIcon } from 'assets/icons/mic.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/arrow-right-1.svg';

import ConfigContext from '../../../ConfigContext';
import PanelButton from '../../../components/PanelButton';
import AddElementContextMenu from './AddElementContextMenu';

const imageMenuId = 'addImage';
const textMenuId = 'addText';

const Root = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

const MainFunctionsContainer = () => {
  const { hiddenElements, handleShowElement, openContextMenu } = useContext(ConfigContext);

  const handleAddImageClick = evt => {
    evt.preventDefault();
    openContextMenu(imageMenuId, evt);
  };

  const handleAddTextClick = evt => {
    evt.preventDefault();
    openContextMenu(textMenuId, evt);
  };

  const imageElements = hiddenElements.filter(el => el.key.includes('visualObject'));
  const textElements = hiddenElements.filter(el => el.key.includes('usernameAction'));

  return (
    <Root>
      <TitledSeparator title="Głowne funkcje" />

      <PanelButton
        startIcon={<ImageIcon />}
        title="Dodaj nowy element:"
        onClick={handleAddImageClick}
        disabled={!imageElements.length}
        fullWidth
      >
        Obrazek, gif lub film
      </PanelButton>
      <AddElementContextMenu
        menuId={imageMenuId}
        elements={imageElements}
        onShowElement={handleShowElement}
      />

      <PanelButton
        startIcon={<TextIcon />}
        title="Dodaj nowy element:"
        onClick={handleAddTextClick}
        disabled={!textElements.length}
        fullWidth
      >
        Dodatkowy tekst
      </PanelButton>
      <AddElementContextMenu
        menuId={textMenuId}
        elements={textElements}
        onShowElement={handleShowElement}
      />

      <Link to="/ustawienia-strony-zamowien#glosowki_od_widzow" style={{ textDecoration: 'none' }}>
        <PanelButton
          startIcon={<MicIcon />}
          title="Przejdź i zarządzaj"
          endIcon={<ChevronRightIcon style={{ color: '#606060', height: 12 }} />}
          fullWidth
        >
          Głosówki od widzów
        </PanelButton>
      </Link>
    </Root>
  );
};

export default MainFunctionsContainer;
