import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import { ReactComponent as WalletIcon } from 'assets/icons/mainmenu/wallet.svg';
import { ReactComponent as ListIcon } from 'assets/icons/mainmenu/list.svg';
import { ReactComponent as LaptopIcon } from 'assets/icons/mainmenu/laptop.svg';
import { ReactComponent as StarIcon } from 'assets/icons/mainmenu/star.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/mainmenu/home.svg';
import { ReactComponent as WindowIcon } from 'assets/icons/mainmenu/window.svg';
import { ReactComponent as GoalsIcon } from 'assets/icons/mainmenu/goals.svg';
import { ReactComponent as AlignLeftIcon } from 'assets/icons/mainmenu/align-left.svg';
import { ReactComponent as FillAndStrokeIcon } from 'assets/icons/mainmenu/fill-and-stroke.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/mainmenu/link.svg';
import { ReactComponent as LoveIcon } from 'assets/icons/mainmenu/love.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/mainmenu/time.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/mainmenu/arrow-down.svg';
import { ReactComponent as PercentsIcon } from 'assets/icons/percent.svg';

import PointsAndBonuses from 'pages/PointsAndBonuses';

import DropDown from 'components/DropDown';

import MenuItem from './components/MenuItem';

const MenuItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
`;

const HelpText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 76px;
  color: var(--gray);
  text-decoration: none;

  > span {
    text-decoration: none;
    color: var(--gray);
    font-size: 13px;
  }

  > svg {
    margin-right: 20px;
    width: 12px;
    height: 12px;
    fill: var(--gray);
  }
`;

const ConfiguratorsDropDown = styled(DropDown)`
  border: 2px solid var(--border-color);
  border-radius: 0 20px 20px 20px;
  padding: 35px 10px 10px;
  width: auto;
  cursor: default;
  top: 73px;
  right: 30px;
  overflow: auto;
`;

const ConfigurationItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`;

const TextContainer = styled.div`
  position: relative;
`;

const NaviText = styled.span`
  font-size: 9px;
  font-family: var(--google-sora-cdn);
  font-weight: bolder;
  text-transform: uppercase;
  color: var(--text-color-white-gray);
  opacity: 0;
  transition: all 200ms ease-out;
  position: absolute;
  top: -11px;
`;

const NewText = styled(NaviText)`
  transition: none;
  opacity: 1;
`;

const ItemText = styled.span`
  font-family: var(--google-sora-cdn);
  font-size: 12px;
  color: var(--text-color);
`;

const activeStyles = css`
  border-color: var(--brand-green);
  background-color: var(--box-background);
  box-shadow: 0 0 5px var(--brand-green);

  > svg {
    transform: translateY(-5px);
  }

  ${NaviText} {
    opacity: 1;
  }

  ${NewText} {
    display: none;
  }
`;

const ConfigurationItemLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  text-decoration: none;
  width: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  padding: 22px 20px 10px;
  border: 1px solid var(--bg-text-editor-color);
  border-radius: 12px;
  transition: all 200ms ease-out;
  font-family: var(--google-sans);

  > svg {
    width: 20px;
    height: 20px;
    transition: all 200ms ease-out;
    fill: var(--text-color);
  }

  &:hover {
    border-color: var(--text-color-medium-gray);
    background-color: var(--bg-text-editor-color);
    box-shadow: none;

    ${NaviText} {
      opacity: 1;
    }

    ${NewText} {
      display: none;
    }

    > svg {
      transform: translateY(-5px);
    }
  }

  ${props => props.isActive && activeStyles}
`;

const ActiveBar = styled.div`
  height: 7px;
  width: calc(100% - 20px);
  position: absolute;
  left: 10px;
  background: var(--bg-color-light-secondary-gray);
  border-radius: 50px;

  > div {
    height: 100%;
    background: var(--brand-green);
    border-radius: 40px;
    width: calc(16.666% - 15px);
    position: absolute;
    left: 0;
    transition: 600ms ease-in-out;
  }
`;

const SHOW_POINTS_AND_BONUSES_EVENT = new CustomEvent('SHOW_POINTS_AND_BONUSES');

class MainMenu extends Component {
  static propTypes = {
    location: PropTypes.instanceOf(Object).isRequired,
  };

  state = {
    offsetLeft: 0,
    currentBarWidth: 82,
    pointAndBonusesIsVisible: false,
    configuratorMenuIsVisible: false,
  };

  pointsAndBonusesRef = React.createRef();

  configuratorItemsRef = React.createRef();

  menuItemsRef = React.createRef();

  componentDidMount() {
    this.setState({
      offsetLeft: this.calculateOffset(),
    });

    window.addEventListener('SHOW_POINTS_AND_BONUSES', this.showPointsAndBonuses);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('SHOW_POINTS_AND_BONUSES', this.showPointsAndBonuses);
  }

  onRouteChanged() {
    this.updateActiveOffset();
  }

  showPointsAndBonuses = () => {
    setTimeout(() => {
      this.setState({
        pointAndBonusesIsVisible: true,
      });
    }, 350);
  };

  /**
   * Funkcja aktualizująca położenie paska, który wskazuje aktywny element menu
   */
  updateActiveOffset = () => {
    this.setState({ offsetLeft: this.calculateOffset() });
  };

  /**
   * Funkcja obliczająca położenie paska, który wskazuje aktywny element menu
   *
   * @returns {number}
   */
  calculateOffset() {
    const activeEl = this.menuItemsRef.current.querySelector(`a.active`);

    if (activeEl) {
      this.setState({ currentBarWidth: activeEl.offsetWidth });
      return activeEl.offsetLeft;
    }

    return 0;
  }

  render() {
    const {
      offsetLeft,
      currentBarWidth,
      pointAndBonusesIsVisible,
      configuratorMenuIsVisible,
    } = this.state;
    let configuratorMenuIsActive = false;

    const isActive = slug => window.location.href.includes(slug);
    const isActiveNaviText = slug => (isActive(slug) ? 'Aktualnie jesteś w' : 'Przejdź do');

    return (
      <div style={{ position: 'relative' }}>
        <MenuItems ref={this.menuItemsRef} className="main-menu">
          <MenuItem to="/panel-uzytkownika">
            <HomeIcon width="22px" height="22px" />

            <span>Panel użytkownika</span>
          </MenuItem>

          <MenuItem to="/lista-wiadomosci">
            <ListIcon width="22px" height="22px" />

            <span>Lista wiadomości</span>
          </MenuItem>

          <MenuItem to="/wyplaty">
            <WalletIcon width="22px" height="22px" />

            <span>Wypłaty</span>
          </MenuItem>

          <MenuItem
            to={
              window.location.pathname.includes('konfigurator')
                ? window.location.pathname
                : '/konfigurator'
            }
            ref={this.configuratorItemsRef}
            isActive={configuratorMenuIsVisible}
            onMouseEnter={() => {
              this.setState(() => ({
                configuratorMenuIsVisible: true,
              }));
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                if (!configuratorMenuIsActive) {
                  this.setState(() => ({
                    configuratorMenuIsVisible: false,
                  }));
                }
              }, 1500);
            }}
          >
            <WindowIcon width="22px" height="22px" />
            <ArrowDownIcon
              width="14px"
              height="14px"
              fill="var(--text-color-medium-gray)"
              style={{
                transform: 'translateX(10px)',
              }}
            />
          </MenuItem>

          <MenuItem to="/cele">
            <GoalsIcon width="22px" height="22px" />

            <span>Cele</span>
          </MenuItem>

          <MenuItem to="/ustawienia-strony-zamowien">
            <LaptopIcon width="22px" height="22px" />

            <span>Ustawienia strony zamówień</span>
          </MenuItem>

          <MenuItem
            isPopup
            ref={this.pointsAndBonusesRef}
            onClick={() => {
              this.setState(prevState => ({
                pointAndBonusesIsVisible: !prevState.pointAndBonusesIsVisible,
              }));
            }}
            isActive={pointAndBonusesIsVisible}
          >
            <StarIcon width="22px" height="22px" />

            <span>Punkty i bonusy</span>
          </MenuItem>
        </MenuItems>

        <ActiveBar>
          <div style={{ left: offsetLeft, width: `calc(${currentBarWidth}px - 18px)` }} />
        </ActiveBar>

        <DropDown
          unmountOnExit
          show={pointAndBonusesIsVisible}
          padding="5px"
          outsideClick={() => this.setState({ pointAndBonusesIsVisible: false })}
          parent={this.pointsAndBonusesRef.current || document.body}
          style={{ width: 'auto', cursor: 'default', top: '100%', right: 5, overflow: 'auto' }}
        >
          <PointsAndBonuses />
        </DropDown>

        <ConfiguratorsDropDown
          unmountOnExit
          show={configuratorMenuIsVisible}
          outsideClick={() => this.setState({ configuratorMenuIsVisible: false })}
          onClick={() => this.setState({ configuratorMenuIsVisible: false })}
          parent={this.configuratorItemsRef.current || document.body}
          onMouseEnter={() => {
            configuratorMenuIsActive = true;
            this.setState(() => ({
              configuratorMenuIsVisible: true,
            }));
          }}
          onMouseLeave={() => {
            configuratorMenuIsActive = false;
            this.setState(() => ({
              configuratorMenuIsVisible: false,
            }));
          }}
        >
          <ConfigurationItems>
            <ConfigurationItemLink
              to="/konfigurator/powiadomienie-o-wiadomosci"
              isActive={isActive('powiadomienie-o-wiadomosci')}
            >
              <FillAndStrokeIcon />
              <TextContainer>
                <NaviText>{isActiveNaviText('powiadomienie-o-wiadomosci')}</NaviText>
                <ItemText>Komunikaty o nowej wiadomości</ItemText>
              </TextContainer>
            </ConfigurationItemLink>

            <ConfigurationItemLink
              to="/konfigurator/ranking-wiadomosci"
              isActive={isActive('ranking-wiadomosci')}
            >
              <LoveIcon />
              <TextContainer>
                <NaviText>{isActiveNaviText('ranking-wiadomosci')}</NaviText>
                <ItemText>Rankingi wpłat widzów</ItemText>
              </TextContainer>
            </ConfigurationItemLink>

            <ConfigurationItemLink
              to="/konfigurator/lista-ostatnich-wiadomosci"
              isActive={isActive('lista-ostatnich-wiadomosci')}
            >
              <AlignLeftIcon />
              <TextContainer>
                <NaviText>{isActiveNaviText('lista-ostatnich-wiadomosci')}</NaviText>
                <ItemText>Listy ostatnich wiadomości</ItemText>
              </TextContainer>
            </ConfigurationItemLink>

            <ConfigurationItemLink to="/konfigurator/twoj-cel" isActive={isActive('twoj-cel')}>
              <GoalsIcon />
              <TextContainer>
                <NaviText>{isActiveNaviText('twoj-cel')}</NaviText>
                <ItemText>Twoje cele płatności</ItemText>
              </TextContainer>
            </ConfigurationItemLink>

            <ConfigurationItemLink
              to="/konfigurator/odlicznik-czasu-do-konca-transmisji"
              isActive={isActive('odlicznik-czasu-do-konca-transmisji')}
            >
              <TimeIcon />
              <TextContainer>
                <NaviText>{isActiveNaviText('odlicznik-czasu-do-konca-transmisji')}</NaviText>
                <ItemText>Odlicznik do końca transmisji</ItemText>
              </TextContainer>
            </ConfigurationItemLink>

            <ConfigurationItemLink to="/konfigurator/glosowania" isActive={isActive('glosowania')}>
              <PercentsIcon />
              <TextContainer>
                <NewText>Nowość</NewText>
                <NaviText>{isActiveNaviText('glosowania')}</NaviText>
                <ItemText>Stwórz głosowanie i konkurencję celów</ItemText>
              </TextContainer>
            </ConfigurationItemLink>
          </ConfigurationItems>

          <HelpText target="_blank" rel="noopener noreferrer" href="https://fb.com/tipplypl">
            <LinkIcon />
            <span>Potrzebuję pomocy</span>
          </HelpText>
        </ConfiguratorsDropDown>
      </div>
    );
  }
}

export function showPointsAndBonuses() {
  window.dispatchEvent(SHOW_POINTS_AND_BONUSES_EVENT);
}

export default withRouter(MainMenu);
