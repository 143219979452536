import React from 'react';

import priceFormatter from 'utils/priceFormatter';
import { NotificationPropTypes } from '../types';
import Paragraph from './Paragraph';

const CommissionThresholdChangeNotification = ({ notification }) => (
  <div>
    <Paragraph>Awansowałeś, na nowy poziom!</Paragraph>
    <Paragraph>Masz teraz powiększone stawki!</Paragraph>
    <Paragraph>{`Twój próg to: ${priceFormatter(notification.payload.new_threshold)}`}</Paragraph>
  </div>
);

export default CommissionThresholdChangeNotification;

CommissionThresholdChangeNotification.propTypes = NotificationPropTypes;
