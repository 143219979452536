import React, { useContext } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconTransparency } from 'assets/icons/transparency.svg';
import { TextEditor } from '../Context';
import { Option, OptionIcon, AbsoluteLayer, AnimationToggleOption } from '../styles';
import { Range } from '../../../shared';

const TYPE = 'fontTransparency';

const RangeWrapper = styled.div`
  width: calc(100% - 60px);
  padding: 0 0 0 25px;
`;

const FontTransparency = props => {
  const {
    style: { fontTransparency },
    updateStyle,
    isActive,
    isNotStyle,
  } = useContext(TextEditor);

  return (
    <Option {...props}>
      <AnimationToggleOption isInactive={isNotStyle} delayOut={0.15}>
        <OptionIcon isActive>
          <IconTransparency />
        </OptionIcon>
      </AnimationToggleOption>

      <AbsoluteLayer isActive={isActive(TYPE)} style={{ zIndex: 5, paddingLeft: 20 }}>
        <OptionIcon isActive>
          <IconTransparency />
        </OptionIcon>
        <RangeWrapper>
          <Range
            value={fontTransparency || 255}
            valuePrefix=""
            min={0}
            max={255}
            onChange={newValue => {
              updateStyle(TYPE, newValue, true);
            }}
            onAfterChange={newValue => {
              updateStyle(TYPE, newValue, true);
            }}
          />
        </RangeWrapper>
      </AbsoluteLayer>
    </Option>
  );
};

export default FontTransparency;
