import styled from 'styled-components';

import hover from './hover';

export default styled.button.attrs(({ background }) => {
  let animationHover;

  switch (background) {
    case 'blue':
      animationHover = 'hoverAnimationBlue';
      break;
    case 'green':
      animationHover = 'hoverAnimationGreen';
      break;
    case 'yellow':
    case 'yellow-secondary':
      animationHover = 'hoverAnimationYellow';
      break;
    case 'white':
      animationHover = 'hoverAnimationWhite';
      break;
    default:
      animationHover = 'hoverAnimationBlue';
  }

  return {
    backgroundColor: background ? `var(--${background})` : 'var(--blue)',
    animationHover,
  };
})`
  position: relative;

  width: 30px;
  height: 30px;
  aspect-ratio: 1 / 1;

  border: 0;
  border-radius: 100%;

  padding: 0 0 2px 2px;

  background-color: ${props => props.backgroundColor};

  transition: all 0.25s ease;
  transition-delay: 0.1s;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    fill: #fff;
    width: 12px;
  }

  &:hover {
    ${props => props.hover && hover};
    animation: ${props => props.animationHover} 0.4s ease;
  }
`;
