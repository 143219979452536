import styled from 'styled-components';

import Text from 'components/Text';

export const GoalTitle = styled(Text)`
  font-family: var(--gilroy-font);
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color-white-gray);
`;
