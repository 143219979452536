import React from 'react';
import styled from 'styled-components';

import BaseText from 'components/Text';
import { ColorfulRoundedButton } from 'components/Buttons';

import PropTypes from 'prop-types';
import { StepContentWrapper } from '../components/StepContent';
import SelectedPlatformBase from '../components/SelectedPlatform';

const Wrapper = styled(StepContentWrapper)`
  padding-top: 71px;
  padding-bottom: 51px;
`;

const SelectedPlatform = styled(SelectedPlatformBase)`
  margin-bottom: 51px;
`;

const Text = styled(BaseText)`
  text-align: center;
  margin: 0 auto 20px;
  max-width: 300px;
  line-height: 1.36;
`;

const ConfirmButton = styled(ColorfulRoundedButton)`
  width: 270px;
  display: block;
  margin: 0 auto;
`;

const StepFinal = ({ provider, onConfirm }) => (
  <Wrapper>
    <SelectedPlatform provider={provider} variant="large" label="Zakończono połączenie z" />
    <Text>
      <strong>Od teraz metoda płatności Paysafecard jest aktywna w Twoim profilu.</strong>
    </Text>
    <Text>
      Pamiętaj, że wszelkie pozyskane środki znajdują się w portfelu wybranej platformy, a
      wiadomości będziesz mógł otrzymywać tylko i wyłącznie w momencie włączonego widgetu z
      powiadomieniami - bez tego, API nie zaktualizuje, wiadomości.
    </Text>
    <Text mb={51}>W razie problemów, bądź dodatkowych problemów, skontaktuj się z nami</Text>
    <ConfirmButton onClick={onConfirm} variant="green">
      Gotowe
    </ConfirmButton>
  </Wrapper>
);

export default StepFinal;

StepFinal.propTypes = {
  provider: PropTypes.string.isRequired,
  onConfirm: PropTypes.instanceOf(Function).isRequired,
};
