import styled from 'styled-components';

import Text from 'components/Text';

export default styled(Text)`
  color: var(--text-white);
  white-space: nowrap;
  font-family: var(--google-sora-cdn);
  font-size: 29.17px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 25px;
`;
