import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 0;
  border-bottom: 1px solid #e7e7e7;
  min-height: 130px;
  line-height: 160%;

  > div {
    width: 50%;

    &:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media screen and (max-width: 1400px) {
    min-height: 70px;
  }

  .medium_name {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 10px;
  }

  .medium_size {
    font-size: 13px;
  }

  .medium_cancel {
    background: transparent;
    border: 0;
    font-size: 0;
    margin: 0 0 0 80px;

    img {
      width: 20px;
    }
  }

  .medium_error {
    font-size: 13px;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      background: var(--error-color);
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
`;
