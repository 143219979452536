import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as TipplyLogo } from 'assets/icons/tipply-logo.svg';

const Wrapper = styled(Link)`
  display: block;
  margin: 0 85px 0 0;

  svg {
    display: block;
    width: 130px;
    height: 46px;
    path {
      fill: var(--text-color);
    }
    ellipse {
      fill: var(--brand-green);
    }
  }
`;

const LogoModeratorPanel = moderatorId => {
  const logoUrl = `/panel-moderatora/${moderatorId.moderatorId.moderatorId}`;
  return (
    <Wrapper to={logoUrl}>
      <TipplyLogo title="Tipply.pl" />
    </Wrapper>
  );
};

export default LogoModeratorPanel;
