import React, { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import StyledFilterButton from '../styles/StyledFilterButton';
import { Consumer as ContextConsumer } from '../Context';

class FilterButton extends Component {
  static propTypes = {
    filterOptions: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);
    const { filterOptions } = this.props;

    this.state = { showList: false, currentFilter: filterOptions[0].value };
  }

  toggleList() {
    const { showList } = this.state;
    this.setState({ showList: showList === false });
  }

  handleListItemClick(option) {
    this.setState({ currentFilter: option.value });
  }

  renderList(filterFunction) {
    const { filterOptions } = this.props;
    const { showList } = this.state;
    const { goals } = store.getState();

    goals.items.forEach(item => {
      const index = filterOptions.findIndex(object => object.key === item.id);
      if (index === -1) {
        filterOptions.push({
          key: item.id,
          value: item.title,
        });
      }
    });

    if (showList) {
      const listItems = filterOptions.map(option => (
        <li key={option.key}>
          <button
            type="button"
            onClick={() => {
              this.handleListItemClick(option);
              filterFunction(option.key);
            }}
          >
            {option.value}
          </button>
        </li>
      ));

      return <ul className="filter-list">{listItems}</ul>;
    }
    return '';
  }

  render() {
    const { currentFilter } = this.state;

    return (
      <ContextConsumer>
        {({ filter }) => (
          <StyledFilterButton onClick={() => this.toggleList()}>
            <div className="filters_wrap">
              <div />
              <div />
              <div />
            </div>
            <span className="text-white-black">{currentFilter}</span>
            {this.renderList(filter)}
          </StyledFilterButton>
        )}
      </ContextConsumer>
    );
  }
}

export default FilterButton;
