import deepmerge from 'deepmerge';

export default (state = [], action) => {
  switch (action.type) {
    case 'UPDATE_USER_DATA':
      return deepmerge(state, action.updatedData);
    case 'REQUEST_PROFILE':
      return {
        ...state,
        failureFetching: false,
        isFetching: true,
      };
    case 'PATCH_USER_PROFILE':
      return {
        ...state,
        isPatching: true,
      };
    case 'COMPLETE_PATCH_USER_PROFILE':
      return {
        ...state,
        isPatching: false,
      };
    case 'FINISH_PATCHING_USER':
      return {
        ...state,
        isPatching: false,
      };
    case 'RECEIVE_USER_PROFILE':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        profile: action.profile,
      };
    case 'RECEIVE_USER_INFO':
      return {
        ...state,
        info: action.info,
      };
    case 'FAILURE_PROFILE':
      return {
        ...state,
        failureFetching: true,
        isFetching: false,
      };
    case 'FAILURE_PATCH_PROFILE':
      return {
        ...state,
        isPatching: false,
      };
    case 'RECEIVE_TOP_DONATORS':
      return {
        ...state,
        topDonators: action.topDonators,
      };
    case 'TOP_DONATORS_DEFINED':
      return {
        ...state,
        topDonators: action.topDonators,
      };
    case 'RECEIVE_ACCOUNTS_INFO':
      return {
        ...state,
        accounts: action.payload,
      };
    case 'RECEIVE_PAYMENT_METHODS':
      if (action.paymentMethods.length === 0) {
        return state;
      }

      return deepmerge(state, { paymentMethods: action.paymentMethods });
    case 'EXTERNAL_TIPS_SOURCE_CONNECTED':
      return {
        ...state,
        info: {
          ...state.info,
          tips_external_source: action.provider,
        },
      };
    case 'EXTERNAL_TIPS_SOURCE_DISCONNECTED':
      // eslint-disable-next-line
      const { tips_external_source, ...info } = state.info;
      return {
        ...state,
        info: {
          ...info,
        },
      };
    case 'TOGGLE_VERIFY_WITH_BANK_WIZARD':
      return {
        ...state,
        showVerifyWithBankTransferModal: !state.showVerifyWithBankTransferModal,
      };
    case 'UPDATE_USER_HAS_PENDING_VALIDATION_REQUEST':
      return {
        ...state,
        info: {
          ...state.info,
          has_pending_banktransfer_validation_request: action.payload,
        },
      };
    case 'PROFILE_FULLY_FETCHED':
      return {
        ...state,
        isFetched: true,
      };
    default:
      return state;
  }
};
