import styled, { css } from 'styled-components';

export default styled.div`
  --item-x-margin: 25px;

  min-width: 280px;
  padding: 7.5px 0;
  border-radius: 10px;

  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
  border: 1px solid var(--white);
  background-color: var(--white);

  max-height: 500px;
  overflow: auto;

  > div > div {
    background-color: var(--white);
  }
`;

const edited = css`
  > span {
    opacity: 1;
    visibility: visible;
  }

  &::before {
    transform: translateY(-50%) scale(1);
  }
`;

const disabled = css`
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
  &:active {
    pointer-events: none;
  }
`;

export const OptionsPopupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  width: 100%;
  padding: 7.5px var(--item-x-margin);

  border: 0;
  background-color: transparent;
  color: ${props => (props.color === 'red' ? 'var(--red)' : 'var(--text-color-dark-gray)')};

  font-size: 13px;
  text-align: left;

  cursor: pointer;

  transition: 0.2s ease;

  > span {
    color: var(--text-color-medium-gray);
    font-size: 10px;
    text-transform: uppercase;
    opacity: 0;
    visibility: hidden;
  }

  > span,
  &::before {
    transition: 0.2s ease;
  }

  &:hover {
    background-color: var(--bg-color-light-secondary-gray);
  }

  ${params => params.edited && edited};
  ${params => params.disabled && disabled};
`;
