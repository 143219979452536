import styled from 'styled-components';

export default styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: var(--item-x-margin);
  margin-top: 15px;
  margin-bottom: 8px;

  color: var(--gray);
`;
