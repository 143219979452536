import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralSettingsButton } from 'components/Buttons';

export const GoalsBlueButton = withRouter(({ history }) => (
  <GeneralSettingsButton
    info="Aby ustalić nazwę celu lub kwotę jaką chcesz zebrać, kliknij poniżej"
    label="Przejdź do ustawień celów"
    color="blue"
    onClick={() => history.push('/cele')}
  />
));
