import React from 'react';

import OnBoardingDialog from 'components/OnBoardingDialog';

const OnBoarding = props => (
  <OnBoardingDialog id="voting" {...props}>
    <OnBoardingDialog.Title>
      Stwórz głosowanie i
      <br />
      konkurencję
      <br />
      celów!
    </OnBoardingDialog.Title>
    <OnBoardingDialog.Description>
      Niech widzowie dokonują wyborów!
      <br />
      Nowa forma interakcji z widzami,
      <br />
      oraz z celami w Tipply.
    </OnBoardingDialog.Description>
    <OnBoardingDialog.List>
      <OnBoardingDialog.ListItem>
        Zorganizuj głosowanie i daj swoim
        <br />
        widzom wybór! Niech wybiorą
        <br />
        czy lubią np. PC czy Konsolę!
      </OnBoardingDialog.ListItem>
      <OnBoardingDialog.ListItem>
        Wybierz datę wskazującą
        <br />
        kiedy nadejdzie koniec głosowania
        <br />
        {'i kiedy opublikujesz jego wyniki!'}
      </OnBoardingDialog.ListItem>
      <OnBoardingDialog.ListItem>
        Daj się ponieść wyobraźni!
        <br />
        Baw się wspólnie ze swoją widownią.
        <br />
        Zachęcaj fanów do zabawy
        <br />
        {'i twórzcie angażujące ankiety!'}
      </OnBoardingDialog.ListItem>
    </OnBoardingDialog.List>
  </OnBoardingDialog>
);

export default OnBoarding;
