import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as Mic } from 'assets/icons/microphone.svg';

export const SoundPlayButton = ({ audioUrl }) => {
  const audio = new Audio(audioUrl);

  return (
    <>
      <button
        type="button"
        onClick={() => {
          audio.play();
        }}
        title="Odtwarzanie nagrania audio"
      >
        <Mic fill="#7d7d7d" />
      </button>
    </>
  );
};

SoundPlayButton.propTypes = {
  audioUrl: PropTypes.string.isRequired,
};
