import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ButtonStyle from 'styles/Button';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const HoverArea = styled.div`
  position: absolute;
  top: -40px;
  right: -40px;
  left: -40px;
  bottom: -40px;
`;

const transition = css`
  transition: 0.2s all ease-out;
`;

const Button = styled(ButtonStyle)`
  background-image: linear-gradient(to bottom, #474747, #373737);
  color: var(--white-white);
  opacity: 0.65;
  margin: 0;
  padding: 12px 44px 11px;
  ${transition};

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        opacity: 1;
      }

      &:active {
        transform: translateY(2px);
      }
    `};
`;

const ButtonInner = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  gap: 17px;

  svg {
    width: 14px;
    height: 11px;

    path {
      fill: var(--white-white);
    }
  }
`;

const Label = styled.span`
  font-family: var(--gilroy-font);
  text-transform: uppercase;
  display: inline-block;
  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  ${transition}
  ${props =>
    props.shifted === 'shift'
      ? css`
          transform: translateX(-12px);
        `
      : ''}
`;

const Arrow = styled(ArrowRight)`
  position: absolute;
  top: calc(50% - 7px);

  > div {
    color: #ffff00;
  }

  right: 0;
  width: 14px;
  height: 11px;
  opacity: 0;
  ${transition}
  ${props =>
    props.shifted === 'shift'
      ? css`
          transform: translateX(12px);
          opacity: 1;
        `
      : ''}
`;

const ButtonWithAnimatedArrow = ({
  onClick,
  children,
  disabled = false,
  earlyHoverDetect = true,
}) => {
  const [hovered, setHovered] = useState(false);

  const hoverProps = !disabled
    ? {
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
      }
    : {};

  return (
    <Wrapper>
      <Button rounded disableHover {...hoverProps} onClick={onClick} disabled={disabled}>
        <ButtonInner>
          <Label shifted={hovered ? 'shift' : undefined}>{children}</Label>
          <Arrow shifted={hovered ? 'shift' : undefined} />
        </ButtonInner>
      </Button>
      {earlyHoverDetect && <HoverArea {...hoverProps} />}
    </Wrapper>
  );
};

export default ButtonWithAnimatedArrow;

ButtonWithAnimatedArrow.propTypes = {
  onClick: PropTypes.instanceOf(Function).isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  earlyHoverDetect: PropTypes.bool,
};

ButtonWithAnimatedArrow.defaultProps = {
  disabled: false,
  earlyHoverDetect: true,
};
