import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import Container from 'components/styled/Container';
import InfoTooltip from 'components/InfoTooltip';
import Points from 'components/Points';
import Text from 'components/Text';

import { H2 } from 'styles/ui/headings';
import WhiteBox from 'styles/WhiteBox';

import Threshold from './components/Threshold';

import { Provider } from './Context';

const GridContainer = styled(Container)`
  display: grid;
  grid-gap: 15px 0;
  width: 460px;
  margin-left: auto;
  margin-right: auto;

  > div {
    box-shadow: none;
  }
`;

const PointsAndBonuses = ({
  fetchCommissionsIfNeeded,
  fetchPaymentMethods,
  fetchInitStatistics,
  statisticsData,
  userData,
  commissionsThresholds,
  statisticsAreFetched,
}) => {
  const momentInstance = moment();
  const resetDays = momentInstance.daysInMonth() - momentInstance.date();
  const [foundThreshold, setFoundThreshold] = useState(false);

  const standardThreshold =
    commissionsThresholds[0] && commissionsThresholds[0].threshold === 0
      ? commissionsThresholds[0]
      : null;

  useEffect(() => {
    fetchCommissionsIfNeeded();

    fetchPaymentMethods();
    fetchInitStatistics();
  }, []);

  if (statisticsAreFetched) {
    for (let i = commissionsThresholds.length; i > 0; i -= 1) {
      if (
        commissionsThresholds[i - 1].threshold <= userData.info.commission_threshold &&
        foundThreshold === false
      ) {
        setFoundThreshold(commissionsThresholds[i - 1].threshold);
        break;
      }
    }
  }

  return (
    <Provider
      value={{
        statisticsData,
      }}
    >
      <GridContainer>
        <Points value={statisticsData.points} initExpanded />

        <WhiteBox style={{ padding: '40px 30px 20px' }}>
          <H2 style={{ display: 'inline-block' }}>
            Zwiększanie stawek
            <InfoTooltip
              style={{ position: 'absolute', top: -10, right: -20 }}
              content="Zarabiając większe kwoty na naszym serwisie, twoje stawki poszybują w górę! Przygotowaliśmy dla Was parę progów, po których przekroczeniu wasze wynagrodzenie wzrasta! Dziękujemy za zaangażowanie w rozwój serwisu!"
              placement="right"
              theme="dark"
            />
          </H2>
          <Text color="blue" weight="medium">
            Zarabiając dużo, zarabiasz jeszcze więcej!
          </Text>

          {commissionsThresholds.map((threshold, index) => {
            if (threshold.threshold === 0) {
              return (
                <Fragment key={threshold.threshold}>
                  <Threshold
                    data={threshold}
                    standardThreshold={null}
                    current={foundThreshold === threshold.threshold}
                  />

                  {commissionsThresholds.length > 1 && (
                    <Text size={12} color="light-gray" margin="30px 0" className="text-center">
                      Stawki, które możesz zdobyć:
                    </Text>
                  )}
                </Fragment>
              );
            }

            return (
              <Threshold
                key={threshold.threshold}
                data={threshold}
                standardThreshold={standardThreshold}
                current={foundThreshold === threshold.threshold}
              />
            );
          })}

          <Text margin="40px 0 0" className="text-center">
            Powiększone wynagrodzenie jest ważne
            <br />
            jeszcze przez:&nbsp;
            <Text tag="span" color="blue">
              {`${resetDays} ${resetDays === 1 ? 'dzień' : 'dni'}.`}
            </Text>
            <br />
            <br />
            Każdego 1 dnia miesiąca progres się resetuje.
          </Text>
        </WhiteBox>
      </GridContainer>
    </Provider>
  );
};

PointsAndBonuses.propTypes = {
  fetchCommissionsIfNeeded: PropTypes.instanceOf(Function).isRequired,
  fetchPaymentMethods: PropTypes.instanceOf(Function).isRequired,
  fetchInitStatistics: PropTypes.instanceOf(Function).isRequired,
  statisticsData: PropTypes.instanceOf(Object).isRequired,
  userData: PropTypes.instanceOf(Object).isRequired,
  commissionsThresholds: PropTypes.instanceOf(Array).isRequired,
  statisticsAreFetched: PropTypes.bool.isRequired,
};

export default PointsAndBonuses;
