import React from 'react';
import PropTypes from 'prop-types';

import bytesToSize from 'utils/bytesToSize';

import StyledSpaceUsage from '../styles/StyledSpaceUsage';
import CONFIG from '../config';
import Progress from './Progress';

const SpaceUsage = ({ usage }) => (
  <StyledSpaceUsage>
    <p>Przestrzeń dla Ciebie</p>
    <p>{`${bytesToSize(usage)} / ${bytesToSize(CONFIG.allowedItemsWeight)}`}</p>

    <Progress value={(usage * 100) / CONFIG.allowedItemsWeight} />
  </StyledSpaceUsage>
);

SpaceUsage.propTypes = {
  usage: PropTypes.number.isRequired,
};

export default SpaceUsage;
