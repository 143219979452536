import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

export const BaseRoundedInputStyles = css`
  border-radius: 23px;
  border: 1px solid var(--border-color);
  color: var(--text-color-gray);
  padding: 12px 35px 12px 15px;
  width: 100%;
  background: var(--bg-color-white-gray);
  box-shadow: none;
  outline: none;
`;

export const SwitchContainer = styled.div`
  ${BaseRoundedInputStyles};
  padding: 8px 15px 4px;
  text-align: center;
`;

export const RoundedTextInput = styled.input.attrs({ type: 'text' })`
  ${BaseRoundedInputStyles}
`;

export const RoundedCurrencyInput = styled(NumberFormat).attrs({
  suffix: ' PLN',
  decimalSeparator: ',',
  decimalScale: 2,
  allowEmptyFormatting: true,
  allowNegative: false,
})`
  ${BaseRoundedInputStyles}
`;
