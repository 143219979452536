import styled from 'styled-components';

export default styled.div`
  font-size: 14px;
  padding: 7px 30px;
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  font-weight: ${props => (props.isActive ? 700 : 400)};

  &:hover {
    background-color: var(--border-color);
  }
`;
