import styled from 'styled-components';

import { MIN_DESKTOP } from 'styles/base/breakpoints';

export const BlueDotSeparator = styled.span`
  display: none;
  color: var(--brand-blue);
  margin: 0 9px 0 6px;
  font-size: 22px;
  font-family: var(--google-sora-cdn);
  @media (${MIN_DESKTOP}) {
    display: inline;

    &::after {
      content: '\\2022';
    }
  }
`;
