import styled, { css } from 'styled-components';

const visibilityStyles = css`
  transform: scale(1);
  opacity: 1;
  visibility: visible;
`;

export default styled.div.attrs(({ placement, visible }) => {
  let transformOrigin = 'top';
  let top = 'calc(100% + 10px)';
  let left = '0';

  if (placement === 'right') {
    transformOrigin = 'left';
    top = '0';
    left = 'calc(100% + 10px)';
  }

  return {
    visible,
    transformOrigin,
    top,
    left,
  };
})`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};

  min-width: 240px;
  width: 100%;

  opacity: 0;
  visibility: hidden;

  transform: scale(0);
  transform-origin: ${props => props.transformOrigin};

  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  z-index: 10;

  ${props => props.visible && visibilityStyles};
`;
