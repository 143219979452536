import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Item } from 'react-contexify';

import ConfigContext from '../../../ConfigContext';

const RemoveElementContextMenu = ({ onRemoveElement }) => {
  const { visibleElements } = useContext(ConfigContext);

  return (
    <Menu id="removeElement_contextMenu">
      {visibleElements.map(element => (
        <Item key={element.key} onClick={() => onRemoveElement(element.key)}>
          {element.name}
        </Item>
      ))}
    </Menu>
  );
};

RemoveElementContextMenu.propTypes = {
  onRemoveElement: PropTypes.instanceOf(Function).isRequired,
};

export default RemoveElementContextMenu;
