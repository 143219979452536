import PropTypes from 'prop-types';
import React, { Children } from 'react';

import BigStrokedNumber from './components/BigStrokedNumber';
import Wrapper from './components/Wrapper';

const List = ({ children }) => {
  const items = Children.toArray(children);

  return (
    <Wrapper>
      {Children.map(items, (item, index) => (
        <>
          <BigStrokedNumber>{`${index + 1}.`}</BigStrokedNumber>
          {item}
        </>
      ))}
    </Wrapper>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
};

export default List;
