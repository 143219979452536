import http from 'utils/http';

import shouldFetchData from '../utils/shouldFetchData';

const requestWithdrawals = () => ({
  type: 'REQUEST_WITHDRAWALS',
});

const receiveWithdrawals = receivedData => ({
  type: 'RECEIVE_WITHDRAWALS',
  receivedData,
});

const failureWithdrawals = () => ({
  type: 'FAILURE_WITHDRAWALS',
});

// Todo paginacja

const fetchWithdrawals = (limit = 20, offset = 0) => async dispatch => {
  dispatch(requestWithdrawals());

  try {
    const withdrawals = await http.get(
      `/user/withdrawals?status[]=ACCEPTED&status[]=TRANSFERRED&limit=${limit}&offset=${offset}`,
    );

    dispatch(receiveWithdrawals(withdrawals.data));
  } catch (error) {
    dispatch(failureWithdrawals());
  }
};

// eslint-disable-next-line import/prefer-default-export
export const fetchWithdrawalsIfNeeded = () => (dispatch, getState) => {
  const { withdrawals } = getState();

  if (shouldFetchData(withdrawals)) {
    dispatch(fetchWithdrawals());
  }
};
