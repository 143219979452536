import styled from 'styled-components';

const RootWrapper = styled.div`
  border-radius: var(--block-border-radius);
  padding: 20px;
  background: var(--bg-color-white-gray);
  border: 2px solid var(--dark-box-border);
  margin-bottom: 15px;
`;

export default RootWrapper;
