import styled from 'styled-components';

const DialogWindow = styled.div`
  position: relative;
  margin: auto;

  width: ${props => (props.width ? props.width : 440)}px;
  padding: ${props => (props.noPadding ? '0' : '60px 60px 45px')};
  z-index: 2;

  background-color: var(--white);
  box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.1);
  border: ${props => (props.noBorder ? '0' : '2px solid var(--border-color)')};
  border-radius: 20px;

  text-align: center;

  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);
`;

export default DialogWindow;
