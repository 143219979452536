import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import BackgroundImage from 'assets/images/configurators-backgrounds/1.png';
import Button from 'components/Button';
import OnScreenWarning from '../../../components/OnScreenWarning';

const Container = styled.div`
  background: url(${BackgroundImage}) no-repeat center center;
  background-size: cover;
  margin-bottom: 15px;
  border-radius: 23px;
  overflow: hidden;
  height: 180px;
  position: relative;

  > iframe {
    border: 0;
    margin: -195px auto 0;
    display: block;
    transform: scale(0.7);
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleButton = styled(Button)`
  color: #333;
`;

export const Preview = ({ widgetUrl, enabled, withVotingWarning }) => {
  const [started, setStared] = useState(enabled);

  return (
    <Container>
      {withVotingWarning && (
        <OnScreenWarning
          style={{
            width: 'auto',
            left: 10,
            top: 10,
          }}
        >
          Cel aktualnie bierze udział w głosowaniu
        </OnScreenWarning>
      )}
      {started ? (
        <iframe src={widgetUrl} width="800" height="600" title="Podgląd" />
      ) : (
        <ButtonWrapper>
          <ToggleButton onClick={() => setStared(prevState => !prevState)}>
            Uruchom podgląd widgetu
          </ToggleButton>
        </ButtonWrapper>
      )}
    </Container>
  );
};

Preview.propTypes = {
  widgetUrl: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  withVotingWarning: PropTypes.bool.isRequired,
};
