import styled, { css } from 'styled-components';

import Button from 'components/Button';

const active = css`
  svg {
    transform: rotateX(180deg);
  }
`;

export default styled(Button)`
  display: block;
  width: 100%;
  font-size: 19px;
  font-weight: 500;
  border-radius: 20px;
  padding: 19px 30px;
  transition: var(--hover-transition);

  &:active {
    transform: scale(0.96);
  }

  svg {
    transition: 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    fill: #fff;
    width: 12px;
    margin-left: 10px;
    top: -1px;
  }

  ${props => props.active && active}
`;
