import React from 'react';

import { usePayPal } from 'components/PayPalConnect/hooks';

import Connected from './Connected';
import NotConnected from './NotConnected';
import RootWrapper from './RootWrapper';

const PayPalButton = () => {
  const { isReady, connected } = usePayPal();

  if (!isReady) return null;

  return <RootWrapper>{connected ? <Connected /> : <NotConnected />}</RootWrapper>;
};

export default PayPalButton;
