import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import InfoTooltip from 'components/InfoTooltip';
import { Container, Buttons, Content, Toggle, DisabledOverlay } from './components';

const getLocalStorageData = itemName => {
  let storage = localStorage.getItem(itemName);

  if (storage !== null) {
    const parsedStorage = JSON.parse(storage);

    if (parsedStorage.constructor === Object) {
      storage = JSON.parse(storage);
    } else {
      storage = {};
    }
  } else {
    storage = {};
  }

  return storage;
};

const CollapsedWhiteBox = ({
  children,
  tooltip,
  isCollapsible,
  localStorageName,
  overlay,
  disabled,
  faded,
  onClickDisabled,
  ...props
}) => {
  const [boxesVisibility, setBoxesVisibility] = useState(() => {
    if (localStorageName === null) return '';

    let storage = getLocalStorageData('boxesVisibility');
    const initComponenttorage = { [localStorageName]: true };

    if (storage[localStorageName] === undefined) {
      storage = {
        ...storage,
        ...initComponenttorage,
      };
    }

    return storage;
  });

  return (
    <Container
      visible={boxesVisibility[localStorageName] || !isCollapsible}
      oHidden={isCollapsible}
      disabled={disabled}
      faded={faded || disabled}
      {...props}
    >
      <Buttons>
        {tooltip && <InfoTooltip content={tooltip} placement="right" theme="dark" />}
        {isCollapsible && (
          <Toggle
            type="button"
            onClick={() => {
              let localStorageValue = getLocalStorageData('boxesVisibility');

              localStorageValue = {
                ...localStorageValue,
                [localStorageName]: !boxesVisibility[localStorageName],
              };

              localStorage.setItem('boxesVisibility', JSON.stringify(localStorageValue));
              setBoxesVisibility(localStorageValue);
            }}
          />
        )}
      </Buttons>

      {disabled && <DisabledOverlay onClick={() => onClickDisabled()} />}

      {overlay &&
        React.cloneElement(overlay, {
          ...overlay.props,
          className: cx(overlay.props.className, 'overlay'),
        })}

      <Content>{children}</Content>
    </Container>
  );
};

CollapsedWhiteBox.propTypes = {
  children: PropTypes.node.isRequired,
  localStorageName: PropTypes.string,
  isCollapsible: PropTypes.bool,
  tooltip: PropTypes.string,
  overlay: PropTypes.node,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  onClickDisabled: PropTypes.instanceOf(Function),
};

CollapsedWhiteBox.defaultProps = {
  isCollapsible: true,
  localStorageName: null,
  tooltip: null,
  overlay: null,
  disabled: false,
  faded: false,
  onClickDisabled: () => {},
};

export default CollapsedWhiteBox;
