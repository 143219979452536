import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  left: calc(50% - 240px);
  width: 480px;
  background-color: var(--yellow-bright);
  border-radius: 17px;
  padding: 10px 20px;
  color: #000;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  text-shadow: none;

  > svg {
    min-width: 20px;
    max-width: 20px;
    fill: currentColor;
  }
`;

export default Wrapper;
