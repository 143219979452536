import styled from 'styled-components';

import WhiteBox from 'styles/WhiteBox';

const Wrapper = styled(WhiteBox)`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding: 25px;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  p {
    font-family: var(--gilroy-font);
    margin: 0 0 5px;

    a {
      font: inherit;
      color: inherit;
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Wrapper;
