import styled from 'styled-components';
import NumberFormat from 'react-number-format';

const Input = styled(NumberFormat)`
  display: block;
  font-family: 'Lato', sans-serif;
  max-width: 7ch;
  font-size: 50px;
  font-weight: 900;
  color: var(--green);
  margin-right: 9px;
  margin-bottom: -4px;
  border: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  background: none;
  outline: none;
`;

export default Input;
