import styled from 'styled-components';

import CircleIcon from 'styles/buttons/CircleIcon';

export default styled(CircleIcon)`
  > svg {
    transform: rotate(-90deg);
    width: 12px;
  }
`;
