import styled, { css } from 'styled-components';

import Value from './Value';

const vertical = css`
  flex-direction: column;

  ${Value} {
    margin: 10px 0 0 0;
  }
`;

export default styled.div`
  display: flex;
  align-items: center;

  .rc-slider {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 10px;

    margin: 5px 0;
    padding: 0;

    touch-action: none;

    .rc-slider-rail,
    .rc-slider-track {
      position: absolute;
      height: 10px;
      border-radius: 10px;
      background-color: var(--light-gray);
    }

    .rc-slider-rail {
      width: 100%;
    }

    .rc-slider-step {
      width: 100%;
      height: 10px;
      background: transparent;
    }

    .rc-slider-handle {
      position: absolute;
      top: 50%;

      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;

      background-color: var(--blue);
      border-radius: 50%;

      cursor: grab;
      touch-action: pan-x;

      box-shadow: none;
      border: 0;
      margin: 0;
    }
  }

  ${Value} {
    margin: 0 0 0 15px;
  }

  ${props => props.position === 'vertical' && vertical}
  ${props =>
    props.alternate &&
    css`
      .rc-slider {
        width: 100%;
        height: 5px;

        .rc-slider-rail,
        .rc-slider-track {
          height: 5px;
        }

        .rc-slider-track {
          background-color: var(--primary-1-light);
        }

        .rc-slider-step {
          height: 5px;
        }

        .rc-slider-handle {
          width: 18px;
          height: 18px;
        }

        ${Value} {
          margin: 0 0 0 25px;
        }
      }
    `}
`;
