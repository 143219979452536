import React from 'react';
import fecha from 'fecha';

import { NotificationPropTypes } from '../types';

import Paragraph from './Paragraph';

const AdminNotification = ({ notification }) => (
  <div>
    <Paragraph>{notification.payload.notification}</Paragraph>

    <Paragraph>
      Data nadania:
      <br />
      {fecha.format(new Date(notification.payload.at), 'DD/MM/YY HH:mm')}
    </Paragraph>
  </div>
);

export default AdminNotification;

AdminNotification.propTypes = NotificationPropTypes;
