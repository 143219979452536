import React from 'react';
import Paragraph from '../Paragraph';

const WithdrawalsActivatedAchievement = () => (
  <div>
    <Paragraph>
      Twoje konto ma już 15 dni! W związku z tym jest ono gotowe by móc zlecić pierwszą wypłatę w
      serwisie! Sprawdź zakładkę wypłat i zobacz jak to działa!
    </Paragraph>
  </div>
);

export default WithdrawalsActivatedAchievement;
