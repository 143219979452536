import deepmerge from 'deepmerge';

import { openAlert } from '../../components/Alert/utils';

export default (state = [], action) => {
  switch (action.type) {
    case 'GOAL_CREATE_REQUEST':
      return {
        ...state,
        isPatching: true,
      };
    case 'GOAL_CREATE_SUCCESS': {
      const { goal, slot } = action.payload;
      return {
        ...state,
        items: [...state.items, goal],
        isPatching: false,
        failureFetching: false,
        createdGoal: {
          goal,
          slot,
        },
      };
    }
    case 'GOAL_CREATE_ERROR':
      openAlert(
        'error',
        'Błąd zapisu',
        'Ups, nie mogę utworzyć nowego celu, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    case 'GOAL_UPDATE_FIELD_REQUEST': {
      const { id, field, value } = action.payload;
      const items = state.items.map(item => {
        if (item.id === id) {
          return deepmerge(item, {
            [field]: value,
          });
        }

        return item;
      });

      return {
        ...state,
        isPatching: true,
        items: [...items],
      };
    }
    case 'GOAL_UPDATE_FIELD_SUCCESS':
      return {
        ...state,
        isPatching: false,
        failureFetching: false,
      };
    case 'GOAL_UPDATE_FIELD_ERROR':
      openAlert(
        'error',
        'Błąd zapisu',
        'Ups, nie mogę zaktualizować celu, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    case 'GOAL_DELETE_REQUEST':
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.id),
        isPatching: true,
      };
    case 'GOAL_DELETE_SUCCESS':
      return {
        ...state,
        isPatching: false,
        failureFetching: false,
      };
    case 'GOAL_DELETE_ERROR':
      openAlert('error', 'Błąd usuwania', 'Ups, nie mogę usunąć celu, spróbuj ponownie później 🥲');
      return {
        ...state,
        isPatching: false,
        failureFetching: true,
      };
    case 'GOALS_FETCH_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'GOALS_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        failureFetching: false,
        items: action.payload,
      };
    case 'GOALS_FETCH_ERROR':
      openAlert(
        'error',
        'Błąd pobierania',
        'Ups, nie mogę pobrać Twoich celów, spróbuj ponownie później 🥲',
      );
      return {
        ...state,
        isFetching: false,
        failureFetching: true,
      };
    case 'GOAL_EMPTY_REQUEST': {
      const items = state.items.map(item => {
        if (item.id === action.id) {
          return deepmerge(item, {
            initial_value: 0,
          });
        }

        return item;
      });

      return {
        ...state,
        isPatching: true,
        items: [...items],
      };
    }
    case 'GOAL_RESET_CREATED_GOAL':
      return {
        ...state,
        createdGoal: null,
      };
    default:
      return state;
  }
};
