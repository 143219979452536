import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close-2.svg';
import { ReactComponent as ConfirmIcon } from 'assets/icons/ok-check.svg';

import { updateGoalField } from 'store/actions/goals';

import TextInput from 'components/TextInput';
import ItemBlueButton from 'components/Modal/styles/ItemBlueButton';
import ItemRedButton from 'components/Modal/styles/ItemRedButton';

const Wrapper = styled.div`
  flex: 1;

  label {
    font-family: var(--google-sora-cdn);
    border-radius: 30px;
    border: solid 2px var(--border-color);
    background-color: var(--white-box-background);
    padding: 13.5px 29px 11.5px;
    font-size: 13px;
    line-height: 1.15;
  }

  input {
    font-family: var(--google-sora-cdn);
    color: var(--text-color);
  }
`;

const GoalTitleInput = ({ goal, onClose }) => {
  const { id, title } = goal;

  const dispatch = useDispatch();
  const [localValue, setLocalValue] = useState(title);
  const [localDisabled, setLocalDisabled] = useState(false);

  const saveTitle = () => {
    setLocalDisabled(true);
    dispatch(updateGoalField(id, 'title', localValue));
    onClose();
  };

  const onTextInputKeyDown = e => {
    if (e.key === 'Enter') {
      saveTitle();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <>
      <Wrapper>
        <TextInput
          autoFocus
          value={localValue}
          onChange={e => setLocalValue(e.target.value)}
          onKeyDown={onTextInputKeyDown}
        />
      </Wrapper>
      <ItemBlueButton onClick={saveTitle} disabled={localDisabled}>
        <ConfirmIcon />
      </ItemBlueButton>
      <ItemRedButton onClick={onClose} disabled={localDisabled}>
        <CloseIcon />
      </ItemRedButton>
    </>
  );
};

GoalTitleInput.propTypes = {
  goal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default GoalTitleInput;
