import React, { useState } from 'react';
import http from 'utils/http';

import { FieldError, Input, InputWrapper, Label } from './components/Form';

export const useConnectFom = (provider, fieldsConfig, processStep, goPrev) => {
  const initialState = {};

  Object.keys(fieldsConfig).forEach(fieldName => {
    initialState[fieldName] = '';
  });

  const [fieldsValues, setFieldsValues] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [backendError, setBackendError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const sendData = () => {
    http
      .post('/user/connect-external-tips-source', {
        provider,
        data: fieldsValues,
      })
      .then(() => {
        processStep();
      })
      .catch(() => {
        setBackendError(true);
        setDisabled(false);
      });
  };

  const validate = () => {
    let currentErrors = { ...initialState };
    let hasErrors = false;

    const validateField = (fieldName, errorMessage) => {
      if (fieldsValues[fieldName].length === 0) {
        currentErrors = {
          ...currentErrors,
          [fieldName]: errorMessage,
        };
        hasErrors = true;
      }
    };

    Object.keys(fieldsConfig).forEach(key => {
      validateField(key, fieldsConfig[key]);
    });

    if (hasErrors) {
      setErrors(currentErrors);
    } else {
      setErrors({ ...initialState });
      setDisabled(true);
      sendData();
    }
  };

  const prev = () => {
    setFieldsValues({ ...initialState });
    goPrev();
  };

  const renderField = (fieldName, label) => (
    <InputWrapper hasError={errors[fieldName]} disabled={disabled} mb={0}>
      <Label>
        {label}
        <Input
          type="text"
          value={fieldsValues[fieldName]}
          onChange={e =>
            setFieldsValues({
              ...fieldsValues,
              [fieldName]: e.target.value,
            })
          }
        />
      </Label>
      {errors[fieldName] && <FieldError>{errors[fieldName]}</FieldError>}
    </InputWrapper>
  );

  return {
    disabled,
    backendError,
    renderField,
    validate,
    prev,
  };
};
