import React from 'react';
import PropTypes from 'prop-types';

import gifSEStep1 from 'assets/images/StreamElementsTutorial/gif_SE_step_1.gif';
import { TutorialImage } from 'styles/TutorialImages';

import Text from 'components/Text';
import Link from 'components/Link';

import StepContent from '../../../components/StepContent';

const Step4 = ({ processStep, goPrev }) => (
  <StepContent provider="stream_elements" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      Jeśli nie posiadasz konta, musisz rozpocząć od rejestracji w serwisie. Wejdź na:
    </Text>
    <Text align="center" mb={30}>
      <Link href="https://streamelements.com/" target="_blank">
        https://streamelements.com/
      </Link>
    </Text>
    <Text align="center" mb={20}>
      Rozpocznij proces rejestracji wybierając dowolne socialmedia dzięki któremu będziesz logował
      się do serwisu StreamElements.
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifSEStep1} />
  </StepContent>
);

export default Step4;

Step4.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
