import React from 'react';
import PropTypes from 'prop-types';
import gifTipeeeImage from 'assets/images/TipeeeTutorial/gif_Tipeee_step_4.gif';
import { TutorialImage } from 'styles/TutorialImages';
import Text from 'components/Text';

import StepContent from '../../../components/StepContent';

const Step7 = ({ processStep, goPrev }) => (
  <StepContent provider="tipeee" processStep={processStep} goPrev={goPrev}>
    <Text lineHeight={1.58} align="center" mb={20}>
      {'Uzupełniamy potrzebne dane. '}
      <strong>
        Bardzo ważnym jest, aby w rubryce „Currency”, wybrać walutę EURO, gdyż po wybraniu USD,
        płatności Paysafecard zostaną wyłączone.
      </strong>
      {
        ' Jeśli wybierzesz błędną walutę, będziesz musiał/a skontaktować się bezpośrednio z Tipeee w celu zmiany waluty.'
      }
    </Text>
    <TutorialImage style={{ marginBottom: 32 }} src={gifTipeeeImage} />
    <Text lineHeight={1.58} align="center" mb={20}>
      Po uzupełnieniu danych klikamy „Active My Account”
    </Text>
  </StepContent>
);

export default Step7;

Step7.propTypes = {
  processStep: PropTypes.instanceOf(Function).isRequired,
  goPrev: PropTypes.instanceOf(Function).isRequired,
};
