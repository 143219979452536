import deepmerge from 'deepmerge';
import debounce from 'lodash/debounce';
import io from 'socket.io-client';
import WebFont from 'webfontloader';

import http from 'utils/http';

import serverAlert from '../utils/serverAlert';
import shouldFetchData from '../utils/shouldFetchData';
import loadWebfontsFromTemplates from '../utils/loadWebfontsFromTemplates';

import { updateConfig } from './configuratorsConfigs';

const socket = io(process.env.REACT_APP_SOCKET_URL);

export const changeActiveTemplate = (templateId, templateType) => ({
  type: 'CHANGE_ACTIVE_TEMPLATE',
  templateId,
  templateType,
});

const requestTemplates = () => ({
  type: 'REQUEST_TEMPLATES',
});

const receiveTemplates = templates => ({
  type: 'RECEIVE_TEMPLATES',
  templates,
});

const updateLocalTemplate = (id, updatedData) => ({
  type: 'UPDATE_TEMPLATE',
  id,
  updatedData,
});

const deleteLocalTemplate = id => ({
  type: 'DELETE_TEMPLATE',
  id,
});

export const cancelCreateTemplate = () => ({
  type: 'CANCEL_CREATE_TEMPLATE',
});

const insertTemplate = template => ({
  type: 'INSERT_TEMPLATE',
  template,
});

const failureTemplates = () => ({
  type: 'FAILURE_TEMPLATES',
});

export const fetchTemplates = () => dispatch => {
  dispatch(requestTemplates());

  return http
    .get('/user/templates')
    .then(response => response.data)
    .then(templates => {
      // check and remove empty keys
      templates.forEach(item => {
        if (item.type === 'TIP_ALERT') {
          const o = item.config.elementsOptions;
          for (const k in o) {
            if (!k) {
              delete o[k];
            }
          }
        }
      });
      loadWebfontsFromTemplates(templates);
      dispatch(receiveTemplates(templates));
    })
    .catch(error => {
      serverAlert('Wystąpił błąd podczas pobierania szablonów.');
      dispatch(failureTemplates());
    });
};

export const fetchTemplatesIfNeeded = () => (dispatch, getState) => {
  const { templates } = getState();

  if (shouldFetchData(templates)) {
    dispatch(fetchTemplates());
  }
};

export const createTemplate = (templateConfig, type) => (dispatch, getState) => {
  const {
    userData: { info: userInfo },
  } = getState();

  dispatch({ type: 'CREATE_TEMPLATE' });

  return http
    .post(`/user/templates/${type}`, templateConfig)
    .then(response => {
      const newTemplate = {
        id: response.data,
        type,
        config: { ...templateConfig },
        updated_at: new Date().toString(),
      };

      dispatch(insertTemplate(newTemplate));

      socket.emit('template', type, userInfo.id, {
        action: 'created',
        template: newTemplate,
      });

      return response;
    })
    .catch(error => {
      serverAlert('Wystąpił błąd podczas tworzenia szablonu.');

      throw error;
    });
};

const debouncedUpdate = debounce((active, config, userId, templateType) => {
  http
    .put(`/templates/${active}`, config)
    .then(() => {
      socket.emit('template', templateType, userId, {
        action: 'updated',
        id: active,
        config,
      });
    })
    .catch(error => {
      serverAlert('Wystąpił błąd przy aktualizacji szablonu');
    });
}, 1000);

export const updateTemplate = (updatedFragment, templateId = null, type) => (
  dispatch,
  getState,
) => {
  const {
    templates: { active, created },
    userData: { info: userInfo },
  } = getState();

  const targetTemplateId = templateId || active[type];

  const editedTemplate = created.find(template => template.id === targetTemplateId);

  if (editedTemplate) {
    const updatedData = deepmerge(editedTemplate, {
      updated_at: new Date().toString(),
      config: updatedFragment,
    });
    dispatch(updateLocalTemplate(targetTemplateId, updatedData));

    return debouncedUpdate(targetTemplateId, updatedData.config, userInfo.id, type);
  }

  return new Promise();
};

export const deleteTemplate = (templateId, type) => (dispatch, getState) => {
  const {
    templates: { defaults },
    configuratorsConfigs: {
      configurations: {
        TIP_ALERT: {
          displaySettings: {
            defaults: {
              templates: { templateId: defaultAlertTemplateId },
            },
            tresholds: { templates: thresholds },
          },
        },
      },
    },
  } = getState();

  const filteredTemplates = defaults.filter(template => template.type === type);
  const defaultTemplateId = filteredTemplates[0].id;

  return http
    .delete(`/templates/${templateId}`)
    .then(() => dispatch(changeActiveTemplate(defaultTemplateId, type)))
    .then(response => {
      dispatch(deleteLocalTemplate(templateId));

      const configFragment = {};

      if (defaultAlertTemplateId === templateId) {
        configFragment.defaults = {
          templates: {
            templateId: defaultTemplateId,
          },
        };
      }

      if (thresholds.filter(item => item.templateId === templateId).length > 0) {
        configFragment.tresholds = {
          templates: thresholds.map(item => {
            if (item.templateId === templateId) {
              return {
                ...item,
                templateId: defaultTemplateId,
              };
            }
            return item;
          }),
        };
      }

      if (Object.keys(configFragment).length > 0) {
        dispatch(updateConfig('TIP_ALERT', { displaySettings: configFragment }));
      }

      return response;
    })
    .catch(error => {
      serverAlert('Wystąpił błąd podczas usuwania szablonu.');
      throw error;
    });
};

export const setCurrentElement = name => ({
  type: 'SET_CURRENT_ELEMENT',
  name,
});

export const setCustomFont = fontItem => (dispatch, getState) => {
  const {
    templates: { created, currentTemplateId, currentTemplateType, currentElementName },
    userData: { info: userInfo },
  } = getState();

  const targetTemplate = created.find(template => template.id === currentTemplateId);

  if (!targetTemplate) return null;

  const newFontFamily = fontItem.name.replace('.ttf', '');
  const templateElements = targetTemplate.config.elementsOptions;

  const updatedFragment = {};

  if (
    ['TIP_ALERT', 'COUNTER_TO_END_LIVE', 'TIPS_GOAL'].includes(currentTemplateType) &&
    [
      'price',
      'message',
      'username',
      'usernameAction',
      'usernameAction1',
      'usernameAction2',
      'usernameAction3',
      'textInput',
      'text',
      'additionalTime',
      'goalName',
      'amountPaid',
    ].includes(currentElementName)
  ) {
    updatedFragment.elementsOptions = deepmerge(templateElements, {
      [currentElementName]: {
        styles: {
          fontFamily: newFontFamily,
        },
      },
    });
  }

  if (
    ['LARGEST_DONATES', 'LATEST_DONATES'].includes(currentTemplateType) &&
    ['nickname', 'colon', 'price', 'counter'].includes(currentElementName)
  ) {
    updatedFragment.elementsOptions = deepmerge(templateElements, {
      list: {
        children: {
          [currentElementName]: {
            styles: {
              fontFamily: newFontFamily,
            },
          },
        },
      },
    });
  }

  if (
    currentTemplateType === 'TIPS_GOAL' &&
    ['to', 'from', 'percent', 'separator'].includes(currentElementName)
  ) {
    updatedFragment.elementsOptions = deepmerge(templateElements, {
      goalNumbers: {
        children: {
          [currentElementName]: {
            styles: {
              fontFamily: newFontFamily,
            },
          },
        },
      },
    });
  }

  const updatedData = deepmerge(targetTemplate, {
    updated_at: new Date().toString(),
    config: updatedFragment,
  });

  dispatch(updateLocalTemplate(currentTemplateId, updatedData));

  WebFont.load({
    custom: {
      families: [`${newFontFamily}`],
      urls: [`${process.env.REACT_APP_PROXY_URL}/user/fonts`],
    },
    loading: () => {
      dispatch(changeActiveTemplate(`DEFAULT_${currentTemplateType}_1`, currentTemplateType));
      setTimeout(() => {
        dispatch(changeActiveTemplate(targetTemplate.id, currentTemplateType));
      }, 50);
    },
  });

  return debouncedUpdate(currentTemplateId, updatedData.config, userInfo.id, currentTemplateType);
};
