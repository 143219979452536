import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PanelBoxContent, { defaultStyle, boxStyle } from './PanelBoxContent';

const Root = styled.div`
  ${defaultStyle};
  ${boxStyle};
  width: 100%;
`;

const PanelBlock = ({ style, flashOnce, ...props }) => (
  <Root style={style} flashOnce={flashOnce}>
    <PanelBoxContent {...props} />
  </Root>
);

PanelBlock.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  flashOnce: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

PanelBlock.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  color: undefined,
  flashOnce: false,
  style: undefined,
};
export default PanelBlock;
