import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import RootModal from 'components/Modal';
import * as moderatorActions from 'store/actions/moderator';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { fetchModeratorsIfNeeded } from '../../../store/actions/moderator';
import TipsModerationListBasket from '../../TipsList/blocks/TipsModerationListBasket';

const InfoText = styled.span`
  font-size: 15px;
  color: var(--text-color-light-gray);
`;

const ModeratorBasketModal = ({
  onClose,
  tipsModerationBasket,
  restoreTipModeration,
  fetchTipsModerationBasketForModerators,
  fetchTipsModerationForTipsListPage,
  ...props
}) => {
  const dispatch = useDispatch();
  const scrollHeight = tipsModerationBasket.length * 60;

  useEffect(() => {
    dispatch(fetchModeratorsIfNeeded());
  }, []);

  return (
    <RootModal width={1000} height="auto" onClose={onClose} {...props}>
      <h4 style={{ marginTop: 0 }}>Koszyk odrzuconych wiadomości</h4>
      <InfoText>Klikając ikonę możesz przywrócić wiadomość do moderacji.</InfoText>
      {tipsModerationBasket.length > 5 && (
        <Scrollbars
          className="scrollbar"
          style={{ height: scrollHeight, maxHeight: 600, margin: '20px 0 0 0' }}
        >
          <TipsModerationListBasket
            tipsModerationBasket={tipsModerationBasket}
            restoreTipModeration={restoreTipModeration}
            fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
            fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
          />
        </Scrollbars>
      )}
      {tipsModerationBasket.length <= 5 && (
        <TipsModerationListBasket
          tipsModerationBasket={tipsModerationBasket}
          restoreTipModeration={restoreTipModeration}
          fetchTipsModerationBasketForModerators={fetchTipsModerationBasketForModerators}
          fetchTipsModerationForTipsListPage={fetchTipsModerationForTipsListPage}
        />
      )}
    </RootModal>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ ...moderatorActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ModeratorBasketModal);

ModeratorBasketModal.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
  tipsModerationBasket: PropTypes.instanceOf(Array).isRequired,
  fetchTipsModerationBasketForModerators: PropTypes.instanceOf(Function).isRequired,
  restoreTipModeration: PropTypes.instanceOf(Function).isRequired,
  fetchTipsModerationForTipsListPage: PropTypes.instanceOf(Function).isRequired,
};
