import styled from 'styled-components';

export default styled.button`
  border-style: none;
  background: var(${props => (props.off ? '--light-gray' : '--brand-light-blue')});
  color: ${props => (props.off ? 'var(--text-color)' : 'white')};
  font-size: 15px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 36px;
  border-radius: 20px;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 16px;

  svg {
    height: 19px;

    path {
      fill: currentColor;
    }
  }
`;
