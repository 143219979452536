import React from 'react';
import PropTypes from 'prop-types';

import Shadow from 'pages/Configurators/styles/Shadow';

import FocusedElement from '../../../../../components/FocusedElement';

import ContextMenu from '../ContextMenu';

const Price = props => {
  const { name, options, animation } = props;

  return (
    <FocusedElement style={{ textAlign: options.styles.textAlign }}>
      <Shadow style={{ margin: 0, ...options.styles, backfaceVisibility: 'hidden' }}>
        1 000 000,00 zł
      </Shadow>
      <p
        id={`${name}__screen_element`}
        className={animation}
        style={{
          margin: 0,
          ...options.styles,
          backfaceVisibility: 'hidden',
          display: 'inline-block',
        }}
      >
        10,00 zł
      </p>

      <ContextMenu elementName={name} fields={['editText', 'animation']} />
    </FocusedElement>
  );
};

Price.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  animation: PropTypes.string.isRequired,
};

export default Price;
