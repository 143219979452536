import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, Item, Separator } from 'react-contexify';
import { useDispatch } from 'react-redux';

import { setCurrentElement } from 'store/actions/templates';
import AnimationsContextMenuList from '../../../../components/AnimationsContextMenuList';
import ConfigContext from '../../../../ConfigContext';
import ElementsContext from './ElementsContext';

const ContextMenu = props => {
  const { elementName, fields, onSelectedMediaManager } = props;
  const { toggleTextEditor, textEditor } = useContext(ConfigContext);
  const { updateElement } = useContext(ElementsContext);
  const dispatch = useDispatch();

  const isCurrentElement = textEditor.elementName === elementName;

  const handleEditTextClick = () => {
    const name = !isCurrentElement ? elementName : false;
    toggleTextEditor(name, 'style');

    dispatch(setCurrentElement(name));
  };

  const handleChangeTextClick = () => {
    const name = !isCurrentElement ? elementName : false;
    toggleTextEditor(name, 'text');
  };

  const handleMoveUpClick = () => {
    updateElement({ elementName, mode: 'moveUp' });
  };

  const handleMoveDownClick = () => {
    updateElement({ elementName, mode: 'moveDown' });
  };

  const handleRemoveClick = () => {
    updateElement({ elementName, mode: 'delete' });
  };

  return (
    <Menu id={`elementContextMenu-${elementName}`}>
      {fields.map(field => {
        switch (field) {
          case 'editText': {
            return (
              <Item onClick={handleEditTextClick} key={field}>
                {isCurrentElement ? 'Zakończ edycje' : 'Edytuj tekst'}
              </Item>
            );
          }
          case 'changeText': {
            const isEditing = isCurrentElement && textEditor.mode === 'text';
            return (
              <Item onClick={handleChangeTextClick} key={field}>
                {isEditing ? 'Zakończ edycje' : 'Zmień tekst'}
              </Item>
            );
          }
          case 'setImage':
            return (
              <Item onClick={onSelectedMediaManager} key={field}>
                Ustaw obrazek
              </Item>
            );
          case 'animation':
            return (
              <AnimationsContextMenuList
                key={field}
                label="Wybierz animacje"
                onAnimationSelect={options =>
                  updateElement({ elementName, mode: 'animation', ...options })
                }
              />
            );
          default:
            return null;
        }
      })}

      <Separator />

      <Item disabled={({ props: itemProps }) => itemProps.zIndex === 5} onClick={handleMoveUpClick}>
        Przenieś do przodu
        <span>CTRL +</span>
      </Item>

      <Item
        disabled={({ props: itemProps }) => itemProps.zIndex === 1}
        onClick={handleMoveDownClick}
      >
        Przenieś do tyłu
        <span>CTRL -</span>
      </Item>

      <Separator />

      <Item onClick={handleRemoveClick}>
        Usuń
        <span>DELETE</span>
      </Item>
    </Menu>
  );
};

ContextMenu.propTypes = {
  elementName: PropTypes.string.isRequired,
  fields: PropTypes.instanceOf(Array).isRequired,
  onSelectedMediaManager: PropTypes.instanceOf(Function),
};

ContextMenu.defaultProps = {
  onSelectedMediaManager: () => {},
};

export default ContextMenu;
