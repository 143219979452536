import { createGlobalStyle } from 'styled-components';

import cssVariablesDark from './base/cssVariablesDark';
import fontFaces from './base/fontFaces';
import userFontFaces from './base/userFontFaces';

import reactResizable from './components/react-resizable';

export default createGlobalStyle`
  ${fontFaces};
  ${userFontFaces};

  :root {
    ${cssVariablesDark};
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html, body {
    font-family: var(--base-font);
    font-weight: 400;
    font-size: 14px;

    background: var(--bg-color-white-gray);
    color: var(--text-color);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &::selection {
    background: var(--brand-green);
    color: var(--text-color);
  }

  button {
    cursor: pointer;
  }

  input,
  textarea,
  button {
    outline: none;

    &:disabled {
      cursor: not-allowed;
      user-select: none;
    }
  }

  .scrollbar {
    > div {
      &:first-child {
        padding: 0 10px 0 0;
      }

      &:last-child {
        background-color: var(--border-color);

        > div {
          background-color: var(--gray) !important;
        }
      }
    }

    &--outside {
      width: calc(100% + 30px) !important;
      left: -15px;

      > div {
        &:first-child {
          padding: 0 15px;
        }
      }
    }
  }

  b, strong {
    font-weight: 700;
  }

  ${reactResizable};

  .spinner {
    animation: rotatorSpinner 1.4s linear infinite;

    .spinner-path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dashSpinner 1.4s ease-in-out infinite;
      stroke: #fff;
    }
  }

  @keyframes rotatorSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes dashSpinner {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
              transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
              transform: rotate(450deg);
    }
  }
`;
