import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SwapImageIcon } from 'assets/icons/image-swap.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import ConfigContext from '../ConfigContext';
import DeleteButton from './DeleteButton';
import { StyledButton } from './ElementActionButton';
import ElementActions from './ElementActions';

const ImageActions = ({ enabled }) => {
  const {
    focusedElementName,
    handleImageChange,
    handleLayerPositionChange,
    handleRemoveElement,
  } = useContext(ConfigContext);

  const handleGraphicChange = evt => {
    if (enabled) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      handleImageChange();
    }
  };

  const eventHandler = (evt, option) => {
    if (enabled) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      handleLayerPositionChange(option);
    }
  };

  const handleLayerUpClick = evt => {
    eventHandler(evt, 'moveUp');
  };

  const handleLayerDownClick = evt => {
    eventHandler(evt, 'moveDown');
  };

  const handleRemoveClick = () => {
    handleRemoveElement(focusedElementName);
  };

  return (
    <ElementActions>
      <StyledButton onClick={handleGraphicChange} blue>
        <SwapImageIcon />
        Zmień obraz
      </StyledButton>

      <StyledButton onClick={handleLayerUpClick}>
        <ArrowUpIcon />
        Warstwa do góry
      </StyledButton>

      <StyledButton onClick={handleLayerDownClick}>
        <ArrowDownIcon />
        Warstwa do dół
      </StyledButton>

      <DeleteButton onClick={handleRemoveClick} />
    </ElementActions>
  );
};

ImageActions.propTypes = {
  enabled: PropTypes.bool,
};

ImageActions.defaultProps = {
  enabled: false,
};

export default ImageActions;
