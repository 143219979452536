import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import StyledDropDown from './styles/StyledDropDown';

class DropDown extends Component {
  static propTypes = {
    parent: PropTypes.instanceOf(Element).isRequired,
    children: PropTypes.instanceOf(Object).isRequired,
    outsideClick: PropTypes.instanceOf(Function),
    show: PropTypes.bool.isRequired,
    unmountOnExit: PropTypes.bool,
  };

  static defaultProps = {
    unmountOnExit: false,
    outsideClick: () => {},
  };

  dropDownRef = React.createRef();

  componentWillMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleOutsideClick = e => {
    const { outsideClick, show, parent } = this.props;

    if (parent.contains(e.target) || this.dropDownRef.current.contains(e.target) || !show) {
      return;
    }

    outsideClick();
  };

  render() {
    const { children, show, unmountOnExit } = this.props;
    this.click = show ? 0 : this.click;

    return (
      <div ref={this.dropDownRef}>
        <CSSTransition in={show} timeout={250} classNames="fade" unmountOnExit={unmountOnExit}>
          <StyledDropDown {...this.props}>{children}</StyledDropDown>
        </CSSTransition>
      </div>
    );
  }
}

export default DropDown;
