import styled from 'styled-components';

export default styled.div`
  width: 1120px;
  height: 1400px;
  border: 1px solid #fff;

  @media screen and (min-height: 768px) {
    width: 440px;
    height: 550px;
  }

  @media screen and (min-height: 900px) {
    width: 520px;
    height: 650px;
  }

  @media screen and (min-height: 1024px) {
    width: 640px;
    height: 800px;
  }

  @media screen and (min-height: 1080px) {
    width: 720px;
    height: 907px;
  }

  @media screen and (min-height: 1200px) {
    width: 800px;
    height: 1000px;
  }

  @media screen and (min-height: 1440px) {
    width: 960px;
    height: 1200px;
  }

  @media screen and (min-height: 1700px) {
    width: 1120px;
    height: 1400px;
  }

  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  margin: 0 auto 10px;
`;
