import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const rootStyle = css`
  color: #adadad;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  width: 100%;
  padding: 6px 0;

  font-family: var(--google-sora-cdn);
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    color: #ddd;
  }

  svg {
    height: 9px;
  }
`;

const Root = styled.button`
  ${rootStyle};
  background: transparent none;
  border: none;
  outline: none;
`;

const RootLink = styled(Link)`
  ${rootStyle};

  text-decoration: none;
`;

/**
 * Text Button link used to do be displayed
 * in the white-box content
 * @param {React.ReactNode} children
 * @param {React.ReactNode} [startIcon]
 * @param {React.ReactNode} [endIcon]
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const BoxLinkButton = ({ children, startIcon, endIcon, href, ...props }) => {
  const content = (
    <>
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </>
  );

  if (href)
    return (
      <RootLink to={href} {...props}>
        {content}
      </RootLink>
    );

  return <Root {...props}>{content}</Root>;
};

BoxLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  href: PropTypes.string,
};

BoxLinkButton.defaultProps = {
  startIcon: undefined,
  endIcon: undefined,
  href: undefined,
};

export default BoxLinkButton;
