import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Overlay from './components/Overlay';
import Wrapper from './components/Wrapper';
import DialogWindow from './components/DialogWindow';

const DialogContainer = styled.div`
  padding: 30px 0 60px;
  width: 100%;
  min-height: 100dvh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const Dialog = ({ children, isVisible, width, noPadding = false, noBorder = false }) => (
  <Wrapper isVisible={isVisible}>
    <Overlay />
    <DialogContainer>
      <DialogWindow width={width} noPadding={noPadding} noBorder={noBorder}>
        {children}
      </DialogWindow>
    </DialogContainer>
  </Wrapper>
);

export default Dialog;

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  width: PropTypes.number,
  noPadding: PropTypes.bool,
  noBorder: PropTypes.bool,
};

Dialog.defaultProps = {
  noPadding: false,
  noBorder: false,
  width: null,
};
