import styled from 'styled-components';

import Content from './Content';
import Overlay from './Overlay';

export default styled.div`
  position: absolute;
  z-index: 100;

  &.fade-enter {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0);
    }
  }

  &.fade-enter-active,
  &.fade-enter-done {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0.7);
    }

    ${Content} {
      height: calc(100% - 30px);
      opacity: 1;
    }
  }

  &.fade-exit,
  &.fade-exit-active {
    ${Overlay} {
      background-color: rgba(9, 13, 47, 0);
      visibility: hidden;
      transition: all ${props => props.unmountDelay}ms cubic-bezier(0.17, 0.84, 0.44, 1);
    }

    ${Content} {
      transition: all ${props => props.unmountDelay - 200}ms cubic-bezier(0.17, 0.84, 0.44, 1);
      transform: scale(0.2) translate(-50%, -50%);
      height: 400px;
      opacity: 0;
    }
  }
`;
