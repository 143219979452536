import deepmerge from 'deepmerge';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextStyleMenu from 'components/ElementEditor/editors/TextEditor/TextStyleMenu';
import DragWrapper from '../../../../components/DragWrapper/DragWrapper';
import ConfigContext from '../../../../ConfigContext';
import Price from './types/Price';
import Message from './types/Message';
import Username from './types/Username';
import UsernameAction from './types/UsernameAction';
import VisualObject from './types/VisualObject';
import ElementsContext from './ElementsContext';

const Element = props => {
  switch (props.name) {
    case 'price':
      return <Price {...props} />;

    case 'message':
      return <Message {...props} />;

    case 'username':
      return <Username {...props} />;

    case 'usernameAction':
    case 'usernameAction1':
    case 'usernameAction2':
    case 'usernameAction3':
      return <UsernameAction {...props} />;

    case 'visualObject':
    case 'visualObject1':
    case 'visualObject2':
    case 'visualObject3':
      return <VisualObject {...props} />;

    default:
      return null;
  }
};

Element.propTypes = {
  name: PropTypes.string.isRequired,
};

const ElementDragWrapper = ({ elementName }) => {
  const {
    elementConfigs,
    configuratorType,
    latestTemplateConfig,

    focusedElementName,
    handleFocusElement,
    moveElementLayer,
    handleLayerPositionChange,

    runAnimation,
    animationDirection,

    textEditor,
    toggleTextEditor,
    configuratorWrapperRef,
  } = useContext(ConfigContext);

  const { handleKeyDownElement, handleContextMenu, updateElement } = useContext(ElementsContext);

  function computesStyles(latestConfig) {
    const defaultOptions = elementConfigs[elementName];
    return deepmerge(defaultOptions, latestConfig.elementsOptions[elementName]);
  }

  const [elementOptions, setElementOptions] = useState(computesStyles(latestTemplateConfig));
  const [isFocused, setIsFocused] = useState(focusedElementName === elementName);
  const [isTextStyleMenuMounted, setIsTextStyleMenuMounted] = useState(
    textEditor.elementName === elementName,
  );

  useEffect(() => {
    setElementOptions(computesStyles(latestTemplateConfig));
  }, [latestTemplateConfig]);

  useEffect(() => {
    setIsFocused(focusedElementName === elementName);
  }, [focusedElementName]);

  useEffect(() => {
    setIsTextStyleMenuMounted(textEditor.elementName === elementName);
  }, [textEditor]);

  useEffect(() => {
    if (isFocused && ['moveUp', 'moveDown'].includes(moveElementLayer)) {
      updateElement({ elementName, mode: moveElementLayer });
      handleLayerPositionChange('');
    }
  }, [moveElementLayer]);

  const handleStyleMenuClose = () => {
    toggleTextEditor('');
  };

  let animationClass = '';
  if (!latestTemplateConfig.animation[animationDirection] && runAnimation) {
    animationClass = `animated ${elementOptions.animation[animationDirection]}`;
    if (animationDirection === 'during') {
      animationClass += ' infinite';
    }
  }

  const isVisualObject = elementName.includes('visualObject');

  return (
    <React.Fragment>
      <DragWrapper
        elementName={elementName}
        elementOptions={elementOptions}
        currentPosition={elementOptions.position}
        onClick={() => handleFocusElement(elementName)}
        onDoubleClick={() => toggleTextEditor(elementName, 'style')}
        onKeyDown={e => handleKeyDownElement(e, elementName)}
        onContextMenu={e => {
          handleFocusElement(elementName);
          handleContextMenu(e, elementName);
        }}
        updateElement={updateElement}
        tabIndex="0"
        configuratorType={configuratorType}
        isFocused={isFocused}
        isResizable={!!elementOptions.size}
        resizeAxis="x"
        resizeMinConstraints={[150, 0]}
      >
        <Element
          name={elementName}
          options={elementOptions}
          updateElement={updateElement}
          animation={animationClass}
        />
      </DragWrapper>

      {!isVisualObject && (
        <TextStyleMenu
          elementName={elementName}
          variant={textEditor.variant}
          elementStyle={elementOptions.styles}
          textContent={elementOptions.value || ''}
          showIn={configuratorWrapperRef.current}
          isMounted={isTextStyleMenuMounted}
          snapTo={isTextStyleMenuMounted ? textEditor.target : null}
          onHide={handleStyleMenuClose}
          onChange={style => updateElement({ mode: 'style', elementName, style })}
          onChangeText={text => updateElement({ mode: 'text', elementName, text })}
        />
      )}
    </React.Fragment>
  );
};

ElementDragWrapper.propTypes = {
  elementName: PropTypes.string.isRequired,
};

export default ElementDragWrapper;
