import styled, { css } from 'styled-components';

const text = css`
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  text-align: center;
`;

export default styled.input`
  position: relative;
  top: 2px;
  margin: 0 0 0 60px;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  border: none;
  padding: 0;
  letter-spacing: 0.5px;
  cursor: pointer;
  outline: none;
  background: transparent;

  ${props => props.text && text}
`;
