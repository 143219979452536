import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import styled from 'styled-components';

import Context from '../Context';
import Indicator from '../styles/Indicator';
import IndicatorContainer from '../styles/IndicatorContainer';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
`;

const NameContainer = styled.div`
  width: 100%;
`;

const Name = styled.span`
  font-family: var(--google-sora-cdn);
  font-size: 12px;
  line-height: 1.33;
  color: var(--black);
  font-weight: ${({ isCustom }) => (isCustom ? 600 : 400)};
`;

const Input = styled.input`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  border: none;
  display: inline-block;
  background: color-mix(in srgb, black 25%, transparent) none;
  color: inherit;
  width: 100%;
  padding: 2px 6px;
  box-sizing: border-box;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  visibility: hidden;
`;

const Button = styled.button`
  font-family: var(--google-sora-cdn);
  background: transparent none;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  text-transform: uppercase;
  color: var(--grey);
  font-size: 10px;
  font-weight: 800;
`;

const Root = styled.li`
  position: relative;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 10px auto;
  column-gap: 16px;
  padding: 4px 16px;
  margin: 0;
  align-items: center;
  cursor: pointer;
  min-height: 32px;

  &:hover {
    background-color: var(--bg-color-white-gray);

    ${Controls} {
      visibility: visible;
    }

    ${Input} {
      background: color-mix(in srgb, white 25%, transparent) none;
    }
  }
`;

const ListItem = ({ id, title, isCustom }) => {
  const {
    activeTemplate,
    changeActiveTemplate,
    updateTemplate,
    handleDuplicateTemplate,
    editMode,
    setEditMode,
    usedTemplateIds,
  } = useContext(Context);

  function checkUsage() {
    return usedTemplateIds.includes(id);
  }

  const [titleValue, setTitleTitleValue] = useState(title);
  const [isUsed, setIsUsed] = useState(checkUsage);

  const inputRef = useRef();

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode, inputRef]);

  useEffect(() => {
    setIsUsed(checkUsage);
  }, [usedTemplateIds]);

  const isActive = id === activeTemplate.id;

  const handleClick = () => {
    if (!isActive) changeActiveTemplate(id);
  };

  const handleNameClick = () => {
    if (isActive && !editMode && activeTemplate.config.editable) {
      setEditMode(true);
    }
  };

  const handleDuplicateClick = evt => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    handleDuplicateTemplate(id);
  };

  const handleChange = evt => {
    setTitleTitleValue(evt.target.value);
  };

  const handleBlur = () => {
    setEditMode(false);
  };

  const handleKeyDown = evt => {
    switch (keycode(evt)) {
      case 'enter':
        updateTemplate({ title: titleValue });
      // falls through
      case 'esc':
        setEditMode(false);
        break;
      default:
        break;
    }
  };

  return (
    <Root onClick={handleClick}>
      <IndicatorContainer>
        {(isActive || isUsed) && (
          <Indicator
            style={{ backgroundColor: isActive ? `var(--brand-blue)` : `var(--text-grey)` }}
          />
        )}
      </IndicatorContainer>
      <Content>
        <NameContainer onClick={handleNameClick}>
          {(editMode && isActive && (
            <Input
              defaultValue={title}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              type="text"
              ref={inputRef}
            />
          )) || <Name isCustom={isCustom}>{title}</Name>}
        </NameContainer>

        {isCustom && (
          <Controls>
            <Button onClick={handleDuplicateClick}>Zduplikuj</Button>
          </Controls>
        )}
      </Content>
    </Root>
  );
};

ListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  isCustom: PropTypes.bool,
};

ListItem.defaultProps = {
  isCustom: false,
};

export default ListItem;
