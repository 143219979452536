import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ChromePicker } from 'react-color';

import { Option, ColorPreview, Input } from '../styles';
import { assembleHexWithAlpha } from '../utils';

const HexInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const HexInput = styled(Input)`
  margin: 0;
  max-width: 115px;
  text-transform: uppercase;
  color: var(--text-color);
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  top: 75px;
  left: 0;
  gap: 5px;
  border-radius: 5px;
  padding: 5px;
  border: 4px solid var(--white);
  background-color: var(--white);

  > div > div > div > div > div > div > input {
     {
      color: var(--text-color) !important;
      background-color: var(--white);
      border: 0 solid var(--white);
    }
  }
  > div > div {
     {
      background-color: var(--white);
      color: var(--text-color) !important;
    }
  }
`;

const CurrentColorsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GradientOptionStaticPickerPositionCss = css`
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  padding: 0;

  ${Wrapper} {
    position: static;
    padding: 0;
    gap: 15px;
  }
`;

const GradientOption = styled(Option)`
  top: -1px;
  ${props => props.pickerPosition === 'static' && GradientOptionStaticPickerPositionCss};
`;

const Gradient = ({ onChange, color, color2, pickerPosition, ...props }) => {
  const [values, setValues] = useState({
    color,
    color2,
  });

  const handleChange = (e, targetField) => {
    let newValue;

    if ('hex' in e && 'rgb' in e) {
      newValue = assembleHexWithAlpha(e);
    } else {
      let { value } = e.target;

      if (value.substr(0, 1) !== '#') {
        value = `#${value}`;
      }

      newValue = value;
    }

    setValues(prevState => ({
      ...prevState,
      [targetField]: newValue,
    }));

    if (targetField === 'color') {
      onChange(newValue, values.color2);
    } else {
      onChange(values.color, newValue);
    }

    return newValue;
  };

  return (
    <GradientOption pickerPosition={pickerPosition} {...props}>
      <CurrentColorsWrapper>
        <ColorPreview
          style={{
            backgroundColor: values.color,
            backgroundImage: `linear-gradient(${values.color}, ${values.color2})`,
          }}
        />

        <HexInputsWrapper>
          <HexInput
            value={values.color}
            maxLength={9}
            onChange={e => {
              handleChange(e, 'color');
            }}
          />
          <HexInput
            value={values.color2}
            maxLength={9}
            onChange={e => {
              handleChange(e, 'color2');
            }}
          />
        </HexInputsWrapper>
      </CurrentColorsWrapper>

      <Wrapper>
        <ChromePicker
          color={values.color}
          width={160}
          onChangeComplete={e => {
            handleChange(e, 'color');
          }}
        />
        <ChromePicker
          color={values.color2}
          width={160}
          onChangeComplete={e => {
            handleChange(e, 'color2');
          }}
        />
      </Wrapper>
    </GradientOption>
  );
};

Gradient.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
  color: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  pickerPosition: PropTypes.oneOf(['absolute', 'static']),
};

Gradient.defaultProps = {
  pickerPosition: 'absolute',
};

export default Gradient;
