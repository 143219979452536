import styled from 'styled-components';

import { style as menuTextStyle, hover as menuTextHover } from 'styles/ui/menuTextHover';

export const Container = styled.ul`
  display: flex;
  width: min-content;
  justify-content: center;
  align-items: center;
  margin: 0 auto 27px;
  list-style: none;

  background-color: var(--white);
  border-radius: 100px;

  padding: 15px 22px;
  text-decoration: none;

  cursor: pointer;

  > a {
    &:hover {
      > span {
        text-decoration: none;
        font-size: 30px;
      }
    }
  }
`;

export const Item = styled.li`
  width: 15px;
  height: 15px;
  margin: 0 8px;

  > a {
    position: relative;
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    background: var(--bg-color-white-gray);
    cursor: pointer;

    > span {
      z-index: 2;
      top: calc(100% + 10px);
      ${menuTextStyle}
    }

    &:hover {
      > span {
        ${menuTextHover}
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      border-radius: 100%;

      background-color: var(--green);
      box-shadow: 0 8px 9px 0 rgba(0, 0, 0, 0.01);

      transform: scale(0);
      transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &.active {
      &::before {
        transform: scale(1);
      }
    }
  }
`;
