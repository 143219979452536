import { css } from 'styled-components';

import { styles } from 'styles/Input';
import { BaseTextBoxCss } from '../../../../../../../../../components/TextInput';

export default css`
  ${BaseTextBoxCss};
  ${styles};
  font-family: var(--google-sora-cdn);
  font-size: 13px;
  border: 2px solid var(--border-color);
  border-radius: 30px;
  max-width: 100px;
  min-height: 38px;
  background-color: var(--white-box-background);
  cursor: pointer !important;
  padding-left: 28px;
  padding-right: 28px;
`;
