import styled from 'styled-components';

const Baloons = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export default Baloons;
