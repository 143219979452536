import styled, { keyframes } from 'styled-components';

const animationOut = keyframes`
  0% {
    top: 0;
    opacity: 0;
  }
`;

const animationIn = keyframes`
  40% {
    opacity: 0.5;
  }

  90% {
    opacity: 0;
  }

  100% {
    top: -200%;
  }
`;

export default styled.span`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);

  color: var(--border-color);
  font-weight: 500;
  opacity: 0;
  animation: ${props => (props.copied ? animationIn : animationOut)} 1s ease;
`;
