import { connect } from 'react-redux';

import { updateTemplate } from 'store/actions/templates';
import { fetchTestVoicesIfNeeded } from 'store/actions/speechSynthesizerVoices';

import defaultOptions from 'store/data/tipAlertDefaultOptions';

import SpeechSynthesizerManager from './SpeechSynthesizerManager';

const mapStateToProps = state => {
  const { created, defaults, active } = state.templates;

  const filteredCreated = created.filter(item => item.type === 'TIP_ALERT');
  const filteredDefaults = defaults.filter(item => item.type === 'TIP_ALERT');

  const activeTemplate = [...filteredCreated, ...filteredDefaults].find(
    template => active.TIP_ALERT === template.id,
  );

  let config = {};
  if (activeTemplate.config.speechSynthesizer) {
    config = { ...activeTemplate.config.speechSynthesizer };
  } else {
    config = { ...defaultOptions.speechSynthesizer };
  }

  const activeVoiceName = state.speechSynthesizerVoices.available.find(
    item => item.id === config.voiceType,
  );

  return {
    config,
    speechSynthesizerVoices: state.speechSynthesizerVoices.available,
    testVoices: state.speechSynthesizerVoices.test,
    activeVoice: activeVoiceName,
    activeTemplateId: activeTemplate.id,
    isEditable: activeTemplate.config.editable,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchUpdateConfig: updated => {
    dispatch(updateTemplate({ speechSynthesizer: { ...updated } }, null, 'TIP_ALERT'));
  },
  fetchTestVoicesIfNeeded: () => dispatch(fetchTestVoicesIfNeeded()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpeechSynthesizerManager);
