import styled from 'styled-components';

import Box from '../../../styles/Box';

export default styled(Box)`
  display: block;
  text-align: center;
  height: 262px;
  overflow: hidden;

  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  transform-origin: top;

  background: transparent none;

  > div > div > div > div > div > input {
    color: var(--text-color) !important;
    background-color: transparent;
    border: 0 solid var(--white);
  }

  .react-tags__selected {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 10px;

    .react-tags__selected-tag {
      margin: 0;
    }
  }
`;
