import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as StaticticsArrow } from 'assets/icons/statictics-arrow.svg';

import Odometer from 'components/Odometer';
import Wrapper from './components/Wrapper';

const PercentValue = ({ value }) => (
  <Wrapper minus={value < 0} zero={value === 0}>
    <StaticticsArrow />
    <Odometer value={value} format="dd" />
    <span>%</span>
  </Wrapper>
);

PercentValue.propTypes = {
  value: PropTypes.number.isRequired,
};

export default PercentValue;
