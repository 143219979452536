import React, { useState } from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';

import serverAlert from 'store/utils/serverAlert';
import TextInput from 'components/TextInput';
import WhiteBox from 'styles/WhiteBox';

import styled from 'styled-components';
import { Consumer } from '../Context';
import { SimpleCheckbox } from '../../../components/Checkboxes';

const Badge = styled.div`
  padding: 5px 0px 5px 0px;
  color: #ff0000;
  font-size: 14px;
  font-weight: 900;
  font-family: var(--google-sora-cdn);
  text-shadow: 0 0 30px rgba(255, 0, 0, 0.8);
`;

const EmoHeaders = styled.div`
  padding: 5px 0px 5px 0px;
  color: var(---text-color);
  font-size: 25px;
  font-weight: 900;
  font-family: var(--google-sora-cdn);
  margin-bottom: 10px;
`;

const EmoText = styled.div`
  font-size: 14px;
  color: var(--gray);
`;

const DisplayBox = styled.div`
  font-family: var(--google-sora-cdn);
  display: flex;
  flex-direction: row;
  height: 180px;
`;

const LeftPart = styled.div`
  font-family: var(--google-sora-cdn);
  width: 450px;
  transform: translateY(10px);
`;

const CenterPart = styled.div`
  display: flex;
  flex-direction: column;
  transform: translateY(10px);
`;

const RightPart = styled.div`
  width: 150px;
  transform: translate(96px, 35px);
`;

const InfoText = styled.div`
  font-size: 14px;
  width: 450px;
  font-weight: 900;
`;

const EmojiGreenState = styled.div`
  padding: 10px 20px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid var(--brand-green);
  background: rgba(43, 210, 131, 0.1);
`;

const EmojiRedState = styled.div`
  padding: 10px 20px;
  width: 400px;
  border-radius: 10px;
  border: 1px solid var(--red);
  background: rgba(255, 0, 0, 0.1);
`;

const OnOff = styled.div`
  width: 100px;
  margin-bottom: 15px;
  font-weight: 900;
`;

const OneRow = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > label {
    height: 52px;
    border-radius: 10px;
    border: 2px solid var(--border-color);
  }
`;

const TextToEmotes = ({ userDataProfile }) => {
  const [emotesId, setEmotesId] = useState(userDataProfile.emotes_id || '');
  const [replaceEmotes, setReplaceEmotes] = useState(userDataProfile.replace_emotes || '');
  const [loading, setLoading] = useState(false);

  return (
    <Consumer>
      {({ updateTipsPageSettings }) => (
        <WhiteBox style={{ padding: '30px 60px 30px' }}>
          <DisplayBox>
            <LeftPart>
              <Badge>NOWOŚĆ</Badge>
              <EmoHeaders>Emotki z Twitcha w Tipply</EmoHeaders>
              <EmoText>
                Skorzystaj z możliwości wrzucenia wszystkich emotek używanych na Twoim kanale
                Twitch, do naszych widgetów! Używane są emotki globalnego Twitch, emotki kanału
                Twitch, emotki z wtyczek BetterTTV, FrankerFaceZ oraz 7TV
              </EmoText>
            </LeftPart>
            <CenterPart>
              <div>
                <InfoText>
                  WPISZ URL DO KANAŁU TWITCH Z KTÓREGO,
                  <br />
                  CHCESZ, ABYŚMY POBIERALI EMOJI
                </InfoText>
                <OneRow>
                  <TextInput
                    value={emotesId}
                    onChange={({ target }) => setEmotesId(target.value)}
                    disabled={loading}
                    loading={loading}
                    prefix="https://twitch.tv/"
                    placeholder="Tu wpisz Twitch Username"
                    onBlur={async () => {
                      if (userDataProfile.emotesId === emotesId) return;
                      setLoading(true);
                      try {
                        await updateTipsPageSettings({ emotesId });
                      } catch (error) {
                        serverAlert(error.response.data.errors.children.emotesId.errors.join(', '));
                      }
                      setLoading(false);
                    }}
                    onKeyPress={event => {
                      const keyCodeType = keycode(event);
                      if (keyCodeType === 'enter') {
                        event.target.blur();
                      }
                    }}
                    maxLength={75}
                  />
                </OneRow>
                {replaceEmotes && (
                  <EmojiGreenState>Emoji we wiadomościach są włączone</EmojiGreenState>
                )}
                {!replaceEmotes && (
                  <EmojiRedState>Emoji we wiadomościach są wyłączone</EmojiRedState>
                )}
              </div>
            </CenterPart>
            <RightPart>
              <span style={{ marginLeft: '25px' }}>
                <OnOff>WŁ / WYŁ</OnOff>
                <SimpleCheckbox
                  color="green"
                  apperance="switch"
                  checked={replaceEmotes}
                  onCheck={async () => {
                    // if (userDataProfile.replaceEmotes === replaceEmotes) return;
                    setLoading(true);
                    try {
                      await updateTipsPageSettings({ replaceEmotes: !replaceEmotes });
                    } catch (error) {
                      serverAlert(
                        error.response.data.errors.children.description.errors.join(', '),
                      );
                    }
                    setLoading(false);
                    setReplaceEmotes(!replaceEmotes);
                  }}
                />
              </span>
            </RightPart>
          </DisplayBox>
        </WhiteBox>
      )}
    </Consumer>
  );
};

TextToEmotes.propTypes = {
  userDataProfile: PropTypes.instanceOf(Object).isRequired,
};

export default TextToEmotes;
