import React, { useRef, useState } from 'react';
import Datetime from 'react-datetime';
import { useDispatch } from 'react-redux';

import { changeConfig } from 'store/actions/goalsVoting';

import { useDelayedState } from 'utils/hooks';

import OptionsPopup from 'styles/OptionsPopup';

import FloatingMenu from 'components/FloatingMenu';
import { ArrowInCircleButton } from 'components/Buttons';

import DatePickerWrapper from '../../../components/DatePickerWrapper';

import { useVotingStore } from '../hooks';

import ClearButton from './ClearButton';
import ConfigBox from '../../../components/ConfigBox';

const SetEndDate = () => {
  const selfRef = useRef();
  const store = useVotingStore();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const {
    config: { finishDate },
  } = store;

  const [localFinishDate, setLocalFinishDate] = useDelayedState(
    finishDate,
    'localFinishDate',
    (fieldName, value) => {
      dispatch(changeConfig({ finishDate: value }));
    },
  );

  const onChange = e => {
    setLocalFinishDate(e.format());
  };

  const onClear = () => {
    setLocalFinishDate(null);
  };

  return (
    <div ref={selfRef} style={{ position: 'relative' }}>
      <ConfigBox>
        <span>
          {localFinishDate === null ? (
            'Czas do końca głosowania'
          ) : (
            <>
              {`Do końca: `}
              <strong>{new Date(`${localFinishDate}`).toLocaleString()}</strong>
            </>
          )}
        </span>
        <ArrowInCircleButton background="green" onClick={() => setVisible(true)} />
      </ConfigBox>

      <FloatingMenu
        visible={visible}
        position="right"
        onClose={() => setVisible(false)}
        parent={selfRef.current || document.body}
      >
        <OptionsPopup>
          <DatePickerWrapper>
            <Datetime
              value={localFinishDate ? new Date(`${localFinishDate}`) : new Date()}
              onChange={onChange}
              timeFormat="HH:mm"
              open
              input={false}
              locale="pl"
            />
            {localFinishDate && <ClearButton onClick={onClear}>Wyczyść</ClearButton>}
          </DatePickerWrapper>
        </OptionsPopup>
      </FloatingMenu>
    </div>
  );
};

export default SetEndDate;
